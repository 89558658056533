import { Vue, Options, mixins } from "vue-class-component";
import template from './ClaimListItem.vue';
import InsurancesCommon from "@/views/model/InsurancesCommon";
import ClaimAdapter from '@/views/model/adapter/ClaimAdapter';

@Options({
    name: 'ClaimsListItem',
    mixins: [template],
    props: {
        claim: ClaimAdapter,
        showStatus: Boolean,
        statusText: String
    }
})
export default class ClaimListItem extends mixins(InsurancesCommon) {
    claim: ClaimAdapter;
    showStatus: Boolean;
    statusText: String;
}
