import { ContentReference, IContent } from "@/definitions/episerver/content";
import api from "@/episerver/api/contentDeliveryApi";
import { ContentResolver } from "@/episerver/ContentResolver";
import store from "@/store";
import "url-search-params-polyfill";
import { ActionContext } from "vuex";

//actions for the epiDataModel module
export const UPDATE_MODEL_BY_DATA = "epiContent/UPDATE_CONTENT_BY_DATA";
export const UPDATE_MODEL_BY_URL = "epiContent/UPDATE_CONTENT_BY_URL";
export const UPDATE_MODEL_BY_CONTENT_LINK =
  "epiContent/UPDATE_CONTENT_BY_CONTENT_LINK";
const MODEL_LOADING = "epiContent/MODEL_LOADING";
export const MODEL_LOADED = "epiContent/MODEL_LOADED";

export interface EpiContentStateContainer {
  epiContent: EpiContentState;
}

export interface EpiContentState {
  modelLoaded: boolean;
  model: any;
  modelLanguage: string;
  isEditable: boolean;
}

const state = (): EpiContentState => ({
  modelLoaded: false,
  model: null,
  modelLanguage: null,
  isEditable: false,
});

const UPDATE_CONTENT = "epiContent/UPDATE_CONTENT";
const mutations = {
  [UPDATE_CONTENT](state: EpiContentState, newModel: any) {
    state.model = newModel.content;

    if (newModel.content?.frontendConfiguration != null) {
      store.dispatch(
        "setEpiRouting",
        newModel.content.frontendConfiguration.menuPoints
      );
    }
    state.modelLanguage = newModel.content?.language?.currentLanguage;
  },
  [MODEL_LOADING](state: EpiContentState) {
    state.modelLoaded = false;
  },
  [MODEL_LOADED](state: EpiContentState) {
    state.modelLoaded = true;
  },
};

const parameters = {};

const getQueryParams = () => {
  const urlQuery = new URLSearchParams(window.location.search);

  const queries = {};
  urlQuery.forEach((val, key) => {
    queries[key] = val;
  });

  return queries;
};

const convertContentLinkToString = (contentLink: ContentReference) => {
  let id = contentLink.id.toString();

  if (contentLink.workId && contentLink.workId > 0) {
    id = `${id}_${contentLink.workId}`;
  }

  return id;
};

const actions = {
  async [UPDATE_MODEL_BY_URL]({ commit, dispatch }, url: string): Promise<any> {
    const contentResolver = new ContentResolver();
    const resolvedContent = await contentResolver.resolveContent(
      url,
      true,
      getQueryParams()
    );
    commit(UPDATE_CONTENT, { ...resolvedContent });
    dispatch(MODEL_LOADED);

    return resolvedContent;
  },

  async [UPDATE_MODEL_BY_DATA]({ commit, state, dispatch }, content: string) {
    commit(UPDATE_CONTENT, content);
    dispatch(MODEL_LOADED);
  },
  async [UPDATE_MODEL_BY_CONTENT_LINK](
    { commit, rootState, state, dispatch }: ActionContext<EpiContentState, any>,
    contentLink: string
  ): Promise<IContent> {
    /**
     * Updating a model by content link is done when something is being
     * edited and when viewing a block. In order to be sure that we get the
     * correct model, we need to keep any previously existing query string
     * from the friendly URL.
     *
     * See the implementation of ExtendedContentModelMapper.GetContextMode
     * for additional details.
     */
    const route = rootState.route || { query: getQueryParams() };
    const params = { ...parameters, ...route.query, ...getQueryParams() };

    let content = await api.getContentByContentLink(contentLink, params);
    //this check prevents an block from stealing the view from an page in the editor
    if (state.modelLoaded) {
      content = await api.getContentByContentLink(
        convertContentLinkToString(state.model.contentLink),
        params
      );
    }
    commit(UPDATE_CONTENT, content);
    dispatch(MODEL_LOADED);
    return content;
  },
  async [MODEL_LOADED]({ commit }: ActionContext<any, any>) {
    //subscription event
    commit(MODEL_LOADED);
  },
};

export default {
  state,
  mutations,
  actions,
};
