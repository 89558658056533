import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pb-3 text-left" }
const _hoisted_2 = { key: "dummy4" }
const _hoisted_3 = {
  key: "dogType1",
  class: "pb-3"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = {
  key: "dogType2",
  class: "pb-3"
}
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "button-container text-center" }
const _hoisted_8 = ["textContent", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!
  const _component_SegmentedControlComponent = _resolveComponent("SegmentedControlComponent")!
  const _component_inputComponent = _resolveComponent("inputComponent")!
  const _component_SearchComponent = _resolveComponent("SearchComponent")!
  const _component_ValidationErrorComponent = _resolveComponent("ValidationErrorComponent")!
  const _component_AbDatePickerComponent = _resolveComponent("AbDatePickerComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SegmentedControlComponent, {
      key: "dummy",
      class: "pb-3",
      id: "dogCount",
      onSegmentedComponent: _ctx.dogCount,
      innerData: _ctx.model.calculation.abCalc.dogCountOptions,
      preSelected: _ctx.model.dogInfo.dogCount + '',
      controlsPerRow: 3
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.cms.dogCountlabel), 1),
          (_ctx.cms.dogCountPopover)
            ? (_openBlock(), _createBlock(_component_PopoverComponent, {
                key: 0,
                type: "icon",
                class: "page-component__popover",
                buttonClass: "popover__icon popover__icon__questionmark",
                innerData: _ctx.cms.dogCountPopover
              }, null, 8, ["innerData"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["onSegmentedComponent", "innerData", "preSelected"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_TransitionGroup, { name: "expand-list" }, {
        default: _withCtx(() => [
          (_ctx.validDogCount)
            ? (_openBlock(), _createBlock(_component_inputComponent, {
                class: "text-left pb-3",
                key: "dogName1",
                id: "dogName1",
                name: "dogName1",
                placeholder: "F.eks. Fido",
                label: "Navn på hund",
                validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
                innerData: _ctx.model.dogInfo,
                readonly: _ctx.readonlyDogName1,
                validationClass: _ctx.model.dogInfo.dogName1 ? 'input--valid' : undefined,
                required: true
              }, null, 8, ["validateOnLoad", "innerData", "readonly", "validationClass"]))
            : _createCommentVNode("", true),
          (_ctx.validDogCount && _ctx.model.dogInfo.dogName1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_SearchComponent, {
                  defaultValue: _ctx.model.dogInfo.dogType1,
                  autocompleteId: "1",
                  label: "Hvilken race tilhøre din hund?",
                  placeholder: "Indtast f.eks Beagle...",
                  searchByCleanedInput: true,
                  resultList: _ctx.cms.dogList,
                  isValid: _ctx.model.dogInfo.dogType1 !== undefined,
                  onSearch: _ctx.dogTypeSelected
                }, null, 8, ["defaultValue", "resultList", "isValid", "onSearch"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.model.pristineStep.get(_ctx.card.name) && _ctx.model.dogInfo.dogType1 === undefined)
            ? _withDirectives((_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                key: "dogType1Error",
                class: "input-error--show mt-n3",
                text: "Du skal vælge en hunde race"
              }, null, 512)), [
                [_vShow, _ctx.validDogCount && _ctx.model.dogInfo.dogName1]
              ])
            : _createCommentVNode("", true),
          (_ctx.validDogCount && _ctx.model.dogInfo.dogName1 && _ctx.model.dogInfo.dogTypeId1)
            ? (_openBlock(), _createBlock(_component_SegmentedControlComponent, {
                key: "gender1",
                class: "pb-3",
                id: "gender1",
                onSegmentedComponent: _ctx.handleGender,
                innerData: _ctx.genderArray,
                preSelected: _ctx.model.dogInfo.gender1,
                controlsPerRow: 4
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "pb-3 pt-3 text-left",
                    textContent: _toDisplayString('Hvilket køn er din hund?')
                  }, null, 8, _hoisted_4)
                ]),
                _: 1
              }, 8, ["onSegmentedComponent", "innerData", "preSelected"]))
            : _createCommentVNode("", true),
          (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.validDogCount && !_ctx.model.dogInfo.dogName1 && !_ctx.model.dogInfo.dogTypeId1 && !_ctx.model.dogInfo.gender1)
            ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                key: "gender1Err",
                class: "input-error--show input-error__radio mt-n3",
                text: "Vælg køn"
              }))
            : _createCommentVNode("", true),
          (_ctx.validDogCount && _ctx.model.dogInfo.dogName1 && _ctx.model.dogInfo.dogTypeId1 && _ctx.model.dogInfo.gender1)
            ? (_openBlock(), _createBlock(_component_AbDatePickerComponent, {
                key: "birthdate1",
                class: "text-left mb-3",
                label: "Fødselsdag (dd-mm-åååå)",
                innerId: "birthdate1",
                innerData: _ctx.model.dogInfo,
                placeholder: "F.eks. 21-12-2020",
                validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
                validationRule: "BeforeToday",
                dateLimitIncluded: false,
                maxLowerLimitYears: 25
              }, null, 8, ["innerData", "validateOnLoad"]))
            : _createCommentVNode("", true),
          (_ctx.showSecondDog)
            ? (_openBlock(), _createBlock(_component_inputComponent, {
                class: "text-left pb-3",
                key: "dogName2",
                id: "dogName2",
                name: "dogName2",
                placeholder: "F.eks. King",
                label: "Navn på anden hund",
                validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
                readonly: _ctx.readonlyDogName2,
                validationClass: _ctx.model.dogInfo.dogName2 ? 'input--valid' : undefined,
                innerData: _ctx.model.dogInfo,
                required: true
              }, null, 8, ["validateOnLoad", "readonly", "validationClass", "innerData"]))
            : _createCommentVNode("", true),
          (_ctx.showSecondDog && _ctx.model.dogInfo.dogName2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_SearchComponent, {
                  defaultValue: _ctx.model.dogInfo.dogType2,
                  autocompleteId: "2",
                  label: "Hvilken race tilhøre din anden hund?",
                  placeholder: "Indtast f.eks Beagle...",
                  searchByCleanedInput: true,
                  resultList: _ctx.cms.dogList,
                  isValid: _ctx.model.dogInfo.dogType2 !== undefined,
                  onSearch: _ctx.dogTypeSelected
                }, null, 8, ["defaultValue", "resultList", "isValid", "onSearch"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.model.pristineStep.get(_ctx.card.name) && _ctx.model.dogInfo.dogType2 === undefined)
            ? _withDirectives((_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                key: "dogType2Error",
                class: "input-error--show mt-n3",
                text: "Du skal vælge en hunde race"
              }, null, 512)), [
                [_vShow, _ctx.showSecondDog && _ctx.model.dogInfo.dogName2]
              ])
            : _createCommentVNode("", true),
          (_ctx.showSecondDog && _ctx.model.dogInfo.dogName2 && _ctx.model.dogInfo.dogTypeId2)
            ? (_openBlock(), _createBlock(_component_SegmentedControlComponent, {
                key: "gender2",
                class: "pb-3",
                id: "gender2",
                onSegmentedComponent: _ctx.handleGender,
                innerData: _ctx.genderArray,
                preSelected: _ctx.model.dogInfo.gender2,
                controlsPerRow: 4
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "pb-3 pt-3 text-left",
                    textContent: _toDisplayString('Hvilket køn er din hund?')
                  }, null, 8, _hoisted_6)
                ]),
                _: 1
              }, 8, ["onSegmentedComponent", "innerData", "preSelected"]))
            : _createCommentVNode("", true),
          (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.showSecondDog && !_ctx.model.dogInfo.dogName2 && !_ctx.model.dogInfo.dogTypeId2 && !_ctx.model.dogInfo.gender2)
            ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                key: "gender2Err",
                class: "input-error--show input-error__radio mt-n3",
                text: "Vælg køn"
              }))
            : _createCommentVNode("", true),
          (_ctx.showSecondDog && _ctx.model.dogInfo.dogName2 && _ctx.model.dogInfo.dogTypeId2 && _ctx.model.dogInfo.gender2)
            ? (_openBlock(), _createBlock(_component_AbDatePickerComponent, {
                key: "birthdate2",
                class: "text-left mb-3",
                label: "Fødselsdag (dd-mm-åååå)",
                innerId: "birthdate2",
                innerData: _ctx.model.dogInfo,
                placeholder: "F.eks. 21-12-2020",
                validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
                validationRule: "BeforeToday",
                dateLimitIncluded: false,
                maxLowerLimitYears: 25
              }, null, 8, ["innerData", "validateOnLoad"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("a", {
        class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', {'btn-disabled': !_ctx.isValid}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep(_ctx.card.routePath))),
        textContent: _toDisplayString(_ctx.card.linkText),
        "data-gtm": _ctx.cms.dataGtm,
        "data-gtm-click-text": _ctx.card.routePath
      }, null, 10, _hoisted_8)
    ])
  ]))
}