import { OrderFlowInputBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd'

export default class OrderflowCheckboxBlockCd extends ContentItemCd {
    public model: OrderFlowInputBlock;

    // These will be set after model loaded
    public id: string;
    public canSubmit: boolean = true;

    constructor(model: OrderFlowInputBlock) {
        super();
        this.model = model;
        this.init();
    }

    public init(): void {
        // console.log('OrderflowCheckboxBlockCd', this.model);
        this.model.hidden = !!this.model.hidden;
        this.id = Math.random() + '';

        if  (!this.model.fieldName) {
            this.model.fieldName = '_' + Math.random();
        }
        // console.log('checkboxModel!!!!', this);
    }

    public handleEvent(evt) {
        if (!this.model.required || this.model.hidden) {
            this.canSubmit = true;
        } else {
            this.canSubmit = evt.checked;
        }
    }

    public setupEventHandler() {
        this.canSubmit = !this.model.required || this.model.hidden || !!this.model.defaultValue ;
    }
}
