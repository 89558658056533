import Axios from 'axios';
import AxiosRetry from 'axios-retry';

class AbAxiosSelfserviceContent {
    private abaxiosRetry = AxiosRetry;
    private axios = Axios.create({
        timeout: 15000,
        headers: {
            'Accept': 'application/json',
            'Accept-Language': 'da'
        }
    });
    constructor() {
    }

    public get(url: string): Promise<any> {
        return this.axios.get(url);
    }

    public getRetry(url: string, params?: any): Promise<any> {
        this.abaxiosRetry(this.axios, { retries: 3 });
        if (params) {
            return this.axios.get(url, {
                params
            });
        } else {
            return this.axios.get(url);
        }
    }

}
export default AbAxiosSelfserviceContent;
