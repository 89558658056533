
import { Vue, Options, mixins } from "vue-class-component";

    @Options({
        name: 'dialog-component',
        props: {
            makeFullscreen: Boolean,
            flowLength: Number,
            showProgressBar: Boolean,
            closeCallBack: Function,
            showSpinner: Boolean,
            showCloseBtn: Boolean
        }
    })


    export default class Dialog extends Vue {

        makeFullscreen: boolean;
        flowLength: number;
        showProgressBar: boolean;
        closeCallBack: Function;
        showSpinner: boolean;
        showCloseBtn: boolean;

        private modal: HTMLInputElement = null;
        private progressLine: HTMLElement = null;
        private currentStep: number;

        mounted() {
            // Get the modal
            this.modal = (<HTMLInputElement>document.getElementById('myModal'));

            this.progressLine = (<HTMLElement>document.querySelector('.progress-line span'));

            if (this.makeFullscreen) {
                this.makeFullWidthAndHeight();
            }
            this.currentStep = 1;
            this.updateProgressLine();

            // const tabFix = (<HTMLElement>document.querySelector('#myModalBody .card-body'));
            // tabFix.style.outline = 'none';
            // tabFix.tabIndex = 1;
            // tabFix.focus();
            // console.log((<HTMLElement>document.querySelector('#myModalBody .card-body')));

        }

        public updated() {
            this.updateProgressLine();
        }

        public updateProgress(step: number = 0) {
            this.currentStep = step + 1;
        }

        private updateProgressLine() {
            if (this.showProgressBar && this.flowLength) {
                if (this.currentStep === -1) {
                    // reset
                    this.currentStep = 1;
                }
                setTimeout(() => {
                    this.progressLine.style.width = ((this.currentStep/this.flowLength) * 100) + '%';
                 }, 1);
            }
        }

        // When the user clicks the button, open the modal
        public openModal() {
            this.modal.style.display = "block";
            window.scrollTo(0, 0);
        }
        // When the user clicks on <span> (x), close the modal
        public closeModal() {
            this.progressLine.style.width = '0%';
            this.currentStep = -1;
            this.modal.style.display = "none";
            if (this.closeCallBack) {
                this.closeCallBack();
            }
            window.scrollTo(0, 0);
            this.$emit('dialogClosed', true);
        }

        public closeOnModalClick(event) {
            if(event && event.target === this.modal) {
                this.closeModal();
            }
        }

        //Make container fullWidth
        private makeFullWidthAndHeight() {
            let container = document.querySelector(".modal-content");
            if (window.innerWidth >= 1024) {
                container.setAttribute("style", "width: 100% !important; height: 100% !important; overflow:hidden; z-index: 28;margin-top: 0px;");
            } else {
                container.setAttribute("style", "width: 100% !important; height: 100% !important; z-index: 28;margin-top: 0px;");
            }

            let footer = document.querySelector(".card-footer");
            footer.classList.add("fixed-bottom");
        }
    }
