import { ModalComponent } from "@/views/sharedcomponents/baseComponents/components";
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";

@Options({
  name: "TileCall",
  components: {
    ModalComponent,
  },
  computed: mapState<any>({
    isEditable: (state) => state.epiContext.isEditable,
    parentModel: (state) => state.epiContent.model,
  }),
  props: {
    model: Object as PropType<any>,
    trackingUrl: String
  },
})
export default class TileCall extends Vue {
  model: any;
  trackingUrl: string;


  public windowWidth: number = null;

  // LIFECYCLE
  mounted() {
    this.windowWidth = window.innerWidth;

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  }

  beforeUnmount() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  }

  // COMPUTED
  get formattedPhoneNo() {
    let formattedPhone = this.model.phoneNumber;
    const phoneNumber = new RegExp("[0-9]{8}");

    if (this.model.phoneNumber.startsWith("0045")) {
      formattedPhone = this.model.phoneNumber.slice(4);
    }

    if (this.model.phoneNumber.startsWith("+45")) {
      formattedPhone = this.model.phoneNumber.slice(3);
    }

    if (phoneNumber.test(formattedPhone)) {
      formattedPhone = formattedPhone.replace(/^(.{2})(.{2})(.{2})(.{2})(.*)$/, "$1 $2 $3 $4");
    }

    return formattedPhone;
  }
}
