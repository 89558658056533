<template>
    <div class="checkbox-styled">
        <input class="checkbox-styled__input"
               type="checkbox"
               :id="id"
               :name="name"
               :ref="(name + id)"
               :disabled="disabled"
               :aria-labelledby="labelledby"
               :value="inputValue"
               :data-t-cb-input="testProp"
               v-model="change"
                @change="changed"
                />
        <label v-if="$slots.default" class="checkbox-styled__label" :for="id" tabindex="0" @keyup.enter="changeItem()" @keyup.space="changeItem()">
            <slot></slot>
        </label>
        <span v-else class="checkbox-styled__label" tabindex="0" @keyup.enter="changeItem()" @keyup.space="changeItem()"></span>
        <span class="checkmark"></span>
    </div>
</template>

<style lang="scss" src="./_assets/_checkbox.scss"></style>
