import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import { PropType } from "vue";
import { mapState } from "vuex";
import { EventsBlock } from "@/definitions/episerver/content-types";
import ClaimAdapter from '@/views/model/adapter/ClaimAdapter';
import AbAxios from '@/views/common/ab_axios';
import { SkeletonLoaderComponent } from '@/views/sharedcomponents/baseComponents/components';
import { useRoute } from 'vue-router';

@Options({
    name: 'EventsBlockComponent',
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<any>,
        id: String,
        claim: ClaimAdapter,
        events: Object,
        isLoadingClaim: Boolean,
        isLoadingEvents: Boolean
    },
    components: {
        SkeletonLoaderComponent
    },
})

export default class EventsBlockComponent extends mixins(InsurancesCommon) {
    model: any;
    id: string;
    claim: ClaimAdapter = null;
    events: any = null;
    isLoadingClaim: boolean = true;
    isLoadingEvents: boolean = true;

    beforeMount() {
        setTimeout(() => {
            this.getClaim();
            this.getEvents();
        }, 1);
    }


    // METHODS
    public abAxios = new AbAxios

    private getClaim() {
        const self = this;
        this.abAxios.abGetWithToken(`/api/v1/serviceproxy/customer/claims/${this.$route.query.id}`, true, function successCallback(response) {
            self.claim = new ClaimAdapter(response.data);
            self.isLoadingClaim = false;
        }, function errorCallback(err) {
            console.log(err, "Skaden kunne ikke hentes. Noget gik galt.. ");
            self.isLoadingClaim = false;
        });
    }

    private getEvents() {
        const self = this;
        this.abAxios.abGetWithToken(`/api/v1/serviceproxy/customer/claims/${this.$route.query.id}/businessEvents`, true, function successCallback(response) {
            self.events = response.data.reverse();
            self.isLoadingEvents = false;
        }, function errorCallback(err) {
            console.log(err, "Skaden kunne ikke hentes. Noget gik galt.. ");
            self.isLoadingEvents = false;
        });
    }
}
