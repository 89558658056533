import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_ctx.hasError)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.alt), 1))
    : (_ctx.isInlineSvg)
      ? (_openBlock(), _createBlock(_component_inline_svg, {
          key: 1,
          src: _ctx.url,
          onError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hasError = true))
        }, null, 8, ["src"]))
      : (_openBlock(), _createElementBlock("img", {
          key: 2,
          src: _ctx.url,
          alt: _ctx.alt
        }, null, 8, _hoisted_2))
}