import { OrderflowPriceBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd'

export default class OrderflowPriceBlockCd extends ContentItemCd {
    public model: OrderflowPriceBlock;
    public currentPrice: string;
    public newPrice: string;
    public totalPrice: string;

    constructor(model: OrderflowPriceBlock) {
        super();
        this.model = model;
        this.init();
    }

    public init(): void {
        if (this.model.backgroundColor) {
            this.model.backgroundColor = 'background-color:' + this.model.backgroundColor;
        }
    }
    public translateTemplate(wordsTranslate: any) {

        // showSum will be packed in <li></li> when in subtext
        if (wordsTranslate && wordsTranslate.showSum && wordsTranslate.showSum !== '') {
            wordsTranslate.showSum = '<li>' + wordsTranslate.showSum + '</li>';
        }
        const translate = Object.assign({
            insuranceName: 'forsikring',
            orderflowName: 'dækning',
            expireDate: 'forfaldsdato',
            showSum: '',
        }, wordsTranslate);

        const replaces = [ new RegExp('{{forsikring}}', 'ig'),
                            new RegExp('{{dækning}}', 'ig'),
                            new RegExp('{{d&aelig;kning}}','ig'),
                            new RegExp('{{FORFALDSDATO}}', 'ig'),
                            new RegExp('{{vis_sum}}', 'ig'),
                        ];
        const replaceWidth = [ translate.insuranceName,  translate.orderflowName,  translate.orderflowName, translate.expireDate, translate.showSum];
        replaces.forEach( (replace, inx) => {
            this.model.subText = this.model.subText.replace(replace, replaceWidth[inx]);
            this.model.currentPriceLabel = this.model.currentPriceLabel.replace(replace, replaceWidth[inx]);
            this.model.newPriceLabel = this.model.newPriceLabel.replace(replace, replaceWidth[inx]);
            this.model.totalPriceLabel = this.model.totalPriceLabel.replace(replace, replaceWidth[inx]);
        });

    }
}
