import OrderFlowModel from "@/views/model/OrderFlowModel";
import OrderflowStepBlockCd from "@/views/contentApi/contentTypes/OrderflowStepBlockCd";
import CustomerEmailHandler from "./CustomerEmailHandler";

export default class FlowHandler {

    public orderFlowList: Array<OrderFlowModel> = [];
    public closeCallBack: Function;
    private activeIndex: number = -1;
    public productTitle: string;

    constructor(orderFlowList: Array<OrderFlowModel>) {
        this.orderFlowList = orderFlowList;
    }
    public hasNextStep(): boolean {
        return this.orderFlowList && this.orderFlowList.length - 1 > this.orderFlowList.findIndex(x => x.selected === true);
    }

    public hasPreviousStep(): boolean {
        return this.orderFlowList && this.orderFlowList.findIndex(x => x.selected === true) !== 0;
    }

    public next(): OrderFlowModel {
        this.activeIndex = this.orderFlowList.findIndex(x => x.selected === true);

        if (this.activeIndex === -1) {
            return null;
        }
        const nextActiveState = this.orderFlowList[this.activeIndex + 1];
        if (nextActiveState === undefined) {
            return null;
        }
        this.orderFlowList[this.activeIndex].selected = false;
        nextActiveState.selected = true;
        this.activeIndex++;
        return nextActiveState;
    }

    public before(): OrderFlowModel {
        this.activeIndex = this.orderFlowList.findIndex(x => x.selected === true);
        if (this.activeIndex <= 0) {
            return null;
        }
        const beforeActiveState = this.orderFlowList[this.activeIndex - 1];
        if (beforeActiveState === undefined) {
            return null;
        }
        this.orderFlowList[this.activeIndex].selected = false;
        beforeActiveState.selected = true;
        this.activeIndex--;
        return beforeActiveState;
    }

    public active(): OrderFlowModel {
        const activeState = this.orderFlowList[this.activeIndex];
        if (!activeState) {
            return null;
        }
        return activeState;
    }

    public getFlowState(): number {
        return this.activeIndex;
    }

    public length(): number {
        return this.orderFlowList.length;
    }

    public reset() {
        this.orderFlowList.map( x => x.selected = false);
        this.orderFlowList[0].selected = true;
        this.activeIndex = 0;
    }

    public static createFlowHandlerContent(orderflowStepBlock: Array<OrderflowStepBlockCd>) {
        const orderFlowList: Array<OrderFlowModel> = [];

        orderflowStepBlock.forEach( (theOrderflowStepBlock) => {
            orderFlowList.push( {
                orderflowStepBlock: theOrderflowStepBlock,
                selected: false,
                submitCallBack: theOrderflowStepBlock.submitCallBack,
                cancelCallBack: theOrderflowStepBlock.cancelCallBack,
            });
        });

        orderFlowList[0].selected = true;
        const flowHandler = new FlowHandler(orderFlowList);
        flowHandler.reset();
        return flowHandler;
    }

    public static setUserDetailsForMail(params, customer) {
        params.set('customerId', customer.customer_id);
        params.set('customerName', customer.person.firstname + ' ' + customer.person.lastname);
        params.set('customerEmail', CustomerEmailHandler.getCustomerEmail());
    }

}
