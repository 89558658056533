<template>
    <div class="select-modal">
        <InputFieldComponent :class="['modal-input',{'modal-input__selected' : selected}]"
                             :key="inputKey"
                             :label="label"
                             :id="id + 1"
                             :inputValue="inputValue"
                             :name="name"
                             :required="true"
                             :readonly="true"
                             v-on:InputFieldComponent="handleInput"/>
        <ModalComponent padding="0"
                        :id="id"
                        :dismisable="true"
                        :trackingEnabled="false"
                        top="50%">
            <template v-slot:body>
                <bulletListComponent type="none"
                    :selectable="true"
                    :selectedValue="inputValue"
                    :innerData="innerData" 
                    v-on:BulletListComponent="selectItem"/>
            </template>
        </ModalComponent>
    </div>
</template>

<style lang="scss" src="./_assets/_select-modal.scss"></style>
