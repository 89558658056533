import AbAxiosSelfserviceContent from '@/views/contentApi/ab_axios_selfservice_content';
import OrderflowContainerBlockCd from '@/views//contentApi/contentTypes/OrderflowContainerBlockCd';
import store from '@/store';
import { defaultConfig } from '@/episerver/config';

export default class GlobalContentHandler {

    private static abAxiosSelfserviceContent: AbAxiosSelfserviceContent = new AbAxiosSelfserviceContent();
    private static ContentUrl: string = '?contentUrl=/orderflow&matchExact=true&config=';

    public static async setUp() {
        if (store.getters.getGlobalOrderflow) {
            return;
        }
        await this.loadContentToStore();
    }

    // https://localhost:40100/api/ab/v3/content?contentUrl=/orderflow&matchExact=true&config=
    private static async loadContentToStore(): Promise<any> {
        const url = defaultConfig.apiUrl + this.ContentUrl;
        try {
                const block = await this.abAxiosSelfserviceContent.get(url);
                // console.log('globalHandler', block.data);
                if (block.data.mainArea?.length === 1) {
                    const contentBlock: OrderflowContainerBlockCd = new OrderflowContainerBlockCd(block.data.mainArea[0] as any);
                    store.dispatch('setGlobalOrderflow', contentBlock);
                    return Promise.resolve();
                } else {
                    console.error("Couldn't load Global orderflow");
                    return Promise.reject();        
                }
                
        } catch(err) {
            console.error(err);
            return Promise.reject();
        }
    }
}
