export interface displays {
    default: string;
    small: string;
    medium: string;
    large: string;
    xlarge: string;

}

export const displayOptions: Record<string, displays> = {
    UsabillaInPageBlock: { default: "small", small: "col-sm-12 col-lg-12", medium: "col-sm-12 col-lg-12", large: "col-sm-12 col-lg-12", xlarge: "col-sm-12 col-lg-12" },
    TextBlock: { default: "small", small: "col-sm-12 col-lg-12", medium: "col-sm-12 col-lg-12", large: "col-sm-12 col-lg-12", xlarge: "col-sm-12 col-lg-12" },
}
