import { App } from "@/views/sharedcomponents/lib/services/app";


export class Tracking {


    static buildTrackingUrl(customer: any, fullPath: string): string {
        const customerType = customer?.tags?.includes("#business") ? 'business' : 'private';
        let path = fullPath;
        path = path.startsWith('/mitalmbrand') ? path.replace('/mitalmbrand#', '/mitalmbrand') : path;

        let pathDelimiter = "/";
        if (path.startsWith("/")) {
            pathDelimiter = "";
        }
        let applikation = App.isEnabled() ? 'app' : 'web';

        let customerIdent = "customer";
        if (customer?.tags?.includes("#broker")) {
            customerIdent = "broker";
        }

        return `virtualurl/${customerType}/${customerIdent}/${applikation}${pathDelimiter}${path}`;
    }

}


