import UsabillaInPageBlockComponent from '@/views/contentApi/components/UsabillaInPageBlockComponent/UsabillaInPageBlock.vue';
import { mixins, Options } from 'vue-class-component';
import { mapState } from 'vuex';
// import '@trevoreyre/autocomplete-vue/dist/style.css'
import { CardBlock, HtmlBlock } from '@/definitions/episerver/content-types';
import { CALCULATOR_LOADED, INIT_CALCULATOR, RESET_CALCULATORS } from '@/episerver/store/modules/calculatorContext';
import store from '@/store';
import InsurancesCommon from '@/views/model/InsurancesCommon';
import { EnvironmentService } from '@/views/sharedcomponents/lib/services/environmentService';
import { cloneDeep } from "lodash";
import { PropType } from 'vue';
import { BuyInsuranceHelper, STEP, Validator } from '../BuyInsuranceHelper';
import ModalComponentCalculator from '../components/ModalComponentCalculator.vue';
import TransitionExpand from '../components/TransitionExpand';
import CampaignStepComponent from '../steps/common/CampaignStepComponent.vue';
import ChoosePackageStepComponent from '../steps/common/ChoosePackageStepComponent.vue';
import ContactInformationStepComponent from '../steps/common/ContactInformationStepComponent.vue';
import OverviewStepComponent from '../steps/common/OverviewStepComponent.vue';
import PaymentStepComponent from '../steps/common/PaymentStepComponent.vue';
import RecieptStepComponent from '../steps/common/RecieptStepComponent.vue';
import AdditionalInfoStepComponent from '../steps/family/AdditionalInfoStepComponent.vue';
import FamilyInfoStepComponent from '../steps/family/FamilyInfoStepComponent.vue';
import PersonInfoStepComponent from '../steps/family/PersonInfoStepComponent.vue';
import FamilyCalculator from './FamilyCalculator';
import { FamilySettings } from './FamilySettings';
import { Model, STEPS } from './Model';

@Options({
    name: 'selfserviceBuyInsuranceFamilyComponent',
    props: {
        calculatorInfoBlock: Object as PropType< Array<HtmlBlock>>,
        contentBlocks: Object as PropType< Array<CardBlock>>,
        cardReciept: Object as PropType<CardBlock>,
        settingsBlock: Object as PropType<HtmlBlock>,
        usabillaBlock: Object as PropType<UsabillaInPageBlockComponent>,
        usabillaBlockLeaveIntent: Object as PropType<UsabillaInPageBlockComponent>,
        contentUrl: String, // Used by helper to handle browser history
        headerBadgeTxt: String,
    },
    components: {
        ModalComponentCalculator,
        UsabillaInPageBlockComponent,
        PersonInfoStepComponent,
        FamilyInfoStepComponent,
        ChoosePackageStepComponent,
        OverviewStepComponent,
        AdditionalInfoStepComponent,
        ContactInformationStepComponent,
        PaymentStepComponent,
        RecieptStepComponent,
        CampaignStepComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
})

export default class BuyFamily extends mixins(InsurancesCommon) {
    contentBlocks: Array<CardBlock>;
    cardReciept: CardBlock;
    settingsBlock: HtmlBlock;
    usabillaBlock: UsabillaInPageBlockComponent;
    usabillaBlockLeaveIntent: UsabillaInPageBlockComponent;
    contentUrl: string; // Used by helper to handle browser history
    headerBadgeTxt: string;
    calculatorInfoBlock: HtmlBlock;

    // list and order of steps
    public steps : Array<string> = STEPS

    public model!: any; // model from store
    public cms!: FamilySettings; // setting from store
    public helper: BuyInsuranceHelper;
    public calculator: FamilyCalculator = null;
    public componentInit = false;
    public checkout = false;
    public transitionExpand = new TransitionExpand();

    // TODO klk
    // @Watch('$route', { deep: true }) // used for history back button
    // onUrlChange(params: any) {
    //     this.helper.onUrlChange(params);
    // }

    public async created() {
        this.helper = new BuyInsuranceHelper(this);

        if (!store.getters.getActiveCalculator) { // this is first load - update store
            const cms = new FamilySettings(this.settingsBlock);
            if(cms.mockData === true) {
                Model.mock();
            }

            await store.dispatch(INIT_CALCULATOR, {cms, model: cloneDeep(Model.model)});
        } else {
            await store.dispatch(CALCULATOR_LOADED);
        }

        if (!await this.helper.initComponent()) {
            return;
        }

        this.calculator = new FamilyCalculator(this);

        this.helper.setFirstCard();
    }

    // called by helper
    public addResetSubscription() {
        const unsubscribe = store.subscribeAction((action, state) => {
            if (action.type === RESET_CALCULATORS) {
                setTimeout(() => {
                    this.checkout = true;
                    store.dispatch(INIT_CALCULATOR, {cms : this.cms, model: cloneDeep(Model.model)});
                    unsubscribe();
                }, 2000);
            }
        });
    }

    public get showValidNowOption() {
        return this.helper.showValidNowOption;
    }

    public async gotoCard(cardName: string): Promise<boolean> {

        if(cardName === STEP.PERSON_INFO || cardName === STEP.PACKAGE) {
            BuyInsuranceHelper.resetSelectedCalculation(this);
        }

        if(cardName === STEP.CHILDREN_INFO) {
            // check address
            this.model.showSpinner = true;
            this.model.calculating = false;
            const ok = await this.calculator.checkForValidAddress();
            this.model.showSpinner = false;
            this.model.calculating = true;

            if (!ok) {
                this.model.currentCardName = cardName;
                this.gotoCard(STEP.PERSON_INFO);
                return false;
            }
        }

        if(cardName === STEP.PACKAGE) {

            this.calculator.setupExcessList();
            // check Age:
            if (!this.helper.handleValidCustomerAge()) {
                this.gotoCard(STEP.PERSON_INFO);
                return false;
            }
        // altid kun en risk (dummy)
            if(this.model.ownRisks.length === 1) {
                this.model.choosePackage.ownRiskId = this.model.ownRisks[0].value;
                this.calculator.getCalculations();
            }
        }

        if(cardName === STEP.PAYMENT) {
            // force user to choose yearly/monthly payment to ensure progressive steps
            this.model.choosePackage.monthYear = undefined;
        }

        this.model.currentCardName = cardName;

        if(cardName === STEP.ORDER) {
            await this.orderByEmail();
            return false;
        }
        return true;
    }

    /**
     * when nextbtn is clicked - check for valid
     * @param cardName
     */
    public nextStep(cardName: string, addToHistory: boolean = true) {
        this.helper.nextStep(cardName, addToHistory);
    }

    public getSubtitle(cardName: string) {
        return this.helper.getSubtitle(cardName);
    }

    public isActiveCard(cardName: string): boolean {
        return cardName === this.model.currentCardName;
    }

    public isValid(cardName): boolean {
        switch(cardName) {
            case STEP.PERSON_INFO:
                const okPerson = this.model.personInfo.address &&
                    this.model.personInfo.familyMemberCount &&
                    Validator.isValidAge(this.model.personInfo.customerAge) &&
                    this.model.personInfo.thatchedRoof &&
                    this.helper.isValidAlmbrandProducts();
                if (okPerson) {
                    this.model.personInfo.customerAge = this.model.personInfo.customerAge.trim().replace(/\D/g,'');
                    // const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
                    this.model.personInfo.subtitle = `${this.model.personInfo.customerAge} år, ${this.model.personInfo.address}`;
                }
                return okPerson;
            case STEP.CHILDREN_INFO:
                const ok = this.isPersonsFilled();
                if (ok) {
                    if (this.model.familyInfo.personCountId === 0 && this.model.familyInfo.age0 !== undefined ) {
                        this.model.familyInfo.subtitle = this.model.familyInfo.name0 + ' ' + this.model.familyInfo.age0 + ' år';
                    } else if(this.model.familyInfo.personCountId > 0 && this.model.familyInfo.personCount){
                        this.model.familyInfo.subtitle = this.model.familyInfo.personCount;
                    } else {
                        this.model.familyInfo.subtitle = '';
                    }
                }
                if(!ok) {
                    this.model.familyInfo.subtitle = '';
                }
                return ok;
            case STEP.PACKAGE:
                return this.helper.isValidPackage() && this.model.choosePackage.insuredValueAmount;
            case STEP.OVERVIEW:
                return this.helper.isValidPackage();
            case STEP.CONTACT_INFORMATION:
                return this.helper.isValidContactInfo();
            case STEP.ADDITIONAL_INFO:
                return this.helper.isValidAdditionalInfo(false);
            case STEP.PAYMENT:
                return this.helper.isValidPaymentInfo();
            default: return true;
        }
    }

    private isPersonsFilled(): boolean {
        let ok = this.model.familyInfo.personCount !== 0;
        if(ok) {
            if (this.model.familyInfo.personCountId + 1 >= this.model.personInfo.familyMemberCountId) {
                ok = false;
            }
        }
        if (ok) {
            for (let i = 0; i <= this.model.familyInfo.personCountId; i++) {
                const age = 'age'+ i;
                ok = this.model.familyInfo[age] !== undefined;
                if(!ok) {
                    break;
                }
                if (this.cms.useAdultAccident) {
                    const personAge = this.model.familyInfo[age];
                    if (personAge > -1 && personAge < 100) {
                        if (personAge >= 18) {
                            if (!this.model.familyInfo['workId' + i]) {
                                ok = false;
                                break;
                            }
                        }
                    }
                } else {
                    if (this.cms.showWorkForMinors && this.model.familyInfo[age] >= 15) {
                        if (!this.model.familyInfo['hasWork' + i]) {
                            ok = false;
                            break;
                        }
                        if(this.model.familyInfo['hasWork' + i] === 'ja') {
                            if (!this.model.familyInfo['workId' + i]) {
                                ok = false;
                                break;
                            }
                        }
                    }
                }
            }
        }
        return ok;
    }
    // called by helper
    public setOverviewData() {
        this.helper.setHighLights();

        this.model.overview.details = [
            this.model.personInfo.customerAge + ' år',
            this.model.personInfo.address,
            'Stråtag: ' + this.model.personInfo.thatchedRoof,
            'Husstand: ' +this.model.personInfo.familyMemberCount,
            this.model.familyInfo.personCount,
            'Selvrisiko: ' + this.getExcessAmount(),
            'Indbo sum: ' + this.model.choosePackage.insuredValue,
        ];
        this.helper.setDetailsYearlyPrice();
    }

    public async orderByEmail() {
        try {
            this.model.showSpinner = true;
            if (!this.isValid(this.model.currentCardName)) {
                this.model.showSpinner = false;
                return;
            }
            const pack = this.model.choosePackage.selectedPackage;
            let desc = '';

            let tracking = '';
            if (this.model.contact_information.customerNo) {
                const device = EnvironmentService.isApp() ? '(APP)' : '(WEB)';
                desc += `<li><h2><i>Kunde genkendt ${device}</i></h2></li>`;
                tracking += "/existingCustomer";
            }
            if (this.model.calculation?.addressSupplement > 1) {
                desc += '<li><h2><i>Adresse tillæg</i></h2></li>';
                tracking += "/addressSupplement";
            }
            if (this.model.campaign.valid) {
                const id = this.model.campaign.ID ? this.model.campaign.ID.toLocaleUpperCase() : 'Ukendt!';
                desc += `<li><h2><i>Kampagne ${id}</i></h2></li>`;
                tracking += `/campaign/${id}`;
            }
            if (this.model.additionalInfo.rki?.toLowerCase() === 'ja') {
                desc += '<li><h2><i>Kunde er registreret i RKI</i></h2></li>';
                tracking += "/rki";
            }
            if (this.model.additionalInfo.skader !== '0') {
                desc += `<li><h2><i>Kunde har ${this.model.additionalInfo.skader} skader</i></h2></li>`;
                // tracking += "/damages";
            }
            if (this.model.personInfo.thatchedRoof === 'ja') {
                desc += `<li><h2><i>Stråtag</i></h2></li>`;
                // tracking += "/thatchedRoof";
            }

            if (desc !== '') {
                desc = '<ul>' + desc + '</ul>'
            }

            await this.helper.submitOrder(desc, tracking);

            const price: number = this.model.choosePackage.monthYear === 'M' ? pack.totalPrice * 12 : pack.yearlyPriceTotal;
            const product = {
                name: 'FP_FP',
                id: pack.name,
                price: '' + price,
                brand: 'Fakedoor flow',
                variant: undefined,
                category: 'Online Salg',
                quantity: '1'
            }
            this.helper.trackPurchase(product, price);

        } catch(e) {
            this.model.showSpinner = false;
            if (this.isDevelop()) {
                this.model.modal.content = this.model.modal.content + '<br><div style="border: 2px solid red">' + e +'</div> ';
            }
            this.toggleModal({
                    id: 'error',
                    show: true,
                    redirect: undefined,
                    track: true,
                }
            );
            this.helper.sendErrorMailToTeam(e);
        }
    }

    /** Called by helper */
    public buildDescriptionForEmail(desc: string) {
        const addProp: Function = this.helper.addProp;
        const pack = this.model.choosePackage.selectedPackage;
        desc += '<h2>Person</h2>';
        const now: Date = new Date();
        desc += addProp('Email sendt', `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${(now.getMonth()+1)}-${now.getFullYear()}`);
        desc += addProp('Navn', this.model.contact_information.name);
        desc += addProp('Adresse', `${this.model.personInfo.address}`);
        desc += addProp('Stråtag', `${this.model.personInfo.thatchedRoof}`);
        desc += addProp('Alder', this.model.personInfo.customerAge);
        if (this.model.contact_information.customerNo) {
            desc += addProp('KundeNr', this.model.contact_information.customerNo);
        } else {
            desc += addProp('Cpr', this.model.contact_information.cpr);
        }

        desc += addProp('Antal personer i husstand', this.model.personInfo.familyMemberCount);
        desc += addProp('Børn', this.model.familyInfo.personCount);
        desc += addProp('Antal skader', this.model.additionalInfo.skader);
        desc += addProp('Kunde har accepteret betingelser', this.model.contact_information.accept ? 'Ja' : 'Nej');
        desc += addProp('Email', this.model.contact_information.email);
        desc += addProp('Telefonnummer', this.model.contact_information.phone);

        desc += this.helper.buildExistingProductsDescription();

        desc += '<h2>Forsikring</h2>';
        desc += addProp('Rki', this.model.additionalInfo.rki);
        desc += addProp('Gyldig hurtigst mulig', this.model.additionalInfo.validNow);
        if(this.model.additionalInfo.validNow === 'nej') {
            desc += addProp('Gyldig pr.', this.model.additionalInfo.validFromDate);
        }

        desc += '<h2>Ønsket forsikring</h2>';
        desc += addProp('Forsikringstype', pack.name);

        desc += '<br><b>Indbo</b><br>';
        desc += addProp('Selvrisiko', this.getExcessAmount());
        desc += addProp('Forsikret indbo sum', this.model.choosePackage.insuredValue);
        desc += addProp('Eksisterende forsikring', this.model.additionalInfo.existInsurance);

        if (this.model.additionalInfo.existInsurance === 'ja') {
            desc += addProp('Nuværende selskab', this.model.additionalInfo.existingInsurance);
        }

        desc += '<br><b>Børneulykke</b>';
        if (this.model.familyInfo.personCountId > 0) {
            desc += '<br><b>' + (this.model.familyInfo.personCountId + 1) + ' Børn</b><br>';
        } else {
            desc += '<br><b>' + (this.model.familyInfo.personCountId + 1) + ' Barn</b><br>';
        }

        for (let i= 0; i <= this.model.familyInfo.personCountId; i++) {
            desc += '<br><b>Barn ' + (i+1) + '</b><br>';
            desc += addProp('navn ', this.model.familyInfo['name' + i]);
            desc += addProp('alder ', this.model.familyInfo['age' + i] + ' år');
            desc += addProp('cpr ', this.model.familyInfo['cpr' + i]);
            if(this.model.familyInfo['workId' + i]) {
                desc += addProp('job ', this.model.familyInfo['work' + i]);
                desc += addProp('jobId ', this.model.familyInfo['workId' + i]);
            }

            desc += addProp('Eksisterende forsikring', this.model.familyInfo['existInsurance' + i]);
            if (this.model.familyInfo['existInsurance' + i] === 'ja') {
                desc += addProp('Nuværende selskab', this.model.familyInfo['existingInsurance' + i]);
            }
        }
        desc += addProp('<br>Betaling', this.model.choosePackage.monthYear === 'M' ? 'Månedlig' : 'Årlig');
        desc += addProp('Samlet pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += addProp('Beregningstype', 'FP_FP beregning indbo, Tia beregning ulykke');
        desc += addProp('Dækninger', this.helper.getCoverageNames(pack));


        desc += '<h2>Betaling</h2>';
        desc += addProp('Reg. nr.', this.model.payment.regNo);
        desc += addProp('Konto nr.', this.model.payment.kontoNo);

        const params: Map<string, string> = new Map();
        params.set('description', desc);
        return params;
    }

    public getExcessAmount(): string {
        return this.model.ownRisks.find(x => x.value === this.model.choosePackage.ownRiskId).displayValue;
    }

    public toggleModal(data: any = {}) {
        this.helper.toggleModal(data);
    }

    public gotoAddInsurances(step: string) {
        this.helper.gotoAddInsurances(step);
    }

}
