import Adapter from "./Adapter";
import { Claim, ClaimStatus } from "@/generated-api/customer-claims/api";
import InsurancesProductEnum from "../InsurancesProductEnum";
import { isNullOrUndefined } from '@/views/common/util';

export default class ClaimAdapter extends Adapter {

    private _claim: Claim;

    public constructor(claim: Claim) {
        super(claim.claimId + "", claim.policyLineSeqNo+"", claim.objectId);
        this._claim = claim;
    }

    get notificationTime(): string {
        return this.formatDate(this.claim.notificationTime+"");
    }

    get incidentTime(): string {
        return this.formatDate(this.claim.incidentTime + "");
    }

    get claim(): Claim {
        return this._claim;
    }

    get status(): string {
        let statusStr = "Ukendt"
        if (this.claim.statusCode === ClaimStatus.CLOSED) {
            statusStr = "Afsluttet";
        }
        else if (this.claim.statusCode === ClaimStatus.CLOSEDFALSELYOPENED) {
            statusStr = "Afsluttet";
        }
        else if (this.claim.statusCode === ClaimStatus.CLOSEDREJECTED) {
            statusStr = "Afsluttet";
        }
        else if (this.claim.statusCode === ClaimStatus.OPEN) {
            statusStr = "I gang";
        }
        else if (this.claim.statusCode === ClaimStatus.REOPEN) {
            statusStr = "I gang";
        }

        return statusStr;
    }

    get claimTitle(): string {
        const claimObjectDesc = this.claim.objectId;
        const policyDesc = this.claim.productTypeDescription;

        if (!isNullOrUndefined(claimObjectDesc) &&
            !isNullOrUndefined(this.claim.productTypeDescription)) {

            switch (this.claim.productTypeDescription.toLocaleLowerCase()) {
                case InsurancesProductEnum.BIL_FORSIKRING:
                case InsurancesProductEnum.INDBO_FORSIKRING:
                    return this.formatSpecialText(claimObjectDesc);
                default:
                    return policyDesc;
            }
        } else {
            return !isNullOrUndefined(claimObjectDesc) ? this.formatSpecialText(claimObjectDesc) : "Skade";
        }
    }

    private formatSpecialText(text: string): string {
        const licensePlate = new RegExp("(^[A-Z]{2}[0-9]{4,5})");

        if (licensePlate.test(text)) {
            return text.replace(/^(.{2})(.{2})(.{2,3})(.*)$/, "$1 $2 $3 $4");
        }

        return text;
    }

    get showStatusIcon(): boolean {
        return this.claim.statusCode === ClaimStatus.OPEN || this.claim.statusCode === ClaimStatus.REOPEN;
    }

    get incidentTimeMinusDot() {
        //remove dot so filtering on '8 jan' works not only '8. jan'
        return this.incidentTime.replace(/\./g, '');
    }

    get formattedLicensePlate() {
        return this.formatSpecialText(this.claim.objectId);
    }
}
