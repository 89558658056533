const setSortOrder = ((group: string, isBusiness?: boolean) => {
    isBusiness = false;

    if (isBusiness) {
        switch (group.toLowerCase()) {
            case "biler og køretøjer":
                return 0;
            case "medarbejdere":
                return 1;
            case "daglig drift":
                return 2;
            case "bygninger":
                return 3;
            default:
                return 4;
        }
    } else {
        switch (group.toLowerCase()) {
            case "biler og køretøjer":
                return 0;
            case "bolig":
                return 1;
            case "helbred og sundhed":
                return 2;
            case "familie og fritid":
                return 3;
            default:
                return 4;
        }
    }
});

export { setSortOrder };
