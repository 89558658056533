import { CardBlock } from '@/definitions/episerver/content-types';
import ContentItemCD from './ContentItemCd'

export default class CardBlockCd extends ContentItemCD {
    public model: CardBlock;

    constructor(model: CardBlock) {
        super();
        this.model = model;
    }

    public getStyles():any {
        let bgColor = {};
        this.model.backgroundColor ? bgColor = {
            'background-color' : this.model.backgroundColor
        } : null;

        let noBorder = {}
        this.model.noBorder ? noBorder = {
            'border' : 'none'
        } : null;

        let noBottomMargin = {}
        this.model.noBottomMargin ? noBottomMargin = {
            'margin-bottom' : '0'
        } : null;

        return Object.assign({}, bgColor, noBorder, noBottomMargin);
    }

}
