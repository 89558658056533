
import { mapState } from 'vuex';
import InputComponent from '../../components/InputComponent.vue';
import { PopoverComponent, SegmentedControlComponent } from '@/views/sharedcomponents/baseComponents/components';
import { CheckboxComponent, RadioComponent } from "@/almbrand/web-shared";
import { CardBlock } from '@/definitions/episerver/content-types';
import { jaNejArray, Validator, ValuePair } from '../../BuyInsuranceHelper';
import { BasketSettings } from '../../basket/BasketSettings';
import InsurancesProductEnum from '@/views/model/InsurancesProductEnum';
import FieldValidator from '../../FieldValidator';
import { BasketProductsEnum } from '@/episerver/store/modules/calculatorContext';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';

@Options({
    name: 'ContactInformationBasketStepComponent',
    components: {
        InputComponent,
        CheckboxComponent,
        RadioComponent,
        SegmentedControlComponent,
        PopoverComponent,
    },
    computed: mapState<any>({
        basketModel: state => state.calculatorContext.basket.model,
        basketCms: state => state.calculatorContext.basket.cms,
    }),
    props: {
        card: Object, // Card from cms
        isValid: Boolean, // wether card is complete
    }
})

export default class ContactInformationBasketStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // wether card is complete

    public basketModel!: any; // datastructure for user input (store)
    public basketCms!: BasketSettings; // settings from CMS (store)
    public fieldValidator: FieldValidator = Validator;
    public modelChildren: any = null;
    public modelAdultAccident: Array<any> = [];
    private hasCarInsurance = false;
    public noEmailCustomer: boolean = false;

    public jaNejArray: Array<ValuePair> = jaNejArray;
    public skaderArray: Array<ValuePair> =[{
        value: '0',
        displayValue: '0',
    },
    {
        value: '1',
        displayValue: '1',
    },
    {
        value: '2',
        displayValue: '2',
    },
    {
        value: '3+',
        displayValue: '3+',
    },
];


    public created() {

        window.addEventListener('keyup' , this.moveOn );

        const basketUnique = store.getters.getSelectableGroupUnique('basket');
        if (basketUnique.includes(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING)) {
            const calc = store.getters.getCalculator(BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0);
            if (calc) {
                this.modelChildren = calc.model;
            }

        } else if (basketUnique.includes(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING)) {
            const calc = store.getters.getCalculator(BasketProductsEnum.BOERNEULYKKES_FORSIKRING0);
            if (calc) {
                this.modelChildren = calc.model;
            }
        }

        if (basketUnique.includes(InsurancesProductEnum.BIL_FORSIKRING)) {
            this.hasCarInsurance = true;
        }

        if (!this.modelChildren?.readyForBasket) {
            this.modelChildren = undefined;
        }

        if (this.basketModel.contact_information.customerNo) {
            if(!this.fieldValidator.isValidEmail(this.basketModel.contact_information.email)) {
                this.noEmailCustomer = true;
            }
        }

        const basket = store.getters.getSelectableGroup('basket');
        basket.forEach(product => {
            if (product.startsWith(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
                const d =  store.getters.getCalculator(product)?.model;
                if (d?.readyForBasket) {
                    this.modelAdultAccident.push(d);
                }
            }
        });
    }

    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    public setSubHeader(evt) {
        if(evt.event.type === 'blur') {
            this.basketModel.contact_information.subtitle = evt.value;
        }
    }

    public get rkiHeader(): string {
        return this.hasCarInsurance ? this.basketCms.rkiHeaderDFIM : this.basketCms.rkiHeader;
    }

    public get rkiPopupTitle(): string {
        return this.hasCarInsurance ? this.basketCms.rkiPopupTitleDFIM : this.basketCms.rkiPopupTitle;
    }

    public get rkiPopover(): string {
        return this.hasCarInsurance ? this.basketCms.rkiPopoverDFIM : this.basketCms.rkiPopover;
    }

    public get rkiPopupContent(): string {
        return this.hasCarInsurance ? this.basketCms.rkiPopupContentDFIM : this.basketCms.rkiPopupContent;
    }

    public handleRkiClick(evt) {
        this.basketModel.contact_information.rki = evt.value;
        if(evt.value === 'ja' && this.rkiPopupTitle) {
            const popup = {
                title: this.rkiPopupTitle,
                content: this.rkiPopupContent,
                redirect: this.basketCms.rkiPopupRedirect,
                btnSecondLabel: this.basketCms.rkiPopupRedirect ? 'Afslut' : 'Ok',
                track: false,
                id: 'rki',
                show: true,
            }
            this.$emit('toggleModal', popup);
        }
    }

    public checkboxEvent(evt) {
        this.basketModel.contact_information.accept = evt.checked;
    }

    public get showEmail(): boolean {
        if(this.basketModel.contact_information.customerNo) {
            return this.noEmailCustomer;
        }
        return true;
    }

    public get hasAdultCpr(): boolean {
        const ok = this.modelAdultAccident.length > 1;
        if (!ok && this.modelAdultAccident.length === 1)  {
            return this.modelAdultAccident[0].personInfo.otherCustomerAge !== undefined;
        }
        return ok;
    }

    public isValidAdultCpr(inx) : string {
        return this.fieldValidator.isValidCpr(this.modelAdultAccident[inx].personInfo.otherCpr) ? 'input--valid' : undefined;
    }

    public isValidChildCpr(inx) : string {
        return this.fieldValidator.getAgeUnderEighteen(this.modelChildren.familyInfo['cpr' + (inx)]) !== -1 ? 'input--valid' : undefined;
    }

    public damagesEvent(evt) {
        this.basketModel.contact_information.skader = evt.value;
        if (this.basketCms.damagesPopupTitle && !this.basketCms.damagesOk.includes(evt.value)) {
            const popup = {
                title: this.basketCms.damagesPopupTitle,
                content: this.basketCms.damagesPopupContent,
                redirect: this.basketCms.damagesPopupRedirect,
                btnSecondLabel: this.basketCms.damagesPopupRedirect ? 'Afslut' : 'Ok',
                track: false,
                id: 'skader',
                show: true,
            }
            this.$emit('toggleModal', popup);
        }
    }

    public nextStep() {
        this.$emit('nextStep', this.card.routePath);
    }

}
