import AgreementStatus from '@/views//model/AgreementStatus';
import AbGtm from '@/views/common/ab_gtm';
import { CustomerAgreement } from '@/generated-api/customer-agreements';
import ProductlineHeader from '@/views/overview/insurances/productline/productlineheader';
import FlowHandler from './FlowHandler';
import CartHandler from './CartHandler';
import { CatalogProduct } from '@/generated-api/catalog';
import { Customer } from '@/generated-api/customer-details';
import { CustomerAgreementSummaryList } from "@/generated-api/customer-agreements";
import UserData from '@/store/modules/userData';
import store from '@/store';
import FeatureAdapter from '@/views/model/adapter/FeatureAdapter';
import { cloneDeep } from "lodash";
import Utils from '../providers/Utils';
import { UrlUtil } from "@/episerver/api/urlUtil";
import { App } from "@/views/sharedcomponents/lib/services/app";
import OrderflowBlockCd from '@/views/contentApi/contentTypes/OrderflowBlockCd';
import OrderflowContainerBlockCd from '@/views/contentApi/contentTypes/OrderflowContainerBlockCd';
import UsabillaInPageBlockComponent from '@/views/contentApi/components/UsabillaInPageBlockComponent';
import InsurancesProductEnum from '../model/InsurancesProductEnum';
import CustomerEmailHandler from './CustomerEmailHandler';
export default class OrderflowHandler {
    private productlineHeader: ProductlineHeader;
    private agreement: CustomerAgreement;
    private orderflowContainerBlocksLoaded: boolean;
    private orderflowBlocks: Map<string, OrderflowBlockCd> = new Map();

    private productName: string;
    private productNameLower: string;
    private catalogProduct: CatalogProduct;
    private expiresSoon: boolean = false;
    private abGtm: AbGtm = new AbGtm();
    private hasCheckedOut: boolean = false;
    private utils: Utils = new Utils();
    private productVersion: string;

    constructor (productlineHeader: ProductlineHeader) {
        // always remove possible "ignored carts"
        CartHandler.removeCart();

        this.productlineHeader = productlineHeader;

        this.productName = productlineHeader.title;
        this.productNameLower = productlineHeader.title.toLocaleLowerCase().trim();
        this.agreement = productlineHeader.productDetails.agreement;

        const end_date = new Date(this.agreement.end_date);

        const now = new Date();
        const dayDiff = this.productlineHeader.getDateDifferenceInDays(now, end_date);
        
        this.expiresSoon = dayDiff < 35; // 5 uger
        // TODO KLK Test
        // if (UrlUtil.isDevelop()) {
        //     this.utils.log('Tilvalg', 'TODO KLK expiresSoon disabled in test');
        //     this.expiresSoon = false;
        // }
        if(this.expiresSoon) {
            this.utils.log('Tilvalg', 'expiresSoon', this.expiresSoon, 'diff in days', dayDiff);
        }
    }

    private async setUserDataWhenMobile() {
        if (!store.getters.getUserData.init) {
            // running in app
            // setUserdata since it might not have been initialized

            return await this.productlineHeader.getCustomer().then( (customer: Customer) => {
                const userData: UserData = store.getters.getUserData;
                userData.setCanChangeOnline(customer);
                store.dispatch('setUserData', userData);
                return Promise.resolve();
            });
        }
        return Promise.resolve();
    }
    public async handleOrderflow() {
        // userdata is not set when running in App
        await this.setUserDataWhenMobile();
        if (!this.orderflowContainerBlocksLoaded) {
            this.orderflowContainerBlocksLoaded = true;

            const productList = await this.productlineHeader.getProductList()
            this.catalogProduct = productList.products.find( (catalogProduct) => {
                return catalogProduct.product_id === this.agreement.product_instance.product_id;
            });
            
            if (this.catalogProduct !== undefined) {
                this.productVersion = this.catalogProduct.code.substring(this.catalogProduct.code.lastIndexOf('-') + 1, this.catalogProduct.code.length)
            }
            const product = await this.productlineHeader.getCustomerAgreementById(
                this.productlineHeader.productId
            );
            const optionalFeatures = [];
            product.product_instance.price.optional_features.forEach( feature => {
                    optionalFeatures.push(feature.name);
                }
            );
                
            const onlineFlowPossible: boolean = this.isOnlineFlow();
            const orderflowContainerBlocks = this.productlineHeader.orderflowContainerBlocks;

            if (orderflowContainerBlocks.length > 0) {
                orderflowContainerBlocks.forEach( (orderflowContainerBlock) => {
                    // console.log('orderflowContainerBlock', orderflowContainerBlock);

                    if (orderflowContainerBlock.orderflowId !== 'Dækning område') { // 4 = dækning europa_verden
                        // orderflowContainerBlock.setup(this.productlineHeader.abAmount);
                        const tilvalg: boolean = optionalFeatures.includes(orderflowContainerBlock.orderflowId);
                        orderflowContainerBlock.getOrderflowBySubType(tilvalg, onlineFlowPossible).then( (orderflowBlock) => {
                            this.initOrderflow(tilvalg, orderflowBlock, onlineFlowPossible);
                        });
                    }
                });
            }
        }
    }

    private initOrderflow(
        tilvalg: boolean,
        orderflowBlock: OrderflowBlockCd,
        onlineFlowPossible: boolean
    ) {
        if (orderflowBlock) {
            const featureId = this.getFeatureId(orderflowBlock.orderflowId);
            if (featureId) {
                this.orderflowBlocks.set(orderflowBlock.orderflowId, orderflowBlock);
                this.updateParentCoverages(onlineFlowPossible, orderflowBlock.orderflowId, tilvalg, featureId);
            }
        }
    }
    private isOnlineFlow(): boolean {
        const canChangeOnline = store.getters.getUserData.canChangeOnline;
        // console.log('onlinePossible', canChangeOnline , this.agreement.changeable , !this.expiresSoon , (this.productlineHeader.status === AgreementStatus.IKRAFT));
        return canChangeOnline && this.agreement.changeable && !this.expiresSoon && this.productlineHeader.status === AgreementStatus.IKRAFT ;
    }

    private getFeatureId(orderflowId: string): string {
        try {
            const orderflowFeature = this.catalogProduct.features.find( (feature) => {
                return orderflowId.toLocaleLowerCase() === feature.title?.toLocaleLowerCase();
            });

            const featureId = orderflowFeature.code.substring(orderflowFeature.code.lastIndexOf('-') + 1, orderflowFeature.code.length);
            if(featureId === '') {
                throw 'featureId empty string';
            }
            return featureId;
        } catch (err) {
            this.utils.log('Coverages',
                this.productName,
                " ver. "+ this.productVersion + ", could not find featureId for:",
                orderflowId
            );
            return undefined;
        }
    }

    private updateParentCoverages(onlineFlowPossible: boolean, title: string = 'Forsikring', tilvalg: boolean, featureId?: string) {
        this.productlineHeader.$emit('updateCoverages', {
            includedCoverage: !tilvalg,
            data: {
                title,
                selected: false,
                hasAction: true,
                changeFunction: this.openOrderFlow(onlineFlowPossible, title, featureId),
            }
        });
    }

    public openOrderFlow(
        onlineFlowPossible: boolean,
        title: string,
        featureId?: string
    ) {
        const self = this;
        return () => {
            this.productlineHeader.showSpinner = true;
            const orderflowBlock = self.orderflowBlocks.get(title);
            const isDependentOnOtherCoverage = this.isDependantOnOtherCoverage(featureId, orderflowBlock.model.tilvalg);
            if (isDependentOnOtherCoverage) {
                this.useGlobalOrderflow(orderflowBlock,title, featureId);
                return;
            }
            if (onlineFlowPossible && orderflowBlock.model.onlineFlow && !isDependentOnOtherCoverage) {
                // use default sum if any
                let optionalValue: any;
                const translate: any = {
                    insuranceName: this.utils.capitalizeFirstLetter(this.productName),
                    orderflowName: title,
                    expireDate: this.productlineHeader.formatDate(this.agreement.end_date),
                }

                if (orderflowBlock.model.tilvalg) {
                    const featureAdaptor = this.findCoverage(orderflowBlock.orderflowId, orderflowBlock.model.tilvalg);
                    optionalValue = featureAdaptor.selectedOptionalProperty;
                    if (optionalValue) {
                        translate.showSum = 'Du er dækket for op til ' + this.productlineHeader.abAmount.formatRoundInt(parseInt(featureAdaptor.selectedOptionalProperty)) + ' kr.';
                    }
                }
                orderflowBlock.translateTemplate(translate);
                orderflowBlock.addPriceCoverageToServer(this.productlineHeader.$route.query.id as string, featureId, orderflowBlock.model.tilvalg, this.productlineHeader.productDetails.price, translate, this.productlineHeader, optionalValue)
                .then( () => {
                    this.createOrderflow(title, true, false, featureId);
                    this.productlineHeader.showSpinner = false;
                }, (error) => {
                    console.error('FAIL', error);
                    this.useGlobalOrderflow(orderflowBlock, title, featureId);
                });

            } else {
                // manual orderflow
                const translate = {
                    insuranceName: this.utils.capitalizeFirstLetter(this.productName),
                    orderflowName: orderflowBlock.orderflowId,
                }
                orderflowBlock.translateTemplate(translate);

                this.createOrderflow(title, false, false, featureId);
                this.productlineHeader.showSpinner = false;
            }
        };
    }

    private useGlobalOrderflow(orderflowBlock, title, featureId) {
        const cancel = this.cancelCallBack();
        cancel();

        // manual orderflow
        const orderflowAlternate = OrderflowContainerBlockCd.getGlobalOrderFlow(orderflowBlock.model.tilvalg, false, title);
        const translate = {
            insuranceName: this.utils.capitalizeFirstLetter(this.productName),
            orderflowName: title,
        }
        orderflowAlternate.translateTemplate(translate);
        // replace online flow
        this.orderflowBlocks.set(orderflowBlock.orderflowId, orderflowAlternate);
        this.createOrderflow(title, false, false, featureId);
        this.productlineHeader.showSpinner = false;

    }

    private isDependantOnOtherCoverage(featureId: string, tilvalg: boolean) {
        if (tilvalg && this.productNameLower === InsurancesProductEnum.BIL_FORSIKRING) {
            if ((featureId === '63' || featureId === '47' || featureId === '51' ) && !store.getters.getUserData.bilKasko) { //'Påkørsel af dyr' ,  'Udvidet glas', 'Afleveringsforsikring - leasing'
                //kræver kasko
                return true;
            }
        }
        return false;
    }

    private createOrderflow(title: string, online: boolean, failFlow: boolean = false, featureId: string) {
        const orderflowBlock: OrderflowBlockCd = failFlow ? this.orderflowBlocks.get(title + '_fail') : this.orderflowBlocks.get(title);
        if (orderflowBlock) {
            const tilvalgFravalg: string = orderflowBlock.model.tilvalg ? 'tilvalg' : 'fravalg';
            const orderflowSteps = orderflowBlock.orderflowSteps;
            const flowHandler = FlowHandler.createFlowHandlerContent(orderflowBlock.orderflowSteps);
            flowHandler.productTitle = this.productName;
            flowHandler.closeCallBack = this.closeCallBackCoverage(online);
            const includePrices: boolean = orderflowBlock.hasPrices();
            let submitCallBack: Function = this.submitWithoutPrices(orderflowBlock.model.tilvalg, title);
            const featureAdaptor = this.findCoverage(orderflowBlock.orderflowId, orderflowBlock.model.tilvalg);

            if (includePrices) {
                // const agreementId = this.productlineHeader.agreementId + '';
                submitCallBack = this.submitWithPrices(orderflowBlock.orderflowId);
            }

            flowHandler.orderFlowList.forEach( (step, inx) => {
                // add submitCallBack to submit step
                if (orderflowSteps[inx].model.isSubmit) {
                    // step.submitCallBack = submitCallBack;
                    orderflowSteps[inx].submitCallBack = submitCallBack;
                }
                // add cancelfunction to all but last step (reciept)
                if (inx < flowHandler.orderFlowList.length - 1) {
                    // step.cancelCallBack = this.cancelCallBack(flowHandler);
                    orderflowSteps[inx].cancelCallBack = this.cancelCallBack(flowHandler);
                }
            if (orderflowSteps[inx].model.trackCode && !failFlow) {
                    step.track = this.track(title, online, orderflowSteps[inx].allTrackCodes, tilvalgFravalg);
                    //Hvis trackcode === advise, hent indhold fra dækning
                    if (orderflowSteps[inx].model.trackCode === 'advice') {
                        step.featureAdapter = cloneDeep(featureAdaptor);
                        step.featureAdapter.hasAction = false;
                        // step.featureAdapter.selected = true;

                        // TEST hent undedækninger fra Rejseforsikring basis
                        // const fake = this.findCoverage('Rejseforsikring basis', orderflowBlock.tilvalg);
                        // const copy = cloneDeep(fake);
                        // step.featureAdapter.feature.sub_features = copy.feature.sub_features;
                        // console.log(step.featureAdapter);
                    }
                    if (online && orderflowSteps[inx].model.trackCode === 'show' && orderflowBlock.model.tilvalg && orderflowBlock.model.userCanSelectSums) {
                        // summer der kan vælges
                        if(featureAdaptor.optionalProperties && featureAdaptor.optionalProperties.length > 0) {
                            orderflowSteps[inx].optionalProperties = []; // reset
                            orderflowSteps[inx].selectedOptionalProperty = undefined; // reset
                            featureAdaptor.optionalProperties.forEach((amount) => {
                                orderflowSteps[inx].optionalProperties.push( {
                                    value: amount,
                                    displayValue: this.productlineHeader.formatPrice(parseInt(amount)) + ' kr.'
                                });
                            });

                            if (featureAdaptor.selectedOptionalProperty) {
                                orderflowSteps[inx].selectedOptionalProperty = featureAdaptor.selectedOptionalProperty;
                            }

                            orderflowSteps[inx].submitSelectCallBack = this.selectOptionalProperty(orderflowBlock, featureId, flowHandler);

                        }
                    }
                }

            });

            this.productlineHeader.$emit("openOrderflow", {
                flowHandler
            });
            this.resetProductCart(flowHandler);
        }
    }

    private selectOptionalProperty(orderflowBlock: OrderflowBlockCd, featureId: string, flowHandler: FlowHandler): Function {
        return async (selectedValue: string) => {
            try {

                this.productlineHeader.showSpinner = true;
                const updated = await orderflowBlock.addPriceCoverageToServer(this.productlineHeader.$route.query.id as string, featureId, orderflowBlock.model.tilvalg, this.productlineHeader.productDetails.price, undefined, this.productlineHeader, selectedValue);
                this.productlineHeader.showSpinner = false;
                return updated;
            } catch(err) {
                flowHandler.reset();
                await CartHandler.removeCart();
                // manual orderflow
                this.createErrorFlow(orderflowBlock.orderflowId);
                this.productlineHeader.showSpinner = false;
                return Promise.reject();
            }
        }
    }

    private resetProductCart(flowHandler?: FlowHandler) {
        if (flowHandler) {
            flowHandler.reset();
        }
    }

    private track(orderflowId: string, onlineFlow: boolean, action: string, tilvalgFravalg: string): Function {
        return () => {
            this.abGtm.triggerCustomGtmEvent({
                'event': 'track-vp',
                'virtualPath': `/mitalmbrand/forsikring/mineforsikringer/${this.productName}/${orderflowId}/${tilvalgFravalg}/${onlineFlow ? 'online' : 'manual'}/${action}`
                });
        }
    }

    private closeCallBackCoverage(update: boolean): Function {
        if(!update) {
            return () => {}
        }

        return () => {
            // always remove possible "ignored carts"
            CartHandler.removeCart();

            if (!this.hasCheckedOut) {
                // customer has not accepted buying coverage - we're done
                return;
            }
            this.productlineHeader.showSpinner = true;
            this.hasCheckedOut = false; // reset
            this.productlineHeader.getAgreementList().then(customerAgreementList => {
                this.productlineHeader.$emit("closeOrderflow");
                this.productlineHeader.showSpinner = false;
                this.handleRoutingNewPolicy();
            }).catch(err => {
                // clean agreement cache
                // this will force reload of agreementlist and product
                store.dispatch('setAgreementList', undefined);
                store.dispatch('setCustomerAgreement', undefined);
                const url = UrlUtil.ensureCorrectLink(`/forsikring/mineforsikringer`); // fallback
                this.productlineHeader.$router.replace(url); // clear from history
                this.productlineHeader.showSpinner = false;
            });
        }
    }

    private cancelCallBack(flowHandler?: FlowHandler): Function {
        return () => {
            this.resetProductCart(flowHandler);
            UsabillaInPageBlockComponent.reloadUsabillas();
        }
    }

    private submitWithPrices(orderflowId: string): Function {
        return async () => {
            try {
                const checkout = await CartHandler.checkoutCart(this.productlineHeader, this.productName, orderflowId);
                this.hasCheckedOut = true;
                store.dispatch('setLastUserUpdatedAgreementId', this.productlineHeader.agreementId);
                this.refreshCacheForProductAndProductList();
                return checkout;
            } catch(err) {
                console.error(err);
                await CartHandler.removeCart();
                // manual orderflow
                this.createErrorFlow(orderflowId);
                return Promise.reject();
            }
        }
    }

    private createErrorFlow(orderflowId: string) {
        const orderflowBlock: OrderflowBlockCd = this.orderflowBlocks.get(orderflowId);
        const orderflowAlternate = OrderflowContainerBlockCd.getGlobalOrderFlow(orderflowBlock.model.tilvalg, false, orderflowId);
        orderflowAlternate.useErrorTexts(0);

        const translate = {
            insuranceName: this.utils.capitalizeFirstLetter(this.productName),
            orderflowName: orderflowId,
        }
        orderflowAlternate.translateTemplate(translate);

        // add error flow
        this.orderflowBlocks.set(orderflowBlock.orderflowId + '_fail', orderflowAlternate);
        this.createOrderflow(orderflowId, false, true, undefined);
        const tilvalgFravalg: string = orderflowBlock.model.tilvalg ? 'tilvalg' : 'fravalg';
        const track = this.track(orderflowId, true, 'fail', tilvalgFravalg);
        track();
    }

    private submitWithoutPrices(tilvalg: boolean, title: string): Function {
        return async (params: Map<string, string>) => {
            const tilvalgFravalg =  tilvalg ? 'tilvalg af ' + title : 'fravalg af ' + title;
            params.set('policyLine', '' + this.productlineHeader.productId);
            params.set('policyNo', '' + this.productlineHeader.agreementId);
            params.set('subject', 'Anmodning om ' + tilvalgFravalg + ' på ' + this.productlineHeader.title);

            const customer = await this.productlineHeader.getCustomer();
            FlowHandler.setUserDetailsForMail(params, customer);
            await this.productlineHeader.sendMailToOss(params);
            return Promise.resolve();
        }
    }

    private findCoverage(orderflowId: string, tilvalg: boolean): FeatureAdapter {
        const coverageList = tilvalg ? this.productlineHeader.productline.productlineMissingList :
                                this.productlineHeader.productline.productlineIncludedList;
        const featureAdapter = coverageList.find( (feature: FeatureAdapter) => feature.title === orderflowId);
        return featureAdapter;
    }

    private async refreshCacheForProductAndProductList(): Promise<any> {
        // fecht new agreement(s) in background
        await store.dispatch('setAgreementList', undefined);
        await store.dispatch('setCustomerAgreement', undefined);
        await this.productlineHeader.getAgreementList();
    }

    private handleRoutingNewPolicy() {
        store.dispatch('setAppReplaceRoute', true);
        this.productlineHeader.doRoute(`/forsikring/mineforsikringer`);
    }

}
