import { SelfserviceMyInsurancesBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd'

export default class SelfserviceMyInsurancesBlockCd extends ContentItemCd {
    public model: SelfserviceMyInsurancesBlock;
    public homeTitle: string;
    public homeGroup: Array<string> = [];
    public healthTitle: string;
    public healthGroup: Array<string> = [];
    public familyTitle: string;
    public familyGroup: Array<string> = [];
    public carsTitle: string;
    public carsGroup: Array<string> = [];
    public otherTitle: string;
    public productsOrdered: Array<String> = [];

    constructor(model: SelfserviceMyInsurancesBlock) {
        super();
        this.model = model;
        this.init();
    }

    public init(): void {
        this.carsTitle = this.model.carsTitle;
        this.carsGroup = this.model.carsGroup.trim() as any;
        this.addArray(this.model.carsGroup);

        this.homeTitle = this.model.homeTitle;
        this.homeGroup = this.model.homeGroup.trim() as any;
        this.addArray(this.model.homeGroup);

        this.familyTitle = this.model.familyTitle;
        this.familyGroup = this.model.familyGroup.trim() as any;
        this.addArray(this.model.familyGroup);

        this.healthTitle = this.model.healthTitle;
        this.healthGroup = this.model.healthGroup.trim() as any;
        this.addArray(this.model.healthGroup);

        this.otherTitle = this.model.otherTitle;

    }

    // clean CMS user input
    private addArray(stringArray: string) {
        stringArray = stringArray.trim();
        if (stringArray !== '') {
            if(stringArray.includes(',')) {
                let arr = stringArray.split(',');
                arr = arr.map( product => product.trim()).filter( product => product != '');
                this.productsOrdered.push(...arr);
            } else {
                this.productsOrdered.push(stringArray);
            }
        }
    }

    public sortProducts(allAgreements: Array<any>): Array<any> {
        const sortedAgreementGroupedList: Array<any> = [];
        let agreements;
        // order products in present groups
        this.productsOrdered.forEach(product => {
            agreements = allAgreements.filter( agreement_ => agreement_.agreement.agreement.tags.indexOf(product) > -1);
            agreements.length > 0 ? sortedAgreementGroupedList.push(...agreements): null;
        });

        // add none mapped products (Andet)
        agreements = allAgreements.filter( agreement_ => agreement_.groupTitle === this.otherTitle);
        agreements.length > 0 ? sortedAgreementGroupedList.push(...agreements): null;
        return sortedAgreementGroupedList;
    }


}
