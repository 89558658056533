import { mapState } from 'vuex';
import InputComponent from '../../components/InputComponent.vue';
import { PopoverComponent } from '@/views/sharedcomponents/baseComponents/components';
import { RadioComponent } from "@/almbrand/web-shared";
import AbGtm from '@/views/common/ab_gtm';
import { CardBlock } from '@/definitions/episerver/content-types';
import { BuyInsuranceHelper, Formatter, monthYearArray, Validator, ValuePair } from '../../BuyInsuranceHelper';
import { BasketSettings } from '../../basket/BasketSettings';
import FieldValidator from '../../FieldValidator';
import UrlHandler from '../../UrlHandler';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';

@Options({
    name: 'PaymentBasketStepComponent',
    components: {
        PopoverComponent,
        InputComponent,
        RadioComponent
    },
    computed: mapState<any>({
        basketModel: state => state.calculatorContext.basket.model,
        basketCms: state => state.calculatorContext.basket.cms,
    }),
    props: {
        card: Object, // Card from cms
        isValid: Boolean, // wether card is complete
    }
})

export default class PaymentBasketStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // wether card is complete

    public basketModel!: any; // datastructure for user input (store)
    public basketCms!: BasketSettings; // settings from CMS (store)
    private abGtm: AbGtm = new AbGtm();
    public monthYearArray: Array<ValuePair> = monthYearArray;

    public monthlyPrice: number = 0;
    public yearlyPrice: number = 0;
    public monthlyDisplayPrice: string = null;
    public yearlyDisplayPrice: string = null;
    public missingCalculations: string = null;
    public fieldValidator: FieldValidator = Validator;

    public productNames : Array<string> = [];
    public oneSubmit = false;

    public async created() {
        this.missingCalculations = '';
        this.productNames = await store.getters.getSelectableGroup('basket');
        this.monthlyPrice = this.getMonthlyTotalPrice(true);
        this.yearlyPrice = this.getMonthlyTotalPrice(false);
        if(this.monthlyPrice === 0) {
            this.monthlyDisplayPrice = 'Månedlig betaling';
            this.yearlyDisplayPrice = 'Årlig betaling';
        } else {
            this.monthlyDisplayPrice = Formatter.format(this.monthlyPrice) + ' kr./md';
            this.yearlyDisplayPrice = Formatter.format(this.yearlyPrice) + ' kr./år';
        }
        window.addEventListener('keyup' , this.moveOn );

    }

    public chooseMonthYear(evt) {
        this.basketModel.choosePackage.monthYear = evt.value;
        this.abGtm.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.basketModel.productName}/betaling/${evt.value === 'M' ? 'monthly' : 'yearly'}`
        });
    }
    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    public getYearlySavingsTxt() {
        const amount = (this.monthlyPrice * 12) - this.yearlyPrice;
        const res = Formatter.format(amount) + ' kr.';
        return this.basketCms.yearlySavings ? this.basketCms.yearlySavings.replace('¤yearlySaving', res) : '';
    }

    private getMonthlyTotalPrice(monthly: boolean): number {
        let totalPrice: number = 0;
        this.productNames.forEach(productName => {
            const calculator = store.getters.getCalculator(productName);
            if(calculator && calculator?.model?.readyForBasket) {
                if(monthly) {
                    totalPrice += calculator.model.choosePackage.selectedPackage.totalPrice;
                } else {
                    totalPrice += calculator.model.choosePackage.selectedPackage.yearlyPriceTotal;
                }
            } else {
                this.missingCalculations = 'Nogle forsikringer i din kurv indgår ikke i prisen';
            }
        });
        return totalPrice;
    }

    public nextStep() {
        if (this.isValid && !this.oneSubmit) {
            this.oneSubmit = true;
            this.$emit('nextStep', this.card.routePath);
        }

    }
}
