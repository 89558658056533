import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "calculators vertical-flow" }
const _hoisted_2 = {
  key: 1,
  class: "row no-gutters"
}
const _hoisted_3 = { class: "calculators badge" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["onClick", "data-gtm", "data-gtm-click-text"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "vertical-flow__form__step__text" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "vertical-flow__form__step__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalComponentCalculator = _resolveComponent("ModalComponentCalculator")!
  const _component_CampaignStepComponent = _resolveComponent("CampaignStepComponent")!
  const _component_OverviewBasketStepComponent = _resolveComponent("OverviewBasketStepComponent")!
  const _component_ContactInformationBasketStepComponent = _resolveComponent("ContactInformationBasketStepComponent")!
  const _component_PaymentBasketStepComponent = _resolveComponent("PaymentBasketStepComponent")!
  const _component_RecieptStepComponent = _resolveComponent("RecieptStepComponent")!
  const _component_usabillaInPageBlockComponent = _resolveComponent("usabillaInPageBlockComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.model?.showSpinner)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(['spinner-full-page', { 'spinner-full-page__subtext' : _ctx.model?.calculating}])
        }, null, 2))
      : _createCommentVNode("", true),
    _createVNode(_component_ModalComponentCalculator, {
      useBasketModel: true,
      onToggleModal: _ctx.toggleModal
    }, null, 8, ["onToggleModal"]),
    (_ctx.model?.showHeaderBadge && _ctx.componentInit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              innerHTML: _ctx.headerBadgeTxt,
              class: "calculator-badge"
            }, null, 8, _hoisted_4)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.componentInit && !_ctx.model?.showReciept && !_ctx.checkout)
      ? (_openBlock(), _createBlock(_TransitionGroup, {
          key: _ctx.model.updateAll,
          name: "fade-in-up"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentBlocks, (card, inx) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'step' + inx
              }, [
                _createElementVNode("div", {
                  id: card.name,
                  class: _normalizeClass(['vertical-flow__form__step',
                            _ctx.isActiveCard(card.name) ? 'vertical-flow__form__step--active' : 'vertical-flow__form__step--inactive',
                            _ctx.isValid(card.name) && !_ctx.isActiveCard(card.name)? 'vertical-flow__form__step--validated' : '']),
                  style: _normalizeStyle('--i:' + inx)
                }, [
                  _createElementVNode("div", {
                    class: "vertical-flow__form__step__title",
                    onClick: ($event: any) => (_ctx.nextStep(card.name)),
                    "data-gtm": _ctx.cms.dataGtm,
                    "data-gtm-click-text": card.name
                  }, [
                    _createElementVNode("div", {
                      class: "vertical-flow__form__step__number",
                      textContent: _toDisplayString((inx + 1))
                    }, null, 8, _hoisted_7),
                    _createElementVNode("div", _hoisted_8, [
                      (card.title)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "main",
                            innerHTML: card.title
                          }, null, 8, _hoisted_9))
                        : _createCommentVNode("", true),
                      (_ctx.getSubtitle(card.name) && !_ctx.isActiveCard(card.name) || _ctx.getSubtitle(card.name))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "validation-message",
                            innerHTML: _ctx.getSubtitle(card.name)
                          }, null, 8, _hoisted_10))
                        : _createCommentVNode("", true)
                    ])
                  ], 8, _hoisted_6),
                  _createVNode(_Transition, {
                    name: "expand",
                    onAfterEnter: _ctx.transitionExpand.afterEnter,
                    onEnter: _ctx.transitionExpand.enter,
                    onLeave: _ctx.transitionExpand.leave
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isActiveCard(card.name))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(['vertical-flow__form__step__accordion', card.textCenter ? 'text-center' : ''])
                          }, [
                            _createElementVNode("div", _hoisted_11, [
                              (card.name === _ctx.model.campaign.ID)
                                ? (_openBlock(), _createBlock(_component_CampaignStepComponent, {
                                    key: 0,
                                    card: card,
                                    onNextStep: _ctx.nextStep
                                  }, null, 8, ["card", "onNextStep"]))
                                : (card.name === _ctx.steps[0])
                                  ? (_openBlock(), _createBlock(_component_OverviewBasketStepComponent, {
                                      key: 1,
                                      card: card,
                                      isValid: _ctx.isValid(card.name),
                                      onBasketPrice: _ctx.handlePrice,
                                      onNextStep: _ctx.nextStep
                                    }, null, 8, ["card", "isValid", "onBasketPrice", "onNextStep"]))
                                  : (card.name === _ctx.steps[1])
                                    ? (_openBlock(), _createBlock(_component_ContactInformationBasketStepComponent, {
                                        key: 2,
                                        card: card,
                                        isValid: _ctx.isValid(card.name),
                                        onToggleModal: _ctx.toggleModal,
                                        onNextStep: _ctx.nextStep
                                      }, null, 8, ["card", "isValid", "onToggleModal", "onNextStep"]))
                                    : (card.name === _ctx.steps[2])
                                      ? (_openBlock(), _createBlock(_component_PaymentBasketStepComponent, {
                                          key: 3,
                                          card: card,
                                          isValid: _ctx.isValid(card.name),
                                          onNextStep: _ctx.nextStep
                                        }, null, 8, ["card", "isValid", "onNextStep"]))
                                      : _createCommentVNode("", true)
                            ])
                          ], 2))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["onAfterEnter", "onEnter", "onLeave"])
                ], 14, _hoisted_5)
              ]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.componentInit && _ctx.cardReciept && (_ctx.model?.showReciept || _ctx.checkout))
      ? (_openBlock(), _createBlock(_component_RecieptStepComponent, {
          key: 3,
          card: _ctx.cardReciept,
          usabillaBlock: _ctx.usabillaBlock
        }, null, 8, ["card", "usabillaBlock"]))
      : _createCommentVNode("", true),
    (_ctx.componentInit && _ctx.usabillaBlockLeaveIntent && !_ctx.cardReciept && !_ctx.model?.showReciept && !_ctx.checkout)
      ? (_openBlock(), _createBlock(_component_usabillaInPageBlockComponent, {
          key: 4,
          style: {"display":"none"},
          model: _ctx.usabillaBlockLeaveIntent
        }, null, 8, ["model"]))
      : _createCommentVNode("", true)
  ]))
}