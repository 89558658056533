import { mixins, Options } from 'vue-class-component';
import { PropType, watch } from 'vue'
import { mapState } from 'vuex';
import InsurancesCommon from '@/views/model/InsurancesCommon';
import { HtmlBlock, CardBlock } from '@/definitions/episerver/content-types';
import UsabillaInPageBlockComponent from '@/views/contentApi/components/UsabillaInPageBlockComponent/UsabillaInPageBlock.vue';
// import '@trevoreyre/autocomplete-vue/dist/style.css'
import { DogSettings } from './DogSettings';
import { Model, STEPS } from'./Model';
import DogCalculator from './DogCalculator';
import ChoosePackageStepComponent from '../steps/common/ChoosePackageStepComponent.vue';
import ContactInformationStepComponent from '../steps/common/ContactInformationStepComponent.vue';
import OverviewStepComponent from '../steps/common/OverviewStepComponent.vue';
import AdditionalInfoStepComponent from '../steps/common/AdditionalInfoStepComponent.vue';
import AdditionalQuestionsInfoStepComponent from '../steps/dog/AdditionalQuestionsInfoStepComponent.vue';
import DogInfoStepComponent from '../steps/dog/DogInfoStepComponent.vue';
import PersonInfoStepComponent from '../steps/dog/PersonInfoStepComponent.vue';
import PaymentStepComponent from '../steps/common/PaymentStepComponent.vue';
import RecieptStepComponent from '../steps/common/RecieptStepComponent.vue';
import { BuyInsuranceHelper, STEP, Validator } from '../BuyInsuranceHelper';
import ModalComponentCalculator from '../components/ModalComponentCalculator.vue';
import { RESET_CALCULATORS, CALCULATOR_LOADED, INIT_CALCULATOR } from '@/episerver/store/modules/calculatorContext';
import CampaignStepComponent from '../steps/common/CampaignStepComponent.vue';
import { cloneDeep } from "lodash";
import { EnvironmentService } from '@/views/sharedcomponents/lib/services/environmentService';
import { UrlUtil } from '@/episerver/api/urlUtil';
import store from '@/store';
import TransitionExpand from '../components/TransitionExpand';
@Options({
    name: 'selfserviceBuyInsuranceDogComponent',
    components: {
        // TransitionExpand,
        ModalComponentCalculator,
        UsabillaInPageBlockComponent,
        ChoosePackageStepComponent,
        OverviewStepComponent,
        AdditionalInfoStepComponent,
        AdditionalQuestionsInfoStepComponent,
        DogInfoStepComponent,
        PersonInfoStepComponent,
        ContactInformationStepComponent,
        PaymentStepComponent,
        RecieptStepComponent,
        CampaignStepComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
    page () {
        return this.$route.query.page || 1
    },
    props: {
        calculatorInfoBlock: Object as PropType< Array<HtmlBlock>>,
        contentBlocks: Array,
        cardReciept: Object,
        settingsBlock: Object,
        usabillaBlock: UsabillaInPageBlockComponent,
        usabillaBlockLeaveIntent: UsabillaInPageBlockComponent,
        contentUrl: String, // Used by helper to handle browser history
        headerBadgeTxt: String,
    },
    watch: {
        page () {
            console.log('!!!!!!!!watcher');
            this.helper.onUrlChange(this.$route);
            // call backend
        }
    },
})
// // <script setup>
// // import { ref, watch } from 'vue'

// // const question = ref('')
// // const answer = ref('Questions usually contain a question mark. ;-)')

// // watch works directly on a ref
// watch(question, async (newQuestion, oldQuestion) => {
//   if (newQuestion.indexOf('?') > -1) {
//     answer.value = 'Thinking...'
//     try {
//       const res = await fetch('https://yesno.wtf/api')
//       answer.value = (await res.json()).answer
//     } catch (error) {
//       answer.value = 'Error! Could not reach the API. ' + error
//     }
//   }
// })
export default class BuyDog extends mixins(InsurancesCommon) {
    contentBlocks: Array<CardBlock>;
    cardReciept: CardBlock;
    settingsBlock: HtmlBlock;
    usabillaBlock: any;//UsabillaInPageBlockComponent;
    usabillaBlockLeaveIntent: any; //UsabillaInPageBlockComponent;
    contentUrl: string; // Used by helper to handle browser history
    headerBadgeTxt: string;
    calculatorInfoBlock: HtmlBlock;

    // list and order of steps
    public steps : Array<string> = STEPS

    public model!: any; // model from store
    public cms!: DogSettings; // setting from store
    public helper: BuyInsuranceHelper;
    public calculator: DogCalculator = null;
    public componentInit = false;
    public checkout = false;
    public transitionExpand = new TransitionExpand();
    
    
    
    // TODO KLK
    // @Watch('$route', { deep: true }) // used for history back button
    // onUrlChange(params: any) {
    //     this.helper.onUrlChange(params);
    // }

    get page () {
        console.log('called get page()', this.$route.query.step);
        return this.$route.query.step || 1
    };

    // public testItems = ['1', '1','1','1','1','1'];
    // private pp = 0;
    // public kk = 'K';
    // public show = false;
    public async created() {
        // setInterval(() => {
        //     // this.kk = this.kk + 1;
        //     // console.log('kk' , this.kk);
        //     this.show = !this.show;
            
        // }, 2000);
        // const end =setInterval(() => {
        //     if (this.pp < 5) {
        //         this.testItems.push('' + this.pp);
        //         this.pp++;    
        //     } else {
        //         this.testItems.splice(-1)
        //         this.pp--;
        //     }
        //         // clearInterval(end);
        // }, 1000);
        this.helper = new BuyInsuranceHelper(this);

        if (!store.getters.getActiveCalculator) { // this is first load - update store
            const cms = new DogSettings(this.settingsBlock);
            if(cms.mockData === true) {
                Model.mock();
            }
            await store.dispatch(INIT_CALCULATOR, {cms, model: cloneDeep(Model.model)});
        } else {
            await store.dispatch(CALCULATOR_LOADED);
        }

        if (!await this.helper.initComponent()) {
            return;
        }

        this.calculator = new DogCalculator(this);

        this.helper.setFirstCard();

    }

    // called by helper
    public addResetSubscription() {
        const unsubscribe = store.subscribeAction((action, state) => {
            if (action.type === RESET_CALCULATORS) {
                setTimeout(() => {
                    this.checkout = true;
                    store.dispatch(INIT_CALCULATOR, {cms : this.cms, model: cloneDeep(Model.model)});
                    unsubscribe();
                }, 2000);
            }
        });
    }

    public get showValidNowOption() {
        return this.helper.showValidNowOption;
    }

    public get showDogQuestions(): boolean {
        return this.model.choosePackage.selectedPackage?.id === this.cms.sickAccidentpackageId;
    }

    public get isDogQuestionsValid() {
        if (!this.showDogQuestions) {
            return true;
        }
        return this.model.dogInfo.vaccine === 'ja' &&
                this.model.dogInfo.vet === 'nej' &&
                this.model.dogInfo.healthy === 'ja' &&
                this.model.dogInfo.beenUnhealthy === 'nej';
    }

    public async gotoCard(cardName: string): Promise<boolean> {
        if(cardName === STEP.DOG_INFO || cardName === STEP.PERSON_INFO) {
            BuyInsuranceHelper.resetSelectedCalculation(this);
        }

        if(cardName === STEP.PERSON_INFO || cardName === STEP.PACKAGE) {
            this.insureNiceDates('birthdate1');
            if (this.model.dogInfo.dogCount === 2) {
                this.insureNiceDates('birthdate2');
            }
        }

        if(cardName === STEP.PACKAGE) {
            this.model.choosePackage.ownRiskId = this.model.calculation.abCalc.excessIdDefault;
            this.calculator.setupExcessList();

        }

        if(cardName === STEP.PAYMENT) {
            // force user to choose yearly/monthly payment to ensure progressive steps
            this.model.choosePackage.monthYear = undefined;
        }

        this.model.currentCardName = cardName;

        if(cardName === STEP.ORDER) {
            await this.orderByEmail();
            return false;
        }
        return true;
    }

    /**
     * insure correct format day/month
     * @param fieldName
     * @param val
     * @returns
     */
    private insureNiceDates(fieldName) {
        const val = this.model.dogInfo[fieldName];
        if (!Validator.isValidBeforeNow(val)) {
            return;
        }
        try {
            if (val.length === 10) {
                return;
            }
            const res = val.trim().match(/[\d]+/g);

            if (res?.length > 2) {
                for (let i = 0 ; i < 2; i++) {
                    res[i] = res[i].length < 2 ? '0' + res[i] : res[i];
                }
                const niceRes = `${res[0]}-${res[1]}-${res[2]}`;
                if (this.model.dogInfo[fieldName] === val && this.model.dogInfo[fieldName] !== niceRes) {
                    this.model.dogInfo[fieldName] = niceRes;
                }
            }
        } catch(e) {
        }

    }

    /**
     * when nextbtn is clicked - check for valid
     * @param cardName
     */
    public nextStep(cardName: string, addToHistory: boolean = true) {
        this.helper.nextStep(cardName, addToHistory);
    }

    public getSubtitle(cardName: string) {
        return this.helper.getSubtitle(cardName);
    }

    public getCardTitle(card): string {
        if( card.name === 'choose_package') {
            return this.model.choosePackage.selectedPackage !== undefined ? this.cms.calculatedHeadline : card.title
        }
        if( card.name === 'dog_info') {
            return this.model.dogInfo.dogCount === 2 ? 'Dine Hunde' : card.title
        }
        return card.title;
    }
    public isActiveCard(cardName: string): boolean {
        return cardName === this.model.currentCardName;
    }

    public isValid(cardName): boolean {
        switch(cardName) {
            case STEP.DOG_INFO:
                let okDog: boolean = !!(this.model.dogInfo.dogCount && this.model.dogInfo.dogCount !== 3);
                let subtitle = '';
                if (okDog) {
                    okDog = !!(this.model.dogInfo.dogName1 && this.model.dogInfo.dogType1 && this.model.dogInfo.gender1 && Validator.isValidBeforeNow(this.model.dogInfo.birthdate1));
                    if (okDog) {
                        subtitle = `${this.model.dogInfo.dogName1}`;
                        if (this.model.dogInfo.dogCount === 2) {
                            okDog = !!(this.model.dogInfo.dogName2 && this.model.dogInfo.dogType2 && this.model.dogInfo.gender2 && Validator.isValidBeforeNow(this.model.dogInfo.birthdate2));
                            if (okDog) {
                                subtitle += ` og ${this.model.dogInfo.dogName2}`;
                            }
                        }
                    }
                }
                this.model.dogInfo.subtitle = subtitle;
                return okDog;
            case STEP.PERSON_INFO:
                return this.helper.isValidAlmbrandProducts();
            case STEP.PACKAGE:
                return this.helper.isValidPackage();
            case STEP.OVERVIEW:
                return this.helper.isValidPackage();
            case STEP.CONTACT_INFORMATION:
                return this.helper.isValidContactInfo();
            case STEP.ADDITIONAL_INFO:
                return this.isDogQuestionsValid && this.helper.isValidAdditionalInfo(false);
            case STEP.PAYMENT:
                return this.helper.isValidPaymentInfo();
            default: return true;
        }
    }

    // called by helper
    public setOverviewData() {
        this.helper.setHighLights();

        this.model.overview.details = [];

        this.model.overview.details.push('Antal hunde: ' + this.model.dogInfo.dogCount);
        this.model.overview.details.push('Navn på hund: ' + this.model.dogInfo.dogName1);
        this.model.overview.details.push('Race: ' + this.model.dogInfo.dogType1);

        if (this.model.dogInfo.dogCount === 2) {
            this.model.overview.details = this.model.overview.details.concat([
                'Navn på hund: ' + this.model.dogInfo.dogName2,
                'Race: ' + this.model.dogInfo.dogType2,
            ]);
        }
        this.helper.setDetailsYearlyPrice();
    }

    public async orderByEmail() {
        try {
            this.model.showSpinner = true;
            if (!this.isValid(this.model.currentCardName)) {
                this.model.showSpinner = false;
                return;
            }
            const pack = this.model.choosePackage.selectedPackage;
            let desc = '';
            let tracking = '';

            if(this.model.contact_information.customerNo) {
                const device = EnvironmentService.isApp() ? '(APP)' : '(WEB)';
                desc += `<li><h2><i>Kunde genkendt ${device}</i></h2></li>`;
                tracking += "/existingCustomer";
            }

            if(this.model.campaign.valid) {
                const id = this.model.campaign.ID ? this.model.campaign.ID.toLocaleUpperCase() : 'Ukendt!';
                desc += `<li><h2><i>Kampagne ${id}</i></h2></li>`;
                tracking += `/campaign/${id}`;
            }
            if(this.model.additionalInfo.rki?.toLowerCase() === 'ja') {
                desc += '<li><h2><i>Kunde er registreret i RKI</i></h2></li>';
                // tracking += "/rki";
            }
            if(this.model.additionalInfo.skader !== '0') {
                desc += `<li><h2><i>Kunde har ${this.model.additionalInfo.skader} skader</i></h2></li>`;
                // tracking += "/damages";
            }

            if( desc !== '') {
                desc = '<ul>' + desc + '</ul>'
            }

            await this.helper.submitOrder(desc, tracking);

            const price: number = this.model.choosePackage.monthYear === 'M' ? pack.totalPrice * 12 : pack.yearlyPriceTotal;
            const product = {
                name: 'VP_VP',
                id: pack.name,
                price: '' + price,
                brand: 'Fakedoor flow',
                variant: undefined,
                category: 'Online Salg',
                quantity: '1'
            }
            this.helper.trackPurchase(product, price);

        } catch(e) {
            this.model.showSpinner = false;
            if (UrlUtil.isDevelop()) {
                this.model.modal.content = this.model.modal.content + '<br><div style="border: 2px solid red">' + e +'</div> ';
            }
            this.toggleModal({
                    id: 'error',
                    show: true,
                    redirect: undefined,
                    track: true,
                }
            );
            this.helper.sendErrorMailToTeam(e);
        }
    }

    /** Called by helper */
    public buildDescriptionForEmail(desc: string) {
        const addProp: Function = this.helper.addProp;
        const pack = this.model.choosePackage.selectedPackage;
        desc += '<h2>Person</h2>';
        const now: Date = new Date();
        desc += addProp('Email sendt', `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${(now.getMonth()+1)}-${now.getFullYear()}`);
        desc += addProp('Navn', this.model.contact_information.name);
        if (this.model.contact_information.customerNo) {
            desc += addProp('KundeNr', this.model.contact_information.customerNo);
        } else {
            desc += addProp('Cpr', this.model.contact_information.cpr);
        }

        desc += addProp('Antal skader', this.model.additionalInfo.skader);
        desc += addProp('Kunde har accepteret betingelser', this.model.contact_information.accept ? 'Ja' : 'Nej');
        desc += addProp('Email', this.model.contact_information.email);
        desc += addProp('Telefonnummer', this.model.contact_information.phone);

        desc += this.helper.buildExistingProductsDescription();

        desc += '<h2>Forsikring</h2>';
        desc += addProp('Rki', this.model.additionalInfo.rki);
        desc += addProp('Eksisterende forsikring', this.model.additionalInfo.existInsurance);

        if (this.model.additionalInfo.existInsurance === 'ja') {
            desc += addProp('Nuværende selskab', this.model.additionalInfo.existingInsurance);
        }

        desc += addProp('Gyldig hurtigst mulig', this.model.additionalInfo.validNow);
        if(this.model.additionalInfo.validNow === 'nej') {
            desc += addProp('Gyldig pr.', this.model.additionalInfo.validFromDate);
        }

        desc += '<h2>Ønsket forsikring</h2>';
        desc += addProp('Forsikringstype', pack.name);
        desc += addProp('Antal hunde', this.model.dogInfo.dogCount);

        desc += addProp('<br>Navn hund 1', this.model.dogInfo.dogName1);
        desc += addProp('Race', this.model.dogInfo.dogType1);
        desc += addProp('Race ID', this.model.dogInfo.dogTypeId1);
        desc += addProp('Køn', this.model.dogInfo.gender1 === 'T' ? 'Tæve' : 'Han');
        desc += addProp('Født', this.model.dogInfo.birthdate1);
        if (this.model.dogInfo.dogCount > 1) {
            desc += addProp('<br>Navn hund 2', this.model.dogInfo.dogName2);
            desc += addProp('Race', this.model.dogInfo.dogType2);
            desc += addProp('Race ID', this.model.dogInfo.dogTypeId2);
            desc += addProp('Køn', this.model.dogInfo.gender2 === 'T' ? 'Tæve' : 'Han');
            desc += addProp('Født', this.model.dogInfo.birthdate2);
        }

        if (this.model.dogInfo.healthy) {
            desc += addProp('<br>Hund(e) sund og rask', this.model.dogInfo.healthy);
            desc += addProp('Hund(e) tidligere været syg', this.model.dogInfo.beenUnhealthy);
            desc += addProp('Hund(e) været ved dyrlæge p.g.a. sydgom/skade', this.model.dogInfo.vet);
            desc += addProp('Hund(e) vaccineret', this.model.dogInfo.vaccine);
        }

        desc += addProp('<br>Betaling', this.model.choosePackage.monthYear === 'M' ? 'Månedlig' : 'Årlig');
        desc += addProp('Samlet pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += addProp('Beregningstype', 'VP_VP beregning');
        desc += addProp('Dækninger', this.helper.getCoverageNames(pack));


        desc += '<h2>Betaling</h2>';
        desc += addProp('Reg. nr.', this.model.payment.regNo);
        desc += addProp('Konto nr.', this.model.payment.kontoNo);

        const params: Map<string, string> = new Map();
        params.set('description', desc);
        return params;
    }

    public toggleModal(data: any = {}) {
        this.helper.toggleModal(data);
    }

    public gotoAddInsurances(step: string) {
        this.helper.gotoAddInsurances(step);
    }
}
