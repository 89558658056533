import Adapter from "./Adapter";
import { Customer, CustomerContact, CustomerContactDetails } from "@/generated-api/customer-details/api";
import { isNullOrUndefined } from '@/views/common/util';

export default class CustomerAdapter extends Adapter {

    public customer: Customer;
    
    public constructor(customer: Customer, customerName: string) {
        super(customer.customer_id + "", customerName, "");
        this.customer = customer;
        this.description = this.buildAddress(customer.contact);
    }

    buildAddress(contact: CustomerContact): string {
        let addr: string = "";
        addr = contact.address.street_name + " " + contact.address.building_no_and_letter + ", ";

        const floor: CustomerContactDetails =
            <CustomerContactDetails>contact.contact_details.find((item) => item.type === "Floor__c");
        if (!isNullOrUndefined(floor)) {
            if (!isNullOrUndefined(floor.detail) && floor.detail !== "null")
                addr = addr + floor.detail + ". ";
        }

        const floorSide: CustomerContactDetails =
            <CustomerContactDetails>contact.contact_details.find((item) => item.type === "Floor_Side_Code__c");
        if (!isNullOrUndefined(floorSide)) {
            if (!isNullOrUndefined(floorSide.detail) && floorSide.detail !== "null")
                addr = addr + floorSide.detail + ". ";
        }

        const floorDoor: CustomerContactDetails =
            <CustomerContactDetails>contact.contact_details.find((item) => item.type === "Floor_Door__c");
        if (!isNullOrUndefined(floorDoor)) {
            if (!isNullOrUndefined(floorDoor.detail) && floorDoor.detail !== "null")
                addr = addr + floorDoor.detail + ". ";
        }


        addr = addr + contact.address.postal_code + " " + contact.address.city_name;

        return addr;
    }

}
