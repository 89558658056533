import { UsabillaInPageBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd'

export default class UsabillaInPageBlockCd extends ContentItemCd {
    public model: UsabillaInPageBlock;

    constructor(model: UsabillaInPageBlock) {
        super();
        this.model = model;

    }
}
