import BuyHouse from './BuyHouseComponent';
import { BuyInsuranceHelper, Formatter, monthYearArray, ValuePair } from '../BuyInsuranceHelper';
import { cloneDeep } from 'lodash';
import { CustomerAgreementFeature} from '@/generated-api/customer-agreements';
import UrlHandler from '../UrlHandler';
import AbAxiosCalculators from '../AbAxiosCalculators';
import InsurancesProductEnum from '@/views/model/InsurancesProductEnum';
import { SelectableData, UPDATE_SELECTABLE } from '@/episerver/store/modules/selectableContext';
import store from '@/store';
import { REMOVE_CALCULATOR } from '@/episerver/store/modules/calculatorContext';
import FeatureAdapter from '@/views/model/adapter/FeatureAdapter';
// import { getHouseInsuranceProductInfo, getHolidayHouseInsuranceProductInfo} from '@/views/TestData/calculators/apiTestData';
// import HouseTestCalculation from '@/views/TestData/calculators/HouseCalculation.json';
import { defaultConfig } from '@/episerver/config';

export default class HouseCalculator {
    private buyHouse: BuyHouse; // component using calculator
    private model: any; // the data structure for state and properties
    private cms: any; // settings from EPI
    private calcConfigHouse: any;
    private succesCalc = true;
    private abAxiosCalculator: AbAxiosCalculators;
    private calculatorUrl: string;
    private calculatorUrlOther: string;
    private calculatorInfo: Array<any>;

    constructor(buyHouse: BuyHouse) {
        this.buyHouse = buyHouse;
        this.model = buyHouse.model;
        this.cms = buyHouse.cms;
        this.calculatorInfo = JSON.parse(this.buyHouse.calculatorInfoBlock.markup);

        this.calculatorUrl =  defaultConfig.baseUrl + store.getters.getConfigEpi.openServiceUrl + this.cms.calculatorUrlVue3; // either holiday or house. 
        this.calculatorUrlOther =  defaultConfig.baseUrl + store.getters.getConfigEpi.openServiceUrl + this.cms.calculatorUrlOtherVue3; // either holiday or house. 
        this.abAxiosCalculator = new AbAxiosCalculators(this.calculatorUrl);
        this.setUpConfig(this.cms.createOnlineOffer);
    }

    private mapCoveragesFromCalc(createOnlineOffer: boolean) {
        this.model.calculation.allCoverages = new Map<number, Map<number, Array<FeatureAdapter>>>();
        this.model.calculation.abCalc.packs.forEach( (calculations, ownRiscId) => {
            const coveragesForPackage = new Map<number, Array<FeatureAdapter>>();
            calculations.forEach((calc, packageInx) => {
                const featureAdapters: Array<FeatureAdapter> = [];
                calc.coverages.forEach((coverage) => {
                    let customerAgreementFeature: CustomerAgreementFeature = {
                        description: this.cms.getCoverageDescription(coverage.name, coverage.description),
                        feature_id: coverage.riskId + '',
                        name: this.cms.getCoverageName(coverage.name),
                        sub_features: [],
                        // optional_properties: undefined,
                        // excess: undefined,
                        // sum: undefined,
                    };
                    let featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
                    featureAdapters.push(featureAdapter);
                });
                coveragesForPackage.set(packageInx, featureAdapters);
            });
            this.model.calculation.allCoverages.set(ownRiscId, coveragesForPackage);
        });
    }

    public async setUpConfig(createOnlineOffer: boolean) {
        if (!this.calcConfigHouse) {
            if (this.model.campaign.valid) {
                this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
            }
            const calculatorInfo = this.calculatorInfo.find(cfg => cfg.calculatorConfigId === this.cms.calculatorConfigId);
            this.calcConfigHouse = calculatorInfo.insuranceConfiguration;
            this.cms.mapCoverages(calculatorInfo.textMappingsOuter);
        }
        this.model.calculation.abCalc = {
            excessIdDefault: this.calcConfigHouse.excessIdDefault,
            packageIds: this.calcConfigHouse.packageIds,
            packages: cloneDeep(this.calcConfigHouse.packages),
            coveragesDetails: this.calcConfigHouse.coverages,
        };

        this.setupExcessList();
        this.model.calculation.abCalc.packages.forEach(pack => {
            pack.coverages = [];
            if (this.cms.extendCoverages) {
                pack.includedCoverageIds.forEach(riskId => {
                    const coverage = this.calcConfigHouse.coverages.find( x => riskId === x.riskId);
                    pack.coverages.push(coverage);
                });
                pack.expandedCoverages = [];
                pack.includedCoveragesExpandedIds.forEach(riskId => {
                    const coverage = this.calcConfigHouse.coverages.find( x => riskId === x.riskId);
                    pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
                });
            } else {
                pack.includedCoveragesExpandedIds.forEach(riskId => {
                    const coverage = this.calcConfigHouse.coverages.find( x => riskId === x.riskId);
                    pack.coverages.push(coverage);
                });
            }
            this.cms.mapCoverageGroups(pack.coverages);
        });
        this.model.calculation.abCalc.packs = new Map<number, Object>();
        this.model.calculation.abCalc.excesses.forEach(excess => {
            const calcs = [];
            this.model.calculation.abCalc.packs.set(excess.id, calcs);
            this.model.calculation.abCalc.packages.forEach( (pack) => {
                calcs.push(this.buyHouse.helper.reducePack(cloneDeep(pack)));
            });
        });
        this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'

        this.mapCoveragesFromCalc(createOnlineOffer);
        this.updateCalcKeys();
    }

    public setupExcessList() {
        this.model.calculation.abCalc.excesses = this.calcConfigHouse.excessList;
        this.model.ownRisks = [];
        this.model.calculation.abCalc.excesses.forEach((excess) => {
            if (this.model.personInfo.customerAge >= excess.minCustomerAge) {
                const valuePair: ValuePair = {
                    value: excess.id,
                    displayValue: Formatter.format(excess.amount) + ' kr.',
                };
                this.model.ownRisks.push(valuePair);
            }
        });
    }

    private checkFirstPackagePrices(excessId: number) {
        let ok = true;
        const packages = this.model.calculation.abCalc.packs.get(excessId);
        packages.forEach(calc => {
            if (!calc.totalPrice) {
                ok = false;
            }
        });
        return ok;
    }

    private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
        this.succesCalc = true;
        const rebate = this.buyHouse.helper.getDiscount();
        this.model.calculation.discount = rebate.discount;
        this.model.calculation.discountDisplay = rebate.discountDisplay;

        let promises = [];
        for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
            const packageId = this.model.calculation.abCalc.packageIds[index];
            promises.push(this.onePackageCalcEpi(monthly, excessId, packageId));
        }
        await Promise.all(promises);
        return Promise.resolve(this.succesCalc);
    }

    public async getCalculations(excessId?: number) : Promise<boolean> {
        if (!this.model.calculation.isCalculated) {
            this.model.showSpinner = true;
            this.model.calculating = true;
            // handle "eternal" spinning
            setTimeout(() => {
                if(this.model.showSpinner) {
                    this.model.showSpinner = false;
                    this.model.calculating = false;
                }
            }, this.cms.calculationSpinnerTimeout);
        }

        // reset prices
        const selectedExcessId = excessId || this.model.choosePackage.ownRiskId;

        const pack = this.model.calculation.abCalc.packs.get(selectedExcessId);
        pack.totalPriceDisplay = undefined;

        return this.getEpiCalculations(selectedExcessId);
    }

    private async getEpiCalculations(excessId? : number): Promise<boolean> {
        // reset calculation
        await this.setUpConfig(false);

        let success = true;

        const _excessId = excessId || this.model.calculation.abCalc.excessIdDefault;

        if (this.buyHouse.helper.hasAddressSupplement()) {
            this.model.calculation.addressSupplement = this.cms.addressSupplement;
        } else {
            this.model.calculation.addressSupplement = 1;
        }

        success = await this.calculatePackagesEpi(true, _excessId);
        // check if has got prices
        if(!success) {
            success = this.checkFirstPackagePrices(_excessId);
        }
        if (success) {
            this.calculatePackagesEpi(false, _excessId);
        }
        this.model.calculation.isCalculated = success;
        this.model.showSpinner = false;
        this.model.calculating = false;
        this.buyHouse.abGtm.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/prices_shown`,
        });

        return success;
    }

    private async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {
        // const response = await this.abAxiosCalculator.getRetry(this.cms.oldCalculatorPrices, this.createParamsEpi(excessId, packageId, monthly ? 1 : 0));
        // const response = JSON.parse(JSON.stringify(HouseTestCalculation));
        
        const response = await this.abAxiosCalculator.getRetryDefaultUrl(this.createParamsEpi(excessId, packageId, monthly ? 1 : 0));
        if (response.status !== 200) {

            this.succesCalc = false;

            this.model.modal.content = this.cms.defaultCalcErrorContent;

            this.model.modal.track = false;
            this.model.modal.btnSecondLabel = 'Ok';
            this.model.modal.id = 'calcWarning';
            this.model.modal.show = true;
            if (response.status === 250) {
                    this.model.modal.title = this.cms.defaultCalcErrorTitle;
                    this.model.modal.content = this.cms.defaultCalcErrorContent;
            }
            else {
                this.model.modal.title = 'Der er desværre sket en fejl';
                this.model.modal.id = 'calcError';
            }
            this.buyHouse.toggleModal();
            return Promise.resolve(false);
        }

        const pack = this.model.calculation.abCalc.packs.get(excessId);
        BuyInsuranceHelper.mapPriceResult(response);
        this.buyHouse.helper.setPrices(packageId, response, monthly, pack);

        this.updateCalcKeys();
        return Promise.resolve(true);
    }

    public async checkForValidAddress(): Promise<boolean> {
        let gotoOtherCalculator = false;
        const packageId = this.model.calculation.abCalc.packageIds[0];
        const excessId = this.model.calculation.abCalc.excessIdDefault;
        let response;
        try {
            response = await this.abAxiosCalculator.getRetryDefaultUrl(this.createParamsEpi(excessId, packageId, 1));//this.createParamsEpiValidAdress());
        } catch(e) {
            console.warn(e);
        }
        if (response.status === 400) {
            // check if calculation will work for other calculator either holiday or house.
            let responseOther;
            try {
                let otherProduct: string;
                if (this.model.productName === InsurancesProductEnum.HUS_FORSIKRING) {
                    otherProduct = InsurancesProductEnum.FRITIDSHUS_FORSIKRING;
                } else {
                    otherProduct = InsurancesProductEnum.HUS_FORSIKRING;
                }
                responseOther = await this.abAxiosCalculator.getRetry(
                        this.calculatorUrlOther, 
                            this.createParamsEpi(this.cms.excessIdOther, this.cms.basePackageOther, 1, this.cms.selectedRisksOther, otherProduct));//this.createParamsEpiValidAdress(true));
            } catch(e) {
                console.error(e);
                
            }
            gotoOtherCalculator = responseOther.status === 200;
        }

        if (response.status !== 200) {
            let popup = {
                content: this.cms.failText,
                title: this.cms.failTitle,
            }

            if (gotoOtherCalculator && response.status === 400) {
                popup = this.handleCalculatorChange();
            }

            if (!gotoOtherCalculator && response.data.warningMessage) {
                popup.content = response.data.warningMessage.replace(/\[[^\]]*?\]/g, '').trim(); // remove errror code from backend e.g. [BR2]
            }

            this.model.modal.title = popup.title;
            this.model.modal.content = popup.content;
            this.model.modal.track = true;
            this.model.modal.trackToken = `error/invalidAddress`;
            this.model.modal.btnSecondLabel = 'Ok';
            this.model.modal.id = 'calcWarning';
            this.model.modal.show = true;
            this.buyHouse.toggleModal();
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    private handleCalculatorChange(): {content: string, title: string} {
        const address = this.model.personInfo.address;
        const customerAge = this.model.personInfo.customerAge;
        const almbrandCustomer = this.model.personInfo.almbrandCustomer;
        const gotoProduct = InsurancesProductEnum.HUS_FORSIKRING === this.model.productName ? InsurancesProductEnum.FRITIDSHUS_FORSIKRING : InsurancesProductEnum.HUS_FORSIKRING;
        const existingAlmBrandProducts: Array<string> = this.model.personInfo.existingAlmBrandProducts.filter( product => product.toLocaleLowerCase() !== gotoProduct);
        const query = {
            step: 'person_info',
            address,
            customerAge,
            almbrandCustomer,
            existingAlmBrandProducts,
            product: gotoProduct
        }

        const popup = {
            title: this.cms.changeCalcTitle,
            content: this.cms.changeCalcContent,
        }
        this.model.modal.btnInlineLabel = this.cms.btnInlineLabel;
        this.model.modal.btnInlineStyle = this.cms.btnInlineStyle;
        this.model.modal.btnInlineAction = () => {
            const virtualPath = `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/change_to_${gotoProduct}/`;
            this.buyHouse.abGtm.triggerCustomGtmEvent({
                'event': 'track-vp',
                virtualPath
            });
            // remove this from basket if there is only one product in basket
            this.removeFromBasket();
            // reset this personInfo
            this.model.personInfo = {
                subtitle: undefined,
                customerAge: undefined,
                address: undefined,
                zipCode: undefined,
                zipName: undefined,
                streetName: undefined,
                houseNr: undefined,
                floorNr: undefined,
                doorNr: undefined,
                kvhxValue: undefined,
                almbrandCustomer: undefined,
                existingAlmBrandProducts: [],
            }

            // this.buyHouse.$router.push({ path: `${UrlHandler.path}${gotoProduct}`, query });
            this.buyHouse.$router.push({
                path: 'gotoProduct',
                query
            })

            // this.buyHouse.$router.push('gotoproduct');
            setTimeout(() => {
                store.dispatch(REMOVE_CALCULATOR, this.model.productName);
            }, this.cms.initialtimeout);
        }
        return popup;
    }
    private async removeFromBasket() {
        // remove this product from basket
        const toggle: SelectableData = {
            group: 'basket',
            id: this.model.productName + '0',
            isSelected: false,
            sortMultiple: true,
        }
        await store.dispatch(UPDATE_SELECTABLE, toggle);
    }

    // private createParamsEpiValidAdress(useOtherCalculatorBlockID: boolean = false) {
    //     const monthlyPrices = 1;
    //     const packageId = useOtherCalculatorBlockID ?  this.cms.basePackageOther : this.model.calculation.abCalc.packageIds[0];
    //     const excessId = useOtherCalculatorBlockID ? this.cms.excessIdOther : this.model.calculation.abCalc.excessIdDefault;
    //     const customerAge  = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));

    //     // create params
    //     const params = {
    //         customerAge,
    //         doorNr: this.model.personInfo.doorNr,
    //         floorNr: this.model.personInfo.floorNr,
    //         houseNr: this.model.personInfo.houseNr,
    //         streetName: this.model.personInfo.streetName,
    //         kvhxValue: this.model.personInfo.kvhxValue,
    //         // selectedOptionalProducts: '46,9,8',
    //         mcb: false, // ????
    //         zipName: `${this.model.personInfo.zipName}`,
    //         zipCode: `${this.model.personInfo.zipCode}`,
    //         excessId: `${excessId}`,
    //         extendedPackage: `${packageId}`,
    //         monthlyPrices: `${monthlyPrices}`,
    //         calculatorBlockId: useOtherCalculatorBlockID ?  this.cms.oldCalculatorBlockIdOther : this.cms.oldCalculatorBlockId,
    //     }
    //     return params;
    // }

    private createParamsEpi( excessId: number, packageId: number, monthlyPrices: number, selectedRisks?: Array<string>, forceProductName?: string) {
        const customerAge  = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
        const selected = selectedRisks ? selectedRisks.toString() : this.buyHouse.helper.getRisksForPackage(packageId)
        const productName = forceProductName ? forceProductName : this.model.productName;
        if (productName === InsurancesProductEnum.HUS_FORSIKRING) {
            const params = {
                plusCustomer: 'PK0',
                age: customerAge,
                excessId: `${excessId}`,
                streetName: this.model.personInfo.streetName,
                // doorNr: this.model.personInfo.doorNr,
                // floorNr: this.model.personInfo.floorNr,
                buildingNoWithLetter: this.model.personInfo.houseNr,
                postalCode: `${this.model.personInfo.zipCode}`,
                paymentsPerYear: monthlyPrices === 1 ? 12 : 1,
                selected,
                
                // kvhxValue: this.model.personInfo.kvhxValue,
                // selectedOptionalProducts: '46,9,8',
                // zipName: `${this.model.personInfo.zipName}`,
                // extendedPackage: `${packageId}`,
                // calculatorBlockId: this.cms.oldCalculatorBlockId,
            }
            return params;
        } else {
            const params = {
                plus_customer: 'PK0',
                age: customerAge,
                excess_id: `${excessId}`,
                street_name: this.model.personInfo.streetName,
                building_no_with_letter: this.model.personInfo.houseNr,
                postal_code: `${this.model.personInfo.zipCode}`,
                payments_per_year: monthlyPrices === 1 ? 12 : 1,
                selected,
                insured_value: this.cms.insuredValue, // only holidayHouse
            }
            return params;
        }
    }
    // private createParamsEpi( excessId: number, packageId: number, monthlyPrices: number ) {
    //     const customerAge  = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
    //     // create params
    //     const params = {
    //         customerAge,
    //         doorNr: this.model.personInfo.doorNr,
    //         floorNr: this.model.personInfo.floorNr,
    //         houseNr: this.model.personInfo.houseNr,
    //         streetName: this.model.personInfo.streetName,
    //         kvhxValue: this.model.personInfo.kvhxValue,
    //         // selectedOptionalProducts: '46,9,8',
    //         mcb: false, // ????
    //         zipName: `${this.model.personInfo.zipName}`,
    //         zipCode: `${this.model.personInfo.zipCode}`,
    //         excessId: `${excessId}`,
    //         extendedPackage: `${packageId}`,
    //         monthlyPrices: `${monthlyPrices}`,
    //         calculatorBlockId: this.cms.oldCalculatorBlockId,
    //     }
    //     return params;
    // }



    public updateCalcKeys() {
        this.model.calculation.updatePrices = this.model.calculation.updatePrices.map( (elem) => {
            return elem += '1';
        });
    }
}
