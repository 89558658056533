import { Vue, Options, mixins } from "vue-class-component";
import template from './TeaserCardBlock.vue';
import TeaserCardBlock from '../../contentTypes/TeaserCardBlockCd';
import { AbLink } from '@/views/sharedcomponents/baseComponents/components';

@Options({
    name: 'teaser-card-block',
    mixins: [template],
    components: {
        AbLink
    },
    props: {
        contentItem: TeaserCardBlock
    }
})
export default class TeaserCardBlockComponent extends Vue{
    contentItem: TeaserCardBlock;
}
