import AbMisc from '@/views/common/ab_misc';
import { isNullOrUndefined } from '@/views/common/util';


export default class ConfigEpi {


    apiKey: string = "x-api-key=e0122b76-d302-4148-af52-7b3890e54482"; // KLK TODO remove?

    serviceUrl: string = "/api/naboo/v1/serviceproxy";
    openServiceUrl: string = "/api/naboo/v1/openserviceproxy";
    mailOSSUrl: string = '/api/naboo/v1/checkoutmail/SendOssMail';
    mailOSSNoLogin: string = '/api/naboo/v1/checkoutmail/sendossnotloggedin';
    teamMailOSSNoLogin: string = '/api/naboo/v1/checkoutmail/sendteamnotloggedin';
    mailTeamUrl: string = '/api/naboo/v1/checkoutmail/sendTeamMail';
    //menuContentId has an fallback value that will be used if 'data-menu-id' does not exist on webview-mitkundeoverblik-component
    //it is here to make sure mitalmbrand does not break when we deploy this to production before we can give it an menu in the new config.
    menuContentId: string = "47867"
    menuServiceUrl: string;
    disableBasePathForLegacyRouting: string;

    csrfToken: string = "";

    epiServiceUrl: string = "https://www.almbrand.dk/webapi";

    errorPageUrl: string = "";

    abMisc = new AbMisc();
    private setValueFromAttribute(component: Element, name: string, setter: (val: string) => void): void {
        const value = component.getAttribute(name);
        if (value) {
            setter(value);
        }
    }
    constructor() {
        this.init();
    }

    init() {
        let mitkundeoverblikComponent = document.querySelector('#webview-mitkundeoverblik-component');
        if (mitkundeoverblikComponent === null) {
            return;
        }

        //csrfToken
        let _csrfToken = this.abMisc.cleanAttr(mitkundeoverblikComponent.getAttributeNode('csrf-token'));
        if (!isNullOrUndefined(_csrfToken) && _csrfToken !== "") {
            this.csrfToken = _csrfToken;
        }

        //Fejl siden som kunden bliver redirectet til hvis api-kald fejler efter 3 forsøg
        this.errorPageUrl = this.abMisc.cleanAttr(mitkundeoverblikComponent.getAttributeNode('error-page-url'));
        this.setValueFromAttribute(mitkundeoverblikComponent, "data-menu-id", (val) => this.menuContentId = val);
        this.menuServiceUrl = `/api/selfservice/v1/menu/${this.menuContentId}`
        this.setValueFromAttribute(mitkundeoverblikComponent, "data-disable-base-path-legacy-routing", (val) => this.disableBasePathForLegacyRouting = val);
    }
}
