import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import CmsPageComponentSelector from '@/views/sharedcomponents/epiComponents/infrastructure/pageComponentSelector/pageComponentSelector';
import DocsPage from '@/views/pages/Docs.vue';
import store from '@/store';
import { App } from "@/views/sharedcomponents/lib/services/app";
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking"
import AbGtm from "@/views/common/ab_gtm";
import { App as AppService } from "@/views/sharedcomponents/lib/services/app";
import { UPDATE_MODEL_BY_URL } from "@/episerver/store/modules/epiContent";
import { UPDATE_AUTH } from "@/episerver/store/modules/authenticationContext";
import ProfileStoreHandler from '@/views/providers/ProfileStoreHandler';
import BasketProductSelectorComponent from '@/views/calculators/BasketProductSelectorComponent.vue';
import { isNullOrUndefined } from '@/views/common/util';
import ServiceEnum from '@/views/model/ServiceEnum';
import AbCookie from '@/views/common/ab_cookie';

const abGtm = new AbGtm();
const isInAppContext = AppService.isEnabled();
//const baseUrl = isInAppContext ? window.location.pathname : '';
const baseUrl = '';
const defaultDocumentTitle = "Mit Alm. Brand";

const appCallbackPath = (to, from, next) => {
  if (App.isEnabled()) {
    if (store.getters.getAppReplaceRoute) {
      App.sendMessage({ "REPLACE_ROUTE_PATH": to.path });
      store.dispatch('setAppReplaceRoute', false);
    } else {
      App.sendMessage({ "ROUTE_PATH":  to.path });
    }
  }
  next();
};

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/:catchAll(.*)",
  //   component: NotFound,
  // },
  {
    name: 'page-component-selector',
    path: '/:pathMatch(.*)',
    component: CmsPageComponentSelector,
    //beforeEnter: appCallbackPath
  },
  {
    path: '/',
    redirect: to => {
      return ServiceEnum.FORSIKRING_URL
    }
  },
  {
    path: '/mitalmbrand',
    redirect: to => {
      return ServiceEnum.FORSIKRING_URL
    },
  },
  // calculator redirect to product
  {
    path: '/mitalmbrand/forsikring/beregn/gotoproduct',
    component: BasketProductSelectorComponent,
    beforeEnter: (to, from, next) => {
      // don't tell app, since this url resolves which product to show and replaces in browser history
      next();
    }
  },
  {
    path: '/beregn/gotoproduct',
    component: BasketProductSelectorComponent,
    beforeEnter: (to, from, next) => {
      // don't tell app, since this url resolves which product to show and replaces in browser history
      next();
    }
  },
];

if (process.env.NODE_ENV === 'development') {
  routes.push(
    {
      name: 'docs',
      path: '/docs',
      component: DocsPage,
    }
  );
}

const router = createRouter({
  //history: isInAppContext?createWebHashHistory():createWebHistory(),    
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return to scrolled position using browser history back
    // somehow returning savedposition object using Vue's native scrolling not working 
    setTimeout(() => {
      let position = { left: 0, top: 0 };
      if (savedPosition) position = { left: savedPosition.left, top: savedPosition.top }
      window.scrollTo(position.left, position.top)
    }, 300); // match page transition duration
  },
  linkActiveClass: "active-child",
  linkExactActiveClass: "active"
});
/*
router.beforeEach(() => {
  // set timing marker for page speed tracking between routes 
  // eslint-disable-next-line
  if (store.state.allowTracking && (window as any).dataLayer) window.performance.mark('betweenRoutesMark');;
});
*/

router.beforeEach((to, from, next) => {
  appCallbackPath(to, from, next);
});

router.afterEach((to, from) => {
  buildTracking(to);
  updateDocumentTitle();
  if (document.title !== defaultDocumentTitle){
    App.sendMessage({ "MAIN_TITLE":  getPageTitle() });
  } else{
    const path = to.path.endsWith('/') ? to.path.slice(0, -1) : to.path;
    const title = to.path.substring(path.lastIndexOf("/")+1, path.length);
    App.sendMessage({ "MAIN_TITLE":  title });
  }
})

function buildTracking(to) {
  try {
    const epiContent = store.state.epiContent;
    //add GTM virtualPath to all SPA routings
    //is business part only works with app
    abGtm.triggerCustomGtmEvent({
      'event': 'page_view',
      'page_location': Tracking.buildTrackingUrl(store.getters.getCustomer, to.fullPath),
      'page_title': epiContent.model.mainTitle
    });
    /** TODO aberec, skal denne stadig kaldes?
    if (!to.path.startsWith('/beregn/')) {
      ProfileStoreHandler.trackingUpdate(to, AppService.isEnabled());
    }*/

  } catch (err) { }
}

function updateDocumentTitle() {
  document.title = getPageTitle();
}

function getPageTitle(){
  const epiContent = store.state.epiContent;
  if (epiContent.modelLoaded) {
    if (isNullOrUndefined(epiContent.model.mainTitle)) {
      return defaultDocumentTitle;
    } else {
      return epiContent.model.mainTitle;
    }
  }
}


/* Sends message to app and cancel navigation, must be before epiContent beforeEach!
router.beforeEach((to, from, next) => {
  if (isInAppContext && to.query.appMessageKey && to.query.appMessageValue) {
    //supports sending multiple messages to the app via query, appMessageKey and appMessageValue length and order must match
    if (to.query.appMessageKey instanceof Array) {

      for (var i = 0; i < to.query.appMessageKey.length; i++) {
        const key = to.query.appMessageKey[i];
        const value = to.query.appMessageValue[i];
        const message = {};
        message[key] = value;
        AppService.sendMessage(message);
      }
    }
    else {
      const message = {};
      message[to.query.appMessageKey] = to.query.appMessageValue;
      AppService.sendMessage(message);
    }

    return next(false);
  }

  next();
});*/


router.beforeEach((to, from, next) => {
  // URL is updated by vue-route-sync, and when time travelling with the
  // debugger we don't want to trigger a model commit as the model is already
  // part of the store holding the url update.
  const currentModelUrl = store.state.epiContent.model?.canonicalUrl.path;

  if (currentModelUrl?.includes(ServiceEnum.CALCULATOR_URL) && to.path.includes(ServiceEnum.CALCULATOR_URL)) {
    if (currentModelUrl.includes(to.path) && currentModelUrl.includes(from.path)) {
      // skip since calculators handles history pop/push
      next();
      return;
    }
  }
  updateModelByUrlFromFullpath(to, next, currentModelUrl);
});

function updateModelByUrlFromFullpath(to, next, currentModelUrl) {
  const fullPath = baseUrl + to.fullPath.replace("#", "");
  if (currentModelUrl !== fullPath) {
    store.dispatch(UPDATE_MODEL_BY_URL, fullPath).then(() => {
      //dont render next page before model has been updated from url
      next();
    });
  } else {
    next();
  }
}

// Authentication Check
// Gets the Authentication expire time and saves it in the store, then checks if current user is still Authenticated when changing route
router.beforeEach((to, from, next) => {
  const path = to.fullPath.replace("#", "");
  // Only run when not in App and currently when this URL "/beregn/*" is used
  if (!AppService.isEnabled() && !path.startsWith(ServiceEnum.CALCULATOR_URL)) {
    if (!store.state.authenticationContext.isLoaded || !store.state.authenticationContext.authenticated) {
      store.dispatch(UPDATE_AUTH);
    } else if (store.state.authenticationContext.isLoaded && store.state.authenticationContext.authenticated) {
      const currentTime = new Date().getTime();
      if (currentTime > store.state.authenticationContext.expire) {
        const abCookie = new AbCookie();
        abCookie.deleteCookie(ServiceEnum.AUTH_COOKIE_NAME);
        window.location.href = ServiceEnum.LOGOUT_SERVICE;
      }
    }
  }

  next();
});

export default router
