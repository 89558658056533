<template>
    <div v-if="contactDetail">
        <h3 class="basic-list__group-title">{{contactDetail.cleanDetail}}</h3>

        <div class="basic-list__item basic-list__item--cols">
            <div>
                <span :id="`contact-detail--${contactDetail.type.toLowerCase()}`" v-html="contactDetail.detail != '' ? contactDetail.detail : missingText"></span>
                <span v-if="showEdit" :class="`changed-text--${contactDetail.type.toLowerCase()}`">Opdateret</span>
            </div>
            <div>
                <ModalComponent ref="editModal">
                    <template v-slot:trigger>
                        <span v-if="showEdit" :class="['modal-trigger ', 'btn--edit', `btn--${contactDetail.type.toLowerCase()}`]" :aria-label="`Åben redigering af ${contactDetail.cleanDetail.toLowerCase()}`"></span>
                    </template>
                    <template v-slot:body>           
                        <h2 v-html="changeIntro"></h2>
                        <input class="input__field" v-model="contactDetail.detail" :inputmode="setInputMode(contactDetail.cleanDetail)" type="text" ref="detailField" />
                        <div class="font-size-xs text-danger">{{changeText}}</div>
                        <button role="button" class="mt-3 btn-solid-secondary" @click="saveEdit(contactDetail)" data-gtm-action="button click" :data-gtm-label="`profil gem ${contactDetail.cleanDetail}`" :data-gtm-url="trackingUrl">Gem</button>
                    </template>
                </ModalComponent>
            </div>
        </div>
    </div>
</template>

<style lang="scss" src="./_assets/_customer-item.scss"></style>
