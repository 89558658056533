import GeneralHighlight from "./GeneralHighlight";
import AgreementLineAdapter from '@/views/model/adapter/AgreementLineAdapter';


export default class EjerskifteHighlight extends GeneralHighlight{

 
    public constructor(productDetails: AgreementLineAdapter, isBusiness: boolean) {
        super(productDetails, isBusiness);
    }


    protected buildHighlights() {
        super.buildHighlights();

       /* this.highlights.splice(1, 0, {
            label: 'Selvrisiko',
            value: 'Ingen'
        });*/

        this.swapSelvrisikoToGyldigTil();
    }

    private swapSelvrisikoToGyldigTil() {
        let SelvrisikoIndex = -1;
        let GyldigTilIndex = -1;

        let index = 0;
        this.highlights.forEach(item => {
            if (item.label.toLowerCase() === "selvrisiko") {
                SelvrisikoIndex = index;
            }
            if (item.label.toLowerCase() === "gyldig til") {
                GyldigTilIndex = index;
            }
            index++;
        });

        if (SelvrisikoIndex > -1 && GyldigTilIndex > -1 && GyldigTilIndex < SelvrisikoIndex) {
            this.swap(this.highlights, GyldigTilIndex, SelvrisikoIndex);
        }

    }
}
