import { mapState } from 'vuex';
import ModalComponentCalculator from './ModalComponentCalculator.vue';
import AbGtm from '@/views/common/ab_gtm';
import { CarSettings } from '../car/CarSettings';
import { AccidentSettings } from '../accident/AccidentSettings';
import { HomeSettings } from '../home/HomeSettings';
import { HouseSettings } from '../house/HouseSettings';
import { DogSettings } from '../dog/DogSettings';
import { TravelSettings } from '../travel/TravelSettings';
import { BasketSettings } from '../basket/BasketSettings';
import UrlHandler from '../UrlHandler';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { nextTick } from 'vue';
import { BuyInsuranceHelper } from '../BuyInsuranceHelper';

@Options({
    name: 'ContactComponent',
    components: {
        ModalComponentCalculator,
    },
    computed: mapState<any>({
        productModel: state => state.calculatorContext[state.calculatorContext.active].model,
        productCms: state => state.calculatorContext[state.calculatorContext.active].cms,
        basketModel: state => state.calculatorContext.basket.model,
        basketCms: state => state.calculatorContext.basket.cms,
    }),
    props: {
        productName: String,
        useBasketModel: {type: Boolean, default: false},
    }
})

export default class ContactComponent extends Vue {
    productName: string; // productname for tracking
    useBasketModel?: boolean;

    public productModel!: any; // datastructure for user input (store)
    public basketModel!: any; // datastructure for basket
    public productCms!: any; // datastructure for user input (store)
    public basketCms!: any; // datastructure for basket

    public cms: CarSettings | AccidentSettings | HomeSettings | HouseSettings | DogSettings | TravelSettings | BasketSettings; // settings from CMS (store; // settings from CMS (store)
    public model: any = null;
    
    public showCall: boolean = true;
    public showWrite: boolean = true;
    public showOffer: boolean = true;

    private abGtm: AbGtm = new AbGtm();
    public created() {
        this.model = this.useBasketModel ? this.basketModel : this.productModel;
        this.cms = this.useBasketModel ? this.basketCms : this.productCms;
    }
    public mounted() {
        // this.showCall = this.cms.contactCanCall;
        this.handleShowCall();
        this.showWrite = this.cms.contactCanWrite;
        this.showOffer = this.cms.contactCompleteOffer;

        this.resetModal();
    }

    private handleShowCall() {
        if (this.cms.contactCanCall) {
            this.showCall = BuyInsuranceHelper.isOpenHours(this.cms.openForCall, this.cms.closingDaysForCall);
        } else {
            this.showCall = false;    
        }
    }

    public closeModal(args) {
        if (!args.show) {
            store.dispatch('toggleModal',{
                isActive: false,
                id: this.model.modal.id
            });
            setTimeout(() => {
                this.resetModal();
                // Hack to Fix scrollbar missing
                (document.body as HTMLElement).style.overflow = 'initial';
            }, 1000);
        }
    }
    
    public openModal(type: string) {
        const modal = this.fillModal(type);
        this.model.modalId = modal.id
        Object.assign(this.model.modal, modal);
        nextTick( () => {
            store.dispatch('toggleModal',{
                isActive: this.model.modal.show,
                id: this.model.modal.id
        });

            setTimeout(() => {
                const elem = (document.querySelector('div.button-container [data-mainbtn]') as HTMLElement);
                if (elem) {
                    elem.focus();
                }
                
            }, 500);
        });
        this.trackcontact();
    }

    private fillModal(type: string): any {
        this.resetModal();
        switch (type) { 
            case 'phone' : return { 
                    btnType: 'tel',
                    id: 'contact_popup',
                    show: true,
                }
            case 'mail' : return {
                    btnType: 'mailto',
                    btnLabel: this.cms.btnLabelEmail,
                    btnAction: this.cms.btnActionEmail,
                    id: 'contact_popup',
                    show: true,
                }
        default: return {};
        }
    }

    private resetModal() {
        this.model.modalId = 'none';
        this.model.icon = this.cms.btnIcon;
        this.model.modal.show = false;
        this.model.modal.id = 'none';
        this.model.modal.track = false;
        this.model.modal.trackToken = undefined;
        this.model.modal.content = this.showCall ? this.cms.contactText : this.cms.contactTextNoCall;
        this.model.modal.btnStyle = this.cms.btnStyle;
        this.model.modal.btnLabel = this.cms.btnLabel;
        this.model.modal.btnIcon = this.cms.btnIcon;
        this.model.modal.btnAction = this.cms.btnAction;
        this.model.modal.btnSecondStyle = this.cms.btnSecondStyle;
        this.model.modal.btnSecondLabel = 'OK';//this.cms.btnSecondLabel;
        this.model.modal.title = this.cms.contactTitle;
        this.model.modal.redirect = undefined;
    }

    private trackcontact() {
        this.abGtm.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.productName}/contact`
        });
    }

}
