import Adapter from "./Adapter";
import { CustomerAgreement } from '@/generated-api/customer-agreements/api';

export default class AgreementAdapter extends Adapter {
    public agreement: CustomerAgreement;

    public constructor(agreement: CustomerAgreement) {
        super(agreement.agreement_line_seq_no + "", agreement.title, agreement.description,
            agreement.product_instance.price.amount_total);
        this.agreement = agreement;
    }
}
