import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34b5e886"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  class: "ab-tooltiptext",
  style: {"width":"310px"}
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["ab-tooltip", {'item-selected': _ctx.toolTip}])
  }, [
    _createElementVNode("img", {
      src:  _ctx.iconGlobalAssetsPath + 'system-icons/icon-system-question-lightblue.svg',
      style: {"height":"20px"},
      role: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toolTip?_ctx.toolTip=false:_ctx.toolTip=true))
    }, null, 8, _hoisted_1),
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("img", {
        src: "/src/assets/svg/systemicon_darkblue_Cancel.svg",
        style: {"height":"20px"},
        role: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toolTip?_ctx.toolTip=false:_ctx.toolTip=true)),
        class: "float-right"
      }),
      _createElementVNode("b", { innerHTML: _ctx.header }, null, 8, _hoisted_3),
      _createElementVNode("p", { innerHTML: _ctx.body }, null, 8, _hoisted_4)
    ])
  ], 2))
}