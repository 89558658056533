import FeatureAdapter from '@/views/model/adapter/FeatureAdapter';
import InsurancesProductEnum from '@/views/model/InsurancesProductEnum';
import { STEP, ValuePair } from '../BuyInsuranceHelper';

// list and order of steps
export const STEPS: Array<string> = [STEP.CAR_INFO, STEP.PERSON_INFO, STEP.PACKAGE, STEP.ADDITIONAL_INFO, STEP.OVERVIEW, STEP.CONTACT_INFORMATION, STEP.PAYMENT, STEP.ORDER];

export class Model {
    public static model = {
        campaign: {
            ID: undefined, // lowercase i.e. diba
            valid: false,
            init: false,
            hasContentPage: false,
            subtitle: undefined,
            uniqueKey: undefined,
            discount: undefined,
            productStepDiscount: undefined,
        },
        carInfo: {
            subtitle: undefined,
            car: undefined,
            year: undefined,
            yearlyMileage: undefined,
            licenseplate: undefined,
            carWeight: 0,
            kid: 0,
            fuelType: undefined,
            isElectricHybrid: false,
            variantId: undefined,
            variantName: undefined,
            vehicleGroup: undefined,
            model: undefined,
            brand: undefined,
            displayText: undefined,
            searchBrandModel: false,
            checkForSpecialCar: false,
        },
        personInfo: {
            subtitle: undefined,
            carOwnerYears: undefined,
            carOwnerYearsByAgeArray: [],
            customerAge: undefined,
            address: undefined,
            zipCode: undefined,
            zipName: undefined,
            streetName: undefined,
            houseNr: undefined,
            floorNr: undefined,
            doorNr: undefined,
            kvhxValue: undefined,
            almbrandCustomer: undefined,
            existingAlmBrandProducts: [],
        },
        choosePackage: {
            subtitle: undefined,
            ownRiskId: undefined,
            selectedPackage: undefined,
            monthYear: undefined,// monthYearArray[0].value,
            useTiaOffer: true,
        },
        overview: {
            subtitle: undefined,
            highlights: undefined,
            details: undefined,
        },
        contact_information: {
            subtitle: undefined,
            name: undefined,
            email: undefined,
            phone: undefined,
            cpr: undefined,
            customerNo: undefined,
            accept: false,
        },
        additionalInfo: {
            subtitle: undefined,
            existInsurance: undefined,
            existingInsurance: undefined,
            existingInsuranceId: undefined,
            existFormerInsurance: undefined,
            formerInsurance: undefined,
            formerInsuranceId: undefined,
            validNow: undefined,
            validFromDate: undefined,
            skader: undefined,
            rki: undefined,
        },
        payment: {
            subtitle: undefined,
            regNo: undefined,
            kontoNo: undefined,
        },
        pristineStep: new Map([[STEP.CAR_INFO.valueOf(), true],
            [STEP.PERSON_INFO.valueOf(), true],
            [STEP.PACKAGE.valueOf(), true],
            [STEP.ADDITIONAL_INFO.valueOf(), true],
            [STEP.OVERVIEW.valueOf(), true],
            [STEP.CONTACT_INFORMATION.valueOf(), true],
            [STEP.PAYMENT.valueOf(), true],
        ]),
        productName: InsurancesProductEnum.BIL_FORSIKRING,
        ownRisks: [] as Array<ValuePair>,
        calculation: {
            isCalculated: false,
            updatePrices: ['pr1', 'pr2','pr3'],
            excessIdDefault: undefined,
            packageIds: undefined,
            packages: undefined,
            abCalc: {
                excesses: [],
                packs: new Map<number, Object>(),
            },
            allCoverages: new Map<number, Map<number, Array<FeatureAdapter>>>(),
            discount: undefined,
            discountDisplay: undefined,
            addressSupplement: 1,
        },
        modal: {
            show: false,
            id: 'none',
            track: false,
            trackToken: undefined,
            content: undefined,
            btnStyle: undefined,
            btnLabel: undefined,
            btnAction: undefined,
            btnSecondStyle: undefined,
            btnSecondLabel: undefined,
            title: undefined,
            redirect: undefined,
        },
        modalId: 'none',
        showSpinner: false,
        showReciept: false,
        currentCardName: 'none',
        updateAll: 'updateAll',
        calculating: false,
        readyForBasket: false,
        multipleProducts: false,
        showExistingAlmBrandProducts: true,
    };

}
