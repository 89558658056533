// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Alm. Brand Cart API
 * Alm. Brands shopping cart API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: p.integration@almbrand.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "https://bluetest-api.almbrand.dk".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AddFeatureRequest
 */
export interface AddFeatureRequest {
    /**
     * The id of the feature that should be added - for example for Insurance that would be the Id of a coverage
     * @type {string}
     * @memberof AddFeatureRequest
     */
    feature_id?: string;
    /**
     * Any additional properties that needs to be set on the feature, for example for Insurance the risk sum.
     * @type {{ [key: string]: string; }}
     * @memberof AddFeatureRequest
     */
    feature_properties?: { [key: string]: string; };
}

/**
 * The affected entity is for showing indirect consequences when doing a change, e.g. when changning coverage area on the travl insurance that the price in-/de-crease on all the current coverages
 * @export
 * @interface AffectedEntity
 */
export interface AffectedEntity {
    /**
     * Display Name of the affected entity
     * @type {string}
     * @memberof AffectedEntity
     */
    display_name?: string;
    /**
     * The display price diff 
     * @type {string}
     * @memberof AffectedEntity
     */
    display_price_diff?: string;
}

/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    id: string;
    /**
     * 
     * @type {Array<ChangeLine>}
     * @memberof Cart
     */
    changes: Array<ChangeLine>;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    total_price?: number;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    display_total_price?: string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    currency_code: string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    locale_code: string;
    /**
     * 
     * @type {Date}
     * @memberof Cart
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    status: string;
}

/**
 * Change Line represents an change to a customer engagement with almbrand, be it add/remove/update product, feature or property
 * @export
 * @interface ChangeLine
 */
export interface ChangeLine {
    /**
     * Type of ChangeLine
     * @type {string}
     * @memberof ChangeLine
     */
    type: string;
    /**
     * The Display name for the change line
     * @type {string}
     * @memberof ChangeLine
     */
    display_name: string;
    /**
     * The new value of a change, if any
     * @type {string}
     * @memberof ChangeLine
     */
    display_value?: string;
    /**
     * Price Diff for the change line
     * @type {number}
     * @memberof ChangeLine
     */
    price_diff?: number;
    /**
     * Display Price Diff for the change line
     * @type {string}
     * @memberof ChangeLine
     */
    display_price_diff: string;
    /**
     * The AgreementId associated with the change line (the specific version of that line)
     * @type {string}
     * @memberof ChangeLine
     */
    agreement_id: string;
    /**
     * The Agreement Number associated with the change line (the number which is shown to the customer)
     * @type {string}
     * @memberof ChangeLine
     */
    agreement_no: string;
    /**
     * The Display name for the associated product
     * @type {string}
     * @memberof ChangeLine
     */
    product_display_name: string;
    /**
     * The affected entities is for showing indirect consequences when doing a change, e.g. when changing the property coverage area on the travl insurance this will contains all the current features and their price diff that happen because of this change
     * @type {Array<AffectedEntity>}
     * @memberof ChangeLine
     */
    affected_entities?: Array<AffectedEntity>;
    /**
     * Product properties set with this change
     * @type {{ [key: string]: string; }}
     * @memberof ChangeLine
     */
    properties?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof Problem
     */
    type?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof Problem
     */
    title?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof Problem
     */
    status?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof Problem
     */
    detail?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof Problem
     */
    instance?: string;
}

/**
 * 
 * @export
 * @interface Quote
 */
export interface Quote {
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    cartId?: string;
    /**
     * 
     * @type {Array<QuoteQuotes>}
     * @memberof Quote
     */
    quotes?: Array<QuoteQuotes>;
}

/**
 * 
 * @export
 * @interface QuoteQuotes
 */
export interface QuoteQuotes {
    /**
     * 
     * @type {string}
     * @memberof QuoteQuotes
     */
    agreement_id?: string;
    /**
     * 
     * @type {number}
     * @memberof QuoteQuotes
     */
    total_price?: number;
    /**
     * 
     * @type {string}
     * @memberof QuoteQuotes
     */
    display_total_price?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteQuotes
     */
    currency_code?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteQuotes
     */
    locale_code?: string;
    /**
     * 
     * @type {Date}
     * @memberof QuoteQuotes
     */
    created?: Date;
}

/**
 * 
 * @export
 * @interface QuoteRequest
 */
export interface QuoteRequest {
    /**
     * 
     * @type {Array<QuoteRequestQuote>}
     * @memberof QuoteRequest
     */
    Quote?: Array<QuoteRequestQuote>;
}

/**
 * 
 * @export
 * @interface QuoteRequestQuote
 */
export interface QuoteRequestQuote {
    /**
     * The type or id of the agreement that should be added - for example travel insurance or car insurance
     * @type {string}
     * @memberof QuoteRequestQuote
     */
    agreement_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuoteRequestQuote
     */
    risk_list?: Array<string>;
    /**
     * Any additional properties that needs to be set on the feature, for example for Insurance the risk sum.
     * @type {{ [key: string]: string; }}
     * @memberof QuoteRequestQuote
     */
    feature_properties?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface UpdatePropertyRequest
 */
export interface UpdatePropertyRequest {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdatePropertyRequest
     */
    properties: { [key: string]: string; };
}


/**
 * CartApi - axios parameter creator
 * @export
 */
export const CartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds a new feature to a given agreement, for example for insurance\\: Add a new coverage to an existing policy, or for leasing, add a new trim to a vehicle
         * @summary Add a new feature to the given agreement
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {AddFeatureRequest} AddFeatureRequest The feature to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeature(cartId: string, agreementId: string, AddFeatureRequest: AddFeatureRequest, options: any = {}): RequestArgs {
            // verify required parameter 'cartId' is not null or undefined
            if (cartId === null || cartId === undefined) {
                throw new RequiredError('cartId','Required parameter cartId was null or undefined when calling addFeature.');
            }
            // verify required parameter 'agreementId' is not null or undefined
            if (agreementId === null || agreementId === undefined) {
                throw new RequiredError('agreementId','Required parameter agreementId was null or undefined when calling addFeature.');
            }
            // verify required parameter 'AddFeatureRequest' is not null or undefined
            if (AddFeatureRequest === null || AddFeatureRequest === undefined) {
                throw new RequiredError('AddFeatureRequest','Required parameter AddFeatureRequest was null or undefined when calling addFeature.');
            }
            const localVarPath = `/cart/{cartId}/agreement/{agreementId}/features`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json;charset=UTF-8';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddFeatureRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(AddFeatureRequest || {}) : (AddFeatureRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change a property of an existing agreement, for example for insurance: Change the area which is covered by a travel insurance, or for leasing, changing the mileage of an existing vehicle. This is a key value pair, as this is different for each Line of Business
         * @summary Change the property of an existing agreement
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {UpdatePropertyRequest} UpdatePropertyRequest Describes a property and the new value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAgreementProperty(cartId: string, agreementId: string, UpdatePropertyRequest: UpdatePropertyRequest, options: any = {}): RequestArgs {
            // verify required parameter 'cartId' is not null or undefined
            if (cartId === null || cartId === undefined) {
                throw new RequiredError('cartId','Required parameter cartId was null or undefined when calling changeAgreementProperty.');
            }
            // verify required parameter 'agreementId' is not null or undefined
            if (agreementId === null || agreementId === undefined) {
                throw new RequiredError('agreementId','Required parameter agreementId was null or undefined when calling changeAgreementProperty.');
            }
            // verify required parameter 'UpdatePropertyRequest' is not null or undefined
            if (UpdatePropertyRequest === null || UpdatePropertyRequest === undefined) {
                throw new RequiredError('UpdatePropertyRequest','Required parameter UpdatePropertyRequest was null or undefined when calling changeAgreementProperty.');
            }
            const localVarPath = `/cart/{cartId}/agreement/{agreementId}/property`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json;charset=UTF-8';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdatePropertyRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(UpdatePropertyRequest || {}) : (UpdatePropertyRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change a property of an existing feature, for example for insurance: Change the risk sum on a coverage. This is a key value pair, as this is different for each Line of Business
         * @summary Change the property of an existing feature
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {string} featureId The feature id
         * @param {UpdatePropertyRequest} UpdatePropertyRequest Describes a property and the new value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFeatureProperty(cartId: string, agreementId: string, featureId: string, UpdatePropertyRequest: UpdatePropertyRequest, options: any = {}): RequestArgs {
            // verify required parameter 'cartId' is not null or undefined
            if (cartId === null || cartId === undefined) {
                throw new RequiredError('cartId','Required parameter cartId was null or undefined when calling changeFeatureProperty.');
            }
            // verify required parameter 'agreementId' is not null or undefined
            if (agreementId === null || agreementId === undefined) {
                throw new RequiredError('agreementId','Required parameter agreementId was null or undefined when calling changeFeatureProperty.');
            }
            // verify required parameter 'featureId' is not null or undefined
            if (featureId === null || featureId === undefined) {
                throw new RequiredError('featureId','Required parameter featureId was null or undefined when calling changeFeatureProperty.');
            }
            // verify required parameter 'UpdatePropertyRequest' is not null or undefined
            if (UpdatePropertyRequest === null || UpdatePropertyRequest === undefined) {
                throw new RequiredError('UpdatePropertyRequest','Required parameter UpdatePropertyRequest was null or undefined when calling changeFeatureProperty.');
            }
            const localVarPath = `/cart/{cartId}/agreement/{agreementId}/feature/{featureId}/property`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json;charset=UTF-8';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdatePropertyRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(UpdatePropertyRequest || {}) : (UpdatePropertyRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check out the specified cart
         * @summary Check out the specified cart
         * @param {string} cartId The cart id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkout(cartId: string, options: any = {}): RequestArgs {
            // verify required parameter 'cartId' is not null or undefined
            if (cartId === null || cartId === undefined) {
                throw new RequiredError('cartId','Required parameter cartId was null or undefined when calling checkout.');
            }
            const localVarPath = `/cart/{cartId}/checkout`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new empty cart for the current user.
         * @summary Create a new cart for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCart(options: any = {}): RequestArgs {
            const localVarPath = `/cart`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a specific card related to the current user
         * @summary Delete an existing cart
         * @param {string} cartId The cart id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCart(cartId: string, options: any = {}): RequestArgs {
            // verify required parameter 'cartId' is not null or undefined
            if (cartId === null || cartId === undefined) {
                throw new RequiredError('cartId','Required parameter cartId was null or undefined when calling deleteCart.');
            }
            const localVarPath = `/cart/{cartId}`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable new agreement by policyId
         * @summary Enable agreement
         * @param {string} agreementId The agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engageQuote(agreementId: string, options: any = {}): RequestArgs {
            // verify required parameter 'agreementId' is not null or undefined
            if (agreementId === null || agreementId === undefined) {
                throw new RequiredError('agreementId','Required parameter agreementId was null or undefined when calling engageQuote.');
            }
            const localVarPath = `/cart/quote/agreement/{agreementId}/engage`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A user can have multiple carts, and this allows for operating on a specific cart
         * @summary Gets a specific cart for the current user
         * @param {string} cartId The cart id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCart(cartId: string, options: any = {}): RequestArgs {
            // verify required parameter 'cartId' is not null or undefined
            if (cartId === null || cartId === undefined) {
                throw new RequiredError('cartId','Required parameter cartId was null or undefined when calling getCart.');
            }
            const localVarPath = `/cart/{cartId}`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the last cart created by the current user if any. Can be used when switching between different devices, so that the cart worked on by the user is always available
         * @summary Gets the last cart created by the current user if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastKnownCart(options: any = {}): RequestArgs {
            const localVarPath = `/cart`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a quote for one or more agreements
         * @summary Get quotes
         * @param {QuoteRequest} QuoteRequest One or more quotes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quote(QuoteRequest: QuoteRequest, options: any = {}): RequestArgs {
            // verify required parameter 'QuoteRequest' is not null or undefined
            if (QuoteRequest === null || QuoteRequest === undefined) {
                throw new RequiredError('QuoteRequest','Required parameter QuoteRequest was null or undefined when calling quote.');
            }
            const localVarPath = `/cart/quote/agreement`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json;charset=UTF-8';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"QuoteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(QuoteRequest || {}) : (QuoteRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes an existing feature from a given agreement.
         * @summary Remove an exiting feature from the given agreement
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {string} featureId The feature id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFeature(cartId: string, agreementId: string, featureId: string, options: any = {}): RequestArgs {
            // verify required parameter 'cartId' is not null or undefined
            if (cartId === null || cartId === undefined) {
                throw new RequiredError('cartId','Required parameter cartId was null or undefined when calling removeFeature.');
            }
            // verify required parameter 'agreementId' is not null or undefined
            if (agreementId === null || agreementId === undefined) {
                throw new RequiredError('agreementId','Required parameter agreementId was null or undefined when calling removeFeature.');
            }
            // verify required parameter 'featureId' is not null or undefined
            if (featureId === null || featureId === undefined) {
                throw new RequiredError('featureId','Required parameter featureId was null or undefined when calling removeFeature.');
            }
            const localVarPath = `/cart/{cartId}/agreement/{agreementId}/features/{featureId}`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartApi - functional programming interface
 * @export
 */
export const CartApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Adds a new feature to a given agreement, for example for insurance\\: Add a new coverage to an existing policy, or for leasing, add a new trim to a vehicle
         * @summary Add a new feature to the given agreement
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {AddFeatureRequest} AddFeatureRequest The feature to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeature(cartId: string, agreementId: string, AddFeatureRequest: AddFeatureRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).addFeature(cartId, agreementId, AddFeatureRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Change a property of an existing agreement, for example for insurance: Change the area which is covered by a travel insurance, or for leasing, changing the mileage of an existing vehicle. This is a key value pair, as this is different for each Line of Business
         * @summary Change the property of an existing agreement
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {UpdatePropertyRequest} UpdatePropertyRequest Describes a property and the new value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAgreementProperty(cartId: string, agreementId: string, UpdatePropertyRequest: UpdatePropertyRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).changeAgreementProperty(cartId, agreementId, UpdatePropertyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Change a property of an existing feature, for example for insurance: Change the risk sum on a coverage. This is a key value pair, as this is different for each Line of Business
         * @summary Change the property of an existing feature
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {string} featureId The feature id
         * @param {UpdatePropertyRequest} UpdatePropertyRequest Describes a property and the new value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFeatureProperty(cartId: string, agreementId: string, featureId: string, UpdatePropertyRequest: UpdatePropertyRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).changeFeatureProperty(cartId, agreementId, featureId, UpdatePropertyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Check out the specified cart
         * @summary Check out the specified cart
         * @param {string} cartId The cart id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkout(cartId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).checkout(cartId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Creates a new empty cart for the current user.
         * @summary Create a new cart for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCart(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).createCart(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Deletes a specific card related to the current user
         * @summary Delete an existing cart
         * @param {string} cartId The cart id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCart(cartId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).deleteCart(cartId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Enable new agreement by policyId
         * @summary Enable agreement
         * @param {string} agreementId The agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engageQuote(agreementId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).engageQuote(agreementId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * A user can have multiple carts, and this allows for operating on a specific cart
         * @summary Gets a specific cart for the current user
         * @param {string} cartId The cart id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCart(cartId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).getCart(cartId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns the last cart created by the current user if any. Can be used when switching between different devices, so that the cart worked on by the user is always available
         * @summary Gets the last cart created by the current user if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastKnownCart(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).getLastKnownCart(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Get a quote for one or more agreements
         * @summary Get quotes
         * @param {QuoteRequest} QuoteRequest One or more quotes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quote(QuoteRequest: QuoteRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quote> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).quote(QuoteRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Removes an existing feature from a given agreement.
         * @summary Remove an exiting feature from the given agreement
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {string} featureId The feature id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFeature(cartId: string, agreementId: string, featureId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart> {
            const localVarAxiosArgs = CartApiAxiosParamCreator(configuration).removeFeature(cartId, agreementId, featureId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CartApi - factory interface
 * @export
 */
export const CartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Adds a new feature to a given agreement, for example for insurance\\: Add a new coverage to an existing policy, or for leasing, add a new trim to a vehicle
         * @summary Add a new feature to the given agreement
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {AddFeatureRequest} AddFeatureRequest The feature to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeature(cartId: string, agreementId: string, AddFeatureRequest: AddFeatureRequest, options?: any) {
            return CartApiFp(configuration).addFeature(cartId, agreementId, AddFeatureRequest, options)(axios, basePath);
        },
        /**
         * Change a property of an existing agreement, for example for insurance: Change the area which is covered by a travel insurance, or for leasing, changing the mileage of an existing vehicle. This is a key value pair, as this is different for each Line of Business
         * @summary Change the property of an existing agreement
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {UpdatePropertyRequest} UpdatePropertyRequest Describes a property and the new value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAgreementProperty(cartId: string, agreementId: string, UpdatePropertyRequest: UpdatePropertyRequest, options?: any) {
            return CartApiFp(configuration).changeAgreementProperty(cartId, agreementId, UpdatePropertyRequest, options)(axios, basePath);
        },
        /**
         * Change a property of an existing feature, for example for insurance: Change the risk sum on a coverage. This is a key value pair, as this is different for each Line of Business
         * @summary Change the property of an existing feature
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {string} featureId The feature id
         * @param {UpdatePropertyRequest} UpdatePropertyRequest Describes a property and the new value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFeatureProperty(cartId: string, agreementId: string, featureId: string, UpdatePropertyRequest: UpdatePropertyRequest, options?: any) {
            return CartApiFp(configuration).changeFeatureProperty(cartId, agreementId, featureId, UpdatePropertyRequest, options)(axios, basePath);
        },
        /**
         * Check out the specified cart
         * @summary Check out the specified cart
         * @param {string} cartId The cart id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkout(cartId: string, options?: any) {
            return CartApiFp(configuration).checkout(cartId, options)(axios, basePath);
        },
        /**
         * Creates a new empty cart for the current user.
         * @summary Create a new cart for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCart(options?: any) {
            return CartApiFp(configuration).createCart(options)(axios, basePath);
        },
        /**
         * Deletes a specific card related to the current user
         * @summary Delete an existing cart
         * @param {string} cartId The cart id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCart(cartId: string, options?: any) {
            return CartApiFp(configuration).deleteCart(cartId, options)(axios, basePath);
        },
        /**
         * Enable new agreement by policyId
         * @summary Enable agreement
         * @param {string} agreementId The agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engageQuote(agreementId: string, options?: any) {
            return CartApiFp(configuration).engageQuote(agreementId, options)(axios, basePath);
        },
        /**
         * A user can have multiple carts, and this allows for operating on a specific cart
         * @summary Gets a specific cart for the current user
         * @param {string} cartId The cart id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCart(cartId: string, options?: any) {
            return CartApiFp(configuration).getCart(cartId, options)(axios, basePath);
        },
        /**
         * Returns the last cart created by the current user if any. Can be used when switching between different devices, so that the cart worked on by the user is always available
         * @summary Gets the last cart created by the current user if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastKnownCart(options?: any) {
            return CartApiFp(configuration).getLastKnownCart(options)(axios, basePath);
        },
        /**
         * Get a quote for one or more agreements
         * @summary Get quotes
         * @param {QuoteRequest} QuoteRequest One or more quotes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quote(QuoteRequest: QuoteRequest, options?: any) {
            return CartApiFp(configuration).quote(QuoteRequest, options)(axios, basePath);
        },
        /**
         * Removes an existing feature from a given agreement.
         * @summary Remove an exiting feature from the given agreement
         * @param {string} cartId The cart id
         * @param {string} agreementId The agreement id
         * @param {string} featureId The feature id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFeature(cartId: string, agreementId: string, featureId: string, options?: any) {
            return CartApiFp(configuration).removeFeature(cartId, agreementId, featureId, options)(axios, basePath);
        },
    };
};

/**
 * CartApi - object-oriented interface
 * @export
 * @class CartApi
 * @extends {BaseAPI}
 */
export class CartApi extends BaseAPI {
    /**
     * Adds a new feature to a given agreement, for example for insurance\\: Add a new coverage to an existing policy, or for leasing, add a new trim to a vehicle
     * @summary Add a new feature to the given agreement
     * @param {string} cartId The cart id
     * @param {string} agreementId The agreement id
     * @param {AddFeatureRequest} AddFeatureRequest The feature to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public addFeature(cartId: string, agreementId: string, AddFeatureRequest: AddFeatureRequest, options?: any) {
        return CartApiFp(this.configuration).addFeature(cartId, agreementId, AddFeatureRequest, options)(this.axios, this.basePath);
    }

    /**
     * Change a property of an existing agreement, for example for insurance: Change the area which is covered by a travel insurance, or for leasing, changing the mileage of an existing vehicle. This is a key value pair, as this is different for each Line of Business
     * @summary Change the property of an existing agreement
     * @param {string} cartId The cart id
     * @param {string} agreementId The agreement id
     * @param {UpdatePropertyRequest} UpdatePropertyRequest Describes a property and the new value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public changeAgreementProperty(cartId: string, agreementId: string, UpdatePropertyRequest: UpdatePropertyRequest, options?: any) {
        return CartApiFp(this.configuration).changeAgreementProperty(cartId, agreementId, UpdatePropertyRequest, options)(this.axios, this.basePath);
    }

    /**
     * Change a property of an existing feature, for example for insurance: Change the risk sum on a coverage. This is a key value pair, as this is different for each Line of Business
     * @summary Change the property of an existing feature
     * @param {string} cartId The cart id
     * @param {string} agreementId The agreement id
     * @param {string} featureId The feature id
     * @param {UpdatePropertyRequest} UpdatePropertyRequest Describes a property and the new value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public changeFeatureProperty(cartId: string, agreementId: string, featureId: string, UpdatePropertyRequest: UpdatePropertyRequest, options?: any) {
        return CartApiFp(this.configuration).changeFeatureProperty(cartId, agreementId, featureId, UpdatePropertyRequest, options)(this.axios, this.basePath);
    }

    /**
     * Check out the specified cart
     * @summary Check out the specified cart
     * @param {string} cartId The cart id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public checkout(cartId: string, options?: any) {
        return CartApiFp(this.configuration).checkout(cartId, options)(this.axios, this.basePath);
    }

    /**
     * Creates a new empty cart for the current user.
     * @summary Create a new cart for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public createCart(options?: any) {
        return CartApiFp(this.configuration).createCart(options)(this.axios, this.basePath);
    }

    /**
     * Deletes a specific card related to the current user
     * @summary Delete an existing cart
     * @param {string} cartId The cart id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public deleteCart(cartId: string, options?: any) {
        return CartApiFp(this.configuration).deleteCart(cartId, options)(this.axios, this.basePath);
    }

    /**
     * Enable new agreement by policyId
     * @summary Enable agreement
     * @param {string} agreementId The agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public engageQuote(agreementId: string, options?: any) {
        return CartApiFp(this.configuration).engageQuote(agreementId, options)(this.axios, this.basePath);
    }

    /**
     * A user can have multiple carts, and this allows for operating on a specific cart
     * @summary Gets a specific cart for the current user
     * @param {string} cartId The cart id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getCart(cartId: string, options?: any) {
        return CartApiFp(this.configuration).getCart(cartId, options)(this.axios, this.basePath);
    }

    /**
     * Returns the last cart created by the current user if any. Can be used when switching between different devices, so that the cart worked on by the user is always available
     * @summary Gets the last cart created by the current user if any
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getLastKnownCart(options?: any) {
        return CartApiFp(this.configuration).getLastKnownCart(options)(this.axios, this.basePath);
    }

    /**
     * Get a quote for one or more agreements
     * @summary Get quotes
     * @param {QuoteRequest} QuoteRequest One or more quotes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public quote(QuoteRequest: QuoteRequest, options?: any) {
        return CartApiFp(this.configuration).quote(QuoteRequest, options)(this.axios, this.basePath);
    }

    /**
     * Removes an existing feature from a given agreement.
     * @summary Remove an exiting feature from the given agreement
     * @param {string} cartId The cart id
     * @param {string} agreementId The agreement id
     * @param {string} featureId The feature id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public removeFeature(cartId: string, agreementId: string, featureId: string, options?: any) {
        return CartApiFp(this.configuration).removeFeature(cartId, agreementId, featureId, options)(this.axios, this.basePath);
    }

}

