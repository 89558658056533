import store from "@/store";
import InsurancesCommon from "@/views/model/InsurancesCommon";
import ServiceEnum from "@/views/model/ServiceEnum";
import { AbLink } from "@/views/sharedcomponents/baseComponents/components";
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking";
import { mixins, Options } from "vue-class-component";
import { mapState } from "vuex";
import template from "./Menu.vue";

@Options({
  name: "selfservice-menu-component",
  mixins: [template],
  components: {
    AbLink,
  },
  computed: mapState<any>({
    model: (state) => state.epiRouting,
  }),
})
export default class Menu extends mixins(InsurancesCommon) {
  windowWidth: number = null;
  model: any;
  trackingUrl: string;

  beforeMount() {
    this.trackingUrl = Tracking.buildTrackingUrl(
      store.getters.getCustomer,
      this.$route.fullPath
    );

    let root = document.documentElement;
    let index = 0;
    this.model.forEach((nav) => {
      root.style.setProperty(
        `--tab-${index}-nav-icon`,
        `url(${nav.svgIconReference?.url})`
      );
      index++;
    });
  }

  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", this.updateWindowWidth);
    window.addEventListener("click", this.closeNavigation);
    window.addEventListener("keydown", this.closeNavigation);
  }

  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
    window.removeEventListener("click", this.closeNavigation);
    window.removeEventListener("keydown", this.closeNavigation);
  }

  // COMPUTED
  get themeContext() {
    return store.getters.getThemeContext;
  }

  get menuItems() {
    return store.getters.getEpiRouting;
  }

  get logoUrl() {
    return store.getters.getLogoUrl;
  }

  get menuLabel() {
    return this.isThemed ? "Primær" : "Mit Alm. Brand";
  }

  // METHODS
  /*TODO aberec
    public getIcon(icon) {
        const iconName = icon || "fallback";
        const iconPath = `/src/assets/svg/selfservice/menu/${iconName}.svg`;

        const style = `mask-image: url(${iconPath})`;
        return style;
    }*/

  public updateWindowWidth() {
    this.windowWidth = window.innerWidth;
  }

  public hasSubmenu(route) {
    const isMobile = this.windowWidth < 768;
    return !isMobile && route?.menuPoints?.length > 0;
  }

  public toggleMenu(route) {
    const menu = this.$refs[`sub-menu-${route}`];
    const toggle = this.$refs[`toggle-${route}`];
    const isOpen = (menu as any).classList.contains("open");

    this.closeActiveMenu();

    if (!isOpen) {
      (toggle as any).setAttribute("aria-expanded", "true");
      (menu as any).classList.add("open");
    }
  }

  public closeActiveMenu() {
    const openMenu = document.querySelector(".primary-navigation__item.open");
    if (openMenu) {
      const openMenuToggle = openMenu.querySelector(
        ".primary-navigation__link--toggle"
      );
      openMenuToggle.setAttribute("aria-expanded", "false");
      openMenu.classList.remove("open");
    }
  }

  public closeNavigation(e) {
    const event = e as any;
    // If clicked outside menu or pressed ESC
    const cssClass = document.querySelector(".primary-navigation");
    if (
      (cssClass !== null && !cssClass.contains(event.target)) ||
      event.keyCode === 27
    ) {
      this.closeActiveMenu();
    }
  }

  public logoutUrl() {
    return ServiceEnum.LOGOUT_SERVICE;
  }
}
