<template>
    <!--Covered / Not covered-->
    <div class="accordion">
        <h2 class="accordion__list-headline" v-if="isCovered && coverages.length > 0" v-html="coveredText"></h2>
        <h2 class="accordion__list-headline" v-if="!isCovered && coverages.length > 0" v-html="notCoveredText"></h2>
        <h3 class="accordion__list-subtitle" v-if="contentItem.model.subtitle && coverages.length > 0" v-html="contentItem.model.subtitle"></h3>
        <productline-feature-component v-for="coverage in coverages"
            :key="coverage.id"
            :coverage="coverage"
            :productTitle="productTitle"
            :isCovered="isCovered"
            :track="track"
            :trackGtm="trackGtm"
            :trackGtmLabel="trackGtmLabel"
            :btnLabel="contentItem.model.btnLabel"
            :btnClass="contentItem.model.btnClass"
        />
    </div>
</template>
