import { BasketProductsEnum, CustomerCalculation, UPDATE_CALCULATOR_INDEX } from '@/episerver/store/modules/calculatorContext';
import { SelectableData, UPDATE_SELECTABLE } from '@/episerver/store/modules/selectableContext';
import store from "@/store";
import InsurancesProductEnum from "@/views/model/InsurancesProductEnum";
import { BuyInsuranceHelper } from "./BuyInsuranceHelper";

export default class UrlHandler {

    private static PATH: string;

    public static get path() {
        if(!this.PATH) {
            if (location.href.includes('mitalmbrand') ) {
                this.PATH = '/mitalmbrand/forsikring/beregn/';
            } else {
                this.PATH = '/beregn/';
            }
        }
        return this.PATH;
    }

    public static getTrackingPreUrl() {
        try {
            const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
            if (customerCalculation.customerFetched && customerCalculation.customer.contact_information.customerNo) {
                return '/virtualurl/mitalmbrand/';
            }
        } catch(e) {
            // Too bad
        }
        return '/virtualurl/';
    }

    // preselect products in basket if they are present in Url
    public static async handlePreselectedProducts(query) {
        try {
            const promises = [];
            if (query.products) {
                const original = query.products.toLocaleLowerCase();
                const productsRaw = original.split(',');
                const basket = new Array<string>();
                const products = [];
                // handle multiple insurances (cars, accidents)
                let carInx = -1;
                let accidentInx = -1;
                productsRaw.forEach(prod => {
                    prod = prod.trim();
                    if (prod.includes(InsurancesProductEnum.BIL_FORSIKRING)) {
                        carInx++;
                        products.push(BuyInsuranceHelper.stripProductIndex(prod) + carInx); // insure indexes on each instance
                    } else if (prod.includes(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
                        accidentInx++;
                        products.push(BuyInsuranceHelper.stripProductIndex(prod) + accidentInx); // insure indexes on each instance
                    } else {
                        products.push(prod);
                    }
                });

                products.forEach(product => {
                    let p = product;
                    if (p === BuyInsuranceHelper.stripProductIndex(p)) {
                        // no index on product, set to 0
                        p += '0';
                    }
                    switch (p) {
                        case BasketProductsEnum.BIL_FORSIKRING0:
                        case BasketProductsEnum.BIL_FORSIKRING1:
                        case BasketProductsEnum.BIL_FORSIKRING2:
                        case BasketProductsEnum.BIL_FORSIKRING3:
                        case BasketProductsEnum.BIL_FORSIKRING4:
                        case BasketProductsEnum.BIL_FORSIKRING5:
                        case BasketProductsEnum.INDBO_FORSIKRING0:
                        case BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0:
                        case BasketProductsEnum.ULYKKES_FORSIKRING0:
                        case BasketProductsEnum.ULYKKES_FORSIKRING1:
                        case BasketProductsEnum.ULYKKES_FORSIKRING2:
                        case BasketProductsEnum.ULYKKES_FORSIKRING3:
                        case BasketProductsEnum.ULYKKES_FORSIKRING4:
                        case BasketProductsEnum.ULYKKES_FORSIKRING5:
                        case BasketProductsEnum.ULYKKES_FORSIKRING6:
                        case BasketProductsEnum.ULYKKES_FORSIKRING7:
                        case BasketProductsEnum.ULYKKES_FORSIKRING8:
                        case BasketProductsEnum.ULYKKES_FORSIKRING9:
                        case BasketProductsEnum.BOERNEULYKKES_FORSIKRING0:
                        case BasketProductsEnum.HUNDE_FORSIKRING0:
                        case BasketProductsEnum.HUS_FORSIKRING0:
                        case BasketProductsEnum.FRITIDSHUS_FORSIKRING0:
                        case BasketProductsEnum.REJSE_FORSIKRING0: if (!basket.includes(p)) {
                                                                basket.push(p);
                                                            }
                                                            break;
                        default: break;
                    }

                    basket.forEach(p => {
                        const toggle: SelectableData = {
                            group: 'basket',
                            id: p,
                            isSelected: true,
                            sortMultiple: true,
                        }
                        promises.push(store.dispatch(UPDATE_SELECTABLE, toggle));
                    });
                });
                await Promise.all(promises);
            }
        } catch (error) {
            // too bad
        }
    }

    public static async gotoFirstProductInBasket(): Promise<string> {
        const basket = store.getters.getSelectableGroup('basket');
        const results = [];
        const multiple = basket.length > 1;
        if (multiple) {
            basket.forEach(name => {
                const calc = store.getters.getCalculator(name);
                if(!calc) {
                    results.push(name);
                } else if(!calc.model.readyForBasket) {
                    results.push(name);
                    calc.model.multiple = true;
                }
            });
        } else if(basket.length === 1) { // only 1 product, not basket mode
            results.push(basket[0]);
        } else {
            results.push('forsikringer'); // no products at all goto choose page
        }

        if(multiple && results.length === 0) { // all products calculated and validated - goto basket
            results.push('kurv');
        }

        const calculatorIndex = BuyInsuranceHelper.getCalculatorIndex(results[0]);
        if (calculatorIndex > -1) {
            await store.dispatch(UPDATE_CALCULATOR_INDEX, calculatorIndex);
        }
                    
        const page: string = BuyInsuranceHelper.getUrlProductName(results[0]);

        return this.path + page;

    }


}
