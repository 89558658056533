import { Vue, Options, mixins } from "vue-class-component";
import template from './CoveragesBlockComponent.vue';
import CoveragesBlock, { CoveragesBlockType } from '../../contentTypes/CoveragesBlockCd'
import ProductlineFeature from '@/views/overview/insurances/productline/productlineFeature';

@Options({
    name: 'coverages-block-component',
    mixins: [template],
    components: {
        'productline-feature-component': ProductlineFeature,
    },
    props:{
        contentItem: CoveragesBlock,
        coverages: Array,
        status: String,
        productTitle: String,
        track: {type: Boolean, default: true }, // track using css classes
        trackGtm: Boolean,// track using gtm attributes
        trackGtmLabel: String// optional label for gtm attributes
    }
})
export default class CoveragesBlockComponent extends Vue {
    contentItem?: CoveragesBlock;
    coverages?: Array<any>;
    status?: string;
    productTitle?: string;
    track: {type: Boolean, default: true }; // track using css classes
    trackGtm?: boolean;// track using gtm attributes
    trackGtmLabel?: string;// optional label for gtm attributes

    public isCovered: boolean = false;

    mounted() {
        this.isCovered = this.contentItem.model.dataPlaceholder === CoveragesBlockType.Daekker;
    }

    get coveredText() {
        return this.contentItem.model.title ? this.contentItem.model.title : "Din forsikring d&aelig;kker";
    }

    get notCoveredText() {
        return this.contentItem.model.title ? this.contentItem.model.title : "Din forsikring d&aelig;kker ikke";
    }
}
