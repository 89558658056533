import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import template from './ProductlineSubFeature.vue';
import { isNullOrUndefined } from '@/views/common/util';
import InsurancesProductEnum from '@/views/model/InsurancesProductEnum';
import InsurancesProductType from '@/views/model/InsurancesProductType';
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking";
import store from '@/store';

@Options({
    name: 'productline-sub-feature',
    mixins: [template],
    props: {
        id: String,
        headline: String,
        desc: String,
        excess: Number,
        sum: Number,
        productTitle: String,
        isCovered: Boolean
    }
})
export default class ProductlineSubFeature extends mixins(InsurancesCommon) {
    public showSubFeature: boolean = false;

    id: string;
    headline: string;
    desc: string;
    excess: number;
    sum: number;
    productTitle: String;
    isCovered: boolean;

    trackingUrl: string;

    public formattedText: string = "";
    public highlights: Array<any> = [];

    beforeMount() {
        this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
    }

    mounted() {
        this.setDefaultHighlights();
        this.formattedText = this.formatTextToClickable(this.desc);
    }

    public animateSubAccordion(content) {
        if (content) {
            this.showSubFeature = !this.showSubFeature;
        }
    }

    private setDefaultHighlights() {

        //TODO skal flyttes i integration
        if (this.productTitle.toLocaleLowerCase() === InsurancesProductEnum.REJSE_FORSIKRING) {
            const bagageforsinkelseProductType = new InsurancesProductType(this.id);
            //Der skal ikke vises sum på bagageforsinkelse
            if (bagageforsinkelseProductType.productRiskNo == 5) {
                return;
            }
        }

        if (!isNullOrUndefined(this.sum) && this.sum > 0) {
            this.highlights.push({
                label: 'Forsikringssum',
                value: this.formatPrice(this.sum) + ' kr.'
            });
        }

        switch (this.productTitle.toLocaleLowerCase()) {
            case InsurancesProductEnum.HUS_FORSIKRING:
                const houseProductType = new InsurancesProductType(this.id);
                if (houseProductType.productRiskNo == 5) {
                    this.highlights.push({
                        label: 'Selvrisiko',
                        value: '10 % af skaden,<br /> dog mindst 2.500 kr'
                    });
                }
            default:
                if (!isNullOrUndefined(this.excess)) {
                    this.highlights.push({
                        label: 'Selvrisiko',
                        value: this.formatPrice(this.excess) + ' kr.'
                    });
                }
        }

        if (this.isCovered) {
            const title = this.productTitle.toLocaleLowerCase();
            switch (title) {
                case InsurancesProductEnum.INDBO_FORSIKRING:
                case InsurancesProductEnum.BIL_FORSIKRING:
                case InsurancesProductEnum.HUS_FORSIKRING:
                //case InsurancesProductEnum.EJERSKIFTE_FORSIKRING:
                case InsurancesProductEnum.FRITIDSHUS_FORSIKRING:
                    if (isNullOrUndefined(this.excess)) {
                        this.highlights.push({
                            label: 'Selvrisiko',
                            value: 'Ingen'
                        });
                    }

                default:
                    break;
            }
        }
    }
}
