import idleTimeout from 'idle-timeout';

export default class LogoutHandler {
    static handleIdleLogout(logoutLocation: string, timeInMinutes?: number) {
        let time = 1000 * 60 * 20;
        if (timeInMinutes) {
            time = 1000 * 60 * timeInMinutes;
        }
        idleTimeout (
            () => {
                window.location.href = logoutLocation;
            },
            {
                timeout: time,
                loop: true,
                element: (<any>document)
            }
        );
    }
}
