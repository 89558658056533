// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Customer Document API
 * # Customer Document API API Customer Document receives files via POST and puts them on a RabbitMQ queue 
 *
 * OpenAPI spec version: 0.0.1.01
 * Contact: P.Integration@almbrand.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "http://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface CommonProblem
 */
export interface CommonProblem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof CommonProblem
     */
    type?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof CommonProblem
     */
    title?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof CommonProblem
     */
    status?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof CommonProblem
     */
    detail?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof CommonProblem
     */
    instance?: string;
}

/**
 * 
 * @export
 * @interface CustomerDocument
 */
export interface CustomerDocument {
    /**
     * 
     * @type {string}
     * @memberof CustomerDocument
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocument
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocument
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocument
     */
    topic?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocument
     */
    doc_type?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocument
     */
    content_type?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocument
     */
    date?: string;
}

/**
 * 
 * @export
 * @interface CustomerDocumentList
 */
export interface CustomerDocumentList {
    /**
     * 
     * @type {Array<CustomerDocument>}
     * @memberof CustomerDocumentList
     */
    documents?: Array<CustomerDocument>;
}

/**
 * 
 * @export
 * @interface EmailContact
 */
export interface EmailContact {
    /**
     * 
     * @type {string}
     * @memberof EmailContact
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailContact
     */
    value?: string;
}

/**
 * 
 * @export
 * @interface FileData
 */
export interface FileData {
    /**
     * 
     * @type {string}
     * @memberof FileData
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof FileData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FileData
     */
    mimeType?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MailType {
    SEND = 'SEND',
    RECEIVED = 'RECEIVED'
}

/**
 * 
 * @export
 * @interface PostEmailRequest
 */
export interface PostEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof PostEmailRequest
     */
    customerNo?: number;
    /**
     * 
     * @type {number}
     * @memberof PostEmailRequest
     */
    policyNo?: number;
    /**
     * 
     * @type {number}
     * @memberof PostEmailRequest
     */
    clacaseNo?: number;
    /**
     * 
     * @type {string}
     * @memberof PostEmailRequest
     */
    securityGroup?: string;
    /**
     * 
     * @type {MailType}
     * @memberof PostEmailRequest
     */
    mailType?: MailType;
    /**
     * 
     * @type {boolean}
     * @memberof PostEmailRequest
     */
    publicView?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PostEmailRequest
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof PostEmailRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PostEmailRequest
     */
    body?: string;
    /**
     * 
     * @type {EmailContact}
     * @memberof PostEmailRequest
     */
    from?: EmailContact;
    /**
     * 
     * @type {Array<EmailContact>}
     * @memberof PostEmailRequest
     */
    to?: Array<EmailContact>;
    /**
     * 
     * @type {Array<EmailContact>}
     * @memberof PostEmailRequest
     */
    cc?: Array<EmailContact>;
    /**
     * 
     * @type {Array<EmailContact>}
     * @memberof PostEmailRequest
     */
    bcc?: Array<EmailContact>;
    /**
     * 
     * @type {Array<FileData>}
     * @memberof PostEmailRequest
     */
    attachments?: Array<FileData>;
}

/**
 * 
 * @export
 * @interface PostFileRequest
 */
export interface PostFileRequest {
    /**
     * 
     * @type {number}
     * @memberof PostFileRequest
     */
    customerNo?: number;
    /**
     * 
     * @type {number}
     * @memberof PostFileRequest
     */
    policyNo?: number;
    /**
     * 
     * @type {number}
     * @memberof PostFileRequest
     */
    clacaseNo?: number;
    /**
     * 
     * @type {string}
     * @memberof PostFileRequest
     */
    securityGroup?: string;
    /**
     * 
     * @type {MailType}
     * @memberof PostFileRequest
     */
    mailType?: MailType;
    /**
     * 
     * @type {boolean}
     * @memberof PostFileRequest
     */
    publicView?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PostFileRequest
     */
    timestamp?: Date;
}

/**
 * 
 * @export
 * @interface PostFileResult
 */
export interface PostFileResult {
    /**
     * 
     * @type {boolean}
     * @memberof PostFileResult
     */
    Success?: boolean;
}


/**
 * CustomerAgreementDocumentApi - axios parameter creator
 * @export
 */
export const CustomerAgreementDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetch customer document content
         * @param {string} document_id Unique id for an document.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerDocument(document_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'document_id' is not null or undefined
            if (document_id === null || document_id === undefined) {
                throw new RequiredError('document_id','Required parameter document_id was null or undefined when calling fetchCustomerDocument.');
            }
            const localVarPath = `/customer/documents/{document_id}`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAgreementDocumentApi - functional programming interface
 * @export
 */
export const CustomerAgreementDocumentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetch customer document content
         * @param {string} document_id Unique id for an document.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerDocument(document_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = CustomerAgreementDocumentApiAxiosParamCreator(configuration).fetchCustomerDocument(document_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CustomerAgreementDocumentApi - factory interface
 * @export
 */
export const CustomerAgreementDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary fetch customer document content
         * @param {string} document_id Unique id for an document.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerDocument(document_id: string, options?: any) {
            return CustomerAgreementDocumentApiFp(configuration).fetchCustomerDocument(document_id, options)(axios, basePath);
        },
    };
};

/**
 * CustomerAgreementDocumentApi - object-oriented interface
 * @export
 * @class CustomerAgreementDocumentApi
 * @extends {BaseAPI}
 */
export class CustomerAgreementDocumentApi extends BaseAPI {
    /**
     * 
     * @summary fetch customer document content
     * @param {string} document_id Unique id for an document.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAgreementDocumentApi
     */
    public fetchCustomerDocument(document_id: string, options?: any) {
        return CustomerAgreementDocumentApiFp(this.configuration).fetchCustomerDocument(document_id, options)(this.axios, this.basePath);
    }

}

/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetches customers document meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerDocuments(options: any = {}): RequestArgs {
            const localVarPath = `/customer/documents`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary post emails which will be published to the archiver
         * @param {PostEmailRequest} PostEmailRequest A nice request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmail(PostEmailRequest: PostEmailRequest, options: any = {}): RequestArgs {
            // verify required parameter 'PostEmailRequest' is not null or undefined
            if (PostEmailRequest === null || PostEmailRequest === undefined) {
                throw new RequiredError('PostEmailRequest','Required parameter PostEmailRequest was null or undefined when calling postEmail.');
            }
            const localVarPath = `/customer/communications/email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PostEmailRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(PostEmailRequest || {}) : (PostEmailRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetches customers document meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerDocuments(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDocumentList> {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).fetchCustomerDocuments(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary post emails which will be published to the archiver
         * @param {PostEmailRequest} PostEmailRequest A nice request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmail(PostEmailRequest: PostEmailRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostFileResult> {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).postEmail(PostEmailRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary fetches customers document meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerDocuments(options?: any) {
            return SystemApiFp(configuration).fetchCustomerDocuments(options)(axios, basePath);
        },
        /**
         * 
         * @summary post emails which will be published to the archiver
         * @param {PostEmailRequest} PostEmailRequest A nice request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmail(PostEmailRequest: PostEmailRequest, options?: any) {
            return SystemApiFp(configuration).postEmail(PostEmailRequest, options)(axios, basePath);
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @summary fetches customers document meta data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public fetchCustomerDocuments(options?: any) {
        return SystemApiFp(this.configuration).fetchCustomerDocuments(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary post emails which will be published to the archiver
     * @param {PostEmailRequest} PostEmailRequest A nice request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public postEmail(PostEmailRequest: PostEmailRequest, options?: any) {
        return SystemApiFp(this.configuration).postEmail(PostEmailRequest, options)(this.axios, this.basePath);
    }

}

