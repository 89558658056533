// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Insurance Car Quote API
 * Enables retrieval of price offers and creation/activation of price quotes for car insurance products
 *
 * OpenAPI spec version: 1.0.0
 * Contact: api@almbrand.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "http://localhost/insurance/car".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * The name of the street
     * @type {string}
     * @memberof Address
     */
    street?: string;
    /**
     * house_number that identifies the address in question in relation to other addresses with the same street name. The house number consists of a number 1-999 possibly. supplemented by a capital letter A..Z, and fixed in ascending order, usually with even and odd numbers on each side of the road. Example: '11', '12A', '187B'.
     * @type {string}
     * @memberof Address
     */
    house_number?: string;
    /**
     * Floor (designation). If value is specified, it can assume the following values: numbers from 1 to 99, st, kl, k2 up to k99.
     * @type {string}
     * @memberof Address
     */
    floor?: string;
    /**
     * Door (designation). If value is specified, it can assume the following values: numbers from 1 to 9999, lowercase letters and the characters / and -.
     * @type {string}
     * @memberof Address
     */
    door?: string;
    /**
     * The postal code in which the address is located.
     * @type {number}
     * @memberof Address
     */
    postal_code?: number;
    /**
     * The name associated with the postal code, typically the name of the city or district. Represented by up to 20 characters. Example: 'København NV'.
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * If the address has/requires 'adressebeskyttelse'. see also https://www.borger.dk/bolig-og-flytning/flytning_oversigt/Adressebeskyttelse
     * @type {boolean}
     * @memberof Address
     */
    _protected?: boolean;
}

/**
 * 
 * @export
 * @interface AgentParty
 */
export interface AgentParty {
    /**
     * 
     * @type {string}
     * @memberof AgentParty
     */
    user_id?: string;
    /**
     * 
     * @type {Person}
     * @memberof AgentParty
     */
    person?: Person;
    /**
     * 
     * @type {Organization}
     * @memberof AgentParty
     */
    organization?: Organization;
    /**
     * 
     * @type {string}
     * @memberof AgentParty
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParty
     */
    phone?: string;
    /**
     * 
     * @type {Address}
     * @memberof AgentParty
     */
    address?: Address;
    /**
     * 
     * @type {PartyStatements}
     * @memberof AgentParty
     */
    statements?: PartyStatements;
    /**
     * 
     * @type {boolean}
     * @memberof AgentParty
     */
    referer?: boolean;
}

/**
 * The offer request with enough information to calculate an offer price for a product. see '/products' resource for a specification of product parameter and features.
 * @export
 * @interface CreateOffer
 */
export interface CreateOffer {
    /**
     * 
     * @type {Product}
     * @memberof CreateOffer
     */
    product?: Product;
}

/**
 * The quote request with information to calculate a quote price.
 * @export
 * @interface CreateQuote
 */
export interface CreateQuote {
    /**
     * 
     * @type {Product}
     * @memberof CreateQuote
     */
    product?: Product;
    /**
     * 
     * @type {PolicyConstructor}
     * @memberof CreateQuote
     */
    policy?: PolicyConstructor;
    /**
     * 
     * @type {AgentParty}
     * @memberof CreateQuote
     */
    agent?: AgentParty;
}

/**
 * The calculated offer with price and coverage information.
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {OfferPrice}
     * @memberof Offer
     */
    price?: OfferPrice;
    /**
     * 
     * @type {Product}
     * @memberof Offer
     */
    product?: Product;
    /**
     * 
     * @type {Array<OfferPolicy>}
     * @memberof Offer
     */
    policies?: Array<OfferPolicy>;
    /**
     * 
     * @type {PrivacyPolicy}
     * @memberof Offer
     */
    privacy?: PrivacyPolicy;
    /**
     * The date and time where the offer was retrieved
     * @type {Date}
     * @memberof Offer
     */
    created_at?: Date;
    /**
     * The date and time where the offer will expire
     * @type {Date}
     * @memberof Offer
     */
    expires_at?: Date;
}

/**
 * Policy information for the offer.
 * @export
 * @interface OfferPolicy
 */
export interface OfferPolicy {
    /**
     * Href to the Insurance Product Information Document.
     * @type {string}
     * @memberof OfferPolicy
     */
    ipid_href?: string;
    /**
     * 
     * @type {Array<PolicyCoverage>}
     * @memberof OfferPolicy
     */
    coverages?: Array<PolicyCoverage>;
}

/**
 * The offer price with annual and monthly price.
 * @export
 * @interface OfferPrice
 */
export interface OfferPrice {
    /**
     * Tthe annually price.
     * @type {number}
     * @memberof OfferPrice
     */
    annually?: number;
    /**
     * The monthly price.
     * @type {number}
     * @memberof OfferPrice
     */
    monthly?: number;
    /**
     * The currency.
     * @type {string}
     * @memberof OfferPrice
     */
    currency?: string;
}

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    cvr_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    cvrp_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface Party
 */
export interface Party {
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    user_id?: string;
    /**
     * 
     * @type {Person}
     * @memberof Party
     */
    person?: Person;
    /**
     * 
     * @type {Organization}
     * @memberof Party
     */
    organization?: Organization;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    phone?: string;
    /**
     * 
     * @type {Address}
     * @memberof Party
     */
    address?: Address;
    /**
     * 
     * @type {PartyStatements}
     * @memberof Party
     */
    statements?: PartyStatements;
}

/**
 * 
 * @export
 * @interface PartyStatements
 */
export interface PartyStatements {
    /**
     * 
     * @type {boolean}
     * @memberof PartyStatements
     */
    consent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartyStatements
     */
    dfim?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartyStatements
     */
    rki?: boolean;
}

/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    cpr_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    last_name?: string;
}

/**
 * 
 * @export
 * @interface Policy
 */
export interface Policy {
    /**
     * external_reference and unique indentification to the coresponding partner contract.
     * @type {string}
     * @memberof Policy
     */
    external_reference?: string;
    /**
     * brand identification
     * @type {string}
     * @memberof Policy
     */
    brand_id?: string;
    /**
     * The date the policy is expected to effective.
     * @type {string}
     * @memberof Policy
     */
    expected_effective_at?: string;
    /**
     * 
     * @type {PolicyConstructorInsurableObject}
     * @memberof Policy
     */
    insurable_object?: PolicyConstructorInsurableObject;
    /**
     * 
     * @type {Array<PolicyParty>}
     * @memberof Policy
     */
    parties?: Array<PolicyParty>;
    /**
     * Unique number identify the policy.
     * @type {string}
     * @memberof Policy
     */
    policy_number?: string;
    /**
     * Status of the policy.
     * @type {string}
     * @memberof Policy
     */
    policy_status?: string;
    /**
     * Href to the Insurance Product Information Document.
     * @type {string}
     * @memberof Policy
     */
    ipid_href?: string;
    /**
     * 
     * @type {Party}
     * @memberof Policy
     */
    insurer?: Party;
    /**
     * 
     * @type {Array<PolicyCoverage>}
     * @memberof Policy
     */
    coverages?: Array<PolicyCoverage>;
}

/**
 * The information used to create the quote (policy) to be legally signed by the holder(s)
 * @export
 * @interface PolicyConstructor
 */
export interface PolicyConstructor {
    /**
     * external_reference and unique indentification to the coresponding partner contract.
     * @type {string}
     * @memberof PolicyConstructor
     */
    external_reference?: string;
    /**
     * brand identification
     * @type {string}
     * @memberof PolicyConstructor
     */
    brand_id?: string;
    /**
     * The date the policy is expected to effective.
     * @type {string}
     * @memberof PolicyConstructor
     */
    expected_effective_at?: string;
    /**
     * 
     * @type {PolicyConstructorInsurableObject}
     * @memberof PolicyConstructor
     */
    insurable_object?: PolicyConstructorInsurableObject;
    /**
     * 
     * @type {Array<PolicyParty>}
     * @memberof PolicyConstructor
     */
    parties?: Array<PolicyParty>;
}

/**
 * 
 * @export
 * @interface PolicyConstructorInsurableObject
 */
export interface PolicyConstructorInsurableObject {
    /**
     * 
     * @type {Vehicle}
     * @memberof PolicyConstructorInsurableObject
     */
    vehicle?: Vehicle;
}

/**
 * 
 * @export
 * @interface PolicyCoverage
 */
export interface PolicyCoverage {
    /**
     * Unique identification of the coverage.
     * @type {string}
     * @memberof PolicyCoverage
     */
    id?: string;
    /**
     * Name of the coverage.
     * @type {string}
     * @memberof PolicyCoverage
     */
    name?: string;
    /**
     * Title of the coverage.
     * @type {string}
     * @memberof PolicyCoverage
     */
    title?: string;
    /**
     * description of the coverage.
     * @type {string}
     * @memberof PolicyCoverage
     */
    description?: string;
    /**
     * Deductible is the amount of money the holder pays of the damage.
     * @type {number}
     * @memberof PolicyCoverage
     */
    deductible?: number;
    /**
     * limit is the max. amount the insurance company pays.
     * @type {number}
     * @memberof PolicyCoverage
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyCoverage
     */
    currency?: string;
}

/**
 * 
 * @export
 * @interface PolicyParty
 */
export interface PolicyParty {
    /**
     * 
     * @type {string}
     * @memberof PolicyParty
     */
    user_id?: string;
    /**
     * 
     * @type {Person}
     * @memberof PolicyParty
     */
    person?: Person;
    /**
     * 
     * @type {Organization}
     * @memberof PolicyParty
     */
    organization?: Organization;
    /**
     * 
     * @type {string}
     * @memberof PolicyParty
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyParty
     */
    phone?: string;
    /**
     * 
     * @type {Address}
     * @memberof PolicyParty
     */
    address?: Address;
    /**
     * 
     * @type {PartyStatements}
     * @memberof PolicyParty
     */
    statements?: PartyStatements;
    /**
     * Is true if the party is the owner of the vehicle.
     * @type {boolean}
     * @memberof PolicyParty
     */
    vehicle_owner?: boolean;
    /**
     * Is true if the party is the user of the vehicle.
     * @type {boolean}
     * @memberof PolicyParty
     */
    vehicle_user?: boolean;
    /**
     * Is true if the party is the holder of the policy.
     * @type {boolean}
     * @memberof PolicyParty
     */
    policy_holder?: boolean;
}

/**
 * 
 * @export
 * @interface PrivacyPolicy
 */
export interface PrivacyPolicy {
    /**
     * Href to alm brand data privacy policy (persondata politik).
     * @type {string}
     * @memberof PrivacyPolicy
     */
    policy_href?: string;
}

/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    [key: string]: any | any;

    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof Problem
     */
    type?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof Problem
     */
    title?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof Problem
     */
    status?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof Problem
     */
    detail?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof Problem
     */
    instance?: string;
}

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Unique identification of a product
     * @type {string}
     * @memberof Product
     */
    product_name?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Product
     */
    parameters?: { [key: string]: any; };
    /**
     * 
     * @type {Array<ProductFeatures>}
     * @memberof Product
     */
    features?: Array<ProductFeatures>;
}

/**
 * 
 * @export
 * @interface ProductFeatures
 */
export interface ProductFeatures {
    /**
     * 
     * @type {string}
     * @memberof ProductFeatures
     */
    name?: string;
}

/**
 * The quote with the calculated price and coverages. The quote will expire after 30 days.
 * @export
 * @interface Quote
 */
export interface Quote {
    /**
     * Unique identification of the quote. The quote_id is used for retrieval and activation of a quote.
     * @type {string}
     * @memberof Quote
     */
    quote_id?: string;
    /**
     * Unique identification the quote. The quote_number is used for call-centter and other customer contact.
     * @type {string}
     * @memberof Quote
     */
    quote_number?: string;
    /**
     * 
     * @type {QuotePrice}
     * @memberof Quote
     */
    price?: QuotePrice;
    /**
     * 
     * @type {Product}
     * @memberof Quote
     */
    product?: Product;
    /**
     * 
     * @type {Array<Policy>}
     * @memberof Quote
     */
    policies?: Array<Policy>;
    /**
     * The date and time where the quote was created
     * @type {Date}
     * @memberof Quote
     */
    created_at?: Date;
    /**
     * The date and time where the quote will expire
     * @type {Date}
     * @memberof Quote
     */
    expires_at?: Date;
    /**
     * 
     * @type {AgentParty}
     * @memberof Quote
     */
    agent?: AgentParty;
    /**
     * 
     * @type {PrivacyPolicy}
     * @memberof Quote
     */
    privacy?: PrivacyPolicy;
}

/**
 * 
 * @export
 * @interface QuoteActivated
 */
export interface QuoteActivated {
    /**
     * Unique identification of the quote. The quote_id is used for retrieval and activation of a quote.
     * @type {string}
     * @memberof QuoteActivated
     */
    quote_id?: string;
    /**
     * Unique identification the quote. The quote_number is used for call-centter and other customer contact.
     * @type {string}
     * @memberof QuoteActivated
     */
    quote_number?: string;
    /**
     * 
     * @type {QuotePrice}
     * @memberof QuoteActivated
     */
    price?: QuotePrice;
    /**
     * 
     * @type {Product}
     * @memberof QuoteActivated
     */
    product?: Product;
    /**
     * 
     * @type {Array<Policy>}
     * @memberof QuoteActivated
     */
    policies?: Array<Policy>;
    /**
     * The date and time where the quote was created
     * @type {Date}
     * @memberof QuoteActivated
     */
    created_at?: Date;
    /**
     * The date and time where the quote will expire
     * @type {Date}
     * @memberof QuoteActivated
     */
    expires_at?: Date;
    /**
     * 
     * @type {AgentParty}
     * @memberof QuoteActivated
     */
    agent?: AgentParty;
    /**
     * 
     * @type {PrivacyPolicy}
     * @memberof QuoteActivated
     */
    privacy?: PrivacyPolicy;
}

/**
 * The quote price with the price amount and other details
 * @export
 * @interface QuotePrice
 */
export interface QuotePrice {
    /**
     * 
     * @type {number}
     * @memberof QuotePrice
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof QuotePrice
     */
    currency?: string;
    /**
     * 
     * @type {number}
     * @memberof QuotePrice
     */
    payments_per_year?: number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof QuotePrice
     */
    details?: { [key: string]: any; };
}

/**
 * 
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    make?: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    variant?: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    vin?: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    registration_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    vehicle_type?: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    vehicle_usage?: string;
}


/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns price offer on a car insurance to unnamed customer. The offer is binding for Alm Brand for the given parameters and features, but expires after a short period of time (hours).
         * @summary Get price offer on car insurance .
         * @param {CreateOffer} CreateOffer Information needed to get an offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(CreateOffer: CreateOffer, options: any = {}): RequestArgs {
            // verify required parameter 'CreateOffer' is not null or undefined
            if (CreateOffer === null || CreateOffer === undefined) {
                throw new RequiredError('CreateOffer','Required parameter CreateOffer was null or undefined when calling createOffer.');
            }
            const localVarPath = `/offers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateOffer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(CreateOffer || {}) : (CreateOffer || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns price offer on a car insurance to unnamed customer. The offer is binding for Alm Brand for the given parameters and features, but expires after a short period of time (hours).
         * @summary Get price offer on car insurance .
         * @param {CreateOffer} CreateOffer Information needed to get an offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(CreateOffer: CreateOffer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer> {
            const localVarAxiosArgs = OfferApiAxiosParamCreator(configuration).createOffer(CreateOffer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Returns price offer on a car insurance to unnamed customer. The offer is binding for Alm Brand for the given parameters and features, but expires after a short period of time (hours).
         * @summary Get price offer on car insurance .
         * @param {CreateOffer} CreateOffer Information needed to get an offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(CreateOffer: CreateOffer, options?: any) {
            return OfferApiFp(configuration).createOffer(CreateOffer, options)(axios, basePath);
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * Returns price offer on a car insurance to unnamed customer. The offer is binding for Alm Brand for the given parameters and features, but expires after a short period of time (hours).
     * @summary Get price offer on car insurance .
     * @param {CreateOffer} CreateOffer Information needed to get an offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public createOffer(CreateOffer: CreateOffer, options?: any) {
        return OfferApiFp(this.configuration).createOffer(CreateOffer, options)(this.axios, this.basePath);
    }

}

/**
 * QuoteApi - axios parameter creator
 * @export
 */
export const QuoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Activates the quote and creates an active agreement based on the quote. If successful the quote's state is changed to activated. If the state is already set to activated, the call succceds as if it was activated (idempotency), and no changes is applyed the the agreement. If the Id is invalid or the quote is expired the http status code 404 Not Found is returned.
         * @summary Activates a previous created price quote.
         * @param {string} quote_id The quote id
         * @param {any} body Information needed to activate a quote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateQuote(quote_id: string, body: any, options: any = {}): RequestArgs {
            // verify required parameter 'quote_id' is not null or undefined
            if (quote_id === null || quote_id === undefined) {
                throw new RequiredError('quote_id','Required parameter quote_id was null or undefined when calling activateQuote.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling activateQuote.');
            }
            const localVarPath = `/quotes/{quote_id}/activation`
                .replace(`{${"quote_id"}}`, encodeURIComponent(String(quote_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create price quote on a car insurance to named customer(s). The quote is binding for Alm Brand, but not for the customer and expires after 30 days. The customer(s) can be persons, households or an organizations.
         * @summary Create price quote on car insurance.
         * @param {CreateQuote} CreateQuote Information needed to create a quote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCarInsuranceQuote(CreateQuote: CreateQuote, options: any = {}): RequestArgs {
            // verify required parameter 'CreateQuote' is not null or undefined
            if (CreateQuote === null || CreateQuote === undefined) {
                throw new RequiredError('CreateQuote','Required parameter CreateQuote was null or undefined when calling createCarInsuranceQuote.');
            }
            const localVarPath = `/quotes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateQuote" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(CreateQuote || {}) : (CreateQuote || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a previous created price quote, issued to named customer(s). If the Id is invalid or the quote is expired the http status code 404 Not Found is returned.
         * @summary Get a previous created price quote by id.
         * @param {string} quote_id The quote id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuote(quote_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'quote_id' is not null or undefined
            if (quote_id === null || quote_id === undefined) {
                throw new RequiredError('quote_id','Required parameter quote_id was null or undefined when calling getQuote.');
            }
            const localVarPath = `/quotes/{quote_id}`
                .replace(`{${"quote_id"}}`, encodeURIComponent(String(quote_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuoteApi - functional programming interface
 * @export
 */
export const QuoteApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Activates the quote and creates an active agreement based on the quote. If successful the quote's state is changed to activated. If the state is already set to activated, the call succceds as if it was activated (idempotency), and no changes is applyed the the agreement. If the Id is invalid or the quote is expired the http status code 404 Not Found is returned.
         * @summary Activates a previous created price quote.
         * @param {string} quote_id The quote id
         * @param {any} body Information needed to activate a quote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateQuote(quote_id: string, body: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteActivated> {
            const localVarAxiosArgs = QuoteApiAxiosParamCreator(configuration).activateQuote(quote_id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Create price quote on a car insurance to named customer(s). The quote is binding for Alm Brand, but not for the customer and expires after 30 days. The customer(s) can be persons, households or an organizations.
         * @summary Create price quote on car insurance.
         * @param {CreateQuote} CreateQuote Information needed to create a quote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCarInsuranceQuote(CreateQuote: CreateQuote, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quote> {
            const localVarAxiosArgs = QuoteApiAxiosParamCreator(configuration).createCarInsuranceQuote(CreateQuote, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns a previous created price quote, issued to named customer(s). If the Id is invalid or the quote is expired the http status code 404 Not Found is returned.
         * @summary Get a previous created price quote by id.
         * @param {string} quote_id The quote id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuote(quote_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quote> {
            const localVarAxiosArgs = QuoteApiAxiosParamCreator(configuration).getQuote(quote_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * QuoteApi - factory interface
 * @export
 */
export const QuoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Activates the quote and creates an active agreement based on the quote. If successful the quote's state is changed to activated. If the state is already set to activated, the call succceds as if it was activated (idempotency), and no changes is applyed the the agreement. If the Id is invalid or the quote is expired the http status code 404 Not Found is returned.
         * @summary Activates a previous created price quote.
         * @param {string} quote_id The quote id
         * @param {any} body Information needed to activate a quote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateQuote(quote_id: string, body: any, options?: any) {
            return QuoteApiFp(configuration).activateQuote(quote_id, body, options)(axios, basePath);
        },
        /**
         * Create price quote on a car insurance to named customer(s). The quote is binding for Alm Brand, but not for the customer and expires after 30 days. The customer(s) can be persons, households or an organizations.
         * @summary Create price quote on car insurance.
         * @param {CreateQuote} CreateQuote Information needed to create a quote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCarInsuranceQuote(CreateQuote: CreateQuote, options?: any) {
            return QuoteApiFp(configuration).createCarInsuranceQuote(CreateQuote, options)(axios, basePath);
        },
        /**
         * Returns a previous created price quote, issued to named customer(s). If the Id is invalid or the quote is expired the http status code 404 Not Found is returned.
         * @summary Get a previous created price quote by id.
         * @param {string} quote_id The quote id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuote(quote_id: string, options?: any) {
            return QuoteApiFp(configuration).getQuote(quote_id, options)(axios, basePath);
        },
    };
};

/**
 * QuoteApi - object-oriented interface
 * @export
 * @class QuoteApi
 * @extends {BaseAPI}
 */
export class QuoteApi extends BaseAPI {
    /**
     * Activates the quote and creates an active agreement based on the quote. If successful the quote's state is changed to activated. If the state is already set to activated, the call succceds as if it was activated (idempotency), and no changes is applyed the the agreement. If the Id is invalid or the quote is expired the http status code 404 Not Found is returned.
     * @summary Activates a previous created price quote.
     * @param {string} quote_id The quote id
     * @param {any} body Information needed to activate a quote
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuoteApi
     */
    public activateQuote(quote_id: string, body: any, options?: any) {
        return QuoteApiFp(this.configuration).activateQuote(quote_id, body, options)(this.axios, this.basePath);
    }

    /**
     * Create price quote on a car insurance to named customer(s). The quote is binding for Alm Brand, but not for the customer and expires after 30 days. The customer(s) can be persons, households or an organizations.
     * @summary Create price quote on car insurance.
     * @param {CreateQuote} CreateQuote Information needed to create a quote
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuoteApi
     */
    public createCarInsuranceQuote(CreateQuote: CreateQuote, options?: any) {
        return QuoteApiFp(this.configuration).createCarInsuranceQuote(CreateQuote, options)(this.axios, this.basePath);
    }

    /**
     * Returns a previous created price quote, issued to named customer(s). If the Id is invalid or the quote is expired the http status code 404 Not Found is returned.
     * @summary Get a previous created price quote by id.
     * @param {string} quote_id The quote id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuoteApi
     */
    public getQuote(quote_id: string, options?: any) {
        return QuoteApiFp(this.configuration).getQuote(quote_id, options)(this.axios, this.basePath);
    }

}

