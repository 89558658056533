import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ABAutocompleteComponent = _resolveComponent("ABAutocompleteComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["autocomplete__container text-left", [{'input--valid' : !_ctx.dirty && _ctx.isValid && !_ctx.searching}, {'input--searching' : _ctx.searching}]])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "input__label",
          innerHTML: _ctx.label
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_ABAutocompleteComponent, {
      autocompleteName: "address",
      autocompleteNative: "off",
      onInput: _ctx.search,
      onOnSelect: _ctx.handleSubmit,
      onBlur: _ctx.blur,
      onFocus: _ctx.onFocus,
      autocompleteId: _ctx.autocompleteId,
      results: _ctx.results,
      acRef: 'resultList',
      placeholder: _ctx.placeholder,
      max: 10,
      "input-class": ['autocomplete-input','input__field','autocomplete'],
      "display-item": _ctx.displayItem,
      onAutoCompleteField: _ctx.setAutoCompleteField
    }, null, 8, ["onInput", "onOnSelect", "onBlur", "onFocus", "autocompleteId", "results", "placeholder", "display-item", "onAutoCompleteField"])
  ], 2))
}