import { Vue, Options, mixins } from "vue-class-component";
import { App } from "@/views/sharedcomponents/lib/services/app";
import template from './SpinnerComponent.vue';

@Options({
    name: 'spinner-component',
    mixins: [template],
})
export default class SpinnerComponent extends Vue {

    showSpinner = false;
    timeout = 15000; //15 seconds
    
    public startSpinner() {
        //the app has it own spinner
        if (!App.isEnabled()) {
            this.showSpinner = true;
            const intervalId = setInterval(() => {
                this.showSpinner = false;
                clearInterval(intervalId);
            }, this.timeout);
        }
    }

    public closeSpinner() {
        this.showSpinner = false;
    }
}