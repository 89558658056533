import { Vue, Options, mixins } from "vue-class-component";
import template from './inputFieldComponent.vue';
import AbGtm from '@/views/common/ab_gtm';
import PopoverComponent from '@/views/sharedcomponents/baseComponents/components/popoverComponent/popoverComponent';

@Options({
    name: 'InputFieldComponent',
    mixins: [template],
    components: {
        PopoverComponent,
    },
    props:{
         id: String,
         label: String,
         labelPopover!: String,
         type!: {type: String, default: "text" },
         name!: {type: String, default: "label" },
         inputmode!: String,
         inputValue!: String,
         placeholder!: String,
         required!: Boolean,
         readonly!: Boolean, // i.e. true when acting as select
         trackingEnabled: {type: Boolean, default: true },
    }
})

export default class InputFieldComponent extends Vue {
     id: string;
     label: string;
     labelPopover?: string;
     type?: string;
     name?: string;
     inputmode?: string;
     inputValue?: string;
     placeholder?: string;
     required?: boolean;
     readonly?: boolean; // i.e. true when acting as select
     trackingEnabled: boolean;
    

    private doTrack = true;

    // METHODS
    public change(evt) {
        const input = evt.target || evt.srcElement;
        // console.log('change', evt.data, input.value, evt);
        this.$emit('InputFieldComponent', {
            name: this.name,
            value: input.value,
            event: evt
        });
        if (this.doTrack && input.data?.length > 0) {
            this.track();
        }
    }
    public getInputMode() {
        if (this.inputmode !== undefined) {
            return this.inputmode
        } else {
            switch (this.type) {
                case "email":
                    return "email";
                case "number":
                    return "numeric";
                case "tel":
                    return "tel";
                case "url":
                    return "url";
                default:
                    return "text";
            }
        }
    }
    private track(): void {
        const abGtm = new AbGtm();
        const track = {
            event: 'InputEvent',
            eventAction: 'Input field',
            eventCategory: 'Engagement',
            eventLabel: `${this.label ? this.label : this.name} - indtastet`,
        }
        abGtm.triggerCustomGtmEvent(track);
        this.doTrack = false; // only once
    }
}
