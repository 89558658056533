import { Options, Vue } from "vue-class-component";


const DEV_CMS_PORT = '40100';

@Options({
    name: 'CmsImg',
    props:{
         isInlineSvg: {type: Boolean, default: false},
         name: String,
         siteImages: {type: Boolean, default: true},
         globalImages: {type: Boolean, default: false},
         alt: String,
         // TODO klk img & inline-svg properties
    }
})

export default class CmsImgComponent extends Vue {
    isInlineSvg: boolean;
    name: string;
    siteImages: boolean;
    globalImages: boolean;
    alt: string;
    
    public hasError = false;

    get url() {
        // remove redundat '/'
        const n = this.name.startsWith('/') ? this.name.substring(1,this.name.length) : this.name;
        return this.baseUrl + n;
    }

    get baseUrl() {
        let url: string = location.origin + '/';
        if (location.port !== '' && location.port !== DEV_CMS_PORT) {
            url = url.replace(location.port, DEV_CMS_PORT);
        }
        if (this.globalImages) {
            //url += 'globalassets/global-images/'
            url += 'alm.-brand/alm.-brand-media/'
        }
        else if (this.siteImages) {
            url += 'siteassets/site-images/'            
        }
        return url;
    }
}
