import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextBody = _resolveComponent("TextBody")!
  const _component_ab_link = _resolveComponent("ab-link")!

  return (_ctx.model.link.url !== undefined)
    ? (_openBlock(), _createBlock(_component_ab_link, {
        key: 0,
        class: "info-text__icon-content--link",
        to: _ctx.model.link.url,
        target: _ctx.model.externalLinkTarget,
        "data-gtm-action": "textblock click",
        "data-gtm-label": _ctx.model.link.url,
        "data-gtm-url": _ctx.trackingUrl
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TextBody, { model: _ctx.model }, null, 8, ["model"])
        ]),
        _: 1
      }, 8, ["to", "target", "data-gtm-label", "data-gtm-url"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_TextBody, { model: _ctx.model }, null, 8, ["model"])
      ]))
}