export class ContentBlockType {
    static MitAbButtonBlock = 'MitAbButtonBlock';
    static HtmlBlock = 'HtmlBlock';
    static CardBlock = 'CardBlock';
    static UsabillaInPageBlock = 'UsabillaInPageBlock';
    static SimpleContactBlock = 'SimpleContactBlock';
    static SelfserviceMyInsurancesBlock = 'SelfserviceMyInsurancesBlock';
    static TeaserCardBlock = 'TeaserCardBlock';
    static InfoCardBlock = 'InfoCardBlock';
    static OrderflowContainerBlock = 'OrderflowContainerBlock';
    static OrderflowBlock = 'OrderflowBlock';
    static OrderflowStepBlock = 'OrderflowStepBlock';
    static OrderflowPriceBlock = 'OrderflowPriceBlock';
    static CoveragesBlock = 'CoveragesBlock';
    static PolicyDownloadBlock = 'PolicyDownloadBlock';
    static OrderflowInputBlock = 'OrderflowInputBlock';
    static ServiceCheckStep = 'ServiceCheckStep';
    static ContactFixedBlock = 'ContactFixedBlock';

}

// export enum ContentBlockType {
// }
