import { Options, mixins} from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import FlowHandler from "@/views/providers/FlowHandler";
import UsabillaInPageBlockComponent from '@/views/contentApi/components/UsabillaInPageBlockComponent';
import TeaserCardBlockCd from "@/views/contentApi/contentTypes/TeaserCardBlockCd";
import InfoCardBlockCd from "@/views/contentApi/contentTypes/InfoCardBlockCd";
import DialogComponent from '@/views/sharedcomponents/baseComponents/components/dialog/Dialog.vue';
import PriceOverview from '../productline/productlinebody/priceoverview';
import { ContentBlockType } from "@/views/contentApi/contentTypes/ContentBlockType";
import OrderFlowModel from "@/views/model/OrderFlowModel";
import ProductlineFeature from '../productline/productlineFeature';
import ButtonGroup from "@/views/overview/_partials/buttonGroup";
import {InputFieldComponent, ButtonComponent} from '@/views/sharedcomponents/baseComponents/components';
import { CheckboxComponent } from "@/views/sharedcomponents/baseComponents/components";
import store from '@/store';
import ContentItemCd from "@/views/contentApi/contentTypes/ContentItemCd";

@Options({
    name: 'selfservice-orderflow-component',
    components: {
        'dialog-component': DialogComponent,
        'price-overview-component': PriceOverview,
        InputFieldComponent,
        CheckboxComponent,
        'button-group': ButtonGroup,
        'productline-feature-component': ProductlineFeature,
        ButtonComponent,
        'usabilla-in-page-block-component': UsabillaInPageBlockComponent,
    },
    props:{
        flowHandler: FlowHandler
    },
})
export default class OrderFlow extends mixins(InsurancesCommon) {

    flowHandler: FlowHandler;

    public teaserCardBlocks: Array <TeaserCardBlockCd> = [];
    public infoCardBlocks: Array<InfoCardBlockCd> = [];
    public showSpinner = false;
    public canSubmit: boolean = true;
    public refreshBtnG = 1;
    public refreshPrice = 1;
    public refreshUsabilla = 1;
    public inputFieldsInitialized = false;
    public themed: boolean;

    // public $refs: {
    dialogComponent: any;
    // }

    created() {
        this.themed = this.isThemed;
    }
    mounted() {
        this.dialogComponent = (this.$refs.dialogComponent as any);
    }

    public updated() {
        this.canSubmit = this.isFieldsValid(this.flowHandler.active());
    }

    public openOrderflow() {
        this.refreshBtnG++;
        this.refreshPrice++;
        // this.refreshUsabilla++;
        this.$nextTick().then(() => { // wait for dialogComponent
            this.dialogComponent.openModal();
            let current: OrderFlowModel = this.flowHandler.active();
            if (current.track) {
                current.track();
            }
            this.setupFields(current);
        });
    }

    public closeOrderflow() {
        if (this.flowHandler.active().orderflowStepBlock.cancelCallBack) {
            this.flowHandler.active().orderflowStepBlock.cancelCallBack();
        }
        this.flowHandler.reset();
        this.dialogComponent.closeModal();
        this.canSubmit = true;
    }

    public closeOrderflowDialog() {
        this.flowHandler.reset();
        this.dialogComponent.closeModal();
        this.canSubmit = true;
    }

    public back() {
        if (this.flowHandler.getFlowState() > 0 ) {
            this.flowHandler.before();
            const step = this.flowHandler.getFlowState();
            this.dialogComponent.updateProgress(step, this.flowHandler.length());
            const current: OrderFlowModel = this.flowHandler.active();

            this.setupFields(current);
            // this.refreshBtnG++;
            this.refreshPrice++;
            if(current.orderflowStepBlock.usabillaInPageBlockCd) {
                // tell there is a new Usabilla
                store.dispatch('setUsabillaCount');
                this.refreshUsabilla += 1; // force (re)mount of usabilla
            }
        } else {
            this.closeOrderflow();
        }
        window.scrollTo(0,0);
    }

    public async forward() {
        let current: OrderFlowModel = this.flowHandler.active();
        if(current.orderflowStepBlock.model.isSubmit && this.isFieldsValid(current)) {
            this.showSpinner = true;
            try {
                await current.orderflowStepBlock.handleSubmit();
                this.showSpinner = false;
            } catch(err) {
                console.error(err);
                this.showSpinner = false;
                this.closeOrderflow();
                return;
            };
        }
        this.flowHandler.next();
        const step = this.flowHandler.getFlowState();
        this.dialogComponent.updateProgress(step, this.flowHandler.length());

        current = this.flowHandler.active();
        this.setupFields(current);
        window.scrollTo(0,0);
        if (current.track) {
            current.track();
        }
        if(current.orderflowStepBlock.usabillaInPageBlockCd) {
            // tell there is a new Usabilla
            store.dispatch('setUsabillaCount');
            this.refreshUsabilla += 1; // force (re)mount of usabilla
        }
        // this.refreshBtnG++;
    }

    private setupFields(current: OrderFlowModel) {
        this.inputFieldsInitialized = false;
        this.canSubmit = false;
        if (current.orderflowStepBlock.inputFields.length > 0) {
            this.canSubmit = true;
            current.orderflowStepBlock.inputFields.forEach( (field) => {
                field.setupEventHandler(this);
            });
            this.canSubmit = this.isFieldsValid(current);

        } else {
            this.canSubmit = true;
        }
        this.inputFieldsInitialized = true;
    }

    public isInputField(field: any): boolean {
        return field.model.fieldType === 'Text' || field.model.fieldType === 'Number' || field.model.fieldType === 'Tel' || field.model.fieldType === 'Email';
    }

    public isCheckbox(field: any): boolean {
        return field.model.fieldType === 'Boolean';
    }

    public isButton(inputField: ContentItemCd): boolean {
        return inputField.isType(ContentBlockType.MitAbButtonBlock);
    }

    public hasOptionalProperties(): boolean {
        const current = this.flowHandler.active();
        return current.orderflowStepBlock.optionalProperties && current.orderflowStepBlock.optionalProperties.length > 0;
    }

    public isFieldsValid(current: OrderFlowModel): boolean {
        if (current.orderflowStepBlock.inputFields.length > 0) {
            return !current.orderflowStepBlock.inputFields.some( (field) => !field.canSubmit);
        }
        return true;
    }

    public handleInputEvent(evt) {
        const current: OrderFlowModel = this.flowHandler.active();
        const field = current.orderflowStepBlock.inputFields.find( field => field.model.fieldName === evt.name);
        field.handleEvent(evt);
        this.canSubmit = this.isFieldsValid(current);
    }

    public async handleSelectEvent(updateType, value) {
        if (updateType === 'Updated') {
            const current = this.flowHandler.active();
            if (current.orderflowStepBlock.submitSelectCallBack) {
                await current.orderflowStepBlock.submitSelectCallBack(value);
                this.refreshPrice += 1;
            }
        }
    }

    public async handleBtnEvent(btnInput) {
        try {
            if (btnInput.delayInMillis) {
                this.showSpinner = true;
            }
            await btnInput.handleEvent();
            this.showSpinner = false;
            if (btnInput.gotoNextStep) {
                this.flowHandler.hasNextStep() ? this.forward() : this.closeOrderflow();
            }
        } catch(err) {
            this.showSpinner = false;
        }
    }
    public setInputValue(inputModel) {
        (document.getElementById(inputModel.fieldName)as HTMLInputElement).value = inputModel.value;
        this.$refs['_' + inputModel.inputId][0].$emit('Component:Event', 'Updated', { model: inputModel});

    }
}
