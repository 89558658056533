import { Vue, Options, mixins } from "vue-class-component";
import template from './toggleComponent.vue';

@Options({
    name: 'ToggleComponent',
    mixins: [template],
    props: {
         name: String,
         id: String,
         labelledby: String,
         inputValue!: Object,
         preSelect!: Boolean,
         singleSelect!: Boolean,
         disabled!: Boolean,
         type: {type: String, default: 'column' }, //'column' | 'inline'
         change: Boolean
    },

})

export default class ToggleComponent extends Vue {
     name: string;
     id: string;
     labelledby?: string;
     inputValue?: any;
     preSelect?: boolean;
     singleSelect?: boolean;
     disabled?: boolean;
     type: string;
     change: boolean = false;

    public created() {
        if (this.preSelect) {
            this.change = this.preSelect;
        }
    }

    public changeItem() {
        this.change = !this.change;
        this.changed();
    }

    public changed() {
        this.$emit('ToggleComponentChanged', {
            name: this.name,
            value: this.inputValue !== undefined ? this.inputValue : this.change
        });
    }


    get inlineType() {
        return this.type === 'inline';
    }
}
