export default {
  getName: (state) => {
    return state.name;
  },

  getUserData: (state) => {
    return state.userData;
  },

  getConfigEpi: (state) => {
    return state.configEpi;
  },

  getCustomer: (state) => {
    return state.customer;
  },

  getBusinessCustomer: (state) => {
    return state.businessCustomer;
  },

  getEpiRouting: (state) => {
    return state.epiRouting;
  },

  getContentPage: (state) => (id: string) => {
    return state.contentPages.get(id);
  },

  getCurrentContentPage: (state) => {
    return state.currentContentPage;
  },

  getUsabillaState: (state) => {
    return state.usabilla;
  },
  getAgreementList: (state) => {
    return state.agreementList;
  },
  isFetchingAgreementList: (state) => {
    return state.isFetchingAgreementList;
  },
  getCustomerAgreementById: (state) => (id: string) => {
    return state.customerAgreements.get(id);
  },
  getCustomerAgreementByIdAndPartNo:
    (state) => (id: string, partNo: string) => {
      const id_partno = id + "_" + partNo;
      return state.customerAgreements.get(id_partno);
    },
  getProductList: (state) => {
    return state.catalogProductList;
  },
  getClaimList: (state) => {
    return state.claimList;
  },
  getBillingList: (state) => {
    return state.billingList;
  },
  getInboxList: (state) => {
    return state.inboxList;
  },
  getGlobalOrderflow: (state) => {
    return state.globalOrderflow;
  },
  getNewPolicy: (state) => (policyLine: string) => {
    return state.newPolicy.get(policyLine);
  },
  getlastUserUpdatedAgreementId: (state) => {
    return state.lastUserUpdatedAgreementId;
  },
  getAppReplaceRoute: (state) => {
    return state.appReplaceRoute;
  },
  getCart: (state) => {
    return state.cart;
  },
  getBuyPolicy: (state) => (policyTitle: string) => {
    return state.buyPolicy.get(policyTitle);
  },
  getBrokerCustomerList: (state) => {
    return state.brokerCustomerList;
  },
  getActiveCalculator: (state) => {
    return state.calculatorContext[state.calculatorContext.active];
  },
  getCalculator: (state) => (name: string) => {
    return state.calculatorContext[name];
  },
  getActiveCalculatorInx: (state) => {
    return state.calculatorContext.activeInx;
  },
  getCalculatorCommonModel: (state) => {
    return state.calculatorContext.commonModel;
  },
  getBasket: (state) => {
    return state.calculatorContext.basket;
  },
  getBasketHistory: (state) => {
    return state.calculatorContext.basketHistory;
  },
  getConsentSetInApp: (state) => {
    return state.consentSetInApp;
  },
  getOldAppVersionAlerted: (state) => {
    return state.oldAppVersionAlerted;
  },
  getModalState: (state) => {
    return state.modalState;
  },

  getSelectableGroup: (state) => (groupId: string) => {
    return state.selectableContext.selectGroups.get(groupId)
      ? state.selectableContext.selectGroups.get(groupId)
      : [];
  },
  getSelectableGroupUnique: (state) => (groupId: string) => {
    const group = state.selectableContext.selectGroups.get(groupId)
      ? state.selectableContext.selectGroups.get(groupId)
      : [];
    const uniqueGroup = [];
    group.forEach((name) => {
      const cleanedName = name.replace(/[0-9]/g, "");
      if (!uniqueGroup.includes(cleanedName)) {
        uniqueGroup.push(cleanedName);
      }
    });
    return uniqueGroup;
  },
  getCustomerCalculation: (state) => {
    return state.calculatorContext.customer;
  },
  getRecaptchaSiteKey: (state) => {
    return state.recaptchaSiteKey;
  },

  getThemeContext: (state) => {
    const themeContext = state.epiContent?.model?.theme;
    return themeContext ?? "almbrand";
  },

  getLogoUrl: (state) => {
    const logoUrl =
      state?.epiContent?.model?.frontendConfiguration?.logoUrl?.url;
    //if no logo from content use default ab logo  
    return logoUrl ?? "/siteassets/site-images/svg/system-icons/icon-system-ab-logo.svg";
  },
};
