<template>
	<span>
		<button class="modal-trigger" @click="toggleModal()" ref="toggle" v-bind="trackingAttributesTrigger">
			<slot name="trigger" />
		</button>
		<transition name="fade">
			<div :class="['modal', { 'modal--active': isActive, 'modal--dismisable': isDismisable }]" v-if="isActive" @click.self="isDismisable ? toggleModal() : ''">
				<div :class="['modal__content', modalTypeClass]" ref="modalContent">
					<div class="modal__close" v-if="isDismisable" @click.self="toggleModal()">x</div>
					<div class="modal__content__header">
						<slot name="header" />
					</div>
					<div class="modal__content__body">
						<slot name="body" />
					</div>
					<div class="modal__content__footer" v-if="hasFooterSlot">
						<slot name="footer" />
					</div>
				</div>
			</div>
		</transition>
	</span>
</template>
