import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState } from "vuex";
import { ImageBrandBlock } from "@/definitions/episerver/content-types";
import store from '@/store';

@Options({
    name: 'ImageBrandBlockComponent',
    components: {
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<any>
    },
})

export default class ImageBrandBlockComponent extends Vue {
    model: any;

    get bg() {
        return `background-image: url(${this.model.backgroundImage})`;
    }

    get customer() {
        return store.getters.getCustomer;
    }

    get name() {
        if (this.customer.person) {
            return this.customer.person.firstname;
        }
        if (this.customer.organisation) {
            return this.customer.organisation.name;
        }
        return;
    }

    get userMessage() {
        return `${this.model.introText ? this.model.introText : 'Velkommen'}<br/>`;
    }
}
