import store from '@/store';
import AbGtm from '@/views/common/ab_gtm';

export default class CustomerPhoneHandler {

    private static hasCustomerPhoneNoChanged: boolean = false;
    private static hasCustomerMobilPhone: boolean = false;
    private static hasCustomnerPhoneNo: boolean = true;


    public static getCustomerPhoneNo(): string {
        const mobileNo = store.getters.getCustomer.contact.contact_details.find( (mobile) => {
            return mobile.type.toLowerCase() === 'mobile__c';
        });
        if(mobileNo && mobileNo.detail && mobileNo.detail !== 'null') {
            this.hasCustomerMobilPhone = true;
            return mobileNo.detail;
        }

        const phoneNo = store.getters.getCustomer.contact.contact_details.find( (phone) => {
            return phone.type.toLowerCase() === 'phone';
        });
        if(phoneNo && phoneNo.detail && phoneNo.detail !== 'null') {
            this.hasCustomerMobilPhone = false;
            return phoneNo.detail;
        }
        this.hasCustomnerPhoneNo = false;
        return undefined;
    }

    public static updateCustomerPhoneNo(phoneInputValue: string) {
        const customer = store.getters.getCustomer;
        let key = 'phone';
        if (this.hasCustomerMobilPhone) {
            key = 'mobile__c';
        }
        customer.contact.contact_details.map( (phoneNo) => {
            if (phoneNo.type.toLowerCase() === key) {
                if (phoneNo.detail !== phoneInputValue) {
                    //track
                    if( this.hasCustomnerPhoneNo && !this.hasCustomerPhoneNoChanged && phoneNo.detail !== 'null' && phoneNo.detail !== '') {
                        // console.log('tracking change...', phoneNo.detail);
                        const action = 'User changed ' + (key === 'phone' ? 'phoneNo' : 'mobileNo');
                        const abGtm: AbGtm = new AbGtm();
                        abGtm.triggerCustomGtmEvent({
                            'event': 'track-vp',
                            'virtualPath': action
                            });
                        this.hasCustomerPhoneNoChanged = true;
                    }

                    // console.log('changing customerPhoneNo', phoneNo.detail, phoneInputValue);
                    phoneNo.detail = phoneInputValue;
                    store.dispatch('setCustomer', customer); // update customer in session with new phoneNo
                }
            }
        });
    }
}
