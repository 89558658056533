

       /*  SETUP
        //I typsescript
        import Tooltipcomponent from '../../../../shared/tooltip/Tooltip.vue';
        @Component({
        name: 'temp name',
        components: {
            'tooltip-component': Tooltipcomponent
        }
        })

        //I html
        <span class="secondary-font__large__bold">Totalomkostninger</span>
        <tooltip-component
            :header="'Totalomkostninger'"
            :body="'Summen udregnes sådan: Md. ydelse og administrationshonorar ganget med leasingperioden (antal md.), plus førstegangsydelse og tilbageleveringsgebyr.'">
        </tooltip-component>

        */


    import { EnvironmentService } from "@/views/sharedcomponents/lib/services/environmentService";
import { Options, Vue } from "vue-class-component";

    @Options({
        name: 'tooltip-component',
        props: {
            header: String,
            body: String
        }
    })

    export default class TooltipComponent extends Vue {
        header: string;
        body: string;
        iconGlobalAssetsPath = EnvironmentService.iconGlobalAssetsPath;

        toolTip: boolean = false;

        mounted() {
            let self = this;
        }
    }
    
