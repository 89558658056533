import { Vue, Options, mixins } from "vue-class-component";
import getComponentTypeForContent from '@/episerver/api/contentComponentSelector';
import { IContent } from '@/definitions/episerver/content';
import template from './ContentComponentSelector.vue';

@Options({
    name: 'ContentComponentSelector',
    mixins: [template],
    props: ["model"],
})

export default class ContentComponentSelector extends Vue {
    model: any;
    getComponentTypeForBlock(block: IContent) {
        return getComponentTypeForContent(block) ?? 'GenericContent';
    }
}
