// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Customer Details API
 * # Customer Details API The Customer Details API allows client applications access to resources used in the Customer Journey 
 *
 * OpenAPI spec version: 0.0.1.01
 * Contact: P.Integration@almbrand.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "https://localhost:8080".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface CommonLink
 */
export interface CommonLink {
    /**
     * 
     * @type {string}
     * @memberof CommonLink
     */
    href?: string;
}

/**
 * 
 * @export
 * @interface CommonLinks
 */
export interface CommonLinks {
    /**
     * 
     * @type {CommonLink}
     * @memberof CommonLinks
     */
    self?: CommonLink;
    /**
     * 
     * @type {CommonLink}
     * @memberof CommonLinks
     */
    product?: CommonLink;
}

/**
 * 
 * @export
 * @interface CommonOk
 */
export interface CommonOk {
    /**
     * 
     * @type {number}
     * @memberof CommonOk
     */
    status?: number;
}

/**
 * 
 * @export
 * @interface CommonProblem
 */
export interface CommonProblem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof CommonProblem
     */
    type?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof CommonProblem
     */
    title?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof CommonProblem
     */
    status?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof CommonProblem
     */
    detail?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof CommonProblem
     */
    instance?: string;
}

/**
 * Customer
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    customer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    tags?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    name?: string;
    /**
     * 
     * @type {CustomerPerson}
     * @memberof Customer
     */
    person?: CustomerPerson;
    /**
     * 
     * @type {CustomerHousehold}
     * @memberof Customer
     */
    household?: CustomerHousehold;
    /**
     * 
     * @type {CustomerOrganisation}
     * @memberof Customer
     */
    organisation?: CustomerOrganisation;
    /**
     * 
     * @type {CustomerContact}
     * @memberof Customer
     */
    contact?: CustomerContact;
    /**
     * 
     * @type {any}
     * @memberof Customer
     */
    _links?: any;
}

/**
 * Address Search
 * @export
 * @interface CustomerAddress
 */
export interface CustomerAddress {
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    kvhx?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    in_one_line?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    street_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    building_no_and_letter?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAddress
     */
    postal_code?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    city_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    postbox?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    building_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    contry?: string;
}

/**
 * ConsentDetails
 * @export
 * @interface CustomerConsentDetails
 */
export interface CustomerConsentDetails {
    /**
     * 
     * @type {string}
     * @memberof CustomerConsentDetails
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerConsentDetails
     */
    value?: string;
}

/**
 * 
 * @export
 * @interface CustomerContact
 */
export interface CustomerContact {
    /**
     * 
     * @type {CustomerAddress}
     * @memberof CustomerContact
     */
    address?: CustomerAddress;
    /**
     * 
     * @type {Array<CustomerContactDetails>}
     * @memberof CustomerContact
     */
    contact_details?: Array<CustomerContactDetails>;
}

/**
 * ContactDetails
 * @export
 * @interface CustomerContactDetails
 */
export interface CustomerContactDetails {
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetails
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetails
     */
    type?: string;
}

/**
 * Household
 * @export
 * @interface CustomerHousehold
 */
export interface CustomerHousehold {
    /**
     * 
     * @type {Array<CustomerPerson>}
     * @memberof CustomerHousehold
     */
    persons?: Array<CustomerPerson>;
    /**
     * 
     * @type {any}
     * @memberof CustomerHousehold
     */
    _links?: any;
}

/**
 * 
 * @export
 * @interface CustomerHouseholdLinks
 */
export interface CustomerHouseholdLinks {
    /**
     * 
     * @type {string}
     * @memberof CustomerHouseholdLinks
     */
    href?: string;
}

/**
 * Organisation
 * @export
 * @interface CustomerOrganisation
 */
export interface CustomerOrganisation {
    /**
     * 
     * @type {string}
     * @memberof CustomerOrganisation
     */
    organisation_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrganisation
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrganisation
     */
    cvr_nr?: string;
}

/**
 * Person
 * @export
 * @interface CustomerPerson
 */
export interface CustomerPerson {
    /**
     * 
     * @type {string}
     * @memberof CustomerPerson
     */
    person_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPerson
     */
    cpr_nr?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPerson
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPerson
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPerson
     */
    fullname?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerPerson
     */
    roles?: Array<string>;
}


/**
 * CustomerDetailsApi - axios parameter creator
 * @export
 */
export const CustomerDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetch customer information for the person signed in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerDetails(options: any = {}): RequestArgs {
            const localVarPath = `/customer/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates customer consents information for the person signed in
         * @param {Array<CustomerConsentDetails>} [CustomerConsentDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerConsents(CustomerConsentDetails?: Array<CustomerConsentDetails>, options: any = {}): RequestArgs {
            const localVarPath = `/customer/details/consents`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;CustomerConsentDetails&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(CustomerConsentDetails || {}) : (CustomerConsentDetails || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates customer information for the person signed in
         * @param {Array<CustomerContactDetails>} [CustomerContactDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerDetails(CustomerContactDetails?: Array<CustomerContactDetails>, options: any = {}): RequestArgs {
            const localVarPath = `/customer/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;CustomerContactDetails&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(CustomerContactDetails || {}) : (CustomerContactDetails || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerDetailsApi - functional programming interface
 * @export
 */
export const CustomerDetailsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetch customer information for the person signed in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerDetails(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer> {
            const localVarAxiosArgs = CustomerDetailsApiAxiosParamCreator(configuration).fetchCustomerDetails(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary updates customer consents information for the person signed in
         * @param {Array<CustomerConsentDetails>} [CustomerConsentDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerConsents(CustomerConsentDetails?: Array<CustomerConsentDetails>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer> {
            const localVarAxiosArgs = CustomerDetailsApiAxiosParamCreator(configuration).updateCustomerConsents(CustomerConsentDetails, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary updates customer information for the person signed in
         * @param {Array<CustomerContactDetails>} [CustomerContactDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerDetails(CustomerContactDetails?: Array<CustomerContactDetails>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer> {
            const localVarAxiosArgs = CustomerDetailsApiAxiosParamCreator(configuration).updateCustomerDetails(CustomerContactDetails, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CustomerDetailsApi - factory interface
 * @export
 */
export const CustomerDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary fetch customer information for the person signed in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerDetails(options?: any) {
            return CustomerDetailsApiFp(configuration).fetchCustomerDetails(options)(axios, basePath);
        },
        /**
         * 
         * @summary updates customer consents information for the person signed in
         * @param {Array<CustomerConsentDetails>} [CustomerConsentDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerConsents(CustomerConsentDetails?: Array<CustomerConsentDetails>, options?: any) {
            return CustomerDetailsApiFp(configuration).updateCustomerConsents(CustomerConsentDetails, options)(axios, basePath);
        },
        /**
         * 
         * @summary updates customer information for the person signed in
         * @param {Array<CustomerContactDetails>} [CustomerContactDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerDetails(CustomerContactDetails?: Array<CustomerContactDetails>, options?: any) {
            return CustomerDetailsApiFp(configuration).updateCustomerDetails(CustomerContactDetails, options)(axios, basePath);
        },
    };
};

/**
 * CustomerDetailsApi - object-oriented interface
 * @export
 * @class CustomerDetailsApi
 * @extends {BaseAPI}
 */
export class CustomerDetailsApi extends BaseAPI {
    /**
     * 
     * @summary fetch customer information for the person signed in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerDetailsApi
     */
    public fetchCustomerDetails(options?: any) {
        return CustomerDetailsApiFp(this.configuration).fetchCustomerDetails(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary updates customer consents information for the person signed in
     * @param {Array<CustomerConsentDetails>} [CustomerConsentDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerDetailsApi
     */
    public updateCustomerConsents(CustomerConsentDetails?: Array<CustomerConsentDetails>, options?: any) {
        return CustomerDetailsApiFp(this.configuration).updateCustomerConsents(CustomerConsentDetails, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary updates customer information for the person signed in
     * @param {Array<CustomerContactDetails>} [CustomerContactDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerDetailsApi
     */
    public updateCustomerDetails(CustomerContactDetails?: Array<CustomerContactDetails>, options?: any) {
        return CustomerDetailsApiFp(this.configuration).updateCustomerDetails(CustomerContactDetails, options)(this.axios, this.basePath);
    }

}

