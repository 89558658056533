import { Vue, Options, mixins } from "vue-class-component";
import template from './checkboxComponent.vue';
import AbGtm from '@/views/common/ab_gtm';
@Options({
    name: 'CheckboxComponent',
    mixins: [template],
    props: {
         name: String,
         id: String,
         labelledby: {type: String, default: undefined},
         inputValue: {type: String, default: undefined},
         preSelect: {type: Boolean, default: false},
         disabled: {type: Boolean, default: false},
         testProp: {type: String, default: undefined}, // Used for targetting component data with automatic test
         trackingEnabled!: {type: Boolean, default: true }, // tracks to gtm, by default sends event action: radio, label: content of slot
        //  change: Boolean,
        //  label: String
    }
})

export default class CheckboxComponent extends Vue {
     name: string;
     id: string;
     labelledby?: string;
     inputValue?: string;
     preSelect?: boolean;
     disabled: boolean;
     testProp?: string; // Used for targetting component data with automatic test
     trackingEnabled!: boolean; // tracks to gtm, by default sends event action: radio, label: content of slot
     //    @Provide() change: boolean = false;
     change: boolean;
     label: string;
     private abGtm = new AbGtm();


    mounted(){
        this.label = this.$slots?.default[0]?.text || this.inputValue || this.id || this.name;
        this.trackEvt.eventLabel = `${this.label} - ${this.change ? 'on' : 'off'}`;
    }

    private trackEvt: any = {
        event: 'Checkmark',
        eventAction: 'checkmark field',
        eventCategory: 'Engagement',
        eventLabel: ''
    }

    public created() {
        if (this.preSelect !== undefined) {
            this.change = this.preSelect;
        }
    }
    public changeItem() {
        this.change = !this.change;
        const item = this.$refs[this.name + this.id];
        (item as HTMLInputElement).checked = true;
        this.changed();
    }

    public changed() {
        this.$parent.$emit('CheckboxComponent', {
            name: this.name,
            id: this.id,
            value: this.inputValue !== undefined ? this.inputValue : this.change,
            checked: this.change
        });
        this.track();
    }

    private track(): void {
        this.trackEvt.eventLabel = `${this.label} - ${this.change ? 'on' : 'off'}`;
        this.abGtm.triggerCustomGtmEvent(this.trackEvt);
    }
}
