import { MitAbButtonBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd'

export default class MitAbButtonBlockCd extends ContentItemCd {
    public model: MitAbButtonBlock;
    public canSubmit = true;

    constructor(model: MitAbButtonBlock) {
        super();
        this.model = model;
        this.model.gotoNextStep = !!this.model.gotoNextStep;
    }

    public async handleEvent() {
        if (this.model.delayInMillis) {
            await new Promise( resolve => {
                setTimeout(resolve, this.model.delayInMillis);
            });
        }
    }


    public setupEventHandler(orderflowComponent: any) {
        // dummy
    }

}
