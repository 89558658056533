import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-3" }
const _hoisted_2 = { class: "button-container text-center" }
const _hoisted_3 = ["textContent", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressSearchComponent = _resolveComponent("AddressSearchComponent")!
  const _component_ExistingAbProductsComponent = _resolveComponent("ExistingAbProductsComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AddressSearchComponent, {
        key: "zipCode",
        id: "zipCode",
        elementId: "zipCode",
        isOnlyZipSearch: true,
        label: "Postnummer by",
        placeholder: "F.eks 8000 Århus C",
        defaultValue: _ctx.model.personInfo.zipCode ? (_ctx.model.personInfo.zipCode + ' ' + _ctx.model.personInfo.zipName) : _ctx.defaultZip,
        isValid: _ctx.model.personInfo.zipCode !== undefined,
        readonly: _ctx.readonlyZip,
        onAddressSearch: _ctx.zipSearch
      }, null, 8, ["defaultValue", "isValid", "readonly", "onAddressSearch"])
    ]),
    _createVNode(_TransitionGroup, { name: "expand-list" }, {
      default: _withCtx(() => [
        (_ctx.model.showExistingAlmBrandProducts && _ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName)
          ? (_openBlock(), _createBlock(_component_ExistingAbProductsComponent, {
              key: "ExistingAbProductsComponent",
              card: _ctx.card
            }, null, 8, ["card"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', {'btn-disabled': !_ctx.isValid}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
        textContent: _toDisplayString(_ctx.card.linkText),
        "data-gtm": _ctx.cms.dataGtm,
        "data-gtm-click-text": _ctx.card.routePath
      }, null, 10, _hoisted_3)
    ])
  ]))
}