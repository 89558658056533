import { isNullOrUndefined } from '@/views/common/util';

export default class InsurancesProductType {

    private _tokenString: string;
    private _payload: { [key: string]: any };

    constructor(token: string) {
        this._tokenString = token;
        this._payload = this.parsePayload() || {};
    }

	/**
	 * Returns the token's payload.
	 * @returns {{[key: string]: any}} Payload
	 */
    payload(): { [key: string]: any } {
        return this._payload || this.parsePayload();
    }


    get productType(): string {
        return this._payload[0];
    }

    get productId(): string {
        return this._payload[1];
    }

    get productLineVersion(): number {
        return this._payload[2];
    }

    get productRiskNo(): number {
        return this._payload[3];
    }


	/**
	 * Parses the token's payload.
     *
     * (product-type)-(product-ID)_(product-line-ID)-(product-line-version)-(RISK_NO)
     * e.g I-SA_SA-2-1
     *
	 * @returns {{ [key: string]: any }} Returns the parsed payload.
	 */
    private parsePayload(): { [key: string]: any } {
        if (isNullOrUndefined(this._tokenString)) {
            return {};
        }
        let elements = this.decodeBase64(this._tokenString).split('-');
        if (elements.length != 4) {
            console.log("Invalid token format");
            return {};
        }
        try {
            return elements;
        } catch (e) {
            console.error(e);
            return {};
        }

    }

    private decodeBase64(str) {
        return decodeURIComponent(window.atob(str));
    }

}
