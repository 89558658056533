import { SimpleContactBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd'


export default class SimpleContactBlockCd extends ContentItemCd {
    public model: SimpleContactBlock;

    constructor(model: SimpleContactBlock) {
        super();
        this.model = model;
    }
}
