import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState } from "vuex";
import { InsuranceListBlock } from "@/definitions/episerver/content-types";
import InsurancesCommon from "@/views/model/InsurancesCommon";
import _ from "lodash";
import InsuranceListItem from './_partials/insuranceListItem/InsuranceListItem';
import { SkeletonLoaderComponent } from '@/views/sharedcomponents/baseComponents/components';
import { setSortOrder } from './_sorting';
import { UrlUtil } from "@/episerver/api/urlUtil";
import store from "@/store";

@Options({
    name: 'InsuranceListBlockComponent',
    components: {
        InsuranceListItem,
        SkeletonLoaderComponent,
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<any>
    },
})

export default class InsuranceListBlockComponent extends mixins(InsurancesCommon) {
    model: any;

    isBusiness: boolean = false;
    rawlist: any = null;
    price: any = null;
    searchField: string = "";

    async created() {
        // redirect to productNameLower, if it has just been updated
        const updatedAgreementId = store.getters.getlastUserUpdatedAgreementId;
        const customerAgreements = await this.getAgreementList();
        let productNameLower = undefined;
        let agreement_line_seq_no: string;
        (customerAgreements.agreements as Array<any>).forEach((agreement) => {
            if (updatedAgreementId == agreement.agreement_id) {
                agreement_line_seq_no = agreement.agreement_line_seq_no;
                productNameLower = agreement.title.toLocaleLowerCase();
            }
        });
        if (agreement_line_seq_no) {
            store.dispatch('setAppReplaceRoute', true);
            store.dispatch('setLastUserUpdatedAgreementId', undefined);
            const url = UrlUtil.ensureCorrectLink(`/forsikring/mineforsikringer/${productNameLower}?id=${agreement_line_seq_no}`);
            this.$router.replace(url);
        }
    }

    async beforeMount() {
        this.isBusiness = await this.customerIsBusiness();

        this.rawlist = await this.getAgreementList();
        this.price = this.formatPrice(this.rawlist.price);

        this.buildGroupedAgreements(this.rawlist);

    }

    buildGroupedAgreements(agreements: any) {
        const groupedList = _.chain(agreements)
            .filter(obj => obj.group !== undefined)
            .groupBy(obj => obj.group.title)
            .map((value, key) => ({ title: key, sortOrder: setSortOrder(key, this.isBusiness), agreements: value }))
            .value()

        let newGroupedList = groupedList.sort((a, b) => a.sortOrder - b.sortOrder);

        newGroupedList.forEach(groups => {
            if (groups.agreements[0].group.tags !== undefined) {
                const sortOrder = groups.agreements[0].group.tags
                    .split('#')
                    .filter(x => x !== "")
                    .map(x => x.trim())
                    .map(x => x.replace("#bilforsikring", "#bilforsikring #motorkøretøjsforsikring "));

                groups.agreements.sort((a, b) => {
                    return sortOrder.indexOf(a.title.toLowerCase()) - sortOrder.indexOf(b.title.toLowerCase());
                });
            }
        });

        return newGroupedList;
    }

    get filteredInsurancesList() {
        if (this.rawlist === null || this.rawlist === undefined) {
            return {};
        }
        let filteredList = this.rawlist.agreements.filter(item => {
            if (this.searchField.length === 0) {
                return {};
            } else {
                //replace searches for whitespace from either the beginning or the end of the string
                let _searchField = this.searchField.replace(/^\s+|\s+$/g, "");
                return item.title.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1 ||
                    item.description.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1 ||
                    item.agreement_id.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1;
            }
        });

        return this.buildGroupedAgreements(filteredList);
    }
}
