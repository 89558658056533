import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from "@/views/model/InsurancesCommon";
import { ModalComponent } from '@/views/sharedcomponents/baseComponents/components';
import template from './AppVersionAlert.vue';
import store from '@/store';

@Options({
    name: 'selfservice-app-version-alert',
    mixins: [template],
    components: {
        ModalComponent
    }
})
export default class AppVersionAlert extends mixins(InsurancesCommon) {
    // LIFECYCLE
    mounted() {
        this.attachEventListeners();

        if (this.showUpdate) {
            (this.$refs['AppVersionModal'] as ModalComponent).toggleModal();
        }
    }

    // COMPUTED
    get appVersion(): string {
        return this.$root['appVersion'];
    }

    get urlVersion(): string {
        const query = window.location.href;
        const url = new URL(query.replace('?client=app#', ''));
        return url.searchParams.get('version');
    }

    get appVersionMessage(): string {
        return this.$root['appVersionMessage'];
    }

    get showUpdate(): boolean {
        //https://localhost/mitalmbrand?client=app#/forsikring?client=app&version=4.1.8&customertype=private
        if (this.appVersion && this.urlVersion) {
            const currentVersion = this.parseVersionFloat(this.appVersion);
            const userVersion = this.parseVersionFloat(this.urlVersion);
            return currentVersion > userVersion;
        }
        return false;
    }

    //METHODS
    attachEventListeners() {
        store.subscribeAction((action, state) => {
            if (action.type === 'toggleModal') {
                if (action.payload.isActive === false) {
                    store.dispatch("setOldAppVersionAlerted", true);
               }
            }
        });        

    }

    private parseVersionFloat(versionString) {
        const versionArray = ("" + versionString)
            .replace("_", ".")
            .replace(/[^0-9.]/g, "")
            .split(".");
            let sum = 0;
        for (let i = 0; i < versionArray.length; ++i) {
            sum += Number(versionArray[i]) / Math.pow(10, i * 3);
        }
        return sum;
    }
}
