// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Customer Claim API
 * # Customer Claim API The Customer Claim API allows client applications to add and modify Claims. 
 *
 * OpenAPI spec version: 0.0.1.01
 * Contact: P.Integration@almbrand.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "https://localhost:8080".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    door?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
}

/**
 * 
 * @export
 * @interface CarClaim
 */
export interface CarClaim extends CreateClaim {
    /**
     * 
     * @type {string}
     * @memberof CarClaim
     */
    registrationNumber?: string;
    /**
     * 
     * @type {Array<CarPart>}
     * @memberof CarClaim
     */
    carDamageParts?: Array<CarPart>;
    /**
     * 
     * @type {Driver}
     * @memberof CarClaim
     */
    driver?: Driver;
    /**
     * 
     * @type {boolean}
     * @memberof CarClaim
     */
    youngDriverExcess?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarClaim
     */
    policeReport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarClaim
     */
    bloodTest?: boolean;
    /**
     * 
     * @type {DamagedItemCode}
     * @memberof CarClaim
     */
    damagedItemCode?: DamagedItemCode;
    /**
     * 
     * @type {string}
     * @memberof CarClaim
     */
    damagedItems?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CarPart {
    FRONT = 'FRONT',
    RIGHTSIDEFRONT = 'RIGHT_SIDE_FRONT',
    LEFTSIDEFRONT = 'LEFT_SIDE_FRONT',
    RIGHTSIDECENTER = 'RIGHT_SIDE_CENTER',
    LEFTSIDECENTER = 'LEFT_SIDE_CENTER',
    RIGHTSIDETRUNK = 'RIGHT_SIDE_TRUNK',
    LEFTSIDETRUNK = 'LEFT_SIDE_TRUNK',
    TRUNK = 'TRUNK',
    ROOF = 'ROOF',
    REARWINDOW = 'REAR_WINDOW',
    WINDSHIELD = 'WINDSHIELD',
    CABIN = 'CABIN',
    MECHANICALPARTS = 'MECHANICAL_PARTS',
    ALL = 'ALL',
    ACCESSORIES = 'ACCESSORIES',
    UNDERBODY = 'UNDERBODY',
    UNKNOWN = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface Claim
 */
export interface Claim {
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    claimId?: number;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    claEventNo?: number;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    nameIdNo?: number;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    policyNo?: number;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    policyLineNo?: number;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    policyLineSeqNo?: number;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    objectNo?: number;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    objectSeqNo?: number;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    objectId?: string;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    riskNo?: number;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    subriskNo?: number;
    /**
     * 
     * @type {ClaimStatus}
     * @memberof Claim
     */
    statusCode?: ClaimStatus;
    /**
     * 
     * @type {Date}
     * @memberof Claim
     */
    notificationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Claim
     */
    incidentTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    placeDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    textualDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    productTypeDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    longitude?: number;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ClaimStatus {
    CLOSED = 'CLOSED',
    CLOSEDREJECTED = 'CLOSED_REJECTED',
    CLOSEDFALSELYOPENED = 'CLOSED_FALSELY_OPENED',
    OPEN = 'OPEN',
    REOPEN = 'REOPEN'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ClaimType {
    ParkingDamageSolo = 'parking_damage_solo',
    SoloDamageSolo = 'solo_damage_solo'
}

/**
 * 
 * @export
 * @interface CommonProblem
 */
export interface CommonProblem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof CommonProblem
     */
    type?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof CommonProblem
     */
    title?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof CommonProblem
     */
    status?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof CommonProblem
     */
    detail?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof CommonProblem
     */
    instance?: string;
}

/**
 * 
 * @export
 * @interface CreateClaim
 */
export interface CreateClaim {
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    claimId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    claEventNo?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    nameIdNo?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    policyNo?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    policyLineNo?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    policyLineSeqNo?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    objectNo?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    objectSeqNo?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateClaim
     */
    objectId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    riskNo?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    subriskNo?: number;
    /**
     * 
     * @type {ClaimStatus}
     * @memberof CreateClaim
     */
    statusCode?: ClaimStatus;
    /**
     * 
     * @type {Date}
     * @memberof CreateClaim
     */
    notificationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateClaim
     */
    incidentTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateClaim
     */
    placeDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaim
     */
    textualDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaim
     */
    productTypeDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClaim
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateClaim
     */
    claimClass?: string;
    /**
     * 
     * @type {ClaimType}
     * @memberof CreateClaim
     */
    claimType?: ClaimType;
    /**
     * 
     * @type {string}
     * @memberof CreateClaim
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaim
     */
    contactPhoneNo?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaim
     */
    jsonAnswers?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaim
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClaim
     */
    verdictPending?: boolean;
}

/**
 * 
 * @export
 * @interface CreateClaimRequest
 */
export interface CreateClaimRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateClaimRequest
     */
    claimClass?: string;
    /**
     * 
     * @type {OneOfCarClaimHouseClaim}
     * @memberof CreateClaimRequest
     */
    //claim?: OneOfCarClaimHouseClaim;
    claim?: any;
}

/**
 * 
 * @export
 * @interface CreateRepairShopReferralRequest
 */
export interface CreateRepairShopReferralRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRepairShopReferralRequest
     */
    repairAreaPostalCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairShopReferralRequest
     */
    repairShopId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateRepairShopReferralRequest
     */
    claimId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairShopReferralRequest
     */
    manufacturerCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairShopReferralRequest
     */
    contactPhoneNo?: string;
}

/**
 * 
 * @export
 * @interface CreateRepairShopReferralResult
 */
export interface CreateRepairShopReferralResult {
    /**
     * 
     * @type {number}
     * @memberof CreateRepairShopReferralResult
     */
    referralId?: number;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DamagedItemCode {
    NATURE = 'NATURE',
    BUILDING = 'BUILDING',
    ANIMAL = 'ANIMAL',
    UTILITY = 'UTILITY',
    OTHER = 'OTHER'
}

/**
 * 
 * @export
 * @interface Driver
 */
export interface Driver {
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    customerId?: number;
    /**
     * 
     * @type {Address}
     * @memberof Driver
     */
    driverAddress?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof Driver
     */
    young?: boolean;
    /**
     * 
     * @type {DriverRelation}
     * @memberof Driver
     */
    relation?: DriverRelation;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    postalRegion?: string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    postalCode?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DriverRelation {
    POLICYHOLDER = 'POLICYHOLDER',
    COHABITANT = 'COHABITANT',
    CHILD = 'CHILD',
    COWORKER = 'COWORKER',
    OTHER = 'OTHER',
    REPAIRMAN = 'REPAIRMAN',
    NONE = 'NONE'
}

/**
 * 
 * @export
 * @interface HouseClaim
 */
export interface HouseClaim extends CreateClaim {
    /**
     * 
     * @type {Array<CarPart>}
     * @memberof HouseClaim
     */
    carDamageParts?: Array<CarPart>;
}

/**
 * 
 * @export
 * @interface Lov
 */
export interface Lov {
    /**
     * 
     * @type {string}
     * @memberof Lov
     */
    name?: string;
    /**
     * 
     * @type {Array<LovElement>}
     * @memberof Lov
     */
    list?: Array<LovElement>;
}

/**
 * 
 * @export
 * @interface LovElement
 */
export interface LovElement {
    /**
     * 
     * @type {string}
     * @memberof LovElement
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof LovElement
     */
    description?: string;
}

/**
 * 
 * @export
 * @interface ManufacturerCode
 */
export interface ManufacturerCode {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerCode
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerCode
     */
    name?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PoliceJurisdiction {
    BORNHOLM = 'BORNHOLM',
    NORDSJAELLAND = 'NORD_SJAELLAND',
    FAROEISLANDS = 'FAROE_ISLANDS',
    FYN = 'FYN',
    GREENLAND = 'GREENLAND',
    KOEBENHAVNVESTEGN = 'KOEBENHAVN_VESTEGN',
    KOEBENHAVN = 'KOEBENHAVN',
    MIDTOGVESTSJAELLAND = 'MIDT_OG_VEST_SJAELLAND',
    MIDTOGVESTJYLLAND = 'MIDT_OG_VEST_JYLLAND',
    NORDJYLLAND = 'NORD_JYLLAND',
    OESTJYLLAND = 'OEST_JYLLAND',
    SYDOESTJYLLAND = 'SYD_OEST_JYLLAND',
    SYDOGSOENDERJYLLAND = 'SYD_OG_SOENDERJYLLAND',
    SYDSJAELLANDOGLOLLANDFALSTER = 'SYD_SJAELLAND_OG_LOLLAND_FALSTER'
}

/**
 * 
 * @export
 * @interface PoliceJurisdictionResult
 */
export interface PoliceJurisdictionResult {
    /**
     * 
     * @type {PoliceJurisdiction}
     * @memberof PoliceJurisdictionResult
     */
    jurisdiction?: PoliceJurisdiction;
}

/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    question?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    questionNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    mandatory?: string;
    /**
     * 
     * @type {Array<LovElement>}
     * @memberof Question
     */
    lov?: Array<LovElement>;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    answer?: string;
}

/**
 * 
 * @export
 * @interface RepairShop
 */
export interface RepairShop {
    /**
     * 
     * @type {string}
     * @memberof RepairShop
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairShop
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairShop
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairShop
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairShop
     */
    supports?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairShop
     */
    waitTime?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairShop
     */
    distance?: string;
}

/**
 * 
 * @export
 * @interface ValidationErrorResponse
 */
export interface ValidationErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorResponse
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationErrorResponse
     */
    status?: number;
    /**
     * 
     * @type {ValidationErrorType}
     * @memberof ValidationErrorResponse
     */
    statusCode?: ValidationErrorType;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorResponse
     */
    description?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ValidationErrorType {
    POLICYNOTFOUND = 'POLICY_NOT_FOUND',
    UNKNOWNCLAIMSERVICE = 'UNKNOWN_CLAIM_SERVICE',
    INPUTVALIDATION = 'INPUT_VALIDATION',
    VALIDPHONENUMBERNOTFOUND = 'VALID_PHONE_NUMBER_NOT_FOUND',
    VALIDEMAILNOTFOUND = 'VALID_EMAIL_NOT_FOUND',
    CLAIMPRODUCTLINEINCORRECT = 'CLAIM_PRODUCT_LINE_INCORRECT',
    DRIVERNOTFOUND = 'DRIVER_NOT_FOUND',
    POLICEREPORTNOTFOUND = 'POLICE_REPORT_NOT_FOUND'
}

/**
 * 
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    registrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    description?: string;
}

/**
 * 
 * @export
 * @interface VehicleData
 */
export interface VehicleData {
    /**
     * 
     * @type {number}
     * @memberof VehicleData
     */
    modelYear?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleData
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleData
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleData
     */
    chassisNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleData
     */
    corporateCustomer?: boolean;
}

/**
 * 
 * @export
 * @interface VehicleUser
 */
export interface VehicleUser {
    /**
     * 
     * @type {number}
     * @memberof VehicleUser
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleUser
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleUser
     */
    age?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleUser
     */
    policyholder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleUser
     */
    user?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleUser
     */
    youngDriverClause?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleUser
     */
    child?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleUser
     */
    cohabitant?: boolean;
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get claims for a given customer
         * @param {'customer_claim_missing'} [claim_state] Filters a claim list
         * @param {number} [size] Page size
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchClaimList(claim_state?: 'customer_claim_missing', size?: number, page?: number, options: any = {}): RequestArgs {
            const localVarPath = `/customer/claims`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (claim_state !== undefined) {
                localVarQueryParameter['claim_state'] = claim_state;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {number} claim_id Unique id for the claim
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVehicleByClaimId(claim_id: number, options: any = {}): RequestArgs {
            // verify required parameter 'claim_id' is not null or undefined
            if (claim_id === null || claim_id === undefined) {
                throw new RequiredError('claim_id','Required parameter claim_id was null or undefined when calling fetchVehicleByClaimId.');
            }
            const localVarPath = `/customer/claims/{claim_id}/vehicle`
                .replace(`{${"claim_id"}}`, encodeURIComponent(String(claim_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} registrationNumber Registration number of a vehicle
         * @param {string} [date] Date for request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVehicleUsers(registrationNumber: string, date?: string, options: any = {}): RequestArgs {
            // verify required parameter 'registrationNumber' is not null or undefined
            if (registrationNumber === null || registrationNumber === undefined) {
                throw new RequiredError('registrationNumber','Required parameter registrationNumber was null or undefined when calling fetchVehicleUsers.');
            }
            const localVarPath = `/customer/claims/vehicles/{registrationNumber}/users`
                .replace(`{${"registrationNumber"}}`, encodeURIComponent(String(registrationNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} [date] Date for request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVehicles(date?: string, options: any = {}): RequestArgs {
            const localVarPath = `/customer/claims/vehicles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a police jurisdiction from longitude/latitude
         * @param {number} latitude latitude
         * @param {number} longitude longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoliceJurisdiction(latitude: number, longitude: number, options: any = {}): RequestArgs {
            // verify required parameter 'latitude' is not null or undefined
            if (latitude === null || latitude === undefined) {
                throw new RequiredError('latitude','Required parameter latitude was null or undefined when calling getPoliceJurisdiction.');
            }
            // verify required parameter 'longitude' is not null or undefined
            if (longitude === null || longitude === undefined) {
                throw new RequiredError('longitude','Required parameter longitude was null or undefined when calling getPoliceJurisdiction.');
            }
            const localVarPath = `/customer/claims/policeJurisdiction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of manufacturer codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepairShopManufacturerCodes(options: any = {}): RequestArgs {
            const localVarPath = `/customer/claims/repairShops/manufacturerCodes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of repair shops
         * @param {string} postalCode postalCode
         * @param {number} modelYear Vehicle model year
         * @param {string} manufacturerCode Manufacturer code of vehicle, a list of codes can be retrieved
         * @param {number} [repairShopSorting] Sorting 0 &#x3D; Best, 1 &#x3D; Closest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepairShops(postalCode: string, modelYear: number, manufacturerCode: string, repairShopSorting?: number, options: any = {}): RequestArgs {
            // verify required parameter 'postalCode' is not null or undefined
            if (postalCode === null || postalCode === undefined) {
                throw new RequiredError('postalCode','Required parameter postalCode was null or undefined when calling getRepairShops.');
            }
            // verify required parameter 'modelYear' is not null or undefined
            if (modelYear === null || modelYear === undefined) {
                throw new RequiredError('modelYear','Required parameter modelYear was null or undefined when calling getRepairShops.');
            }
            // verify required parameter 'manufacturerCode' is not null or undefined
            if (manufacturerCode === null || manufacturerCode === undefined) {
                throw new RequiredError('manufacturerCode','Required parameter manufacturerCode was null or undefined when calling getRepairShops.');
            }
            const localVarPath = `/customer/claims/repairShops`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }

            if (modelYear !== undefined) {
                localVarQueryParameter['modelYear'] = modelYear;
            }

            if (manufacturerCode !== undefined) {
                localVarQueryParameter['manufacturerCode'] = manufacturerCode;
            }

            if (repairShopSorting !== undefined) {
                localVarQueryParameter['repairShopSorting'] = repairShopSorting;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tests if Taksatorringens service is online
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isRepairShopServiceOnline(options: any = {}): RequestArgs {
            const localVarPath = `/customer/claims/repairShops/online`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary post business for the person signed in
         * @param {CreateClaimRequest} CreateClaimRequest A nice request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postClaim(CreateClaimRequest: CreateClaimRequest, options: any = {}): RequestArgs {
            // verify required parameter 'CreateClaimRequest' is not null or undefined
            if (CreateClaimRequest === null || CreateClaimRequest === undefined) {
                throw new RequiredError('CreateClaimRequest','Required parameter CreateClaimRequest was null or undefined when calling postClaim.');
            }
            const localVarPath = `/customer/claims`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateClaimRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(CreateClaimRequest || {}) : (CreateClaimRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a referral to a repair shop
         * @param {CreateRepairShopReferralRequest} CreateRepairShopReferralRequest A nice request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReferral(CreateRepairShopReferralRequest: CreateRepairShopReferralRequest, options: any = {}): RequestArgs {
            // verify required parameter 'CreateRepairShopReferralRequest' is not null or undefined
            if (CreateRepairShopReferralRequest === null || CreateRepairShopReferralRequest === undefined) {
                throw new RequiredError('CreateRepairShopReferralRequest','Required parameter CreateRepairShopReferralRequest was null or undefined when calling postReferral.');
            }
            const localVarPath = `/customer/claims/repairShops/referrals`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateRepairShopReferralRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(CreateRepairShopReferralRequest || {}) : (CreateRepairShopReferralRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get claims for a given customer
         * @param {'customer_claim_missing'} [claim_state] Filters a claim list
         * @param {number} [size] Page size
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchClaimList(claim_state?: 'customer_claim_missing', size?: number, page?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Claim>> {
            const localVarAxiosArgs = CustomerApiAxiosParamCreator(configuration).fetchClaimList(claim_state, size, page, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get
         * @param {number} claim_id Unique id for the claim
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVehicleByClaimId(claim_id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleData> {
            const localVarAxiosArgs = CustomerApiAxiosParamCreator(configuration).fetchVehicleByClaimId(claim_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} registrationNumber Registration number of a vehicle
         * @param {string} [date] Date for request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVehicleUsers(registrationNumber: string, date?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleUser>> {
            const localVarAxiosArgs = CustomerApiAxiosParamCreator(configuration).fetchVehicleUsers(registrationNumber, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} [date] Date for request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVehicles(date?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>> {
            const localVarAxiosArgs = CustomerApiAxiosParamCreator(configuration).fetchVehicles(date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Returns a police jurisdiction from longitude/latitude
         * @param {number} latitude latitude
         * @param {number} longitude longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoliceJurisdiction(latitude: number, longitude: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoliceJurisdictionResult> {
            const localVarAxiosArgs = CustomerApiAxiosParamCreator(configuration).getPoliceJurisdiction(latitude, longitude, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get list of manufacturer codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepairShopManufacturerCodes(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerCode>> {
            const localVarAxiosArgs = CustomerApiAxiosParamCreator(configuration).getRepairShopManufacturerCodes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Returns a list of repair shops
         * @param {string} postalCode postalCode
         * @param {number} modelYear Vehicle model year
         * @param {string} manufacturerCode Manufacturer code of vehicle, a list of codes can be retrieved
         * @param {number} [repairShopSorting] Sorting 0 &#x3D; Best, 1 &#x3D; Closest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepairShops(postalCode: string, modelYear: number, manufacturerCode: string, repairShopSorting?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairShop>> {
            const localVarAxiosArgs = CustomerApiAxiosParamCreator(configuration).getRepairShops(postalCode, modelYear, manufacturerCode, repairShopSorting, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Tests if Taksatorringens service is online
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isRepairShopServiceOnline(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = CustomerApiAxiosParamCreator(configuration).isRepairShopServiceOnline(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary post business for the person signed in
         * @param {CreateClaimRequest} CreateClaimRequest A nice request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postClaim(CreateClaimRequest: CreateClaimRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClaim> {
            const localVarAxiosArgs = CustomerApiAxiosParamCreator(configuration).postClaim(CreateClaimRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Post a referral to a repair shop
         * @param {CreateRepairShopReferralRequest} CreateRepairShopReferralRequest A nice request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReferral(CreateRepairShopReferralRequest: CreateRepairShopReferralRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRepairShopReferralResult> {
            const localVarAxiosArgs = CustomerApiAxiosParamCreator(configuration).postReferral(CreateRepairShopReferralRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary get claims for a given customer
         * @param {'customer_claim_missing'} [claim_state] Filters a claim list
         * @param {number} [size] Page size
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchClaimList(claim_state?: 'customer_claim_missing', size?: number, page?: number, options?: any) {
            return CustomerApiFp(configuration).fetchClaimList(claim_state, size, page, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get
         * @param {number} claim_id Unique id for the claim
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVehicleByClaimId(claim_id: number, options?: any) {
            return CustomerApiFp(configuration).fetchVehicleByClaimId(claim_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get
         * @param {string} registrationNumber Registration number of a vehicle
         * @param {string} [date] Date for request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVehicleUsers(registrationNumber: string, date?: string, options?: any) {
            return CustomerApiFp(configuration).fetchVehicleUsers(registrationNumber, date, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get
         * @param {string} [date] Date for request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVehicles(date?: string, options?: any) {
            return CustomerApiFp(configuration).fetchVehicles(date, options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns a police jurisdiction from longitude/latitude
         * @param {number} latitude latitude
         * @param {number} longitude longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoliceJurisdiction(latitude: number, longitude: number, options?: any) {
            return CustomerApiFp(configuration).getPoliceJurisdiction(latitude, longitude, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of manufacturer codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepairShopManufacturerCodes(options?: any) {
            return CustomerApiFp(configuration).getRepairShopManufacturerCodes(options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns a list of repair shops
         * @param {string} postalCode postalCode
         * @param {number} modelYear Vehicle model year
         * @param {string} manufacturerCode Manufacturer code of vehicle, a list of codes can be retrieved
         * @param {number} [repairShopSorting] Sorting 0 &#x3D; Best, 1 &#x3D; Closest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepairShops(postalCode: string, modelYear: number, manufacturerCode: string, repairShopSorting?: number, options?: any) {
            return CustomerApiFp(configuration).getRepairShops(postalCode, modelYear, manufacturerCode, repairShopSorting, options)(axios, basePath);
        },
        /**
         * 
         * @summary Tests if Taksatorringens service is online
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isRepairShopServiceOnline(options?: any) {
            return CustomerApiFp(configuration).isRepairShopServiceOnline(options)(axios, basePath);
        },
        /**
         * 
         * @summary post business for the person signed in
         * @param {CreateClaimRequest} CreateClaimRequest A nice request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postClaim(CreateClaimRequest: CreateClaimRequest, options?: any) {
            return CustomerApiFp(configuration).postClaim(CreateClaimRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Post a referral to a repair shop
         * @param {CreateRepairShopReferralRequest} CreateRepairShopReferralRequest A nice request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReferral(CreateRepairShopReferralRequest: CreateRepairShopReferralRequest, options?: any) {
            return CustomerApiFp(configuration).postReferral(CreateRepairShopReferralRequest, options)(axios, basePath);
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary get claims for a given customer
     * @param {'customer_claim_missing'} [claim_state] Filters a claim list
     * @param {number} [size] Page size
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public fetchClaimList(claim_state?: 'customer_claim_missing', size?: number, page?: number, options?: any) {
        return CustomerApiFp(this.configuration).fetchClaimList(claim_state, size, page, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get
     * @param {number} claim_id Unique id for the claim
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public fetchVehicleByClaimId(claim_id: number, options?: any) {
        return CustomerApiFp(this.configuration).fetchVehicleByClaimId(claim_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get
     * @param {string} registrationNumber Registration number of a vehicle
     * @param {string} [date] Date for request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public fetchVehicleUsers(registrationNumber: string, date?: string, options?: any) {
        return CustomerApiFp(this.configuration).fetchVehicleUsers(registrationNumber, date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get
     * @param {string} [date] Date for request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public fetchVehicles(date?: string, options?: any) {
        return CustomerApiFp(this.configuration).fetchVehicles(date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns a police jurisdiction from longitude/latitude
     * @param {number} latitude latitude
     * @param {number} longitude longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getPoliceJurisdiction(latitude: number, longitude: number, options?: any) {
        return CustomerApiFp(this.configuration).getPoliceJurisdiction(latitude, longitude, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of manufacturer codes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getRepairShopManufacturerCodes(options?: any) {
        return CustomerApiFp(this.configuration).getRepairShopManufacturerCodes(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns a list of repair shops
     * @param {string} postalCode postalCode
     * @param {number} modelYear Vehicle model year
     * @param {string} manufacturerCode Manufacturer code of vehicle, a list of codes can be retrieved
     * @param {number} [repairShopSorting] Sorting 0 &#x3D; Best, 1 &#x3D; Closest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getRepairShops(postalCode: string, modelYear: number, manufacturerCode: string, repairShopSorting?: number, options?: any) {
        return CustomerApiFp(this.configuration).getRepairShops(postalCode, modelYear, manufacturerCode, repairShopSorting, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Tests if Taksatorringens service is online
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public isRepairShopServiceOnline(options?: any) {
        return CustomerApiFp(this.configuration).isRepairShopServiceOnline(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary post business for the person signed in
     * @param {CreateClaimRequest} CreateClaimRequest A nice request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postClaim(CreateClaimRequest: CreateClaimRequest, options?: any) {
        return CustomerApiFp(this.configuration).postClaim(CreateClaimRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Post a referral to a repair shop
     * @param {CreateRepairShopReferralRequest} CreateRepairShopReferralRequest A nice request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postReferral(CreateRepairShopReferralRequest: CreateRepairShopReferralRequest, options?: any) {
        return CustomerApiFp(this.configuration).postReferral(CreateRepairShopReferralRequest, options)(this.axios, this.basePath);
    }

}

