
import { loadUsabillaScript } from "@/config/loadUsabillaScript";
import { defaultConfig } from "@/episerver/config";
import { runAtStart } from "@/startupRunners";
import store from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      firstLoad: true,
    };
  },
  computed: {
    meta() {
      /* eslint-disable-next-line
      const { metaDescription, metaKeywords, metaTitle, ogImage, ogTitle, ogDescription } = (store.state.epiContent.model as any);
      return {
        metaDescription,
        metaKeywords,
        metaTitle,
        ogImage,
        ogTitle,
        ogDescription
      }*/
    },
    canonicalUrl() {
      return store.state.epiContent.model?.canonicalUrl;
    },

    themeName: () => {
      return store?.getters?.getThemeContext;
    },
  },
  methods: {
    handleResize() {
      store.commit("setWindowWidth", window.innerWidth);
    },
    handleScroll() {
      store.commit(
        "setScrollPosition",
        document.documentElement.scrollTop || document.body.scrollTop
      );
    },
    isTouchDevice() {
      const isTouchDevice =
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        // eslint-disable-next-line
        (navigator as any).msMaxTouchPoints > 0;
      store.commit("setDeviceType", isTouchDevice);
      return isTouchDevice;
    },
    getScrollbarWidth() {
      if (this.isTouchDevice()) return;
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.documentElement.style.setProperty(
        "--scrollbarWidth",
        `${scrollbarWidth}px`
      );
    },
    setMetaData() {
      document.title = this.meta.metaTitle
        ? this.meta.metaTitle
        : "Alm. Brand Group";

      const metaData = [
        {
          type: "description",
          value: this.meta.metaDescription || "",
          property: "name",
        },
        {
          type: "keywords",
          value: this.meta.metaKeywords || "",
          property: "name",
        },
        {
          type: "og:image",
          value:
            this.meta.ogImage && this.meta.ogImage.url
              ? this.meta.ogImage.url
              : "",
          property: "property",
        },
        {
          type: "og:description",
          value: this.meta.ogDescription || "",
          property: "property",
        },
        {
          type: "og:title",
          value: this.meta.ogTitle || "",
          property: "property",
        },
      ];
      metaData.forEach((meta) => {
        const metaTag = document.querySelector(
          `meta[${meta.property}="${meta.type}"]`
        );

        if (!metaTag) {
          let tag = document.createElement("meta");
          tag.setAttribute(meta.property, meta.type);
          tag.setAttribute("content", meta.value);
          document.head.appendChild(tag);
        } else {
          metaTag.setAttribute("content", meta.value);
        }
      });
    },
    setCanonicalUrl() {
      const tag = document.querySelector('link[rel="canonical"]');
      if (tag) tag.setAttribute("href", this.canonicalUrl.url);
    },
    trackPageView() {
      /*
      const startMark = this.firstLoad ? 'firstLoadMark' : 'betweenRoutesMark';
      const performance = window.performance.measure('page_speed', startMark, 'end');

      // eslint-disable-next-line
      (window as any).dataLayer.push({
        event: 'pageView',
        title: document.title,
        path: this.canonicalUrl.path || window.location.pathname,
        location: this.canonicalUrl.url || window.location.href,
        // eslint-disable-next-line
        pageSpeed: (performance as any).duration
      });*/
    },
    loadRecaptchaScript() {
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://www.google.com/recaptcha/api.js?render=" +
          this.resolveReCaptchaToken()
      );
      recaptchaScript.setAttribute("type", "text/javascript");
      recaptchaScript.setAttribute("async", "async");
      document.head.appendChild(recaptchaScript);
      console.log("recaptchaScript loaded");
    },
    resolveReCaptchaToken() {
      let recaptchaSiteKey =
        document.querySelector("#app")?.getAttribute("data-SiteKey")?.trim() ||
        "";
      if (location.port === "3000" && recaptchaSiteKey === "") {
        recaptchaSiteKey = process.env.VUE_APP_DEV_RECAPTCHA;
        console.log("recaptchaSiteKey fallback", "<" + recaptchaSiteKey + ">");
      }
      store.dispatch("setRecaptchaSiteKey", recaptchaSiteKey);
      console.log("got recaptcha sitekey", recaptchaSiteKey);

      return recaptchaSiteKey;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);

    loadUsabillaScript();

    this.loadRecaptchaScript();

    const isOpenCalculator = location.pathname
      .toLocaleLowerCase()
      .startsWith("/beregn/");
    store.commit("setIsOpenCalculator", isOpenCalculator);
  },
  mounted() {
    let element = document.getElementById("app");
    const domainUrl =
      element?.getAttribute("data-domain") ?? process.env.VUE_APP_BASEURL ?? "";
    const contentDeliveryApiUri =
      element?.getAttribute("data-contentdelivery-url") ??
      process.env.VUE_APP_CONTENTDELIVERY_URL ??
      "";
    // const language = element?.getAttribute('data-language') ?? "";
    //store.state.cmsDataModel.language = language;
    defaultConfig.apiUrl = domainUrl + contentDeliveryApiUri;
    defaultConfig.baseUrl = domainUrl;
    defaultConfig.selectAllProperties = true;
    defaultConfig.expandAllProperties = true;
    /*
     * If the `epieditmode` parameter is present we know we're
     * in either edit- or preview mode and should include following scripts.
     */
    if (
      this.$route.query.epieditmode ||
      document.location.search.includes("epieditmode")
    ) {
      const domainScript = document.createElement("script");
      domainScript.innerHTML = "document.domain = 'localhost';";
      document.body.appendChild(domainScript);

      const communicationScript = document.createElement("script");
      communicationScript.src = `${domainUrl}/episerver/cms/latest/clientresources/epi-cms/communicationinjector.js`;
      document.body.appendChild(communicationScript);
    }

    document.onreadystatechange = () => {
      // wait for DOM to render
      if (document.readyState === "complete")
        setTimeout(this.getScrollbarWidth, 100);
    };

    this.handleResize();
    this.handleScroll();

    /*dont create testdata server side: port 40100
    if (location.port === "3000"){
      createTestData();
      // createTestDataKlk();
    }*/

    runAtStart(this);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    meta() {
      // on page change
      this.setMetaData();
      this.setCanonicalUrl();

      /*this.$nextTick(() => {
        window.performance.mark('end');

        // eslint-disable-next-line
        if (store.state.allowTracking && (window as any).dataLayer) this.trackPageView();
      });*/
    },
    $route(from, to) {
      this.firstLoad = to.name === undefined ? true : false;
    },
  },
});
