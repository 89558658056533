
import { mapState } from 'vuex';
import UsabillaInPageBlockComponent from '@/views/contentApi/components/UsabillaInPageBlockComponent/UsabillaInPageBlock.vue';
import { CardBlock } from '@/definitions/episerver/content-types';
import { CarSettings } from '../../car/CarSettings';
import { AccidentSettings } from '../../accident/AccidentSettings';
import { HomeSettings } from '../../home/HomeSettings';
import { HouseSettings } from '../../house/HouseSettings';
import { DogSettings } from '../../dog/DogSettings';
import { TravelSettings } from '../../travel/TravelSettings';
import { CustomerCalculation, RESET_CALCULATORS } from '@/episerver/store/modules/calculatorContext';
import { EnvironmentService } from '@/views/sharedcomponents/lib/services/environmentService';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import UsabillaInPageBlockCd from '@/views/contentApi/contentTypes/UsabillaInPageBlockCd';
import TransitionExpand from '../../components/TransitionExpand';

@Options({
    name: 'RecieptStepComponent',
    components: {
        UsabillaInPageBlockComponent,
    },
    computed: mapState<any>({
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
    props: {
        card: Object,
        usabillaBlock: Object//UsabillaInPageBlockComponent, // usabillaBlock from CMS
    }
})

export default class RecieptStepComponent extends Vue {
    card: CardBlock; // RecieptCard from CMS
    usabillaBlock: UsabillaInPageBlockCd; // usabillaBlock from CMS

    public cms!: DogSettings | TravelSettings | CarSettings | AccidentSettings | HomeSettings | HouseSettings; // settings from CMS (store)


    public link: string = null;
    public linkTxt: string = null;
    public transitionExpand = new TransitionExpand();
    public componentInit: boolean = false;

    public mounted() {
        this.setLink();
        store.dispatch(RESET_CALCULATORS);
        setTimeout(() => {
            this.componentInit = true;    
        }, 500);
        
    }

    private setLink() {
        const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;

        if (customerCalculation.hasCustomerData) {
            if (customerCalculation.useLogout) {
                this.link = '/logout';
                this.linkTxt = 'Log ud';
            } else {
                if (EnvironmentService.isApp()) {
                    this.link = '/mitalmbrand?client=app#/forsikring'
                } else {
                    this.link = '/mitalmbrand/forsikring';
                }
                this.linkTxt = 'Gå til forsikringer';
            }
        } else {
            this.link = '/'; // default - almbrand.dk
            this.linkTxt = this.card.linkText;
        }
    }
}
