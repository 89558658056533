import { HtmlBlock } from '@/definitions/episerver/content-types';
import BuyInsuranceSettings from '../BuyInsuranceSettings'

export const enum PACKAGES {
    BASIC = 'Basis',
    MELLEM = 'Udvidet',
    STOR = 'Luksus',
}

export class HomeSettings extends BuyInsuranceSettings{

    public sumsLabel: string;
    public sumsSelectDefault: string;

    public constructor(settingsBlock: HtmlBlock) {
        super(settingsBlock);
        this.loadHomeSettings();

    }

    /**
     * load settings from CMS if present
     */
    private loadHomeSettings() {
        try {
             let setting: string;
             const settingsArr = this.settingsBlock.markup.split('$');
             settingsArr.forEach(setting_ => {
                 setting = setting_.toLowerCase().trim();
                if (setting.indexOf('sumslabel=') > -1 ) {
                    this.sumsLabel = this.getSettingsValue(setting_);
                } else if (setting.indexOf('sumsselectdefault=') > -1 ) {
                    this.sumsSelectDefault = this.getSettingsValue(setting_);
                }

             });

         } catch(e) {
             console.error(e);
         }
     }
}
