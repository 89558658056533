<template>
    <span class="basic-list__item">
        <span class=" h3 basic-list__item__title">{{claim.claimTitle}}</span>
        <span class="basic-list__item--cols align-items-start">
            <span class="basic-list__item__col--1">
                <span class="basic-list__item__subtitle">{{claim.claim.claimId}} - {{claim.claim.productTypeDescription}}</span>
                <span v-if="claim.showStatusIcon" class="basic-list__item__dot-warning"></span>
                <span class="basic-list__item__status-text">{{claim.status}}</span>
            </span>
            <span class="basic-list__item__col--2 basic-list__item__value basic-list__item__value--simple">{{claim.incidentTime}}</span>
            <span v-if="showStatus" class="claim__status-text">{{statusText}}</span>
        </span>
    </span>
</template>

