import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState } from "vuex";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking";
import { FileDownloadBlock } from "@/definitions/episerver/content-types";
import store from '@/store';
import { SpinnerComponent } from '@/views/sharedcomponents/baseComponents/components';

@Options({
    name: 'FileDownloadBlockComponent',
    components: {
        SpinnerComponent
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<FileDownloadBlock>
    },
})

export default class FileDownloadBlockComponent extends mixins(InsurancesCommon) {

    date = new Date();
    inboxItems: any = null;
    doc: any = null;

    trackingUrl: string | undefined;

    beforeMount() {
        this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
    }

    mounted() {
        this.getInboxViaRest();
    }

    getInboxViaRest(): void {
        this.getInboxList().then(inbox => {
            this.handleGetInbox(inbox);
        }).catch(err => {
            this.handleApiServiceError(err, "Dokumenter kunne ikke hentes. Noget gik galt.. ");
        })
    }

    handleGetInbox(inbox: any): void {
        this.inboxItems = {};
        this.inboxItems = inbox.documents;

        const thisYear = this.date.getFullYear();
        for (let i = 0; i < 3; i++) {
            // starter med at søge i listen for i år, derefter sidste år osv.
            let index = this.inboxItems.findIndex(doc => doc.date.startsWith(thisYear - i) && doc.doc_type === "Forsikringsoversigt");
            if (index > -1) {
                this.doc = this.inboxItems[index];
                break;
            }
        }
    }

    async downloadSelectedFile(item: any) {
        (this.$refs.spinnerComponent as any).startSpinner(); 
        
        /** removed for now until backend has the service setup
        fetch('/api/profiles/event', {
            method: 'POST', headers: { 'Content-Type': 'application/json;charset=utf-8' }, credentials: 'same-origin',
            body: JSON.stringify({ eventType: 'Download', payload: { documentId: this.doc.id }, userInfo: {}, value: "forsikringsoversigt", PageUri: window.location.href, PageTitle: document.title })
        }).catch(err =>  err);*/

        if (this.isLoggedInAsApp()) {
            this.callFromNativeScript(
                {
                    "PDF_REST": `/customer/documents/${this.doc.id}`
                }
            );
        } else {
            let response = await this.fetchCustomerDocument();
            const blob = new Blob([response]);
            this.downloadFile(blob, `Forsikringsoversigt_fil_${this.doc.date}.pdf`);
        }


    }

    async fetchCustomerDocument() {
        return await this.abAxiosSelfservice.customerInboxDocumentServiceLayer.fetchCustomerDocument(this.doc.id,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: "blob"
            }
        ).then(response => {
            (this.$refs.spinnerComponent as any).closeSpinner();
            return response.data;
        }).catch(err => {
            (this.$refs.spinnerComponent as any).closeSpinner();
            this.handleApiServiceError(err, "Dokument kunne ikke hentes. Noget gik galt.. ");
        });
    }

}
