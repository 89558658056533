import { cloneDeep } from "lodash"
import UserData from './modules/userData';
import { CatalogProductList } from "@/generated-api/catalog";
import { CustomerAgreement } from "@/generated-api/customer-agreements";
//import OrderflowContainerBlock from "../components/contentApi/contentTypes/OrderflowContainerBlock";
import { Claim } from "@/generated-api/customer-claims";
import { Cart } from '@/generated-api/cart';
import { App } from "@/views/sharedcomponents/lib/services/app";
import { OrderflowContainerBlock } from "@/definitions/episerver/content-types";

export default {
    SET_NAME(state: any, name: string) {
        state.name = name;
    },

    SET_USER_DATA(state: any, userData: UserData) {
        state.userData = userData;
    },

    SET_CUSTOMER(state: any, customer: {}) {
        state.customer = customer;
    },

    SET_BUSINESS_CUSTOMER: (state: any, businessCustomer: Object) => {
        state.businessCustomer = Object.assign({}, businessCustomer);
    },

    SET_EPI_ROUTING(state: any, epiRouting: []) {
        state.epiRouting = epiRouting;
    },

    SET_USABILLA_COUNT(state: any) {
        state.usabilla.count = state.usabilla.count + 1;
    },

    SET_USABILLA_MOUNTED(state: any) {
        state.usabilla.mounted = state.usabilla.mounted + 1;
    },

    SET_AGREEMENT_LIST(state: any, agreementList: {}) {
        state.agreementList = agreementList;
        state.isFetchingAgreementList = false;
    },

    SET_CUSTOMER_AGREEMENT(state: any, customerAgreement: CustomerAgreement) {
        if (customerAgreement) {
            if (customerAgreement.agreement_part.part_no !== undefined) {
                state.customerAgreements.set(customerAgreement.agreement_line_seq_no + "_" + customerAgreement.agreement_part.part_no,
                    customerAgreement);
            } else {
                state.customerAgreements.set(customerAgreement.agreement_line_seq_no, customerAgreement);
            }
        } else {
            state.customerAgreements.clear();
        }
    },

    SET_FETCHING_AGREEMENT_LIST(state: any, isFetching: boolean) {
        state.isFetchingAgreementList = isFetching;
    },

    SET_PRODUCT_LIST(state: any, catalogProductList: CatalogProductList) {
        state.catalogProductList = catalogProductList;
    },

    SET_CLAIM_LIST(state: any, claimList: Array<Claim> ) {
        state.claimList = claimList;
    },

    SET_BILLING_LIST(state: any, billingList: Array<any>) {
        state.billingList = billingList;
    },

    SET_INBOX_LIST(state: any, inboxList: Array<any>) {
        state.inboxList = inboxList;
    },

    SET_GLOBAL_ORDERFLOW(state: any, globalOrderflow: OrderflowContainerBlock) {
        state.globalOrderflow = globalOrderflow;
    },

    SET_NEW_POLICY(state: any, newPolicy: any) {
        state.newPolicy.set(newPolicy.agreement_id, newPolicy);
    },

    SET_LAST_USER_UPDATED_AGREEMENT_ID(state: any, agreementId: string) {
        state.lastUserUpdatedAgreementId = agreementId;
    },

    SET_APP_REPLACE_ROUTE(state: any, appReplaceRoute: boolean) {
        if (App.isEnabled()) { // only app relevant
            state.appReplaceRoute = appReplaceRoute;
        }
    },

    SET_CART(state: any, cart: Cart) {
        state.cart = cart;
    },
    SET_BUY_POLICY(state: any, policy: any) {
        state.buyPolicy.set(policy.title, policy);
    },
    RESET_BUY_POLICY(state: any, title: string) {
        state.buyPolicy.delete(title);
    },

    /*SET_BROKER_CUSTOMER_LIST(state: any, brokerCustomerList: Array<UserInfoCustomerList>) {
        state.brokerCustomerList = brokerCustomerList;
    },*/

    SET_CONSENT_SET_IN_APP(state: any, consent: boolean) {
        state.consentSetInApp = consent;
    },

    SET_OLD_APP_VERSION_ALERTED(state: any, consent: boolean) {
        state.oldAppVersionAlerted = consent;
    },
    TOGGLE_MODAL(state: any, modalState: {id: string, isActive: boolean}) {
        state.modalState = modalState;
    },


    setWindowWidth(state: any, payload) {
        state.windowWidth = payload;
    },
    setScrollPosition(state: any, payload) {
        state.scrollPosition = payload;
    },
    setDeviceType(state: any, payload) {
        state.isTouchDevice = payload;
    },
    setAllowTracking(state: any, payload) {
        state.allowTracking = payload;
    },
    setIsOpenCalculator(state: any, isOpenCalculator: boolean) {
        state.isOpenCalculator = isOpenCalculator;
    },
    
    RECAPTHCA_SITE_KEY(state: any, recaptchaSiteKey: string) {
        state.recaptchaSiteKey = recaptchaSiteKey;
    },


}
