import { UrlUtil } from "@/episerver/api/urlUtil";
import { CALCULATOR_UPDATE_COMMON_MODEL, CustomerCalculation, DISCOUNT_UPDATE, UPDATE_CALCULATOR_INDEX } from "@/episerver/store/modules/calculatorContext";
import { RESET_SELECTABLE } from "@/episerver/store/modules/selectableContext";
import store from "@/store";
import InsurancesProductEnum from "@/views/model/InsurancesProductEnum";
import CookieHandler from "@/views/providers/CookieHandler";
import { EnvironmentService } from "@/views/sharedcomponents/lib/services/environmentService";
import * as localForage from "localforage";
import { cloneDeep } from "lodash";
import { nextTick } from "vue";
import VueScrollTo from "vue-scrollto";
import FieldValidator from './FieldValidator';
import UrlHandler from "./UrlHandler";



// steps names
export const enum STEP {
    DOG_INFO = 'dog_info',
    CAR_INFO = 'car_info',
    CHILDREN_INFO = 'children_info',
    // FAMILY_INFO = 'family_info',
    PERSON_INFO = 'person_info',
    PACKAGE = 'choose_package',
    OVERVIEW = 'overview',
    ADDITIONAL_INFO = 'additional_info',
    CONTACT_INFORMATION = 'contact_information',
    PAYMENT = 'payment',
    ORDER = 'order',
}
const STEPS_EXCLUDE_BASKET: Array<string> = [STEP.CONTACT_INFORMATION, STEP.OVERVIEW, STEP.PAYMENT, STEP.ORDER];

export interface ValuePair {
    value: string,
    displayValue: string,
}

export const monthYearArray: Array<ValuePair> = [{
    value: 'M',
    displayValue: 'Pr. md.',
},
{
    value: 'Y',
    displayValue: 'Pr. år',
}];

export const jaNejArray: Array<ValuePair> = [{
    value: 'ja',
    displayValue: 'Ja',
    },
    {
        value: 'nej',
        displayValue: 'Nej',
    }
];

export const Formatter: Intl.NumberFormat = Intl.NumberFormat("da-DK", { minimumFractionDigits: 0,  maximumFractionDigits: 0 } as any);

export const Validator: FieldValidator = new FieldValidator('none');

export class BuyInsuranceHelper {
    public comp: any;

    constructor(uiComp: any) {
        this.comp = uiComp;
    }
    public async initComponent(): Promise<boolean> {
        if (this.comp.$root['cookieBanner'] === "True") {
            CookieHandler.applyCookieScript();
        }

        this.handleQueryParams();
        await this.handleCampaigns();

        // make sure campaign discount is updated, after campaign is loaded
        if (this.comp.model.campaign.valid) {
            this.comp.model.campaign.discount = BuyInsuranceHelper.getDiscountForAmountProducts(this.comp.model.campaign);
            this.comp.model.campaign.originalDiscount = this.comp.model.campaign.discount;
            this.comp.model.campaign.discountDisplay = Formatter.format(100 * this.comp.model.campaign.discount) + ' %';
        }

        this.handleHeaderBadge();

        const productsInBasket = store.getters.getSelectableGroup('basket');

        this.comp.model.multipleProducts = productsInBasket.length > 1;
        if (this.comp.model.multipleProducts) {
            store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, {fromCommonModel: true});
            this.removeStepsIfBasket();
        }

        // check if user is logged in (coming from MitAlmbrand), and use customer data
        await this.handleLoggedInUser();

        this.removeUnreferencedBlocks();

        this.resetModal();
        if (this.comp.abMisc.browserIe11OrOlder()) {
            nextTick().then(() => {
                this.toggleModal({
                    id: 'error_browser',
                    show: true,
                    redirect: '/',
                    track: true,
                    trackToken: 'error/oldiebrowser',
                    title: this.comp.cms.ieBrowserTitle,
                    content: this.comp.cms.ieBrowserContent,
                });
            });
            let tries=10;
            const interval = setInterval(() => {
                if(this.comp.model.showSpinner || tries < 0) {
                    this.comp.model.showSpinner = false;
                    clearInterval(interval);
                }
                tries--;

            }, this.comp.cms.initialTimeout);
            return false;
        }

        if (this.comp.cms.closeCalculator) {
            this.comp.model.calculating = false;
            nextTick().then(() => {
                this.toggleModal({
                id: 'closed',
                show: true,
                redirect: '/',
                track: true,
                trackToken: 'error/calculatorclosed',
                title: this.comp.cms.closeCalculatorPopupTitle,
                content: this.comp.cms.closeCalculatorPopupContent,
                });
            });
            let tries=10;
            const interval = setInterval(() => {
                if (this.comp.model.showSpinner && tries < 0) {
                    this.comp.model.showSpinner = false;
                    clearInterval(interval);
                }
                else if (!this.comp.model.showSpinner) {
                    clearInterval(interval);
                }
                tries--;
            }, this.comp.cms.initialTimeout);
            return false;
        }

        // Check if close modal should redirect
        store.subscribeAction((action, state) => {
            if (action.type === 'toggleModal') {
                if(!action.payload.isActive && this.comp.model.modal && this.comp.model.modal.redirect) {
                    this.comp.model.showSpinner = true;
                    location.href = this.comp.model.modal.redirect;
                }
            }        
        });

        return true;
    }

    private handleHeaderBadge() {
        if (!this.comp.headerBadgeTxt) {
            this.comp.model.showHeaderBadge = false;
            return;
        }
        if (this.comp.model.campaign.valid && this.comp.model.campaign.ID && this.comp.model.campaign.ID !== 'almbrandonline') {
            this.comp.model.showHeaderBadge = false;
            return;
        }
        this.comp.model.showHeaderBadge = true;

    }


// MODAL ********************************
    public toggleModal(model: any = {}) {
        this.comp.model.modal = Object.assign(this.comp.model.modal, model);

        this.comp.model.modalId = this.comp.model.modal.id;

        if (this.comp.model.modal.show) {
            if (this.comp.model.modal.track) {
                const token = this.comp.model.modal.trackToken ? this.comp.model.modal.trackToken + '/' : '';
                this.comp.abGtm.triggerCustomGtmEvent({
                    'event': 'track-vp',
                    'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.comp.model.productName}/${token}`,
                });
            }
        } else {
            if(this.comp.model.modal.redirect) {
                // modal will redirect on modal close event
                this.comp.model.showSpinner = true;
            }
        }
        nextTick( () => {
            store.dispatch('toggleModal',{
                isActive: this.comp.model.modal.show,
                id: this.comp.model.modal.id
            });

            if (!this.comp.model.modal.show) {
                setTimeout(() => {
                    this.resetModal();
                    // Hack to Fix scrollbar missing
                    (document.body as HTMLElement).style.overflow = 'initial';
                }, 1000);
            } else {
                setTimeout(() => {
                    const input = (document.querySelector('div.button-container [data-mainbtn]') as HTMLElement);
                    if (input) {
                        input.focus();
                    }
                }, 500);
            }
        });
    }

    private resetModal() {
        // setup fail modal as default
        this.comp.model.modalId = 'none';
        this.comp.model.modal.show = false;
        this.comp.model.modal.id = 'none';
        this.comp.model.modal.track = false;
        this.comp.model.modal.trackToken = undefined;
        this.comp.model.modal.content = this.comp.cms.failText;
        this.comp.model.modal.btnType = 'tel';
        this.comp.model.modal.btnStyle = this.comp.cms.btnStyle;
        this.comp.model.modal.btnLabel = this.comp.cms.btnLabel;
        this.comp.model.modal.btnIcon = this.comp.cms.btnIcon;
        this.comp.model.modal.btnAction = this.comp.cms.btnAction;
        this.comp.model.modal.btnSecondStyle = this.comp.cms.btnSecondStyle;
        this.comp.model.modal.btnSecondLabel = this.comp.cms.btnSecondLabel;
        this.comp.model.modal.btnInlineLabel = undefined;
        this.comp.model.modal.btnInlineAction = undefined;
        this.comp.model.modal.btnInlinestyle = this.comp.cms.btnStyle;
        this.comp.model.modal.title = this.comp.cms.failTitle;
        this.comp.model.modal.redirect = undefined;
    }
// NAVIGATION ********************************

    public onUrlChange(params: any): void { // used for history back button
        console.log('url Change called', params);
        
        if (!this.comp.model.showReciept) {
            if (!!params.query.step) {
                this.nextStep(params.query.step, false);
            }
            else {
                this.nextStep(this.comp.cms.defaultCard, true);
            }
        }

    }
    public gotoAddInsurances(step: string) {
        if (step === STEP.OVERVIEW && this.isPreviousStepsValid(step) ) {
            this.comp.model.multipleProducts = true;
            this.comp.model.readyForBasket = true;
            store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, {fromCommonModel : false});
        }
        const path = UrlUtil.ensureCorrectLink(UrlHandler.path + 'forsikringer')
        this.comp.$router.push({ path });
    }


    public setFirstCard() {
        setTimeout(() => {
            let moveOn = true;
            if (this.comp.model.currentCardName && this.comp.model.currentCardName !== 'none') { // coming from outside carcalulator (basket)
                const rerenderCard = this.comp.model.currentCardName;
                const stepInx = this.comp.steps.indexOf(this.comp.model.currentCardName);
                if(stepInx > 0) {
                    this.comp.model.currentCardName = this.comp.steps[stepInx-1];
                    this.nextStep(rerenderCard, true);
                    moveOn = false;
                } else if ( this.comp.model.currentCardName === STEP.OVERVIEW && this.comp.model.readyForBasket) {
                    // goto next product or basket
                    this.handleProductBasketMove(this.comp.model.currentCardName);
                    moveOn = false;
                }
            }
            if (moveOn && this.comp.model.campaign.valid && this.comp.model.campaign.hasContentPage) {
                this.nextStep(this.comp.model.campaign.ID, true);
                moveOn = false;
            }
            if(moveOn) {
                this.nextStep(this.comp.cms.defaultCard, true);
            }
            this.comp.model.showSpinner = false;
        }, this.comp.cms.initialTimeout);
        this.comp.model.showSpinner = true;
        this.comp.componentInit = true;
    }
    /**
     * when nextbtn is clicked - check for valid
     * @param cardName
     */
    public async nextStep(cardName: string, addToHistory: boolean) {
        if (this.comp.model.currentCardName === cardName) {
            return;
        }

        if (this.comp.model.currentCardName === STEP.PERSON_INFO) {
            const res = this.isSyncWithCommonModel();
            if (!res.ok) {
                let msg = this.comp.cms.wrongAddressPopupContent;
                msg = msg.replace('@zipTxt', res.msg);
                const popup = {
                    title: this.comp.cms.wrongAddressPopuptitle,
                    content:  msg,
                    btnSecondLabel: 'Ok',
                    track: true,
                    trackToken: 'error/addressMismatch',
                    id: 'addressMismatch',
                    show: true,
                }
                this.toggleModal(popup);
                return;
            }
        }

        if(!this.isPreviousStepsValid(cardName)) {
            return;
        }

        if (this.isStepPreviousToCurrent(cardName)) {

            if (await this.comp.gotoCard(cardName) ) {
                this.trackNextStep(cardName, addToHistory);
            }
            return;
        }

        if ( !this.comp.model.readyForBasket && !this.comp.isValid(this.comp.model.currentCardName)) {

            this.comp.model.pristineStep.set(this.comp.model.currentCardName, false);
            this.comp.model.updateAll += 1;
            return;
        }

        if (this.handleProductBasketMove(cardName)) {
            return;
        }

        if (await this.comp.gotoCard(cardName) ) {
            this.trackNextStep(cardName, addToHistory);
        }

    }

    public isSyncWithCommonModel(): {ok: boolean, msg?: string }{
        if (!this.comp.model.multipleProducts) {
            return {ok: true};
        }

        if (this.comp.model.productName === InsurancesProductEnum.HUS_FORSIKRING ||
            this.comp.model.productName === InsurancesProductEnum.INDBO_FORSIKRING ||
            this.comp.model.productName === InsurancesProductEnum.BIL_FORSIKRING ||
            this.comp.model.productName === InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING) {
            const personInfo = store.getters.getCalculatorCommonModel?.personInfo;
            if(!personInfo) {
                return {ok: true};
            }
            const personInfoCalc = this.comp.model.personInfo;
            if (!this.compareField('zipCode', personInfo, personInfoCalc)) {
                personInfoCalc.zipCode = undefined;
                personInfoCalc.zipName = undefined;
                personInfoCalc.address = undefined;
                personInfoCalc.streetName = undefined;
                personInfoCalc.houseNr = undefined;
                personInfoCalc.floorNr = undefined;
                personInfoCalc.kvhxValue = undefined;
                return {ok: false, msg: personInfo.zipCode + ' '  + personInfo.zipName};
            }

        }
        return {ok: true};
    }

    private compareField(fieldName: string, commonModel: any, caclulatorModel: any): boolean {
        return commonModel[fieldName] ? caclulatorModel[fieldName] === commonModel[fieldName] : true;
    }

    public static resetSelectedCalculation(product) {
        product.model.choosePackage.selectedPackage = undefined;
        product.model.choosePackage.subtitle = undefined;
        product.model.choosePackage.ownRiskId = undefined;
        product.model.calculation.isCalculated = false;
        product.model.overview.highlights = undefined;
        product.model.overview.details = undefined;
        product.model.readyForBasket = false;
        // set to monthly payment as default
        product.model.choosePackage.monthYear = monthYearArray[0].value;
    }

    private handleProductBasketMove(cardName: string): boolean {
        if(cardName === STEP.OVERVIEW && this.comp.model.productName !== 'kurv') {
            // check if there is multiple products (Basket)
            if (this.comp.model.multipleProducts) {
                this.comp.model.readyForBasket = true;
                const basket = store.getters.getSelectableGroup('basket');
                let foundNextProduct = false;
                basket.forEach((productName: string) => {

                    let match = this.comp.model.productName === productName;
                    if (!match) {
                        const activeIndex = store.getters.getActiveCalculatorInx;
                        match = this.comp.model.productName + activeIndex === productName;
                    }

                    if (!match && !foundNextProduct) {
                        //check if calculator is done
                        const product = store.getters.getCalculator(productName);
                        if(!product || !product.model.readyForBasket) {
                            foundNextProduct = true;
                            store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, {fromCommonModel : false});
                            this.comp.addResetSubscription();
                            const productInx = BuyInsuranceHelper.getCalculatorIndex(productName);
                            if (productInx > -1) {
                                store.dispatch(UPDATE_CALCULATOR_INDEX, productInx);
                            }

                            // if (!productName.includes(this.comp.model.productName)) {
                            //     this.comp.$router.push(BuyInsuranceHelper.getUrlProductName(productName));
                            // } else {
                                // temp page to be able to reload next product
                                this.comp.$router.push('gotoproduct');
                                return true;
                            // }
                        }
                    }
                });
                // all products calculated - goto basket
                if(!foundNextProduct) {
                    store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, {fromCommonModel : false});
                    this.comp.addResetSubscription();
                    // this.comp.$router.push('kurv');
                    this.comp.$router.push('gotoproduct');
                }
                return true;
            }
        }
        return false;
    }

    public static stripProductIndex(productName) {
        return productName.replace(/[0-9]/g, ''); // remove indexes (bilforsikring0 -> bilforsikring, bilforsikring1 -> bilforsikring)
    }



    public static getUrlProductName(productName) {
        const name = this.stripProductIndex(productName);

        switch(name) {
            case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING : return 'boerneulykkesforsikring';
            case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING : return 'boernefamilieforsikring';
            default: return name;
        }
    }

    private trackNextStep(cardName: string, addToHistory) {
        nextTick().then(() => {
            this.scrollMeTo();
        });
        this.comp.abGtm.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.comp.model.productName}/${cardName}`
        });

        this.addStepToHistory(addToHistory);

    }

    public scrollMeTo() {
        setTimeout(() => {
            VueScrollTo.scrollTo(`#${this.comp.model.currentCardName}`, 300, { easing: 'ease-out', offset: -25 });
            // Hack to Fix scrollbar missing
            (document.body as HTMLElement).style.overflow = 'initial';
        }, this.comp.cms.animationTimeout);
    }

    public addStepToHistory(add: boolean) {
        if (add) {
            if (EnvironmentService.isWeb()) {
                let url = this.comp.contentUrl?.endsWith('/') ? this.comp.contentUrl.substring(0,this.comp.contentUrl.length - 1) : this.comp.contentUrl;
                if (!url) {
                    url = this.comp.$route.path;

                }
                window.history.pushState({
                        step: this.comp.model.currentCardName
                    },
                    this.comp.model.currentCardName,
                    `${url}?step=${this.comp.model.currentCardName}`
                );
            } else {
                const href = location.href;
                const appInx = href.indexOf('app#') + 4;
                let url = href.substring(0, appInx);
                let remainUrl = href.substring(appInx, href.length);
                const indexStep = remainUrl.indexOf('step=') -1; // $ before step=
                if (indexStep > 1) {
                    remainUrl = remainUrl.substring(0, indexStep);
                }

                const _url = UrlUtil.ensureCorrectLink(remainUrl);
                const newUrl = url + _url;

                window.history.pushState({
                    step: this.comp.model.currentCardName
                },
                    this.comp.model.currentCardName,
                    `${newUrl}&step=${this.comp.model.currentCardName}`
                );
            }
        }

    }

// VALIDATIONS ********************************

    /**Checks if calculator has Address supplement  (price * 1.6)*/
    public hasAddressSupplement(): boolean {
        try {
            const zipCitySearch = `${this.comp.model.personInfo.zipCode} ${this.comp.model.personInfo.zipName}`.toLocaleLowerCase();
            const zipFound = this.comp.cms.callUpAddressesBlocks.find(address => address.zipCity === zipCitySearch);
            if (zipFound) {
                const streetName = this.comp.model.personInfo.streetName.toLocaleLowerCase();
                const streetFound = zipFound.streets.find( street => street.name === streetName);
                if (streetFound) {
                    if (streetFound.nos) {
                        const no = this.comp.model.personInfo.houseNr.toLocaleLowerCase();
                        return streetFound.nos.includes(no);
                    }
                } else {
                    return false;
                }
                return true;
            }
            return false;
        } catch (error) {
            console.error(error);
            return false;

        }
    }

    public isValidAlmbrandProducts() {
        let ok = this.comp.model.personInfo.almbrandCustomer !== undefined;
        if (ok) {
            if (this.comp.model.personInfo.almbrandCustomer === 'ja' ) {
                ok = this.comp.model.personInfo.existingAlmBrandProducts?.length > 0;
            }
        }
        return ok;
    }

    public isValidPersonInfo(): boolean {
        const okPerson = this.comp.model.personInfo.zipCode &&
            this.comp.model.personInfo.zipName &&
            Validator.isValidAge(this.comp.model.personInfo.customerAge) &&
            this.isValidAlmbrandProducts();
        if (okPerson) {
            this.comp.model.personInfo.customerAge = this.comp.model.personInfo.customerAge.trim().replace(/\D/g,'');
            this.comp.model.personInfo.subtitle = `${this.comp.model.personInfo.customerAge} år, ${this.comp.model.personInfo.zipCode} ${this.comp.model.personInfo.zipName}`;
        }
        return okPerson;
    }

    public isValidOtherPersonInfo(): boolean {
        const okPerson = this.comp.model.personInfo.zipCode &&
            this.comp.model.personInfo.zipName &&
            Validator.isValidAge(this.comp.model.personInfo.otherCustomerAge) &&
            this.isValidAlmbrandProducts();
        if (okPerson) {
            this.comp.model.personInfo.otherCustomerAge = this.comp.model.personInfo.otherCustomerAge.trim().replace(/\D/g,'');
            this.comp.model.personInfo.subtitle = `${this.comp.model.personInfo.otherCustomerAge} år, ${this.comp.model.personInfo.zipCode} ${this.comp.model.personInfo.zipName}`;
        }
        return okPerson;
    }

    public isValidContactInfo(): boolean {
        if (this.comp.model.contact_information.customerNo) {
            // only validate Email and customer accept
            return !!(this.comp.model.contact_information.accept &&
                Validator.isValidEmail(this.comp.model.contact_information.email));
        }
        const okContact = this.comp.model.contact_information.accept &&
        Validator.isValidName(this.comp.model.contact_information.name) &&
        Validator.isValidPhone(this.comp.model.contact_information.phone) &&
        Validator.isValidEmail(this.comp.model.contact_information.email) &&
        Validator.isValidCpr(this.comp.model.contact_information.cpr)
        return !!okContact;
    }

    public isValidAdditionalInfo(skipInsuranceFields: boolean): boolean {
        let ok = true;
        if (!skipInsuranceFields) {
            ok = !!(this.comp.model.additionalInfo.existInsurance && this.comp.model.additionalInfo.validNow);
            if (!ok) {
                return ok;
            }
            if (this.comp.model.additionalInfo.existInsurance === 'ja') {
                ok = Validator.isValidExistingInsurance(this.comp.model.additionalInfo.existingInsurance);
                if (!ok) {
                    return ok;
                }
            }
        }
        if (!this.comp.model.additionalInfo.validNow) {
            return false;
        }
        if (this.comp.model.additionalInfo.validNow === 'nej') {
            ok = Validator.isValidFromDate(this.comp.model.additionalInfo.validFromDate);
        }

        if (ok && !this.comp.model.multipleProducts) {
            // if in basketmode, RKI and damages question will be in another flow
            ok = this.comp.model.additionalInfo.rki === 'nej';
            if (ok) {
                ok = this.comp.model.additionalInfo.skader && this.comp.cms.damagesOk.includes(this.comp.model.additionalInfo.skader);
            }
        }

        return !!ok;
    }

    public isValidPaymentInfo(): boolean {
        return Validator.isValidRegNo(this.comp.model.payment.regNo) &&
            Validator.isValidKontoNo(this.comp.model.payment.kontoNo);
    }

    public isValidPackage(): boolean {
        const ok = !!(this.comp.model.choosePackage.selectedPackage && this.comp.model.choosePackage.ownRiskId);
        if (ok && !this.comp.model.overview.highlights) {
            this.comp.setOverviewData();
        }
        return ok;
    }

    public get showValidNowOption(): boolean {
        if (this.comp.model.additionalInfo.existInsurance === 'nej' || (this.comp.model.additionalInfo.existInsurance === 'ja' && this.comp.model.additionalInfo.existingInsurance)) {
            return true;
        }
        return false;
    }

    public isPreviousStepsValid(cardName: string): boolean {
        const stepInx = this.comp.steps.indexOf(cardName);
        if(stepInx <= 0 ) { // 'none' or 1. step
            return true;
        }
        let c = stepInx - 1;
        while(c >= 0) {
            if (!this.comp.isValid(this.comp.steps[c])) {
                return false;
            }
            c--;
        }
        return true;
    }

    public isStepPreviousToCurrent(cardName: string): boolean {
        if(cardName === STEP.OVERVIEW && this.comp.model.multipleProducts && this.comp.model.productName !== 'kurv') {
            return false;
        }
        return this.comp.steps.indexOf(cardName) < this.comp.steps.indexOf(this.comp.model.currentCardName);
    }

    public handleValidCustomerAge(): boolean {
        const ok = Validator.isValidAge(this.comp.model.personInfo.customerAge);
        if (!ok) {
            this.comp.model.modal.title = this.comp.cms.customerAgePopupTitle;
            this.comp.model.modal.content = this.comp.cms.customerAgePopupContent;
            this.comp.model.modal.redirect = this.comp.cms.customerAgePopupRedirect;
            this.comp.model.modal.btnSecondLabel = this.comp.model.modal.redirect ? 'Afslut' : 'Ok';
            this.comp.model.modal.track = false;
            this.comp.model.modal.id = 'customerAgePopup';
            this.comp.model.modal.show = true;
            this.toggleModal();
        }
        // clean customer age input
        this.comp.model.personInfo.customerAge = this.comp.model.personInfo.customerAge.trim().replace(/\D/g,'');
        return ok;
    }

// Data ********************************
    public static getCalculatorIndex(name: string): number {
        const productInx = name.replace(/[^\d]/g, '');
        return productInx === '' ? -1 : parseInt(productInx);
    }

    public static getShortProductName(productName: string): string {
        const name = this.stripProductIndex(productName);
        const inx = this.getCalculatorIndex(productName);
        let productInx = '';
        if (inx > 0) {
            productInx = ' ' + (inx);
        }
        switch(name) {
            case InsurancesProductEnum.BIL_FORSIKRING:
            case InsurancesProductEnum.INDBO_FORSIKRING:
            case InsurancesProductEnum.REJSE_FORSIKRING:
            case InsurancesProductEnum.FRITIDSHUS_FORSIKRING:
            case InsurancesProductEnum.HUS_FORSIKRING:
            case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING:
                return name.replace('forsikring', '') + productInx;
            case InsurancesProductEnum.ULYKKES_FORSIKRING:
            case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
                return name.replace('sforsikring', '') + productInx;
            case InsurancesProductEnum.HUNDE_FORSIKRING:
                return name.replace('eforsikring', '') + productInx;
            default: return productName +  productInx;
        }
    }

    public static getCalculatorNameUi(name: string): string {
        let n = name.charAt(0).toLocaleUpperCase() + name.slice(1)
        const inx = this.getCalculatorIndex(n);
        return inx < 1 ? n.replace('' + inx , '') : n.replace('' + inx , ' ' + (inx + 1)); // næste forsikring hedder 2 ikke 1
    }


    private handleQueryParams() {
        try {
            // check for customerNo in query
            const query = this.comp.$route.query;
            if (Object.keys(query).length === 0) {
                return;
            }
            const customerNo = query.customerno as string || query.customerNo as string || query.K as string;
            if (customerNo) {
                this.comp.model.contact_information.customerNo = customerNo;
            }
            // check for includedProducts
            if (this.comp.cms.queryProductNames.length > 0) {
                this.comp.cms.queryProductNames.forEach((name, inx) => {
                    const value = query[name];
                    if (value !== undefined) {
                        if (value === 1 || value.toLocaleLowerCase().trim() === 'true') {
                            this.comp.model.personInfo.almbrandCustomer = 'ja';
                            this.comp.model.personInfo.existingAlmBrandProducts.push(this.comp.cms.queryProductValues[inx]);
                        }
                    }
                });
            }
            const source = query.utm_source as string
            if (source) {
                this.comp.abGtm.triggerCustomGtmEvent({
                    'event': 'track-vp',
                    'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.comp.model.productName}/utm_source/${source}`
                });
            }
            const medium = query.utm_medium as string
            if (medium) {
                this.comp.abGtm.triggerCustomGtmEvent({
                    'event': 'track-vp',
                    'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.comp.model.productName}/utm_medium/${medium}`
                });
            }
        } catch (error) {
            // too bad
        }
    }

    private async handleCampaigns() {
        if (this.comp.model.campaign.ID && this.comp.model.campaign.valid) {
            // campaign already loaded (basket or localStorage)
            return;
        }
        let campaignID = this.comp.$route.query.partner as string;
        if (!campaignID) {
            campaignID = this.comp.$route.query.campaign as string;
        }
        if (!campaignID) {
            campaignID = this.comp.$route.query.utm_campaign as string;
        }
        
        if (!campaignID) {
            const hasStoredCampaign = await this.loadCampaignFromStorage();
            if (hasStoredCampaign) {
                return; // campaign loaded from storage
            }
        }

        this.comp.model.campaign.ID = campaignID?.toLocaleLowerCase();
        this.comp.model.campaign.valid = false;

        if (this.comp.model.campaign.ID && this.comp.model.campaign.ID === 'diba') {
            if (this.comp.cms.validCampaigns.includes(this.comp.model.campaign.ID))  {
                // TODO klk DIBA campaign check unique key via future api, if it is valid
                this.comp.model.campaign.uniqueKey = this.comp.$route.query.partnerGuid as string || this.comp.$route.query.partnerguid as string;
                if (this.comp.model.campaign.uniqueKey) {
                    this.comp.model.campaign.valid = true;
                }
            }
        } else if (this.comp.model.campaign.ID) {
            if (this.comp.cms.validCampaigns.includes(this.comp.model.campaign.ID))  {
                this.comp.model.campaign.valid = true;
            }
        }

        // standard almbrand online campaign - fallback
        if (!this.comp.model.campaign.valid && this.comp.cms.validCampaigns.includes('almbrandonline')) {
            this.comp.model.campaign.valid = true;
            this.comp.model.campaign.ID = 'almbrandonline';
        }

        if (this.comp.model.campaign.valid) {
            const campaign = this.comp.cms.campaigns.get(this.comp.model.campaign.ID);
            this.comp.model.campaign.productStepDiscount = campaign.productStepDiscount;
            this.comp.model.campaign.discount = campaign.discount;
            this.comp.model.campaign.originalDiscount = campaign.discount;
            this.comp.model.campaign.discountDisplay = Formatter.format(100 * campaign.discount) + ' %';
            this.comp.model.campaign.tiaDiscount = campaign.tiaDiscount;
            this.comp.model.campaign.description = campaign.description;
            this.comp.model.campaign.groupId = campaign.groupId;
            this.comp.model.campaign.includeDiscountPlusCustomer = campaign.includeDiscountPlusCustomer;
            if (this.comp.cms.useLocalStorage) {
                // save to localStorage
                let expires = new Date();
                expires.setTime(new Date().getTime() + this.comp.cms.campaignStoreValidMillis);
                // clonedeep, since loaclForgae cannot persist vue proxy
                localForage.setItem('campaign' + this.comp.model.productName, cloneDeep({
                    campaign: this.comp.model.campaign,
                    expires
                }));
            }
        }
    }

    /** discount depending on amount of selected products */
    public static getDiscountForAmountProducts(campaign) {
        if (campaign.productStepDiscount) {
            const basket = store.getters.getSelectableGroup('basket');
            const productAmount = basket.length;
            if (productAmount >= campaign.productStepDiscount.length) {
                return campaign.productStepDiscount[campaign.productStepDiscount.length -1];
            }
            switch (productAmount) {
                case 0 :
                case 1 : return campaign.productStepDiscount[0];
                default: return campaign.productStepDiscount[productAmount - 1];
            }
        }
        return campaign.discount;
    }


    private async loadCampaignFromStorage(): Promise<boolean> {
        try {
            if (!this.comp.cms.useLocalStorage) {
                // don't load from localStorage
                return;
            }
            const campaign: any = await localForage.getItem('campaign'+ this.comp.model.productName);
            if(!campaign) {
                return false;
            }
            if (new Date().getTime() < campaign.expires.getTime()) {
                this.comp.model.campaign = campaign.campaign;
                return true;
            } else {
                // remove from storage
                localForage.removeItem('campaign'+ this.comp.model.productName);
            }
            return false;
        } catch (error) {
            // Not handled - could be versioning error
            return false;
        }

    }
    // cleanup campaign in storage if it exists
    public resetCampaignStorage(model: any) {
        if (model?.campaign?.valid) {
            try {
                const campaignName = 'campaign' + model.productName;
                localForage.removeItem(campaignName);
            } catch(e) {
                // no problem
            }
        }
    }

    private removeStepsIfBasket() {
        if (this.comp.model.multipleProducts && this.comp.model.productName !== 'kurv') {
            this.comp.steps = this.comp.steps.filter( step => !STEPS_EXCLUDE_BASKET.includes(step));
        }
        // When STEP.OVERVIEW is not present - routelink for STEPS:CHOOSE_PACKAGE must be updated
        const choosePackageBlock = this.comp.contentBlocks.find( block => block.name === 'choose_package');
        if(choosePackageBlock) {
            choosePackageBlock.routePath = STEP.ADDITIONAL_INFO;
        }

    }
    private removeUnreferencedBlocks() {
        let removeBlocks = [];
        this.comp.contentBlocks.forEach((block, inx) => {
            if (this.comp.model.campaign.valid && block.name === this.comp.model.campaign.ID) { // check for partner welcome page
                this.comp.model.campaign.hasContentPage = true;
                return;
            }
            if (!this.comp.steps.includes(block.name)) {
                removeBlocks.push(inx);
                return;
            }
            if (this.comp.model.multipleProducts || !this.comp.model.showExistingAlmBrandProducts) {
                // DOG
                if (this.comp.model.productName === InsurancesProductEnum.HUNDE_FORSIKRING && block.name === STEP.PERSON_INFO) {
                    if (this.comp.isValid(STEP.PERSON_INFO)) {
                        // remove step person_info
                        removeBlocks.push(inx);
                        // this.comp.cms.defaultCard = STEP.PACKAGE;
                        this.comp.contentBlocks.find( block => block.name === STEP.DOG_INFO).routePath = STEP.PACKAGE;
                    }
                }
                // CHILDREN ACCIDENT
                if (this.comp.model.productName === InsurancesProductEnum.BOERNEULYKKES_FORSIKRING && block.name === STEP.PERSON_INFO) {
                    if (this.comp.isValid(STEP.PERSON_INFO)) {
                        // remove step person_info
                        removeBlocks.push(inx);
                        this.comp.contentBlocks.find( block => block.name === STEP.CHILDREN_INFO).routePath = STEP.PACKAGE;
                    }
                }
                // HOUSE
                if (this.comp.model.productName === InsurancesProductEnum.HUS_FORSIKRING && block.name === STEP.PERSON_INFO) {
                    if (this.comp.isValid(STEP.PERSON_INFO)) {
                        // remove step person_info
                        removeBlocks.push(inx);
                        this.comp.cms.defaultCard = STEP.PACKAGE;
                        if (this.comp.model.campaign.valid) {
                            const block = this.comp.contentBlocks.find( block => block.name === this.comp.model.campaign.ID);
                            if (block) {
                                block.routePath = STEP.PACKAGE;
                            }

                        }
                    }
                }
                //TRAVEL
                if (this.comp.model.productName === InsurancesProductEnum.REJSE_FORSIKRING && block.name === STEP.PERSON_INFO) {
                    if (this.comp.isValid(STEP.PERSON_INFO)) {
                        // remove step person_info
                        removeBlocks.push(inx);
                        this.comp.cms.defaultCard = STEP.PACKAGE;
                        if (this.comp.model.campaign.valid) {
                            const block = this.comp.contentBlocks.find( block => block.name === this.comp.model.campaign.ID);
                            if (block) {
                                block.routePath = STEP.PACKAGE;
                            }
                        }
                    }
                }
                // DONT REMOVE STEPS IN ACCIDENT
                // DONT REMOVE STEPS IN CAR
            }

        });

        removeBlocks = removeBlocks.reverse();
        removeBlocks.forEach(inx => {
            this.comp.contentBlocks.splice(inx, 1);
        });
    }

    public getCoverageNames(selectedPackage) {
        if(selectedPackage.expandedCoverages) {
            return selectedPackage.expandedCoverages.join(", ");
        }

        const names = [];
        selectedPackage.coverages.forEach(element => {
            names.push(element.name || element.title);
        });
        return names.join(", ");
    }

// UPDATES ********************************
    private async handleLoggedInUser() {
        try {
            if (!this.comp.cms.tryLoadCustomer || this.comp.model.contact_information.customerNo) {
                // cms blocked for load or customer already loaded
                return;
            }
            const customerCalculation: CustomerCalculation = this.comp.$store.getters.getCustomerCalculation;
            if (!customerCalculation.hasCustomerData || !customerCalculation.customerFetched) {
                await new Promise((resolve) => {
                    let maxRun = 50; // wait for customer to load 10 seconds
                    const interval = setInterval(() => {
                        if(customerCalculation.customerFetched || maxRun < 1) {
                            clearInterval(interval);
                            resolve('Done waiting');
                        }
                        maxRun--;
                    }, 200)
                });
            }

            if (!customerCalculation.hasCustomerData) {
                return;
            }
            if (!this.comp.model.contact_information.name) {
                this.comp.model.contact_information.phone = customerCalculation.customer.contact_information.phone;
                this.comp.model.contact_information.customerNo = customerCalculation.customer.contact_information.customerNo;
                this.comp.model.contact_information.name = customerCalculation.customer.contact_information.name;
                this.comp.model.contact_information.email = customerCalculation.customer.contact_information.email;
            }

            if (!this.comp.model.personInfo.zipCode && this.comp.model.productName !== InsurancesProductEnum.FRITIDSHUS_FORSIKRING) {
                this.comp.model.personInfo.address = customerCalculation.customer.personInfo.address;
                this.comp.model.personInfo.zipCode = customerCalculation.customer.personInfo.zipCode
                this.comp.model.personInfo.zipName = customerCalculation.customer.personInfo.zipName;
                this.comp.model.personInfo.streetName = customerCalculation.customer.personInfo.streetName;
                this.comp.model.personInfo.houseNr = customerCalculation.customer.personInfo.houseNr;
                this.comp.model.personInfo.floorNr = customerCalculation.customer.personInfo.floorNr;
                this.comp.model.personInfo.doorNr = customerCalculation.customer.personInfo.doorNr;
            }

            if (!this.comp.model.personInfo.almbrandCustomer) {
                if (!customerCalculation.agreementsFetched) {
                    // wait for agreements
                    new Promise((resolve, reject) => {
                        let maxRun = 30;
                        const interval = setInterval(() => {
                            if (customerCalculation.agreementsFetched || maxRun < 1) {
                                if (customerCalculation.agreementsFetched) {
                                    this.comp.model.personInfo.almbrandCustomer = customerCalculation.customer.personInfo.almbrandCustomer;
                                    this.comp.model.personInfo.existingAlmBrandProducts = customerCalculation.customer.personInfo.existingAlmBrandProducts;
                                    if (this.comp.model.personInfo.almbrandCustomer === 'ja' && this.comp.model.personInfo.existingAlmBrandProducts?.length > 0) {
                                        this.comp.model.showExistingAlmBrandProducts = false;
                                        this.comp.$store.dispatch(DISCOUNT_UPDATE);
                                    }
                                }
                                clearInterval(interval);
                                resolve('Done waiting');
                            } else {
                                maxRun--;
                            }
                        }, 150);
                    });
                } else {
                    this.comp.model.personInfo.almbrandCustomer = customerCalculation.customer.personInfo.almbrandCustomer;
                    this.comp.model.personInfo.existingAlmBrandProducts = customerCalculation.customer.personInfo.existingAlmBrandProducts;
                    if (this.comp.model.personInfo.almbrandCustomer === 'ja' && this.comp.model.personInfo.existingAlmBrandProducts?.length > 0) {
                        this.comp.model.showExistingAlmBrandProducts = false;
                        this.comp.$store.dispatch(DISCOUNT_UPDATE);
                    }

                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    public setHighLights(calculator?: any): void {
        let calculation: any;
        if (calculator) {
            calculation = calculator;
        } else {
            calculation = this.comp;
        }
        BuyInsuranceHelper.setHighLights_(calculation);
    }

    public static setHighLights_(calculator: any) {
        const calculation = calculator;
        calculation.model.overview.highlights = [];
        const isPlusCustomer = calculation.model.calculation.discount && calculation.model.calculation.discount < 1;

        let hasCampaignDiscount = false;
        let campaignDiscount = 0;
        let discountDisplay: string = undefined;
        let hasCampaignSplash = false
        if (calculation.model.campaign.valid) {
            hasCampaignDiscount = calculation.model.campaign.discount > 0;
            if (hasCampaignDiscount) {
                campaignDiscount = calculation.model.campaign.discount;
                discountDisplay = calculation.model.campaign.discountDisplay;

            } else {
                hasCampaignDiscount = calculation.model.campaign.tiaDiscount > 0;
                if (hasCampaignDiscount) {
                    campaignDiscount = calculation.model.campaign.tiaDiscount;
                    discountDisplay = Formatter.format(100 * campaignDiscount) + ' %';
                }
            }
            hasCampaignSplash = calculation.model.campaign.splash;
        }

        if (isPlusCustomer) {
            let txt = '<div class="grow"><i>Plus</i>Kunde rabat fratrukket pris </div><div>';
            if(hasCampaignDiscount) {
                txt = '<div class="grow"><i>Plus</i>Kunde rabat </div><div>'
            }
            calculation.model.overview.highlights.push(
                `${txt}<div>${calculation.model.calculation.discountDisplay}</div>`
            )
        }
        if (hasCampaignDiscount) {
            let txt = '<div class="grow">Online rabat fratrukket pris </div>';
            if(isPlusCustomer) {
                txt = '<div class="grow">Online rabat </div>'
            }
            calculation.model.overview.highlights.push(
                `${txt}<div>${discountDisplay}</div>`
            )
        }
        if (isPlusCustomer && hasCampaignDiscount) {
            const totalDiscount = Math.abs(calculation.model.calculation.discount - 1) + campaignDiscount;
            const display = Number(totalDiscount).toLocaleString("da-DK",{style: 'percent', minimumFractionDigits:0});
            calculation.model.overview.highlights.push(`<div class="grow">Samlet rabat fratrukket pris </div><div><strong>${display}</strong></div>`);
        }

        if (hasCampaignSplash) {
            calculation.model.overview.highlights.push(`<div class="grow">Kampagne </div><div>${calculation.model.campaign.splash}</div>`);
        }
    }
    public setDetailsYearlyPrice(){
        if (!this.comp.cms.overviewShowYearlyPrice) {
            // wait for all calcs
            let max = 20;
            const interval = setInterval(() => {
                max--;
                if(this.comp.model.choosePackage.selectedPackage.yearlyPriceTotalDisplay || max < 1) {
                    this.comp.model.overview.details = ['Årlig pris: ' + this.comp.model.choosePackage.selectedPackage.yearlyPriceTotalDisplay, ...this.comp.model.overview.details];
                    clearInterval(interval);
                }
            }, 500);
        }
    }

    public getSubtitle(cardName: string) {
        switch(cardName) {
            case STEP.CAR_INFO : return this.comp.model.carInfo.subtitle;
            case STEP.DOG_INFO: return this.comp.model.dogInfo.subtitle;
            // case STEP.FAMILY_INFO:
            case STEP.CHILDREN_INFO: return this.comp.model.familyInfo.subtitle;
            case STEP.PERSON_INFO : return this.comp.model.personInfo.subtitle;
            case STEP.OVERVIEW : return this.comp.model.overview.subtitle;
            case STEP.ADDITIONAL_INFO : return this.comp.model.additionalInfo.subtitle;
            case STEP.PACKAGE : return this.comp.model.choosePackage.subtitle;
        }
        return this.comp.model[cardName] ? this.comp.model[cardName].subtitle : undefined;
    }

    public static isOpenHours(open: string, closed: Array<string> = []) {
        // const open: string = '07.30, 15.30, 1.2.3.4.5';
        //const open: string = this.comp.cms.open;
        if (!open) {
            return true;
        }
        const timeArr = open.split(',');

        const days = timeArr[2].split('.').map( (item) => {
            return parseInt(item);
        });
        const now = new Date();

        let isOpen = false;
        if (days.includes(now.getDay())) {
            const start = timeArr[0].split('.').map( (item) => {
                return parseInt(item);
            });
            const end = timeArr[1].split('.').map( (item) => {
                return parseInt(item);
            });

            const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), start[0], start[1]);
            const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), end[0], end[1]);

            isOpen = now.getTime() > startTime.getTime() && now.getTime() < endTime.getTime();
        }

        if (isOpen) {
            // check for closing days
            //this.comp.cms.closingDays
            isOpen = !this.isClosingDay(closed);
        }
        return isOpen;
    }

    public setOpenTxtOnReciept(multipleProducts: boolean, missingCalculations: boolean) {
        const isOpen = BuyInsuranceHelper.isOpenHours(this.comp.cms.open, this.comp.cms.closingDays);
        let txt: string;
        if (isOpen) {
            txt = this.comp.cms.recieptOpenTxt;
        } else {
            txt = this.comp.cms.recieptClosedTxt;
        }

        if (multipleProducts) {
            if (missingCalculations) {
                if (isOpen) {
                    // overwrite txt
                    txt = this.comp.cms.recieptOpenMissingProduct;
                } else {
                    // overwrite txt
                    txt = this.comp.cms.recieptClosedMissingProduct;
                }
            } else {
                txt += this.comp.cms.recieptBasketTxt;
            }
        } else {
            txt += this.comp.cms.recieptOneTxt;
        }

        this.comp.cardReciept.text = txt;
    }

    private static isClosingDay(closed: Array<string>): boolean {
        try {
        const closingDays: Array<string> = closed;
        if (!closingDays) {
            return false;
        }
        let isClosingDay = false;
        const now = new Date();
        const date = (now.getMonth() + 1) + '-' + now.getDate();
        isClosingDay = closingDays.includes(date);
        return isClosingDay;

        } catch(e) {
            return false;
        }
    }
// ORDER By Email ********************************

    public async submitOrder(desc: string, tracking: string) {

        desc = `<h2>Kunde ønsker en ny ${this.comp.model.productName}</h2>${desc}`;

        if(this.comp.model.campaign.valid) {
            const id = this.comp.model.campaign.ID ? this.comp.model.campaign.ID.toLocaleUpperCase() : 'Ukendt!'
            if (this.comp.model.campaign.uniqueKey) {
                desc += this.addProp(`${id} unik ID`, this.comp.model.campaign.uniqueKey);
            }
            if (this.comp.model.campaign.description) {
                desc += this.addProp(`Kampagne beskrivelse`, this.comp.model.campaign.description);
            }
            if (this.comp.model.campaign.groupId > 0) {
                desc += this.addProp(`Kampagne Gruppe kode`, this.comp.model.campaign.groupId);
            }
            if (this.comp.model.campaign.discount > 0) {
                desc += this.addProp(`Kampagne rabat inkluderet i pris`, this.comp.model.campaign.discountDisplay);
            }
            if (this.comp.model.campaign.tiaDiscount > 0) {
                desc += this.addProp(`Kampagne rabat fra TIA inkluderet i pris`, Formatter.format(100 * this.comp.model.campaign.tiaDiscount) + ' %');
            }
            if (this.comp.model.campaign.splash) {
                desc += this.addProp(`Kampagne specifik`, this.comp.model.campaign.splash);
            }
        }
        const params: Map<string, string> = this.comp.buildDescriptionForEmail(desc);

        params.set('subject', `Kunde ønsker ${this.comp.model.productName}`);
        params.set('name', this.comp.model.contact_information.name);
        params.set('cprNo', this.comp.model.contact_information.cpr);
        params.set('email', this.comp.model.contact_information.email);
        params.set('phoneNo', this.comp.model.contact_information.phone);

        const totalPrice =  this.comp.model.choosePackage.monthYear === 'M' ? this.comp.model.choosePackage.selectedPackage.totalPrice : this.comp.model.choosePackage.selectedPackage.yearlyPriceTotal;
        const monthly = this.comp.model.choosePackage.monthYear === 'M';
        const monthlyYearlyDisplay = monthly ? ' kr. Månedligt' : ' kr. Årligt';

        params.set('products', this.comp.capitalizeFirstLetter(this.comp.model.productName));
        params.set(this.comp.model.productName.replace('ø', 'oe') + '0', this.comp.capitalizeFirstLetter(this.comp.model.productName) + ', ' + ((Formatter.format(totalPrice) + monthlyYearlyDisplay)));
        params.set('isOpenSingle', BuyInsuranceHelper.isOpenHours(this.comp.cms.open) + '');
        params.set('uncalculatedProducts', 'false');

        params.set('price', totalPrice + '');
        params.set('priceFormatted', '0');
        params.set('monthlyPayment', monthly + '');
        params.set('multipleInsurances', 'false');

        const startTime = new Date().getTime();

        await this.comp.sendMailToOssNoLogin(params);

        if (this.comp.cms.mailConfirmationToTeam) {
            await this.sendMailToTeam();
        }

        await this.comp.$store.dispatch(RESET_SELECTABLE, 'basket');
        this.comp.addResetSubscription();
        this.resetCampaignStorage(this.comp.model);

        this.addStepToHistory(true);
        this.comp.abGtm.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.comp.model.productName}/order${tracking}`
        });

        const mailDuration = (new Date().getTime() - startTime);
        const fakeTimeout = this.comp.cms.animationTimeoutBuy - mailDuration;
        setTimeout(() => {
            this.setOpenTxtOnReciept(false, false);
            this.comp.model.showReciept = true;
            this.comp.model.showSpinner = false;
            window.scrollTo(0,0);
            this.comp.model.currentCardName = 'reciept';
            setTimeout(() => {
                VueScrollTo.scrollTo(`#reciept`, 300, { easing: 'ease-out', offset: -25 });
            }, this.comp.cms.animationTimeout);
        }, (fakeTimeout < 0 ? 100 : fakeTimeout) );

        // if stuff hangs
        setTimeout( () => {
            if(this.comp.model?.showSpinner === true) {
                this.comp.model.showSpinner = false;
            }
        }, 7000);
    }

    public async sendMailToTeam() {
        try {
            const now: Date = new Date();
            let data = `<h2>Kunde ønsker en ny ${this.comp.model.productName}</h2>`;
            data += this.addProp('Email sendt', `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${(now.getMonth()+1)}-${now.getFullYear()}`);
            data += this.addProp('Tlf',this.comp.model.contact_information.phone);
            data += this.addProp('Email',this.comp.model.contact_information.email);
            data += this.addProp('KundeNr',this.comp.model.contact_information.customerNo);
            const params: Map<string, string> = new Map();
            params.set('subject', `Online salg af ${this.comp.model.productName}`);
            params.set('description', data);

            await this.comp.sendMailToTeamNoLogin(params);
        } catch(e) {
            // too bad
        }
        return;
    }

    public async sendErrorMailToTeam(err) {
        try {
            const now: Date = new Date();
            const e: Error = <Error> err;
            let data = `<h2>Kunde ønsker en ny ${this.comp.model?.productName}</h2>`;
            data += this.addProp('Email sendt', `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${(now.getMonth()+1)}-${now.getFullYear()}`);
            data += this.addProp('Tlf',this.comp.model?.contact_information?.phone);
            data += this.addProp('Email',this.comp.model?.contact_information?.email);
            data += this.addProp('KundeNr',this.comp.model?.contact_information?.customerNo);
            data += this.addProp('Error msg',e?.message);
            data += this.addProp('Error name',e?.name);
            data += this.addProp('Error stack',e?.stack);

            const params: Map<string, string> = new Map();
            params.set('subject', `ERROR Online salg af ${this.comp.model?.productName}`);
            params.set('description', data);

            await this.comp.sendMailToTeamNoLogin(params);
        } catch(e) {
            // too bad
        }
        return;
    }

    public static async sendMailLead(comp) {
        console.log('sendMailLead');
        try {
            const products = store.getters.getSelectableGroup('basket');
            let contact_information = store.getters.getCalculatorCommonModel?.contact_information;
            if (!contact_information?.phone && !contact_information?.email ) {
                contact_information = store.getters.getActiveCalculator?.model?.contact_information;
                if (!contact_information?.phone && contact_information?.email) {
                    // console.log('no contact_information', store.getters.getActiveCalculator?.model?.contact_information, store.getters.getCalculatorCommonModel);
                    return null;
                }
            }
            // console.log('contact_information', contact_information );
            if (!contact_information.accept) {
                return;
            }
            // console.log('contact_information', contact_information, store.getters.getActiveCalculator?.model?.contact_information?.phone, store.getters.getCalculatorCommonModel);
            const now: Date = new Date();
            let prods: string = 'Ingen valgte produkter';
            if (products?.length > 0) {
                prods = this.stripProductIndex(products.join(', ').trim());
            }
            // console.log('products', prods, contact_information );
            
            let data = `<h2>Mulig lead - kunde har valgt online : ${prods}</h2>`;
            data += this.addProp('Email sendt', `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${(now.getMonth()+1)}-${now.getFullYear()}`);
            data += this.addProp('Tlf',contact_information.phone);
            data += this.addProp('Email',contact_information.email);
            if (contact_information.customerNo) {
                data += this.addProp('Kunde logget ind','Ja');
                data += this.addProp('KundeNr',contact_information.customerNo);
            } else {
                data += this.addProp('Kunde logget ind','Nej');
            }
            data += this.addProp('Accepteret betingelser',contact_information.accept ? 'Ja' : 'Nej');
            const params: Map<string, string> = new Map();
            params.set('subject', `Mulig lead - online salg af ${prods}`);
            params.set('description', data);

            await comp.sendMailToTeamNoLogin(params);
        } catch(e) {
            // console.error('e', e);
            // too bad
        }
        return;
    }

// TRACKING ********************************

    public trackPurchase(product: any, price: number) {
        const transactionComplete = {
            ecommerce: {
                purchase: {
                    actionField: {
                        id: '' + new Date().getTime(),
                        affiliation: 'Alm. Brand. Online Salg',
                        revenue: '' + price,
                        tax: '0',
                        shipping: '0',
                    },
                    products: [product]
                }
            },
            event: 'transactionComplete'
        };
        this.comp.abGtm.triggerCustomGtmEvent(transactionComplete);
    }

// CALCULATIONS *************************

    public static handleSpecialCarDiscounts(calculator): {specialDiscount: number, specialDiscountType: string} {
        const res = {specialDiscount: 0, specialDiscountType: undefined};
        if (calculator.model.productName !== InsurancesProductEnum.BIL_FORSIKRING) {
            return res;
        }
        let addSpecialDiscount = false;

        if (calculator.cms.vehicleDiscountEnabled) {
            addSpecialDiscount = true;
            if (calculator.model.campaign.valid) {
                if (!calculator.cms.vehicleDiscountEnabledInCampaign.includes(calculator.model.campaign.ID)) {
                    addSpecialDiscount = false;
                }
            }
        }
        if (addSpecialDiscount) {
            const grp = calculator.model.carInfo.vehicleGroup + '';
            if (calculator.cms.vehicleDiscountGroups.includes(grp)) {
                res.specialDiscount = calculator.cms.vehicleDiscount;
                res.specialDiscountType = 'vehicleGroup';
            }
        }

        if (calculator.model?.carInfo?.isElectricHybrid && calculator.model.calculation.discount < 1 ) {
            // pluskunde og el/hybrid
            addSpecialDiscount = true;
            if (calculator.model.campaign.valid) {
                if (!calculator.cms.electricFuelTypeDiscountEnabledInCampaign.includes(calculator.model.campaign.ID)) {
                    addSpecialDiscount = false;
                }
            }
            if (addSpecialDiscount) {
                const grp = calculator.model.carInfo.vehicleGroup + '';
                if (calculator.cms.electricFuelTypeVehicleGroups.includes(grp)) {
                    res.specialDiscount = calculator.cms.electricFuelTypeDiscount;
                    res.specialDiscountType = 'fuelType';
                }
            }
        }

        return res;
    }

    public getSuggestedProducts(): {suggestedProducts: string, discountDisplay: string} {
        return BuyInsuranceHelper.getSuggestedProducts(this.comp.$store, this.comp.cms);
    }

    public static getSuggestedProducts($store: any, cms: any): {suggestedProducts: string, discountDisplay: string} {
        const basket = $store.getters.getSelectableGroupUnique('basket');
        if (basket.length === 0) {
            return {
                suggestedProducts: 'at tilføje <strong>' + cms.discountPrimaryProducts[0] +'</strong> og eksempelvis <strong>'+ cms.discountSecondaryProducts[0] + '</strong>',
                discountDisplay: '5 %',
            }
        }
        const rebate = this.getDiscount($store, cms);

        let products = [];
        let personInfo = $store.getters.getCalculatorCommonModel?.personInfo;

        if (!personInfo?.almbrandCustomer) {
            // get from active calculator
            personInfo = $store.getters.getActiveCalculator?.model?.personInfo;
        }

        if (personInfo?.existingAlmBrandProducts ) {
            products = Object.assign([], personInfo.existingAlmBrandProducts);
            products = products.map( product => product.toLocaleLowerCase());
        }


        basket.forEach(product => {
            if(!products.includes(product)) {
                products.push(product);
            }
        });

        if (products.includes(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING)) {
            if (!products.includes(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
                products.push(InsurancesProductEnum.ULYKKES_FORSIKRING);
            }
        }

        if (products.includes(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING)) {
            if (!products.includes(InsurancesProductEnum.INDBO_FORSIKRING)) {
                products.push(InsurancesProductEnum.INDBO_FORSIKRING);
            }
            if (!products.includes(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
                products.push(InsurancesProductEnum.ULYKKES_FORSIKRING);
            }
        }

        // kun hus eller fritidshus er rabatgivende
        if (products.includes(InsurancesProductEnum.HUS_FORSIKRING) && products.includes(InsurancesProductEnum.FRITIDSHUS_FORSIKRING)) {
            products = products.filter(item => item !== InsurancesProductEnum.FRITIDSHUS_FORSIKRING);
        }

        const suggestProducts = cms.discountSecondaryProducts.filter(element => {
            return !products.includes(element.toLocaleLowerCase());
        });

        if (!products.includes('indboforsikring')) {
            switch (suggestProducts.length) {
                case 4 : return {
                        suggestedProducts: 'at tilføje <strong>' + cms.discountPrimaryProducts[0] +'</strong> og eksempelvis <strong>'+ cms.discountSecondaryProducts[0] + '</strong>',
                        discountDisplay: '5 %'
                    }
                case 3 : return {
                    suggestedProducts: 'at tilføje <strong>' + cms.discountPrimaryProducts[0] + '</strong>',
                    discountDisplay: '5 %'
                }
                case 2 : return {
                    suggestedProducts: 'at tilføje <strong>' + cms.discountPrimaryProducts[0] + '</strong>',
                    discountDisplay: '10 %'
                }
                case 1 :
                case 0 : return {
                    suggestedProducts: 'at tilføje <strong>' + cms.discountPrimaryProducts[0] + '</strong>',
                    discountDisplay: '15 %'
                }
            }
        } else {
            switch (rebate.discount) {
                case 0.85: return undefined; // ikke mere pluskunde rabat
                case 0.90: return {
                    suggestedProducts: 'eksempelvis at tilføje <strong>' + suggestProducts[0] + '</strong>',
                    discountDisplay: '15 %'
                };
                case 0.95: return {
                    suggestedProducts:  'eksempelvis at tilføje <strong>' + suggestProducts[0] + '</strong>',
                    discountDisplay: '10 %'
                };
                default: return {
                    suggestedProducts:  'eksempelvis at tilføje <strong>' + suggestProducts[0] + '</strong>',
                    discountDisplay: '5 %'
                };
            }
        }
    }

    public static mapPriceResult(response) {
        const res = response.data.quotes[0];
        response.data.totalPrice = res.total_price;
        response.data.statutoryFee = res.statutory_fee;
        response.data.rebatePrice = res.alternative_prices;
    }

    public getRisksForPackage(packageId: number) {
        // console.log('chooosing package', packageId);
        
        const pack = this.comp.model.calculation.abCalc.packages.find( pack => packageId === pack.id);
        //  console.log('found package for id', packageId, pack);
        // console.log('coverages', this.comp.model.calculation.abCalc.coveragesDetails);
        
        const selectedRisks = pack.includedCoveragesExpandedIds.filter( riskNo => {
            if (riskNo > -1) {
                return riskNo;
            }
        });
        
        // console.log('selected risks', selectedRisks);
        

        
        // find coverages in riskGroups
        const risksInGroups: Array<number> = [];
        // console.log('this.comp.model.calculation.abCalc.coveragesDetails', this.comp.model.calculation.abCalc.coveragesDetails);
        selectedRisks.forEach(riskId => {
            this.comp.model.calculation.abCalc.coveragesDetails.forEach(coverage => {
                if (coverage.riskId !== -1 && coverage.riskGroup === riskId && coverage.riskId !== riskId) {
                    risksInGroups.push(coverage.riskId);
                }
            });
        });
        // console.log('found risks in groups', risksInGroups);

        const res = selectedRisks.concat(risksInGroups).sort((a, b) => {
            return a - b;
          });

        // console.log('using risks', res);
        
        return res.toString();
    }

    public setPrices(packageId, response, monthly, pack, specialProductDiscount: number = 0) {
        pack.forEach(calc => {
            if (calc.id === packageId) {
                if (monthly) {
                    response.data.totalPrice = response.data.totalPrice / 12; // always recieve yearly price
                    response.data.statutoryFee = response.data.statutoryFee / 12; // always recieve yearly fee
                }

                let hasDogFee = false;
                if (response.data.specialFee) {
                    if (this.comp.model.calculation.discount === 0.95 || (this.comp.model.calculation.discount === 1 && this.comp.cms.alwaysUseSickAccident)) {
                        hasDogFee = true;
                    }
                }
                if (this.comp.cms.plusCustomerCalculation) {
                    let totalPrice = response.data.totalPrice;// - response.data.statutoryFee;
                    totalPrice *= 1.05 // PK1
                    const statutoryFee = totalPrice * 0.03525;
                    response.data.totalPrice = totalPrice;// + statutoryFee;
                    response.data.statutoryFee = statutoryFee;
                }
                // vehicleGroup/fuelType Discount for now
                calc.specialDiscount = specialProductDiscount ? specialProductDiscount : undefined;

                if (this.comp.model.calculation?.addressSupplement > 1) {
                    response.data.totalPrice *= this.comp.model.calculation.addressSupplement; // adresse tillæg (60%)
                }

                if (monthly) {
                    // console.log('setting monthly prices');
                    
                    calc.rebatePrice = response.data.rebatePrice;
                    calc.rebateProcent = response.data.rebateProcent;
                    calc.statutoryFee = response.data.statutoryFee;
                    calc.basePrice = response.data.totalPrice;
                    calc.specialFee = response.data.specialFee;
                    let totalPrice = response.data.totalPrice - response.data.statutoryFee;

                    // dog pluscustomer 5% PK1 - sickness disease coverage
                    if (hasDogFee) {
                        totalPrice += calc.specialFee / 12;
                    }

                    // pluscustomer discount -> 1 | 0.95 | 0.9 | 0.85
                    totalPrice *= this.comp.model.calculation.discount;

                    // special discount
                    if (calc.specialDiscount) {
                        totalPrice -= (totalPrice * calc.specialDiscount);
                    }

                    // campaign discount
                    if (this.comp.model.campaign.valid)  {
                        totalPrice -= (totalPrice * this.comp.model.campaign.discount);
                    }

                    calc.totalPrice = Math.round( totalPrice + response.data.statutoryFee);
                    calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';

                } else {
                    // console.log('setting yearly prices');
                    calc.yearlyRebatePrice = response.data.rebatePrice;
                    calc.yearlyBasePrice = response.data.totalPrice;
                    calc.yearlyStatutoryFee = response.data.statutoryFee;
                    calc.yearlySpecialFee = response.data.specialFee;
                    let totalPrice = response.data.totalPrice - response.data.statutoryFee;

                    // dog pluscustomer 5% PK1 - sickness disease coverage
                    if (hasDogFee) {
                        totalPrice += calc.yearlySpecialFee;
                    }

                    // pluscustomer discount -> 1 | 0.95 | 0.9 | 0.85
                    totalPrice *= this.comp.model.calculation.discount;

                    // special discount
                    if (calc.specialDiscount) {
                        totalPrice -= (totalPrice * calc.specialDiscount);
                    }

                    // campaign discount
                    if (this.comp.model.campaign.valid)  {
                        totalPrice -= (totalPrice * this.comp.model.campaign.discount);
                    }

                    calc.yearlyPriceTotal = Math.round( totalPrice + response.data.statutoryFee);
                    calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
                }
            }
        });
    }

    /** calculate pluskunde discount
     *  EKS.
        Børnefamilieforsikring | (Indboforsikring + Ulykkesforsikring)	5 %
        Børnefamilieforsikring | (Indboforsikring + Børne- Ulykkesforsikring) + fritids- Husforsikring	10 %
        Børnefamilieforsikring | (Indboforsikring + Børne- Ulykkesforsikring) + fritids- Husforsikring + Bilforsikring	15 %
    */
    public getDiscount(): {discount: number, discountDisplay: string} {
        return BuyInsuranceHelper.getDiscount(this.comp.$store, this.comp.cms);
    }
    public static getDiscount($store: any, cms: any): {discount: number, discountDisplay: string} {
        const rebate = {discount: 1, discountDisplay: undefined};
        let products = [];

        // get commonModel for calculators
        let personInfo = $store.getters.getCalculatorCommonModel?.personInfo;

        if (!personInfo?.almbrandCustomer) {
            // get from active calculator
            personInfo = $store.getters.getActiveCalculator?.model?.personInfo;
        }

        if (personInfo?.existingAlmBrandProducts ) {
            products = Object.assign([], personInfo.existingAlmBrandProducts);
            products = products.map( product => product.toLocaleLowerCase());
        }

        const basket = $store.getters.getSelectableGroupUnique('basket');

        basket.forEach(product => {
            if(!products.includes(product)) {
                products.push(product);
            }
        });
        // find primary product, and remove if present (homeInsurance)
        let count:number = 0;
        cms.discountPrimaryProducts.forEach( (primaryProduct) => {
            if (products.includes(primaryProduct.toLocaleLowerCase())) {
                count = 1; // only one product will count
            }
        });
        // no discount
        if (count === 0) {
            return rebate;
        }
        // find first secondary product, and remove if present (accident, car, house or vacationHouse)
        cms.discountSecondaryProducts.forEach( (secondaryProduct) => {
            if (products.includes(secondaryProduct.toLocaleLowerCase())) {
                count++;
            }
        });
        // Børnefamilieforsikring || børneulykke
        if(!products.includes(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
            if(products.includes(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING) || products.includes(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING) ) {
                count++;
            }
        }
        // kun en af hus- fritishus forsikring gælder
        if (products.includes(InsurancesProductEnum.HUS_FORSIKRING) && products.includes(InsurancesProductEnum.FRITIDSHUS_FORSIKRING)) {
            count--;
        }

        // no discount, only indbo
        if (count === 1) {
            return rebate;
        }
        // find correct discount -> count remaining products and set discount
        switch (count) {
            case 2 : rebate.discount = .95;
                break;
            case 3 : rebate.discount = .90;
                break;
            default : rebate.discount = .85;
                break;
        }

        const discount = Math.abs(rebate.discount-1);
        rebate.discountDisplay = Number(discount).toLocaleString("da-DK",{style: 'percent', minimumFractionDigits:0});
        return rebate;
    }

    public reducePack(pack) {
        return {
            coverages: pack.coverages,
            expandedCoverages: pack.expandedCoverages,
            name: pack.name,
            id: pack.id,
            rebatePrice: pack.rebatePrice,
            rebateProcent: pack.rebateProcent,
            statutoryFee: pack.statutoryFee,
            totalPrice: pack.totalPrice,
            totalPriceDisplay: pack.totalPriceDisplay,
            yearlyPriceTotal: pack.yearlyPriceTotal,
            yearlyPriceTotalDisplay: pack.yearlyPriceTotalDisplay,
        }
    }

// UTILS ********************************

    public addProp(label: string, val: string = ''):string {
        return `${label}: <b>${val}</b><br>`;
    }

    public static addProp(label: string, val: string = ''):string {
        return `${label}: <b>${val}</b><br>`;
    }

    public getExistingProductList(): Array <string> {
        // check if "no products" selected
        return this.comp.model.personInfo.existingAlmBrandProducts.includes(this.comp.cms.discountProductNone) ?
            [] : this.comp.model.personInfo.existingAlmBrandProducts;
    }

    public buildExistingProductsDescription(): string {
        let description = this.addProp('Eksisterende kunde', this.comp.model.personInfo.almbrandCustomer);
        if (this.comp.model.personInfo.almbrandCustomer === 'ja') {
            const existingProducts = this.getExistingProductList();
            description += this.addProp('Nuværende forsikringer', existingProducts.length > 0 ? existingProducts.join(', ') : 'Ingen');
        }
        if (this.comp.model.calculation.discount && this.comp.model.calculation.discount < 1) {
            // Børnefamilie giver 5% Pluskunde rabat
            description += this.addProp('PlusKunde rabat inkluderet i pris', this.comp.model.calculation.discountDisplay);
        }
    return description;
    }

}
