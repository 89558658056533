<template>
    <div>
        <div v-html="formattedText" class="mb-3"></div>
 
        <div v-if="feature.included" v-for="(highlight) in highlights" :key="highlight.label" class="d-flex justify-content-between">
            <p v-html="highlight.label"></p>
            <p class="font-family-work-sans-bold" v-html="highlight.value"></p>
        </div>

        <!-- show action btn here if there are no sub features-->
        <div v-if="hasAction && !hasSubfeatures() && !isThemed" class="text-center">
            <div role="button" @click="feature.changeFunction(), feature.selected = false" :class="[btnClass, 'btn-sm mt-3']">
                <span>{{getBtnText()}}</span>
                <!-- <span class="alternate">{{feature.actionLabelShort}}</span> !-->
            </div>
        </div>
        

        <div v-if="feature.formattedPrice">
            <div>{{feature.formattedPrice}}</div>
            <div>
                {{ feature.included ? "Fjern" : "Tilføj"}}
            </div>
        </div>
        
    </div>
</template>
