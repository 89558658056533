<template>
    <div :class="['cookie-footer', { 'cookie-footer--themed' : isThemed }, { 'cookie-footer--app' : isLoggedInAsApp() }]">
        <ModalComponent v-if="cookieInformation">
            <template v-slot:trigger>
                <span class="cookie-footer__link mr-2">Om cookies</span>
            </template>
            <template v-slot:body>
                <span v-html="cookieInformation"></span>
            </template>
        </ModalComponent>
        <a v-else href="https://www.almbrand.dk/kundeservice/generel-information/cookies/" target="_blank" class="cookie-footer__link mr-2">Om cookies</a>
        |
        <a href="https://www.almbrand.dk/kundeservice/generel-information/vi-passer-paa-dine-persondata/" target="_blank" class="cookie-footer__link ml-2">Persondatapolitik</a>
    </div>
</template>

<style src="./_assets/_cookie-footer.scss" lang="scss"></style>
