<template>
    <div :class="['accordion__item', { 'accordion__item--open' : coverage.selected }]">
        <div :class="['accordion__header',
                     {'accordion__header--track_covered' : track && isCovered},
                     {'accordion__header--track_not_covered' : track && !isCovered}]"
                v-on:click="animateController()"
                v-on:keyup.enter="animateController()"
                role="button"
                tabindex="0"
                :data-gtm-action="trackGtm ? 'Accordian' : null"
                :data-gtm-label="getTrackGtmLabel()"
                :data-gtm-url="trackingUrl">
            <img v-if="isCovered && !noCheckMark" class="accordion__icon" :src="'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj48ZGVmcz48c3R5bGU+LmEsLmIsLmR7ZmlsbDpub25lO30uYSwuYntzdHJva2U6cmdiYSg1LDQxLDgxLDEpO30uYXtzdHJva2Utd2lkdGg6MnB4O30uYntzdHJva2Utd2lkdGg6M3B4O30uY3tzdHJva2U6bm9uZTt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIyNCAtMzM3KSI+PGcgY2xhc3M9ImEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIyNCAzMzcpIj48L2c+PHBhdGggY2xhc3M9ImIiIGQ9Ik0tMzEsNDkuODgtMjIuMzI2LDU5LTguODM0LDQwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNjQuMjQzIDMwNy4yODgpIi8+PC9nPjwvc3ZnPg=='"/>
            <h3 :class="['accordion__headline',
                    {'accordion__headline--track_covered' : track && isCovered},
                    {'accordion__headline--track_not_covered' : track && !isCovered}]">
                {{coverage.title}}
            </h3>
        </div>
        <transition name="slide" :key="coverage.id">
                <div v-show="coverage.selected" :class="['accordion__body', {'accordion__body--has-icon' : isCovered && !noCheckMark}]" :key="refreshKey">
                    <productline-body-component :feature="coverage"
                                                :hasAction="coverage.hasAction"
                                                :productTitle="productTitle"
                                                :btnClass="btnClass"
                                                :btnLabel="btnLabel" />

                    <div class="sub-accordion" v-if="coverage.feature.sub_features && coverage.feature.sub_features.length > 0">
                        <productline-sub-feature-component v-for="subItem in coverage.feature.sub_features"
                                                            :key="subItem.sub_feature_id"
                                                            :id="subItem.sub_feature_id"
                                                            :headline="subItem.name"
                                                            :desc="subItem.description"
                                                            :excess="subItem.excess"
                                                            :sum="subItem.sum"
                                                            :productTitle="productTitle"
                                                            :isCovered="isCovered"/>
                        <!-- show action btn here if there are sub features-->
                        <div v-if="coverage.hasAction && !isThemed" class="text-center">
                            <div role="button" @click="coverage.changeFunction(), coverage.selected = false" :class="[btnClass, 'btn-sm mt-3']">
                                <span>{{getBtnText()}}</span>
                            </div>
                        </div>

                    </div>
                </div>
        </transition>
    </div>
</template>
