import { createStore } from "vuex";
import ConfigEpi from "./modules/configEpi";

import {
  ContentPage,
  OrderflowContainerBlock,
} from "@/definitions/episerver/content-types";
import authenticationContext, {
  AuthenticationState,
} from "@/episerver/store/modules/authenticationContext";
import calculatorContext, {
  CalculatorContextState,
} from "@/episerver/store/modules/calculatorContext";
import epiContent, {
  EpiContentState,
} from "@/episerver/store/modules/epiContent";
import epiContext, {
  EpiContextState,
} from "@/episerver/store/modules/epiContext";
import selectableContext, {
  SelectableContextState,
} from "@/episerver/store/modules/selectableContext";
import { Cart } from "@/generated-api/cart";
import { CatalogProductList } from "@/generated-api/catalog";
import { CustomerAgreement } from "@/generated-api/customer-agreements";
import { Claim } from "@/generated-api/customer-claims";
import Actions from "./actions";
import Getters from "./getters";
import UserData from "./modules/userData";
import Mutations from "./mutations";

export type CmsRootState = {
  windowWidth: number;
  scrollPosition: number;
  isTouchDevice: boolean;
  allowTracking: boolean;
  isOpenCalculator: boolean;

  userData: UserData;
  configEpi: ConfigEpi;
  customer: {};
  businessCustomer: {};
  adminData: {};
  adminDashboard: boolean;
  userPresent: boolean;
  epiRouting: [];
  currentContentPage: ContentPage;
  contentPages: Map<string, ContentPage>;
  usabilla: { mounted: number; count: number };
  agreementList: {};
  isFetchingAgreementList: boolean;
  customerAgreements: Map<string, CustomerAgreement>;
  catalogProductList: CatalogProductList;
  claimList: Array<Claim>;
  billingList: Array<any>;
  inboxList: Array<any>;
  globalOrderflow: OrderflowContainerBlock;
  newPolicy: Map<string, any>;
  lastUserUpdatedAgreementId: string;
  epiContent?: EpiContentState;
  epiContext?: EpiContextState;
  appReplaceRoute: boolean;
  cart: Cart;
  buyPolicy: Map<string, any>;
  consentSetInApp: boolean;
  dataMap: Map<string, any>;
  //brokerCustomerList: Array<UserInfoCustomerList>,
  calculatorContext?: CalculatorContextState;
  selectableContext?: SelectableContextState;
  authenticationContext?: AuthenticationState;
  oldAppVersionAlerted: boolean;
  modalState: boolean;
  recaptchaSiteKey: string;
};

const rootState = {
  windowWidth: 0,
  scrollPosition: 0,
  isTouchDevice: false,
  allowTracking: true,
  isOpenCalculator: false,

  userData: new UserData(),
  configEpi: new ConfigEpi(),
  customer: {},
  adminData: {},
  adminDashboard: false,
  userPresent: false,
  businessCustomer: {},
  epiRouting: [],
  currentContentPage: undefined,
  contentPages: new Map<string, ContentPage>(),
  usabilla: { count: 0, mounted: 0 },
  agreementList: {},
  isFetchingAgreementList: false,
  customerAgreements: new Map<string, CustomerAgreement>(),
  catalogProductList: undefined,
  claimList: undefined,
  billingList: undefined,
  inboxList: undefined,
  globalOrderflow: undefined,
  newPolicy: new Map<string, any>(),
  lastUserUpdatedAgreementId: undefined,
  appReplaceRoute: false,
  cart: undefined,
  buyPolicy: new Map<string, any>(),
  dataMap: new Map<string, any>(),
  //brokerCustomerList: undefined,
  consentSetInApp: false,
  oldAppVersionAlerted: false,
  modalState: false,
  recaptchaSiteKey: undefined,
} as CmsRootState;

export default createStore<CmsRootState>({
  state: rootState,
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
  modules: {
    epiContent,
    epiContext,
    authenticationContext,
    selectableContext,
    calculatorContext,
  },
});
