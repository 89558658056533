import { CreateOffer, ProductFeatures, Offer } from '@/generated-api/accident-quote';
import BuyAccidentChildren from './BuyAccidentChildrenComponent';
import { monthYearArray, ValuePair, Formatter, BuyInsuranceHelper } from '../BuyInsuranceHelper';
import {PACKAGES, lilleFeatures, storFeatures, productId} from './AccidentChildrenSettings';
import { cloneDeep } from 'lodash';
import { CustomerAgreementFeature} from '@/generated-api/customer-agreements';
import UrlHandler from '../UrlHandler';
import store from '@/store';
import AbAxiosSelfservice from '@/views/common/ab_axios_selfservice';
import FeatureAdapter from '@/views/model/adapter/FeatureAdapter';

export default class AccidentCalculator {
    private abAxiosSelfserviceOpen: AbAxiosSelfservice;
    private buyAccident: BuyAccidentChildren; // component using calculator
    private model: any; // the data structure for state and properties
    private cms: any; // settings from EPI
    private calculationUrl: string = '/insurance/accident';
    private oldCalcConfig: any;
    private tiaCoverages =  new Map<string, Array<FeatureAdapter>>();

    private errorPopup: any;

    constructor(buyAccident: BuyAccidentChildren) {
        this.buyAccident = buyAccident;
        this.model = buyAccident.model;
        this.cms = buyAccident.cms;

        this.errorPopup = {
            id: 'calcError',
            title: this.cms.defaultCalcErrorTitle,
            content: this.cms.defaultCalcErrorContent,
            btnSecondLabel: 'Ok',
            track: true,
            trackToken: 'error/tiacalculator',
            show: true,
        }
        const headers = [{ name: 'quote-api', value: 'true'}];
        this.abAxiosSelfserviceOpen = new AbAxiosSelfservice(store.getters.getConfigEpi.openServiceUrl + this.calculationUrl,
            'application/json',
            store.getters.getConfigEpi.csrfToken,
            headers,
        3);
        this.abAxiosSelfserviceOpen.useRecaptcha(true);
        
        this.setUpConfig();
    }

    private async setupTiaCoverages(): Promise<any> {
        return new Promise( (resolve) => {
            let featureAdapters: Array<FeatureAdapter> = [];
            this.cms.getFeaturesByAge(lilleFeatures, this.model.personInfo.customerAge).forEach( productFeature => {
                featureAdapters.push(...this.getMappedTiaCoverage(productFeature));
            });
            this.tiaCoverages.set(PACKAGES.LILLE, featureAdapters);

            featureAdapters = [];
            let features = this.cms.extendCoverages ? storFeatures : [...lilleFeatures, ...storFeatures];
            this.cms.getFeaturesByAge(features, this.model.personInfo.customerAge).forEach( productFeature => {
                featureAdapters.push(...this.getMappedTiaCoverage(productFeature));
            });
            this.tiaCoverages.set(PACKAGES.STOR, featureAdapters);

            return resolve(true);
        });
    }

    private getMappedTiaCoverage(productFeature): Array<FeatureAdapter> {
        const featureAdapters: Array<FeatureAdapter> = [];
        let featureFound = false;
        this.cms.productInfo.products.features.forEach( feature => {
            if (feature.feature_id === productFeature.name ) {
                let customerAgreementFeature: CustomerAgreementFeature = {
                    description: this.cms.getCoverageDescription(feature.title, feature.description),
                    feature_id: feature.feature_id,
                    name: this.cms.getCoverageName(feature.title),
                    sub_features: [],
                }
                let featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
                featureAdapters.push(featureAdapter);
                featureFound = true;
                // insert sub_feature_ids around here
            }
        });
        // some features are listed as subfeatures in .../category/products. For now, they are elavated to features.
        if(!featureFound) {
            this.cms.productInfo.products.sub_features.forEach( subfeature => {
                if (subfeature.sub_feature_id === productFeature.name ) {
                    let customerAgreementFeature: CustomerAgreementFeature = {
                        description: this.cms.getCoverageDescription(subfeature.title, subfeature.description),
                        feature_id: subfeature.sub_feature_id,
                        name: this.cms.getCoverageName(subfeature.title),
                        //sub_features: [{name: 'test', description: 'test beskrivelse'}],
                        sub_features: [],
                    }
                    let featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
                    featureAdapters.push(featureAdapter);
                    featureFound = true;
                }
            });
        }
        if (!featureFound) {
            console.error('Could not find feature with name', productFeature.name);
        }
        return featureAdapters;
    }

    private mapCoveragesFromCalc() {
        this.model.calculation.allCoverages = new Map<number, Map<number, Array<FeatureAdapter>>>();
        this.model.calculation.abCalc.packs.forEach( (calculations, ownRiscId) => {
            const coveragesForPackage = new Map<number, Array<FeatureAdapter>>();
            calculations.forEach((calc, packageInx) => {
                coveragesForPackage.set(packageInx, this.tiaCoverages.get(calc.name));
                calc.tiaCoverages = this.tiaCoverages.get(calc.name);
                if (this.cms.extendCoverages) {
                    if(calc.name === PACKAGES.LILLE) {
                        // calc.expandedCoverages = this.tiaCoverages.get(calc.name);
                        calc.expandedCoverages = [...this.tiaCoverages.get(PACKAGES.LILLE).map( (e) => e.title)];
                    } else if (calc.name === PACKAGES.STOR) {
                        calc.expandedCoverages = [...this.tiaCoverages.get(PACKAGES.LILLE).map( (e) => e.title), ...this.tiaCoverages.get(calc.name).map( (e) => e.title)];
                    }
                }
            });
            this.model.calculation.allCoverages.set(ownRiscId, coveragesForPackage);
        });
    }

    public async setUpConfig() {
        // hardcoded oldConfig
        this.oldCalcConfig = this.cms.oldCalcConfig.insuranceConfiguration;

        this.cms.mapCoverages(this.cms.oldCalcConfig.textMappingsOuter);

        this.model.calculation.abCalc = {
            excessIdDefault: this.oldCalcConfig.excessIdDefault,
            packageIds: this.oldCalcConfig.packageIds,
            packages: cloneDeep(this.oldCalcConfig.packages),
        };

        this.setupExcessList();
        this.setupTiaCoverages();

        this.model.calculation.abCalc.packages.forEach(element => {
            element.coverages = this.tiaCoverages.get(element.name);
        });

        this.model.calculation.abCalc.packs = new Map<number, Object>();
        this.model.calculation.abCalc.excesses.forEach(excess => {
            if (excess.id === this.model.calculation.abCalc.excessIdDefault) {
                const calcs = [];
                this.model.calculation.abCalc.packs.set(excess.id, calcs);
                this.model.calculation.abCalc.packages.forEach( (pack) => {
                    calcs.push(this.buyAccident.helper.reducePack(cloneDeep(pack)));
                });
            }
        });

        this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'
        this.mapCoveragesFromCalc();
        this.updateCalcKeys();
    }

    public setupExcessList() {
        this.model.calculation.abCalc.excesses = this.oldCalcConfig.excessList;
        if (this.model.calculation.abCalc.excesses[0].id === this.model.calculation.abCalc.excessIdDefault) {
            // ensure default excess is last in list
            this.model.calculation.abCalc.excesses.reverse();
        }
        this.model.ownRisks = [];
        // only one excess ( not used)
        this.model.calculation.abCalc.excesses.forEach((excess) => {
            if (excess.id === this.model.calculation.abCalc.excessIdDefault) {
                const valuePair: ValuePair = {
                    value: excess.id,
                    displayValue: Formatter.format(excess.amount) + ' kr.',
                };
                this.model.ownRisks.push(valuePair);
            }
        });
    }

    public async getCalculations(excessId?: number) : Promise<boolean> {
        if (this.model.calculation.isCalculated) {
            return;
        }
        this.model.showSpinner = true;
        this.model.calculating = true;
        // handle "eternal" spinning
        setTimeout(() => {
            if(this.model.showSpinner) {
                this.model.showSpinner = false;
                this.model.calculating = false;
            }
        }, this.cms.calculationSpinnerTimeout);

        // reset calculation and setup coverages by age
        await this.setUpConfig();
        this.model.calculation.abCalc.calculated = true;

        try {
            const rebate = this.buyAccident.helper.getDiscount();
            this.buyAccident.model.calculation.discount = rebate.discount;
            this.buyAccident.model.calculation.discountDisplay = rebate.discountDisplay;

            let promises = [];
            
            this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach(calc => {
                calc.summedBasePrice = 0;
                calc.summedStatutoryFee = 0;// not reurned by api
                calc.summedTotalPrice = 0;

                calc.summedYearlyBasePrice = 0;
                calc.summedYearlyStatutoryFee = 0; // not reurned by api
                calc.summedYearlyPriceTotal = 0;
            });
            for (let i= 0; i <= this.model.familyInfo.personCountId; i++) {
                const age = this.model.familyInfo['age' + i];
                const workId = this.model.familyInfo['workId' + i];
                promises.push(this.calculateQuickQuote(PACKAGES.LILLE, age, workId));
                // await this.calculateQuickQuote(PACKAGES.LILLE, age, undefined);
            }
            await Promise.all(promises);
            this.updateCalcKeys();

            promises = [];
            for (let i= 0; i <= this.model.familyInfo.personCountId; i++) {
                const age = this.model.familyInfo['age' + i];
                const workId = this.model.familyInfo['workId' + i];
                promises.push(this.calculateQuickQuote(PACKAGES.STOR, age, workId));
                // await this.calculateQuickQuote(PACKAGES.STOR, age, undefined);
            }
            await Promise.all(promises);

            this.sumUpPrices();
            this.model.showSpinner = false;
            this.model.calculating = false;

            this.model.calculation.isCalculated = true;
            this.updateCalcKeys();
            this.buyAccident.abGtm.triggerCustomGtmEvent({
                'event': 'track-vp',
                'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/prices_shown`,
            });
            return Promise.resolve(true);
        } catch(err) {
            console.error('Accident offer', err);
            this.buyAccident.abGtm.triggerCustomGtmEvent({
                'event': 'track-vp',
                'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/error/tiaonlineoffer`,
            });

            console.error(err);
            this.buyAccident.toggleModal(this.errorPopup);
            this.model.showSpinner = false;
            this.model.calculating = false;
            return Promise.resolve(false);
        }
    }
    private sumUpPrices() {
        this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach(calc => {
            // console.log('calc', calc);
            calc.basePrice = calc.summedBasePrice;
            calc.totalPrice = calc.summedTotalPrice;
            calc.yearlyBasePrice = calc.summedYearlyBasePrice;
            calc.yearlyPriceTotal = calc.summedYearlyPriceTotal;
            calc.totalPriceDisplay = Formatter.format(calc.summedTotalPrice) + ' kr.';
            calc.yearlyPriceTotalDisplay = Formatter.format(calc.summedYearlyPriceTotal) + ' kr.';
        });
    }

    private async calculateQuickQuote(productType: string, childAge: string, workId: string): Promise<any> {
        let customerAge = parseInt(childAge);
        if (customerAge < this.cms.minChildAge) {
            customerAge = this.cms.minChildAge;
        }
        let features: Array<ProductFeatures> = this.getFeatures(productType);
        if (!features) {
            return Promise.reject();
        }
        const createOffer: CreateOffer =
        {
            product: {
                product_name: productId,
                features,
                parameters: {
                    age: customerAge,
                    postalCode: this.model.personInfo.zipCode,
                },
            }
        };
        workId ? createOffer.product.parameters.employment = workId: undefined;

        try {
            let res;
            let offer;
            try {
                res = await this.abAxiosSelfserviceOpen.offerServiceLayer.createOffer(createOffer);
                offer = res.data;
                if (!offer || !offer.price || !offer.price.annually) {
                    throw new Error();
                }
            } catch (error) {
                // retry
                res = await this.abAxiosSelfserviceOpen.offerServiceLayer.createOffer(createOffer);
                offer = res.data;
                if (!offer || !offer.price || !offer.price.annually) {
                    throw new Error();
                }
            }
            this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach(calc => {
                if (calc.name === productType) {
                    let totalPrice = offer.price.monthly;
                    calc.basePrice = totalPrice;
                    calc.statutoryFee = 0; // not reurned by api

                    // pluscustomer discount
                    totalPrice *= this.buyAccident.model.calculation.discount;

                    // campaign discount
                    if (this.model.campaign.valid)  {
                        totalPrice -= (totalPrice * this.model.campaign.discount);
                    }

                    calc.totalPrice = Math.round(totalPrice);

                    totalPrice = offer.price.annually;
                    calc.yearlyBasePrice = totalPrice;
                    calc.yearlyStatutoryFee = 0; // not reurned by api

                    // pluscustomer discount
                    totalPrice *= this.buyAccident.model.calculation.discount;

                    // campaign discount
                    if (this.model.campaign.valid)  {
                        totalPrice -= (totalPrice * this.model.campaign.discount);
                    }

                    calc.yearlyPriceTotal = Math.round(totalPrice);

                    calc.summedBasePrice += calc.basePrice;
                    calc.summedStatutoryFee = 0;// not reurned by api
                    calc.summedTotalPrice += calc.totalPrice;

                    calc.summedYearlyBasePrice += calc.yearlyBasePrice;
                    calc.summedYearlyPriceTotal += calc.yearlyPriceTotal;
                    calc.summedYearlyStatutoryFee = 0; // not reurned by api

                    // calc.totalPriceDisplay = Formatter.format(calc.summedTotalPrice) + ' kr.';
                    // calc.yearlyPriceTotalDisplay = Formatter.format(calc.summedYearlyPriceTotal) + ' kr.';
                    }
            });

            return Promise.resolve();
        } catch(err) {
            if (!this.buyAccident.isDevelop()) {
                console.error(err);
                this.buyAccident.toggleModal(this.errorPopup);
                return Promise.reject(err);
            } else if (this.cms.mockData) {
                // MOCK
                let add = 2000;
                this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach(calc => {
                    add += 100;
                    if (calc.name === productType) {
                        let totalPrice = add;
                        calc.basePrice = totalPrice;
                        calc.statutoryFee = 0; // not reurned by api
                        if (this.model.campaign.valid)  {
                            totalPrice -= (totalPrice * this.model.campaign.discount);
                        }

                        calc.totalPrice = Math.round(totalPrice * this.model.calculation.discount);
                        calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';

                        totalPrice = 12 * add * .97;
                        calc.yearlyBasePrice = totalPrice;
                        calc.yearlyStatutoryFee = 0; // not reurned by api

                        if (this.model.campaign.valid)  {
                            totalPrice -= (totalPrice * this.model.campaign.discount);
                        }
                        calc.yearlyPriceTotal = Math.round(totalPrice * this.model.calculation.discount);
                        calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
                        this.updateCalcKeys();
                    }
                });
            }






        };

    }

    private getFeatures(productType: string): Array<ProductFeatures> {
        const customerAge = this.model.personInfo.customerAge;

        switch(productType) {
            case PACKAGES.LILLE :  return this.cms.getFeaturesByAge(lilleFeatures, customerAge);
            // case PACKAGES.MELLEM : return  this.cms.getFeaturesByAge(mellemFeatures, customerAge);
            case PACKAGES.STOR : return this.cms.getFeaturesByAge([...lilleFeatures, ...storFeatures], customerAge);
            default: console.warn('no package for calculation selected', productType);
                return undefined;
        }
    }

    public updateCalcKeys() {
        this.model.calculation.updatePrices = this.model.calculation.updatePrices.map( (elem) => {
            return elem += '1';
        });
    }

}
