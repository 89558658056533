import { CustomerAgreementPart, CustomerAgreementSummaryList } from '@/generated-api/customer-agreements';
import store from '@/store';
import { isNullOrUndefined } from '@/views/common/util';
import TagsEnum from '@/views/model/TagsEnum';
import PaymentMethodEnum from "@/views/overview/insurances/BillingBlock/PaymentMethodEnum";
import TitleReplaceEnum from "@/views/overview/insurances/InboxBlock/TitleReplaceEnum";
import localForage from "localforage";
import AbCommon from './AbCommon';
import AgreementStatus from "./AgreementStatus";
import InsurancesProductCodeEnum from "./InsurancesProductCodeEnum";
import InsurancesProductEnum from "./InsurancesProductEnum";

export default class InsurancesCommon extends AbCommon {

    get storedAgreementList(): CustomerAgreementSummaryList {
        return store.getters.getAgreementList;
    }
    public async fetchCustomerAgreementList(page: number, showError: boolean = true) {
        try {

            // Check if a cachebust call is already running, and wait for it to terminate
            const loading = store.getters.isFetchingAgreementList;
            if (loading) {
                await this.waitForfetchCustomerAgreementList();
                return this.storedAgreementList;
            }
            store.dispatch('setFetchingAgreementList', true);
            const response = <CustomerAgreementSummaryList>
                (await this.abAxiosSelfservice.customerAgreementsServiceLayer.fetchCustomerAgreementList(page)).data;

            if (response.page !== undefined && response.page.total_pages > 1) {
                let response2 = null;
                let newAgreementsArr: any[] = [];
                let pagePricePerYear = response.page_price.per_year;
                for (let i = 1; i < response.page.total_pages; i++) {
                    response2 = <CustomerAgreementSummaryList>
                        (await this.abAxiosSelfservice.customerAgreementsServiceLayer.fetchCustomerAgreementList(page + i)).data;

                    //merge agreements
                    newAgreementsArr = newAgreementsArr.concat(response2.agreements);
                    pagePricePerYear = pagePricePerYear + response2.page_price.per_year;
                }
                response.agreements = response.agreements.concat(newAgreementsArr);
                response.price = pagePricePerYear;
            } else {
                response.price = response.page_price.per_year;
            }

            let isBusiness = await this.customerIsBusiness();
            if (response.agreements !== undefined) {
                response.agreements.forEach(agreement => {
                    if (this.hasPolicyChangedInSession(agreement)) {
                        agreement.status = AgreementStatus.NY_AENDRING;
                    }
                    agreement.title = agreement.title?.trim();
                    /**
                    * TODO aberec 10-08-2020
                    * Vi går i prod med erhverv uden PIM integration, så alle steder hvor PIM'en deler product id
                    * mellem privat og erhverv f.eks bilforsikring (AU_PB) skal dækninger filtreres fra for erhverv og titler skal ændres.
                    * Når vi åbner op for erhverv med PIM integration skal denne filter fjernes.
                    */
                    if (isBusiness) {
                        if (agreement.type_code === InsurancesProductCodeEnum.BILFORSIKRING) {
                            agreement.title = "Motorkøretøjsforsikring";
                        }
                        if (agreement.type_code === InsurancesProductCodeEnum.ARBEJDSMASKINEFORSIKRING) {
                            agreement.title = "Arbejdsmaskineforsikring";
                        }
                    }
                });
            } else {
                response.agreements = [];
            }

            store.dispatch('setAgreementList', response);
            store.dispatch('setFetchingAgreementList', false);
            return response;

        } catch (err) {
            store.dispatch('setFetchingAgreementList', false);
            if (showError) {
                this.handleApiServiceError(err, "Aftaler kunne ikke hentes. Noget gik galt.. ");
            }
        }
    }

    /**
     * if several calls to fetchCustomerAgreementList(true) are running simultaniously, let them
     * wait for the first call to come trough and then use the cached result.
     */
    public async waitForfetchCustomerAgreementList() {
        let interval: number | undefined;
        let maxRun = 80; //wait max 20 seconds
        try {
            return await new Promise((resolve) => {
                interval = setInterval(() => {
                    const loading = store.getters.isFetchingAgreementList;
                    if (!loading) {
                        clearInterval(interval);
                        return resolve(true);
                    }
                    if (--maxRun < 1) {
                        clearInterval(interval);
                        return resolve(false);
                    }
                }, 250);
            });
        } catch (err) {
            clearInterval(interval);
        }
    }

    public async getAgreementList(page: number = 1, showError: boolean = true) {
        const storedAgreements = this.storedAgreementList;
        if (storedAgreements && storedAgreements.agreements !== undefined) {
            return storedAgreements;
        } else {
            return await this.fetchCustomerAgreementList(page, showError);
        }
    }

    public async agreementIsPartner(id: string): Promise<boolean> {
        const product = await this.getCustomerAgreementById(id)
        return product.tags.includes(TagsEnum.VWSF_DK);
    }

    public async fetchCustomerAgreementById(agreement_line_seq_no: string, part_no?: number) {
        try {
            if (!isNullOrUndefined(agreement_line_seq_no)) {
                const customerAgreement = await this.abAxiosSelfservice.customerAgreementsServiceLayer.fetchCustomerAgreementById(agreement_line_seq_no, part_no);
                if (this.hasPolicyChangedInSession(customerAgreement.data)) {
                    customerAgreement.data.status = AgreementStatus.NY_AENDRING;
                }
                /**
                 * TODO aberec 10-08-2020
                 * Vi går i prod med erhverv uden PIM integration, så alle steder hvor PIM'en deler product id
                 * mellem privat og erhverv f.eks bilforsikring (AU_PB) skal dækninger filtreres fra for erhverv og titler skal ændres.
                 * Når vi åbner op for erhverv med PIM integration skal denne filter fjernes.
                 */
                try {
                    //gælder ikke VWSF kunder
                    if (!customerAgreement.data.tags.includes(TagsEnum.VWSF_DK)) {
                        let isBusiness = await this.customerIsBusiness();
                        if (isBusiness) {
                            if (customerAgreement.data.type_code !== InsurancesProductCodeEnum.BYGNING1) {
                                customerAgreement.data.product_instance.price.included_features = [];
                                customerAgreement.data.product_instance.price.optional_features = [];
                            }
                            if (customerAgreement.data.type_code === InsurancesProductCodeEnum.BILFORSIKRING) {
                                customerAgreement.data.title = "Motorkøretøjsforsikring";
                            }
                            if (customerAgreement.data.type_code === InsurancesProductCodeEnum.ARBEJDSMASKINEFORSIKRING) {
                                customerAgreement.data.title = "Arbejdsmaskineforsikring";
                            }
                        }
                    }
                }
                catch (err) {
                    console.error(err);
                }
                store.dispatch('setCustomerAgreement', customerAgreement.data);
                return customerAgreement.data;
            } 
        } catch(err) {
            this.handleApiServiceError(err, "Kundes forsikringsaftale kunne ikke hentes. Noget gik galt.. ");
        }
    }

    public hasPolicyChangedInSession(customerAgreement: any): boolean {
        const newPolicy = store.getters.getNewPolicy(customerAgreement.agreement_id);
        if (newPolicy && newPolicy.agreement_line_seq_no) {
            if (customerAgreement.agreement_line_seq_no !== newPolicy.agreement_line_seq_no) {
                console.warn('Userchange out of sync with TIA', customerAgreement.agreement_line_seq_no, newPolicy.agreement_line_seq_no);
                return true;
            }
        }
        return false;

    }
    public async getCustomerAgreementById(agreement_line_seq_no: any, part_no?: any) {
        if (!isNullOrUndefined(part_no)) {
            const customerAgreement = store.getters.getCustomerAgreementByIdAndPartNo(agreement_line_seq_no, part_no);
            if (customerAgreement) {
                return customerAgreement;
            } else {
                return await this.fetchCustomerAgreementById(agreement_line_seq_no, Number(part_no));
            }
        }
        else {
            const customerAgreement = store.getters.getCustomerAgreementById(agreement_line_seq_no);
            if (customerAgreement) {
                return customerAgreement;
            } else {
                return await this.fetchCustomerAgreementById(agreement_line_seq_no);
            }
        }
    }

    private async fetchProductList(): Promise<any> {
        try {
            const response = await this.abAxiosSelfservice.catalogServiceLayer.fetchProductList();
            store.dispatch('setProductList', response.data);
            return response.data;

        } catch (err) {
            this.handleApiServiceError(err, "Kundes produkt katalog kunne ikke hentes. Noget gik galt.. ");
        };
    }

    public async getProductList() {
        const productList = store.getters.getProductList;
        if (productList) {
            return productList;
        } else {
            return await this.fetchProductList();
        }
    }

    public async getClaimList() {
        const claimList = store.getters.getClaimList;
        if (claimList) {
            return claimList;
        } else {
            return await this.fetchClaimList();
        }
    }

    private async fetchClaimList(): Promise<any> {
        try {
            const response = await this.abAxiosSelfservice.customerClaimsServiceLayer.fetchClaimList();
            store.dispatch('setClaimList', response.data);
            return response.data;

        } catch (err) {
            this.handleApiServiceError(err, "Skader kunne ikke hentes. Noget gik galt.. ");
        };
    }


    public async getInboxList() {
        const inboxList = store.getters.getInboxList;
        if (inboxList) {
            return inboxList;
        } else {
            return await this.fetchInboxList();
        }
    }

    private async fetchInboxList(): Promise<any> {
        try {
            const response = await this.abAxiosSelfservice.customerInboxServiceLayer.fetchCustomerDocuments();

            let inbox = response.data as any;
            if (inbox.documents !== undefined) {
                for (let doc of inbox.documents) {
                    doc.formattedDate = this.formatDate(doc.date);
                    doc.dateMinusDot = doc.formattedDate.replace(/\./g, '');
                    if (doc.description === undefined) {
                        doc.description = "";
                    }
                    if (doc.description.length >= 1 && doc.description.length <= 3) {
                        doc.description = TitleReplaceEnum["Volapyk"];
                    }
                    if (doc.doc_type.length >= 1 && doc.doc_type.length <= 3) {
                        doc.doc_type = TitleReplaceEnum["Volapyk"];
                    }
                    let matchValue = TitleReplaceEnum[doc.doc_type];
                    if (matchValue !== null && matchValue !== undefined) {
                        doc.doc_type = matchValue;
                    }
                    if (screen.width <= 320) {
                        if (doc.name !== "" && doc.name.length > 17) {
                            doc.name = doc.name.substring(0, 17) + "...";
                        }
                        if (doc.description !== "" && doc.description.length > 25) {
                            doc.description = doc.description.substring(0, 25) + "...";
                        }
                    }
                }
            } else {
                inbox.documents = [];
            }
            store.dispatch('setInboxList', inbox);
            return response.data;

        } catch (err) {
            this.handleApiServiceError(err, "Indbakken kunne ikke hentes. Noget gik galt.. ");
        };
    }

    public async getBillingList() {
        const billingList = store.getters.getBillingList;
        if (billingList) {
            return billingList;
        } else {
            return await this.fetchBillingList();
        }
    }

    private async fetchBillingList(): Promise<any> {
        try {

            let yearsBack = 3;
            let now = new Date().getFullYear();

            let requestYears = [];
            for (let i = 0; i < yearsBack; i++) {
                let requestYear = now - i;
                let response1 = this.abAxiosSelfservice.customerInvoicesServiceLayer.fetchCustomerInvoiceList(requestYear, { timeout: 30000 });
                requestYears.push(response1);
            }

            let manipulateResponse: any[] = [];
            let responses = await Promise.all(requestYears);
            responses.forEach(item => {
                manipulateResponse.push(this.manipulateInvoice(item.data));
            })


            store.dispatch('setBillingList', manipulateResponse);
            return manipulateResponse;

        } catch (err) {
            this.handleApiServiceError(err, "Regninger kunne ikke hentes. Noget gik galt.. ");
        };
    }

    private manipulateInvoice(response: any) {
        let invoiceYear = response;
        invoiceYear.id = this.uuidv4();
        let keys = Object.keys(PaymentMethodEnum);
        for (let invoice of invoiceYear.invoices) {
            //accordian håndtering
            invoice.selected = false;
            invoice.description = this.capitalizeFirstLetter(this.buildDateSeparated(invoice.start_date)[1]);
            invoice.id = this.uuidv4();
            for (let payment of invoice.payments) {
                let dateParts = this.buildDateSeparated(payment.payment_date);
                payment.payment_date = `${dateParts[0]}. ${dateParts[1]}`;
                keys.forEach(key => {
                    if (key === payment.payment_method) {
                        payment.payment_method = PaymentMethodEnum[key];
                    }
                });
            }
        }

        return invoiceYear;
    }

    public sendMailToOss(params: Map<string, string>): Promise<any> {
        return this.sendMail(params, store.getters.getConfigEpi.mailOSSUrl);
    }

    public async sendMailToOssNoLogin(params: Map<string, string>): Promise<any> {
        this.abAxios.useRecaptcha(true);
        const res = await this.sendMail(params, store.getters.getConfigEpi.mailOSSNoLogin);
        this.abAxios.useRecaptcha(false);
        return res;

    }

    public async sendMailToTeamNoLogin(params: Map<string, string>): Promise<any> {
        this.abAxios.useRecaptcha(true);
        const res = await this.sendMail(params, store.getters.getConfigEpi.teamMailOSSNoLogin);
        this.abAxios.useRecaptcha(false);
        return res;
    }

    private async sendMail(params: Map<string, string>, to: string): Promise<any> {

        let obj = Array.from(params).reduce((obj, [key, value]) => (
            Object.assign(obj, { [key]: value })
        ), {});
        return await this.abAxios.abPostWithToken(to,
            obj,
            "application/json",
            false,
            () => { },
            () => { },
            '').then((response: any) => {
                // console.log(response)
                return Promise.resolve();
            }, (err: any) => {
                console.error('error', err);
                return Promise.reject();
            });

    }

    public async sendMailToTeam(topic: string, subject: string, description: string): Promise<any> {
        const customerId = store.getters.getCustomer?.customer_id;
        return await this.abAxios.abPostWithToken(store.getters.getConfigEpi.mailTeamUrl,
            {
                topic,
                description,
                subject, 
                customerId
            },
            "application/json",
            false,
            () => { },
            () => { },
            '').then((response: any) => {
                // console.log(response)
                return Promise.resolve();
            }, (err: any) => {
                console.error('error', err);
                return Promise.reject();
            });
    }

    public deepLinkIfPresent(): Promise<any> {
        // check if user has a saved deepurl because of redirect to login page
        return localForage.getItem('userRedirect').then((url) => {
            if (url) {
                // remove saved url from storage and redirect to it
                return localForage.removeItem('userRedirect').then(() => this.$router.push({ path: url as string }));
            }
        });
    }

    // Try to get logic from api.
    public getBaseInfoDescription(title: string, agreementPart: Array<CustomerAgreementPart>, description: string) {
        switch (title.toLowerCase()) {
            case InsurancesProductEnum.BIL_FORSIKRING:
            case InsurancesProductEnum.BAAD_FORSIKRING:
            case InsurancesProductEnum.KNALLERT_FORSIKRING:
            case InsurancesProductEnum.CAMPINGVOGNS_FORSIKRING:
            case InsurancesProductEnum.VETERAN_FORSIKRING:
            case InsurancesProductEnum.MOTORCYKEL_FORSIKRING:
            case InsurancesProductEnum.TRAKTOR_FORSIKRING:
            case InsurancesProductEnum.PAAHAENGSVOGNS_FORSIKRING:
            case InsurancesProductEnum.MOTORKOERETOEJS_FORSIKRING:
                const brand = this.getBaseInfoValueByName('brand', agreementPart[0].fields);
                const licensePlate = this.getBaseInfoValueByName('licence_plate', agreementPart[0].fields);
                let car: string;
                if (brand !== "" && licensePlate !== "") {
                    const brandModel = this.getBaseInfoValueByName('brand_model', agreementPart[0].fields);
                    if (this.utils.hasValue(brandModel)) {
                        car = brand + ' ' + brandModel;
                    } else {
                        car = brand;
                    }
                    car = this.utils.capitalizeFirstLetter(car, true);
                    car += ", " + this.buildAutoBaseInfoSubtitle(licensePlate);
                } else {
                    let descArr = description.split(" ");
                    let productDesc = "";
                    productDesc = descArr[1] + ", " + this.buildAutoBaseInfoSubtitle(descArr[0]);
                    return productDesc;
                }
                return car;

            case InsurancesProductEnum.HUS_FORSIKRING:
            case InsurancesProductEnum.INDBO_FORSIKRING:
            case InsurancesProductEnum.FRITIDSHUS_FORSIKRING:
            case InsurancesProductEnum.SAELGERANSVARS_FORSIKRING:
            case InsurancesProductEnum.EJERSKIFTE_FORSIKRING:
                const addr = agreementPart[0].address;
                let address =
                    addr?.street_name + " " +
                    addr?.street_no +
                    this.addSeparationSign(addr.floor, true, false, true) +
                    this.addSeparationSign(addr.floor_side, false, false, true);
                return address;

            case InsurancesProductEnum.HUNDE_FORSIKRING:
                const pet = this.getBaseInfoValueByName('dog_name', agreementPart[0].fields);
                return pet;

            case InsurancesProductEnum.ULYKKES_FORSIKRING:
            case InsurancesProductEnum.GRAVIDITETS_FORSIKRING:
            case InsurancesProductEnum.LOEN_FORSIKRING:
            case InsurancesProductEnum.SYGDOMS_FORSIKRING:
            case InsurancesProductEnum.UNGDOMS_FORSIKRING:
            case InsurancesProductEnum.KORTTIDSREJSE_FORSIKRING:
                const person = agreementPart[0].person_name;
                return person;

            case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
                const children = agreementPart[0].person_name;
                const childCount = children?.split(',').length;
                const childrenCovered = "Dækning for " + childCount + " børn";
                return childCount >= 2 ? childrenCovered : children;

            case InsurancesProductEnum.REJSE_FORSIKRING:
                let travel = this.getBaseInfoValueByName('destination', agreementPart[0].fields).toLowerCase() === "verden" ? "Verdens" : this.getBaseInfoValueByName('destination', agreementPart[0].fields);
                travel += 'dækning';
                return travel;

            case InsurancesProductEnum.ERHVERVSANSVARS_FORSIKRING:
            case InsurancesProductEnum.ERHVERV_OG_PRODUKTANSVAR_FORSIKRING:
            case InsurancesProductEnum.KOLLEKTIVULYKKE:
                return "";

            default:
                return this.utils.capitalizeFirstLetter(description, true);
        }
    }

    callbackAppSettings(title: string) {
        this.callFromNativeScript({ "APP_SETTINGS": title });
    }

    callbackLogout() {
        this.callFromNativeScript({ "LOG_OUT": {} });
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    public async getBrokerList() {
        const customerList = store.getters.getBrokerCustomerList;
        if (customerList) {
            return customerList;
        } else {
            return await this.fetchBrokerList();
        }
    }

    private async fetchBrokerList(): Promise<any> {
        try {

            const query = new URLSearchParams(window.location.search);
            const token = query.get("token");
            const customerList = await fetch("/api/v1/broker/customers", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({"token": token })
            }).then(res => res.json());
            store.dispatch('setBrokerCustomerList', customerList);

            return customerList;

        } catch (err) {
            this.handleApiServiceError(err, "Mægler betjente kunder kunne ikke hentes. Noget gik galt.. ");
        };
    }


    protected buildAutoBaseInfoSubtitle(info: string) {
        let desc = "";
        if (info === "UREG") {
            desc = info;
        } else {
            desc = info.slice(0, 2) + " " + info.slice(2, 4) + " " + info.slice(4);
        }
        return desc;
    }

    protected formatTextToClickable(description = ""): string {
        const phoneRegex = /((\(?\+45\)?)?)(\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2})/;
        const newLineRegex = /\r?\n/g;
        const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    
        return description
            .replace(phoneRegex, `<a href="tel:$&">$&</a>`)
            .replace(newLineRegex, '<br />')
            .replace(urlRegex, '<a href="$1" target="_blank">link</a>');
    }
    
}
