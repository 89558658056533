import { mapState } from 'vuex';
import InputComponent from '../../components/InputComponent.vue';
import { CheckboxComponent } from "@/almbrand/web-shared";
import { CardBlock } from '@/definitions/episerver/content-types';
import { CarSettings } from '../../car/CarSettings';
import { AccidentSettings } from '../../accident/AccidentSettings';
import { HomeSettings } from '../../home/HomeSettings';
import { HouseSettings } from '../../house/HouseSettings';
import { DogSettings } from '../../dog/DogSettings';
import { TravelSettings } from '../../travel/TravelSettings';
import InsurancesProductEnum from '@/views/model/InsurancesProductEnum';
import FieldValidator from '../../FieldValidator';
import { Validator } from '../../BuyInsuranceHelper';
import { Options, Vue } from 'vue-class-component';


@Options({
    name: 'ContactInformationStepComponent',
    components: {
        InputComponent,
        CheckboxComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
    props: {
        card: Object, // Card from cms
        isValid: Boolean, // wether card is complete
    }
})

export default class ContactInformationStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // wether card is complete

    public model!: any; // datastructure for user input (store)
    public cms!: DogSettings | TravelSettings | CarSettings | AccidentSettings | HomeSettings | HouseSettings; // settings from CMS (store)
    public fieldValidator: FieldValidator = Validator;
    public noEmailCustomer: boolean = false;

    public mounted() {
        if(this.model.contact_information.customerNo) {
            if(!this.fieldValidator.isValidEmail(this.model.contact_information.email)) {
                this.noEmailCustomer = true;
            }
        }
        window.addEventListener('keyup' , this.moveOn );
    }

    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    public setSubHeader(evt) {
        if(evt.event.type === 'blur') {
            this.model.contact_information.subtitle = evt.value;
        }
    }

    public checkboxEvent(evt) {
        this.model.contact_information.accept = evt.checked;
    }

    public nextStep() {
        this.$emit('nextStep', this.card.routePath);
    }

    public get showEmail(): boolean {
        if(this.model.contact_information.customerNo) {
            return this.noEmailCustomer;
        }
        return true;
    }
    public get hasChildrenCpr(): boolean {
        return this.model.productName === InsurancesProductEnum.BOERNEULYKKES_FORSIKRING ||
            this.model.productName === InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING;
    }

    public isValidChildCpr(inx) : string {
        return this.fieldValidator.getAgeUnderEighteen(this.model.familyInfo['cpr' + (inx)]) !== -1 ? 'input--valid' : undefined;
    }

    public get hasAdultCpr(): boolean {
        if (this.model.productName === InsurancesProductEnum.ULYKKES_FORSIKRING) {
            return this.model.personInfo.otherCustomerAge !== undefined;
        }
        return false;
    }

    public get isValidAdultCpr() : string {
        return this.fieldValidator.isValidCpr(this.model.personInfo.otherCpr) ? 'input--valid' : undefined;
    }

}
