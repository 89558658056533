import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState } from "vuex";
import PageTitle from "@/views/overview/_partials/pageTitle";
import ContentArea from "@/views/sharedcomponents/epiComponents/infrastructure/contentArea/contentArea";
import Private from "@/views/layouts/private";
import { App } from "@/views/sharedcomponents/lib/services/app";
import {useRoute} from 'vue-router'

@Options({
    name: 'SelfServicePageComponent',
    components: {
        ContentArea,
        PageTitle,
        Private
    },
    computed: mapState<any>({
        isInEditMode: state => state.epiContext.inEditMode,
    }),
    props: {
        model: Object as PropType<any>
    },
})

export default class SelfServicePageComponent extends Vue {
    model: any;

    // LIFECYCLE
    beforeMount() {
        /*fortæl app'en hvor webview er. Styrer f.eks native tilbage knap*/
        if (App.isEnabled()) {
            const route = useRoute();
            App.sendMessage({ "ROUTE_PATH": route.path });
        }
    }

    // COMPUTED
    get isTiles() {
        return this.model.mainArea && this.model.mainArea.some(x => x.contentType === "TileBlock");
    }

    get hideMenus() {
        if (this.model.disableAuthenticationCheck || this.model.hideMenus) {
            return true;
        }

        return false;
    }
}
