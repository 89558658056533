import { Options, Vue} from "vue-class-component";
import template from "./segmentedControlTemplate.vue";

export interface VPair {
    value: string,
    displayValue: string,
}

@Options({
    name: 'SegmentedControlComponent',
    mixins: [template],
    props: {
        id: {type: String, default: Math.random()+''},
        innerData: Object,
        preSelected: String,
        theme: String,
        controlsPerRow: {type: Number, default: 2}
    }
})
export default class SegmentedControlComponent extends Vue {
    id: String;
    innerData: any;
    preSelected: String;
    theme: String;
    controlsPerRow: number;
    selectedControl: number = -1;
    chunks: Array<Array<number>> = [[]];
    // LIFECYCLE
    public created() {
        this.handleChunks();
    }
    public mounted() {
        if (this.preSelected && this.preSelected !== '' && this.selectedControl === -1) {
            this.selectedControl = this.list.findIndex((control) => control.value === this.preSelected);
        }
    }

    // COMPUTED
    public get list() {
        if (this.innerData.find(item => typeof item === 'object')) {
            return this.innerData;
        } else {
            const mappedData: Array<VPair> = this.innerData.map(obj => {
                const mapped: VPair = {
                    value: obj,
                    displayValue: obj,
                }
                return mapped;
            });
            return mappedData;
        }
    }
    // METHODS
    private handleChunks(): void {
        this.list.forEach((elem, inx) => {
            if (inx % this.controlsPerRow === 0 && inx !== 0) {
                const arr = [];
                arr.push(inx);
                this.chunks.push(arr);
            } else {
                this.chunks[this.chunks.length - 1].push(inx);
            }
        });
    }
    public selected(inx: number): void {
        if (this.selectedControl === inx) {
            return;
        }
        this.selectedControl = inx;
        this.$emit('SegmentedComponent', {
            id: this.id,
            value: this.value()
        });
    }
    private isValid(): boolean {
        return this.selectedControl > -1;
    }
    private value(): string {
        return this.isValid() ? this.list[this.selectedControl].value : undefined;
    }
}