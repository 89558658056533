import AgreementLineAdapter from '@/views/model/adapter/AgreementLineAdapter';
import { CustomerAgreementExcessSum, CustomerAgreementPart } from '@/generated-api/customer-agreements/api';
import InsurancesProductEnum from '@/views/model/InsurancesProductEnum';
import Utils from '@/views/providers/Utils'
import { isNullOrUndefined } from '@/views/common/util';
import AgreementStatus from '@/views/model/AgreementStatus';

export default class GeneralHighlight {

    protected _highlights: Array<any> = [];
    protected _productDetails: AgreementLineAdapter = null;

    protected utils: Utils = new Utils();

    protected description: string;
    protected title: string;
    protected price: number;
    protected agreementId: string;
    protected agreementStartDate: string;
    protected agreementEndDate: string;
    protected nextPaymentDate: string;
    protected changeable: boolean;
    protected agreementPart: CustomerAgreementPart;
    protected paymentType: string;
    protected excesses: Array<CustomerAgreementExcessSum>;
    protected sums: Array<CustomerAgreementExcessSum>;
    protected status: string;
    protected isBusiness: boolean;
    protected isVWSF: boolean;


    public agreementStatus = AgreementStatus;

    public constructor(productDetails: AgreementLineAdapter, isBusiness: boolean, isVWSF?: boolean) {
        this._productDetails = productDetails;

        this.title = productDetails.title;
        this.description = productDetails.description;
        this.price = productDetails.agreement.product_instance.price.amount_total;
        this.paymentType = productDetails.agreement.product_instance.price.payment_type;
        this.agreementId = productDetails.agreement.agreement_id;
        this.nextPaymentDate = productDetails.agreement.next_payment_date;
        this.agreementStartDate = productDetails.agreement.first_start_date;

        this.agreementEndDate = this.utils.formatDate(productDetails.agreement.end_date);
        this.agreementPart = productDetails.agreement.agreement_part;
        this.changeable = productDetails.agreement.changeable;
        this.excesses = productDetails.agreement.product_instance.price.excesses;
        this.sums = productDetails.agreement.product_instance.price.sums;
        this.status = productDetails.agreement.status;

        this.isBusiness = isBusiness;
        this.isVWSF = isVWSF;

        this.buildHighlights();
    }

    get highlights() {
        return this._highlights;
    }

    protected swap(theArray, index1, index2) {
        const temp = theArray[index2];
        theArray[index2] = theArray[index1];
        theArray[index1] = temp;
    }

    protected specializeName(name: string): string {
        const title = this.title.toLocaleLowerCase();
        switch (title) {
            case InsurancesProductEnum.BIL_FORSIKRING:
            case InsurancesProductEnum.MOTORKOERETOEJS_FORSIKRING:
                if (name.toLocaleLowerCase() === "ansvar") {
                    name = "Selvrisiko, ansvar"
                }
                else if (name.toLocaleLowerCase() === "kasko") {
                    name = "Selvrisiko, kasko"
                }
                else if (name.toLocaleLowerCase() === "delkasko") {
                    name = "Selvrisiko, delkasko"
                }
            case InsurancesProductEnum.INDBO_FORSIKRING:
                if (name.toLocaleLowerCase() === "sum") {
                    name = "Indbosum"
                }
            case InsurancesProductEnum.HUS_FORSIKRING:
                if (name.toLocaleLowerCase() === "sum") {
                    name = "Forsikringssum"
                }
            default:
                break;
        }
        return name;
    }
    /**
     *Opbygning af stamdata highlights
     *
     * ADVARSEL RÆKKEFØLGE MÅ IKKE ÆNDRES.
     *
     * */
    protected buildHighlights() {
        if (this.status !== this.agreementStatus.AFVENTER) {
            this.highlights.push({
                label: (this.paymentType === "one_time_amount") ? "Engangsbetaling" : "Pris pr. år",
                value: this.utils.formatPrice(this.price) + ' kr.'
            });
        }

        if (this.paymentType === "one_time_amount") {
            this.highlights.push({
                label: "Gyldig til",
                value: this.agreementEndDate

            });
        }
        if (!isNullOrUndefined(this.excesses)) {
            this.excesses.forEach((excess: any) => {
                this.highlights.push({
                    label: this.specializeName(excess.name),
                    value: this.utils.formatPrice(excess.amount) + " " + excess.units
                });
            })
        }
        if (!isNullOrUndefined(this.sums)) {
            this.sums.forEach((sum: any) => {
                this.highlights.push({
                    label: this.specializeName(sum.name),
                    value: this.utils.formatPrice(sum.amount) + " " + sum.units
                });
            })
        }

        const buildingAreal = this.utils.getBaseInfoValueByName('building_areal', this.agreementPart.fields);
        if (!isNullOrUndefined(buildingAreal) && buildingAreal !== "") {
            this.highlights.push({
                label: "Areal",
                value: buildingAreal + " m²"
            });
        }

        const insuranceForm = this.utils.getBaseInfoValueByName('insurance_form', this.agreementPart.fields);
        if (!isNullOrUndefined(insuranceForm) && insuranceForm !== "") {
            this.highlights.push({
                label: "Forsikret til",
                value: insuranceForm
            });
        }

        /*const insuranceSum = this.utils.getBaseInfoValueByName('insurance_sum', this.agreementPart.fields);
        if (!isNullOrUndefined(insuranceSum) && insuranceSum !== "") {
            this.highlights.push({
                label: "Forsikringssum",
                value: this.utils.formatPrice(Math.ceil(insuranceSum)) + ' kr.'
            });
        }*/

        const inUse = this.utils.getBaseInfoValueByName('in_use', this.agreementPart.fields);
        if (!isNullOrUndefined(inUse) && inUse !== "") {
            this.highlights.push({
                label: "Underanvendelse",
                value: inUse
            });
        }

        if (!this.isVWSF) {
            const koerselAarligt = this.utils.getBaseInfoValueByName('annual_distance', this.agreementPart.fields);
            if (!isNullOrUndefined(koerselAarligt) && koerselAarligt !== "" && !this.isBusiness) {
                this.highlights.push({
                    label: "Kørsel årligt",
                    value: koerselAarligt
                });
            }

            const koeretoejstype = this.utils.getBaseInfoValueByName('koeretoejstype_description', this.agreementPart.fields);
            if (!isNullOrUndefined(koeretoejstype) && koeretoejstype.length > 0 && this.isBusiness) {
                this.highlights.push({
                    label: "Køretøjstype",
                    value: koeretoejstype
                });
            }
        }

        if (!isNullOrUndefined(this.status)) {
            if (this.status === this.agreementStatus.TILBUD) {
                this.highlights.push({
                    label: "Gyldig fra",
                    value: "<span class=\"card__dot-warning\"></span> " + this.utils.formatDate(this.agreementStartDate)
                });
            } else if (this.status === this.agreementStatus.AFVENTER) {
                this.highlights.push({
                    label: "Afventer, ikke i kraft <span class=\"card__dot-danger\"></span>",
                    value: ""
                });

            } else if (this.status === this.agreementStatus.NY_AENDRING) {
                this.highlights.push({
                    label: "Igangværende ændring af forsikring <span class=\"card__dot-warning\"></span>",
                    value: ""
                });
            }
        }
    }
}
