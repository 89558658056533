<template>
	<div class="popover popover--icon">
		<button :class="['popover__toggle', 'popover__toggle--'+type, buttonClass]" @click.stop="toggle()" v-bind="trackingAttributes">
			<slot/>
		</button>
		<transition name="fade">
			<div class="popover__box popover__box--lg" v-if="isOpen" ref="popover">
				<div class="close" />
				<div class="popover__box__inner" v-html="innerData" />
				<slot class="popover" name="body"/>
				<div class="popover__box__arrow" ref="arrow" />
			</div>
		</transition>
	</div>
</template>

<style lang="scss" src="./_assets/_popover.scss"></style>
