<template>
    <div>
        <label v-if="type !== 'hidden'" :for="id" class="input__label">{{ label }}</label>

        <PopoverComponent
            v-if="type !== 'hidden' && labelPopover"
            type="icon"
            class="page-component__popover text-left"
            buttonClass="popover__icon popover__icon__questionmark"
            innerData="labelPopover"/>
                
        <input class="input__field"
               :type="type"
               :id="id"
               :name="name"
               :ref="name"
               :placeholder="placeholder"
               :inputmode="getInputMode()"
               :data-t-input="name"
               :required="required"
               :value="inputValue"
               :readonly="readonly"
                @input="change"
                @blur="change"
                @focus="change"
               />
    </div>
    <!-- @keyup="change($event)" -->
                   <!-- @blur="change($event)"
               @focus="change($event)" /> -->
</template>
