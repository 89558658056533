import { Vue, Options, mixins } from "vue-class-component";
import template from './Private.vue';
import PageHeroComponent from '@/views/overview/_partials/pageHero';
import MenuComponent from '@/views/overview/_partials/menu';
import CookieFooter from "@/views/overview/_partials/cookieFooter";
import PoweredBy from "@/views/overview/_partials/poweredBy";
import AppVersionAlert from "@/views/overview/_partials/appVersionAlert";
import { EnvironmentService as Env } from "@/views/sharedcomponents/lib/services/environmentService";
import AbCommon from "@/views/model/AbCommon";
import LogoutHandler from "@/views/providers/LogoutHandler";
import CookieHandler from "@/views/providers/CookieHandler";
import store from '@/store';
import { useRoute } from 'vue-router';
import { isNullOrUndefined } from "@/views/common/util";
import ServiceEnum from "@/views/model/ServiceEnum";

@Options({
    name: 'private-layout-component',
    mixins: [template],
    components: {
        PageHeroComponent,
        MenuComponent,
        CookieFooter,
        PoweredBy,
        AppVersionAlert
    },
    props:{
        disableAuthenticationCheck: Boolean,
        hideMenus: Boolean,
        showTitleInApp: Boolean
    }
})

export default class Private extends mixins(AbCommon) {
    disableAuthenticationCheck: boolean;
    hideMenus: boolean;
    showTitleInApp: boolean;

    // LIFECYCLE
    async beforeMount() {
        /*if (this.isLoggedInAsApp()) {
            this.getCustomer();
        }*/
        //flyttet fra main.vue
        const route = useRoute();
        if (!isNullOrUndefined(this.disableAuthenticationCheck) && !this.disableAuthenticationCheck) {
            await this.customerIsBusiness();
        }
    }

    created() {
        if (this.cookieBanner == "True") {
            CookieHandler.applyCookieScript();
        }
    }

    mounted() {
        if (this.isWeb) {
            if(this.disableAuthenticationCheck) {
                // no login, so no idle handler
            }
            else if (this.isThemed) {
                LogoutHandler.handleIdleLogout(ServiceEnum.LOGOUT_URL, 20);
            } else {
                LogoutHandler.handleIdleLogout(ServiceEnum.LOGOUT_SERVICE, 20);
            }
        }

        /*if (this.pageTitle) {
            document.title = this.pageTitle;
        } else {
            // Support for pages not using new content api - hopefully we can remove this soon.
            const fallback = this.isThemed ? "" : "Mit Alm. Brand";
            setTimeout(() => { document.title = this.pageTitle ? this.pageTitle : fallback }, 100);
        }*/

        const epiContent = store.state.epiContent;
        if (epiContent.modelLoaded) {
            if (epiContent.model.metaDescription) {
                const elem = document.querySelector('meta[name="description"]');
                if (elem) {
                    elem.setAttribute("content", epiContent.model.metaDescription);
                }


            }
            if (epiContent.model.metaKeywords) {
                const elem = document.querySelector('meta[name="keywords"]');
                if (elem) {
                    elem.setAttribute("content", epiContent.model.metaKeywords);
                }
            }
        }
    }

    // COMPUTED
    /*
    get pageTitle() {
        const epiContent = store.state.epiContent;

        if (epiContent.modelLoaded) {
            return epiContent.model.metaTitle ? epiContent.model.metaTitle : epiContent.model.mainTitle;
        } else {
            return;
        }
    }

    get themeBrand() {
        return this.$root['themeBrand'];
    }*/

    get cookieBanner() {
        return this.$root['cookieBanner'];
    }

    get isWeb(): boolean {
        return !this.isLoggedInAsApp();
    }

    get showAlert(): boolean {
        return this.isLoggedInAsApp() && !store.getters.getOldAppVersionAlerted;
    }

    // METHODS
    public getClasses(): string {
        if (Env.isApp() && !this.showTitleInApp) {
            return 'pt-4'
        }
        return '';
    }
}
