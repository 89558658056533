import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import template from './ProductListItem.vue';
import AgreementStatus from '@/views/model/AgreementStatus';

@Options({
    name: 'product-list-item',
    mixins: [template],
    props: {
        title: String,
        baseInfo: Array,
        price: Number,
        endDate: String,
        paymentType: String,
        description: String,
        startDate: String,
        status: String
    }
})
export default class ProductListItem extends mixins(InsurancesCommon) {
    title: string;
    baseInfo: Array<any>;
    price: Number;
    endDate: string;
    paymentType: string;
    description: string;
    startDate: string;
    status: string;

    productValueDesc: string = "";
    baseInfoDescription: string = "";
    public agreementStatus = AgreementStatus;
    public themed: boolean = false;

    // LIFECYCLE
    mounted() {
        this.productValueDesc = this.getProductValueDesc();
        this.baseInfoDescription = this.getBaseInfoDescription(this.title, this.baseInfo, this.description);
        this.themed = this.isThemed;
    }

    //COMPUTED
    get themeBrandSimple() {
        return this.$root['themeBrandSimple'];
    }

    // METHODS
    private getProductValueDesc() {
        if (this.paymentType === "one_time_amount") {
            return `Gyldig til ${new Date(this.endDate).getFullYear()}`;
        }
        return this.price ? `${this.price}` : '';
    }
}
