import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "basket basket__link",
  style: {"outline":"none"}
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "button-container text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cmsImg = _resolveComponent("cmsImg")!
  const _component_OverviewBasketMiniStepComponent = _resolveComponent("OverviewBasketMiniStepComponent")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!

  return (_ctx.productsInBasket > -1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PopoverComponent, {
          type: "icon",
          class: "page-component__popover text-left popup--basket",
          innerData: '<strong>Din indkøbskurv</strong>' + _ctx.discount,
          preventCloseInsideClick: true
        }, {
          default: _withCtx(() => [
            (_ctx.showBasket)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: _normalizeClass(['basket__link basket__link__badge', {'hiddenVis' : _ctx.productsInBasket < 1}]),
                    innerHTML: _ctx.productsInBasket
                  }, null, 10, _hoisted_3),
                  _createElementVNode("div", null, [
                    _createVNode(_component_cmsImg, {
                      name: "icons-svg/system-icons/icon-system-basket.svg",
                      isInlineSvg: true,
                      globalImages: true,
                      alt: "kurv"
                    })
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          body: _withCtx(() => [
            (_ctx.showContent)
              ? (_openBlock(), _createBlock(_component_OverviewBasketMiniStepComponent, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_router_link, {
                    style: {"border-color":"initial"},
                    class: "text-center btn-link-secondary mt-5",
                    to: _ctx.forsikringerLink,
                    textContent: _toDisplayString('Tilføj forsikringer'),
                    "data-gtm": "button-click",
                    "data-gtm-click-text": "Tilføj forsikringer"
                  }, null, 8, ["to", "textContent"])
                ]))
          ]),
          _: 1
        }, 8, ["innerData"])
      ]))
    : _createCommentVNode("", true)
}