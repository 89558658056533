import { AbLink } from "@/views/sharedcomponents/baseComponents/components";
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";

@Options({
    name: 'TileNav',
    components: {
        AbLink
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<any>,
        trackingUrl: String
    },
})

export default class TileNav extends Vue {
    model: any;
    trackingUrl: string;
}
