// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Customer Invoice API
 * # Customer Invoice API The Customer Invoice API allows client applications access to resources used in the Customer Journey 
 *
 * OpenAPI spec version: 0.0.1.01
 * Contact: P.Integration@almbrand.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "https://localhost:8080".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface CommonLink
 */
export interface CommonLink {
    /**
     * 
     * @type {string}
     * @memberof CommonLink
     */
    href?: string;
}

/**
 * 
 * @export
 * @interface CommonLinks
 */
export interface CommonLinks {
    /**
     * 
     * @type {CommonLink}
     * @memberof CommonLinks
     */
    self?: CommonLink;
    /**
     * 
     * @type {CommonLink}
     * @memberof CommonLinks
     */
    product?: CommonLink;
}

/**
 * 
 * @export
 * @interface CommonProblem
 */
export interface CommonProblem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof CommonProblem
     */
    type?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof CommonProblem
     */
    title?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof CommonProblem
     */
    status?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof CommonProblem
     */
    detail?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof CommonProblem
     */
    instance?: string;
}

/**
 * Holding the detailed information for a customer invoice
 * @export
 * @interface CustomerInvoiceDetails
 */
export interface CustomerInvoiceDetails {
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    invoice_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    invoice_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    payment_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    payment_method?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    agreement_id?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoiceDetails
     */
    amount_total?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    end_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    customer_number?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    customer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    customer_address?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceDetails
     */
    account_number?: string;
    /**
     * 
     * @type {Array<CustomerInvoiceLine>}
     * @memberof CustomerInvoiceDetails
     */
    invoice_lines?: Array<CustomerInvoiceLine>;
}

/**
 * Holding the detailed information for a customer invoice line fee
 * @export
 * @interface CustomerInvoiceFee
 */
export interface CustomerInvoiceFee {
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceFee
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoiceFee
     */
    amount?: number;
}

/**
 * Holding the detailed information for a customer invoice line
 * @export
 * @interface CustomerInvoiceLine
 */
export interface CustomerInvoiceLine {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerInvoiceLine
     */
    person_names?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceLine
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceLine
     */
    end_date?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoiceLine
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoiceLine
     */
    amount_total?: number;
    /**
     * 
     * @type {Array<CustomerInvoiceFee>}
     * @memberof CustomerInvoiceLine
     */
    fee?: Array<CustomerInvoiceFee>;
}

/**
 * Holding the information for a customer invoice
 * @export
 * @interface CustomerInvoiceMonthSummary
 */
export interface CustomerInvoiceMonthSummary {
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceMonthSummary
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceMonthSummary
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoiceMonthSummary
     */
    total_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceMonthSummary
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceMonthSummary
     */
    end_date?: string;
    /**
     * 
     * @type {Array<CustomerInvoicePaymentSummary>}
     * @memberof CustomerInvoiceMonthSummary
     */
    payments?: Array<CustomerInvoicePaymentSummary>;
}

/**
 * Holding the information for a customer invoice
 * @export
 * @interface CustomerInvoicePaymentSummary
 */
export interface CustomerInvoicePaymentSummary {
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoicePaymentSummary
     */
    invoice_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoicePaymentSummary
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoicePaymentSummary
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoicePaymentSummary
     */
    payment_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoicePaymentSummary
     */
    payment_method?: string;
    /**
     * 
     * @type {Array<CustomerInvoicePaymentSummaryLine>}
     * @memberof CustomerInvoicePaymentSummary
     */
    payment_lines?: Array<CustomerInvoicePaymentSummaryLine>;
}

/**
 * Holding the information for a customer invoice
 * @export
 * @interface CustomerInvoicePaymentSummaryLine
 */
export interface CustomerInvoicePaymentSummaryLine {
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoicePaymentSummaryLine
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoicePaymentSummaryLine
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoicePaymentSummaryLine
     */
    amount?: number;
}

/**
 * Holding the information for a customer invoices
 * @export
 * @interface CustomerInvoiceYearSummary
 */
export interface CustomerInvoiceYearSummary {
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoiceYearSummary
     */
    customer_number?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceYearSummary
     */
    customer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoiceYearSummary
     */
    customer_address?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoiceYearSummary
     */
    total_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoiceYearSummary
     */
    year?: number;
    /**
     * 
     * @type {Array<CustomerInvoiceMonthSummary>}
     * @memberof CustomerInvoiceYearSummary
     */
    invoices?: Array<CustomerInvoiceMonthSummary>;
}


/**
 * CustomerInvoiceApi - axios parameter creator
 * @export
 */
export const CustomerInvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetch and match existing customer invoice
         * @param {string} invoice_id Unique id for an invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerInvoiceById(invoice_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'invoice_id' is not null or undefined
            if (invoice_id === null || invoice_id === undefined) {
                throw new RequiredError('invoice_id','Required parameter invoice_id was null or undefined when calling fetchCustomerInvoiceById.');
            }
            const localVarPath = `/customer/invoices/{invoice_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoice_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fetch and match existing customer invoices
         * @param {number} [year] Year for the invoices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerInvoiceList(year?: number, options: any = {}): RequestArgs {
            const localVarPath = `/customer/invoices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fetch and match existing customer pdf invoice
         * @param {string} invoice_id Unique id for an invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerInvoicePdfById(invoice_id: string, options: any = {}): RequestArgs {
            // verify required parameter 'invoice_id' is not null or undefined
            if (invoice_id === null || invoice_id === undefined) {
                throw new RequiredError('invoice_id','Required parameter invoice_id was null or undefined when calling fetchCustomerInvoicePdfById.');
            }
            const localVarPath = `/customer/invoices/{invoice_id}/pdf`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoice_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerInvoiceApi - functional programming interface
 * @export
 */
export const CustomerInvoiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetch and match existing customer invoice
         * @param {string} invoice_id Unique id for an invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerInvoiceById(invoice_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerInvoiceDetails> {
            const localVarAxiosArgs = CustomerInvoiceApiAxiosParamCreator(configuration).fetchCustomerInvoiceById(invoice_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary fetch and match existing customer invoices
         * @param {number} [year] Year for the invoices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerInvoiceList(year?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerInvoiceYearSummary> {
            const localVarAxiosArgs = CustomerInvoiceApiAxiosParamCreator(configuration).fetchCustomerInvoiceList(year, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary fetch and match existing customer pdf invoice
         * @param {string} invoice_id Unique id for an invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerInvoicePdfById(invoice_id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = CustomerInvoiceApiAxiosParamCreator(configuration).fetchCustomerInvoicePdfById(invoice_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CustomerInvoiceApi - factory interface
 * @export
 */
export const CustomerInvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary fetch and match existing customer invoice
         * @param {string} invoice_id Unique id for an invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerInvoiceById(invoice_id: string, options?: any) {
            return CustomerInvoiceApiFp(configuration).fetchCustomerInvoiceById(invoice_id, options)(axios, basePath);
        },
        /**
         * 
         * @summary fetch and match existing customer invoices
         * @param {number} [year] Year for the invoices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerInvoiceList(year?: number, options?: any) {
            return CustomerInvoiceApiFp(configuration).fetchCustomerInvoiceList(year, options)(axios, basePath);
        },
        /**
         * 
         * @summary fetch and match existing customer pdf invoice
         * @param {string} invoice_id Unique id for an invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerInvoicePdfById(invoice_id: string, options?: any) {
            return CustomerInvoiceApiFp(configuration).fetchCustomerInvoicePdfById(invoice_id, options)(axios, basePath);
        },
    };
};

/**
 * CustomerInvoiceApi - object-oriented interface
 * @export
 * @class CustomerInvoiceApi
 * @extends {BaseAPI}
 */
export class CustomerInvoiceApi extends BaseAPI {
    /**
     * 
     * @summary fetch and match existing customer invoice
     * @param {string} invoice_id Unique id for an invoice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInvoiceApi
     */
    public fetchCustomerInvoiceById(invoice_id: string, options?: any) {
        return CustomerInvoiceApiFp(this.configuration).fetchCustomerInvoiceById(invoice_id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary fetch and match existing customer invoices
     * @param {number} [year] Year for the invoices.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInvoiceApi
     */
    public fetchCustomerInvoiceList(year?: number, options?: any) {
        return CustomerInvoiceApiFp(this.configuration).fetchCustomerInvoiceList(year, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary fetch and match existing customer pdf invoice
     * @param {string} invoice_id Unique id for an invoice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInvoiceApi
     */
    public fetchCustomerInvoicePdfById(invoice_id: string, options?: any) {
        return CustomerInvoiceApiFp(this.configuration).fetchCustomerInvoicePdfById(invoice_id, options)(this.axios, this.basePath);
    }

}

