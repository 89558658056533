import { CreateOffer, ProductFeatures, Offer } from '@/generated-api/accident-quote';
import BuyFamily from './BuyFamilyComponent';
import { BuyInsuranceHelper, Formatter, monthYearArray, ValuePair } from '../BuyInsuranceHelper';
import { cloneDeep } from 'lodash';
import { CustomerAgreementFeature} from '@/generated-api/customer-agreements';
import {PACKAGES, lilleFeatures, mellemFeatures, storFeatures, productAccidentId, PACKAGES_ACCIDENT, lilleAdultFeatures, mellemAdultFeatures, storAdultFeatures} from './FamilySettings';
import UrlHandler from '../UrlHandler';
import AbAxiosCalculators from '../AbAxiosCalculators';
import AbAxiosSelfservice from '@/views/common/ab_axios_selfservice';
import FeatureAdapter from '@/views/model/adapter/FeatureAdapter';
import store from '@/store';
// import { getFamilyInsuranceProductInfo } from '@/views/TestData/calculators/apiTestData';
// import FamilyTestCalculation from '@/views/TestData/calculators/FamilyCalculation.json';
import { defaultConfig } from '@/episerver/config';

export default class FamilyCalculator {
    private abAxiosSelfserviceOpen: AbAxiosSelfservice;
    private calculationAccidentUrl: string = '/insurance/accident';

    private buyFamily: BuyFamily; // component using calculator
    private model: any; // the data structure for state and properties
    private cms: any; // settings from EPI
    private calcConfigHome: any;
    private oldCalcAccidentConfig: any;
    // used for first three calculations, to see if there's known error codes
    private succesCalc = true;
    private tiaCoverages = new Map<string, Array<FeatureAdapter>>();
    private abAxiosCalculator: AbAxiosCalculators;
    private calculatorUrl: string;
    private errorPopup: any;
    private calculatorInfoHome: Array<any>;

    

    constructor(buyFamily: BuyFamily) {
        this.buyFamily = buyFamily;
        this.model = buyFamily.model;
        this.cms = buyFamily.cms;
        this.calculatorInfoHome = JSON.parse(this.buyFamily.calculatorInfoBlock.markup);

        this.errorPopup = {
            id: 'calcError',
            title: this.cms.defaultCalcErrorTitle,
            content: this.cms.defaultCalcErrorContent,
            btnSecondLabel: 'Ok',
            track: true,
            trackToken: 'error/tiacalculator',
            show: true,
        }

        const headers = [{ name: 'quote-api', value: 'true'}];
        this.abAxiosSelfserviceOpen = new AbAxiosSelfservice(store.getters.getConfigEpi.openServiceUrl + this.calculationAccidentUrl,
            'application/json',
            store.getters.getConfigEpi.csrfToken,
            headers,
        3);
        this.abAxiosSelfserviceOpen.useRecaptcha(true);

        this.calculatorUrl =  defaultConfig.baseUrl + store.getters.getConfigEpi.openServiceUrl + '/insurance/home/quotes';    
        this.abAxiosCalculator = new AbAxiosCalculators(this.calculatorUrl);
        this.setUpConfig();
    }

    public async setUpConfig() {
        if (!this.calcConfigHome) {
            if (this.model.campaign.valid) {
                this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
            }
            
            const calculatorInfo = this.calculatorInfoHome.find(cfg => cfg.calculatorConfigId === this.cms.calculatorConfigId);
            
            this.calcConfigHome = calculatorInfo.insuranceConfiguration;

            this.calcConfigHome.insuredValueOptions = calculatorInfo.insuredValueOptions;
            this.calcConfigHome.familyMemberCountOptions = []
            this.calcConfigHome.familyMemberCountOptions = calculatorInfo.familyMemberCountOptions;
            this.cms.mapCoverages(calculatorInfo.textMappingsOuter);

            this.model.calculation.abCalc = {
                excessIdDefault: this.calcConfigHome.excessIdDefault,
                packageIds: this.calcConfigHome.packageIds,
                packages: cloneDeep(this.calcConfigHome.packages),
                coveragesDetails: this.calcConfigHome.coverages,
                insuredValueOptions: this.calcConfigHome.insuredValueOptions,
                familyMemberCountOptions: this.calcConfigHome.familyMemberCountOptions
            };

            this.setupExcessList();
            this.model.calculation.abCalc.packages.forEach( (pack, inx) => {
                pack.coverages = [];
                pack.expandedCoverages = [];
                pack.includedCoverageIds.forEach(riskId => {
                    const coverage = this.calcConfigHome.coverages.find( x => riskId === x.riskId);
                    pack.coverages.push(coverage);
                });
                pack.includedCoveragesExpandedIds.forEach(riskId => {
                    const coverage = this.calcConfigHome.coverages.find( x => riskId === x.riskId);
                    pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
                });
                this.cms.mapCoverageGroups(pack.coverages);
            });


            this.model.calculation.abCalc.packs = new Map<number, Object>();
            this.model.calculation.abCalc.excesses.forEach(excess => {
                const calcs = [];
                this.model.calculation.abCalc.packs.set(excess.id, calcs);
                this.model.calculation.abCalc.packages.forEach( (pack) => {
                    calcs.push(this.buyFamily.helper.reducePack(cloneDeep(pack)));
                });
            });
            this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'

            await this.setupAccident();

            this.mapCoveragesFromCalc();
        }
        this.updateCalcKeys();
    }

    private mapCoveragesFromCalc() {
        if(!this.model.calculationAccident.allCoverages || this.model.calculationAccident.allCoverages.size === 0) {
            this.mapCoveragesAccidentFromCalc();
        }

        if (!this.model.calculation.allCoverages || this.model.calculation.allCoverages.size === 0) {
            this.model.calculation.allCoverages = new Map<number, Map<number, Array<FeatureAdapter>>>();
            this.model.calculation.abCalc.packs.forEach( (calculations, ownRiscId) => {
                const coveragesForPackage = new Map<number, Array<FeatureAdapter>>();
                calculations.forEach((calc, packageInx) => {
                    let featureAdapters: Array<FeatureAdapter> = [];
                    calc.coverages.forEach((coverage) => {
                        let customerAgreementFeature: CustomerAgreementFeature = {
                            description: this.cms.getCoverageDescription(coverage.name, coverage.description),
                            feature_id: coverage.riskId + '',
                            name: this.cms.getCoverageName(coverage.name),
                            sub_features: [],
                            // optional_properties: undefined,
                            // excess: undefined,
                            // sum: undefined,
                        };
                        let featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
                        featureAdapters.push(featureAdapter);
                    });
                    // add accident covs
                    const accidentCovs = this.model.calculationAccident.allCoverages.get(this.model.calculationAccident.abCalc.excessIdDefault).get(packageInx);
                    calc.coverages = accidentCovs.concat(calc.coverages); // add to product coverages
                    featureAdapters = accidentCovs.concat(featureAdapters);
                    coveragesForPackage.set(packageInx, featureAdapters);
                    const ulykkeExpanded = this.model.calculationAccident.abCalc.packs.get(this.model.calculationAccident.abCalc.excessIdDefault)[packageInx].expandedCoverages;
                    calc.expandedCoverages = [...ulykkeExpanded, ...calc.expandedCoverages];
                    // coveragesForPackage.set(packageInx, accidentCovs.concat(featureAdapters));
                });
                this.model.calculation.allCoverages.set(ownRiscId, coveragesForPackage);
            });
        }
    }

    public setupExcessList() {
        this.model.calculation.abCalc.excesses = this.calcConfigHome.excessList;
        this.model.ownRisks = [];
        this.model.calculation.abCalc.excesses.forEach((excess) => {
            if (this.model.personInfo.customerAge >= excess.minCustomerAge) {
                const valuePair: ValuePair = {
                    value: excess.id,
                    displayValue: Formatter.format(excess.amount) + ' kr.',
                };
                this.model.ownRisks.push(valuePair);
            }
        });
    }

    private checkFirstPackagePrices(excessId: number) {
        let ok = true;
        const packages = this.model.calculation.abCalc.packs.get(excessId);
        packages.forEach(calc => {
            if (!calc.totalPrice) {
                ok = false;
            }
        });
        return ok;
    }

    private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
        this.succesCalc = true;

        const rebate = this.buyFamily.helper.getDiscount();
        this.model.calculation.discount = rebate.discount;
        this.model.calculation.discountDisplay = rebate.discountDisplay;

        if (this.buyFamily.helper.hasAddressSupplement()) {
            this.model.calculation.addressSupplement = this.cms.addressSupplement;
        } else {
            this.model.calculation.addressSupplement = 1;
        }

        let promises = [];
        for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
            const packageId = this.model.calculation.abCalc.packageIds[index];
            promises.push(this.onePackageCalcEpi(monthly, excessId, packageId));
        }
        await Promise.all(promises);
        return Promise.resolve(this.succesCalc);
    }

    public async getCalculations(excessId?: number) : Promise<boolean> {
        this.model.showSpinner = true;
        this.model.calculating = true;
        // handle "eternal" spinning
        setTimeout(() => {
            if(this.model.showSpinner) {
                this.model.showSpinner = false;
                this.model.calculating = false;
            }
        }, this.cms.calculationSpinnerTimeout);

        // reset prices
        const pack = this.model.calculation.abCalc.packs.get(excessId);
        pack.totalPriceDisplay = undefined;
        this.updateCalcKeys();
        const indbo = await this.getEpiCalculations(excessId);
        if(!indbo) {
            this.model.calculation.isCalculated = false;
            this.model.showSpinner = false;
            this.model.calculating = false;
            // TODO KLK error msg
            return Promise.reject();
        }
        pack.totalPriceDisplay = undefined;
        this.updateCalcKeys();
        const accident_ = await this.getAccidentCalculations();
        if(!accident_) {
            this.model.calculation.isCalculated = false;
            this.model.showSpinner = false;
            this.model.calculating = false;
            // TODO KLK error msg
            return Promise.reject();
        }

        const accident = this.model.calculationAccident.abCalc.packs.get(this.model.calculationAccident.abCalc.excessIdDefault);
        this.model.calculation.abCalc.packs.forEach(calcs => {
            calcs.forEach(calc => {
                if (calc.totalPriceDisplay){
                    let accidentCalc = {};
                    if (calc.name === PACKAGES.BASIC) {
                        accidentCalc = accident.filter(calc => calc.name === PACKAGES_ACCIDENT.LILLE)[0];
                    } else if(calc.name === PACKAGES.MELLEM) {
                        accidentCalc = accident.filter(calc => calc.name === PACKAGES_ACCIDENT.MELLEM)[0];
                    } else if(calc.name === PACKAGES.STOR) {
                        accidentCalc = accident.filter(calc => calc.name === PACKAGES_ACCIDENT.STOR)[0];
                    }
                    this.addAccidentPricesToHomePrices(calc,accidentCalc);
                }
            });
        });
        this.model.calculation.isCalculated = true;
        this.model.showSpinner = false;
        this.model.calculating = false;
        this.updateCalcKeys();
        this.buyFamily.abGtm.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/prices_shown`,
        });
        return Promise.resolve(true);
    }

    private addAccidentPricesToHomePrices(homeCalc, childCalc) {
    /*
        basePrice: 312.1666666666667
        coverages: (10) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
        id: 3004
        name: "Grunddækning"
        rebatePrice: 266.3333333333333
        rebateProcent: 15
        statutoryFee: 10
        totalPrice: 312
        totalPriceDisplay: "312 kr."
        yearlyBasePrice: 3631
        yearlyPriceTotal: 3631
        yearlyPriceTotalDisplay: "3.631 kr."
        yearlyRebatePrice: 3098

    */

        homeCalc.basePrice += childCalc.summedBasePrice;
        homeCalc.totalPrice += childCalc.summedTotalPrice
        homeCalc.yearlyBasePrice += childCalc.summedYearlyBasePrice
        homeCalc.yearlyPriceTotal += childCalc.summedYearlyPriceTotal;

        homeCalc.totalPriceDisplay = Formatter.format(homeCalc.totalPrice) + ' kr.';
        homeCalc.yearlyPriceTotalDisplay = Formatter.format(homeCalc.yearlyPriceTotal) + ' kr.';
    }

    private async getEpiCalculations(excessId? : number): Promise<boolean> {
        // reset calculation
        await this.setUpConfig();

        let success = true;
        const _excessId = excessId ? excessId : this.model.calculation.abCalc.excessIdDefault;

        success = await this.calculatePackagesEpi(true, _excessId);
        // check if has got prices
        if(!success) {
            success = this.checkFirstPackagePrices(_excessId);
        }
        if (success) {
            await this.calculatePackagesEpi(false, _excessId);
        }
        return success;
    }

    private async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {

        const response = await this.abAxiosCalculator.getRetryDefaultUrl(this.createParamsEpi(excessId, packageId, monthly ? 1 : 0));

        if (response.status !== 200) {

            this.succesCalc = false;

            this.model.modal.content = this.cms.defaultCalcErrorContent;

            this.model.modal.track = false;
            this.model.modal.btnSecondLabel = 'Ok';
            this.model.modal.id = 'calcWarning';
            this.model.modal.show = true;
            if (response.status === 250) {
                    this.model.modal.title = this.cms.defaultCalcErrorTitle;
                    this.model.modal.content = this.cms.defaultCalcErrorContent;
            }
            else {
                this.model.modal.title = 'Der er desværre sket en fejl';
                this.model.modal.id = 'calcError';
            }
            this.buyFamily.toggleModal();
            return Promise.resolve(false);
        }

        const pack = this.model.calculation.abCalc.packs.get(excessId);
        BuyInsuranceHelper.mapPriceResult(response);
        this.buyFamily.helper.setPrices(packageId, response, monthly, pack);
        return Promise.resolve(true);
    }

    private createParamsEpi( excessId: number, packageId: number, monthlyPrices: number ) {
        const customerAge  = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
        const streetBuildingLetter = this.model.personInfo.houseNr.trim().replace(/[0-9]/g, ''); // remove numbers
        const streetNumber = this.model.personInfo.houseNr.trim().replace(/\D/g,''); // remove characters

        let suite: number;
        let floorSideCode: string;
        if (this.model.personInfo.doorNr) {
            suite = this.model.personInfo.doorNr.trim().replace(/[0-9]/g, '') === '' ? parseInt(this.model.personInfo.doorNr.trim()) : undefined;
            floorSideCode = this.model.personInfo.doorNr.trim().replace(/\D/g,'', '') === '' ? this.model.personInfo.doorNr.trim() : undefined;
        }
        
        let insuredValue = this.model.choosePackage.insuredValueAmount;
        if (!insuredValue) {
            insuredValue = this.model.calculation.abCalc.insuredValueOptions[0].value;
        }

        let groupAgreement = '-1';
        if (this.model.campaign.valid) {
            groupAgreement = this.cms.campaigns.get(this.model.campaign.ID).groupId;
        }

        // create params
        const params = {
            district_name: 'xxx', // ?
            customer_age: customerAge,
            insured_value: insuredValue,
            street_name: this.model.personInfo.streetName,
            street_building: streetNumber,
            street_building_letter: streetBuildingLetter !== '' ? streetBuildingLetter : undefined,
            floor: this.model.personInfo.floorNr,

            suite, // hvis tal
            floor_side_code: floorSideCode, // hvis ikke tal
            
            post_code: `${this.model.personInfo.zipCode}`,
            has_thatched_roof: this.model.personInfo.thatchedRoof === 'ja' ? 'y' : 'n',
            has_burglar_alarm: 'n',
            family_member_count: this.model.personInfo.familyMemberCountId,
            plus_customer: this.cms.plusCustomerCalculation ? 'PK1' : 'PK0',
            excess_id: `${excessId}`,
            payments_per_year: monthlyPrices === 1 ? 12 : 1,
            selected: this.buyFamily.helper.getRisksForPackage(packageId),
            group_agreement: groupAgreement !== "-1" ? groupAgreement : undefined,        
            ext_bike_sum: this.cms.extendedBikeSum,
        }

        return params;
    }

    public async checkForValidAddress(): Promise<boolean> {
        const packageId = this.model.calculation.abCalc.packageIds[0];
        const excessId = this.model.calculation.abCalc.excessIdDefault;
        const ok = await this.onePackageCalcEpi(true, excessId, packageId);
        return Promise.resolve(ok);
    }

    public updateCalcKeys() {
        this.model.calculation.updatePrices = this.model.calculation.updatePrices.map( (elem) => {
            return elem += '1';
        });
    }


// Accident
    private async setupAccident(){
        // hardcoded oldConfig
        this.oldCalcAccidentConfig = this.cms.oldCalcAccidentConfig.insuranceConfiguration;
        this.cms.mapCoverages(this.cms.oldCalcAccidentConfig.textMappingsOuter);

        this.model.calculationAccident.abCalc = {
            excessIdDefault: this.oldCalcAccidentConfig.excessIdDefault,
            packageIds: this.oldCalcAccidentConfig.packageIds,
            packages: cloneDeep(this.oldCalcAccidentConfig.packages),
        };

        this.setupAccidentExcessList();
        await this.setupTiaAccidentCoverages();

        this.model.calculationAccident.abCalc.packages.forEach(element => {
            element.coverages = this.tiaCoverages.get(element.name);
        });

        this.model.calculationAccident.abCalc.packs = new Map<number, Object>();
        this.model.calculationAccident.abCalc.excesses.forEach(excess => {
            if (excess.id === this.model.calculationAccident.abCalc.excessIdDefault) {
                const calcs = [];
                this.model.calculationAccident.abCalc.packs.set(excess.id, calcs);
                this.model.calculationAccident.abCalc.packages.forEach( (pack) => {
                    calcs.push(this.buyFamily.helper.reducePack(cloneDeep(pack)));
                });
            }
        });
    }

    public setupAccidentExcessList() {
        this.model.calculationAccident.abCalc.excesses = this.calcConfigHome.excessList;
    }

    private async setupTiaAccidentCoverages(): Promise<any> {
        return new Promise( (resolve) => {
            let featureAdapters: Array<FeatureAdapter> = [];
            lilleFeatures.forEach( productFeature => {
                featureAdapters.push(...this.getMappedAccidentTiaCoverage(productFeature));
            });
            this.tiaCoverages.set(PACKAGES_ACCIDENT.LILLE, featureAdapters);

            featureAdapters = [];
            mellemFeatures.forEach( productFeature => {
                featureAdapters.push(...this.getMappedAccidentTiaCoverage(productFeature));
            });
            this.tiaCoverages.set(PACKAGES_ACCIDENT.MELLEM, featureAdapters);

            featureAdapters = [];
            storFeatures.forEach( productFeature => {
                featureAdapters.push(...this.getMappedAccidentTiaCoverage(productFeature));
            });
            this.tiaCoverages.set(PACKAGES_ACCIDENT.STOR, featureAdapters);

            featureAdapters = [];
            return resolve(true);
        });
    }

    private mapCoveragesAccidentFromCalc() {
        this.model.calculationAccident.allCoverages = new Map<number, Map<number, Array<FeatureAdapter>>>();
        this.model.calculationAccident.abCalc.packs.forEach( (calculationAccident, ownRiscId) => {
            const coveragesForPackage = new Map<number, Array<FeatureAdapter>>();
            calculationAccident.forEach((calc, packageInx) => {
                const covs = this.tiaCoverages.get(calc.name);
                calc.expandedCoverages = [];
                covs.forEach(cov => {
                    // console.log('TIA inx', packageInx, cov.feature.name);
                    calc.expandedCoverages.push(cov.feature.name);
                });
                if(packageInx === 1) {
                    calc.expandedCoverages = [...calculationAccident[0].expandedCoverages, ...calc.expandedCoverages];
                }
                if(packageInx === 2) {
                    calc.expandedCoverages = [...calculationAccident[1].expandedCoverages, ...calc.expandedCoverages];
                }
                this.cms.mapCoverageGroups(covs, true);
                coveragesForPackage.set(packageInx, covs);
                calc.tiaCoverages = covs;
            });
            this.model.calculationAccident.allCoverages.set(ownRiscId, coveragesForPackage);
        });
    }

    private getMappedAccidentTiaCoverage(productFeature): Array<FeatureAdapter> {
        const featureAdapters: Array<FeatureAdapter> = [];
        let featureFound = false;
        this.cms.productInfoAccident.products.features.forEach( feature => {
            if (feature.feature_id === productFeature.name ) {
                let customerAgreementFeature: CustomerAgreementFeature = {
                    description: this.cms.getCoverageDescription(feature.title, feature.description),
                    feature_id: feature.feature_id,
                    name: this.cms.getCoverageName(feature.title),
                    sub_features: [],
                }
                let featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
                featureAdapters.push(featureAdapter);
                featureFound = true;
                // insert sub_feature_ids around here
            }
        });
        // some features are listed as subfeatures in .../category/products. For now, they are elavated to features.
        if(!featureFound) {
            this.cms.productInfoAccident.products.sub_features.forEach( subfeature => {
                if (subfeature.sub_feature_id === productFeature.name ) {
                    let customerAgreementFeature: CustomerAgreementFeature = {
                        description: this.cms.getCoverageDescription(subfeature.title, subfeature.description),
                        feature_id: subfeature.sub_feature_id,
                        name: this.cms.getCoverageName(subfeature.title),
                        //sub_features: [{name: 'test', description: 'test beskrivelse'}],
                        sub_features: [],
                    }
                    let featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
                    featureAdapters.push(featureAdapter);
                    featureFound = true;
                }
            });
        }
        if (!featureFound) {
            console.error('Could not find feature with name', productFeature.name);
        }
        return featureAdapters;
    }


    private async getAccidentCalculations(excessId?: number) : Promise<boolean> {
        // reset calculation and setup coverages by age
        await this.setupAccident();

        try {
            const promises = [];
            this.model.calculationAccident.abCalc.packs.get(this.model.calculationAccident.abCalc.excessIdDefault).forEach(calc => {
                calc.summedBasePrice = 0;
                calc.summedStatutoryFee = 0;// not reurned by api
                calc.summedTotalPrice = 0;

                calc.summedYearlyBasePrice = 0;
                calc.summedYearlyStatutoryFee = 0; // not reurned by api
                calc.summedYearlyPriceTotal = 0;
            });
            for (let i= 0; i <= this.model.familyInfo.personCountId; i++) {
                const age = this.model.familyInfo['age' + i];
                const workId = this.model.familyInfo['workId' + i];
                promises.push(this.calculateAccidentQuickQuote(PACKAGES_ACCIDENT.LILLE, age, workId));
            }
            await Promise.all(promises);

            const promises1 = [];
            for (let i= 0; i <= this.model.familyInfo.personCountId; i++) {
                const age = this.model.familyInfo['age' + i];
                const workId = this.model.familyInfo['workId' + i];
                promises1.push(this.calculateAccidentQuickQuote(PACKAGES_ACCIDENT.MELLEM, age, workId));
            }
            await Promise.all(promises1);

            const promises2 = [];
            for (let i= 0; i <= this.model.familyInfo.personCountId; i++) {
                const age = this.model.familyInfo['age' + i];
                const workId = this.model.familyInfo['workId' + i];
                promises2.push(this.calculateAccidentQuickQuote(PACKAGES_ACCIDENT.STOR, age, workId));
            }
            await Promise.all(promises2);
            // this.updateCalcKeys();

            return Promise.resolve(true);
        } catch(err) {
            console.error('Accident offer', err);
            this.buyFamily.abGtm.triggerCustomGtmEvent({
                'event': 'track-vp',
                'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/error/tiaonlineoffer`,
            });

            console.error(err);
            this.buyFamily.toggleModal(this.errorPopup);
            this.model.showSpinner = false;
            this.model.calculating = false;
            return Promise.resolve(false);
        }
    }

    private async calculateAccidentQuickQuote(productType: string, age: string, workId: string): Promise<any> {
        let customerAge = parseInt(age);
        if (customerAge < this.cms.minChildAge) {
            customerAge = this.cms.minChildAge;
        }

        let features: Array<ProductFeatures> = this.getFeatures(productType, customerAge);
        if (!features) {
            return Promise.reject();
        }
        const createOffer: CreateOffer =
        {
            product: {
                product_name: productAccidentId,
                features,
                parameters: {
                    age: customerAge,
                    postalCode: this.model.personInfo.zipCode,
                },
            }
        };
        workId ? createOffer.product.parameters.employment = workId: undefined;

        try {
            // const res = await this.abAxiosSelfserviceOpen.offerServiceLayer.createOffer(createOffer);
            // const offer = res.data;
            // if (!offer || !offer.price || !offer.price.annually) {
            //     throw new Error();
            // }
            let res;
            let offer;
            try {
                res = await this.abAxiosSelfserviceOpen.offerServiceLayer.createOffer(createOffer);
                offer = res.data;
                if (!offer || !offer.price || !offer.price.annually) {
                    throw new Error();
                }
            } catch (error) {
                // retry
                res = await this.abAxiosSelfserviceOpen.offerServiceLayer.createOffer(createOffer);
                offer = res.data;
                if (!offer || !offer.price || !offer.price.annually) {
                    throw new Error();
                }
            }
            this.model.calculationAccident.abCalc.packs.get(this.model.calculationAccident.abCalc.excessIdDefault).forEach(calc => {
                if (calc.name === productType) {
                    let totalPrice = offer.price.monthly;
                    calc.basePrice = totalPrice;
                    calc.statutoryFee = 0; // not reurned by api

                    // pluscustomer discount
                    totalPrice *= this.buyFamily.model.calculation.discount;

                    // campaign discount
                    if (this.model.campaign.valid)  {
                        totalPrice -= (totalPrice * this.model.campaign.discount);
                    }

                    calc.totalPrice = Math.round(totalPrice);

                    totalPrice = offer.price.annually;
                    calc.yearlyBasePrice = totalPrice;
                    calc.yearlyStatutoryFee = 0; // not reurned by api

                    // pluscustomer discount
                    totalPrice *= this.buyFamily.model.calculation.discount;

                    // campaign discount
                    if (this.model.campaign.valid)  {
                        totalPrice -= (totalPrice * this.model.campaign.discount);
                    }

                    calc.yearlyPriceTotal = Math.round(totalPrice);

                    calc.summedBasePrice += calc.basePrice;
                    calc.summedStatutoryFee = 0;// not reurned by api
                    calc.summedTotalPrice += calc.totalPrice;

                    calc.summedYearlyBasePrice += calc.yearlyBasePrice;
                    calc.summedYearlyPriceTotal += calc.yearlyPriceTotal;
                    calc.summedYearlyStatutoryFee = 0; // not reurned by api

                    calc.totalPriceDisplay = Formatter.format(calc.summedTotalPrice) + ' kr.';
                    calc.yearlyPriceTotalDisplay = Formatter.format(calc.summedYearlyPriceTotal) + ' kr.';
                    }
            });

            return Promise.resolve();
        } catch(err) {
            console.error(err);
            if (!this.buyFamily.isDevelop()) {
                this.buyFamily.toggleModal(this.errorPopup);
                return Promise.reject(err);
            } else if (this.cms.mockData) {
                // MOCK
                let add = 2000;
                this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach(calc => {
                    add += 100;
                    if (calc.name === productType) {
                        let totalPrice = add;
                        calc.basePrice = totalPrice;
                        calc.statutoryFee = 0; // not reurned by api
                        if (this.model.campaign.valid)  {
                            totalPrice -= (totalPrice * this.model.campaign.discount);
                        }

                        calc.totalPrice = Math.round(totalPrice * this.model.calculation.discount);
                        calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';

                        totalPrice = 12 * add * .97;
                        calc.yearlyBasePrice = totalPrice;
                        calc.yearlyStatutoryFee = 0; // not reurned by api

                        if (this.model.campaign.valid)  {
                            totalPrice -= (totalPrice * this.model.campaign.discount);
                        }
                        calc.yearlyPriceTotal = Math.round(totalPrice * this.model.calculation.discount);
                        calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
                        this.updateCalcKeys();
                    }
                });
            }
        };

    }

    private getFeatures(productType: string, customerAge: number): Array<ProductFeatures> {
        // const customerAge = this.model.personInfo.customerAge;
        if (customerAge >= 18) {
            switch(productType) {
                case PACKAGES_ACCIDENT.LILLE :  return lilleAdultFeatures;
                case PACKAGES_ACCIDENT.MELLEM : return mellemAdultFeatures;
                case PACKAGES_ACCIDENT.STOR : return storAdultFeatures;
                default: console.warn('no package for calculation selected', productType);
                    return undefined;
            }

        } else {
            switch(productType) {
                case PACKAGES_ACCIDENT.LILLE :  return this.cms.getFeaturesByAge(lilleFeatures, customerAge);
                case PACKAGES_ACCIDENT.MELLEM : return  this.cms.getFeaturesByAge([...lilleFeatures, ...mellemFeatures]/*mellemFeatures*/, customerAge);
                case PACKAGES_ACCIDENT.STOR : return this.cms.getFeaturesByAge([...lilleFeatures, ...mellemFeatures, ...storFeatures]/*storFeatures*/, customerAge);
                default: console.warn('no package for calculation selected', productType);
                    return undefined;
            }

        }
    }
}
