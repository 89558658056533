<template>
    <div class="basic-list__item basic-list__item--cols">
        <div :class="[{ 'd-flex' : themed }, 'basic-list__item__col--1']">
            <img v-if="themeBrandSimple" :src="themeBrandSimple" class="basic-list__item__image" />
            <div class="basic-list__item__inner-wrapper">
                <h3 class="basic-list__item__title">{{title}}</h3>
                <p class="basic-list__item__subtitle">{{baseInfoDescription}}</p>

                <div v-if="status !== agreementStatus.I_KRAFT" class="font-size-xs font-family-work-sans-medium">
                    <p v-if="status === agreementStatus.TILBUD">
                        Gyldig fra {{formatDate(startDate)}} <span class="card__dot-warning"></span>
                    </p>
                    <p v-else-if="status === agreementStatus.AFVENTER">
                        Afventer, ikke i kraft <span class="card__dot-danger"></span>
                    </p>
                    <p v-else-if="status === agreementStatus.NY_AENDRING">
                        Igangværende ændring af forsikring <span class="card__dot-warning"></span>
                    </p>
                </div>
            </div>
        </div>
        <div v-if="productValueDesc !== '' &&
                paymentType !== 'one_time_amount' &&
                status !== agreementStatus.AFVENTER"
                class="basic-list__item__col--2 basic-list__item__value">
            {{productValueDesc}}
            <div class="d-flex ml-1">
                kr <span class="text-grey-3 ml-1">/år</span>
            </div>
        </div>
    </div>
</template>
