import { UrlUtil } from '@/episerver/api/urlUtil';
import Axios, { AxiosRequestConfig } from 'axios';
import AxiosRetry from 'axios-retry';

export default class AbAxiosCalculators {
    private abaxiosRetry = AxiosRetry;
    private axiosRetry3 = Axios.create({
        timeout: 15000,
        headers: {
            // 'Accept': 'application/json',
            'Accept': '*/*',
            'Accept-Language': 'da'
        }
    });
    private axiosOnce = Axios.create({
        timeout: 15000,
        headers: {
            //'Accept': 'application/json',
            'Accept': '*/*',
            'Accept-Language': 'da'
        }
    });
    private defaultUrl: string;
    private withCredentials: boolean = true;
    private recaptchaEnabled: boolean = true;

    private retryConfig = { 
        retries: 3,
        retryDelay: (retryCount: number) => {
        // console.log(`retry attempt: ${retryCount}`);
        return retryCount * 100; // time interval between retries
        },
        retryCondition: (error) => {
        // console.log('retry error', error);
            if (!error?.response?.status) {
                return true;
            }
            return error?.response?.status > 240;
        },
        shouldResetTimeout : true,
    };

    constructor(url?: string) {
        // this.defaultUrl = process.env?.VUE_APP_BASEURL + baseUrl;
        this.defaultUrl = url;

        this.abaxiosRetry(this.axiosRetry3, this.retryConfig);


        this.axiosRetry3.interceptors.request.use(async (config) => {
            return await this.addHeaders(config);
          });      

          this.axiosOnce.interceptors.request.use(async (config) => {
            return await this.addHeaders(config);
          });
    }

    public setWithCredentials(withCredentials: boolean) {
        this.withCredentials = withCredentials;
    }

    public useRecaptcha(useRecaptcha: boolean) {
        this.recaptchaEnabled = useRecaptcha;
    }

    public async get(url?: string): Promise<any> {
        const url_ = url ? url : this.defaultUrl;
        try {
            return await this.axiosOnce.get(url_, {
                withCredentials: this.withCredentials
            });    
        } catch (error) {
            console.error(error);
            if (error?.response) {
                return error.response;
             }
            
        }
    }
    public getRetryDefaultUrl(params?: any): Promise<any> {
        return this.getRetry(undefined, params)
    }

    public async getRetry(url: string, params?: any): Promise<any> {
        let response;
        try {
            const url_ = url ? url : this.defaultUrl
            if (params) {
                response = await this.axiosRetry3.get(url_, {
                    params,
                    timeout: 15000,
                    withCredentials: this.withCredentials,
                });
            } else {
                response = await this.axiosRetry3.get(url_);
            }
            return response;
        } catch (error) {
            console.error(error);
            
            if (error?.response) {
                return error.response;
             }
        }

    }

    public async post(url: string, params?: any): Promise<any> {
        let response;
        try {
            const url_ = url ? url : this.defaultUrl
            if (params) {
                response = await this.axiosOnce.post(url_, {
                    params,
                    timeout: 15000,
                    withCredentials: this.withCredentials,
                });
            } else {
                response = await this.axiosOnce.post(url_, {
                    timeout: 15000,
                    withCredentials: this.withCredentials
                });
            }
            return response;
        } catch (error) {
            console.error(error);
            
            if (error?.response) {
                return error.response;
             }
        }

    }

    public async delete(url: string, params?: any): Promise<any> {
        let response;
        try {
            const url_ = url ? url : this.defaultUrl
            if (params) {
                response = await this.axiosOnce.delete(url_, {
                    params,
                    timeout: 15000,
                    withCredentials: this.withCredentials,
                });
            } else {
                response = await this.axiosOnce.delete(url_, {
                    timeout: 15000,
                    withCredentials: this.withCredentials
                });
            }
            return response;
        } catch (error) {
            console.error(error);
            
            if (error?.response) {
                return error.response;
             }
        }

    }

    public async put(url: string, params?: any): Promise<any> {
        let response;
        try {
            const url_ = url ? url : this.defaultUrl
            if (params) {
                response = await this.axiosOnce.put(url_, {
                    params,
                    timeout: 15000,
                    withCredentials: this.withCredentials,
                });
            } else {
                response = await this.axiosOnce.put(url_, {
                    timeout: 15000,
                    withCredentials: this.withCredentials
                });
            }
            return response;
        } catch (error) {
            console.error(error);
            
            if (error?.response) {
                return error.response;
             }
        }

    }

    private async addHeaders(config): Promise<AxiosRequestConfig> {
        let recaptchaToken: string;
        if (this.recaptchaEnabled) {
            recaptchaToken = await UrlUtil.getReCaptchaToken();
        }
        config.headers = {
            'x-recaptcha-token': recaptchaToken,
            'Accept': 'application/json',
            'Accept-Language': 'da',
        }
        return config;
    }
}
