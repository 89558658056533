/**
 *  @classdesc Holds functions for communicating with the app
 */
import Utils from '@/views/providers/Utils'

export class App {
    private static utils = new Utils();

    /**
     * @returns true if open in App, else false
     */
    static isEnabled(): boolean {
        if (window.location.href.indexOf("client=app") > -1) {
            return true;
        }

        if((<any>window).nsWebViewBridge !== undefined) {
            return true;
        }

        return false;
    }

    /**
     * Sends a message to the app via gotMessage
     * @param message An object containing the message: { 'MAIN_TITLE': 'title'}
     *  
     */
    static sendMessage(message: {}) {
        if(App.isEnabled()) {
            (<any>window).nsWebViewBridge?.emit('gotMessage', message);
            if(!(<any>window).nsWebViewBridge) {
                this.utils.log('App_message', message);
            }
        }
    }
}
