import store from '@/store';
import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState } from "vuex";
import { Claim } from '@/generated-api/customer-claims';
import ClaimAdapter from '@/views/model/adapter/ClaimAdapter';
import InsurancesProductEnum from '@/views/model/InsurancesProductEnum';
import { ClaimsListBlock } from "@/definitions/episerver/content-types";
import InsurancesCommon from "@/views/model/InsurancesCommon";
import ClaimListItem from './_partials/claimListItem/ClaimListItem';
import { SkeletonLoaderComponent, AbLink } from '@/views/sharedcomponents/baseComponents/components';
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking"
@Options({
    name: 'ClaimsListBlockComponent',
    components: {
        ClaimListItem,
        SkeletonLoaderComponent,
        AbLink,
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<any>
    }

})

export default class ClaimsListComponent extends mixins(InsurancesCommon) {
    model: any;

    claimArray: Array<ClaimAdapter> = null;
    searchField = "";

    vueScrollTo: any;
    inputSelected = false;

    from: number = 0;
    to: number = 5;
    manipulatedTo: number = 5;
    showCount: number = 5;

    showLoadMoreBtn: boolean = true;

    trackingUrl: string;

    beforeMount() {
        this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
    }

    // LIFECYCLE
    mounted() {
        this.getClaimsViaRest();
        this.vueScrollTo = require('vue-scrollto');
    }

    // COMPUTED
    get filteredClaimList() {
        const filteredList = this.claimArray.filter(claimAdapter => {
            if (this.searchField.length === 0) {
                return true;
            } else {
                //replace searches for whitespace from either the beginning or the end of the string
                const _searchField = this.searchField.replace(/^\s+|\s+$/g, "");
                return claimAdapter.claimTitle.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1 ||
                    claimAdapter.claim.textualDescription?.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1 ||
                    claimAdapter.claim.productTypeDescription?.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1 ||
                    claimAdapter.status.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1 ||
                    claimAdapter.claim.claimId?.toString().indexOf(_searchField.toLowerCase()) !== -1 ||
                    claimAdapter.incidentTime.indexOf(_searchField.toLowerCase()) !== -1 ||
                    claimAdapter.incidentTimeMinusDot.indexOf(_searchField.toLowerCase()) !== -1;
            }
        });


        return filteredList;
    }

    // METHODS
    getClaimsViaRest(): void {
        this.getClaimList().then(claims => {
            this.handleClaims(claims);
        }).catch(err => {
            this.handleApiServiceError(err, "Skader kunne ikke hentes. Noget gik galt.. ");
        })
    }

    handleClaims(claims: Array<Claim>): void {
        this.claimArray = [];
        claims.forEach(item => {
            //service returnere TIA navn på bil
            if (item.productTypeDescription === "Motorkøretøjsforsikring") {
                const bil = InsurancesProductEnum.BIL_FORSIKRING;
                item.productTypeDescription = "Bilforsikring " + bil.charAt(0).toUpperCase() + bil.slice(1);
            }

            this.claimArray.push(new ClaimAdapter(item));
        })
        //sorter med de nyeste skader foerst
        this.claimArray.sort((a: any, b: any) => new Date(b.claim.notificationTime).getTime() - new Date(a.claim.notificationTime).getTime());
    }


    inputSelect(value: boolean) {
        this.inputSelected = value;
    }

    loadMoreRows() {
        this.manipulatedTo = this.manipulatedTo + this.showCount;
        this.to = this.manipulatedTo;

        if (this.manipulatedTo >= this.claimArray.length) {
            this.showLoadMoreBtn = false;
        }

        this.vueScrollTo.scrollTo(document.querySelector('#scroll-to-bottom'));
    }

    showEventsLink(type: string, date: string) {
        // Temp handling until we rework timeline
        // Convert date format to "YYYY-MM-DD"
        const eventDate = new Date(date).toJSON().slice(0, 10);

        // Format "YYYY-MM-DD"
        const cutoffDate = "2020-11-26";

        const beforeCutoff = new Date(eventDate) > new Date(cutoffDate);

        return type === 'Bilforsikring' && beforeCutoff && !this.isThemed;
    }

}
