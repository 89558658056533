import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 2,
  class: "empty-header"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "col-6 col-md-8 text-left priceline priceline__1" }
const _hoisted_9 = { class: "col-6 col-md-4 text-right priceline priceline__1" }
const _hoisted_10 = { class: "col-6 col-md-8 text-left priceline priceline__2" }
const _hoisted_11 = { class: "col-6 col-md-4 text-right priceline priceline__2" }
const _hoisted_12 = { class: "col-6 col-md-8 text-left priceline priceline__2" }
const _hoisted_13 = { class: "col-6 col-md-4 text-right priceline priceline__2" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 3,
  class: "content"
}
const _hoisted_16 = {
  key: 4,
  class: "content row text-center"
}
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 0,
  class: "button-container text-center"
}
const _hoisted_19 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_group = _resolveComponent("button-group")!
  const _component_usabilla_in_page_block_component = _resolveComponent("usabilla-in-page-block-component")!
  const _component_productline_feature_component = _resolveComponent("productline-feature-component")!
  const _component_input_field_component = _resolveComponent("input-field-component")!
  const _component_checkbox_component = _resolveComponent("checkbox-component")!
  const _component_button_component = _resolveComponent("button-component")!
  const _component_dialog_component = _resolveComponent("dialog-component")!

  return (_ctx.flowHandler)
    ? (_openBlock(), _createBlock(_component_dialog_component, {
        key: 0,
        class: "orderflow",
        ref: "dialogComponent",
        makeFullscreen: false,
        flowLength: _ctx.flowHandler.length(),
        showProgressBar: true,
        closeCallBack: _ctx.flowHandler.closeCallBack,
        showSpinner: _ctx.showSpinner,
        showCloseBtn: true,
        onCheckboxComponent: _ctx.handleInputEvent
      }, {
        header: _withCtx(() => [
          (_ctx.flowHandler.active()!== null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.flowHandler.active().orderflowStepBlock.model.titleImg.url)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.flowHandler.active().orderflowStepBlock.model.titleImg.url,
                      alt: "",
                      class: "header-image text-center"
                    }, null, 8, _hoisted_2))
                  : (_ctx.flowHandler.active().orderflowStepBlock.model.overTitle)
                    ? (_openBlock(), _createElementBlock("h3", {
                        key: 1,
                        class: "text-uppercase over-header text-center pl-2 pr-2",
                        innerHTML: _ctx.flowHandler.active().orderflowStepBlock.model.overTitle
                      }, null, 8, _hoisted_3))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4))
              ]))
            : _createCommentVNode("", true)
        ]),
        body: _withCtx(() => [
          (_ctx.flowHandler.active()!== null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: "h2 text-center",
                  innerHTML: _ctx.flowHandler.active().orderflowStepBlock.model.title
                }, null, 8, _hoisted_6),
                _createElementVNode("div", {
                  class: "content",
                  innerHTML: _ctx.flowHandler.active().orderflowStepBlock.model.text
                }, null, 8, _hoisted_7),
                (_ctx.hasOptionalProperties())
                  ? (_openBlock(), _createBlock(_component_button_group, {
                      key: _ctx.refreshBtnG + 'btnG',
                      class: "content",
                      buttons: _ctx.flowHandler.active().orderflowStepBlock.optionalProperties,
                      preSelected: _ctx.flowHandler.active().orderflowStepBlock.selectedOptionalProperty,
                      buttonsPerRow: 2,
                      "onComponent:Event": _ctx.handleSelectEvent
                    }, null, 8, ["buttons", "preSelected", "onComponent:Event"]))
                  : _createCommentVNode("", true),
                (_ctx.flowHandler.active().orderflowStepBlock.orderflowPriceBlock)
                  ? (_openBlock(), _createElementBlock("div", {
                      class: "content row",
                      style: _normalizeStyle(_ctx.flowHandler.active().orderflowStepBlock.orderflowPriceBlock.model.backgroundColor),
                      key: _ctx.refreshPrice + 'Price'
                    }, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.flowHandler.active().orderflowStepBlock.orderflowPriceBlock.model.currentPriceLabel), 1),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.flowHandler.active().orderflowStepBlock.orderflowPriceBlock.currentPrice), 1),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.flowHandler.active().orderflowStepBlock.orderflowPriceBlock.model.newPriceLabel), 1),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.flowHandler.active().orderflowStepBlock.orderflowPriceBlock.newPrice), 1),
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.flowHandler.active().orderflowStepBlock.orderflowPriceBlock.model.totalPriceLabel), 1),
                      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.flowHandler.active().orderflowStepBlock.orderflowPriceBlock.totalPrice), 1),
                      _createElementVNode("div", {
                        class: "col-12 subtext text-left",
                        innerHTML: _ctx.flowHandler.active().orderflowStepBlock.orderflowPriceBlock.model.subText
                      }, null, 8, _hoisted_14)
                    ], 4))
                  : _createCommentVNode("", true),
                (_ctx.flowHandler.active().orderflowStepBlock.usabillaInPageBlock)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: _ctx.refreshUsabilla + 'usab',
                      class: "content iframe-center row text-center"
                    }, [
                      _createVNode(_component_usabilla_in_page_block_component, {
                        contentItem: _ctx.flowHandler.active().orderflowStepBlock.usabillaInPageBlock
                      }, null, 8, ["contentItem"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.flowHandler.active().featureAdapter)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(_component_productline_feature_component, {
                        coverage: _ctx.flowHandler.active().featureAdapter,
                        productTitle: _ctx.flowHandler.productTitle,
                        isCovered: true,
                        noCheckMark: true,
                        track: false,
                        isThemed: _ctx.themed
                      }, null, 8, ["coverage", "productTitle", "isThemed"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.flowHandler.active().orderflowStepBlock.inputFields.length !== 0 && _ctx.inputFieldsInitialized)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flowHandler.active().orderflowStepBlock.inputFields, (inputField, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index + 'fields',
                          class: "w-100"
                        }, [
                          (_ctx.isInputField(inputField))
                            ? (_openBlock(), _createBlock(_component_input_field_component, {
                                key: 0,
                                id: inputField.id,
                                name: inputField.model.fieldName,
                                type: inputField.model.hidden ? 'hidden' : inputField.type,
                                inputValue: inputField.model.defaultValue,
                                placeholder: inputField.model.placeHolder,
                                label: inputField.model.label,
                                required: inputField.model.required,
                                class: "text-left pl-3 pr-3",
                                onInputFieldComponent: _ctx.handleInputEvent
                              }, null, 8, ["id", "name", "type", "inputValue", "placeholder", "label", "required", "onInputFieldComponent"]))
                            : (_ctx.isCheckbox(inputField))
                              ? (_openBlock(), _createBlock(_component_checkbox_component, {
                                  key: 1,
                                  id: inputField.id,
                                  name: inputField.model.fieldName,
                                  class: "pl-3 pr-3"
                                }, {
                                  default: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "default", {}, () => [
                                      _createElementVNode("span", {
                                        class: "text-left regular",
                                        innerHTML: inputField.model.label
                                      }, null, 8, _hoisted_17)
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["id", "name"]))
                              : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          (_ctx.flowHandler.active()!== null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                (_ctx.flowHandler.active().orderflowStepBlock.model.btnEscapeBack)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back())),
                      class: _normalizeClass(_ctx.flowHandler.active().orderflowStepBlock.model.btnEscapeBackStyle)
                    }, _toDisplayString(_ctx.flowHandler.active().orderflowStepBlock.model.btnEscapeBack), 3))
                  : _createCommentVNode("", true),
                (_ctx.flowHandler.active().orderflowStepBlock.model.btnForward)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      type: "button",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.flowHandler.hasNextStep() ? _ctx.forward() : _ctx.closeOrderflow())),
                      disabled: !_ctx.canSubmit,
                      class: _normalizeClass(_ctx.flowHandler.active().orderflowStepBlock.model.btnForwardStyle)
                    }, _toDisplayString(_ctx.flowHandler.active().orderflowStepBlock.model.btnForward), 11, _hoisted_19))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flowHandler.active().orderflowStepBlock.inputFields, (inputField, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index + 'btns_'
                  }, [
                    (_ctx.isButton(inputField))
                      ? (_openBlock(), _createBlock(_component_button_component, {
                          key: 0,
                          href: inputField.link,
                          icon: inputField.icon,
                          label: inputField.label,
                          btnClass: inputField.buttonClass,
                          btnType: inputField.buttonType,
                          onButtonComponent: ($event: any) => (_ctx.handleBtnEvent(inputField))
                        }, null, 8, ["href", "icon", "label", "btnClass", "btnType", "onButtonComponent"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["flowLength", "closeCallBack", "showSpinner", "onCheckboxComponent"]))
    : _createCommentVNode("", true)
}