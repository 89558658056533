import { TileVariantEnum } from "@/definitions/episerver/content-types";
import { AbLink, ModalComponent } from "@/views/sharedcomponents/baseComponents/components";
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import TileApp from "./_partials/tileApp/TileApp.vue";
import TileButton from "./_partials/tileButton/TileButton.vue";
import TileCall from "./_partials/tileCall/TileCall.vue";
import TileNav from "./_partials/tileNav/TileNav.vue";
import TileSelectable from "./_partials/tileSelectable/TileSelectable.vue";

export default defineComponent({
  name: "TileBlockComponent",
  components: {
    AbLink,
    ModalComponent,
    TileApp,
    TileNav,
    TileCall,
    TileSelectable,
    TileButton,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const isEditable = store.state.epiContext.isEditable;
    const parentModel = store.state.epiContent.model;
    
    let trackingUrl = ref(undefined);
    trackingUrl = computed(() => Tracking.buildTrackingUrl(store.getters.getCustomer, route.fullPath));
    
    if (props.model.tileVariant === 4) {
      props.model.tileVariant = TileVariantEnum.Select;
    } else if (props.model.tileVariant === 5) {
      props.model.tileVariant = TileVariantEnum.Button;
    }

    return {
      TileVariantEnum,
      isEditable,
      parentModel,
      route,
      trackingUrl
    };
  },
});
