import store from "@/store";
import { Options, Vue } from "vue-class-component";
import template from "./modalComponent.vue";

@Options({
  name: "modal-component",
  mixins: [template],
  props: {
    id: String, // use to trigger modal with event, ex. on element
    type: { type: String, default: "default" }, // 'compact' | 'default'
    dismisable: Boolean, // compact modal is by default not dismisable, can be overridden if necessary
    trackingEnabled: { type: Boolean, default: true }, // used for tracking to distinguish different modals
    trackingLabel: String, // used for tracking to distinguish different modals, NB! required if tracking is enabled
    trackingUrl: String,
    bgColor: { type: String, default: "#fff" },
    padding: Number, // override padding, ie. '10px', '24px 32px', '0' etc.
    top: { type: String, default: "0" }, // override top, ie. '10px', '50%' etc.
  },
})
export default class ModalComponent extends Vue {
  id: string; // use to trigger modal with event, ex. on element
  type!: string; // 'compact' | 'default'
  dismisable!: boolean; // compact modal is by default not dismisable, can be overridden if necessary
  trackingEnabled!: boolean; // used for tracking to distinguish different modals
  trackingLabel!: string; // used for tracking to distinguish different modals, NB! required if tracking is enabled
  trackingUrl!: string;
  bgColor: string;
  padding!: number; // override padding, ie. '10px', '24px 32px', '0' etc.
  top!: string; // override top, ie. '10px', '50%' etc.

  isActive = false;
  scrollbarWidth = null;
  isDismisable = false;

  toggleModal(val?: boolean) {
    val === undefined ? (this.isActive = !this.isActive) : (this.isActive = val);
    if (this.isActive) {
      this.scrollbarWidth = this.getScrollbarWidth();
      setTimeout(() => {
        this.handleResize(); // position modal
      }, 10);
    }

    if (this.scrollbarWidth > 0) {
      // prevent content behind moving with overflow hidden
      this.setBodyWidthWithoutScrollbar();
    }
  }

  created() {
    document.addEventListener("keyup", this.handleKeyup);
    window.addEventListener("resize", this.handleResize);
    store.subscribeAction((action, state) => {
      if (action.type === "toggleModal") {
        if (this.id === action.payload.id) {
          this.toggleModal(action.payload.isActive);
        }
      }
    });
    this.isDismisable = (this.type === "compact" && this.dismisable) || this.type === "default" ? true : false;
  }

  beforeUnmount() {
    document.removeEventListener("keyup", this.handleKeyup);
    window.removeEventListener("resize", this.handleResize);
  }

  get modalTypeClass() {
    return this.type === "compact" ? "modal__content--compact" : null;
  }

  get hasFooterSlot() {
    return !!this.$slots.footer;
  }

  get trackingAttributesTrigger() {
    return this.trackingEnabled
      ? {
          "data-gtm-action": "tile click",
          "data-gtm-label": `${this.trackingLabel}`,
          "data-gtm-url": `${this.trackingUrl}`
        }
      : null;
  }

  private handleKeyup(e) {
    if (e.keyCode === 27 && this.isActive && this.isDismisable) {
      // escape
      this.toggleModal();
    }
  }

  private handleResize() {
    const modalInner = this.$refs.modalContent as HTMLElement;
    if (modalInner) {
      const modalHeight = modalInner.getBoundingClientRect().height;
      const modalMargins =
        parseInt(window.getComputedStyle(modalInner).getPropertyValue("margin-top"), 0) +
        parseInt(window.getComputedStyle(modalInner).getPropertyValue("margin-bottom"), 0);

      if (modalHeight + modalMargins > window.innerHeight || this.top !== "0") {
        // modal placement in top of viewport
        modalInner.style.cssText = `position:absolute; top: ${this.top}; background-color: ${this.bgColor}; padding: ${this.padding};`;
      } else {
        // set absolute position to prevent content jumping if height is changed
        const offsetTop = (window.innerHeight - modalHeight - modalMargins) / 2;
        modalInner.style.cssText = `position:absolute; top: ${offsetTop}px; background-color: ${this.bgColor}; padding: ${this.padding};`;
      }
    }
  }

  private getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
  }

  private setBodyWidthWithoutScrollbar() {
    const bodyWidth = this.isActive ? `calc(100% - ${this.scrollbarWidth}px)` : "100%";
    const overflowStyle = this.isActive ? "hidden" : "initial";
    (document.body as HTMLElement).style.overflow = overflowStyle;
    (document.body as HTMLElement).style.width = bodyWidth;
  }
}
