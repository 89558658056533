import GeneralHighlight from "./GeneralHighlight";
import AgreementLineAdapter from '@/views/model/adapter/AgreementLineAdapter';


export default class UlykkeHighlight extends GeneralHighlight{

 
    public constructor(productDetails: AgreementLineAdapter, isBusiness: boolean) {
        super(productDetails, isBusiness);
    }


    protected buildHighlights() {
        super.buildHighlights();

        this.highlights.splice(1, 0, {
            label: 'Selvrisiko',
            value: 'Ingen'
        });

        this.swapDoedfaldssumToInvaliditetssum();
    }

    private swapDoedfaldssumToInvaliditetssum() {
        let DoedfaldssumIndex = -1;
        let InvaliditetssumIndex = -1;

        let index = 0;
        this.highlights.forEach(item => {
            if (item.label.toLowerCase() === "dødsfaldssum") {
                DoedfaldssumIndex = index;
            }
            if (item.label.toLowerCase() === "invaliditetssum") {
                InvaliditetssumIndex = index;
            }
            index++;
        });

        if (DoedfaldssumIndex > -1 && InvaliditetssumIndex > -1 && DoedfaldssumIndex < InvaliditetssumIndex) {
            this.swap(this.highlights, InvaliditetssumIndex, DoedfaldssumIndex);
        }

    }
}
