import { TileBlock } from "@/definitions/episerver/content-types";
import { SELECTABLE_UPDATED, SelectableData, UPDATE_SELECTABLE } from "@/episerver/store/modules/selectableContext";
import store from "@/store";
import { CheckboxComponent } from "@/views/sharedcomponents/baseComponents/components";
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";

@Options({
  name: "TileSelectable",
  components: {
    CheckboxComponent,
  },
  computed: mapState<any>({
    isEditable: (state) => state.epiContext.isEditable,
    parentModel: (state) => state.epiContent.model,
  }),
  props: {
    model: Object as PropType<TileBlock>,
    trackingUrl: String,
  },
})
export default class TileSelectable extends Vue {
  model: TileBlock;
  trackingUrl: string;

  public selected = false;
  public disabled = false;
  public updateCheckMark = 1;
  public multipleCount = 0;
  private unsubscribe: any;

  public beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
  async mounted() {
    if (this.isSelected) {
      // selected in store
      this.selected = true;
      this.updateCheckMark++;
    } else if (this.model.selectActive) {
      // selected from cms
      await this.toggleSelect(undefined);
    }
    if (this.model.disabledWhen) {
      this.unsubscribe = store.subscribeAction((action, state) => {
        if (action.type === SELECTABLE_UPDATED && action.payload?.group === this.model.selectGroup) {
          this.toggleDisabled();
        }
      });
      // run on mount
      this.toggleDisabled();
    }
  }

  private toggleDisabled() {
    const disableWhen: Array<string> = this.model.disabledWhen.split(",");
    const selectGroup = store.getters.getSelectableGroup(this.model.selectGroup); // selected in store
    if (selectGroup) {
      this.disabled = false;
      disableWhen.forEach((element) => {
        if (selectGroup.includes(element.trim())) {
          this.disabled = true;
        }
      });
    }
  }

  public async toggleSelect(evt) {
    if (!this.disabled) {
      if (this.model.selectMultiple) {
        if (evt.srcElement?.id === "productPlus" || evt.srcElement?.id === "productMinus") {
          return;
        } else {
          this.add(undefined);
        }
      } else {
        this.selected = !this.selected;
        const toggle: SelectableData = {
          group: this.model.selectGroup,
          id: this.model.selectId,
          isSelected: this.selected,
          sortMultiple: true,
        };
        await store.dispatch(UPDATE_SELECTABLE, toggle);
        this.updateCheckMark++;
        // const res = store.getters.getSelectableGroup(this.model.selectGroup);
        // console.log('state of group', res);
      }
    }
  }

  public async add(evt) {
    if (this.multipleCount >= this.model.selectMultipleMax) {
      return;
    }

    const inx = this.findFirstInx();

    const toggle: SelectableData = {
      group: this.model.selectGroup,
      //id: this.model.selectId + this.multipleCount,
      id: this.model.selectId + inx,
      isSelected: true,
      sortMultiple: true,
    };
    // console.log('adding', this.model.selectId + inx);

    await store.dispatch(UPDATE_SELECTABLE, toggle);
    if (!this.selected) {
      this.selected = true;
      this.updateCheckMark++;
    }
    this.multipleCount++;
    // const selectGroup = store.getters.getSelectableGroup(this.model.selectGroup); // selected in store
    // console.log('selectGroup', selectGroup);
  }

  public async remove(evt) {
    if (this.multipleCount < 1) {
      return;
    }
    const toggle: SelectableData = {
      group: this.model.selectGroup,
      id: this.model.selectId + (this.multipleCount - 1),
      isSelected: false,
      sortMultiple: true,
    };
    await store.dispatch(UPDATE_SELECTABLE, toggle);
    if (this.multipleCount < 2) {
      this.selected = false;
      this.updateCheckMark++;
    }
    this.multipleCount--;
    // const selectGroup = store.getters.getSelectableGroup(this.model.selectGroup); // selected in store
    // console.log('selectGroup', selectGroup);
  }

  public get isSelected(): boolean {
    const selectGroup = store.getters.getSelectableGroup(this.model.selectGroup);
    if (selectGroup.length < 1) {
      return false;
    }
    if (this.model.selectMultiple) {
      const prods = selectGroup.filter((product) => product.startsWith(this.model.selectId));
      this.multipleCount = prods.length;
      return this.multipleCount > 0;
    }
    return selectGroup.includes(this.model.selectId); // selected in store
  }

  private findFirstInx() {
    const selectGroup = store.getters.getSelectableGroup(this.model.selectGroup);
    const prods = selectGroup.filter((product) => product.startsWith(this.model.selectId));
    if (prods.length === 0) {
      return 0;
    }
    let inx = 0;
    let run = true;
    prods.forEach((name) => {
      const productInx = parseInt(name.replace(/[^\d]/g, ""));
      if (run) {
        if (run && inx !== productInx) {
          // use this vacant spot
          run = false;
        }
        if (run) {
          inx++;
        }
      }
    });
    return inx;
  }
}
