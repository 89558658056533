import { UrlUtil } from "@/episerver/api/urlUtil";
import { Log } from "@/episerver/logging/log";
import { UPDATE_AUTH } from "@/episerver/store/modules/authenticationContext";
import { Customer, CustomerContactDetails } from '@/generated-api/customer-details';
import store from '@/store';
import UserData from '@/store/modules/userData';
import AbAmount from '@/views/common/ab_amount';
import AbAxios from '@/views/common/ab_axios';
import AbAxiosSelfservice from '@/views/common/ab_axios_selfservice';
import AbGtm from '@/views/common/ab_gtm';
import AbLightbox from '@/views/common/ab_lightbox';
import AbMisc from '@/views/common/ab_misc';
import { isNullOrUndefined } from '@/views/common/util';
import AbAxiosSelfserviceContent from '@/views/contentApi/ab_axios_selfservice_content';
import ServiceEnum from "@/views/model/ServiceEnum";
import TagsEnum from '@/views/model/TagsEnum';
import Utils from '@/views/providers/Utils';
import { App } from "@/views/sharedcomponents/lib/services/app";
import localForage from "localforage";
import { Vue } from "vue-class-component";
import AbCookie from "../common/ab_cookie";

const _logProperties = { SourceContext: "AbCommon", context: "spa" };

export default class AbCommon extends Vue {
    protected abMisc = new AbMisc();
    protected utils = new Utils();
    protected abLightbox = new AbLightbox();
    public abGtm = new AbGtm();

    public abAmount = new AbAmount();

    public abAxios = new AbAxios(
        store.getters.getConfigEpi?.serviceUrl,
        'application/json; charset=utf-8',
        store.getters.getConfigEpi?.csrfToken);

    public abAxiosSelfservice = new AbAxiosSelfservice(
        store.getters.getConfigEpi?.serviceUrl,
        'application/json',
        store.getters.getConfigEpi?.csrfToken);

    public abAxiosSelfserviceContent = new AbAxiosSelfserviceContent();
    public usabillaLoaded: boolean = false;

    public async getCustomer(showError: boolean = true) {
        try {
            let customer = null;
            if (!isNullOrUndefined(store.getters.getCustomer) &&
                (!isNullOrUndefined(store.getters.getCustomer.person) || !isNullOrUndefined(store.getters.getCustomer.organisation))) {
                Log.verbose("Getting customer from cache in AbCommon.GetCustomer", { ..._logProperties, method: "getCustomer" });
                customer = store.getters.getCustomer;
            } else {
                Log.verbose("Getting customer from api in AbCommon.GetCustomer", { ..._logProperties, method: "getCustomer" });
                customer = await this.getCustomerViaRest(showError);
            }
            return customer;
        } catch (err) {
            if(showError) {
                Log.exception("Exception in AbCommon.GetCustomer", err as any, { ..._logProperties, method: "getCustomer" })
                throw err;
            }
        }
    }

    public async customerIsBusiness() {
        if (store.state.isOpenCalculator) {
            return false;
        }
        const customer = <Customer>await this.getCustomer();
        return customer?.tags?.includes(TagsEnum.BUSINESS);
    }

    public async customerIsBroker(): Promise<boolean> {
        // the broker customer list dont have access to customer details 
        // const customer = <Customer>await this.getCustomer();
        // return customer?.tags?.includes(TagsEnum.BROKER);
        
        //check for token and state to determine if the current user is an broker
        //not the best but works for now 
        const query = new URLSearchParams(window.location.search);
        const token = query.get("token");
        const state = query.get("state");
        
        if(token && state) {
            return true;
        }

        return false;
    }

    private async getCustomerViaRest(showError: boolean = true) {
        return await this.abAxiosSelfservice.customerDetailsServiceLayer.fetchCustomerDetails().then(resp => {
            store.dispatch('setCustomer', resp.data);
            const userData: UserData = store.getters.getUserData;
            userData.setCanChangeOnline(resp.data);
            store.dispatch('setUserData', userData);

            return resp.data;
        }).catch(err => {
            if (showError) {
                if (!this.$route.path.startsWith(ServiceEnum.CALCULATOR_URL)) {
                    Log.exception("Exception while trying to get customer from api in AbCommon.getCustomerViaRest", err, { ..._logProperties, method: "getCustomerViaRest" });
                    this.handleApiServiceError(err, "Kunde kunne ikke hentes. Noget gik galt.. ");
                }
            }
        })
    }

    public async updateCustomerDetails(customerDetails: Array<CustomerContactDetails>) {
        return await this.abAxiosSelfservice.customerDetailsServiceLayer.updateCustomerDetails(customerDetails).then(resp => {
            store.dispatch('setCustomer', resp.data);
            return resp.data;
        }).catch(err => {
            this.handleApiServiceError(err, "Kunde kunne ikke opdateres. Noget gik galt.. ");
            throw "Kunde kunne ikke opdateres. Noget gik galt.. ";
        })
    }

    public async updateCustomerConsents(customerDetails: Array<CustomerContactDetails>) {
        return await this.abAxiosSelfservice.customerDetailsServiceLayer.updateCustomerConsents(customerDetails).then(resp => {
            store.dispatch('setCustomer', resp.data);
            return resp.data;
        }).catch(err => {
            this.handleApiServiceError(err, "Kunde kunne ikke opdateres. Noget gik galt.. ");
            throw "Kunde kunne ikke opdateres. Noget gik galt.. ";
        })
    }

    public receiveMessage() {
        const content = document.getElementById('content');
        if(content !== null){
            content.innerHTML = 'Got message';
        }
    }

    public callFromNativeScript(msg: {}) {
        if (App.isEnabled()) {
            App.sendMessage(msg);
        }
    }

    public isLoggedInAsApp() {
        return App.isEnabled();
    }

    public formatPrice(value: any): string {
        if (value === undefined) {
            return "0";
        }

        return this.abAmount.format(value) + "";
    }

    public formatDate(dateStr: string): string {
        return this.utils.formatDate(dateStr);
    }

    public getDateDifferenceInDays(startDate: Date, endDate: Date): number {
        const dayDiff: number = (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24;
        return dayDiff;
    }

    public handleApiServiceError(error: any, errorDesc: string) {
        //Send error log to App
        this.callFromNativeScript({ "ERROR": { "error": error, "errorDesc": errorDesc } })

        if (!isNullOrUndefined(error.response)) {
            let errorRes = error.response,
                statusCode = errorRes.status,
                statusText = errorRes.statusText,
                errorMsg = errorDesc + "[" + statusCode + " - " + statusText + "]";
            console.error(errorDesc, error, errorMsg);
            this.abGtm.triggerGtmEvent("fejl", "ApiServiceError", '[' + this.abGtm.getUtcTimestamp() + '] ' + errorMsg);
        } else {
            this.abGtm.triggerGtmEvent("fejl", "ApiServiceError", '[' + this.abGtm.getUtcTimestamp() + '] ' + error);
            console.error(errorDesc, error);
        }
        this.handleApiRequestError(error, errorDesc);
    }

    private handleApiRequestError(error: any, errorDesc: string){
        if (!App.isEnabled() && !this.$route.fullPath.startsWith(ServiceEnum.CALCULATOR_URL)) {
            store.dispatch(UPDATE_AUTH);
            if (store.state.authenticationContext.isLoaded && store.state.authenticationContext.authenticated) {
                const currentTime = new Date().getTime();
                if (currentTime >= store.state.authenticationContext.expire) {
                    const abCookie = new AbCookie();
                    abCookie.deleteCookie(ServiceEnum.AUTH_COOKIE_NAME);
                    window.location.href = ServiceEnum.LOGOUT_SERVICE;
                    return;
                }
            } 
        } 

        this.abLightbox.standardLightTextSelfService("", "", errorDesc + ", " + error);
    }

    public downloadFile(fileData: BlobPart, fileName: string, fileType = 'application/pdf') {
        //const arrayBuffer = this.base64ToArrayBuffer(fileData);
        const blob = new Blob([fileData], { type: fileType });

        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
    }

    get storedAgreementList() {
        return store.getters.getAgreementList;
    }

    public addUsabillaScript() {
        // console.log('adding usabilla', (window as any).usabilla);
        this.$nextTick(() => {
            if ((window as any).usabilla && !this.usabillaLoaded) {
                let maxRun = 30;
                let interval = setInterval(() => {
                    if (document.querySelector('div[ub-in-page]')) {
                        // console.log('usabilla found', document.querySelector('div[ub-in-page]'));
                        if (!this.usabillaLoaded) {
                            this.usabillaLoaded = true;
                            (window as any).usabilla.load("w.usabilla.com", "e9e9f96ce675");
                            // console.log('Usabilla called - maxRun', maxRun);
                        }
                        clearInterval(interval);
                    }
                    if (maxRun < 1) {
                        console.warn('Usabilla - Max run reached');
                        clearInterval(interval);
                    }
                    maxRun--;
                }, 100);
            }
            // } else {
            //     console.error('(window as any).usabilla not loaded, usabillaLaoded', this.usabillaLoaded);
            // }
        });
    }

    public doRoute(url: string) {
        if (isNullOrUndefined(url)) {
            return;
        }

        if (url.toLowerCase().includes("http") || url.toLowerCase().includes("www")) {
            window.open(url, "_blank");
        } else {
            let route = {
                path: UrlUtil.ensureCorrectLink(url),
            }
            this.$router.push(route);
        }
    }

    public isDevelop() {
        return UrlUtil.isDevelop();
    }

    public deepLinkIfPresent(): Promise<any> {
        // check if user has a saved deepurl because of redirect to login page
        return localForage.getItem('userRedirect').then((url) => {
            if (url) {
                // remove saved url from storage and redirect to it
                return localForage.removeItem('userRedirect').then(() => this.$router.push({ path: url as string }));
            }
        });
    }

    public async getStorage(name: string) {
        return await localForage.getItem(name);
    }

    public async setStorage(name: string, value: any) {
        return await localForage.setItem(name, value);
    }

    protected addSeparationSign(val: string, addCommaBefore = false, addCommaAfter = false, addDotAfter = false): string {
        return this.utils.addSeparationSign(val, addCommaBefore, addCommaAfter, addDotAfter);
    }

    public getBaseInfoValueByName(name: string, baseInfo: any, addCommaBefore = false, addCommaAfter = false, addDotAfter = false): string {
        return this.utils.getBaseInfoValueByName(name, baseInfo, addCommaBefore, addCommaAfter, addDotAfter);
    }

    protected replaceDanishChars(str: string): string {
        const danishChars = { æ: "ae", ø: "oe", å: "aa", Æ: "AE", Ø: "OE", Å: "AA" };
        str = str.replace(/æ|ø|å|Æ|Ø|Å/gi, (matched) => {
            return danishChars[matched];
        });

        str = str.replace(/ /g, "_");

        //replace special characters
        str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_');

        return str;
    }

    protected buildDateSeparated(dateStr: string): Array<string> {
        return this.utils.buildDateSeparated(dateStr);
    }

    public capitalizeFirstLetter(str: string, allWords: boolean = false) {
        return this.utils.capitalizeFirstLetter(str, allWords);
    }

    get isThemed() {
        return store.getters.getThemeContext !== "almbrand";
    }
}
