<template>
    <a v-if="btnType === 'tel' || 'mailto'"
       :class="[btnClass || 'btn']"
       :ref="(name + id)"
       :target="btnType === 'mailto' ? '_blank' : undefined"
       @click="clicked()">
        <span v-if="icon">
            <img :src="icon" class="btn-icon-left" />
        </span>
        <span>{{label}}</span>
    </a>
    <button v-else
            :type="btnType || 'button'"
            :class="[btnClass || 'btn']"
            :ref="(name + id)"
            @click="clicked()">
        <span v-if="icon">
            <img :src="icon" class="btn-icon-left" />
        </span>
        <span>{{label}}</span>
    </button>
</template>
