import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { key: "formerInsurance2" }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { key: "validNow" }
const _hoisted_9 = ["textContent"]
const _hoisted_10 = {
  key: "RkiLabel",
  class: "pb-3 text-left"
}
const _hoisted_11 = ["textContent", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioComponent = _resolveComponent("RadioComponent")!
  const _component_ValidationErrorComponent = _resolveComponent("ValidationErrorComponent")!
  const _component_SelectModalComponent = _resolveComponent("SelectModalComponent")!
  const _component_AbDatePickerComponent = _resolveComponent("AbDatePickerComponent")!
  const _component_SegmentedControlComponent = _resolveComponent("SegmentedControlComponent")!
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showInsuranceChoices)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "pb-3 text-left",
            innerHTML: _ctx.existingInsuranceLabel
          }, null, 8, _hoisted_2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jaNejArray, (item, inx) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([(inx + 1) === _ctx.jaNejArray.length ? 'pb-3' : 'pb-2']),
              key: 'existInsurance' + inx
            }, [
              _createVNode(_component_RadioComponent, {
                name: 'existInsurance',
                id: 'existInsurance' + item.value,
                inputValue: item.value,
                preSelect: _ctx.model.additionalInfo.existInsurance,
                trackingLabel: _ctx.existingInsuranceLabel,
                onRadioComponent: _ctx.handleRadioClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.displayValue), 1)
                ]),
                _: 2
              }, 1032, ["id", "inputValue", "preSelect", "trackingLabel", "onRadioComponent"])
            ], 2))
          }), 128)),
          (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.model.additionalInfo.existInsurance)
            ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                key: 0,
                class: "input-error--show input-error__radio mt-n3",
                text: "Vælg Ja eller Nej"
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showInsuranceChoices)
      ? (_openBlock(), _createBlock(_TransitionGroup, {
          key: 1,
          name: "expand-list"
        }, {
          default: _withCtx(() => [
            (_ctx.model.additionalInfo.existInsurance === 'ja')
              ? (_openBlock(), _createElementBlock("div", {
                  key: "existInsuranceLabel",
                  class: "pb-3 text-left",
                  textContent: _toDisplayString('Hvilket forsikringsselskab var du senest hos?')
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_SelectModalComponent, {
              key: "existingInsurance",
              id: "existingInsurance",
              name: "existingInsurance",
              preSelect: _ctx.model.additionalInfo.existingInsurance ?  _ctx.model.additionalInfo.existingInsurance : 'Vælg forsikringsselskab',
              innerData: _ctx.cms.insuranceCompanies,
              selected: _ctx.model.additionalInfo.existingInsurance !== undefined,
              "onSelectModalComponent:selected": _ctx.selectExistingInsurance
            }, null, 8, ["preSelect", "innerData", "selected", "onSelectModalComponent:selected"]), [
              [_vShow, _ctx.model.additionalInfo.existInsurance === 'ja']
            ]),
            (!_ctx.model.pristineStep.get(_ctx.card.name) && _ctx.model.additionalInfo.existingInsurance === undefined)
              ? _withDirectives((_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                  key: "existInsuranceErr",
                  class: "input-error--show mt-n5",
                  text: "Du skal vælge et forsikringsselskab"
                }, null, 512)), [
                  [_vShow, _ctx.model.additionalInfo.existInsurance === 'ja']
                ])
              : _createCommentVNode("", true),
            (_ctx.showFormerInsurance && _ctx.model.additionalInfo.existInsurance === 'nej')
              ? (_openBlock(), _createElementBlock("div", {
                  class: "pb-3 pt-3 text-left",
                  textContent: _toDisplayString('Har du haft en ' + _ctx.model.productName + ' før?'),
                  key: "formerInsurance1"
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.model.additionalInfo.existInsurance === 'nej' && _ctx.showFormerInsurance)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jaNejArray, (item, inx) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass([(inx + 1) === _ctx.jaNejArray.length ? 'pb-3' : 'pb-2']),
                      key: 'existFomrerInsurance' + inx
                    }, [
                      _createVNode(_component_RadioComponent, {
                        name: 'existFormerInsurance',
                        id: 'existFormerInsurance' + item.value,
                        inputValue: item.value,
                        preSelect: _ctx.model.additionalInfo.existFormerInsurance,
                        trackingLabel: 'Har du haft en ' + _ctx.model.productName + ' før?',
                        onRadioComponent: _ctx.handleRadioClick
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.displayValue), 1)
                        ]),
                        _: 2
                      }, 1032, ["id", "inputValue", "preSelect", "trackingLabel", "onRadioComponent"])
                    ], 2))
                  }), 128)),
                  (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.model.additionalInfo.existFormerInsurance)
                    ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                        key: "formerInsuranceErr",
                        class: "input-error--show input-error__radio mt-n3",
                        text: "Vælg Ja eller Nej"
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.showFormerInsurance && _ctx.model.additionalInfo.existFormerInsurance === 'ja' && _ctx.model.additionalInfo.existInsurance === 'nej')
              ? (_openBlock(), _createElementBlock("div", {
                  key: "existFormerInsuranceLabel",
                  class: "pb-3 text-left",
                  textContent: _toDisplayString('Hvilket forsikringsselskab var du senest hos?')
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true),
            (_ctx.showFormerInsurance && _ctx.model.additionalInfo.existFormerInsurance === 'ja' && _ctx.model.additionalInfo.existInsurance === 'nej')
              ? (_openBlock(), _createBlock(_component_SelectModalComponent, {
                  key: "formerInsurance",
                  id: "formerInsurance",
                  name: "formerInsurance",
                  preSelect: _ctx.model.additionalInfo.formerInsurance ?  _ctx.model.additionalInfo.formerInsurance : 'Vælg selskab',
                  innerData: _ctx.cms.insuranceCompanies,
                  selected: _ctx.model.additionalInfo.formerInsurance !== undefined,
                  "onSelectModalComponent:selected": _ctx.selectFormerInsurance
                }, null, 8, ["preSelect", "innerData", "selected", "onSelectModalComponent:selected"]))
              : _createCommentVNode("", true),
            (!_ctx.model.pristineStep.get(_ctx.card.name) && _ctx.model.additionalInfo.formerInsurance === undefined)
              ? _withDirectives((_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                  key: "existFormerInsuranceErr",
                  class: "input-error--show mt-n5",
                  text: "Du skal vælge et forsikringsselskab"
                }, null, 512)), [
                  [_vShow, _ctx.showFormerInsurance && _ctx.model.additionalInfo.existFormerInsurance === 'ja' && _ctx.model.additionalInfo.existInsurance === 'nej']
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_TransitionGroup, { name: "expand-list" }, {
      default: _withCtx(() => [
        (_ctx.showValidNowOption)
          ? (_openBlock(), _createElementBlock("div", {
              class: "pb-3 pt-3 text-left",
              textContent: _toDisplayString('Hvornår skal forsikringen gælde fra?'),
              key: "validNowLabel"
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true),
        (_ctx.showValidNowOption)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validNowArray, (item, inx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass([(inx + 1) === _ctx.validNowArray.length ? 'pb-3' : 'pb-2']),
                  key: 'validNow' + inx
                }, [
                  _createVNode(_component_RadioComponent, {
                    name: "validNow",
                    id: 'validNow' + item.value,
                    inputValue: item.value,
                    preSelect: _ctx.model.additionalInfo.validNow,
                    trackingLabel: "Hvornår skal forsikringen gælde fra?",
                    onRadioComponent: _ctx.handleRadioClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.displayValue), 1)
                    ]),
                    _: 2
                  }, 1032, ["id", "inputValue", "preSelect", "onRadioComponent"])
                ], 2))
              }), 128)),
              (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.model.additionalInfo.validNow)
                ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                    key: "validNowErr",
                    class: "input-error--show input-error__radio mt-n3",
                    text: "Vælg Ja eller Nej"
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.model.additionalInfo.validNow === 'nej' && _ctx.showValidNowOption)
          ? (_openBlock(), _createBlock(_component_AbDatePickerComponent, {
              key: "validFromDate",
              class: "text-left mb-3",
              label: "",
              innerId: "validFromDate",
              innerData: _ctx.model.additionalInfo,
              placeholder: "F.eks. 21-12-2020",
              validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
              validationRule: "AfterToday",
              dateLimitIncluded: true
            }, null, 8, ["innerData", "validateOnLoad"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_SegmentedControlComponent, {
          key: "skader",
          class: "pb-3",
          id: "skader",
          innerData: _ctx.skaderArray,
          preSelected: _ctx.model.additionalInfo.skader,
          controlsPerRow: 4,
          onSegmentedComponent: _ctx.handleRadioClick
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "pb-3 pt-3 text-left",
              textContent: _toDisplayString('Hvor mange forsikringsskader har du haft de seneste 2 år?')
            }, null, 8, _hoisted_9)
          ]),
          _: 1
        }, 8, ["innerData", "preSelected", "onSegmentedComponent"]), [
          [_vShow, !_ctx.model.multipleProducts && _ctx.isInsuranceInfoFilled]
        ]),
        (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.model.multipleProducts && _ctx.isInsuranceInfoFilled && !_ctx.model.additionalInfo.skader)
          ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
              key: "skaderErr",
              class: "input-error--show input-error__radio mt-n3",
              text: "Vælg antal skader"
            }))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_10, [
          _createElementVNode("span", null, _toDisplayString(_ctx.cms.rkiHeader), 1),
          (_ctx.cms.rkiPopover)
            ? (_openBlock(), _createBlock(_component_PopoverComponent, {
                key: 0,
                type: "icon",
                class: "page-component__popover text-left",
                buttonClass: "popover__icon popover__icon__questionmark",
                innerData: _ctx.cms.rkiPopover
              }, null, 8, ["innerData"]))
            : _createCommentVNode("", true)
        ], 512), [
          [_vShow, !_ctx.model.multipleProducts && _ctx.isInsuranceInfoFilled && _ctx.model.additionalInfo.skader]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jaNejArray, (item, inx) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            class: _normalizeClass([(inx + 1) === _ctx.jaNejArray.length ? 'pb-3' : 'pb-2']),
            key: 'rki' + inx
          }, [
            _createVNode(_component_RadioComponent, {
              name: "rki",
              id: 'rki' + item.value,
              inputValue: item.value,
              preSelect: _ctx.model.additionalInfo.rki,
              trackingLabel: _ctx.cms.rkiHeader,
              onRadioComponent: _ctx.handleRadioClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.displayValue), 1)
              ]),
              _: 2
            }, 1032, ["id", "inputValue", "preSelect", "trackingLabel", "onRadioComponent"])
          ], 2)), [
            [_vShow, !_ctx.model.multipleProducts && _ctx.isInsuranceInfoFilled && _ctx.model.additionalInfo.skader]
          ])
        }), 128)),
        (!_ctx.model.pristineStep.get(_ctx.card.name) && _ctx.model.additionalInfo.rki === undefined)
          ? _withDirectives((_openBlock(), _createBlock(_component_ValidationErrorComponent, {
              key: "rkiErr",
              class: "input-error--show input-error__radio mt-n3",
              text: "Vælg Ja eller Nej"
            }, null, 512)), [
              [_vShow, !_ctx.model.multipleProducts && _ctx.isInsuranceInfoFilled && _ctx.model.additionalInfo.skader]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("a", {
      class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', {'btn-disabled': !_ctx.isValid}]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
      textContent: _toDisplayString(_ctx.getNextCardLinkText()),
      "data-gtm": _ctx.cms.dataGtm,
      "data-gtm-click-text": _ctx.card.routePath
    }, null, 10, _hoisted_11)
  ]))
}