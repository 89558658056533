import { mapState } from 'vuex';
import InputComponent from '../../components/InputComponent.vue';
import AddressSearchComponent from '../../components/AddressSearchComponent.vue';
import { SelectModalComponent } from '@/views/sharedcomponents/baseComponents/components';
import { RadioComponent } from "@/almbrand/web-shared";
import FieldValidator from '../../FieldValidator';
import { HomeSettings } from '../../home/HomeSettings';
import { Validator, jaNejArray, ValuePair } from '../../BuyInsuranceHelper';
import ExistingAbProductsComponent from '../../components/ExistingABProductsComponent.vue'
import { FamilySettings } from '../../family/FamilySettings';
import { Options, Vue } from 'vue-class-component';
import { nextTick, PropType } from 'vue';
import { CardBlock } from '@/definitions/episerver/content-types';
import { CustomerCalculation } from '@/episerver/store/modules/calculatorContext';
import store from '@/store';

@Options({
    name: 'PersonInfoStepComponent',
    props: {
        card: Object as PropType<CardBlock>, // Card from cms
        isValid: Boolean, // wether card is complete
    },
    components: {
        InputComponent,
        AddressSearchComponent,
        SelectModalComponent,
        RadioComponent,
        ExistingAbProductsComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
})

export default class PersonInfoStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // wether card is complete

    public model!: any; // datastructure for user input (store)
    public cms!: HomeSettings | FamilySettings; // settings from CMS (store)

    public jaNejArray: Array<ValuePair> = jaNejArray;
    public fieldValidator: FieldValidator = Validator;
    public defaultAddress: string = null;
    public readonlyAge: string = null;
    public readonlyAddress: string = null;
    public readonlyFamilyMemberCount: string = null;

    public created () {
        const addressParam = this.$route.query.address as string;
        if (addressParam) {
            this.defaultAddress = addressParam;
        }
    }

    public mounted() {
        const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
        if (!this.model.personInfo.address || customerCalculation.hasCustomerData) {
            // wait for transition to begin showing address field
            setTimeout(() => {
                const input = (document.querySelector('#person_info .autocomplete-input') as HTMLInputElement);
                if (input) {
                    input.focus();
                }
            }, this.cms.animationTimeout  + 500);
        }

        if (this.model.multipleProducts) {
            const commonModel = store.getters.getCalculatorCommonModel;
            if (commonModel.personInfo.address) {
                this.readonlyAddress = this.model.personInfo.address;
            }
            if (this.fieldValidator.isValidAge(commonModel.personInfo.customerAge)) {
                this.readonlyAge = this.model.personInfo.customerAge;
            }
            if (commonModel.personInfo.familyMemberCount) {
                this.readonlyFamilyMemberCount = this.model.personInfo.familyMemberCount;
            }
        }
        window.addEventListener('keyup' , this.moveOn );
    }

    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    public nextStep() {
        this.$emit('nextStep', this.card.routePath);
    }

    public addressSearch(address) {
        if (address.matchText) {
            this.model.personInfo.address = address.matchText;
            this.model.personInfo.zipCode = address.data.postnr;
            this.model.personInfo.zipName = address.data.postnrnavn;
            this.model.personInfo.streetName = address.data.vejnavn;
            this.model.personInfo.houseNr = address.data.husnr;
            this.model.personInfo.floorNr = address.data.etage;
            this.model.personInfo.doorNr = address.data.dør;
            this.model.personInfo.kvhxValue = address.data.kvhx;
            this.model.pristineStep.set(this.card.name, true);
            return;
        }

        this.model.personInfo.address = undefined;
        this.model.personInfo.zipCode = undefined;
        this.model.personInfo.zipName = undefined;
        this.model.personInfo.streetName = undefined;
        this.model.personInfo.houseNr = undefined;
        this.model.personInfo.floorNr = undefined;
        this.model.personInfo.doorNr = undefined;
        this.model.personInfo.kvhxValue = undefined;
    }

    public familyMemberSelected(result: any) {
        if (result && result.selectedValue) {
            this.model.personInfo.familyMemberCount = result.selectedValue;
            const inx = this.familyMemberList.indexOf(result.selectedValue);
            this.model.personInfo.familyMemberCountId = this.model.calculation.abCalc.familyMemberCountOptions[inx].value;
            return;
        }

        this.model.personInfo.familyMemberCount = undefined;
        this.model.personInfo.familyMemberCountId = undefined;
    }

    get familyMemberList(): Array<string> {
        const memberListFormatted = [];
            this.model.calculation.abCalc.familyMemberCountOptions.forEach(element => {
                memberListFormatted.push(element.key);
            });
        return memberListFormatted;
    }

    public handleRadioClick(evt: any) {
        const name = evt.name ? evt.name : evt.id;
        if (name === 'thatchedRoof') {
            this.model.personInfo.thatchedRoof = evt.value;
            nextTick( () => {
                // wait for transition to begin showing customerAge field
                setTimeout(() => {
                    const input = document.getElementById('customerAge');
                    if (input) {
                        input.focus();
                    }
                }, 300);
            });
        }
    }

}
