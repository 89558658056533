import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import template from './CookieFooter.vue';
import { ModalComponent } from '@/views/sharedcomponents/baseComponents/components';

@Options({
    name: 'selfservice-cookie-footer',
    mixins: [template],
    components: {
        ModalComponent
    }
})
export default class CookieFooter extends mixins(InsurancesCommon) {
    get cookieInformation() {
        return this.$root['cookieInformation'];
    }
}
