import { ProductFeatures } from '@/generated-api/accident-quote';
import BuyInsuranceSettings from '../BuyInsuranceSettings'
import { cloneDeep } from 'lodash';
import { HtmlBlock } from '@/definitions/episerver/content-types';

export const enum PACKAGES {
    LILLE = 'Lille',
    STOR = 'Stor',
}

export const productId = 'SS1VUF9VUC0yOA==';

export const lilleFeatures: Array<any> = [
    {
        name: 'SS1VUF9VUC0yOC02Mg==', // Invaliditet heltid - sum 1 mill
        sum: 1000000,
        flex1: '5',
        flex1Lov: 'LOV_12'
    },
    {
        name: 'SS1VUF9VUC0yOC03', // dødsfaldsdækning heltid - sum 50.000
        sum: 50000
    },
    {
        name: 'SS1VUF9VUC0yOC00Nw==' // ekstra udbetaling
    },
];

export const storFeatures: Array<any> = [
    // ...mellemFeatures,
    {
        name: 'SS1VUF9VUC0yOC00NQ==' // Brudskader
    },
    {
        name: 'SS1VUF9VUC0yOC00OA==' // Extra hjælp
    },
    {
        name: 'SS1VUF9VUC0yOC01Mg==' // Kritisk sygdom børn
    },
];
export class AccidentChildrenSettings extends BuyInsuranceSettings{
    public work: Array<string> = [];
    public workIds: Array<string> = [];
    public personCountList: Array<string> = [];
    public showWorkForMinors: boolean = false;
    public workForMinorsLabel: string;
    public minChildAge: number = 1;
    public childCprPopover: string;

    public constructor(settingsBlock: HtmlBlock) {
        super(settingsBlock);
        this.loadAccidentChildrenSettings();
    }

    public getFeaturesByAge(features: Array<ProductFeatures>, customerAge) {
        const filteredFeatures: Array<ProductFeatures> = cloneDeep(features);
        return filteredFeatures;
    }

    /**
     * load settings from CMS if present
     */
    private loadAccidentChildrenSettings() {
       try {
            let setting: string;
            const settingsArr = this.settingsBlock.markup.split('$');
            settingsArr.forEach(setting_ => {
                setting = setting_.toLowerCase().trim();
                // console.log('checking setting', setting, setting.indexOf('usework='));

                if (setting.startsWith('work=') ) {
                    this.work = this.getSettingsValue(setting_).split('¤');
                } else if (setting.startsWith('workids=')) {
                    this.workIds = this.getSettingsValue(setting_).split('¤');
                } else if (setting.startsWith('personcountlist=') ) {
                    this.personCountList = this.getSettingsValue(setting_).split('¤');
                } else if (setting.startsWith('showworkforminors=') ) {
                    this.showWorkForMinors = JSON.parse(this.getSettingsValue(setting_));
                } else if (setting.startsWith('workforminorslabel=') ) {
                    this.workForMinorsLabel = this.getSettingsValue(setting_);
                } else if (setting.startsWith('minchildage=') ) {
                    this.minChildAge = parseInt(this.getSettingsValue(setting_));
                } else if (setting.indexOf('childcprpopover=') > -1 ) {
                    this.childCprPopover = this.getSettingsValue(setting_);
                }
            });
        } catch(e) {
            console.error(e);
        }
    }

    // hugget fra ulykkesforsikring
    public productInfo = {
        "products": {
            "product_id": "SS1VUF9VUC0yOA==",
            "code": "I-UP_UP-28",
            "title": "Ulykkesforsikring",
            "tags": "#private #insurance #up",
            "optional_feature_ids": [
                "SS1VUF9VUC0yOC02Mw==",
                "SS1VUF9VUC0yOC00NQ==",
                "SS1VUF9VUC0yOC00Nw==",
                "SS1VUF9VUC0yOC00OA==",
                "SS1VUF9VUC0yOC0zMA==",
                "SS1VUF9VUC0yOC0xMg==",
                "SS1VUF9VUC0yOC0xNA==",
                "SS1VUF9VUC0yOC0xOA==",
                "SS1VUF9VUC0yOC00Mw==",
                "SS1VUF9VUC0yOC01Mg==",
                "SS1VUF9VUC0yOC02Mg==",
                "SS1VUF9VUC0yOC0xNQ==",
                "SS1VUF9VUC0yOC01Mw==",
                "SS1VUF9VUC0yOC00NA==",
                "SS1VUF9VUC0yOC03"
            ],
            "features": [
                {
                    "feature_id": "SS1VUF9VUC0yOC02Mg==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-62",
                    "tags": "#private #insurance",
                    "title": "Invaliditet heltid",
                    "description": "Dækning hele døgnet - uanset om du er på arbejde eller har fri.",
                    "sub_feature_ids": [
                        "SS1VUF9VUC0yOC0z",
                        "SS1VUF9VUC0yOC02MA==",
                        "SS1VUF9VUC0yOC0y",
                        "SS1VUF9VUC0yOC00Mg=="
                    ],
                    "exclude_risk_num": "63"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC0xNQ==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-15",
                    "tags": "#private #insurance",
                    "title": "Udvidet motorcykel",
                    "description": "Dækker ulykker, der rammer dig som fører under kørsel på motorcykel."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC0zMA==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-30",
                    "tags": "#private #insurance",
                    "title": "Tyggeskade",
                    "description": "<p>Dækker rimelige og nødvendige udgifter til tandbehandling, hvis din tand går i stykker, mens du spiser. Omfatter også pludselig skade på gebis med op til 20.000 kr.<br></p>"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC00NA==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-44",
                    "tags": "#private #insurance",
                    "title": "Udvidet luftfartøj",
                    "description": "Dækker ulykker, der rammer dig som fører af et privatfly."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC00Nw==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-47",
                    "tags": "#private #insurance",
                    "title": "Ekstra udbetaling",
                    "description": "Der udbetales ekstra erstatning ved en méngrad på mellem 20-29% og dobbelterstatning ved en méngrad på mere end 29%."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC00OA==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-48",
                    "tags": "#private #insurance",
                    "title": "Ekstra hjælp",
                    "description": "Giver økonomisk kompensation\nved hospitalsindlæggelse i mere end tre dage, dækker udgifter til f.eks.\nrengøringshjælp eller andre daglige gøremål, medicin og transport til\nbehandlingsstedet.",
                    "risk_sum": [
                        "10000"
                    ],
                    "default_risk_sum": "10000"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC00NQ==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-45",
                    "tags": "#private #insurance",
                    "title": "Brudskader",
                    "description": "Du får udbetalt en erstatning på 1% af invaliditetssummen, dog maksimalt 15.000 kr., hvis du ved en ulykke f.eks. brækker en arm eller et ben. Dækker også ved overrivning af akillessenen eller korsbåndet."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC0xMg==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-12",
                    "tags": "#private #insurance",
                    "title": "Dødsfaldsdækning - fritid",
                    "description": "Hvis en ulykke er direkte årsag til din død, inden der er gået et år efter ulykkesdagen, får de/den begunstigede i policen udbetalt den aftalte dødfaldssum."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC01Mg==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-52",
                    "tags": "#private #insurance",
                    "title": "Kritisk sygdom - børn",
                    "description": "Der udbetales en erstatning, hvis dit barn får konstateret en kritisk sygdom som er omfattet af forsikringen. Se dine betingelser.",
                    "exclude_risk_num": "53",
                    "risk_sum": [
                        "125000"
                    ],
                    "default_risk_sum": "125000"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC00Mw==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-43",
                    "tags": "#private #insurance",
                    "title": "Farlig sport",
                    "description": "Dækker dig i tilfælde af ulykker, der sker mens du f.eks dyrker faldskærmsudspring, paragliding, bjergbestigning eller lignende."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC0xNA==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-14",
                    "tags": "#private #insurance",
                    "title": "Fritidsjobsdækning",
                    "description": "<p>Dækker barnet i tilfælde af ulykker opstået på fritidsjobbet.</p>"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC0xOA==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-18",
                    "tags": "#private #insurance",
                    "title": "Motorsport og ATV",
                    "description": "Dækker dig i tilfælde af ulykker under kørsel med gocart og ATV, samt deltagelse eller træning som amatør, i motorløb i bil eller på motorcykel på bane."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC02Mw==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-63",
                    "tags": "#private #insurance",
                    "title": "Invaliditet fritid",
                    "description": "<p>Dækker i fritiden.</p>",
                    "sub_feature_ids": [
                        "SS1VUF9VUC0yOC02MQ==",
                        "SS1VUF9VUC0yOC0xMA==",
                        "SS1VUF9VUC0yOC02NA==",
                        "SS1VUF9VUC0yOC05"
                    ]
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC01Mw==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-53",
                    "tags": "#private #insurance",
                    "title": "Kritisk sygdom",
                    "description": "Der udbetales en erstatning, hvis du får konstateret en kritisk sygdom som er omfattet af forsikringen. Se dine betingelser.",
                    "exclude_risk_num": "52",
                    "risk_sum": [
                        "125000"
                    ],
                    "default_risk_sum": "125000"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC03",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-7",
                    "tags": "#private #insurance",
                    "title": "Dødsfaldsdækning - heltid",
                    "description": "<p>Hvis en ulykke er direkte årsag til din død, inden der er gået et år efter ulykkesdagen, får de/den begunstigede i policen udbetalt den aftalte dødfaldssum.<br></p>",
                    "exclude_risk_num": "12"
                }
            ],
            "sub_features": [
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC02MQ==",
                    "code": "I-UP_UP-28-61",
                    "tags": "#private #insurance",
                    "title": "Sport",
                    "description": "<p>Dækker dig hvis du kommer til skade, mens du f.eks. spiller fodbold eller håndbold, dyrker karate eller cykeltræning, deltager i cykelløb, dykker eller deltager i tilsvarende aktiviteter.</p>"
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC0y",
                    "code": "I-UP_UP-28-2",
                    "tags": "#private #insurance",
                    "title": "Tandskade",
                    "description": "Dækker de udgifter, du har til tandbehandling, som er nødvendige for at opnå samme tandstatus, som du havde før ulykken."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC00Mg==",
                    "code": "I-UP_UP-28-42",
                    "tags": "#private #insurance",
                    "title": "Sport",
                    "description": "Dækker dig hvis du kommer til skade, mens du f.eks. spiller fodbold eller håndbold, dyrker karate eller cykeltræning, deltager i cykelløb, dykker eller deltager i tilsvarende aktiviteter."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC0z",
                    "code": "I-UP_UP-28-3",
                    "tags": "#private #insurance",
                    "title": "Fingererstatning",
                    "description": "Dækker med et fast beløb, hvis du får foretaget knogleamputation af hele det yderste led på enten ringfinger eller lillefinger, også selvom dit varige mén er under 5%."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC0xMA==",
                    "code": "I-UP_UP-28-10",
                    "tags": "#private #insurance",
                    "title": "Fingererstatning",
                    "description": "Dækker med et fast beløb, hvis du får foretaget knogleamputation af hele det yderste led på enten ringfinger eller lillefinger, også selvom dit varige mén er under 5%."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC05",
                    "code": "I-UP_UP-28-9",
                    "tags": "#private #insurance",
                    "title": "Tandskade",
                    "description": "Dækker de udgifter, du har til tandbehandling, som er nødvendige for at opnå samme tandstatus, som du havde før ulykken."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC02MA==",
                    "code": "I-UP_UP-28-60",
                    "tags": "#private #insurance",
                    "title": "Behandling og genoptræning",
                    "description": "Dækker behandling og genoptræning hos f.eks. fysioterapeut eller kiropraktor."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC02NA==",
                    "code": "I-UP_UP-28-64",
                    "tags": "#private #insurance",
                    "title": "Behandling og genoptræning",
                    "description": "<p>Dækker behandling og genoptræning hos f.eks. fysioterapeut eller kiropraktor.</p>"
                }
            ]
        }
    };

    // hardcoded Ulykke
    public oldCalcConfig =
        {
        "insuranceConfiguration": {
            "basePackageId": 18188,
            "coverages": [
            ],
            "excessIdDefault": 70,
            "excessList": [
                {
                    "amount": 3229,
                    "hidden": false,
                    "id": 64,
                    "minCustomerAge": 0,
                    "useAsDefault": false
                },
                {
                    "amount": 6457,
                    "hidden": false,
                    "id": 70,
                    "minCustomerAge": 0,
                    "useAsDefault": true
                }
            ],
            "packageIds": [
                18184,
                18188
            ],
            "packages": [
                {
                    "id": 18188,
                    "name": PACKAGES.LILLE,
                },
                {
                    "id": 18184,
                    "name": PACKAGES.STOR,
                }
            ],
        },
        textMappingsOuter: {
            "ui.coverage.mappings": "Dødsfaldsdækning - heltid=Dødsfaldsdækning",
            "ui.coverage.description.mappings":
                "Dødsfaldsdækning - heltid=Hvis en ulykke er direkte årsag til din død, inden der er gået et år efter ulykkesdagen, får de/den begunstigede i policen udbetalt den aftalte dødfaldssum.<br>Forsikringssum 50.000 kr.¤Invaliditet heltid=Dækning hele døgnet<br>Forsikringssum op til 1 mill. kr."
        }
    };
}
