import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_title = _resolveComponent("page-title")!
  const _component_content_area = _resolveComponent("content-area")!
  const _component_private = _resolveComponent("private")!
  const _directive_epi_edit = _resolveDirective("epi-edit")!

  return (_openBlock(), _createBlock(_component_private, {
    disableAuthenticationCheck: _ctx.model.disableAuthenticationCheck,
    showTitleInApp: _ctx.model.showTitleInApp,
    hideMenus: _ctx.model.hideMenus
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_page_title, {
          class: _normalizeClass({ 'page__title--tiles' : _ctx.isTiles }),
          preTitle: _ctx.model.preTitle,
          mainTitle: _ctx.model.mainTitle,
          showTitleInApp: _ctx.model.showTitleInApp
        }, null, 8, ["class", "preTitle", "mainTitle", "showTitleInApp"]),
        (_ctx.isTiles)
          ? _withDirectives((_openBlock(), _createBlock(_component_content_area, {
              key: 0,
              rootClass: 'tile-wrapper',
              model: _ctx.model.mainArea,
              isTiles: _ctx.isTiles
            }, null, 8, ["model", "isTiles"])), [
              [_directive_epi_edit, 'MainArea']
            ])
          : _withDirectives((_openBlock(), _createBlock(_component_content_area, {
              key: 1,
              rootClass: 'row',
              itemClass: 'col-12',
              model: _ctx.model.mainArea
            }, null, 8, ["model"])), [
              [_directive_epi_edit, 'MainArea']
            ])
      ])
    ]),
    _: 1
  }, 8, ["disableAuthenticationCheck", "showTitleInApp", "hideMenus"]))
}