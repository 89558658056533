import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5aaccee4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "image-brand__title"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "image-brand",
      style: _normalizeStyle(_ctx.bg)
    }, [
      (_ctx.userMessage)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_2, [
            _createElementVNode("span", { innerHTML: _ctx.userMessage }, null, 8, _hoisted_3),
            (_ctx.customer)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  innerHTML: _ctx.name
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 4)
  ]))
}