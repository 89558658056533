import BuyHome from './BuyHomeComponent';
import { BuyInsuranceHelper, Formatter, monthYearArray, ValuePair } from '../BuyInsuranceHelper';
import { cloneDeep } from 'lodash';
import { CustomerAgreementFeature} from '@/generated-api/customer-agreements';
import UrlHandler from '../UrlHandler';
import AbAxiosCalculators from '../AbAxiosCalculators';
import FeatureAdapter from '@/views/model/adapter/FeatureAdapter';
// import { getHomeInsuranceProductInfo } from '@/views/TestData/calculators/apiTestData';
// import HomeTestCalculation from '@/views/TestData/calculators/HomeCalculation.json';
import store from '@/store';
import { defaultConfig } from '@/episerver/config';

export default class HomeCalculator {
    private buyHome: BuyHome; // component using calculator
    private model: any; // the data structure for state and properties
    private cms: any; // settings from EPI
    private calcConfigHome: any;
    private succesCalc = true;
    private abAxiosCalculator: AbAxiosCalculators;
    private calculatorUrl: string;
    private calculatorInfoHome: Array<any>;

    constructor(buyHome: BuyHome) {
        this.buyHome = buyHome;
        this.model = buyHome.model;
        this.cms = buyHome.cms;
        this.calculatorInfoHome = JSON.parse(this.buyHome.calculatorInfoBlock.markup);
        this.calculatorUrl =  defaultConfig.baseUrl + store.getters.getConfigEpi.openServiceUrl + '/insurance/home/quotes';
        // console.log('this.calculatorUrl', this.calculatorUrl);
        // https://api.almbrand.dk/insurance/home/quotes?district_name=xxx&customer_age=45&insured_value=347000&street_name=Adolphsvej&street_building=19&post_code=2820&has_thatched_roof=n&has_burglar_alarm=n&family_member_count=2&plus_customer=PK0,PK3&excess_id=42&payments_per_year=12&selected=1,2,3,4,5,6,7,47&optional=8,9,46
        this.abAxiosCalculator = new AbAxiosCalculators(this.calculatorUrl);
        // this.abAxiosCalculator.get(this.calculatorUrl + '?district_name=xxx&customer_age=45&insured_value=347000&street_name=Adolphsvej&street_building=19&post_code=2820&has_thatched_roof=n&has_burglar_alarm=n&family_member_count=2&plus_customer=PK0,PK3&excess_id=42&payments_per_year=12&selected=1,2,3,4,5,6,7,47&optional=8,9,46')
        
        this.setUpConfig();
    }

    private mapCoveragesFromCalc() {
        this.model.calculation.allCoverages = new Map<number, Map<number, Array<FeatureAdapter>>>();
        this.model.calculation.abCalc.packs.forEach( (calculations, ownRiscId) => {
            const coveragesForPackage = new Map<number, Array<FeatureAdapter>>();
            calculations.forEach((calc, packageInx) => {
                const featureAdapters: Array<FeatureAdapter> = [];
                calc.coverages.forEach((coverage) => {
                    let customerAgreementFeature: CustomerAgreementFeature = {
                        description: this.cms.getCoverageDescription(coverage.name, coverage.description),
                        feature_id: coverage.riskId + '',
                        name: this.cms.getCoverageName(coverage.name),
                        sub_features: [],
                        // optional_properties: undefined,
                        // excess: undefined,
                        // sum: undefined,
                    };
                    let featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
                    featureAdapters.push(featureAdapter);
                });
                coveragesForPackage.set(packageInx, featureAdapters);
            });
            this.model.calculation.allCoverages.set(ownRiscId, coveragesForPackage);
        });
    }

    public async setUpConfig() {
        if (!this.calcConfigHome) {
            if (this.model.campaign.valid) {
                this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
            }
            
            const calculatorInfo = this.calculatorInfoHome.find(cfg => cfg.calculatorConfigId === this.cms.calculatorConfigId);
            
            this.calcConfigHome = calculatorInfo.insuranceConfiguration;

            this.calcConfigHome.insuredValueOptions = calculatorInfo.insuredValueOptions;
            this.calcConfigHome.familyMemberCountOptions = []
            this.calcConfigHome.familyMemberCountOptions = calculatorInfo.familyMemberCountOptions;
            this.cms.mapCoverages(calculatorInfo.textMappingsOuter);
        }
        this.model.calculation.abCalc = {
            excessIdDefault: this.calcConfigHome.excessIdDefault,
            packageIds: this.calcConfigHome.packageIds,
            packages: cloneDeep(this.calcConfigHome.packages),
            coveragesDetails: this.calcConfigHome.coverages,
            insuredValueOptions: this.calcConfigHome.insuredValueOptions,
            familyMemberCountOptions: this.calcConfigHome.familyMemberCountOptions
        };

        this.setupExcessList();
        this.model.calculation.abCalc.packages.forEach(pack => {
            pack.coverages = [];
            if (this.cms.extendCoverages) {
                pack.includedCoverageIds.forEach(riskId => {
                    const coverage = this.calcConfigHome.coverages.find( x => riskId === x.riskId);
                    pack.coverages.push(coverage);
                });
                pack.expandedCoverages = [];
                pack.includedCoveragesExpandedIds.forEach(riskId => {
                    const coverage = this.calcConfigHome.coverages.find( x => riskId === x.riskId);
                    pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
                });
            } else {
                pack.includedCoveragesExpandedIds.forEach(riskId => {
                    const coverage = this.calcConfigHome.coverages.find( x => riskId === x.riskId);
                    pack.coverages.push(coverage);
                });
            }
            this.cms.mapCoverageGroups(pack.coverages);
        });
        this.model.calculation.abCalc.packs = new Map<number, Object>();
        this.model.calculation.abCalc.excesses.forEach(excess => {
            const calcs = [];
            this.model.calculation.abCalc.packs.set(excess.id, calcs);
            this.model.calculation.abCalc.packages.forEach( (pack) => {
                calcs.push(this.buyHome.helper.reducePack(cloneDeep(pack)));
            });
        });
        this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'

        this.mapCoveragesFromCalc();
        this.updateCalcKeys();
    }

    public setupExcessList() {
        this.model.calculation.abCalc.excesses = this.calcConfigHome.excessList;
        this.model.ownRisks = [];
        this.model.calculation.abCalc.excesses.forEach((excess) => {
            if (this.model.personInfo.customerAge >= excess.minCustomerAge) {
                const valuePair: ValuePair = {
                    value: excess.id,
                    displayValue: Formatter.format(excess.amount) + ' kr.',
                };
                this.model.ownRisks.push(valuePair);
            }
        });
    }

    private checkFirstPackagePrices(excessId: number) {
        let ok = true;
        const packages = this.model.calculation.abCalc.packs.get(excessId);
        packages.forEach(calc => {
            if (!calc.totalPrice) {
                ok = false;
            }
        });
        return ok;
    }

    private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
        this.succesCalc = true;

        const rebate = this.buyHome.helper.getDiscount();
        this.model.calculation.discount = rebate.discount;
        this.model.calculation.discountDisplay = rebate.discountDisplay;

        if (this.buyHome.helper.hasAddressSupplement()) {
            this.model.calculation.addressSupplement = this.cms.addressSupplement;
        } else {
            this.model.calculation.addressSupplement = 1;
        }

        let promises = [];
        for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
            const packageId = this.model.calculation.abCalc.packageIds[index];
            promises.push(this.onePackageCalcEpi(monthly, excessId, packageId));
        }
        await Promise.all(promises);
        return Promise.resolve(this.succesCalc);
    }

    public async getCalculations(excessId?: number) : Promise<boolean> {
        if (!this.model.calculation.isCalculated) {
            this.model.showSpinner = true;
            this.model.calculating = true;
            // handle "eternal" spinning
            setTimeout(() => {
                if(this.model.showSpinner) {
                    this.model.showSpinner = false;
                    this.model.calculating = false;
                }
            }, this.cms.calculationSpinnerTimeout);
        }

        // reset prices
        const pack = this.model.calculation.abCalc.packs.get(excessId);
        pack.totalPriceDisplay = undefined;
        return this.getEpiCalculations(excessId);
    }

    private async getEpiCalculations(excessId? : number): Promise<boolean> {
        // reset calculation
        await this.setUpConfig();

        let success = true;
        const _excessId = excessId ? excessId : this.model.calculation.abCalc.excessIdDefault;

        success = await this.calculatePackagesEpi(true, _excessId);
        // check if got prices
        if(!success) {
            success = this.checkFirstPackagePrices(_excessId);
        }
        if (success) {
            this.calculatePackagesEpi(false, _excessId);
        }
        this.model.calculation.isCalculated = success;
        this.model.showSpinner = false;
        this.model.calculating = false;
        this.buyHome.abGtm.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/prices_shown`,
        });

        return success;
    }

    private async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {

        // const response = JSON.parse(JSON.stringify(HomeTestCalculation));
        const response = await this.abAxiosCalculator.getRetryDefaultUrl(this.createParamsEpi(excessId, packageId, monthly ? 1 : 0));

        if (response.status !== 200) {

            this.succesCalc = false;

            this.model.modal.content = this.cms.defaultCalcErrorContent;

            this.model.modal.track = false;
            this.model.modal.btnSecondLabel = 'Ok';
            this.model.modal.id = 'calcWarning';
            this.model.modal.show = true;
            if (response.status === 250) {
                    this.model.modal.title = this.cms.defaultCalcErrorTitle;
                    this.model.modal.content = this.cms.defaultCalcErrorContent;
            }
            else {
                this.model.modal.title = 'Der er desværre sket en fejl';
                this.model.modal.id = 'calcError';
            }
            this.buyHome.toggleModal();
            return Promise.resolve(false);
        }

        const pack = this.model.calculation.abCalc.packs.get(excessId);
        BuyInsuranceHelper.mapPriceResult(response);
        this.buyHome.helper.setPrices(packageId, response, monthly, pack);

        this.updateCalcKeys();
        return Promise.resolve(true);
    }
    
    private createParamsEpi( excessId: number, packageId: number, monthlyPrices: number ) {
        const customerAge  = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
        const streetBuildingLetter = this.model.personInfo.houseNr.trim().replace(/[0-9]/g, ''); // remove numbers
        const streetNumber = this.model.personInfo.houseNr.trim().replace(/\D/g,''); // remove characters

        let suite: number;
        let floorSideCode: string;
        if (this.model.personInfo.doorNr) {
            suite = this.model.personInfo.doorNr.trim().replace(/[0-9]/g, '') === '' ? parseInt(this.model.personInfo.doorNr.trim()) : undefined;
            floorSideCode = this.model.personInfo.doorNr.trim().replace(/\D/g,'', '') === '' ? this.model.personInfo.doorNr.trim() : undefined;
        }
        
        let insuredValue = this.model.choosePackage.insuredValueAmount;
        if (!insuredValue) {
            insuredValue = this.model.calculation.abCalc.insuredValueOptions[0].value;
        }
        // district_name=xxx&
        // customer_age=45
        // insured_value=347000&
        // street_name=Adolphsvej&
        // street_building=19
        // &post_code=2820&
        // has_thatched_roof=n&
        // has_burglar_alarm=n&
        // family_member_count=2&
        // plus_customer=PK0&
        // excess_id=42&
        // payments_per_year=12&
        // selected=1,2,3,4,5,6,7,47&
        // optional=8,9,46

        //bournionv 1a
        // https://api.almbrand.dk/insurance/home/quotes?district_name=xxx&customer_age=45&insured_value=347000&street_name=Bournonvillesvej&street_building=1&street_building_letter=A&post_code=3480&has_thatched_roof=n&has_burglar_alarm=n&family_member_count=2&plus_customer=PK0,PK3&excess_id=42&payments_per_year=12&selected=1,2,3,4,5,6,7,47&optional=8,9,46
        
        // gasværks
        // https://api.almbrand.dk/insurance/home/quotes?district_name=xxx&customer_age=45&insured_value=347000&street_name=Gasværksvej&street_building=29&floor=2&floor_side_code=th&post_code=1656&has_thatched_roof=n&has_burglar_alarm=n&family_member_count=2&plus_customer=PK0,PK3&excess_id=42&payments_per_year=12&selected=1,2,3,4,5,6,7,47&optional=8,9,46


        //https://?district_name=xxx&customer_age=45&insured_value=347000&street_name=Bournonvillesvej&street_building=1&street_building_letter=A&post_code=3480&has_thatched_roof=y&has_burglar_alarm=n&family_member_count=2&plus_customer=PK0,PK3&excess_id=42&payments_per_year=12&selected=1,2,3,4,5,6,7,47&optional=8,9,46        
        //https://?customer_age=45&district_name=xxx&insured_value=347000&street_name=Bournonvillesvej&street_building=1&street_building_letter=A&post_code=3480&hasThatchedRoof=y&has_burglar_alarm=n&family_member_count=1&plus_customer=PK0&excess_id=42&payments_per_year=12&selected=1,2,3,4,5,6,7,47
        // create params
        const params = {
            district_name: 'xxx', // ?
            customer_age: customerAge,
            insured_value: insuredValue,
            street_name: this.model.personInfo.streetName,
            street_building: streetNumber,
            street_building_letter: streetBuildingLetter !== '' ? streetBuildingLetter : undefined,
            floor: this.model.personInfo.floorNr,

            suite, // hvis tal
            floor_side_code: floorSideCode, // hvis ikke tal
            
            post_code: `${this.model.personInfo.zipCode}`,
            has_thatched_roof: this.model.personInfo.thatchedRoof === 'ja' ? 'y' : 'n',
            has_burglar_alarm: 'n',
            family_member_count: this.model.personInfo.familyMemberCountId,
            plus_customer: 'PK0',
            excess_id: `${excessId}`,
            payments_per_year: monthlyPrices === 1 ? 12 : 1,
            selected: this.buyHome.helper.getRisksForPackage(packageId),
            // kvhxValue: this.model.personInfo.kvhxValue, // ?
            // optional
            
            
            
            // selectedOptionalProducts: '46,9,8',
            // zipName: `${this.model.personInfo.zipName}`,
            // extendedBikeSum: this.cms.extendedBikeSum // is present in familyinsurance, currently not in homeInsurance
            // extendedPackage: `${packageId}`,
            // calculatorBlockId: this.cms.oldCalculatorBlockId,
        }
        return params;
    }

    // private createParamsEpi( excessId: number, packageId: number, monthlyPrices: number ) {
    //     const customerAge  = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
    //     // create params
    //     const params = {
    //         customerAge,
    //         familyMemberCount: this.model.personInfo.familyMemberCountId,
    //         doorNr: this.model.personInfo.doorNr,
    //         floorNr: this.model.personInfo.floorNr,
    //         houseNr: this.model.personInfo.houseNr,
    //         streetName: this.model.personInfo.streetName,
    //         kvhxValue: this.model.personInfo.kvhxValue,
    //         insuredValue: this.model.choosePackage.insuredValueAmount,
    //         // selectedOptionalProducts: '46,9,8',
    //         hasThatchedRoof: this.model.personInfo.thatchedRoof === 'ja' ? 1 : 0,
    //         mcb: false, // ????
    //         zipName: `${this.model.personInfo.zipName}`,
    //         zipCode: `${this.model.personInfo.zipCode}`,
    //         excessId: `${excessId}`,
    //         extendedPackage: `${packageId}`,
    //         monthlyPrices: `${monthlyPrices}`,
    //         calculatorBlockId: this.cms.oldCalculatorBlockId,
    //     }
    //     return params;
    // }
    public async checkForValidAddress(): Promise<boolean> {
        const packageId = this.model.calculation.abCalc.packageIds[0];
        const excessId = this.model.calculation.abCalc.excessIdDefault;
        const ok = await this.onePackageCalcEpi(true, excessId, packageId);
        return Promise.resolve(ok);
    }

    public updateCalcKeys() {
        this.model.calculation.updatePrices = this.model.calculation.updatePrices.map( (elem) => {
            return elem += '1';
        });
    }

}
