import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState } from "vuex";
import { InboxBlock } from "@/definitions/episerver/content-types";
import { SkeletonLoaderComponent, SpinnerComponent } from '@/views/sharedcomponents/baseComponents/components';
import { ModalComponent } from "@/views/sharedcomponents/baseComponents/components";
import Private from '@/views/layouts/private';
import InsurancesCommon from "@/views/model/InsurancesCommon";
import FileExtEnum from "./FileExtEnum";
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking"
import store from '@/store';

@Options({
    name: 'InboxBlockComponent',
    components: {
        Private,
        ModalComponent,
        SkeletonLoaderComponent,
        SpinnerComponent
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<any>
    },
})

export default class InboxBlockComponent extends mixins(InsurancesCommon) {
    model: any;

    reader: FileReader = null;
    parser: DOMParser = null;

    inboxItems = null;

    inputSelected = false;
    searchField = "";

    vueScrollTo: any;

    from: number = 0;
    to: number = 5;
    manipulatedTo: number = 5;
    showCount: number = 5;

    showLoadMoreBtn: boolean = true;

    isBusiness: boolean = false;

    public xmlHtml: string = null;

    blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    trackingUrl: string;

    beforeMount() {
        this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
    }

    // LIFECYCLE
    async mounted() {
        this.isBusiness = await this.customerIsBusiness();
        this.getInboxViaRest();
        this.vueScrollTo = require('vue-scrollto');
    }

    // COMPUTED
    get displayList() {
        return this.filteredInbox.slice(this.from, this.to);
    }

    get filteredInbox() {
        if (this.inboxItems === null || this.inboxItems === undefined) {
            return {};
        }
        let filteredList = this.inboxItems.filter(item => {
            if (this.searchField.length === 0) {
                this.to = this.manipulatedTo;
                return true;
            } else {
                this.to = 99999;
                //replace searches for whitespace from either the beginning or the end of the string
                let _searchField = this.searchField.replace(/^\s+|\s+$/g, "");
                return item.name.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1 ||
                    item.description.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1 ||
                    item.doc_type.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1 ||
                    item.date.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1 ||
                    item.dateMinusDot.toLowerCase().indexOf(_searchField.toLowerCase()) !== -1;
            }
        });

        return filteredList;
    }

    // METHODS
    getInboxViaRest(): void {
        this.getInboxList().then(inbox => {
            this.handleGetInbox(inbox);
        }).catch(err => {
            this.handleApiServiceError(err, "Indbakke kunne ikke hentes. Noget gik galt.. ");
        })
    }

    // Tranformerer data til view
    handleGetInbox(inbox: any): void {
        this.inboxItems = {};
        this.inboxItems = inbox.documents;

        if (this.manipulatedTo >= this.inboxItems.length) {
            this.showLoadMoreBtn = false;
        }
    }

    loadMoreRows() {
        this.manipulatedTo = this.manipulatedTo + this.showCount;
        this.to = this.manipulatedTo;

        if (this.manipulatedTo >= this.inboxItems.length) {
            this.showLoadMoreBtn = false;
        }

        this.vueScrollTo.scrollTo(document.querySelector('#scroll-to-bottom'));
    }

    inputSelect(value) {
        this.inputSelected = value;
    }


    async inboxSelected(item: any) {
        (this.$refs.spinnerComponent as any).startSpinner(); 

        if (item.content_type === "application/xml") {
            let response = await this.fetchCustomerDocument(item);
            this.xmlBlobToHtmlView(new Blob([response], { type: 'application/xml' }))
        }
        else if (item.content_type === "application/pdf") {
            if (this.isLoggedInAsApp()) {
                this.callFromNativeScript(
                    {
                        "PDF_REST": `/customer/documents/${item.id}`
                    }
                );
            } else {
                let response = await this.fetchCustomerDocument(item);
                const blob = new Blob([response]);
                let date = new Date();
                let formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                this.downloadFile(blob, `${item.name}_fil_${formattedDate}.pdf`);
            }
        }
        else if (item.content_type === "text/plain") {
            let response = await this.fetchCustomerDocument(item);
            this.plainBlobToHtmlView(item, new Blob([response]))
        }
        else {
            const fileExt = this.fileExt(item.content_type);
            let response = await this.fetchCustomerDocument(item);
            this.fileBlobToHtmlView(item, fileExt, new Blob([response]));
        }
    }

    fileExt(contentType: string) {
        let ext = null;
        let values = Object.values(FileExtEnum);
        values.forEach(value => {
            if (value === contentType) {
                ext = Object.keys(FileExtEnum).find(key => FileExtEnum[key] == value);
            }
        });
        return ext;
    }

    public async fetchCustomerDocument(item: any) {
        return await this.abAxiosSelfservice.customerInboxDocumentServiceLayer.fetchCustomerDocument(item.id,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: "blob"
            }
        ).then(response => {
            (this.$refs.spinnerComponent as any).closeSpinner();
            return response.data;
        }).catch(err => {
            (this.$refs.spinnerComponent as any).closeSpinner();
            this.handleApiServiceError(err, "Document kunne ikke hentes. Noget gik galt.. ");
        });
    }

    xmlBlobToHtmlView(blob) {
        this.reader = new FileReader();
        this.parser = new DOMParser();
        this.reader.addEventListener('loadend', (element: any) => {
            const text = element.srcElement.result;

            const xmlDoc = this.parser.parseFromString(text, "text/xml");
            let nodevalue = null;
            let elements = xmlDoc.getElementsByTagName("wcm:element");
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].attributes[0].nodeValue === "Content") {
                    nodevalue = elements[i];
                }
            }

            if (nodevalue === undefined || nodevalue === null) {
                return false;
            } else {
                let htmlStr = nodevalue.childNodes[0].nodeValue;
                // Fjern urler
                // htmlStr = htmlStr.replace(/<a\b[^>]*>/gm, '').replace(/<\/a>/gm, '');
                // Hvis app -> callback så GP håndtere det
                if (this.isLoggedInAsApp()) {
                    this.callFromNativeScript(
                        {
                            "SHOW_HTML": `${htmlStr}`
                        }
                    );
                }
                // Ellers kald browser print
                else {
                    this.xmlHtml = htmlStr;
                    (this.$refs["Modal"] as any).toggleModal();
                }
            }
        });
        this.reader.readAsText(blob);
    }

    fileBlobToHtmlView(item, ext, blob) {
        if (this.isLoggedInAsApp()) {
            this.blobToBase64(blob).then(base64 => {
                this.callFromNativeScript(
                    {
                        "SHOW_FILE": `${base64}`,
                        "EXT": `${ext}`
                    }
                );
            });
        }
        // Ellers download fil
        else {
            let date = new Date();
            let formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
            this.downloadImage(blob, `${item.name}_${formattedDate}.${ext}`);
        }
    }

    public downloadImage(fileData, fileName) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(fileData);
        link.download = fileName;
        document.body.append(link);
        link.click();
        link.remove();
        window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
    }

    plainBlobToHtmlView(item, blob) {
        this.reader = new FileReader();
        this.reader.addEventListener('loadend', (element: any) => {
            const text = element.srcElement.result;
            if (this.isLoggedInAsApp()) {
                this.callFromNativeScript(
                    {
                        "SHOW_HTML": `${text}`
                    }
                );
            }
            else {
                this.printDiv(`<p>${text}</p>`);
            }
        });
        this.reader.readAsText(blob);
    }

    printDiv(html) {
        let myWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

        myWindow.document.write(html);
        myWindow.document.close(); // Necessary for IE >= 10

        myWindow.onload = () => { // Necessary if the div contain images
            myWindow.focus(); // Necessary for IE >= 10
            myWindow.print();
            myWindow.close();
        };

        return true;
    }

    // Temp solution to handle max date retured by api in certain cases
    isNotMaxDate(date: string) {
        const maxDateFormatted = "99/99/99";
        return date !== maxDateFormatted;
    }
}
