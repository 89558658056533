import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tile_app = _resolveComponent("tile-app")!
  const _component_tile_nav = _resolveComponent("tile-nav")!
  const _component_tile_call = _resolveComponent("tile-call")!
  const _component_tile_selectable = _resolveComponent("tile-selectable")!
  const _component_tile_button = _resolveComponent("tile-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'tile' : _ctx.model.tileVariant !== _ctx.TileVariantEnum.Button }])
  }, [
    (_ctx.model.tileVariant == _ctx.TileVariantEnum.App)
      ? (_openBlock(), _createBlock(_component_tile_app, {
          key: 0,
          model: _ctx.model,
          trackingUrl: _ctx.trackingUrl
        }, null, 8, ["model", "trackingUrl"]))
      : _createCommentVNode("", true),
    (_ctx.model.tileVariant == _ctx.TileVariantEnum.Navigation)
      ? (_openBlock(), _createBlock(_component_tile_nav, {
          key: 1,
          model: _ctx.model,
          trackingUrl: _ctx.trackingUrl
        }, null, 8, ["model", "trackingUrl"]))
      : _createCommentVNode("", true),
    (_ctx.model.tileVariant == _ctx.TileVariantEnum.Call)
      ? (_openBlock(), _createBlock(_component_tile_call, {
          key: 2,
          model: _ctx.model,
          trackingUrl: _ctx.trackingUrl
        }, null, 8, ["model", "trackingUrl"]))
      : _createCommentVNode("", true),
    (_ctx.model.tileVariant == _ctx.TileVariantEnum.Select)
      ? (_openBlock(), _createBlock(_component_tile_selectable, {
          key: 3,
          model: _ctx.model,
          trackingUrl: _ctx.trackingUrl
        }, null, 8, ["model", "trackingUrl"]))
      : _createCommentVNode("", true),
    (_ctx.model.tileVariant == _ctx.TileVariantEnum.Button)
      ? (_openBlock(), _createBlock(_component_tile_button, {
          key: 4,
          model: _ctx.model,
          trackingUrl: _ctx.trackingUrl
        }, null, 8, ["model", "trackingUrl"]))
      : _createCommentVNode("", true)
  ], 2))
}