<template>
    <div>
        <slot/>
        <div v-for="(chunk,inx) in chunks"
             :key="id + 'segC' + inx"
             :class="['segmented-control', theme]">
            <button type="button" v-for="index in chunk"
                    :key="id + 'ctrl' + index"
                    :class="['segmented-control__item', {'segmented-control__item--selected' : index === selectedControl}]"
                    :style="'width: ' + (100/controlsPerRow) + '%'"
                    @click="selected(index)"
                    @keyup.enter.space="selected(index)" v-text="list[index].displayValue"/>
        </div>
    </div>
</template>

<style lang="scss" src="./_assets/_segmented-control.scss"></style>
<!-- <script src="./segmentedControlComponent.ts" lang="ts"></script> -->