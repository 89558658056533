<template>
    <div v-if="showPolicyOrTermsButton()" v-on:click="doGetPolicyOrTerms()" class="policy policy--link" tabindex="0"
        data-gtm-action="button click" :data-gtm-label="contentItem.model.downloadType + ` download valgt`"
        :data-gtm-url="trackingUrl">
        <SpinnerComponent ref="spinnerComponent" />
        <div class="policy__content-text">
            <h3 class="policy__intro">{{contentItem.model.intro}}</h3>
            <p class="policy__text" v-if="contentItem.model.text">{{contentItem.model.text}}</p>
        </div>

        <img class="policy__btn" :src="contentItem.model.downloadIcon.url" alt="Hent"
            v-if="contentItem.model.downloadIcon.url !== undefined" />
        <img class="policy__btn" src="/siteassets/site-images/svg/system-icons/icon-system-file-download.svg" alt="Hent" v-else />
    </div>
</template>
