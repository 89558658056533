<template>
        <ul class="basic-list__list">
            <li class="basic-list__link"
                v-on:click="objectSelected(item, item.objectId)"
                v-on:keyup.enter="objectSelected(item, item.objectId)"
                v-for="item in agreemenObjects" :key="item.id">
                <product-list-item :title="item.name"
                                   :description="item.adress" />
            </li>
        </ul>

        <div class="basic-list__item basic-list__item--cols">
            <h3 class="basic-list__item__title"> Samlet pris / år:</h3>
            <div>{{formatPrice(totalPrice)}} /kr</div>
        </div>
</template>
