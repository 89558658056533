import store from '@/store';
import AbAxiosSelfservice from '@/views/common/ab_axios_selfservice';
import { Cart, AddFeatureRequest, QuoteRequest } from '@/generated-api/cart';
import { AxiosResponse } from 'axios';
import InsurancesCommon from '@/views/model/InsurancesCommon';
import Utils from '@/views/providers/Utils';
export default class CartHandler {

    private static abAxiosSelfservice_: AbAxiosSelfservice;
    private static headerOptions = {
        headers: {
        'Accept': '*/*',
        'Accept-Language': 'da',
        'Content-Type': 'application/json',
        // 'aaa-test': 'klk',
        // 'hello-dolly': 'application/json',
        },

        withCredentials: true
    };
    private static utils = new Utils();

    private static get abAxiosSelfservice(): AbAxiosSelfservice {
        if(!this.abAxiosSelfservice_) {
            this.abAxiosSelfservice_ = new AbAxiosSelfservice(store.getters.getConfigEpi.serviceUrl,
                'application/json',
                store.getters.getConfigEpi.csrfToken);
        }
        return this.abAxiosSelfservice_;
    }

    public static async getCart(): Promise<Cart> {
        let cart: Cart = store.getters.getCart;
        if (!cart) {
            const newCart = await this.createCart();
            store.dispatch('setCart', newCart.data);
            cart = newCart.data;
        }
        return cart;
    }

    private static async createCart(): Promise<any> {
        try {
            return await this.abAxiosSelfservice.cartServiceLayer.createCart(CartHandler.headerOptions);
        }
        catch (err) {
            return Promise.reject();
        }
    }

    /**
     * checkout mean accept the change ( in TIA )
     */
    public static async checkoutCart(insurancesCommon: InsurancesCommon, productTitle: string, orderflowId: string): Promise<Cart | AxiosResponse<Cart> | Response> {
        try {
            const cartBeforeCheckout = store.getters.getCart;

            const checkedOutCart = await this.abAxiosSelfservice.cartServiceLayer.checkout(cartBeforeCheckout.id, CartHandler.headerOptions);
            this.logCheckout(insurancesCommon, checkedOutCart, cartBeforeCheckout, productTitle, orderflowId );
            if((checkedOutCart as any).id /*|| true*/) {
                // fail, cart is returned from server, when something goes wrong
                return Promise.reject();
            }
            const newPolicy = cartBeforeCheckout.changes[0];
               store.dispatch('setNewPolicy', {
               agreement_id: newPolicy.agreement_no,
               agreement_line_seq_no: newPolicy.agreement_id,
            });
            store.dispatch('setCart', undefined);
            return Promise.resolve(checkedOutCart.data);
        } catch (e) {
            console.error("error", e);
            return Promise.reject();
        }
    }

    private static logCheckout(insurancesCommon: InsurancesCommon, checkedOutCart: any, cartBeforeCheckout: Cart, productTitle: string, orderflowId: string) {
        this.utils.log('Tilvalg','CART CHECKED OUT');
        try {
            let subject = 'Online ændring';
            if (checkedOutCart.id) { // cart not removed from server - out of sync
                // console.log('CHECKOUT FAIL', checkedOutCart);
                this.utils.log('Tilvalg','CHECKOUT FAIL', checkedOutCart);
                if (checkedOutCart.changes && checkedOutCart.changes[0]) {
                    subject = 'Fejl i ' + subject + ' på ' + productTitle + ', ' + orderflowId;
                    insurancesCommon.sendMailToTeam('Fejl i tilkøb for kunde på MitAlmbrand', subject, JSON.stringify(checkedOutCart));
                }
            } else if (cartBeforeCheckout && cartBeforeCheckout.changes && cartBeforeCheckout.changes[0]) {
                subject +=  ' på ' + productTitle + ', ' + orderflowId;
                insurancesCommon.sendMailToTeam('Kunde har købt online ændring på MitAlmbrand', subject, JSON.stringify(cartBeforeCheckout));
            }
        } catch (err) {}
    }

    public static async requestPrice(agreementId: string, propertyChangeName: string, propertyChangeId: string ): Promise<Cart>  {
        const cart: Cart = await this.getCart();
        const cart2 = await this.abAxiosSelfservice.cartServiceLayer.changeAgreementProperty(cart.id, agreementId, {
            properties: {
                [propertyChangeName]: propertyChangeId
            }
        },
        CartHandler.headerOptions);
        store.dispatch('setCart', cart2.data);
        return Promise.resolve(cart2.data);
    }

    public static async requestPriceCoverage(agreementId: string, featureId: string, tilvalg: boolean, optionalValue?: string): Promise<Cart>  {
        // console.log('requestPriceCoverage',agreementId, featureId, tilvalg, optionalValue);
        
        const cart: Cart = await this.getCart();
        // console.log('cart', cart);
        
        const cart2 = await this.addFeatureServer(cart.id, agreementId, featureId, tilvalg, optionalValue);
        // console.log('cart2', cart2);
        
        store.dispatch('setCart', cart2);
        return Promise.resolve(cart2);
    }

    private static async addFeatureServer(cardId: string, agreementId: string, featureId: string, tilvalg: boolean, optionalValue?: string) {

        
        try {
            let addFeatureRequest: AddFeatureRequest = { feature_id: featureId };
            // console.log('addFeatureServer',cardId, agreementId, addFeatureRequest);
            if (optionalValue) {
                const feature_properties = { riskSum: optionalValue};
                addFeatureRequest = { feature_id: featureId, feature_properties };
            }

            // "feature_id": "3",
            // "feature_properties":
            //     {
            //         "riskSum": "30000"
            //     }


            let result: AxiosResponse<Cart>;
            if(tilvalg)
            {
                // console.log('tilvalg');
                
                result = await this.abAxiosSelfservice.cartServiceLayer.addFeature(cardId, agreementId, addFeatureRequest, CartHandler.headerOptions);
            } else {
                // console.log('fravalg');
                
                result = await this.abAxiosSelfservice.cartServiceLayer.removeFeature(cardId, agreementId, featureId, CartHandler.headerOptions);
            }
            // console.log('Result', result);
            // if (optionalValue) {
            //     result.data.changes[0].price_diff +=100;
            // }
            return Promise.resolve(result.data);
        }
        catch (e) {
            console.error('addFeatureServer', e);
            
            return Promise.reject();
        }

    }

    public static async removeCart(): Promise<any> {
        try {
            store.dispatch('setCart', undefined);
            // console.log('getting lastKnownCart');
            const cart = await this.abAxiosSelfservice.cartServiceLayer.getLastKnownCart(CartHandler.headerOptions);
            // console.log('lastKnownCart', cart);
            
            if (cart && cart.data && cart.data.id) {
                // const abAxiosCalculator = new AbAxiosCalculators(store.getters.getConfigEpi.serviceUrl+'/cart/' + cart.data.id);
                // abAxiosCalculator.setWithCredentials(true);
                // abAxiosCalculator.useRecaptcha(false);
                // return await abAxiosCalculator.delete(undefined);


                return this.abAxiosSelfservice.cartServiceLayer.deleteCart(cart.data.id, CartHandler.headerOptions).then((deletedCard) => {
                    this.utils.log('Tilvalg','Cart removed on server', cart.data, deletedCard.data, deletedCard.status, deletedCard.statusText);

                    return Promise.resolve();
                });
            }
            else {
                return Promise.resolve();
            }
        } catch (err) {
            console.error(err);
            return Promise.resolve();
        }
    }

    public static async createProduct(agreementId: string) {
        // await this.removeCart(); // fjern evt. andre carts
        // const newCart = await this.createCart();
        const newProduct = await this.abAxiosSelfservice.cartServiceLayer.engageQuote(agreementId, CartHandler.headerOptions);
        return newProduct.data;
    }
    public static async getQuotes(quoteRequest: QuoteRequest) {
        // await this.removeCart(); // fjern evt. andre carts
        // const newCart = await this.createCart();
        const newProduct = await this.abAxiosSelfservice.cartServiceLayer.quote(quoteRequest, CartHandler.headerOptions);
        return newProduct.data;
    }
}
