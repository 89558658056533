import { Vue, Options, mixins } from "vue-class-component";
import template from './skeletonLoaderComponent.vue';

@Options({
    name: 'SkeletonLoaderComponent',
    mixins: [template],
    props: {
        columns: { type: Number, default: 1 },
        loaders: { type: Number, default: 2 },
        bgColor: { default: 'white' },
        overwriteColsOnPhone: {type: Boolean, default: true }
    }
})

export default class SkeletonLoaderComponent extends Vue {

    columns?: number;
    loaders?: number;
    bgColor?: string;
    overwriteColsOnPhone?: boolean;

    public getColumns() {
        const col = Math.floor(12 / this.columns);
        return this.overwriteColsOnPhone ? `col-sm-${col}` : `col-${col}`;
    }
}
