import { Vue, Options, mixins } from "vue-class-component";
import template from './InfoCardBlock.vue';
import InfoCardBlock from '../../contentTypes/InfoCardBlockCd'

@Options({
    name: 'info-card-block',
    mixins: [template],
    props: {
        contentItem: InfoCardBlock
    }
})
export default class InfoCardBlockComponent extends Vue{

    contentItem: InfoCardBlock;

}
