<template>
    <div>
        <div v-if="isTeaserBlock(contentItem)">
            <teaser-card-block-component :contentItem="contentItem"></teaser-card-block-component>
        </div>

        <div v-if="isInfoCardBlock(contentItem)">
            <info-card-block-component :contentItem="contentItem"></info-card-block-component>
        </div>

        <div v-if="isUsabillaBlock(contentItem)" class="text-center">
            <usabilla-in-page-block-component :contentItem="contentItem"/>
        </div>

        <div v-if="isHtmlBlock(contentItem)">
            <div v-html="contentItem.model.markup"></div>
        </div>

        <div v-if="isCardBlock(contentItem)">
            <card-block-component :contentItem="contentItem"></card-block-component>
        </div>
    </div>
</template>
