enum TitleReplaceEnum {
    Notification = "E-mail",
    KundeMail = "E-mail",
    Document = "Dokument",
    KundeBrev = "Dokument",
    Forslag = "Dokument",
    Volapyk = "Dokument",
}

export default TitleReplaceEnum;
