import { ContactFixedBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd';

export default class ContactFixedBlockCd extends ContentItemCd {
    public model: ContactFixedBlock;
    constructor(model: ContactFixedBlock) {
        super();
        this.model = model;
    }
}
