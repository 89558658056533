import { createApp } from 'vue';
import App from '@/App.vue';
import EpiEdit from '@/directives/epiEdit';
import inView from '@/directives/inView';
import router from '@/router';
import store from '@/store';
import InlineSvg from 'vue-inline-svg';
import appInsights, { AppInsightsOptions } from './appinsights';
import '@/epiBootstrap';
import { defaultCmsConfig } from '@/config';
defaultCmsConfig.contentArea.rootHtmlTag = 'section';

import InboxBlock from '@/views/overview/insurances/InboxBlock/InboxBlock.vue';
import CustomerBlock from '@/views/overview/customer/customerBlock/CustomerBlock.vue';
import TileBlock from '@/views/sharedcomponents/epiComponents/blocks/tileBlock/TileBlock.vue';
import TextBlock from '@/views/sharedcomponents/epiComponents/blocks/textBlock/TextBlock.vue';
import UsabillaInPageBlock from '@/views/contentApi/components/UsabillaInPageBlockComponent/UsabillaInPageBlock.vue';
import InsuranceListBlock from '@/views/overview/insurances/insuranceListBlock/InsuranceListBlock.vue';
import InsuranceDetailBlock from '@/views/overview/insurances/productline/Productline.vue';
import ProductObjectsBlock from '@/views/overview/insurances/productObject/ProductObject.vue';
import FileDownloadBlock from '@/views/overview/misc/fileDownloadBlock/FileDownloadBlock.vue';
import BillingBlock from '@/views/overview/insurances/BillingBlock/BillingBlock.vue';
import ClaimsListBlock from '@/views/overview/insurances/claimsListBlock/ClaimsListBlock.vue';
import EventsBlock from '@/views/overview/insurances/eventsBlock/eventsBlock.vue';
import ImageBrandBlock from '@/views/overview/misc/imageBrandBlock/imageBrandBlock.vue';
import PopoverComponent from '@/views/sharedcomponents/baseComponents/components/popoverComponent/popoverComponent'; // TODO KLK  - fix to vue
import ValidationErrorComponent from '@/views/sharedcomponents/baseComponents/components/ValidationErrorComponent/ValidationErrorComponent.vue';
import CmsImg from './views/sharedcomponents/baseComponents/components/cmsImg/cmsImg.vue';
import AccordionGroupBlock from '@/views/sharedcomponents/epiComponents/blocks/accordionBlock/AccordionBlock.vue';


const app = createApp(App)
    .directive('epi-edit', EpiEdit)
    .use(store)
    .use(router)
    .use(inView)
    .use(appInsights, { router } as AppInsightsOptions)
    .component('inline-svg', InlineSvg)
    .component('InboxBlock', InboxBlock)
    .component('CustomerBlock', CustomerBlock)
    .component('InsuranceListBlock', InsuranceListBlock)
    .component('TileBlock', TileBlock)
    .component('TextBlock', TextBlock)
    .component('UsabillaInPageBlock', UsabillaInPageBlock)
    .component('FileDownloadBlock', FileDownloadBlock)
    .component('BillingBlock', BillingBlock)
    .component('ClaimBlock', ClaimsListBlock)
    .component('EventBlock', EventsBlock)
    .component('ImageBrandBlock', ImageBrandBlock)  
    .component('CmsImg', CmsImg)
    .component('InsuranceDetailBlock', InsuranceDetailBlock)
    .component('ProductObjectsBlock', ProductObjectsBlock)
    .component('AccordionGroupBlock', AccordionGroupBlock)
    // Calculators
    .component('PopoverComponent', PopoverComponent)
    .component('ValidationErrorComponent', ValidationErrorComponent)

// Register all Episerver view components globally. This requires webpack!
// Otherwise we need to register all components manually here in main.js.
let requireComponent = require.context('./views/pages', true, /.vue$/);
requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    // Gets the component name regardless folder depth
    const namePart = fileName.split('/').pop() ?? '';
    const componentName = namePart.replace(/\.\w+$/, '');

    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    //console.log("###componentName: "+componentName);
    app.component(componentName, componentConfig.default || componentConfig);
});

app.mount('#app');

declare global {
    interface Window {
        epi: {
            inEditMode: boolean
            isEditable: boolean
            subscribe(eventName: string, callback: (message: { url: string }) => void): void
            ready: boolean
        }
    }
}