import { Vue, Options, mixins } from "vue-class-component";
import ToggleLineComponent from '@/views/sharedcomponents/baseComponents/components/toggleline/ToggleLineSimpleComponent.vue';
import Tooltipcomponent from '@/views/sharedcomponents/baseComponents/components/tooltip/Tooltip.vue';



import InsurancesCommon from '@/views/model/InsurancesCommon';
import DataRow from '@/views/model/DataRow';
import template from './PriceOverview.vue';

@Options({
    name: 'selfservice-insurances-priceoverview-component',
    mixins: [template],
    components: {
        'tooltip-component': Tooltipcomponent,
        'toggleline-component': ToggleLineComponent,
    }
})
export default class PriceOverview extends mixins(InsurancesCommon) {

    coverages: Array<DataRow> = [];

    mounted() {
        console.log("pricelist overview page");
        this.coverages = [
            {
                id: 1,
                title: "Ansvar",
                description: "1.001 kr.",
                price: 1001,
                selected: false,

                subheaders: [
                    { title: "Samlede renter", value: "11624 kr. / år" },
                    { title: "Lånebeløb", value: "541810 kr. / år" },
                    { title: "Rentespænd", value: "3.95 kr. / år" }
                ],
            },
            {
                id: 2,
                title: "Kasko",
                description: "Beskrivelse af produkt",
                price: 1749,
                selected: false
            },
            {
                id: 3,
                title: "Din nye pris",
                description: "",
                price: 2850,
                selected: false
            },

        ];
    }

}
