import { Vue, Options } from "vue-class-component";
import { mapState } from "vuex";
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking";
import store from '@/store';
import { PropType } from "vue";

@Options({
	name: 'AccordianBlock',
	components: {},
	computed: mapState<any>({
		isEditable: state => state.epiContext.isEditable
	}),
	props: {
		model: Object as PropType<any>
	}
})

export default class AccordianBlock extends Vue {

	model: any;
	accordians: Array<any> = [];
	groupTitle: string;
	trackingUrl: string;

	beforeMount() {
		this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);

		this.groupTitle = this.model.title;
		this.model.accordionItems.items.forEach(item =>{
			item.content.selected = false;
			item.content.id = Math.random();

			this.accordians.push(item);
		})

	}
	public animateController(item: any) {
		item.content.selected = !item.content.selected;
	}

}
