<template>
    <div v-if="consentData !== null">
        <div class="customer-consent">
            <div class="customer-consent__header">
                <h3 class="customer-consent__title" id="consent-data-title">
                    {{consentData.cleanDetail}}
                </h3>
                <ToggleComponent name="consentData"
                                 labelledBy="consent-data-title"
                                 :id="consentData.type"
                                 :class="consentData.type"
                                 :preSelect="consentData.checked" 
                                 v-on:ToggleComponentChanged="ToggleComponentChanged"/>
            </div>

            <p v-if="consentData.description !== ''" class="customer-consent__content" v-html="consentData.description"></p>
        </div>
    </div>
</template>

<style src="./_assets/_customer-consents.scss" lang="scss"></style>
