import { IContent } from "@/definitions/episerver/content";
import { ContactFixedBlock, CoveragesBlock, InfoCardBlock, MitAbButtonBlock, OrderflowContainerBlock, PolicyDownloadBlock, HtmlBlock, CardBlock, SelfserviceMyInsurancesBlock, ServiceCheckStep, SimpleContactBlock, TeaserCardBlock, UsabillaInPageBlock } from "@/definitions/episerver/content-types";
import ContactFixedBlockCd from "./contentTypes/ContactFixedBlockCd";
import { ContentBlockType } from "./contentTypes/ContentBlockType";
import CoveragesBlockCd from "./contentTypes/CoveragesBlockCd";
import InfoCardBlockCd from "./contentTypes/InfoCardBlockCd";
import MitAbButtonBlockCd from "./contentTypes/MitAbButtonBlockCd";
import OrderflowContainerBlockCd from "./contentTypes/OrderflowContainerBlockCd";
import PolicyDownloadBlockCd from "./contentTypes/PolicyDownloadBlockCd";
import HtmlBlockCd from "./contentTypes/HtmlBlockCd";
import CardBlockCd from "./contentTypes/CardBlockCd";
import SelfserviceMyInsurancesBlockCd from "./contentTypes/SelfserviceMyInsurancesBlockCd";
import ServiceCheckStepCd from "./contentTypes/ServiceCheckStepCd";
import SimpleContactBlockCd from "./contentTypes/SimpleContactBlockCd";
import TeaserCardBlockCd from "./contentTypes/TeaserCardBlockCd";
import UsabillaInPageBlockCd from "./contentTypes/UsabillaInPageBlockCd";


export default class ContentBlockCreator {
    public static getBlocks(blocks: Array<IContent>) : Array<any> {
        const contentBlocks = [];
        if (blocks?.length < 1) {
            return contentBlocks;
        }
        let b: any;
        blocks.forEach(block => {
            b = undefined;
            if (!block.hide) {
                switch (block.contentType) {
                    case ContentBlockType.InfoCardBlock : b = new InfoCardBlockCd(block as InfoCardBlock);
                        break;
                    case ContentBlockType.OrderflowContainerBlock : b = new OrderflowContainerBlockCd(block as OrderflowContainerBlock)
                        break;
                    case ContentBlockType.CoveragesBlock : b =  new CoveragesBlockCd(block as CoveragesBlock);
                        break;
                    case ContentBlockType.PolicyDownloadBlock : b =  new PolicyDownloadBlockCd(block as PolicyDownloadBlock);
                        break;
                    case ContentBlockType.TeaserCardBlock : b =  new TeaserCardBlockCd(block as TeaserCardBlock);
                        break;
                    case ContentBlockType.SelfserviceMyInsurancesBlock : b =  new SelfserviceMyInsurancesBlockCd(block as SelfserviceMyInsurancesBlock);
                        break;
                    case ContentBlockType.ContactFixedBlock : b =  new ContactFixedBlockCd(block as ContactFixedBlock);
                        break;
                    case ContentBlockType.MitAbButtonBlock : b =  new MitAbButtonBlockCd(block as MitAbButtonBlock);
                        break;
                    case ContentBlockType.HtmlBlock : b =  new HtmlBlockCd(block as HtmlBlock);
                        break;
                    case ContentBlockType.CardBlock : b =  new CardBlockCd(block as CardBlock);
                        break;
                    case ContentBlockType.SimpleContactBlock : b =  new SimpleContactBlockCd(block as SimpleContactBlock);
                        break;
                    case ContentBlockType.ServiceCheckStep : b =  new ServiceCheckStepCd(block as ServiceCheckStep);
                        break;
                    case ContentBlockType.UsabillaInPageBlock : b =  new UsabillaInPageBlockCd(block as UsabillaInPageBlock);
                        break;
                    default: console.warn('Missing mapping in ContentBlockCreator for', block.contentType);
                    
                }
                if (b) {
                    contentBlocks.push(b);
                }
            }
        });
        return contentBlocks;
    }

    public static getBlock(block: IContent): any {
        return this.getBlocks([block])[0];
    }

    public static getBlocksOfContentType(blocks: Array<IContent>, contentType: string): Array<any> {
        const filteredBlocks = blocks.filter(block => block.contentType === contentType );
        // console.log('filteredBlocks', filteredBlocks);
        return this.getBlocks(filteredBlocks);
    }


}