enum FileExtEnum {
    doc = "application/msword",
    dot = "application/msword",
    docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    dotx = "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    xls = "application/vnd.ms-excel",
    xlt = "application/vnd.ms-excel",
    xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xltx = "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    pps = "application/vnd.ms-powerpoint",
    ppsx = "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    ppt = "application/vnd.ms-powerpoint",
    pot = "application/vnd.ms-powerpoint",
    pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    potx = "application/vnd.openxmlformats-officedocument.presentationml.template",
    pdf = "application/pdf",
    pub = "application/x-mspublisher",

    jpg = "image/jpeg",
    jpeg = "image/jpeg",
    png = "image/png",
    bmp = "image/bmp",
    gif = "image/gif",
    tif = "image/tiff",
    tiff = "image/tiff",
    eps = "application/postscript",
    svg = "image/svg+xml",
}

export default FileExtEnum;
