import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import { CustomerContactDetails } from '@/generated-api/customer-details/api';
import template from './CustomerItem.vue';
import { ModalComponent } from "@/views/sharedcomponents/baseComponents/components";
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking"
import store from '@/store';

@Options({
    name: 'customer-item-component',
    mixins: [template],
    components: {
        ModalComponent
    },
    props:{
        data: Object,
        showEdit: Boolean,
        missingText: String
    }
})
export default class CustomerItem extends mixins(InsurancesCommon) {
    data: any;
    showEdit: boolean;
    missingText: string;

    contactDetail: any = null;

    public changeText: string = "";

    trackingUrl: string;

    beforeMount() {
        this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
    }

    // LIFECYCLE
    mounted() {
        this.contactDetail = {};
        //lav en kopi af oprindelig data så den ikke kan overskrives i formen
        //skal først overskrives når kundens stamdata er opdateret i salesforce
        this.contactDetail = Object.assign(this.contactDetail, this.data);
    }

    // COMPUTED
    get changeIntro() {
        return `Ret ${this.contactDetail.cleanDetail.toLowerCase()}`;
    }

    // METHODS
    saveEdit(contactDetails: CustomerContactDetails) {
        const modal = this.$refs['editModal'] as any;

        switch (contactDetails.type.toLowerCase()) {
            case "email__c":
                if (this.validateMail(contactDetails.detail)) {
                    modal.toggleModal();
                    this.$emit('masterDataChanged', contactDetails);
                }
                break;
            case "mobile__c":
                if (this.validatePhone(contactDetails.detail)) {
                    modal.toggleModal();
                    this.$emit('masterDataChanged', contactDetails);
                }
                break;
        }
    }

    validateMail(val: string): boolean {
        const regexMail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        let validateResult = false;
        if (regexMail.test(val.toLowerCase())) {
            validateResult = true;
        } else {
            this.changeText = "E-mailen er ugyldig.";
        }
        return validateResult;
    }

    validatePhone(val: string) {
        const regexPhone = new RegExp(/((\(?\+45\)?)?)(\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2})/);

        let validateResult = false;
        if (regexPhone.test(val)) {
            validateResult = true;
        } else {
            this.changeText = "Telefonnummeret er ugyldigt.";
        }
        return validateResult;
    }

    setInputMode(type: string) {
        if (type) {
            if (type.toLowerCase() === "e-mail") {
                return "email";
            }
            if (type.toLowerCase() === "telefon") {
                return "tel";
            }
        }
        return "text";
    }
}
