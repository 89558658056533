enum PaymentMethodEnum {
    AFREGN	= "Flådepolicer",
    AFV_KUND	= "Afv. udb. konto",
    CASE = "Skade: Case sendes ved forfald",
    CHECK = "Check udbetaling",
    DANKORT = "Opkrævet på dit betalingskort",
    DIBS = "Opkrævet på dit betalingskort",
    FI_ALMB = "Opkrævet på girokort",
    FI_BMÆGL = "Opkrævet på girokort",
    FI_MÆGL = "Opkrævet på girokort",
    FI_PBS = "Opkrævet på girokort",
    FI_PRÆM = "Opkrævet på girokort",
    FI_SKADE = "Opkrævet på girokort",
    HKCHECK = "HKcheck",
    INCASSO = "Gruppering af incasso items",
    INCA_PRO = "Gruppering af incasso items",
    NEMKONTO = "Konto til nemkonto udbetaling",
    PBS = "Opkrævet via betalingsservice",
    PBS_UDB = "Konto til konto udbetaling",
    PROVISIO = "Provisions payment metode",
    SAMLE = "Samler udbet. og indbet. pr. dag",
    UDB_CASE = "Case vedr. udbetaling",
    UDB_KTK = "Udbetaling til kontonr. - ERHVERV",
    UDB_NEM = "CPRNR til nem_udb - ERHVERV",
    UDPANTN = "Udpantning",
    VENTER = "Flådepolice afventer reguler."
}

export default PaymentMethodEnum;
