import { CreateOffer, ProductFeatures, Offer } from '@/generated-api/car-quote';
import BuyCar from './BuyCarComponent';
import {PACKAGES, ansvarFeatures, kaskoFeatures, superKaskoFeatures, ansvarDIBAFeatures, kaskoDIBAFeatures, superKaskoDIBAFeatures, productId} from './CarSettings';
import { BuyInsuranceHelper, Formatter, monthYearArray, ValuePair } from '../BuyInsuranceHelper';
import { cloneDeep } from 'lodash';
import { CustomerAgreementFeature} from '@/generated-api/customer-agreements';
import AbAxiosCalculators from '../AbAxiosCalculators';
import UrlHandler from '../UrlHandler';
// import AbAxiosSelfservice from '@/views/common/ab_axios_selfservice';
import FeatureAdapter from '@/views/model/adapter/FeatureAdapter';
import store from '@/store';
// import { getCarLicenseplateSearch } from '@/views/TestData/calculators/apiTestData';
// import CarTestCalculation from '@/views/TestData/calculators/CarCalculation.json';
// import { HtmlBlock } from '@/definitions/episerver/content-types';
import { defaultConfig } from '@/episerver/config';

export default class CarCalculator {
    // private abAxiosSelfserviceOpen: AbAxiosSelfservice; // access tia services
    private buyCar: BuyCar; // component using calculator
    private model: any; // the data structure for state and properties
    private cms: any; // settings from EPI
    private licensePlateSearhcUrl : string;
    private calcConfig: any;
    private tiaCoverages =  new Map<string, Array<FeatureAdapter>>();
    // used for first three calculations, to see if there's known error codes
    private succesCalc = true;
    private abAxiosCalculator: AbAxiosCalculators;
    private calculatorUrl: string;
    private calculatorInfo: Array<any>;


    constructor(buyCar: BuyCar) {
        this.buyCar = buyCar;
        this.model = buyCar.model;
        this.cms = buyCar.cms;
        this.calculatorInfo = JSON.parse(this.buyCar.calculatorInfoBlock.markup);
        //private baseUrl: string = '/insurance/car'
        // const headers = [{ name: 'quote-api', value: 'true'}];
        // this.abAxiosSelfserviceOpen = new AbAxiosSelfservice(store.getters.getConfigEpi.openServiceUrl + this.baseUrl,
        //     'application/json',
        //     store.getters.getConfigEpi.csrfToken,
        //     headers);
        this.calculatorUrl =  defaultConfig.baseUrl + store.getters.getConfigEpi.openServiceUrl + '/insurance/car/quotes';

        this.licensePlateSearhcUrl = defaultConfig.baseUrl + store.getters.getConfigEpi.openServiceUrl + 
            '/insurance/car/variants/by-registration-number/';
        this.abAxiosCalculator = new AbAxiosCalculators(this.calculatorUrl);
        this.setupTiaCoverages();
        this.setUpConfig(this.cms.createOnlineOffer);
    }

    public async setUpConfig(createOnlineOffer: boolean) {
        if (!this.calcConfig) {
            if (this.model.campaign.valid) {
                this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
            }
            
            const calculatorInfo = this.calculatorInfo.find(cfg => cfg.calculatorConfigId === this.cms.calculatorConfigId);
            
            this.calcConfig = calculatorInfo.insuranceConfiguration;
            this.cms.mapCoverages(calculatorInfo.textMappingsOuter);

        }
        this.model.calculation.abCalc = {
            excessIdDefault: this.calcConfig.excessIdDefault,
            packageIds: this.calcConfig.packageIds,
            packages: cloneDeep(this.calcConfig.packages),
            coveragesDetails: this.calcConfig.coverages,
        };

        this.setupExcessList();

        // map coverages to each package
        if (createOnlineOffer) {
            this.model.calculation.abCalc.packages.forEach(element => {
                element.coverages = this.tiaCoverages.get(element.name);
            });
        } else {
            this.model.calculation.abCalc.packages.forEach(pack => {
                // we can only extend, when Ansvar+ is handled explicit
                if (this.cms.extendCoverages) {
                    pack.coverages = [];
                    pack.includedCoverageIds.forEach(riskId => {
                        const coverage = this.calcConfig.coverages.find( x => riskId === x.riskId);
                        pack.coverages.push(coverage);
                    });
                    pack.expandedCoverages = [];
                    pack.includedCoveragesExpandedIds.forEach(riskId => {
                        const coverage = this.calcConfig.coverages.find( x => riskId === x.riskId);
                        pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
                    });
                } else {
                    pack.coverages = [];
                    pack.includedCoveragesExpandedIds.forEach(riskId => {
                        const coverage = this.calcConfig.coverages.find( x => riskId === x.riskId);
                        pack.coverages.push(coverage);
                    });
                }
            });
        }


        this.model.calculation.abCalc.packs = new Map<number, Object>();
        this.model.calculation.abCalc.excesses.forEach(excess => {
            const calcs = [];
            this.model.calculation.abCalc.packs.set(excess.id, calcs);
            this.model.calculation.abCalc.packages.forEach( (pack) => {
                calcs.push(this.buyCar.helper.reducePack(cloneDeep(pack)));
            });
        });
        this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'
        if(!this.model.carInfo.yearlyMileage) {
            this.model.carInfo.yearlyMileage = this.cms.kmForbrug[2]; // op til 15.000 km
        }

        this.mapCoveragesFromCalc(createOnlineOffer);
        this.updateCalcKeys();
    }

    // private getUTCDateString_(yyyy, mm, dd) {
    //     const date = this.getDate(yyyy, mm, dd);
    //     return this.getUTCDateString(date);
    // }

    private getDate(yyyy, mm, dd) {
        const date = new Date();
        date.setFullYear(yyyy);
        date.setMonth(parseInt(mm)-1);
        date.setDate(dd);
        return date;
    }

    private getUTCDateString(date?: Date) {
        if (!date) {
            date = new Date();
        }
        const diff = Math.abs(date.getTimezoneOffset())/60;
        let dateString = date.toISOString();
        // remove end'.xxxZ'
        dateString = dateString.substring(0, dateString.length - 5);
        // add local Time difference
        dateString += `+0${diff}:00`;
        return dateString;
    }

    private async setupTiaCoverages(): Promise<any> {
        return new Promise( (resolve) => {
            let featureAdapters: Array<FeatureAdapter> = [];
            if(this.model.campaign.valid && this.model.campaign.ID === 'diba') {
                ansvarDIBAFeatures.forEach( productFeature => {
                    featureAdapters.push(...this.getMappedTiaCoverage(productFeature));
                });
            } else {
                ansvarFeatures.forEach( productFeature => {
                    featureAdapters.push(...this.getMappedTiaCoverage(productFeature));
                });
            }
            this.tiaCoverages.set(PACKAGES.ANSVAR, cloneDeep(featureAdapters));

            featureAdapters = [];
            if(this.model.campaign.valid && this.model.campaign.ID === 'diba') {
                kaskoDIBAFeatures.forEach( productFeature => {
                    featureAdapters.push(...this.getMappedTiaCoverage(productFeature));
                });
                } else {
                kaskoFeatures.forEach( productFeature => {
                    featureAdapters.push(...this.getMappedTiaCoverage(productFeature));
                });

            }
            this.tiaCoverages.set(PACKAGES.KASKO, cloneDeep(featureAdapters));

            featureAdapters = [];
            if(this.model.campaign.valid && this.model.campaign.ID === 'diba') {
                superKaskoDIBAFeatures.forEach( productFeature => {
                    featureAdapters.push(...this.getMappedTiaCoverage(productFeature));
                });
                } else {
                superKaskoFeatures.forEach( productFeature => {
                    featureAdapters.push(...this.getMappedTiaCoverage(productFeature));
                });

            }
            this.tiaCoverages.set(PACKAGES.SUPER_KASKO, cloneDeep(featureAdapters));

            return resolve(true);
        });
    }

    private getMappedTiaCoverage(productFeature): Array<FeatureAdapter> {
        const featureAdapters: Array<FeatureAdapter> = [];
        let featureFound = false;
        this.cms.productInfo.products.features.forEach( feature => {
            if (feature.feature_id === productFeature.id ) {
                let customerAgreementFeature: CustomerAgreementFeature = {
                    description: this.cms.getCoverageDescription(feature.title, feature.description),
                    feature_id: feature.feature_id,
                    name: this.cms.getCoverageName(feature.title),
                    sub_features: [],
                }
                let featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
                featureAdapters.push(featureAdapter);
                featureFound = true;
                // insert sub_feature_ids around here
            }
        });
        // some features are listed as subfeatures in .../category/products. For now, they are elavated to features.
        if(!featureFound) {
            this.cms.productInfo.products.sub_features.forEach( subfeature => {
                if (subfeature.sub_feature_id === productFeature.id ) {
                    let customerAgreementFeature: CustomerAgreementFeature = {
                        description: this.cms.getCoverageDescription(subfeature.title, subfeature.description),
                        feature_id: subfeature.sub_feature_id,
                        name: this.cms.getCoverageName(subfeature.title),
                        //sub_features: [{name: 'test', description: 'test beskrivelse'}],
                        sub_features: [],
                    }
                    let featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
                    featureAdapters.push(featureAdapter);
                    featureFound = true;
                }
            });
        }
        if (!featureFound) {
            console.error('Could not find feature with id', productFeature.id);
        }
        return featureAdapters;
    }

    private mapCoveragesFromCalc(createOnlineOffer: boolean) {
        this.model.calculation.allCoverages = new Map<number, Map<number, Array<FeatureAdapter>>>();
        this.model.calculation.abCalc.packs.forEach( (calculations, ownRiscId) => {
            const coveragesForPackage = new Map<number, Array<FeatureAdapter>>();
            calculations.forEach((calc, packageInx) => {
                const featureAdapters: Array<FeatureAdapter> = [];
                if (createOnlineOffer) {
                    coveragesForPackage.set(packageInx, this.tiaCoverages.get(calc.name));
                } else {
                    calc.coverages.forEach((coverage) => {
                        let customerAgreementFeature: CustomerAgreementFeature = {
                            description: this.cms.getCoverageDescription(coverage.name, coverage.description),
                            feature_id: coverage.riskId + '',
                            name: this.cms.getCoverageName(coverage.name),
                            sub_features: [],
                            // optional_properties: undefined,
                            // excess: undefined,
                            // sum: undefined,
                        };
                        let featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
                        featureAdapters.push(featureAdapter);
                    });
                    coveragesForPackage.set(packageInx, featureAdapters);
                }
            });
            this.model.calculation.allCoverages.set(ownRiscId, coveragesForPackage);
        });
    }

    public setupExcessList() {
        this.model.calculation.abCalc.excesses = this.calcConfig.excessList;
        if (this.model.calculation.abCalc.excesses[0].id === this.model.calculation.abCalc.excessIdDefault) {
            // ensure default excess is last in list
            this.model.calculation.abCalc.excesses.reverse();
        }
        this.model.ownRisks = [];
        this.model.calculation.abCalc.excesses.forEach((excess) => {
            if (this.model.personInfo.customerAge >= excess.minCustomerAge) {
                const valuePair: ValuePair = {
                    value: excess.id,
                    displayValue: Formatter.format(excess.amount) + ' kr.',
                };
                this.model.ownRisks.push(valuePair);
            }
        });
    }

    private checkFirstPackagePrices(excessId: number) {
        let ok = true;
        const packages = this.model.calculation.abCalc.packs.get(excessId);
        packages.forEach(calc => {
            if (!calc.totalPrice) {
                ok = false;
            }
        });
        return ok;
    }

    private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
        this.succesCalc = true;

        const rebate = this.buyCar.helper.getDiscount();
        this.model.calculation.discount = rebate.discount;
        this.model.calculation.discountDisplay = rebate.discountDisplay;

// FuelType/VehicleGroup discount
        const special = BuyInsuranceHelper.handleSpecialCarDiscounts(this.buyCar);

        let promises = [];
        for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
            const packageId = this.model.calculation.abCalc.packageIds[index];
            promises.push(this.onePackageCalcEpi(monthly, excessId, packageId, special.specialDiscount));
        }
        await Promise.all(promises);
        return Promise.resolve(this.succesCalc);
    }

    public async searchLicenseplate(value) {
        // return await getCarLicenseplateSearch();

        const url = `${this.licensePlateSearhcUrl}${encodeURI(value)}`;
        return await this.abAxiosCalculator.get(url);
    }
    public async getCalculations(excessId?: number) : Promise<boolean> {
        if (!this.model.calculation.isCalculated) {
            this.model.showSpinner = true;
            this.model.calculating = true;
            // handle "eternal" spinning
            setTimeout(() => {
                if(this.model.showSpinner) {
                    this.model.showSpinner = false;
                    this.model.calculating = false;
                }
            }, this.cms.calculationSpinnerTimeout);
        }

        // if (this.cms.createOnlineOffer) {
            // use Tia rest
            // try {
            //     // reset calculation
            //     this.model.choosePackage.useTiaOffer = true;
            //     await this.setUpConfig(this.cms.createOnlineOffer);
            //     this.resetRetries();

            //     //const riskId = this.model.calculation.abCalc.excessIdDefault
            //     const riskId = this.model.choosePackage.ownRiskId
            //     const promises = [];
            //     promises.push(this.calculateQuickQuote(PACKAGES.ANSVAR, riskId, true));
            //     promises.push(this.calculateQuickQuote(PACKAGES.KASKO, riskId, true));
            //     promises.push(this.calculateQuickQuote(PACKAGES.SUPER_KASKO, riskId, true));
            //     await Promise.all(promises);
            //     this.model.calculation.isCalculated = true;
            //     this.model.showSpinner = false;
            //     this.model.calculating = false;

            //     const otherExcess = this.model.calculation.abCalc.excesses.find( x => x.id !== riskId);
            //     if (otherExcess) {
            //         this.calculateQuickQuote(PACKAGES.ANSVAR, otherExcess.id, true);
            //         this.calculateQuickQuote(PACKAGES.KASKO, otherExcess.id, true);
            //         this.calculateQuickQuote(PACKAGES.SUPER_KASKO, otherExcess.id, true);
            //     }
            //     this.buyCar.abGtm.triggerCustomGtmEvent({
            //         'event': 'track-vp',
            //         'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/prices_shown`,
            //     });

            //     return Promise.resolve(true);
            // } catch(err) {
            //     console.error('tia offer', err);
            //     this.buyCar.abGtm.triggerCustomGtmEvent({
            //         'event': 'track-vp',
            //         'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/error/tiaonlineoffer`,
            //     });

            //     const res = await this.getEpiCalculations();
            //     if (!res) {
            //         console.error(err);
            //         this.model.modal.id = 'calcError'
            //         this.model.modal.title = this.cms.defaultCalcErrorTitle;
            //         this.model.modal.content = this.cms.defaultCalcErrorContent;
            //         this.model.modal.btnSecondLabel = 'Ok';
            //         this.model.modal.track = true;
            //         this.model.modal.trackToken = 'error/oldcalculator',
            //         this.model.modal.show = true;
            //         this.buyCar.toggleModal();
            //     }
            //     this.model.showSpinner = false;
            //     this.model.calculating = false;
            //     return Promise.resolve(false);
            // }
        // } else {
            return this.getEpiCalculations();
        // }
    }

    private async getEpiCalculations(): Promise<boolean> {
        // reset calculation
        this.model.choosePackage.useTiaOffer = false;
        await this.setUpConfig(false);
        let success = true;

        const selectedExcessId = this.model.choosePackage.ownRiskId || this.model.calculation.abCalc.excessIdDefault;

        if (this.buyCar.helper.hasAddressSupplement()) {
            this.model.calculation.addressSupplement = this.cms.addressSupplement;
        } else {
            this.model.calculation.addressSupplement = 1;
        }
        // resetPrices
        const pack = this.model.calculation.abCalc.packs.get(selectedExcessId);
        pack.totalPriceDisplay = undefined;

        success = await this.calculatePackagesEpi(true, selectedExcessId);
        // check if has got prices
        if(!success) {
            success = this.checkFirstPackagePrices(selectedExcessId);
        }
        // get yearlyprices
        this.calculatePackagesEpi(false, selectedExcessId);

        this.model.calculation.isCalculated = true;
        this.model.showSpinner = false;
        this.model.calculating = false;
        return success;
    }

    private async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number, specialDiscount: number) {
        if (this.isSpecialCarOrExcludedBrand()) {
            this.succesCalc = false;
            return await this.specialCarModal();
        }
        const response = await this.abAxiosCalculator.getRetryDefaultUrl(this.createParamsEpi(excessId, packageId, monthly ? 1 : 0));
        
        if (response.status !== 200) {
            this.succesCalc = false;

            if (response?.quote_problem?.length > 0) {
                return await this.specialCarModal();
            }

            this.model.modal.title = 'Der er desværre sket en fejl';
            this.model.modal.id = 'calcError';
            this.model.modal.content = this.cms.defaultCalcErrorContent;
            this.model.modal.track = false;
            this.model.modal.btnSecondLabel = 'Ok';
            this.model.modal.id = 'calcWarning';
            this.model.modal.show = true;
            this.buyCar.toggleModal();
            return Promise.resolve(false);
        }

        const pack = this.model.calculation.abCalc.packs.get(excessId);
        //BuyInsuranceHelper.mapPriceResult(response); - doesnt work - car is different from other calculators
        this.mapPriceResultForCar(response);
        this.buyCar.helper.setPrices(packageId, response, monthly, pack, specialDiscount);
        this.updateCalcKeys();
        return Promise.resolve(true);
    }

    private mapPriceResultForCar(response) {
        const res = response.data;
        response.data.totalPrice = res.total;
        response.data.statutoryFee = res.detail.statutory_fee;
        response.data.rebatePrice = res.rebate_price;
    }

    private createParamsEpi( excessId: number, packageId: number, monthlyPrices: number ) {
        // move these outside
        const carOwnerYears = this.cms.carOwnerYears.indexOf(this.model.personInfo.carOwnerYears);
        let yearlyDrivingInKm = this.cms.kmForbrug.indexOf(this.model.carInfo.yearlyMileage);
        yearlyDrivingInKm = ((yearlyDrivingInKm * 5000) + 5000);
        const customerAge  = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
        let groupAgreement = 0;
        if (this.model.campaign.valid) {
            groupAgreement = this.cms.campaigns.get(this.model.campaign.ID).groupId;
        }
        const pack = this.model.calculation.abCalc.packages.find( pack => packageId === pack.id);

        // create params
        const params = {
            // kid: this.model.carInfo.kid,
            // car_weight: this.model.carInfo.kid ? this.model.carInfo.carWeight : undefined,

            annual_driving_exceed_in_km: yearlyDrivingInKm,
            claims_last_3_years: 0,
            plus_customer: 'PK0',
            customer_age: customerAge,
            excess_id: excessId,
            years_with_own_car: carOwnerYears,
            postal_code: this.model.personInfo.zipCode,
            payments_per_year: monthlyPrices === 1 ? 12 : 1,
            selected: pack.calculateRisks.toString(),
            brand: this.model.carInfo.brand,
            model: this.model.carInfo.model,
            variant_id: this.model.carInfo.variantId,
            group_agreement: groupAgreement > 0 ? groupAgreement : undefined,
        }
        return params;
    }

    private createParamsEpiSpecialCar() {
        const monthlyPrices = 1;
        const packageId = this.model.calculation.abCalc.packageIds[0]; // superkasko
        const excessId = this.model.calculation.abCalc.excessIdDefault;
        const pack = this.model.calculation.abCalc.packages.find( pack => packageId === pack.id);
        // console.log('pack.calculateRisks', pack.calculateRisks.toString());
        
        // create params
        const params = {
            years_with_own_car: 2,
            annual_driving_exceed_in_km: 15000,
            customer_age: 30,
            postal_code: 1656,
            plus_customer: 'PK0',
            claims_last_3_years: 0,
            variant_id: this.model.carInfo.variantId,
            model: this.model.carInfo.model,
            brand: this.model.carInfo.brand,
            excess_id: excessId,
            payments_per_year: monthlyPrices === 1 ? 12 : 1,
            selected: pack.calculateRisks.toString(),
        }
        
        return params;
    }

    // public async calculateQuickQuote(productType: string, excessId: number, monthly: boolean): Promise<any> {
    //     const model = this.model;
    //     const carOwnerYears = this.cms.carOwnerYears.indexOf(this.model.personInfo.carOwnerYears);
    //     const yearlyMileage = this.cms.kmForbrug.indexOf(model.carInfo.yearlyMileage) + 1; // Tia starter ved 1 og ikke 0
    //     const customerAge  = parseInt(model.personInfo.customerAge.trim().replace(/\D/g,''));
    //     let features: Array<ProductFeatures> = this.getFeatures(productType);
    //     if (!features) {
    //         return Promise.reject();
    //     }
    //     const createOffer: CreateOffer =
    //     {
    //         product: {
    //             product_name: productId,
    //             features,
    //             parameters: {
    //                 paymentFrequency: monthly ? '12' : '1',
    //                 kmAarligKoersel: yearlyMileage,
    //                 UniktId: model.carInfo.variantId,
    //                 customerAlder: customerAge,
    //                 postnr: model.personInfo.zipCode,
    //                 riskCodeAnsvar: excessId,
    //                 licenceplate: model.carInfo.licenseplate,

    //                 kmStandPrDato: this.getUTCDateString(), // no data
    //                 regdato: this.getUTCDateString(), // no data
    //                 antalAarKunde: 0,// no data
    //                 skadefriAar: carOwnerYears,
    //                 kmStand: '10000', // no data
    //             },
    //         }
    //     };
    //     if (productType !== PACKAGES.ANSVAR) {
    //         createOffer.product.parameters.riskCodeKasko = excessId;
    //     }
    //     try {
    //         const res = await this.abAxiosSelfserviceOpen.offerServiceLayer.createOffer(createOffer);
    //         const offer = res.data;
    //         this.model.calculation.abCalc.packs.get(excessId).forEach(calc => {
    //             if (calc.name === productType) {
    //                 calc.totalPrice = Math.round(offer.price.monthly * this.buyCar.model.calculation.discount);
    //                 calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';
    //                 calc.yearlyPriceTotal = Math.round(offer.price.annually * this.buyCar.model.calculation.discount);
    //                 calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
    //             }
    //         });
    //         this.updateCalcKeys();
    //         return Promise.resolve();
    //     } catch(err) {
    //         return Promise.reject(err);
    //     };

    // }

    // public async createQuote(productType: string) {
    //     const model = this.model;

    //     const excessId: number = model.choosePackage.ownRiskId;
    //     const yearlyMileage = this.cms.kmForbrug.indexOf(model.carInfo.yearlyMileage) + 1; // Tia starter ved 1 og ikke 0
    //     const customerAge  = parseInt(model.personInfo.customerAge.trim().replace(/\D/g,''));
    //     const carOwnerYears = this.cms.carOwnerYears.indexOf(this.model.personInfo.carOwnerYears);

    //     let skader = model.additionalInfo.skader;
    //     if (skader === '3+') {
    //         skader = '3';
    //     }
    //     let tidligereSelskab = '';
    //     if (model.additionalInfo.existInsurance === 'ja') {
    //         tidligereSelskab = model.additionalInfo.existingInsuranceId;
    //     }
    //     else if (model.additionalInfo.existFormerInsurance === 'ja') {
    //         tidligereSelskab = model.additionalInfo.formerInsuranceId;
    //     }

    //     let dateValidFrom: string;
    //     if (model.additionalInfo.validNow === 'ja') {
    //         dateValidFrom = this.getUTCDateString();
    //     } else {
    //         const res = model.additionalInfo.validFromDate.trim().match(/[\d]+/g);
    //         dateValidFrom = this.getUTCDateString_(res[2], res[1], res[0]);
    //     }

    //     let features: Array<any> = this.getFeatures(productType);
    //     if (!features) {
    //         return Promise.reject();
    //     }

    //     const quoteRequest: any =
    //     {
    //         policyholder : {
    //             cpr_number: model.contact_information.cpr.trim().replace(/\D/g,''),
    //             email: this.model.contact_information.email,
    //         },
    //         product: {
    //             id: productId,
    //             features,
    //             parameters: {
    //                 paymentFrequency: model.choosePackage.monthYear === 'M' ? '12' : '1',
    //                 kmAarligKoersel: yearlyMileage,
    //                 UniktId: model.carInfo.variantId,
    //                 customerAlder: customerAge,
    //                 postnr: model.personInfo.zipCode,
    //                 riskCodeAnsvar: excessId,
    //                 licenceplate: model.carInfo.licenseplate,
    //                 tidligereSelskab, // skal være et ID
    //                 skader_sidste_3_aar: skader,
    //                 faellesRegister: model.additionalInfo.rki === 'ja' ? 'Y' : 'N', //RKI/DFIM
    //                 regdato: this.getUTCDateString(), // no data
    //                 coverStartDate: dateValidFrom,
    //                 antalAarKunde: 1, // no data,
    //                 skadefriAar: carOwnerYears,
    //                 kmStand: '10000', // no data
    //                 kmStandPrDato: this.getUTCDateString(), // no data
    //             },
    //         },
    //         paymentInfo: {
    //             account : model.payment.kontoNo,
    //             regNo : model.payment.regNo,
    //         }
    //     };
    //     if (productType !== PACKAGES.ANSVAR) {
    //         quoteRequest.product.parameters.riskCodeKasko = excessId;
    //     }
    //     console.log('quoteRequest', quoteRequest);

    //     try {
    //         const res = await this.abAxiosSelfserviceOpen.quoteServiceLayer.createCarInsuranceQuote(quoteRequest);
    //         const quote = res.data;
    //         console.log('Quote created', quote);
    //         console.log(productType, excessId);
    //         const excess = this.model.calculation.abCalc.excesses.find( x => x.id === model.choosePackage.ownRiskId);
    //         // console.log('diff excess quote/quickQuote', quote. details.riskExcess.amount, excess.amount);
    //         // if (quote.price.annually) {
    //         //     console.log('quote annually', quote.price.annually.amount);
    //         //     console.log('quickQuote annually', this.model.choosePackage.selectedPackage.yearlyPriceTotal);
    //         // } else {
    //         //     console.log('quote monthly', quote.price.monthly.amount);
    //         //     console.log('quickQuote monthly', this.model.choosePackage.selectedPackage.totalPrice);
    //         // }
    //         return Promise.resolve();
    //     } catch(err) {
    //         return Promise.reject(err);
    //     }
    // }

    // private getFeatures(productType): Array<ProductFeatures> {
    //     switch(productType) {
    //         case PACKAGES.ANSVAR :  return cloneDeep(ansvarFeatures);
    //         case PACKAGES.KASKO : return cloneDeep(kaskoFeatures);
    //         case PACKAGES.SUPER_KASKO : return cloneDeep(superKaskoFeatures);
    //         default: console.warn('no package for calculation selected', productType);
    //             return undefined;
    //     }

    // }

    public async isSpecialCar(): Promise<boolean> {
        if (!this.model.carInfo.checkForSpecialCar) {
            this.model.carInfo.checkForSpecialCar = true;

            if (this.isSpecialCarOrExcludedBrand()) {
                return await this.specialCarModal();
            } 

            const response = await this.abAxiosCalculator.getRetryDefaultUrl(this.createParamsEpiSpecialCar());
            if (response.status === 400) {
                if (response?.quote_problem?.length > 0) {
                    return await this.specialCarModal();
                }
            }
        }
        return Promise.resolve(false);
    }

    private isSpecialCarOrExcludedBrand() {
        if (/*this.model.carInfo.special ||*/ this.cms.excludedVehicleGroups.includes(this.model.carInfo.vehicleGroup + '')) {
            return true;
        }
        const brand = this.model.carInfo.brand?.toLocaleLowerCase()?.trim();
        return this.cms.carBrandBlackList.includes(brand);
    }

    private async specialCarModal(): Promise<any> {
        await new Promise((res) => setTimeout(res, 500));
        this.model.modal.title = this.cms.specialCarTitle;
        this.model.modal.content = this.cms.specialCarContent;
        this.model.modal.track = true;
        this.model.modal.trackToken = `error/specialcar`;
        this.model.modal.btnSecondLabel = 'Ok';
        this.model.modal.id = 'calcWarning';
        this.model.modal.show = true;
        this.buyCar.toggleModal();
        return Promise.resolve(true);
    }

    public updateCalcKeys() {
        this.model.calculation.updatePrices = this.model.calculation.updatePrices.map( (elem) => {
            return elem += '1';
        });
    }

}
