import UserData from './modules/userData';
import { CatalogProductList } from '@/generated-api/catalog';
import { CustomerAgreement } from '@/generated-api/customer-agreements';
import { Claim } from '@/generated-api/customer-claims';
import { Cart } from '@/generated-api/cart';
import OrderflowContainerBlockCd from '@/views/contentApi/contentTypes/OrderflowContainerBlockCd';

export default {
    setName({ commit }: { commit: any }, name: string) {
        commit("SET_NAME", name);
    },

    setUserData({ commit }: { commit: any }, userData: UserData) {
        commit("SET_USER_DATA", userData);
    },

    setCustomer({ commit }: { commit: any }, customer: {}) {
        commit("SET_CUSTOMER", customer);
    },
    /*setBusinessCustomer({ commit }: { commit: any }, businessCustomer: {}) {
        return new Promise((resolve, reject) => {
            commit("SET_BUSINESS_CUSTOMER", businessCustomer);
            Vue.nextTick()
                .then(() => {
                    resolve();
                })
        })
    },*/
    setEpiRouting({ commit }: { commit: any }, epiRouting: []) {
        commit("SET_EPI_ROUTING", epiRouting);
    },
    setUsabillaCount({ commit }: { commit: any }) {
        commit("SET_USABILLA_COUNT");
    },
    setUsabillaMounted({ commit }: { commit: any }) {
        commit("SET_USABILLA_MOUNTED");
    },
    setAgreementList({ commit }: { commit: any }, customer: {}) {
        commit("SET_AGREEMENT_LIST", customer);
    },
    setFetchingAgreementList({ commit }: { commit: any }, isFetching: boolean) {
        commit("SET_FETCHING_AGREEMENT_LIST", isFetching);
    },
    setCustomerAgreement(
        { commit }: { commit: any },
        customerAgreement: CustomerAgreement
    ) {
        commit("SET_CUSTOMER_AGREEMENT", customerAgreement);
    },
    setProductList(
        { commit }: { commit: any },
        catalogProductList: CatalogProductList = {}
    ) {
        commit("SET_PRODUCT_LIST", catalogProductList);
    },
    setClaimList({ commit }: { commit: any }, claimList: Array<Claim> = []) {
        commit('SET_CLAIM_LIST', claimList);
    },
    setBillingList({ commit }: { commit: any }, billingList: Array<any> = []) {
        commit('SET_BILLING_LIST', billingList);
    },
    setInboxList({ commit }: { commit: any }, inboxList: Array<any> = []) {
        commit('SET_INBOX_LIST', inboxList);
    },
    setGlobalOrderflow({ commit }: { commit: any }, globalOrderflow: OrderflowContainerBlockCd) {
        commit('SET_GLOBAL_ORDERFLOW', globalOrderflow);
    },
    setNewPolicy({ commit }: { commit: any }, newPolicy: any) {
        commit("SET_NEW_POLICY", newPolicy);
    },
    setLastUserUpdatedAgreementId(
        { commit }: { commit: any },
        agreementId: string
    ) {
        commit("SET_LAST_USER_UPDATED_AGREEMENT_ID", agreementId);
    },
    setAppReplaceRoute({ commit }: { commit: any }, appReplaceRoute: boolean) {
        commit('SET_APP_REPLACE_ROUTE', appReplaceRoute);
    },
    setCart({ commit }: { commit: any }, cart: Cart) {
        commit('SET_CART', cart);
    },
    setBuyPolicy({ commit }: { commit: any }, policy: any) {
        commit('SET_BUY_POLICY', policy);
    },
    resetBuyPolicy({ commit }: { commit: any }, title: string) {
        commit('RESET_BUY_POLICY', title);
    },
    /*setBrokerCustomerList({ commit }: { commit: any }, brokerCustomerList: Array<UserInfoCustomerList> = []) {
        commit('SET_BROKER_CUSTOMER_LIST', brokerCustomerList);
    },*/
    setConsentSetInApp({ commit }: { commit: any }, consent: boolean) {
        commit('SET_CONSENT_SET_IN_APP', consent);
    },
    setOldAppVersionAlerted({ commit }: { commit: any }, consent: boolean) {
        commit('SET_OLD_APP_VERSION_ALERTED', consent);
    },
    toggleModal({ commit }: { commit: any }, modal: {id: string, isActive: boolean}) {
        commit('TOGGLE_MODAL', modal);
    },
    setRecaptchaSiteKey({ commit }: { commit: any }, recaptchaSiteKey: string) {
        commit('RECAPTHCA_SITE_KEY', recaptchaSiteKey);
    },

};
