<template>
    <div class="card card--base" :class="[contentItem.model.cardHeight, contentItem.model.inactivate ? 'disabled ' : '', contentItem.model.textCenter ? 'text-center' : '']"
            :style="contentItem.getStyles()">
        <img v-if="contentItem.model.image" class="card__image" :src="contentItem.model.image" alt="" />
        <div class="card__body">
            <h2 v-if="contentItem.model.headlineUnderline" class="h1 h1-underline h1-small">{{contentItem.model.title}}
                <!-- <router-link v-if="!isExternalLink()" :to="contentItem.model.routePath" :title="contentItem.model.title" class="m-0">{{contentItem.model.title}}</router-link>
                <a v-else :href="contentItem.model.routePath" target="_blank">{{contentItem.model.title}}</a> -->
            </h2>
            <h2 v-else-if="!contentItem.model.inactivate" class="card__headline">
                <router-link v-if="!hasExternalLink && contentItem.model.routePath" :class="contentItem.model.buttonStyle" :to="contentItem.model.routePath" :title="contentItem.model.title" class="m-0">{{contentItem.model.title}}</router-link>
                <button v-else-if="hasMobilbankLink && isMobile && contentItem.model.routePath" class="m-0" :class="contentItem.model.buttonStyle" @click="openApp()">{{contentItem.model.title}}</button>
                <a v-else class="m-0" :class="contentItem.model.buttonStyle" :href="contentItem.model.routePath" target="_blank">{{contentItem.model.title}}</a>
            </h2>
            <h2 v-else class="card__headline">{{contentItem.model.title}}</h2>

            <div class="card__text" v-html="contentItem.model.text"></div>
            <span v-if="!contentItem.model.inactivate">
                <router-link v-if="!hasExternalLink && contentItem.model.routePath" class="m-0" :class="contentItem.model.buttonStyle" :to="contentItem.model.routePath" :title="contentItem.model.linkText">{{contentItem.model.linkText}}</router-link>
                <button v-else-if="hasMobilbankLink && isMobile && contentItem.model.routePath" class="m-0" :class="contentItem.model.buttonStyle" @click="openApp()">{{contentItem.model.linkText}}</button>
                <a v-else class="m-0" :class="contentItem.model.buttonStyle" :href="contentItem.model.routePath" target="_blank">{{contentItem.model.linkText}}</a>
            </span>
            <span v-else>{{contentItem.model.linkText}}</span>
        </div>
    </div>
</template>
