import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src", "width", "height"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(
        ['info-text', 
        {
        'info-text__theme-danger':_ctx.model.theme==='danger', 
        'info-text__theme-light':_ctx.model.theme==='light', 
        'info-text__display_none': _ctx.textblockRemoved,
        'info-text-link': _ctx.model.link.url !== undefined
        }
        ])
  }, [
    (_ctx.model.showCloseButton === true)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "info-text__close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
        }, "x"))
      : _createCommentVNode("", true),
    (_ctx.model.image?.url)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(
        ['info-text__icon-content', 
        { 
            'info-text__icon-content__align-icon-right': _ctx.model.imagePosition ==='hoejre', 
            'info-text__icon-content__align-icon-center': _ctx.model.imagePosition ==='center', 
            'info-text__icon-content__padding-remove': _ctx.model.removePadding === true
        }
        ])
        }, [
          (_ctx.model.image?.url)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: _normalizeClass({'info-text__icon-content--img': !_ctx.model.removeScreenScale}),
                src: _ctx.model.image.url,
                width: (_ctx.model.imageWidth > 0)?_ctx.model.imageWidth:'',
                height: (_ctx.model.imageHeight > 0)?_ctx.model.imageHeight:''
              }, null, 10, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(
            ['info-text__icon-content__content', 
            { 
                'info-text__icon-content__align-content-right': _ctx.model.imagePosition ==='hoejre',
                'info-text__icon-content__align-content-center': _ctx.model.imagePosition ==='center',
                'info-text__icon-content__align-text-right': _ctx.model.textPosition ==='hoejre',
                'info-text__icon-content__align-text-center': _ctx.model.textPosition ==='center',
            }
            ]),
            innerHTML: _ctx.model.text
          }, null, 10, _hoisted_2)
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(
        ['info-text__content',
        {
            'info-text__icon-content__align-text-right': _ctx.model.textPosition ==='hoejre',
            'info-text__icon-content__align-text-center': _ctx.model.textPosition ==='center',
        }
        ]),
          innerHTML: _ctx.model.text
        }, null, 10, _hoisted_3))
  ], 2))
}