<template>

    <div class="row justify-content-center">

        <div class="col-12 no-gutters text-left" v-for="(item, index) in coverages" :key="item.Id">


            <hr class="abOverview__hr" v-if="index === (coverages.length-1)">

            <toggleline-component :id="item.id"
                                  :description="item.description"
                                  :name="item.title"
                                  :postUiState="true"
                                  :disabled="true"
                                  :selected="true"
                                  :hideArrow="index === (coverages.length-1)"
                                  :disabledText="formatPrice(item.price) + ' kr. / år'"
                                  :subHeaders="item.subheaders"
                                  >
            </toggleline-component>
            <!--
                  :priceFormatted="formatPrice(item.price)"
                  :monthlyPrice="false"
                  :showPrice="true"

            -->


        </div>

    </div>

</template>

<style scoped lang="scss">

    .abToggleLine__description{
        margin-left: 0px;
    }

</style>
