// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Insurance Accident Quote API
 * Enables retrieval of price offers and creation/activation of price quotes for accident insurance products
 *
 * OpenAPI spec version: 1.0.0
 * Contact: api@almbrand.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "http://localhost/insurance/accident".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * The offer request with enough information to calculate an offer price for a product. see '/products' resource for a specification of product parameter and features.
 * @export
 * @interface CreateOffer
 */
export interface CreateOffer {
    /**
     * 
     * @type {Product}
     * @memberof CreateOffer
     */
    product?: Product;
}

/**
 * The calculated offer with price and coverage information.
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {OfferPrice}
     * @memberof Offer
     */
    price?: OfferPrice;
    /**
     * 
     * @type {Product}
     * @memberof Offer
     */
    product?: Product;
    /**
     * 
     * @type {Array<OfferPolicy>}
     * @memberof Offer
     */
    policies?: Array<OfferPolicy>;
    /**
     * 
     * @type {PrivacyPolicy}
     * @memberof Offer
     */
    privacy?: PrivacyPolicy;
    /**
     * The date and time where the offer was retrieved
     * @type {Date}
     * @memberof Offer
     */
    created_at?: Date;
    /**
     * The date and time where the offer will expire
     * @type {Date}
     * @memberof Offer
     */
    expires_at?: Date;
}

/**
 * Policy information for the offer.
 * @export
 * @interface OfferPolicy
 */
export interface OfferPolicy {
    /**
     * Href to the Insurance Product Information Document.
     * @type {string}
     * @memberof OfferPolicy
     */
    ipid_href?: string;
    /**
     * 
     * @type {Array<PolicyCoverage>}
     * @memberof OfferPolicy
     */
    //coverages?: Array<PolicyCoverage>;
    coverages?: Array<any>;
}

/**
 * The offer price with annual and monthly price.
 * @export
 * @interface OfferPrice
 */
export interface OfferPrice {
    /**
     * Tthe annually price.
     * @type {number}
     * @memberof OfferPrice
     */
    annually?: number;
    /**
     * The monthly price.
     * @type {number}
     * @memberof OfferPrice
     */
    monthly?: number;
    /**
     * The currency.
     * @type {string}
     * @memberof OfferPrice
     */
    currency?: string;
}

/**
 * 
 * @export
 * @interface Party
 */
export interface Party {
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    user_id?: string;
    /**
     * 
     * @type {Person}
     * @memberof Party
     */
    //person?: Person;
    person?: any;
    /**
     * 
     * @type {Organization}
     * @memberof Party
     */
    //organization?: Organization;
    organization?: any;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    phone?: string;
    /**
     * 
     * @type {Address}
     * @memberof Party
     */
    //address?: Address;
    ddress?: any;
    /**
     * 
     * @type {PartyStatements}
     * @memberof Party
     */
    //statements?: PartyStatements;
    statements?: any;
}

/**
 * 
 * @export
 * @interface PrivacyPolicy
 */
export interface PrivacyPolicy {
    /**
     * Href to alm brand data privacy policy (persondata politik).
     * @type {string}
     * @memberof PrivacyPolicy
     */
    policy_href?: string;
}

/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    [key: string]: any | any;

    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof Problem
     */
    type?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof Problem
     */
    title?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof Problem
     */
    status?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof Problem
     */
    detail?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof Problem
     */
    instance?: string;
}

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Unique identification of a product
     * @type {string}
     * @memberof Product
     */
    product_name?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Product
     */
    parameters?: { [key: string]: any; };
    /**
     * 
     * @type {Array<ProductFeatures>}
     * @memberof Product
     */
    features?: Array<ProductFeatures>;
}

/**
 * 
 * @export
 * @interface ProductFeatures
 */
export interface ProductFeatures {
    /**
     * 
     * @type {string}
     * @memberof ProductFeatures
     */
    name?: string;
}


/**
 * InsuranceOfferApi - axios parameter creator
 * @export
 */
export const InsuranceOfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns price offer on an accident insurance to unnamed customer. The offer is binding for Alm Brand for the given parameters and features, but expires after a short period of time (hours).
         * @summary Get price offer on accident insurance .
         * @param {CreateOffer} CreateOffer Information needed to get an offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(CreateOffer: CreateOffer, options: any = {}): RequestArgs {
            // verify required parameter 'CreateOffer' is not null or undefined
            if (CreateOffer === null || CreateOffer === undefined) {
                throw new RequiredError('CreateOffer','Required parameter CreateOffer was null or undefined when calling createOffer.');
            }
            const localVarPath = `/offers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateOffer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(CreateOffer || {}) : (CreateOffer || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceOfferApi - functional programming interface
 * @export
 */
export const InsuranceOfferApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns price offer on an accident insurance to unnamed customer. The offer is binding for Alm Brand for the given parameters and features, but expires after a short period of time (hours).
         * @summary Get price offer on accident insurance .
         * @param {CreateOffer} CreateOffer Information needed to get an offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(CreateOffer: CreateOffer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer> {
            const localVarAxiosArgs = InsuranceOfferApiAxiosParamCreator(configuration).createOffer(CreateOffer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * InsuranceOfferApi - factory interface
 * @export
 */
export const InsuranceOfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Returns price offer on an accident insurance to unnamed customer. The offer is binding for Alm Brand for the given parameters and features, but expires after a short period of time (hours).
         * @summary Get price offer on accident insurance .
         * @param {CreateOffer} CreateOffer Information needed to get an offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(CreateOffer: CreateOffer, options?: any) {
            return InsuranceOfferApiFp(configuration).createOffer(CreateOffer, options)(axios, basePath);
        },
    };
};

/**
 * InsuranceOfferApi - object-oriented interface
 * @export
 * @class InsuranceOfferApi
 * @extends {BaseAPI}
 */
export class InsuranceOfferApi extends BaseAPI {
    /**
     * Returns price offer on an accident insurance to unnamed customer. The offer is binding for Alm Brand for the given parameters and features, but expires after a short period of time (hours).
     * @summary Get price offer on accident insurance .
     * @param {CreateOffer} CreateOffer Information needed to get an offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceOfferApi
     */
    public createOffer(CreateOffer: CreateOffer, options?: any) {
        return InsuranceOfferApiFp(this.configuration).createOffer(CreateOffer, options)(this.axios, this.basePath);
    }

}

