import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import template from './CustomerConsents.vue';
import { ToggleComponent } from '@/views/sharedcomponents/baseComponents/components';

@Options({
    name: 'customer-consents-component',
    mixins: [template],
    components: {
        ToggleComponent
    },
    props:{
        data: Object
    }
})
export default class CustomerConsents extends mixins(InsurancesCommon) {
    data: any;
    consentData: any = null;

    // LIFECYCLE 
    mounted() {
        this.consentData = this.data;
    }

    //catch emit
    ToggleComponentChanged(toggleData: any){
        this.consentData.checked = toggleData.value;
        this.consentChecked();
    }

    consentChecked() {
        this.$emit('consentChanged', this.consentData);
    }
}
