import { Vue, Options, mixins } from "vue-class-component";
import { ContentBlockType } from "@/views/contentApi/contentTypes/ContentBlockType";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import AgreementStatus from '@/views/model/AgreementStatus';
import ProductlineHeader from './productlineheader';
import FlowHandler from "@/views/providers/FlowHandler";
import BundleAdapter from '@/views/model/adapter/BundleAdapter';
import FeatureAdapter from "@/views/model/adapter/FeatureAdapter";
import AgreementLineAdapter from '@/views/model/adapter/AgreementLineAdapter';
import PageTitle from '@/views/overview/_partials/pageTitle';
import { CustomerAgreementBundle } from "@/generated-api/customer-agreements";
import Orderflow from '../orderflow/OrderFlow.vue'
import CoveragesBlockCd, { CoveragesBlockType } from "@/views/contentApi/contentTypes/CoveragesBlockCd";
import ContentItem from "@/views/contentApi/contentTypes/ContentItemCd";
import CoveragesBlockComponent from '@/views/contentApi/components/CoveragesBlockComponent';
import PolicyDownloadBlockComponent from '@/views/contentApi/components/PolicyDownloadBlockComponent';
import DefaultComponents from '@/views/overview/_partials/defaultComponents'
import _ from "lodash";
import UsabillaInPageBlockComponent from "@/views/contentApi/components/UsabillaInPageBlockComponent";
import InsurancesProductEnum from "@/views/model/InsurancesProductEnum";
import GlobalContentHandler from "@/views/providers/GlobalContentHandler";
import { SkeletonLoaderComponent } from '@/views/sharedcomponents/baseComponents/components';
import Private from '@/views/layouts/private';
import { Log } from "@/episerver/logging/log";
import Menu from "@/views/overview/_partials/menu";
import Dialogcomponent from '@/views/sharedcomponents/baseComponents/components/dialog/Dialog.vue';
import store from '@/store';
import { SelfServiceProductPage } from "@/definitions/episerver/content-types";
import ContentItemCd from "@/views/contentApi/contentTypes/ContentItemCd";
import { MODEL_LOADED } from "@/episerver/store/modules/epiContent";
import ContentBlockCreator from "@/views/contentApi/ContentBlockCreator";
import PolicyDownloadBlockCd from "@/views/contentApi/contentTypes/PolicyDownloadBlockCd";
import { UrlUtil } from "@/episerver/api/urlUtil";
import OrderflowContainerBlockCd from "@/views/contentApi/contentTypes/OrderflowContainerBlockCd";
import { nextTick, ref } from "vue";
import { computed } from 'vue'
import { useRoute, useRouter } from "vue-router";

@Options({
    name: 'selfservice-insurances-productline-component',
    components: {
        SkeletonLoaderComponent,
        'productline-header-component': ProductlineHeader,
        'menu-component': Menu,
        'page-title-component': PageTitle,
        'orderflow-component': Orderflow,
        'coverages-block-component': CoveragesBlockComponent,
        'shared-components': DefaultComponents,
        'policy-download-block-component': PolicyDownloadBlockComponent,
        'dialog-component': Dialogcomponent,
    }
})

export default class Productline extends mixins(InsurancesCommon) {
    public model: SelfServiceProductPage;

    productDetails: AgreementLineAdapter = null;
    public productlineMissingList: Array<FeatureAdapter> = [];
    public productlineIncludedList: Array<FeatureAdapter> = [];
    daekkerIkkeCoverages: Array<string> = [];
    daekkerCoverages: Array<string> = [];
    public contentBlocks: Array<ContentItemCd> = [];
    public orderflowContainerBlocks: Array<OrderflowContainerBlockCd> = [];
    public headerContentBlocks: Array<ContentItemCd> = [];
    contentBlocksLoaded: boolean = false;
    hasCoverages: boolean = false;
    public flowHandler: FlowHandler = null;
    public dialog: any = {};
    public agreementStatus = AgreementStatus;
    public agreementEndDateFormatted: string;
    private logProperties = { SourceContext: "Productline", context: "spa" };
    public productName: string;
    dialogComponent: any;

    async beforeMount() {
        try {
            setTimeout(() => {
                if (!store.state.epiContent.modelLoaded) {
                    const unsubscribe = store.subscribeAction((action, state) => {
                        if (action.type === MODEL_LOADED) {
                            this.model = store.state.epiContent.model as SelfServiceProductPage;
                            this.init();
                            unsubscribe();
                        }
                    });
                } else {
                    this.model = store.state.epiContent.model as SelfServiceProductPage;
                    this.init();
                }
            }, 1);

        } catch (error) {
            console.error(error);
                        
        }
}

    private async init() {
        try {
            await this.getProductLineViaRest();
            try {
                if (this.model.mainArea?.length > 0) {
                    this.setContentBlocks();
                    this.hasCoverages = this.contentBlocks.some((item) => this.isCoveragesBlock(item));
                    //handle policy and condition PDF block
                    const hasPolicyDownloadblock = this.contentBlocks.some((item) => this.isPolicyDownloadBlock(item));
                    if (!hasPolicyDownloadblock) {
                        this.contentBlocks = this.contentBlocks.concat(PolicyDownloadBlockCd.createDefaults());
                    }
                    await this.handleKasko();
                }
                this.contentBlocksLoaded = true;
                this.handleProductLine();
                UsabillaInPageBlockComponent.reloadUsabillas();

            } catch(err) {
                console.error(err);
                this.handleProductLine();
            }
        } catch (error) {
            Log.exception("Exception in Productline", error as any, { ...this.logProperties, method: "beforeMount" });
        }

    }

    get cookieBanner() {
        return this.$root['cookieBanner'];
    }

    showDocuments(status: string) {
        return status.toLowerCase() === "i kraft";
    }

    updated() {
        // console.log('replacing usabillas');
        UsabillaInPageBlockComponent.reloadUsabillas();
    }

    private async getProductLineViaRest(): Promise<any> {
        try {
            const product = await this.getCustomerAgreementById(this.$route.query.id, this.$route.query.objectid);
            this.productName = product.title.toLocaleLowerCase();

            if (this.hasPolicyChangedInSession(product)) {
                // old policy: user is using browser back to get to old(changed) policy - flush cache and redirect to newest policy
                store.dispatch('setCustomerAgreement', undefined);
                const newPolicy = store.getters.getNewPolicy(product.agreement_id);
                const url = UrlUtil.ensureCorrectLink(`/forsikring/mineforsikringer/${this.productName}?id=${newPolicy.agreement_line_seq_no}`);
                this.$router.replace(url);
                return Promise.reject();
            }

            this.agreementEndDateFormatted = this.utils.formatDate(product.end_date);
            this.productDetails = new AgreementLineAdapter(product);
            return await product;
        }
        catch (err) {
            this.handleApiServiceError(err, "Produkt detaljer kunne ikke hentes. Noget gik galt.. ");
        }
    }

    private handleProductLine(): void {

        const includedFeatures = this.productDetails.agreement.product_instance.price.included_features.sort();
        let productline: BundleAdapter;
        if (includedFeatures && includedFeatures.length > 0) {
            productline = new BundleAdapter({ id: -1 } as CustomerAgreementBundle);
        }

        const dataBlocks = this.contentBlocks.filter( block => block.isType(ContentBlockType.CoveragesBlock));
        dataBlocks.forEach((dataBlock) => {
            if (dataBlock.model.dataPlaceholder === CoveragesBlockType.DaekkerIkke) {
                this.daekkerIkkeCoverages = (dataBlock as CoveragesBlockCd).daekkerIkkeCoverages;
            } else if (dataBlock.model.dataPlaceholder === CoveragesBlockType.Daekker) {
                this.daekkerCoverages = (dataBlock as CoveragesBlockCd).daekkerCoverages;
            }
        });

        // add features outside bundle(s)
        // mark as included
        if (productline !== undefined) {
            // add features outside bundles
            for (let feature of includedFeatures) {
                productline.featureList.push(new FeatureAdapter(feature));
            }
            // mark as included
            productline.featureList.forEach((feature, index) => {
                feature.included = true;
                if (this.daekkerCoverages.includes(feature.title.toLowerCase())) {
                    this.daekkerCoverages.forEach((item, i) => {
                        if (item === feature.title.toLowerCase()) {
                            feature.sortIndex = i;
                        }
                    });
                } else {
                    feature.sortIndex = index + this.daekkerCoverages.length;
                }
            });
            //sort the list
            this.productlineIncludedList = _.sortBy(productline.featureList, (o: { sortIndex: any; }) => o.sortIndex) as any; 

        }

        if (this.productDetails.agreement.product_instance !== undefined) {
            for (let feature of this.productDetails.agreement.product_instance.price.optional_features) {
                if (feature.name !== undefined && this.daekkerIkkeCoverages.includes(feature.name.toLowerCase())) {
                    this.productlineMissingList.push(new FeatureAdapter(feature));
                } else {
                    this.utils.log('Coverages', feature.name, '- ignored coverage')
                }
            }
            this.productlineMissingList.forEach((feature, index) => {
                this.daekkerIkkeCoverages.forEach((item, i) => {
                    if (item === feature.title.toLowerCase()) {
                        feature.sortIndex = i;
                    }
                });
            });

            this.productlineMissingList = _.sortBy(this.productlineMissingList, (o: { sortIndex: any; }) => o.sortIndex) as any;
            // console.log('this.productlineMissingList', this.productlineMissingList);
            // console.log('this.productlineIncludedList', this.productlineIncludedList);
        }
    }

    public openOrderflow(data: any) {
        this.flowHandler = data.flowHandler;
        nextTick(() => { // wait for mount
            (this.$refs.orderflowComponent as any).openOrderflow();
        });
    }

    public closeOrderflow() {
        this.flowHandler = null;
        (this.$refs.orderflowComponent as any).closeOrderflowDialog();
    }
    public getCovered(isCovered: boolean): Array<any> {
        return isCovered ? this.productlineIncludedList : this.productlineMissingList
    }

    public updateCoverages(data: any) {
        if (data.data) {
            if (data.includedCoverage) {
                // toggle af europa- verden
                if (data.data.id === 'coverage_Eu_world') {
                    if (!this.productlineIncludedList || this.productlineIncludedList.length === 0) {
                        // No included coverages, don't do anything
                        return;
                        // this.productlineIncludedList.push(data.data);
                    }
                    else if (this.productlineIncludedList[data.position].id === data.data.id) {
                        // replace coverage
                        this.productlineIncludedList.splice(data.position, 1, data.data);
                    } else {
                        // add coverage to position of list
                        if (this.productlineIncludedList[0].title.toLocaleLowerCase() === 'rejseforsikring basis') { // insert after rejseforsikring basis
                            this.productlineIncludedList.splice(1, 0, data.data);
                        } else { // insert at position from coverage
                            this.productlineIncludedList.splice(data.position, 0, data.data);
                        }

                    }
                } else {
                    // almindeligt fravalg
                    const index = this.productlineIncludedList.findIndex((feature) => feature.title === data.data.title);

                    if (index > -1) {
                        Object.assign(this.productlineIncludedList[index], data.data);
                    } // else ok - the coverage is not in the included list
                    // console.log('this.productlineIncludedList[index]', this.productlineIncludedList[index]);

                }
            } else {
                // almindeligt tilvalg
                const index = this.productlineMissingList.findIndex((feature) => feature.title === data.data.title);
                if (index > -1) {
                    Object.assign(this.productlineMissingList[index], data.data);
                } // else ok - the coverage is not in the missing list
            }
        }
    }


    public getCoveragesType(contentItem: CoveragesBlockCd): Array<any> {
        if (this.productDetails.agreement.status === this.agreementStatus.AFVENTER || this.productDetails.agreement.status === this.agreementStatus.NY_AENDRING) {
            return [];
        }

        return contentItem.model.dataPlaceholder === CoveragesBlockType.Daekker ?
            this.productlineIncludedList : this.productlineMissingList;
    }

    public isHtmlBlock(contentItem: ContentItem): boolean {
        return contentItem.isType(ContentBlockType.HtmlBlock);
    }

    public isCoveragesBlock(contentItem: ContentItem): boolean {
        return contentItem.isType(ContentBlockType.CoveragesBlock);
    }

    public isPolicyDownloadBlock(contentItem: ContentItem): boolean {
        return contentItem.isType(ContentBlockType.PolicyDownloadBlock);
    }

    private async handleKasko(): Promise<any> {
        if (this.productDetails.title.toLocaleLowerCase().indexOf(InsurancesProductEnum.BIL_FORSIKRING) === -1) { // ikke bilforsikring -> return
            return Promise.resolve();
        }
        const userData = store.getters.getUserData;
        const feature = this.productDetails.agreement.product_instance.price.included_features.find(feature => feature.name === 'Kasko');
        userData.bilKasko = feature !== undefined;
        return await store.dispatch('setUserData', userData);
    }

    private setContentBlocks() {
        let orderFlows = [];
        
        let orderflowItems: Array<any> = (this.model?.orderFlows as any)?.items;
        if (orderflowItems?.length > 0) {
            orderflowItems.forEach(element => {
                if (element.content) {
                    orderFlows.push(element.content);
                }
            });
        }

        if (orderFlows.length > 0){
            this.orderflowContainerBlocks = ContentBlockCreator.getBlocks(orderFlows);
        }
        this.contentBlocks = ContentBlockCreator.getBlocks(this.model.mainArea);
    }


    public alertToggle(data: any) {
        this.dialog = Object.assign({}, data);
        if (this.dialog.show) {
            this.$nextTick(() => {
                this.dialogComponent.openModal();
            });
        } else {
            this.dialogComponent.closeModal();
        }
    }

}
