
    import { Vue, Options, mixins } from "vue-class-component";
    import { DownloadFile } from './downloadFile';
    import SubHeader from './subHeader';

    @Options({
        name: 'toggleline-simple-component',
        props: { 
             id: Number,
             name: String,
             description: String,
             type: String,
             price: Number,
             isHidden: Boolean,
             priceFormatted: String,
             optional: Boolean,
             excluded: Boolean,
             included: Boolean,
             showPrice: Boolean,
             showSwitch: Boolean,
             selected: Boolean,
             disabledText: String,
             disabled: Boolean,
             index: Number,
             postUiState: Boolean,
             monthlyPrice: Boolean,
             hideArrow: Boolean,
             useSpacing: Boolean,
             downloadFile: Object,
             disableSwitch: Boolean,
             subHeaders: Array
        }
    })
    export default class ToggleLine extends Vue {

         id: number;
         name: string;
         description: string;
         type: string;
         price: number;
         isHidden: boolean;
         priceFormatted: string;
         optional: boolean;
         excluded: boolean;
         included: boolean;
         showPrice: boolean;
         showSwitch: boolean;
         selected: boolean;
         disabledText: string;
         disabled: boolean;
         index: number;
         postUiState: boolean;
         monthlyPrice: boolean;
         hideArrow: boolean;
         useSpacing: boolean;
         downloadFile: DownloadFile;
         disableSwitch: boolean;
         subHeaders: Array<SubHeader>;


        /**
         * Toggling description text visibility
         * @param e
         */
        public toggleDescription(e): void {
            const arrowIcon: HTMLElement = this.$el.querySelector('#arrowIcon');
            if (arrowIcon !== null) {
                arrowIcon.classList.contains('toggle-down') === true ? arrowIcon.classList.remove('toggle-down') : arrowIcon.classList.add('toggle-down');
            }
            const descriptionElm: HTMLElement = this.$el.querySelector('#abToggleLineDesc');
            descriptionElm.classList.contains('hiddenAni') === true ? descriptionElm.classList.remove('hiddenAni') : descriptionElm.classList.add('hiddenAni');
        }

        /**
         * Emitting a change when switch checked value changes
         * @param e
         */
        public switchToggleChanged(e): void {
            const elm: HTMLInputElement = e.target;

            const data = {
                index: this.index,
                id: this.id,
                type: this.type,
                checkedValue: elm.checked
            };

            //console.log(data);
            this.$emit('switchToggleChange', data);
        }

        mounted() {
            //let subHeaderBody = document.querySelector(".abToggleLine__description");
            //subHeaderBody.classList.add("style", "margin-right: 2px !important;");
        }
    }

