import { mapState } from 'vuex';
import { SelectModalComponent} from '@/views/sharedcomponents/baseComponents/components';
import InputComponent from '../../components/InputComponent.vue';
import FamilyMemberComponent from '../../components/FamilyMemberComponent.vue';
import { FamilySettings } from '../../family/FamilySettings';
import { Options, Vue } from 'vue-class-component';
import { nextTick, PropType } from 'vue';
import { CardBlock } from '@/definitions/episerver/content-types';


@Options({
    name: 'FamilyInfoStepComponent',
    props: {
        card: Object as PropType<CardBlock>, // Card from cms
        isValid: Boolean, // wether card is complete
    },
    components: {
        SelectModalComponent,
        InputComponent,
        FamilyMemberComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
})

export default class FamilyInfoStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // wether card is complete

    public model!: any; // datastructure for user input (store)
    public cms!: FamilySettings; // settings from CMS (store)

    public created() {
        window.addEventListener('keyup' , this.moveOn );
    }

    public mounted() {
        if (this.cms.useAdultAccident) {
            this.model.familyInfo.personCount = this.model.personInfo.familyMemberCount;
            this.model.familyInfo.personCountId = this.model.personInfo.familyMemberCountId - 1;

            setTimeout(() => {
                const input = document.getElementById('name0');
                if (input) {
                    input.focus();
                }
            }, this.cms.animationTimeout);

        }
        this.checkPersonCount();
    }

    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    public familyMemberCountSelected(result: any) {
        if (result && result.selectedValue) {

            this.model.familyInfo.personCount = result.selectedValue;
            this.model.familyInfo.personCountId = this.cms.personCountList.indexOf(this.model.familyInfo.personCount);
            if (!this.checkPersonCount()) {
                return;
            }
            nextTick( () => {
                // wait for transition to begin showing 1. name field
                setTimeout(() => {
                    const input = document.getElementById('name0');
                    if (input) {
                        input.focus();
                    }

                }, 300);
            });
            return;
        }
        this.model.familyInfo.personCount = undefined;
        this.model.familyInfo.personCountId = 0;
    }

    private checkPersonCount() {
        if(this.model.familyInfo.personCount && this.model.personInfo.familyMemberCount) {
            if (this.model.familyInfo.personCountId + 1 >= this.model.personInfo.familyMemberCountId) {
                const popup = {
                    title: this.cms.personCountPopupTitle,
                    content: this.cms.personCountPopupContent.replace('@childCount', this.model.familyInfo.personCount),
                    btnSecondLabel: 'Ok',
                    track: false,
                    id: 'personCount',
                    show: true,
                }
                this.$emit('toggleModal', popup);
                return false;
            }
        }
        return true;
    }
    public get personCountPreSelect() : string {
        return this.model.familyInfo.personCount ?  this.model.familyInfo.personCount : 'Vælg hvor mange børn der skal forsikres'
    }

    public get personCountLabel() : string {
        return this.cms.useAdultAccident ?  'Hvor mange i husstanden skal forsikres' : 'Hvor mange børn der skal forsikres?';
    }

    public getLabelWork(inx: number): string {
        if (inx === 0 && this.cms.useAdultAccident) {
            return 'Hvad arbejder du med';
        }
        let name: string = this.model.familyInfo[('name' + inx)];
        if (name && name.trim().length > 2) {
            name = name.trim();
            const res = name.match(/^[a-zA-Z\æøå]+/ig);
            if (res && res[0].length > 2) {
                return `Hvad arbejder ${res[0]} med`;
            }
        }
        const age = this.model.familyInfo[('age' + inx)];
        if(age < 18) {
            return 'Hvad arbejder dit barn med';
        }
        return 'Hvad arbejder din medbo med';
    }

    public getPersonNameLabel(inx: number): string {
        if (inx === 0 && this.cms.useAdultAccident) {
            return 'Dit navn';
        }
        return this.cms.useAdultAccident ? 'Fulde navn' : 'Dit barns fulde navn';
    }

    public getPersonAgeLabel(inx: number): string {
        return this.cms.useAdultAccident ? 'alder' : 'Dit barns alder';
    }

    public nextStep() {
        this.$emit('nextStep', this.card.routePath);
    }

}
