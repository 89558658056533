//Scroll config used in vue-scroll

export default {
    container: 'body',
    easing: 'ease-in',
    offset: -200,
    cancelable: true,
    onStart: null,
    onDone: null,
    onCancel: null,
    x: false,
    y: true
}
