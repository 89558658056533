import { CoveragesBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd';

export enum CoveragesBlockType {
    Daekker = 'Daekker',
    DaekkerIkke = 'DaekkerIkke',
}

export default class CoveragesBlockCd extends ContentItemCd {
    public model: CoveragesBlock;
    public daekkerIkkeCoverages: Array<string> = [];
    public daekkerCoverages: Array<string> = [];

    constructor(model: CoveragesBlock) {
        super();
        this.model = model as CoveragesBlock;
        this.init();
    }

    private init(): void {
        // console.log('CoveragesBlockCd', this);
        
        if (this.model.dataPlaceholder) {
            if (this.model.dataPlaceholder === CoveragesBlockType.DaekkerIkke) {
                // default
                this.model.btnLabel = 'Vælg';
                this.model.btnClass = 'btn-solid-secondary';
            } else if (this.model.dataPlaceholder === CoveragesBlockType.Daekker) {
                // default
                this.model.btnLabel = 'Fravælg';
                this.model.btnClass = 'btn-outline-secondary';
            }

            if (this.model.coverages) {
                const coveragesArray = this.model.coverages.split(",");

                if (this.model.dataPlaceholder === CoveragesBlockType.DaekkerIkke) {
                    this.daekkerIkkeCoverages = [];
                    coveragesArray.forEach(element => {
                        this.daekkerIkkeCoverages.push(element.trim().toLowerCase());
                    });
                } else if (this.model.dataPlaceholder === CoveragesBlockType.Daekker) {
                    this.daekkerCoverages = [];
                    coveragesArray.forEach(element => {
                        this.daekkerCoverages.push(element.trim().toLowerCase());
                    });
                }
            }
        }
    }
    public static createDefaultDataBlocks(): Array<CoveragesBlockCd> {
        const model: CoveragesBlock = { 
            dataPlaceholder:  CoveragesBlockType.Daekker,
            contentType: 'CoveragesBlock'
            } as CoveragesBlock;
        const blocks: Array<CoveragesBlockCd> = [];
        const dataBlock: CoveragesBlockCd = new CoveragesBlockCd(model);
        dataBlock.model.hide = false;
        blocks.push(dataBlock);
        blocks.push(Object.assign( dataBlock, {
            dataPlaceholder: CoveragesBlockType.DaekkerIkke,
        }));
        return blocks;
    }
}
