import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import AgreementStatus from '@/views/model/AgreementStatus';
import template from './ProductlineHeader.vue';
import OrderflowTravelPropertyHandler from '@/views/providers/OrderflowTravelPropertyHandler';
import OrderflowHandler from '@/views/providers/OrderflowHandler';
import { isNullOrUndefined } from '@/views/common/util';
import InsurancesProductEnum from '@/views/model/InsurancesProductEnum';
import AgreementLineAdapter from '@/views/model/adapter/AgreementLineAdapter';
import { CustomerAgreementPart } from '@/generated-api/customer-agreements';
import GeneralHighlight from './highlights/GeneralHighlight';
import BygningHighlight from './highlights/BygningHighlight';
import RejseHighlight from './highlights/RejseHighlight';
import HundeHighlight from './highlights/HundeHighlight';
import UlykkeHighlight from './highlights/UlykkeHighlight';
import EjerskifteHighlight from './highlights/EjerskifteHighlight';
import CampingvognHighlight from './highlights/CampingvognHighlight';
import TraktorHighlight from './highlights/TraktorHighlight';
import Productline from '../../productline/Productline';
import ContentItem from "@/views/contentApi/contentTypes/ContentItemCd";
import DefaultComponents from '@/views/overview/_partials/defaultComponents'
import store from '@/store'
import OrderflowContainerBlockCd from "@/views/contentApi/contentTypes/OrderflowContainerBlockCd";
import GlobalContentHandler from "@/views/providers/GlobalContentHandler";

@Options({
    name: 'selfservice-insurances-productline-header-component',
    mixins: [template],
    components: {
        'shared-components': DefaultComponents,        
    },
    props: {
        productDetails: AgreementLineAdapter,
        productId: String,
        contentBlocks: Object,
        orderflowContainerBlocks: Object
    }
})
export default class ProductlineHeader extends mixins(InsurancesCommon) {
    productDetails: AgreementLineAdapter;
    productId: string;
    contentBlocks: Array<ContentItem>;
    orderflowContainerBlocks: Array<OrderflowContainerBlockCd>

    public highlight: GeneralHighlight = null;

    public agreementPart: CustomerAgreementPart;
    public status: string;
    public title: string;
    public agreementId: string;
    public description: string;

    public baseInfoTitle: string = "";
    public baseInfoSubtitle: string = "";
    public updateHighlights: number = 123;
    public showSpinner: boolean = false;
    public subHeadData: Array<any> = null;
    public accordionOpen = false;
    public productline: Productline = undefined;
    public productImage: string = null;
    // rejseforsikring
    private orderflowTravelPropertyHandler: OrderflowTravelPropertyHandler = null;
    public agreementStatus = AgreementStatus;

    async mounted() {
        this.productImage = (store.state.epiContent.model as any).productImage;
        this.agreementPart = this.productDetails.agreement.agreement_part;
        this.status = this.productDetails.agreement.status;
        this.title = this.productDetails.title;
        this.agreementId = this.productDetails.agreement.agreement_id;
        this.description = this.productDetails.description;

        //this.sendMsgToApp({ "MAIN_TITLE": this.title });

        let isBusiness = await this.customerIsBusiness();
        let isVWSF = await this.agreementIsPartner(this.productDetails.agreement.agreement_line_seq_no);
        const title = this.title.toLocaleLowerCase();
        switch (title) {

            case InsurancesProductEnum.BYGNINGS_FORSIKRING:
                this.highlight = new BygningHighlight(this.productDetails, isBusiness);
                break;
            case InsurancesProductEnum.REJSE_FORSIKRING:
                this.highlight = new RejseHighlight(this.productDetails, isBusiness);
                break;
            case InsurancesProductEnum.HUNDE_FORSIKRING:
                this.highlight = new HundeHighlight(this.productDetails, isBusiness);
                break;
            case InsurancesProductEnum.ULYKKES_FORSIKRING:
            case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
                this.highlight = new UlykkeHighlight(this.productDetails, isBusiness);
                break;
            case InsurancesProductEnum.EJERSKIFTE_FORSIKRING:
                this.highlight = new EjerskifteHighlight(this.productDetails, isBusiness);
                break;
            case InsurancesProductEnum.CAMPINGVOGNS_FORSIKRING:
                this.highlight = new CampingvognHighlight(this.productDetails, isBusiness);
                break;
            case InsurancesProductEnum.TRAKTOR_FORSIKRING:
                this.highlight = new TraktorHighlight(this.productDetails, isBusiness);
                break;
            default:
                this.highlight = new GeneralHighlight(this.productDetails, isBusiness, isVWSF);
                break;
        }

        await this.setProductBaseInfo();
        this.productline = <Productline>this.$parent;
    }

    private async setProductBaseInfo() {
        const title = this.title.toLocaleLowerCase();
        await GlobalContentHandler.setUp();
            
        // standard orderflows
        new OrderflowHandler(this).handleOrderflow();

        switch (title) {
            case InsurancesProductEnum.BIL_FORSIKRING:
            case InsurancesProductEnum.BAAD_FORSIKRING:
            case InsurancesProductEnum.KNALLERT_FORSIKRING:
            case InsurancesProductEnum.CAMPINGVOGNS_FORSIKRING:
            case InsurancesProductEnum.VETERAN_FORSIKRING:
            case InsurancesProductEnum.TRAKTOR_FORSIKRING:
            case InsurancesProductEnum.PAAHAENGSVOGNS_FORSIKRING:
            case InsurancesProductEnum.MOTORKOERETOEJS_FORSIKRING:
                const licensePlate = this.getBaseInfoValueByName('licence_plate', this.agreementPart.fields);
                if (licensePlate !== "") {
                    const brandModel = this.getBaseInfoValueByName('brand_model', this.agreementPart.fields);
                    this.baseInfoTitle = this.getBaseInfoValueByName('brand', this.agreementPart.fields) + ' ';
                    if (this.utils.hasValue(brandModel)) {
                        this.baseInfoTitle += brandModel + ' ';
                    }
                    
                    this.baseInfoTitle = this.utils.capitalizeFirstLetter(this.baseInfoTitle, true);
                    this.baseInfoSubtitle = this.buildAutoBaseInfoSubtitle(licensePlate);
                } else {
                    let descArr = this.description.split(" ");
                    this.baseInfoTitle = descArr[1];
                    this.baseInfoSubtitle = this.buildAutoBaseInfoSubtitle(descArr[0]);
                }
                break;

            case InsurancesProductEnum.HUS_FORSIKRING:
            case InsurancesProductEnum.INDBO_FORSIKRING:
            case InsurancesProductEnum.FRITIDSHUS_FORSIKRING:
            case InsurancesProductEnum.SAELGERANSVARS_FORSIKRING:
            case InsurancesProductEnum.EJERSKIFTE_FORSIKRING:
                const addr = this.agreementPart.address;
                this.baseInfoTitle =
                    addr.street_name + " " +
                    addr.street_no +
                    this.addSeparationSign(addr.floor, true, false, true) +
                    this.addSeparationSign(addr.floor_side, false, false, true);

                this.baseInfoSubtitle =
                    addr.postal_code + " " +
                    addr.city;

                break;

            case InsurancesProductEnum.HUNDE_FORSIKRING:
                this.baseInfoTitle = this.getBaseInfoValueByName('dog_name', this.agreementPart.fields);
                this.baseInfoSubtitle = this.getBaseInfoValueByName('dog_race', this.agreementPart.fields);

                break;

            case InsurancesProductEnum.ULYKKES_FORSIKRING:
                this.baseInfoTitle = this.agreementPart.person_name;
                this.baseInfoSubtitle = "";

                const hovederhverv = this.getBaseInfoValueByName('main_job', this.agreementPart.fields);
                if (!isNullOrUndefined(hovederhverv) && hovederhverv !== "") {
                    this.baseInfoSubtitle = hovederhverv;
                }

                break;

            case InsurancesProductEnum.GRAVIDITETS_FORSIKRING:
            case InsurancesProductEnum.LOEN_FORSIKRING:
            case InsurancesProductEnum.SYGDOMS_FORSIKRING:
            case InsurancesProductEnum.UNGDOMS_FORSIKRING:
            case InsurancesProductEnum.KORTTIDSREJSE_FORSIKRING:
                this.baseInfoTitle = this.agreementPart.person_name;
                this.baseInfoSubtitle = "";
                break;

            case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
                let names = this.agreementPart.person_name;
                let namesSplit = names.split(',');

                this.baseInfoTitle = namesSplit.join("<br/>");
                this.baseInfoSubtitle = "";

                break;
            case InsurancesProductEnum.REJSE_FORSIKRING:
                this.baseInfoTitle = "Beboerne på adressen";
                const streetName = this.agreementPart.address.street_name,
                    streetNo = this.agreementPart.address.street_no;
                this.baseInfoSubtitle = streetName + " " + streetNo;
                if( this.status !== AgreementStatus.AFVENTER && this.status !== AgreementStatus.NY_AENDRING) {

                    // property orderflows
                    this.orderflowTravelPropertyHandler = new OrderflowTravelPropertyHandler(this);
                    this.orderflowTravelPropertyHandler.handleOrderflow();

                } else if ( this.status === AgreementStatus.NY_AENDRING) {
                    // send mail
                    const mailDescription = {
                        status: this.status,
                        policyLine: this.productId,
                        policyNo: this.agreementId,
                    }

                    try {
                        await this.sendMailToTeam('Kunde har tilkøbt en dækning, men afventer at police er klar', 'Kunde har tilkøbt en dækning på ' + this.title + ', men afventer at police er klar', JSON.stringify(mailDescription));
                    } catch(err){}
                }

                break;

            case InsurancesProductEnum.BYGNINGS_FORSIKRING:
                this.baseInfoTitle = this.getBaseInfoValueByName('building_name', this.agreementPart.fields);
                this.baseInfoTitle = this.baseInfoTitle.replace("beboelse-Fritidslandbr", "beboelse-Fritidslandbrug");

                const address = this.agreementPart.address;
                this.baseInfoSubtitle =
                    address.street_name + " " +
                    address.street_no +
                    this.addSeparationSign(address.floor, true, false, true) +
                    this.addSeparationSign(address.floor_side, false, false, true) +", "+
                    address.postal_code + " " +
                    address.city;

                break;

            case InsurancesProductEnum.ERHVERVSANSVARS_FORSIKRING:
            case InsurancesProductEnum.ERHVERV_OG_PRODUKTANSVAR_FORSIKRING:
            case InsurancesProductEnum.KOLLEKTIVULYKKE:
                this.baseInfoTitle = "";
                this.baseInfoSubtitle = "";
                break;

            default:
                this.baseInfoTitle = this.description;
                this.baseInfoSubtitle = "";
                break;
        }
    }

    public animateController() {
        this.accordionOpen = !this.accordionOpen;
    }

    public updateHighlight(label: string, value: string) {
        this.highlight.highlights.map( elem => {
            if (elem.label === label) {
                elem.value = value;
            }
        });
        this.updateHighlights += 1;
    }
}
