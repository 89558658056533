import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState} from "vuex";
import AbCookie from "@/views/common/ab_cookie";
import { isNullOrUndefined } from "@/views/common/util";
import { AbLink } from "@/views/sharedcomponents/baseComponents/components";

@Options({
    name: 'TextBlockComponent',
    components: {
        AbLink
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<any>
    },
})

export default class TextBlockComponent extends Vue {
    model: any;
    textblockRemoved = false;
    abCookie = new AbCookie();

    beforeMount() {
        if (this.abCookie.checkCookie(this.model.closeButtonCookieGuid)){
            this.textblockRemoved = true;
        }
        this.model.text = this.model.text.replace('{{AuthError}}', this.$route.query.error);  
    }

    close(){
        let cookie = this.abCookie.getCookie(this.model.closeButtonCookieGuid);
        if (cookie === ""){
            //default 90 dage ellers brug cookieActiveDays felt fra CMS hvis defineret
            let cookieActiveDays = 90;
            if (!isNullOrUndefined(this.model.cookieActiveDays) && this.model.cookieActiveDays > 0){
                cookieActiveDays = this.model.cookieActiveDays;
            }
            this.abCookie.setCookie(this.model.closeButtonCookieGuid, "true", cookieActiveDays);
            this.textblockRemoved = true;     
        }
    }
}
