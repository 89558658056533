import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import template from './Object.vue';
import _ from "lodash";
import { isNullOrUndefined } from '@/views/common/util';
import ProductListItem from './productListItem/ProductListItem';
import { UrlUtil } from "@/episerver/api/urlUtil";
import InsurancesProductEnum from "@/views/model/InsurancesProductEnum";
import store from "@/store";

@Options({
    name: 'product-list-component',
    mixins: [template],
    components: {
        ProductListItem
    },
    props: {
        agreemenObjects: Array,
        title: String,
        totalPrice: String
    }
})
export default class ProductObject extends mixins(InsurancesCommon) {
    agreemenObjects: Array<any>;
    title: string;
    totalPrice: string;
    basePath = store.getters.getConfigEpi.disableBasePathForLegacyRouting ? "" : UrlUtil.baseUrlPath;

    mounted() {
        let obj = this.agreemenObjects.find(x => x.name === "Bygning 1 - Privat beboelse-Fritidslandbr");
        if (!isNullOrUndefined(obj)) {
            obj.name = "Bygning 1 - Privat beboelse-Fritidslandbrug";
        }
        this.agreemenObjects.sort((a, b) => a.objectId - b.objectId);
    }

    objectSelected(item: any, objectId: string = "") {
        let route = {
            path: this.basePath + '/forsikring/mineforsikringer/' + this.replaceDanishChars(item.title.toLowerCase()),
            query: {
                id: item.id,
                objectid: objectId,
            }
        }
        this.$router.push(route);
    }
}
