import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState, Store } from "vuex";
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking";
import store from '@/store';
import { AbLink } from "@/views/sharedcomponents/baseComponents/components";
import TextBody from "./_partials/TextBody.vue";


@Options({
    name: 'TextBlockComponent',
    components: {
        AbLink,
        TextBody
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<any>
    },
})

export default class TextBlockComponent extends Vue {
    model: any;
    trackingUrl!: string;

    beforeMount() {
        this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
    }
}
