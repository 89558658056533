import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: "padding",
  class: "pb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inputComponent = _resolveComponent("inputComponent")!
  const _component_RadioComponent = _resolveComponent("RadioComponent")!
  const _component_ValidationErrorComponent = _resolveComponent("ValidationErrorComponent")!
  const _component_SearchComponent = _resolveComponent("SearchComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_TransitionGroup, { name: "expand-list" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_inputComponent, {
          class: "text-left pb-2",
          key: 'name' + _ctx.personNo,
          id: 'name'  + _ctx.personNo,
          name: 'name' + _ctx.personNo ,
          placeholder: _ctx.placeholderName,
          label: _ctx.labelName,
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          innerData: _ctx.model.familyInfo,
          validationClass: _ctx.model.familyInfo['name' + _ctx.personNo] ? 'input--valid' : undefined,
          required: true,
          autocomplete: "off"
        }, null, 8, ["id", "name", "placeholder", "label", "validateOnLoad", "innerData", "validationClass"]), [
          [_vShow, _ctx.showPerson]
        ]),
        _withDirectives(_createVNode(_component_inputComponent, {
          class: "text-left pb-5",
          key: 'age' + _ctx.personNo,
          id: 'age' + _ctx.personNo,
          name: 'age' + _ctx.personNo,
          placeholder: _ctx.placeholderAge,
          label: _ctx.labelAge,
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          innerData: _ctx.model.familyInfo,
          validationClass: _ctx.age > -1 && _ctx.age < 18 ? 'input--valid' : undefined,
          required: true,
          autocomplete: "off",
          fieldValidatorType: _ctx.fieldValidatorType,
          onInputChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleAge()))
        }, null, 8, ["id", "name", "placeholder", "label", "validateOnLoad", "innerData", "validationClass", "fieldValidatorType"]), [
          [_vShow, _ctx.showPerson]
        ]),
        (_ctx.showWork)
          ? (_openBlock(), _createElementBlock("div", {
              key: 'work' + _ctx.personNo,
              class: "mt-n3"
            }, [
              (_ctx.showWorkQuestion)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", {
                      class: "pb-3 text-left",
                      textContent: _toDisplayString(_ctx.labelQuestionWork)
                    }, null, 8, _hoisted_2),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jaNejArray, (item, inx) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass([(inx + 1) === _ctx.jaNejArray.length ? 'pb-3' : 'pb-2']),
                        key: 'hasWork' + _ctx.personNo + inx
                      }, [
                        _createVNode(_component_RadioComponent, {
                          name: 'hasWork' + _ctx.personNo,
                          id: 'hasWork' + _ctx.personNo + inx + item.value,
                          inputValue: (item.value + ''),
                          preSelect: _ctx.model.familyInfo['hasWork' + _ctx.personNo],
                          trackingLabel: 'har dit barn arbejde?',
                          onRadioComponent: _ctx.handleRadioClick
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.displayValue), 1)
                          ]),
                          _: 2
                        }, 1032, ["name", "id", "inputValue", "preSelect", "onRadioComponent"])
                      ], 2))
                    }), 128)),
                    (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.model.familyInfo['hasWork' + _ctx.personNo])
                      ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                          key: "hasWorkErr",
                          class: "input-error--show input-error__radio mt-n3",
                          text: "Vælg Ja eller Nej"
                        }))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.model.familyInfo['hasWork' + _ctx.personNo] === 'ja')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_Transition, {
                      name: "expand",
                      onAfterEnter: _ctx.transitionExpand.afterEnter,
                      onEnter: _ctx.transitionExpand.enter,
                      onLeave: _ctx.transitionExpand.leave
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SearchComponent, {
                          defaultValue: _ctx.work,
                          label: _ctx.labelWork,
                          placeholder: _ctx.placeholderWork,
                          searchByCleanedInput: true,
                          resultList: _ctx.cms.work,
                          isValid: _ctx.work,
                          onSearch: _ctx.workSelected,
                          onSearchError: _ctx.workSearchError
                        }, null, 8, ["defaultValue", "label", "placeholder", "resultList", "isValid", "onSearch", "onSearchError"])
                      ]),
                      _: 1
                    }, 8, ["onAfterEnter", "onEnter", "onLeave"])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.model.pristineStep.get(_ctx.card.name) && _ctx.model.familyInfo.work === undefined)
                ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                    key: "workError",
                    class: "input-error--show mt-n3",
                    text: 'Du skal vælge hvad dit barn arbejder med'
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}