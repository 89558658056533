import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import PageTitle from '@/views/overview/_partials/pageTitle';
import DefaultComponents from '@/views/overview/_partials/defaultComponents';
import Object from './object/Object';
import { CustomerAgreementSummaryList } from '@/generated-api/customer-agreements';
import { useRoute } from 'vue-router';
import ContentItemCd from "@/views/contentApi/contentTypes/ContentItemCd";
import { SelfServiceProductPage } from "@/definitions/episerver/content-types";
import store from "@/store";
import { MODEL_LOADED } from "@/episerver/store/modules/epiContent";
import ContentBlockCreator from "@/views/contentApi/ContentBlockCreator";

@Options({
    name: 'selfservice-insurances-product-component',
    components: {
        PageTitle,
        DefaultComponents,
        'product-object-component': Object,
    }
})
export default class ProductObject extends mixins(InsurancesCommon) {
    public model: SelfServiceProductPage;
    contentBlocks: Array<ContentItemCd> = [];
    title = null;
    totalPrice: number;
    agreementObjects: Array<any> = [];
    public showCookieComponent: boolean = false;

    beforeMount() {
        setTimeout(() => {
            if (!store.state.epiContent.modelLoaded) {
                const unsubscribe = store.subscribeAction((action, state) => {
                    if (action.type === MODEL_LOADED) {
                        this.model = store.state.epiContent.model as SelfServiceProductPage;
                        this.init();
                        unsubscribe();
                    }
                });
            } else {
                this.model = store.state.epiContent.model as SelfServiceProductPage;
                this.init();
            }
        }, 1);
    }

    public init() {
        //this.sendMsgToApp({ "MAIN_TITLE": this.model.mainTitle });
        this.contentBlocks = ContentBlockCreator.getBlocks(this.model.mainArea);

        this.getAgreementList().then(agreements => {
            this.showCookieComponent = agreements.agreements && agreements.agreements.length > 0
            this.handleGetAgreements(agreements);
        });
    }

    private handleGetAgreements(response: CustomerAgreementSummaryList): void {
        for (let agreement of response.agreements) {
            if (agreement.agreement_line_seq_no === this.$route.query.id) {

                this.totalPrice = agreement.price.amount_total;
                //this.title = "bygninger";

                agreement.agreement_parts.forEach(part => {
                    const addr = part.address;
                    let address =
                        addr.street_name + " " +
                        addr.street_no +
                        this.addSeparationSign(addr.floor, true, false, true) +
                        this.addSeparationSign(addr.floor_side, false, false, true);

                    this.agreementObjects.push(
                        {
                            "name": this.getBaseInfoValueByName("building_name", part.fields),
                            "adress": address,
                            "title": "Bygningsforsikring",
                            "id": this.$route.query.id,
                            "objectId": part.part_no,
                        }
                    )
                })
            }
        }
    }
}
