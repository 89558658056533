import { ServiceCheckStep } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd'


export default class ServiceCheckStepCd extends ContentItemCd {
    public model: ServiceCheckStep;
    constructor(model: ServiceCheckStep) {
        super();
        this.model = model;
        this.init('mainArea');
    }

    private init(field: any) {
        if (this.model[field]) {
            this.model[field].forEach(item => {
                let data: any;
                switch(item.contentType) {
                    case 'Accordion':
                        data = item.accordionItems.map(accordionItem => {
                            return {
                                title: accordionItem.title,
                                body: accordionItem.content
                            }
                        });
                        break;
                    case 'WysiwygBlock':
                        data = {
                            type: item.contentType,
                            text: item.text
                        }
                        break;
                    case 'ResultBlock':
                        data = {
                            type: item.contentType,
                            resultCode: item.resultCode,
                            text: item.text
                        }
                        break;
                    case 'UsabillaInPageBlock':
                        data = {
                            usabillaCategory: item.usabillaCategory,
                            usabillaId: item.usabillaId,
                            usabillaItem: item.usabillaItem,
                            usabillaSubCategory: item.usabillaSubCategory,
                            usabillaTitle: item.usabillaTitle
                        }
                        break;
                    case 'ButtonLabelWorkHoursBlock':
                    case 'ButtonLabelAddWorkGroupBlock':
                        data = { text: item.label }
                        break;
                    default:
                        data = item;
                }

                this[field].push({[item.contentType]: data });
            })
        }
    }
}
