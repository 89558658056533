import { Options, Vue } from 'vue-class-component';
import template from './bulletListComponent.vue';
import { nextTick } from 'vue';

@Options({
    name: 'BulletList',
    mixins: [template],
    components: {},
    props: {
        name: String,
        innerData: Array,
        type: {type: String, default: 'square' },
        overflow: { type: Boolean, default: false },
        selectable: { type: Boolean, default: false },
        selectedValue: String,
    }
})

export default class BulletList extends Vue {
    name?: string;
    innerData: Array<any>; // can be ['lorem', 'ipsum', 3, 4] or [{ title: 'Title', desc: 'Whatever' }] or [{ name: 'Title' }]
    type: string; // 'square' | 'round' | 'none'
    overflow?: boolean; // text ellipsis
    selectable: boolean; // wether an element can be selected -> fires click and keyup events
    selectedValue?: string; // load with selected value in bullitlist

    public selected: number = -1;

    public created() {
        if (this.selectable) {
            document.addEventListener('keyup', this.handleKeyup);        
        }
    }

	public beforeUnmount() {
        if (this.selectable) {
            document.removeEventListener('keyup', this.handleKeyup);
        }
	}

    private handleKeyup(e) {
        let scroll = false;
        if (e.key === 'ArrowUp') {
            this.selected < 1 ? this.selected = 0 :this.selected--;
            scroll = true;
        } else if(e.key === 'ArrowDown') {
            this.selected < this.innerData.length-1 ? this.selected++ : this.selected = this.innerData.length-1;
            scroll = true;
        } else if(e.key === 'Enter' || e.key === ' ') { // space tab
            this.clicked(this.selected, true);
        }
        if (scroll) {
            this.scrollToSelected();
        }
    }

    public mounted() {
        if (this.selectedValue) {
            this.selected = this.list.indexOf(this.selectedValue);
            if (this.selected > -1) {
                this.scrollToSelected();
            }
        }
    }

    private scrollToSelected() {
        nextTick( () => {
            const myElement = document.querySelector('.bullet-list li.selected') as HTMLLIElement;
            myElement.scrollIntoView( {
                behavior: 'smooth',
                block: 'center',
            });
        });
    }
    get list() { // if array contains objects, return name or title values only
        if (this.innerData.some(item => typeof item === 'object')) {
            return this.innerData.map(item => {
                return item.name || item.title;
            });
        }
        return this.innerData;
    }

    public clicked(index: number, instantly? : boolean): void {
        if (this.selectable) {
            this.selected = index;
            this.$emit('BulletListComponent', {
                name: this.name,
                value: this.list[index],
                instantly
            });
        }
    }
}
