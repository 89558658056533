import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState } from "vuex";
import { IContent } from "@/definitions/episerver/content";
import ContentComponentSelector from "@/views/sharedcomponents/epiComponents/infrastructure/contentComponentSelector/contentComponentSelector";
import getComponentTypeForContent from "@/episerver/api/contentComponentSelector";
import { displayOptions } from "@/definitions/displayOptions/displayOption";
import template from './ContentArea.vue';

@Options({
    name: 'ContentAreaComponent',
    mixins: [template],
    components: {
        ContentComponentSelector
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable
    }),
    props: {
        model: Array as PropType<IContent[]>,
        rootClass: { type: String, default: "ContentArea" },
        itemClass: { type: String, default: "ContentAreaItem" }
    },
})

export default class ContentAreaComponent extends Vue {
    isEditable = false;
    model: IContent;
    rootClass: { type: String, default: "ContentArea" };
    itemClass: { type: String, default: "ContentAreaItem" };

    getDisplayOption(block: IContent) {
        const blockType = block.contentType;
        //TODO make sure the value here makes sense
        if (blockType && displayOptions[blockType]) {
            let displayoption: string = block.displayOption
            if (!displayoption && displayOptions[blockType]["default"]) {
                displayoption = displayOptions[blockType]["default"];
            }
            return displayOptions[blockType][displayoption] || "";
        } else {
            return (block.displayOptionFallback ?? "") || "";
        }
    }

    getComponentTypeForBlock(block: IContent) {
        return getComponentTypeForContent(block);
    }

    getBlockClasses(block: IContent) {
        const clzz = (block as any)?.containerClass ? (block as any).containerClass : '';
        return `${this.itemClass} ${this.getDisplayOption(block)} ${clzz}`;
    }
}
