import ContentItemCd from './ContentItemCd'
import AbAmount from '@/views/common/ab_amount';
import Utils from '@/views/providers/Utils';
import CartHandler from '@/views/providers/CartHandler';
import ProductPricingHandler from '@/views/providers/ProductPricingHandler';
import InsurancesCommon from '@/views/model/InsurancesCommon';
import { OrderflowBlock, OrderflowStepBlock } from '@/definitions/episerver/content-types';
import OrderflowStepBlockCd from './OrderflowStepBlockCd';
import { UrlUtil } from '@/episerver/api/urlUtil';

export default class OrderflowBlockCd extends ContentItemCd {
    public model: OrderflowBlock;
    private abAmount: AbAmount = new AbAmount();
    public orderflowId: string; // will be set by the container
    public orderflowSteps: Array<any> = [];
    private utils: Utils = new Utils();

    constructor(model: OrderflowBlock) {
        super();
        this.model = model;
        // console.log('orderflowBlock model', model);
        // console.log('orderflowBlock model.mainArea', model.mainArea);
        this.init();
    }


    public init(): void {
        
        this.model.tilvalg = !!this.model.tilvalg;
        this.model.onlineFlow = !!this.model.onlineFlow;
        this.model.userCanSelectSums = !!this.model.userCanSelectSums;
        
        
        // console.log('!!!!!!!!!!!!!!!!!*******************OrderflowBlockCd', this.model.mainArea );
        if (this.model.mainArea?.length > 0) {
            this.orderflowSteps = [];
            this.model.mainArea.forEach(orderflowStepBlock => {
                if (!orderflowStepBlock.hide) {
                    this.orderflowSteps.push(new OrderflowStepBlockCd(orderflowStepBlock as OrderflowStepBlock));
                }
            });
        // console.log('orderflowSteps', this.orderflowSteps);
                    

        }
    }
    // TODO KLK remove test
    private getMockedPrice() {
        console.warn('TODO KLK Remove mocked data');
        
        const min = Math.ceil(100);
        const max = Math.floor(300);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    public setPrices(currentPrice: number, addedPrice: number, newPrice: number, wordsTranslate: any) {
        console.log(currentPrice, addedPrice, newPrice);

        // rounding issue - make sure the numbers add up from server.
        const diff = ProductPricingHandler.getDiffInNewPrice(currentPrice, addedPrice, newPrice);
        // const diff = 0;

        this.orderflowSteps.forEach(orderflowStepBlock => {
            if (orderflowStepBlock.orderflowPriceBlock) {
                orderflowStepBlock.orderflowPriceBlock.currentPrice = currentPrice || currentPrice === 0 ? this.abAmount.formatRoundInt(currentPrice) + ' kr./år' : undefined;
                let addPlus = '';
                if (addedPrice + diff > 0) {
                    addPlus = "+ ";
                }
                orderflowStepBlock.orderflowPriceBlock.newPrice = addedPrice || addedPrice === 0 ? (addPlus + this.abAmount.formatRoundInt(addedPrice + diff)) + ' kr./år' : undefined;
                orderflowStepBlock.orderflowPriceBlock.totalPrice = newPrice || newPrice === 0 ? this.abAmount.formatRoundInt(newPrice) + ' kr./år' : undefined;
                orderflowStepBlock.orderflowPriceBlock.translateTemplate(wordsTranslate);
            }
        });
    }

    public hasPrices() {
        return this.orderflowSteps.some(orderflowStepBlock => {
            return orderflowStepBlock.orderflowPriceBlock != undefined;
        });
    }

    public async addPropertyChange(agreementId: string, tilvalg: boolean,currentPrice: number, propertyName: string, propertyOption: string, wordsTranslate: any, insurancesCommon: InsurancesCommon): Promise<any> {
        try {
            const cartUpdate = await CartHandler.requestPrice(agreementId, propertyName, propertyOption);
            // console.log('cartUpdate.changes[0].price_diff', cartUpdate.changes[0].price_diff);

            if (cartUpdate.changes[0].price_diff < 1 && tilvalg) {
                await insurancesCommon.sendMailToTeam(JSON.stringify(cartUpdate), 'FEJL  - Pris er 0 kr for online dækning: ' + propertyName, 'Kunde kunne ikke få pris på tilvalg af dækning ' + propertyName);
                throw new Error('Kunde kunne ikke få pris på dækning ' + propertyName);
            }
            if (cartUpdate.changes[0].price_diff > 0 && !tilvalg) {
                await insurancesCommon.sendMailToTeam(JSON.stringify(cartUpdate), 'FEJL  - Pris er over 0 kr for online dækning: ' + propertyName, 'Kunde kunne ikke få pris på fravalg af dækning ' + propertyName);
                throw new Error('Kunde kunne ikke få pris på dækning ' + propertyName);
            }

            this.setPrices(currentPrice, cartUpdate.changes[0].price_diff, cartUpdate.total_price, wordsTranslate);
            return Promise.resolve();
        }
        catch (err) {
            console.error(err);
            
            // if (UrlUtil.isDevelop()) {
            //     // TODO KLK remove test
            //     console.warn('TODO KLK remove test');
                
            //     const price = this.getMockedPrice();
            //     if (tilvalg) {
            //         this.setPrices(currentPrice, price, currentPrice + price, wordsTranslate);
            //     } else {
            //         this.setPrices(currentPrice, -price, currentPrice - price, wordsTranslate);
            //     }
            //     CartHandler.removeCart();
            //     return Promise.resolve();
            // } else {
                CartHandler.removeCart();
                return Promise.reject();
            // }
        }
    }

    public async addPriceCoverageToServer(agreementId: string, featureId: string, tilvalg: boolean, currentPrice: number, wordsTranslate: any, insurancesCommon: InsurancesCommon, optionalValue?: string): Promise<any> {
        console.log('addPriceCoverageToServer', agreementId, featureId, tilvalg, currentPrice, wordsTranslate, insurancesCommon, optionalValue);
        try {
            const cartUpdate = await CartHandler.requestPriceCoverage(agreementId, featureId, tilvalg, optionalValue);
            console.log('cartUpdate.changes[0].price_diff', cartUpdate.changes[0].price_diff);
            
            if (cartUpdate.changes[0].price_diff < 1 && tilvalg) {
                await insurancesCommon.sendMailToTeam(JSON.stringify(cartUpdate), 'FEJL  - Pris er 0 kr for online dækning: ' + featureId, 'Kunde kunne ikke få pris på dækning ' + featureId);
                throw new Error('Kunde kunne ikke få pris på dækning ' + featureId);
            }
            if (cartUpdate.changes[0].price_diff > 0 && !tilvalg) {
                await insurancesCommon.sendMailToTeam(JSON.stringify(cartUpdate), 'FEJL  - Pris er over 0 kr for online dækning: ' + featureId, 'Kunde kunne ikke få pris på fravalg af dækning ' + featureId);
                throw new Error('Kunde kunne ikke få pris på dækning ' + featureId);
            }

            this.setPrices(currentPrice, cartUpdate.changes[0].price_diff, cartUpdate.total_price, wordsTranslate);
            return Promise.resolve();
        }
        catch (err) {
            // if (UrlUtil.isDevelop()) {
            //     console.warn('TODO KLK remove test');
            //     const price = this.getMockedPrice();
            //     if (tilvalg) {
            //         this.setPrices(currentPrice, price, currentPrice + price, wordsTranslate);
            //     } else {
            //         this.setPrices(currentPrice, -price, currentPrice - price, wordsTranslate);
            //     }
            //     CartHandler.removeCart();
            //     return Promise.resolve();
            // } else {
                CartHandler.removeCart();
                console.error(err);
                return Promise.reject();
            // }
        }
    }

    public isDevelop() {
        return UrlUtil.isDevelop();
    }

    public useErrorTexts(orderflowStepInx: number) {
        this.utils.hasValue(this.model.errorTitle) ? this.orderflowSteps[orderflowStepInx].model.title = this.model.errorTitle : null;
        this.utils.hasValue(this.model.errorButtonLabel) ? this.orderflowSteps[orderflowStepInx].model.btnForward = this.model.errorButtonLabel : null;
        this.utils.hasValue(this.model.errorButtonStyle) ? this.orderflowSteps[orderflowStepInx].model.btnForwardStyle = this.model.errorButtonStyle : null;
        this.utils.hasValue(this.model.errorText) ? this.orderflowSteps[orderflowStepInx].model.text = this.model.errorText : null;
    }

    public translateTemplate(wordsTranslate: any) {
        const translate = Object.assign({
            insuranceName: 'forsikring',
            orderflowName: 'dækning',
            showSum: '',
            // expireDate: 'forfaldsdato',
        }, wordsTranslate);

        const replace = [ new RegExp('{{forsikring}}', 'ig'),
                            new RegExp('{{dækning}}', 'ig'),
                            new RegExp('{{d&aelig;kning}}','ig'),
                            new RegExp('{{vis_sum}}', 'ig')
                        ];
        const words = [ translate.insuranceName,  translate.orderflowName,  translate.orderflowName, translate.showSum];

        this.orderflowSteps.map( (orderflowStep) => {
            replace.forEach( (elm, inx) => {
                orderflowStep.model.title ? orderflowStep.model.title = orderflowStep.model.title.replace(replace[inx], words[inx]) : orderflowStep.model.title;
                orderflowStep.model.text ? orderflowStep.model.text = orderflowStep.model.text.replace(replace[inx], words[inx]) : orderflowStep.model.text;
                });
            orderflowStep.inputFields.forEach( (input) => {
                replace.forEach( (elm, inx) => {
                    if (input.model) {
                        if (this.utils.isString(input.model.value)) {
                            input.model.value = input.model.value.replace(replace[inx], words[inx]);
                        }
                        if (this.utils.isString(input.value)) {
                            input.value = input.value.replace(replace[inx], words[inx]);
                        }
                        if (this.utils.isString(input.model.label)) {
                            input.model.label = input.model.label.replace(replace[inx], words[inx]);
                        }
                    } else if (input.label) {
                        // translation for eks. button
                        input.label = input.label.replace(replace[inx], words[inx]);
                    }// fallthrough
                });
            });
        });
    }
}
