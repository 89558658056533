<template>
    <div class="row">
        <div :class="['d-block', getColumns()]" v-for="item in loaders" :key="item">
            <div :class="['skeleton-loader', {'skeleton-loader--grey' : bgColor == 'grey' }]">
                <div class="skeleton-loader__mask" v-for="mask in 7"></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" src="./_assets/_skeleton-loader.scss"></style>
