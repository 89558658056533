import { PolicyDownloadBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd'

export default class PolicyDownloadBlockCd extends ContentItemCd {
    public model: PolicyDownloadBlock;

    constructor(model: PolicyDownloadBlock) {
        super();
        this.model = model;
    }

    public static createDefaults(): Array<PolicyDownloadBlockCd> {
        const def = [];
        const policy = new PolicyDownloadBlockCd({} as PolicyDownloadBlock);
        policy.model.downloadType = 'Policy';
        policy.model.linkText = 'Hent police';
        policy.model.buttonStyle = 'btn-outline-secondary';
        policy.model.errorTitle = 'Police under ændring';
        policy.model.errorText = 'Police dokument kan endnu ikke hentes, da policen netop er ændret';
        policy.model.errorButtonStyle = 'btn-solid-secondary';
        policy.model.errorButtonLabel = 'Ok';
        def.push(policy);

        const terms = new PolicyDownloadBlockCd({} as PolicyDownloadBlock);
        terms.model.downloadType = 'Terms';
        terms.model.linkText = 'Hent betingelser';
        terms.model.buttonStyle = 'btn-outline-secondary';
        terms.model.errorTitle = 'Betingelser under ændring';
        terms.model.errorText = 'Betingelses dokument kan endnu ikke hentes, da policen netop er ændret';
        terms.model.errorButtonStyle = 'btn-solid-secondary';
        terms.model.errorButtonLabel = 'Ok';
        def.push(terms);
        return def;

    }
}
