import { Vue, Options, mixins } from "vue-class-component";
import AbCommon from '@/views/model/AbCommon';
import template from './PageHero.vue';
import { AbLink } from '@/views/sharedcomponents/baseComponents/components';
import Utils from '@/views/providers/Utils';
import store from '@/store';

@Options({
    name: 'page-hero-component',
    mixins: [template],
    components: {
        AbLink
    },
    props:{
        imageUrl: String,
        link: String
    }
})
export default class PageHero extends mixins(AbCommon) {
    public imageUrl: string;
    public link: string;

    utils = new Utils();

    linkUrl() {
        if (this.utils.isIpad()) {
            return "/mitalmbrand/forsikring";
        } else {
            return this.link;
        }
    }

    public getImageUrl(): string {
        return this.imageUrl ?? (store.state.epiContent.model as any).imageUrl;
    }

    public getStyle(): string {
        const image = this.getImageUrl();
        if (image) {
            return `backgroundImage: url(${image})`;
        }
        return "";
    }

}
