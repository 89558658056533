import { Vue, Options, mixins } from "vue-class-component";
import template from './ButtonGroup.vue';
import { OptionalProperty } from "@/views/contentApi/contentTypes/OrderflowStepBlockCd";

@Options({
    name: 'button-group',
    mixins: [template],
    props:{
        buttons: Array,
        preSelected: String,
        buttonsPerRow: Number
    }
})

export default class ButtonGroup extends Vue{
    buttons: Array<OptionalProperty>;
    preSelected: string;
    buttonsPerRow: number;

    private selectedBtn: number = -1;
    private chunks: Array< Array<number>> = [[]];

    // LIFECYCLE
    public created() {
        this.handleChunks();
    }

    public mounted() {
        this.handlePreSelectedValue();
    }

    // METHODS
    private handlePreSelectedValue(): void {
        if (this.preSelected && this.preSelected !== '' && this.selectedBtn === -1) {
            this.selectedBtn = this.buttons.findIndex( (btn) =>  btn.value === this.preSelected);
        }
    }

    private handleChunks(): void {
        if (!this.buttonsPerRow) {
            this.buttonsPerRow = 2; // DEFAULT
        }
        this.buttons.forEach( (btn, inx) => {
            if(inx % this.buttonsPerRow === 0 && inx !== 0) {
                const arr = [];
                arr.push(inx);
                this.chunks.push(arr);
            } else {
                this.chunks[this.chunks.length - 1].push(inx);
            }
        });
    }
    public buttonClicked(inx: number): void {
        if (this.selectedBtn === inx) {
            return;
        }
        this.selectedBtn = inx;
        this.$emit("Component:Event", "Updated", this.value());
    }

    public isValid(): boolean {
        return this.selectedBtn > -1;
    }

    public value(): string {
        return this.isValid() ? this.buttons[this.selectedBtn].value : undefined;
    }

    public reset(): void {
        this.selectedBtn = -1;
    }

}
