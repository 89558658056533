import { Customer } from "@/generated-api/customer-details";
import * as localForage from "localforage";


export default class UserData {

    public init = false;
    public mastercard = false;
    public travelMastercardAdditionalPrice = false;
    public bilKasko = false;

    public canChangeOnline = true;

    constructor() {
    }

    async clearStorage() {
        this.mastercard = false;
        this.travelMastercardAdditionalPrice = false;
        this.bilKasko = false;
        await localForage.clear();
    }

    public setCanChangeOnline(customer: Customer) {
        const details = customer.contact?.contact_details;
        details?.forEach( (detail: any) => {
            if (this.canChangeOnline && detail.detail) {
                switch(detail.type) {
                    case 'Account_Mark__c' : detail.detail !== 'null' ? this.canChangeOnline = false : null;
                    break;
                    case 'hasWarning__c' : detail.detail !== 'false' ? this.canChangeOnline = false : null;
                    break;
                    case 'hasWarningAccountMark__c' : detail.detail !== 'false' ? this.canChangeOnline = false : null;
                    break;
                    case 'hasWarningDontTouch__c' : detail.detail !== 'false' && detail.detail !== 'null' ? this.canChangeOnline = false : null;
                    break;
                    case 'hasWarningLegalStatus__c' : detail.detail !== 'false' ? this.canChangeOnline = false : null;
                    break;
                    case 'hasWarningNotWanted__c' : detail.detail !== 'false' ? this.canChangeOnline = false : null;
                    break;
                    case 'hasWarningRKI__c' : detail.detail !== 'false' ? this.canChangeOnline = false : null;
                    break;
                    case 'hasWarningServiceChannel__c' : detail.detail !== 'false' ? this.canChangeOnline = false : null;
                    break;
                    case 'Not_Wanted_Customer__c' : detail.detail !== 'Ingen' && detail.detail !== 'null' ? this.canChangeOnline = false : null;
                    break;
                    case 'OBS_Check_Customer__c' : //detail.detail !== 'null' ? this.canChangeOnline = false : null;
                            if(detail.detail !== undefined && detail.detail !== 'null') {
                                try {
                                    if (!detail.detail.startsWith('1800-01-01')) {
                                        this.canChangeOnline = false;
                                    }
                                } catch (error) {
                                    this.canChangeOnline = false;
                                }
                            }
                    break;
                    case 'No_Touch__c' : detail.detail !== 'null' ? this.canChangeOnline = false : null;
                    break;
                    // case 'PersonIndividualId' : console.log('PersonIndividualId', detail.detail);
                    // break;
                }
                if( !this.canChangeOnline) {
                    console.warn('manual', detail);

                }
            }
        });
        this.init = true;
    }

}
