import ContentItemCd from './ContentItemCd'
import { OrderFlowInputBlock, OrderflowStepBlock } from '@/definitions/episerver/content-types';
import { ContentBlockType } from './ContentBlockType';
import OrderflowPriceBlockCd from './OrderflowPriceBlockCd';
import UsabillaInPageBlockCd from './UsabillaInPageBlockCd';
import OrderflowInputIntBlockCd from './OrderflowInputIntBlockCd';
import OrderflowCheckboxBlockCd from './OrderflowCheckboxBlockCd';
import MitAbButtonBlockCd from './MitAbButtonBlockCd';
import OrderflowInputStringBlockCd from './OrderflowInputStringBlockCd';

export interface OptionalProperty {
    value: string,
    displayValue: string,
}

export default class OrderflowStepBlockCd extends ContentItemCd {
    public model: OrderflowStepBlock;
    public orderflowPriceBlock: OrderflowPriceBlockCd;
    public inputFields: Array<any> = [];

    public submitCallBack: Function // set after mapping at runtime
    public submitSelectCallBack: Function // set after mapping at runtime
    public cancelCallBack: Function // set after mapping at runtime
    public optionalProperties: Array <OptionalProperty> = []; // set after mapping at runtime
    public selectedOptionalProperty?: string; // set after mapping at runtime
    public allTrackCodes: string; // set after mapping at runtime
    public usabillaInPageBlockCd: UsabillaInPageBlockCd;

    constructor(model: OrderflowStepBlock) {
        super();
        // console.log('!!!!!!!!!!!!OrderflowStepBlock model', model);
        
        this.model = model;
        this.init();
    }

    private init(): void {
        // console.log('OrderflowStepBlockCd!!!!!!!!!!!!!!!!!!!!', this);
        if (this.model.mainArea) {
            this.model.mainArea.forEach(child => {
                // console.log('adding child', child.contentType);
                switch (child.contentType) {
                    
                    case ContentBlockType.OrderflowPriceBlock: this.orderflowPriceBlock = new OrderflowPriceBlockCd(child as any);
                                                        break;
                    case ContentBlockType.MitAbButtonBlock: this.inputFields.push( new MitAbButtonBlockCd(child as any));
                                                        break;
                    case ContentBlockType.UsabillaInPageBlock: this.usabillaInPageBlockCd = new UsabillaInPageBlockCd(child as any); // only support one for now
                                                        break;
                    case ContentBlockType.OrderflowInputBlock : this.setInputType(child as OrderFlowInputBlock);
                                                        break;
                }

            });
        }
        this.allTrackCodes = this.model.trackCode;
        if (this.allTrackCodes && this.model.trackAddCode) {
            this.allTrackCodes += `/${this.model.trackAddCode}`;
        }
        if (!this.model.titleImg) {
            this.model.titleImg = {
                url: undefined,
                type: undefined
            }
        }
    }
    private setInputType(child: OrderFlowInputBlock) {
        // console.log('adding inputField', child.fieldType);
        
        switch (child.fieldType) {
            case 'Tel' :
            case 'Number' : this.inputFields.push(new OrderflowInputIntBlockCd(child as OrderFlowInputBlock));
                break;
            case 'Email' :
            case 'Text' : this.inputFields.push(new OrderflowInputStringBlockCd(child as OrderFlowInputBlock));
                break;
            case 'Boolean' : this.inputFields.push(new OrderflowCheckboxBlockCd(child as OrderFlowInputBlock));
                break;
        }
    }
    public handleSubmit(): Promise<any> {
        if(!this.model.isSubmit) {
            return;
        }
        if(this.inputFields.length > 0) {
            const mappedInput = new Map<string, string> (
                this.inputFields.map(field => [field.model.fieldName, field.value || field.model.defaultValue] as [string, string])
            );
            return this.submitCallBack(mappedInput);
        } else {
            return this.submitCallBack();
        }
    }
}
