import Adapter from "./Adapter";
import { CatalogProductList, CatalogFeature, CatalogProduct } from '@/generated-api/catalog';
import { CustomerAgreementFeature, CustomerAgreementFeatureSubFeature } from '@/generated-api/customer-agreements';
export default class FeatureAdapter extends Adapter {

    public feature: CustomerAgreementFeature;
    public sortIndex?: number;
    public hasAction?: boolean = false;
    public changeFunction?: Function;
    public actionLabel?: string;
    public included?: boolean = false;
    public optionalProperties: Array<string> = [];
    public selectedOptionalProperty?: string;

    public constructor(feature: CustomerAgreementFeature) {
        super(feature.feature_id + "", feature.name, feature.description);
        if(feature.optional_properties && feature.optional_properties.sum && feature.optional_properties.sum.length > 0) {
            this.optionalProperties = feature.optional_properties.sum;
            if(feature.optional_properties.default_sum && feature.optional_properties.default_sum !== '') {
                this.selectedOptionalProperty = feature.optional_properties.default_sum;
            }
        }
        this.feature = feature;
    }

    public static async createFeaturAdaptorFromCatalog(productTitle: string, productList: CatalogProductList, riskList: Array<string>): Promise<Map<string, FeatureAdapter>> {

        const catalogProduct: CatalogProduct = productList.products.find( product => product.title === productTitle);
        // console.log('catalogProduct', catalogProduct);
        // catalogProduct.features.forEach( (feature) => {
        //     console.log('feature', feature.title, feature);
            
        // } )
        const featureMap = new Map<string, CatalogFeature>();
        catalogProduct.features.forEach(element => {
            const code = (element.code.substr(element.code.length - 2, element.code.length)).replace('-', '')
            if (riskList.includes(code)) {
                featureMap.set(code, element);
            }
            
        });
        // console.log('coverages', featureMap);
        const coverages = new Map<string, FeatureAdapter>();

        riskList.forEach( (id) => {
            const feature: CatalogFeature = featureMap.get(id);
            const customerAgreementFeature = {} as CustomerAgreementFeature;
            customerAgreementFeature.description = feature.description;
            customerAgreementFeature.feature_id = feature.feature_id;
            customerAgreementFeature.name = feature.title;
            if (feature.risk_sum && feature.default_risk_sum) {
                customerAgreementFeature.optional_properties = {sum: feature.risk_sum, default_sum: feature.default_risk_sum};
            }
            customerAgreementFeature.sub_features = Array<CustomerAgreementFeatureSubFeature>();
            feature.sub_feature_ids.forEach( (id) => {
                const subFeature = catalogProduct.sub_features.find( sub => sub.sub_feature_id === id);
                (subFeature as any).name = subFeature.title;
                customerAgreementFeature.sub_features.push(subFeature);
            });
            const featureAdapter: FeatureAdapter = new FeatureAdapter(customerAgreementFeature);
            coverages.set(id,featureAdapter);
        });
        return coverages

    }
}
