import GeneralHighlight from "./GeneralHighlight";
import AgreementLineAdapter from '@/views/model/adapter/AgreementLineAdapter';


export default class TraktorHighlight extends GeneralHighlight{

 
    public constructor(productDetails: AgreementLineAdapter, isBusiness: boolean) {
        super(productDetails, isBusiness);
    }


    protected buildHighlights() {
        super.buildHighlights();


        this.highlights.forEach((item, index) => {
            if (item.label === "Køretøjstype") this.highlights.splice(index, 1);
            if (item.label === "Kørsel årligt") this.highlights.splice(index, 1);
        });
    }
}
