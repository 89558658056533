import { Customer, CustomerContactDetails } from '@/generated-api/customer-details/api';
import store from '@/store';
import { isNullOrUndefined } from '@/views/common/util';
import Private from '@/views/layouts/private';
import CustomerAdapter from '@/views/model/adapter/CustomerAdapter';
import InsurancesCommon from '@/views/model/InsurancesCommon';
import { ModalComponent, PopoverComponent, SkeletonLoaderComponent } from '@/views/sharedcomponents/baseComponents/components';
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking";
import { PropType } from 'vue';
import { mixins, Options } from "vue-class-component";
import { mapState } from 'vuex';
import CustomerConsents from './_partials/customerConsents/CustomerConsents';
import CustomerItem from './_partials/customerItem/CustomerItem';

@Options({
    name: 'CustomerBlockComponent',
    //mixins:[template],
    components: {
        CustomerConsents,
        SkeletonLoaderComponent,
        PopoverComponent,
        ModalComponent,
        CustomerItem,
        Private
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<any>
    },
})

export default class CustomerBlockComponent extends mixins(InsurancesCommon) {
    model: any;

    customerObj: Customer = null;
    customer: CustomerAdapter = null;
    customerData: Array<any> = [];
    customerConsents: Array<any> = [];

    isBusiness: boolean;
    consentDesc: string = '';
    addressDesc: string = '';
    changedConsent: string = '';

    trackingUrl: string;
    // LIFECYCLE
    beforeMount() {
        this.customerIsBusiness().then((state) => {
            this.isBusiness = state;

            this.fetchCustomer();
        });

        this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
    }

    async fetchCustomer(): Promise<void> {
        this.customerObj = await this.getCustomer();

        let name = '';
        if (this.customerObj !== undefined) {
            if (this.customerObj.person !== undefined) {
                name = `${this.customerObj.person.firstname} ${this.customerObj.person.lastname}`;
            } else if (this.customerObj.organisation !== undefined) {
                name = this.customerObj.organisation.name;
            }
        }

        this.customer = new CustomerAdapter(this.customerObj, name);
        this.customerData = this.buildCustomerData(this.customerObj);
        this.customerConsents = this.buildCustomerConsents(this.customerObj);
    }

    buildCustomerData(customer: any) {
        let detailsList: Array<any> = [];
        for (let detail of customer.contact.contact_details) {
            switch (detail.type.toLowerCase()) {
                case 'vat_number__c':
                    if (this.isBusiness) {
                        if (isNullOrUndefined(detail.detail) || detail.detail === 'null') {
                            detail.detail = '';
                        }
                        detail.cleanDetail = 'CVR-nummer';
                        detail.showEdit = false;
                        detailsList.unshift(detail);
                    }
                    break;
                case 'email__c':
                    if (isNullOrUndefined(detail.detail) || detail.detail === 'null') {
                        detail.detail = '';
                    }
                    detail.cleanDetail = 'E-mail';
                    detail.showEdit = true;
                    detailsList.push(detail);
                    break;
                case 'mobile__c':
                    if (isNullOrUndefined(detail.detail) || detail.detail === 'null') {
                        detail.detail = '';
                    }
                    detail.cleanDetail = 'Telefon';
                    detail.showEdit = true;
                    detailsList.push(detail);
                    break;
            }
        }
        return detailsList;
    }

    buildCustomerConsents(customer: any) {
        let consentsList: Array<any> = [];

        const setConsentItemData = (consent, cleanDetail: string, description: string, date: string) => {
            consent.cleanDetail = cleanDetail;
            consent.checked = consent.detail.toLowerCase() === 'ja' ? true : false;
            consent.description = description;
            consent.date = null;
            if (consent.checked) {
                consent.date = getConsentDate(date);
            }
            consentsList.push(consent);
        }

        const getConsentDate = (type: string) => {
            for (let consent of customer.contact.contact_details) {
                if (consent.type.toLowerCase() === type) {
                    return consent.detail;
                }
            }
            return null;
        }

        for (let consent of customer.contact.contact_details) {
            switch (consent.type.toLowerCase()) {

                case 'promotion_email__c':
                    setConsentItemData(
                        consent,
                        this.model.marketingConsentHeadline,
                        this.model.marketingConsentText,
                        'promotion_email_date__c'
                    )
                    break;

                case 'promotion_sms__c':
                    setConsentItemData(
                        consent,
                        this.model.smsConsentHeadline,
                        this.model.smsConsentText,
                        'promotion_sms_date__c'
                    )
                    break;
            }
        }

        return consentsList;
    }

    // Emit fra customerConsents
    consentChanged(consentData: any): void {
        let payload = {
            'type': '',
            'value': '',
        };
        payload.type = consentData.type;
        payload.value = (consentData.checked) ? 'Ja' : 'Nej';

        this.changedConsent = 'Du har nu afmeldt ' + consentData.cleanDetail.toLowerCase();
        if (consentData.checked) {
            this.changedConsent = 'Du har nu givet ' + consentData.cleanDetail.toLowerCase();
        }
        if (consentData.type === 'Promotion_SMS__c' || consentData.type === 'Promotion_Email__c') {
            this.changedConsent = this.changedConsent + ' samtykke'
        }

        let customerDetails: Array<any> = [];
        customerDetails.push(payload)

        const modal = this.$refs['consentChanged'] as ModalComponent;

        modal.toggleModal();
        setTimeout(() => { modal.toggleModal(); }, 1500);

        try {
            // Opdater kunden stamdata i salesforce og overskriv lokal cache
            this.updateCustomerConsents(customerDetails).then(customer => {
                this.customerConsents = this.buildCustomerConsents(customer);
            })
        }
        // Hvis exception overskriv data i view laget med cachet data
        catch (e) {
            this.customerConsents = this.buildCustomerConsents(this.customerObj);
        }
    }

    // Emit from customerItem
    masterDataChanged(contactDetails: CustomerContactDetails): void {
        let payload = {
            'cleanDetail': ''
        };
        payload = Object.assign(payload, contactDetails);
        let customerDetails: Array<any> = [];
        // Slet ekstra felt brugt til præsentation
        this.showUpdatedAlert(contactDetails);
        delete payload.cleanDetail;
        customerDetails.push(payload)

        try {
            // Opdater kunden stamdata i salesforce og overskriv lokal cach
            this.updateCustomerDetails(customerDetails).then(customer => {
                this.customerData = this.buildCustomerData(contactDetails);
            })
        }
        // Hvis exception overskriv data i view laget med cachet data
        catch (e) {
            this.customerData = this.buildCustomerData(this.customerObj);
        }
    }

    public showUpdatedAlert(contactDetails: CustomerContactDetails): void {
        if (contactDetails) {
            const type = contactDetails.type.toLowerCase(),
                changeText = document.querySelector(`.changed-text--${type}`),
                btn = document.querySelector(`.btn--${type}`),
                changeTextValue = document.getElementById(`contact-detail--${type}`);

            changeTextValue.innerHTML = contactDetails.detail;

            setTimeout(() => {
                changeText.classList.add('changed-text--open');
                btn.classList.add('btn--success');
                setTimeout(() => {
                    changeText.classList.remove('changed-text--open');
                    btn.classList.remove('btn--success');
                }, 4500);
            }, 500);
        }
    }
}
