<template>
    <div :class="['toggle-styled', { 'toggle-styled--inline': inlineType }]">
        <input v-if="singleSelect"
               class="toggle-styled__input"
               type="radio"
               :id="id"
               :name="name"
               :ref="(name + id)"
               :disabled="disabled"
               :aria-labelledby="labelledby"
               :value="inputValue"
               @change="changed()"
               v-model="change" />
        <input v-else
               class="toggle-styled__input"
               type="checkbox"
               :id="id"
               :name="name"
               :ref="(name + id)"
               :disabled="disabled"
               :aria-labelledby="labelledby"
               :value="inputValue"
               @change="changed()"
               v-model="change" />
        <label v-if="$slots.default" class="toggle-styled__label" :for="id"><slot></slot></label>
        <span v-else class="toggle-styled__label" @click="changeItem()"></span>
    </div>
</template>

<style lang="scss" src="./_assets/_toggle.scss"></style>
