
import { mapState } from 'vuex';
import { SELECTABLE_UPDATED } from '@/episerver/store/modules/selectableContext';
import { CardBlock } from '@/definitions/episerver/content-types';
import { PopoverComponent } from '@/views/sharedcomponents/baseComponents/components';
import { BasketSettings } from '../../basket/BasketSettings';
import { BuyInsuranceHelper, Formatter } from '../../BuyInsuranceHelper';
import OverviewCalculatorStepComponent from './OverviewCalculatorStepComponent.vue';
import { UrlUtil } from '@/episerver/api/urlUtil';
import UrlHandler from '../../UrlHandler';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';

@Options({
    name: 'OverviewBasketStepComponent',
    components: {
        OverviewCalculatorStepComponent,
        PopoverComponent,
    },
    computed: mapState<any>({
        basketCms: state => state.calculatorContext.basket.cms,
        basketModel: state => state.calculatorContext.basket.model,
    }),
    props: {
        card: Object, // Card from cms
        isValid: Boolean, // whether card is complete
    }
})

export default class OverviewBasketStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // whether card is complete

    public basketCms!: BasketSettings; // settings from CMS (store)
    public basketModel!: any;

    public updateProdKey = 'updateProdKey';
        public productNames: Array<string> = [];
    public suggest: {suggestedProducts: string, discountDisplay: string} = {
        suggestedProducts: undefined,
        discountDisplay: undefined
    }
    public suggestText: string = null;
    public suggestText1: string = null;
    public hasPrices: boolean = false;

    private unsubscribe: Function;

    public async created() {
        this.productNames = await store.getters.getSelectableGroup('basket');
        this.unsubscribe = store.subscribeAction((action, state) => {
            if (action.type === SELECTABLE_UPDATED && action.payload?.group === 'basket') {
                this.productNames = store.getters.getSelectableGroup('basket');
                this.updateProdKey += '1';
                this.setSuggestText();
            }
        });
        this.setSuggestText();
        window.addEventListener('keyup' , this.moveOn );
    }

    public beforeUnmount() {
        this.unsubscribe();
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    private setSuggestText() {
        this.suggestText = this.getSuggestText();
        this.suggestText1 = this.getSuggestText1();

    }
    private getSuggestText(): string {
        if(!this.basketCms.suggestProductsText) {
            return undefined;
        }
        this.suggest = BuyInsuranceHelper.getSuggestedProducts(store, this.basketCms);
        if (!this.suggest) {
            return undefined;
        }
        return this.basketCms.suggestProductsText.replace('¤discount', this.suggest.discountDisplay).replace('¤products', this.suggest.suggestedProducts);
    }
    private getSuggestText1(): string {
        if (this.suggest && this.basketCms.suggestProductsText1) {
            return this.basketCms.suggestProductsText1.replace('¤discount', this.suggest.discountDisplay).replace('¤products', this.suggest.suggestedProducts);
        }
        return undefined;
    }

    public get forsikringerLink() {
        const url = UrlUtil.ensureCorrectLink(UrlHandler.path + 'forsikringer');
        return url;
    }

    public getMonthlyTotalPrice(monthly: boolean): string {
        let totalPrice: number = 0;
        this.productNames.forEach(productName => {
            const calculator = store.getters.getCalculator(productName);
            if(calculator && calculator.model && calculator.model.readyForBasket) {
                if(monthly) {
                    totalPrice += calculator.model.choosePackage.selectedPackage.totalPrice;
                } else {
                    totalPrice += calculator.model.choosePackage.selectedPackage.yearlyPriceTotal;
                }
            }
        });
        if (totalPrice) {
            this.hasPrices = true;
            this.$emit('basketPrice', totalPrice);
            return Formatter.format(totalPrice) + ' kr.' + (monthly ? '/md' : '/år');
        }
        this.$emit('basketPrice', 0);
        this.hasPrices = false;
        return 'Ingen produkter beregnet endnu';
    }

    public nextStep(): void {
        this.$emit('nextStep', this.card.routePath);
    }
}
