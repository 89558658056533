import store from "@/store";
import InsurancesCommon from "@/views/model/InsurancesCommon";
import { mixins, Options } from "vue-class-component";
import template from "./PoweredBy.vue";

@Options({
  name: "selfservice-powered-by",
  mixins: [template],
})
export default class PoweredBy extends mixins(InsurancesCommon) {
  // computed

  get themeContext() {
    return store.getters.getThemeContext;
  }

  get logoUrl() {
    return store.getters.getLogoUrl;
  }
}
