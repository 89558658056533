import { Vue, Options, mixins } from "vue-class-component";
import template from './buttonComponent.vue';

    @Options({
        name: 'ButtonComponent',
        mixins: [template],
        props: {
             name!: {type:String, default: 'btn'},
             id: String,
             btnType: String,
             btnClass: String,
             label: String,
             icon: String,
        }
    })

    export default class ButtonComponent extends Vue {
         name?: string;
         id: string;
         btnType: string;
         btnClass: string;
         label: string;
         icon: string;

        public clicked() {
            this.$emit("ButtonComponent", this)
        }
    }
