import { mapState } from "vuex";
import { SelfServiceCalculatorPage } from "@/definitions/episerver/content-types";
import BuyInsuranceComponent from "@/views/calculators/BuyInsuranceComponent.vue";
import { App } from "@/views/sharedcomponents/lib/services/app";
import Utils from "@/views/providers/Utils";
import { Options, Vue } from "vue-class-component";
import {useRoute} from 'vue-router'
import {PropType, computed} from 'vue'

@Options({
    name: 'SelfServiceCalculatorPageComponent',
    components: {
        BuyInsuranceComponent
    },
    computed: mapState<any>({
        isInEditMode: state => state.epiContext.inEditMode
    }),
    props: {
        model: Object as PropType<SelfServiceCalculatorPage>
    },
})

export default class SelfServiceCalculatorPageComponent extends Vue {
    model: SelfServiceCalculatorPage;
    private utils: Utils = new Utils();

    beforeMount() {
        this.utils.setPageMetaData(this.model);
    }

}
