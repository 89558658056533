import { Vue, Options, mixins } from "vue-class-component";
import { mapState } from "vuex";
//import ConfigModel from "@/store/configModel";
import getComponentTypeForContent from '@/episerver/api/contentComponentSelector';
import template from './PageComponentSelector.vue';

@Options({
    name: 'PageComponentSelector',
    mixins: [template],
    computed: mapState<any>({
        model: state => state.epiContent.model,
        modelLoaded: state => state.epiContent.modelLoaded
    }),
})

export default class PageComponentSelector extends Vue {
    getComponentTypeForPage(model) {
        return getComponentTypeForContent(model);
    }  
}
