import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import AgreementStatus from '@/views/model/AgreementStatus';
import template from './PolicyDownloadBlock.vue';
import PolicyDownloadBlock from '../../contentTypes/PolicyDownloadBlockCd'
import store from '@/store';
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking";
import { isNullOrUndefined } from "@/views/common/util";
import { SpinnerComponent } from '@/views/sharedcomponents/baseComponents/components';


@Options({
    name: 'policy-download-block',
    components: {
        SpinnerComponent
    },
    mixins: [template],
    props: {
        contentItem: PolicyDownloadBlock,
        agreementSeqNo: String,
        productTitle: String,
        conditionCode: String,
        status: String
    }
})
export default class PolicyDownloadBlockComponent extends mixins(InsurancesCommon) {
    contentItem: PolicyDownloadBlock;
    agreementSeqNo: string;
    productTitle: string;
    conditionCode: string;
    status: String;
    mutatedConditionCode: string;

    public customerId: string = "";
    public agreementStatus = AgreementStatus;

    trackingUrl: string;

    beforeMount() {
        this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
    }

    mounted() {
        this.customerId = store.getters.getCustomer.customer_id;
        if (!isNullOrUndefined(this.conditionCode)){
            this.mutatedConditionCode = this.conditionCode.replaceAll("_", "-");
        }
    }

    doGetPolicyOrTerms() {
        this.contentItem.model.downloadType === 'Policy' ? this.getPolicy() : this.getConditionPolicy();
    }

    showPolicyOrTermsButton(): boolean {
        if (this.status === this.agreementStatus.IKRAFT &&
            (this.contentItem.model.downloadType === 'Policy' || (this.contentItem.model.downloadType === 'Terms' && this.conditionCode !== 'NOT_FOUND'))) {
            return true;
        }

        return false;
    }

    getPolicy(): void {
        (this.$refs.spinnerComponent as any).startSpinner(); 

        if (this.isLoggedInAsApp()) {
            this.callFromNativeScript(
                {
                    "PDF_REST": `/customer/agreements/${this.agreementSeqNo}/pdf`
                }
            );
        } else {
            const authorization = undefined; // new required parameter
            this.abAxiosSelfservice.customerAgreementsDocumentServiceLayer.fetchCustomerAgreementPdfById(this.agreementSeqNo, authorization,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    },
                    responseType: "blob"
                }
            ).then(resp => {
                this.getPdf(resp.data);
                (this.$refs.spinnerComponent as any).closeSpinner();
            }).catch(err => {
                (this.$refs.spinnerComponent as any).closeSpinner();
                this.$emit('alertToggle', {
                    show: true,
                    title: this.contentItem.model.errorTitle,
                    content: this.contentItem.model.errorText,
                    btnStyle: this.contentItem.model.errorButtonStyle,
                    btnLabel: this.contentItem.model.errorButtonLabel
                });
            });
        }
    }

    async getConditionPolicy() {
        const url = `/siteassets/site-images/pdf/betingelser/${await this.buildConditionCode()}.pdf`;
        if (this.isLoggedInAsApp()) {
            this.callFromNativeScript(
                {
                    "PDF_URL": url
                }
            );
        } else {
            window.open(url);
        }
    }

    async buildConditionCode(): Promise<string> {
        if (await this.agreementIsPartner(this.agreementSeqNo)) {
            const agreement = await this.getCustomerAgreementById(this.agreementSeqNo);
            const brand = this.getBaseInfoValueByName('brand', agreement.agreement_part.fields);
            return (this.mutatedConditionCode + "-" + brand).toLowerCase();
        }
        return this.mutatedConditionCode.toLowerCase();
    }

    getPdf(response): void {
        let date = new Date();
        let formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
        this.downloadFile(response, `${this.productTitle}_police_${formattedDate}.pdf`);
    }

}
