<template>
    <div :class="'page full-width-page ' + getClasses()">
        <div class="row no-gutters">
            <div class="col-12">
                <menu-component v-if="isWeb && !hideMenus" />
                <div class="row no-gutters">
                    <div class="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
                        <div class="page__container">
                            <div class="page__content mb-1">
                                <slot />
                            </div>
                        </div>
                    </div>
                </div>
                <cookie-footer />
                <!--<powered-by />-->
                <app-version-alert v-if="showAlert" />
            </div>
        </div>
    </div>
</template>
