import { isNullOrUndefined } from '@/views/common/util';
import AbAmount from "@/views/common/ab_amount";

const styles = {
    Tilvalg: 'background-color: #F4B608; border-radius: 0.5em; color: white; font-weight: bold; padding: 2px 0.5em;',
    Old_content: 'background-color: #4950B3; border-radius: 0.5em; color: white; font-weight: bold; padding: 2px 0.5em;',
    App_message: 'background-color: #4B53BC; border-radius: 0.5em; color: white; font-weight: bold; padding: 2px 0.5em;',
    Coverages: 'background-color: #C62828; border-radius: 0.5em; color: white; font-weight: bold; padding: 2px 0.5em;',
    BuyInsurance: 'background-color: #00B9EA; border-radius: 0.5em; color: white; font-weight: bold; padding: 2px 0.5em;',

}
class Utils {


    monthNames: Array<string> = ["januar", "februar", "marts", "april", "maj", "juni",
        "juli", "august", "september", "oktober", "november", "december"
    ];

    private abAmount = new AbAmount();

    constructor() {
    }

    public environment = {
        //mobile or desktop compatible event name, to be used with '.on' function
        TOUCH_DOWN_EVENT_NAME: 'mousedown touchstart',
        TOUCH_UP_EVENT_NAME: 'mouseup touchend',
        TOUCH_MOVE_EVENT_NAME: 'mousemove touchmove',
        TOUCH_DOUBLE_TAB_EVENT_NAME: 'dblclick dbltap',

        isAndroid: function () {
            return navigator.userAgent.match(/Android/i);
        },
        isBlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        isIOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        isOpera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        isWindows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        isMobile: function () {
            return (this.isAndroid() || this.isBlackBerry() || this.isIOS() || this.isOpera() || this.isWindows());
        },
        isIE: function () {
            return navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
        }
    }

    public hasValue(val: string): boolean {
        return !(isNullOrUndefined(val) || val === '');
    }

    public isString(str: any) {
        return (str && (typeof str === 'string' || str instanceof String));
    }

    public getBaseInfoValueByName(name: string, baseInfo: any, addCommaBefore = false, addCommaAfter = false, addDotAfter = false): any {
        // fix after new baseInfo api
        if (baseInfo && baseInfo.fields) {
            baseInfo = baseInfo.fields;
        }

        let val: string = "";
        if (!isNullOrUndefined(baseInfo) && baseInfo.length > 0) {
            let baseinfo = baseInfo.find(baseInfo => baseInfo.name === name);
            if (isNullOrUndefined(baseinfo) || baseinfo === "undefined") {
                return val;
            } else {
                val = baseinfo.value;
                if (isNullOrUndefined(val)) {
                    val = "";
                }
            }
        }
        return this.addSeparationSign(val, addCommaBefore, addCommaAfter, addDotAfter);
    }

    public addSeparationSign(val: string, addCommaBefore = false, addCommaAfter = false, addDotAfter = false) {
        if (isNullOrUndefined(val)) {
            return "";
        } else {
            if (val !== "" && addCommaBefore) {
                val = ", " + val;
            }
            if (val !== "" && addCommaAfter) {
                val = val + ", ";
            }
            if (val !== "" && addDotAfter) {
                val = val + ". ";
            }
        }
        return val;
    }


    public formatPrice(value: number): string {
        if (isNullOrUndefined(value) || value === -1) {
            //return "0 kr. /år";
            return;
        }

        return this.abAmount.format(value) + "";
    }

    public formatDate(dateStr: string): string {
        if (isNullOrUndefined(dateStr) || dateStr === "") {
            return "";
        }
        try {
            const date = dateStr.slice(0, 10);
            const dateFormatted = `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(2, 4)}`;
            if (dateFormatted.toLocaleLowerCase().includes("nan")) {
                return "";
            }
            return dateFormatted;
        } catch (err) {
            console.warn(err);
            return dateStr ? dateStr : "";
        }
    }

    public buildDateSeparated(dateStr: string): Array<string> {
        let dateSeparated: Array<string> = [];
        if (isNullOrUndefined(dateStr) || dateStr === "") {
            return null;
        }
        try {
            const date = new Date(dateStr);

            dateSeparated.push(date.getDate() + "");
            dateSeparated.push(this.monthNames[date.getMonth()]);
            dateSeparated.push(date.getFullYear() + "");

            return dateSeparated;
        } catch (err) {
            console.warn(err);
            return null;
        }
    }
    /**
     * Make whole string lowercase and Capitalize 1. Letter
     * If allWords, then take every word to lowercase, split by ' ' and capitalize 1 letter.
     * checks also for double ' '
     * TODO perhaps also check also on '-'
     * @param str
     * @param allWords default = false
     */

    public capitalizeFirstLetter(str: string, allWordsInString: boolean = false) {
        if (!str || str.trim() === '') {
            return '';
        }

        const str_ = str.toLocaleLowerCase();
        if (!allWordsInString) {
            return str_[0].toUpperCase() + str_.slice(1);
        }
        return str_.split(' ').map(w => {
            w = w.trim();
            return w !== '' ? w[0].toUpperCase() + w.slice(1) : w;
        }).join(' ');

      }
      public log(type : string, ...args) {
        const style = styles[type];
        if (styles) {
            console.log(`%c${(type.replace('_', ' '))}`, style, ...args);
        } else {
            console.log(...args);
        }
      }

      /**
       * Beregner alder udfra Cpr nr.
       * alder kan ikke være under 18
       * @param cpr
       */
      public getAgeFromCpr(cpr: string): number {
        const dateNow = new Date();
        const thisYear = dateNow.getFullYear() - 2000;
        let year = parseInt(cpr.slice(4,6));
        if (year > ( thisYear - 18 )) {
            year = 1900 + year;
        } else {
            year = 2000 + year;
        }
        const dateThen = new Date(year, parseInt(cpr.slice(2,4)) -1, parseInt(cpr.slice(0,2)));
        dateThen.setFullYear(year);
        dateThen.setMonth(parseInt(cpr.slice(2,4)) -1);
        dateThen.setDate(parseInt(cpr.slice(0,2)));

        let diffYears =  dateNow.getFullYear() - dateThen.getFullYear();
        const diffMonths =  dateNow.getMonth() - dateThen.getMonth();
        const diffDays =  dateNow.getDate() - dateThen.getDate();

        if(diffMonths < 0) {
            diffYears--;
        }
        if(diffMonths === 0 && diffDays < 0) {
            diffYears--;
        }
        if(diffYears < 18) {
            diffYears += 100;
        }
        return diffYears;
    }

    public isIpad() {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('iPad') > -1) {
            return true;
        }

        if (ua.indexOf('Macintosh') > -1) {
            try {
                document.createEvent("TouchEvent");
                return true;
            } catch (e) { }
        }

        return false;
    }
    public setPageMetaData(model: any) {
        try {
            if (!model) {
                return;
            }
            if (model.metaTitle?.length > 0) {
                document.title = model.metaTitle;
            }
            if (model.metaDescription?.length > 0) {
                document.querySelector('meta[name="description"]').setAttribute("content", model.metaDescription);
            }
            if (model.metaKeywords?.length > 0) {
                document.querySelector('meta[name="keywords"]').setAttribute("content", model.metaKeywords);
            }
        } catch(e) {
            // document.querySelector fails
        }
    }    
}

export default Utils;
