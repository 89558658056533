/**
 * COMPONENTS
 */
/*
import Radio from './components/radioComponent/radioComponent';
import Textarea from './components/textareaComponent/textareaComponent';

import Loader from './components/loaderComponent/loaderComponent';
import Chartjs from './components/chartjsComponent/chartjsComponent';
*/

import Modal from './components/modalComponent/modalComponent';
import SkeletonLoader from './components/skeletonLoaderComponent/skeletonLoaderComponent';
import Link from './components/linkComponent/linkComponent';
import Popover from './components/popoverComponent/popoverComponent';
import SegmentedControl from './components/segmentedControlComponent/segmentedControlComponent';
import InputField from './components/inputFieldComponent/inputFieldComponent';
import Toggle from './components/toggleComponent/toggleComponent';
import Button from './components/buttonComponent/buttonComponent';
import Checkbox from './components/checkboxComponent/checkboxComponent';
import SelectModal from './components/selectModalComponent/selectModalComponent';
import BulletList from './components/bulletListComponent/bulletListComponent';
import Spinner from './components/spinner/SpinnerComponent';


export class ModalComponent extends Modal { };
export class SkeletonLoaderComponent extends SkeletonLoader { };
export class AbLink extends Link { };
export class PopoverComponent extends Popover { };
export class InputFieldComponent extends InputField { };
export class ToggleComponent extends Toggle { };
export class ButtonComponent extends Button { };
export class CheckboxComponent extends Checkbox { };
export class SegmentedControlComponent extends SegmentedControl { };
export class SelectModalComponent extends SelectModal { };
export class BulletListComponent extends BulletList { };
export class SpinnerComponent extends Spinner { };
/*
export class RadioComponent extends Radio { };
export class TextareaComponent extends Textarea { };


export class ChartjsComponent extends Chartjs { };
export class LoaderComponent extends Loader { };
*/