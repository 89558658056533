import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "validatable" }
const _hoisted_2 = {
  key: 1,
  class: "validatable"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex input__field disabled input--valid" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputFieldComponent = _resolveComponent("InputFieldComponent")!
  const _component_ValidationErrorComponent = _resolveComponent("ValidationErrorComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.readonly)
      ? (_openBlock(), _createBlock(_component_InputFieldComponent, {
          key: 0,
          label: _ctx.label,
          labelPopover: _ctx.labelPopover,
          type: _ctx.type,
          id: _ctx.id,
          inputValue: _ctx.inputValue,
          name: _ctx.name,
          placeholder: _ctx.placeholder,
          class: _normalizeClass(_ctx.validationClass),
          onInputFieldComponent: _ctx.handleInput
        }, null, 8, ["label", "labelPopover", "type", "id", "inputValue", "name", "placeholder", "class", "onInputFieldComponent"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                class: "input__label",
                innerHTML: _ctx.label
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", { innerHTML: _ctx.readonly }, null, 8, _hoisted_5)
          ])
        ])),
    _createVNode(_component_ValidationErrorComponent, {
      text: _ctx.validationErrorFormatText,
      showValidationError: _ctx.hasError
    }, null, 8, ["text", "showValidationError"])
  ]))
}