<template>
    <div>
        <div v-for="(chunk,inx) in chunks" :key="'btnG' + chunk[inx]" class="btn-grp-container">
            <button type="button" v-for="index in chunk" :key="'btn' + index"
                :class="[index === selectedBtn ? 'selected' : ''] + ' btn-outline-secondary btn-grp'"
                :style="'width: ' + (100/buttonsPerRow) + '%'"
                @click="buttonClicked(index)">
            {{buttons[index].displayValue}}
            </button>
        </div>
    </div>
</template>
<style lang="scss">
    .btn-grp-container {
        display: flex;
        justify-content: center;
    }
</style>
