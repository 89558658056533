import { Vue, Options, mixins } from "vue-class-component";
import template from './DefaultComponents.vue';
import { ContentBlockType } from "@/views/contentApi/contentTypes/ContentBlockType";
import ContentItem from "@/views/contentApi/contentTypes/ContentItemCd";
import TeaserCardBlockComponent from '@/views/contentApi/components/TeaserCardBlockComponent';
import InfoCardBlockComponent from '@/views/contentApi/components/InfoCardBlockComponent';
import UsabillaInPageBlockComponent from '@/views/contentApi/components/UsabillaInPageBlockComponent';
import CardBlockComponent from '@/views/contentApi/components/CardBlockComponent';

@Options({
    name: 'default-components',
    mixins: [template],
    components: {
        'teaser-card-block-component': TeaserCardBlockComponent,
        'info-card-block-component': InfoCardBlockComponent,
        'usabilla-in-page-block-component': UsabillaInPageBlockComponent,
        'card-block-component': CardBlockComponent
    },
    props:{
        contentItem: ContentItem
    }
})
export default class DefaultComponents extends Vue {
    contentItem: ContentItem;

    public isTeaserBlock(contentItem: ContentItem): boolean {
        return contentItem.isType(ContentBlockType.TeaserCardBlock);
    }
    public isSimpleContactBlock(contentItem: ContentItem): boolean {
        return contentItem.isType(ContentBlockType.SimpleContactBlock);
    }
    public isInfoCardBlock(contentItem: ContentItem): boolean {
        return contentItem.isType(ContentBlockType.InfoCardBlock);
    }
    public isUsabillaBlock(contentItem: ContentItem): boolean {
        return (contentItem.isType(ContentBlockType.UsabillaInPageBlock))
    }
    public isHtmlBlock(contentItem: ContentItem): boolean {
        return contentItem.isType(ContentBlockType.HtmlBlock);
    }
    public isCardBlock(contentItem: ContentItem): boolean {
        return contentItem.isType(ContentBlockType.CardBlock);
    }

    mounted() {
        // console.log(this.contentItem);
    }
}
