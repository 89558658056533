import { Vue, Options, mixins } from "vue-class-component";
import template from './ProductlineFeature.vue';
import ProductlineBody from '../productlinebody';
import ProductlineSubFeature from '../productlinesubfeature';
import FeatureAdapter from "@/views/model/adapter/FeatureAdapter";
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking";
import store from '@/store';

@Options({
    name: 'productline-feature-component',
    mixins: [template],
    components: {
        'productline-body-component': ProductlineBody,
        'productline-sub-feature-component': ProductlineSubFeature,
    },
    props: {
        coverage: Object,
        isCovered: Boolean,
        track: Boolean,  // track using css classes
        trackGtm: Boolean,// track using gtm attributes
        trackGtmLabel: String,// optional label for gtm attributes
        productTitle: String,
        noCheckMark: Boolean,
        btnClass: String,
        btnLabel: String,
        isThemed: Boolean,
    }
})
export default class ProductlineFeature extends Vue{
    coverage: FeatureAdapter;
    isCovered: boolean;
    track: boolean;  // track using css classes
    trackGtm: boolean;// track using gtm attributes
    trackGtmLabel?: string;// optional label for gtm attributes
    productTitle: String;
    noCheckMark: boolean;
    btnClass: string;
    btnLabel: string;
    isThemed: boolean;
    trackingUrl: string;

    public refreshKey: number = Math.random();

    beforeMount() {
        this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
        
    }
    public animateController() {
        if (this.coverage.description || this.coverage.feature.sub_features.length > 0 ) {
            this.coverage.selected = !this.coverage.selected;
            this.refreshKey = Math.random();
        }
    }
    public getBtnText(): string {
        return (this.coverage.actionLabel ? this.coverage.actionLabel : this.btnLabel);
    }

    public getTrackGtmLabel(): string {
        if (this.trackGtm) {
            if (this.trackGtmLabel) {
                return `${this.trackGtmLabel} - ${this.coverage.title}`;
            }
            return this.coverage.title;
        }
        return undefined;
    }

}
