import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";
import store from '@/store';
import { UsabillaInPageBlock } from "@/definitions/episerver/content-types";
import { isNullOrUndefined } from "@/views/common/util";

@Options({
    name: 'usabilla-in-page-block',
    props: {
        model: Object as PropType<UsabillaInPageBlock>,
        // contentItem: Object as PropType<any>,
        headlineClasses: String,
        'data-epi-block-id': String
    }
})
export default class UsabillaInPageBlockComponent extends Vue{
    model: UsabillaInPageBlock;
    // contentItem: any;
    headlineClasses: string;

    private static waiting: boolean = false;
    beforeCreate() {
        store.dispatch("setUsabillaCount");
    }

    mounted() {
        // tell store that one usabilla div is mountedreloadUsabillas(
        store.dispatch('setUsabillaMounted');
        UsabillaInPageBlockComponent.reloadUsabillas(this);
    }

    public static reloadUsabillas(usabillaInPageBlockComponent?: UsabillaInPageBlockComponent) {
        // no usabilla component present
        if(!usabillaInPageBlockComponent) {
            return;
        }
        // check if this is the last mounted usabilla div
        const usabilla = store.getters.getUsabillaState;
        const site = (!isNullOrUndefined(usabillaInPageBlockComponent.model) && !isNullOrUndefined(usabillaInPageBlockComponent.model.siteId)) ? usabillaInPageBlockComponent.model.siteId : "e9e9f96ce675";

        // console.log('calling reloadUsabillas', usabilla.mounted, usabilla.count, (window as any).usabilla !== undefined);
        if (usabilla.mounted === usabilla.count && (window as any).usabilla) {
            if (UsabillaInPageBlockComponent.waiting) {
                // console.log('skipping usabillas');
                return;
            }
            UsabillaInPageBlockComponent.waiting = true;

            setTimeout(() => {
                const iframes = document.querySelectorAll('[ub-in-page] iframe' ) as any;
                // console.log('usabillas', iframes);
                if(iframes && iframes.length && (window as any).usabilla) {
                    iframes.forEach(element => {
                        element.parentNode.removeChild(element);
                    });
                }

                // console.log('reloading usabilla script');
                (window as any).usabilla.load("w.usabilla.com", site);
                UsabillaInPageBlockComponent.waiting = false;
            }, 1000);

        }
    }
}
