import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import template from './ProductlineBody.vue';
import FeatureAdapter from "@/views/model/adapter/FeatureAdapter";
import { isNullOrUndefined } from '@/views/common/util';
import InsurancesProductEnum from "@/views/model/InsurancesProductEnum";
import InsurancesProductType from "@/views/model/InsurancesProductType";

@Options({
    name: 'selfservice-insurances-productlinebody-component',
    mixins: [template],
    props: {
        feature: Object,
        productTitle: String,
        hasAction: Boolean,
        btnClass: String,
        btnLabel: String
    }
})
export default class ProductlineBody extends mixins(InsurancesCommon) {

    feature: FeatureAdapter;
    productTitle: string;
    hasAction: boolean;
    btnClass: string;
    btnLabel: string;

    public formattedText: string = "";
    public highlights: Array<any> = [];

    mounted() {
        this.formattedText = this.formatTextToClickable(this.feature.description);
        this.setDefaultHighlights();
    }

    private setDefaultHighlights() {
        const title = this.productTitle.toLocaleLowerCase();

        if (!isNullOrUndefined(this.feature.feature.sum) && this.feature.feature.sum > 0) {
            this.highlights.push({
                label: 'Forsikringssum',
                value: this.formatPrice(this.feature.feature.sum) + ' kr.'
            });
        }

        //TODO skal flyttes i integration
        if (title === InsurancesProductEnum.BIL_FORSIKRING && isNullOrUndefined(this.feature.feature.excess)) {
            const ungfoererProductType = new InsurancesProductType(this.feature.feature.feature_id);
            //ung fører skal ikke vise: selvrisiko ingen.
            if (ungfoererProductType.productRiskNo == 14) {
                return;
            }
        }

        switch (title) {
            case InsurancesProductEnum.SAELGERANSVARS_FORSIKRING:
                const insurancesProductType = new InsurancesProductType(this.feature.feature.feature_id);
                if (insurancesProductType.productRiskNo == 1) {
                    this.highlights.push({
                        label: 'Selvrisiko',
                        value: '10 % af skadeudgifterne,<br /> dog minimum 10.000 kr.'
                    });
                }
                break;
            case InsurancesProductEnum.BYGNINGS_FORSIKRING:
                break;
            default:
                if (!isNullOrUndefined(this.feature.feature.excess)) {
                    this.highlights.push({
                        label: 'Selvrisiko',
                        value: this.formatPrice(this.feature.feature.excess) + ' kr.'
                    });
                }
        }


        switch (title) {
            case InsurancesProductEnum.INDBO_FORSIKRING:
            case InsurancesProductEnum.BIL_FORSIKRING:
            //case InsurancesProductEnum.HUS_FORSIKRING:
            //case InsurancesProductEnum.EJERSKIFTE_FORSIKRING:
            case InsurancesProductEnum.FRITIDSHUS_FORSIKRING:
                if (isNullOrUndefined(this.feature.feature.excess)) {
                    this.highlights.push({
                        label: 'Selvrisiko',
                        value: 'Ingen'
                    });
                }

            default:
                break;
        }
    }

    public hasSubfeatures(): boolean {
        const subFeatures = this.feature.feature.sub_features;
        return subFeatures && subFeatures.length > 0;
    }

    public getBtnText(): string {
        return (this.feature.actionLabel ? this.feature.actionLabel : this.btnLabel);
    }
}
