// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Customer Agreement API
 * # Customer Agreement API The Customer Agreement API allows client applications access to resources used in the Customer Journey 
 *
 * OpenAPI spec version: 0.0.1.01
 * Contact: P.Integration@almbrand.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "https://localhost:8080".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface CommonLink
 */
export interface CommonLink {
    /**
     * 
     * @type {string}
     * @memberof CommonLink
     */
    href?: string;
}

/**
 * 
 * @export
 * @interface CommonLinks
 */
export interface CommonLinks {
    /**
     * 
     * @type {CommonLink}
     * @memberof CommonLinks
     */
    self?: CommonLink;
    /**
     * 
     * @type {CommonLink}
     * @memberof CommonLinks
     */
    first?: CommonLink;
    /**
     * 
     * @type {CommonLink}
     * @memberof CommonLinks
     */
    prev?: CommonLink;
    /**
     * 
     * @type {CommonLink}
     * @memberof CommonLinks
     */
    next?: CommonLink;
    /**
     * 
     * @type {CommonLink}
     * @memberof CommonLinks
     */
    last?: CommonLink;
}

/**
 * 
 * @export
 * @interface CommonProblem
 */
export interface CommonProblem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof CommonProblem
     */
    type?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof CommonProblem
     */
    title?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof CommonProblem
     */
    status?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof CommonProblem
     */
    detail?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof CommonProblem
     */
    instance?: string;
}

/**
 * Holding the information for a customer agreement
 * @export
 * @interface CustomerAgreement
 */
export interface CustomerAgreement {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    agreement_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    agreement_line_no?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    agreement_line_seq_no?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    condition_code?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreement
     */
    cancel_code?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    tags?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    type_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    first_start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    end_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreement
     */
    next_payment_date?: string;
    /**
     * 
     * @type {CustomerAgreementPart}
     * @memberof CustomerAgreement
     */
    agreement_part?: CustomerAgreementPart;
    /**
     * 
     * @type {CustomerAgreementProductInstance}
     * @memberof CustomerAgreement
     */
    product_instance?: CustomerAgreementProductInstance;
    /**
     * Indicates if this agreement is the latest version of an agreement. Used by the UI to determine if this agreement is eligable for an \"upgrade\" to the latest version
     * @type {boolean}
     * @memberof CustomerAgreement
     */
    latest_version?: boolean;
    /**
     * Indicates if it is possible to change an agreement through our API. If this is set to true that means that is possible to update the agreement itself or add/remove features under an agremeent. Note this is not related to the \"latest_version\" property, as it might be possible to change an agreement even though it is not the latest version
     * @type {boolean}
     * @memberof CustomerAgreement
     */
    changeable?: boolean;
}

/**
 * 
 * @export
 * @interface CustomerAgreementBundle
 */
export interface CustomerAgreementBundle {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementBundle
     */
    bundle_id?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementBundle
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementBundle
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementBundle
     */
    description?: string;
    /**
     * 
     * @type {Array<CustomerAgreementFeature>}
     * @memberof CustomerAgreementBundle
     */
    features?: Array<CustomerAgreementFeature>;
}

/**
 * 
 * @export
 * @interface CustomerAgreementDocument
 */
export interface CustomerAgreementDocument {
    /**
     * 
     * @type {any}
     * @memberof CustomerAgreementDocument
     */
    file?: any;
}

/**
 * 
 * @export
 * @interface CustomerAgreementExcessSum
 */
export interface CustomerAgreementExcessSum {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementExcessSum
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementExcessSum
     */
    text?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementExcessSum
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementExcessSum
     */
    units?: string;
}

/**
 * 
 * @export
 * @interface CustomerAgreementFeature
 */
export interface CustomerAgreementFeature {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementFeature
     */
    feature_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementFeature
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementFeature
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementFeature
     */
    excess?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementFeature
     */
    sum?: number;
    /**
     * 
     * @type {Array<CustomerAgreementFeatureSubFeature>}
     * @memberof CustomerAgreementFeature
     */
    sub_features?: Array<CustomerAgreementFeatureSubFeature>;
    /**
     * 
     * @type {CustomerAgreementFeatureOptionalProperties}
     * @memberof CustomerAgreementFeature
     */
    optional_properties?: CustomerAgreementFeatureOptionalProperties;
}

/**
 * 
 * @export
 * @interface CustomerAgreementFeatureOptionalProperties
 */
export interface CustomerAgreementFeatureOptionalProperties {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerAgreementFeatureOptionalProperties
     */
    sum?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementFeatureOptionalProperties
     */
    default_sum?: string;
}

/**
 * 
 * @export
 * @interface CustomerAgreementFeatureSubFeature
 */
export interface CustomerAgreementFeatureSubFeature {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementFeatureSubFeature
     */
    sub_feature_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementFeatureSubFeature
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementFeatureSubFeature
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementFeatureSubFeature
     */
    excess?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementFeatureSubFeature
     */
    sum?: number;
}

/**
 * 
 * @export
 * @interface CustomerAgreementPart
 */
export interface CustomerAgreementPart {
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementPart
     */
    part_no?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPart
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPart
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPart
     */
    person_name?: string;
    /**
     * 
     * @type {CustomerAgreementPartAddress}
     * @memberof CustomerAgreementPart
     */
    address?: CustomerAgreementPartAddress;
    /**
     * 
     * @type {Array<CustomerAgreementPartField>}
     * @memberof CustomerAgreementPart
     */
    fields?: Array<CustomerAgreementPartField>;
}

/**
 * 
 * @export
 * @interface CustomerAgreementPartAddress
 */
export interface CustomerAgreementPartAddress {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPartAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPartAddress
     */
    street_no?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPartAddress
     */
    street_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPartAddress
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPartAddress
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPartAddress
     */
    floor_side?: string;
}

/**
 * 
 * @export
 * @interface CustomerAgreementPartField
 */
export interface CustomerAgreementPartField {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPartField
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPartField
     */
    value?: string;
}

/**
 * 
 * @export
 * @interface CustomerAgreementPrice
 */
export interface CustomerAgreementPrice {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPrice
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPrice
     */
    payment_type?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementPrice
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementPrice
     */
    amount_total?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementPrice
     */
    fee?: number;
    /**
     * 
     * @type {Array<CustomerAgreementPriceDetails>}
     * @memberof CustomerAgreementPrice
     */
    price_details?: Array<CustomerAgreementPriceDetails>;
    /**
     * 
     * @type {Array<CustomerAgreementExcessSum>}
     * @memberof CustomerAgreementPrice
     */
    excesses?: Array<CustomerAgreementExcessSum>;
    /**
     * 
     * @type {Array<CustomerAgreementExcessSum>}
     * @memberof CustomerAgreementPrice
     */
    sums?: Array<CustomerAgreementExcessSum>;
    /**
     * 
     * @type {Array<CustomerAgreementBundle>}
     * @memberof CustomerAgreementPrice
     */
    included_bundles?: Array<CustomerAgreementBundle>;
    /**
     * 
     * @type {Array<CustomerAgreementFeature>}
     * @memberof CustomerAgreementPrice
     */
    included_features?: Array<CustomerAgreementFeature>;
    /**
     * 
     * @type {Array<CustomerAgreementBundle>}
     * @memberof CustomerAgreementPrice
     */
    optional_bundles?: Array<CustomerAgreementBundle>;
    /**
     * 
     * @type {Array<CustomerAgreementFeature>}
     * @memberof CustomerAgreementPrice
     */
    optional_features?: Array<CustomerAgreementFeature>;
}

/**
 * 
 * @export
 * @interface CustomerAgreementPriceDetails
 */
export interface CustomerAgreementPriceDetails {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPriceDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementPriceDetails
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementPriceDetails
     */
    amount?: number;
}

/**
 * 
 * @export
 * @interface CustomerAgreementProductInstance
 */
export interface CustomerAgreementProductInstance {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductInstance
     */
    product_id?: string;
    /**
     * 
     * @type {Array<CustomerAgreementProductParameters>}
     * @memberof CustomerAgreementProductInstance
     */
    product_parameters?: Array<CustomerAgreementProductParameters>;
    /**
     * 
     * @type {CustomerAgreementPrice}
     * @memberof CustomerAgreementProductInstance
     */
    price?: CustomerAgreementPrice;
}

/**
 * Agreement Property
 * @export
 * @interface CustomerAgreementProductParameters
 */
export interface CustomerAgreementProductParameters {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    frequency?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    min?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    max?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    min_value?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    max_value?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementProductParameters
     */
    value?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerAgreementProductParameters
     */
    values?: Array<string>;
}

/**
 * Holding the information for a customer agreement
 * @export
 * @interface CustomerAgreementSummary
 */
export interface CustomerAgreementSummary {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    agreement_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    agreement_line_no?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    agreement_line_seq_no?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementSummary
     */
    cancel_code?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    tags?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    type_code?: string;
    /**
     * 
     * @type {CustomerAgreementSummaryPrice}
     * @memberof CustomerAgreementSummary
     */
    price?: CustomerAgreementSummaryPrice;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    payment_type?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementSummary
     */
    fee?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    first_start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummary
     */
    end_date?: string;
    /**
     * 
     * @type {Array<CustomerAgreementPart>}
     * @memberof CustomerAgreementSummary
     */
    agreement_parts?: Array<CustomerAgreementPart>;
}

/**
 * AgreementList
 * @export
 * @interface CustomerAgreementSummaryList
 */
export interface CustomerAgreementSummaryList {
    /**
     * 
     * @type {CustomerAgreementSummaryPage}
     * @memberof CustomerAgreementSummaryList
     */
    page?: CustomerAgreementSummaryPage;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementSummaryList
     */
    price?: number;
    /**
     * 
     * @type {Array<CustomerHouseholdRelationship>}
     * @memberof CustomerAgreementSummaryList
     */
    household_relationships?: Array<CustomerHouseholdRelationship>;
    /**
     * 
     * @type {Array<CustomerAgreementSummary>}
     * @memberof CustomerAgreementSummaryList
     */
    agreements?: Array<CustomerAgreementSummary>;
    /**
     * 
     * @type {CommonLinks}
     * @memberof CustomerAgreementSummaryList
     */
    _links?: CommonLinks;

    page_price: any;
}

/**
 * Holding the information for pagination
 * @export
 * @interface CustomerAgreementSummaryPage
 */
export interface CustomerAgreementSummaryPage {
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementSummaryPage
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementSummaryPage
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementSummaryPage
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementSummaryPage
     */
    number?: number;
}

/**
 * 
 * @export
 * @interface CustomerAgreementSummaryPrice
 */
export interface CustomerAgreementSummaryPrice {
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummaryPrice
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAgreementSummaryPrice
     */
    payment_type?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementSummaryPrice
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementSummaryPrice
     */
    amount_total?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAgreementSummaryPrice
     */
    fee?: number;
}

/**
 * Holding the information for household persons
 * @export
 * @interface CustomerHouseholdRelationship
 */
export interface CustomerHouseholdRelationship {
    /**
     * 
     * @type {string}
     * @memberof CustomerHouseholdRelationship
     */
    for_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerHouseholdRelationship
     */
    last_name?: string;
}


/**
 * CustomerAgreementApi - axios parameter creator
 * @export
 */
export const CustomerAgreementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetch and match existing customer agreements with items i cart
         * @param {string} agreement_line_seq_no Unique id for an agreement.
         * @param {number} [part_no] A part number parameter of the agreement part
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerAgreementById(agreement_line_seq_no: string, part_no?: number, options: any = {}): RequestArgs {
            // verify required parameter 'agreement_line_seq_no' is not null or undefined
            if (agreement_line_seq_no === null || agreement_line_seq_no === undefined) {
                throw new RequiredError('agreement_line_seq_no','Required parameter agreement_line_seq_no was null or undefined when calling fetchCustomerAgreementById.');
            }
            const localVarPath = `/customer/agreements/{agreement_line_seq_no}`
                .replace(`{${"agreement_line_seq_no"}}`, encodeURIComponent(String(agreement_line_seq_no)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (part_no !== undefined) {
                localVarQueryParameter['part_no'] = part_no;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fetch and match existing customer agreements with items i cart
         * @param {number} [page] A pagination parameter
         * @param {number} [size] A pagination parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerAgreementList(page?: number, size?: number, options: any = {}): RequestArgs {
            const localVarPath = `/customer/agreements`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAgreementApi - functional programming interface
 * @export
 */
export const CustomerAgreementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetch and match existing customer agreements with items i cart
         * @param {string} agreement_line_seq_no Unique id for an agreement.
         * @param {number} [part_no] A part number parameter of the agreement part
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerAgreementById(agreement_line_seq_no: string, part_no?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAgreement> {
            const localVarAxiosArgs = CustomerAgreementApiAxiosParamCreator(configuration).fetchCustomerAgreementById(agreement_line_seq_no, part_no, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary fetch and match existing customer agreements with items i cart
         * @param {number} [page] A pagination parameter
         * @param {number} [size] A pagination parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerAgreementList(page?: number, size?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAgreementSummaryList> {
            const localVarAxiosArgs = CustomerAgreementApiAxiosParamCreator(configuration).fetchCustomerAgreementList(page, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CustomerAgreementApi - factory interface
 * @export
 */
export const CustomerAgreementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary fetch and match existing customer agreements with items i cart
         * @param {string} agreement_line_seq_no Unique id for an agreement.
         * @param {number} [part_no] A part number parameter of the agreement part
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerAgreementById(agreement_line_seq_no: string, part_no?: number, options?: any) {
            return CustomerAgreementApiFp(configuration).fetchCustomerAgreementById(agreement_line_seq_no, part_no, options)(axios, basePath);
        },
        /**
         * 
         * @summary fetch and match existing customer agreements with items i cart
         * @param {number} [page] A pagination parameter
         * @param {number} [size] A pagination parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerAgreementList(page?: number, size?: number, options?: any) {
            return CustomerAgreementApiFp(configuration).fetchCustomerAgreementList(page, size, options)(axios, basePath);
        },
    };
};

/**
 * CustomerAgreementApi - object-oriented interface
 * @export
 * @class CustomerAgreementApi
 * @extends {BaseAPI}
 */
export class CustomerAgreementApi extends BaseAPI {
    /**
     * 
     * @summary fetch and match existing customer agreements with items i cart
     * @param {string} agreement_line_seq_no Unique id for an agreement.
     * @param {number} [part_no] A part number parameter of the agreement part
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAgreementApi
     */
    public fetchCustomerAgreementById(agreement_line_seq_no: string, part_no?: number, options?: any) {
        return CustomerAgreementApiFp(this.configuration).fetchCustomerAgreementById(agreement_line_seq_no, part_no, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary fetch and match existing customer agreements with items i cart
     * @param {number} [page] A pagination parameter
     * @param {number} [size] A pagination parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAgreementApi
     */
    public fetchCustomerAgreementList(page?: number, size?: number, options?: any) {
        return CustomerAgreementApiFp(this.configuration).fetchCustomerAgreementList(page, size, options)(this.axios, this.basePath);
    }

}

/**
 * CustomerAgreementDocumentApi - axios parameter creator
 * @export
 */
export const CustomerAgreementDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetch customer document from WCC
         * @param {string} agreement_line_seq_no Unique id for an agreement.
         * @param {string} [authorization] bearer authorization used to call document service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerAgreementPdfById(agreement_line_seq_no: string, authorization?: string, options: any = {}): RequestArgs {
            // verify required parameter 'agreement_line_seq_no' is not null or undefined
            if (agreement_line_seq_no === null || agreement_line_seq_no === undefined) {
                throw new RequiredError('agreement_line_seq_no','Required parameter agreement_line_seq_no was null or undefined when calling fetchCustomerAgreementPdfById.');
            }
            const localVarPath = `/customer/agreements/{agreement_line_seq_no}/pdf`
                .replace(`{${"agreement_line_seq_no"}}`, encodeURIComponent(String(agreement_line_seq_no)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAgreementDocumentApi - functional programming interface
 * @export
 */
export const CustomerAgreementDocumentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetch customer document from WCC
         * @param {string} agreement_line_seq_no Unique id for an agreement.
         * @param {string} [authorization] bearer authorization used to call document service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerAgreementPdfById(agreement_line_seq_no: string, authorization?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = CustomerAgreementDocumentApiAxiosParamCreator(configuration).fetchCustomerAgreementPdfById(agreement_line_seq_no, authorization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CustomerAgreementDocumentApi - factory interface
 * @export
 */
export const CustomerAgreementDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary fetch customer document from WCC
         * @param {string} agreement_line_seq_no Unique id for an agreement.
         * @param {string} [authorization] bearer authorization used to call document service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCustomerAgreementPdfById(agreement_line_seq_no: string, authorization?: string, options?: any) {
            return CustomerAgreementDocumentApiFp(configuration).fetchCustomerAgreementPdfById(agreement_line_seq_no, authorization, options)(axios, basePath);
        },
    };
};

/**
 * CustomerAgreementDocumentApi - object-oriented interface
 * @export
 * @class CustomerAgreementDocumentApi
 * @extends {BaseAPI}
 */
export class CustomerAgreementDocumentApi extends BaseAPI {
    /**
     * 
     * @summary fetch customer document from WCC
     * @param {string} agreement_line_seq_no Unique id for an agreement.
     * @param {string} [authorization] bearer authorization used to call document service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAgreementDocumentApi
     */
    public fetchCustomerAgreementPdfById(agreement_line_seq_no: string, authorization?: string, options?: any) {
        return CustomerAgreementDocumentApiFp(this.configuration).fetchCustomerAgreementPdfById(agreement_line_seq_no, authorization, options)(this.axios, this.basePath);
    }

}

