<template>
    <li>
        <router-link :to="linkData" class="basic-list__link">
            <div class="basic-list__item basic-list__item--cols">
                <div :class="[{ 'd-flex' : isThemed }, 'basic-list__item__col--1']">
                    <img v-if="themeBrandSimple" :src="themeBrandSimple" class="basic-list__item__image" />
                    <div class="basic-list__item__inner-wrapper">
                        <h3 class="basic-list__item__title">{{agreement.title}}</h3>
                        <p class="basic-list__item__subtitle">{{productDesc}}</p>
                    </div>
                </div>

                <template v-if="productValueDesc !== ''">
                    <div class="basic-list__item__col--2 basic-list__item__value">
                        {{productValueDesc}}
                        <span v-if="!isOneTimePayment" class="d-flex ml-1">
                            kr <span class="text-grey-3 ml-1">/år</span>
                        </span>
                    </div>
                </template>

                <div v-else>
                    <div class="basic-list__item__value">
                        <p v-if="agreement.status === agreementStatus.TILBUD">
                            Gyldig fra {{formatDate(startDate)}} <span class="card__dot-warning"></span>
                        </p>
                        <p v-else-if="agreement.status === agreementStatus.AFVENTER">
                            Afventer, ikke i kraft <span class="card__dot-danger"></span>
                        </p>
                        <p v-else-if="agreement.status === agreementStatus.NY_AENDRING">
                            Igangværende ændring af forsikring <span class="card__dot-warning"></span>
                        </p>
                    </div>
                </div>
            </div>
        </router-link>
    </li>
</template>
