import { mixins, Options } from 'vue-class-component';
import { mapState } from 'vuex';
// import '@trevoreyre/autocomplete-vue/dist/style.css'
import UsabillaInPageBlockComponent from '@/views/contentApi/components/UsabillaInPageBlockComponent/UsabillaInPageBlock.vue';
import { AccidentSettings } from './AccidentSettings';
import { Model, STEPS } from'./Model';
import AccidentCalculator from './AccidentCalculator';
import ChoosePackageStepComponent from '../steps/common/ChoosePackageStepComponent.vue';
import ContactInformationStepComponent from '../steps/common/ContactInformationStepComponent.vue';
import OverviewStepComponent from '../steps/common/OverviewStepComponent.vue';
import AdditionalInfoStepComponent from '../steps/common/AdditionalInfoStepComponent.vue';
import PersonInfoStepComponent from '../steps/accident/PersonInfoStepComponent.vue';
import PaymentStepComponent from '../steps/common/PaymentStepComponent.vue';
import RecieptStepComponent from '../steps/common/RecieptStepComponent.vue';
import { BuyInsuranceHelper, STEP, Validator } from '../BuyInsuranceHelper';
import ModalComponentCalculator from '../components/ModalComponentCalculator.vue';
import CampaignStepComponent from '../steps/common/CampaignStepComponent.vue';
import { cloneDeep } from "lodash";
import { PropType } from 'vue';
import { CardBlock, HtmlBlock } from '@/definitions/episerver/content-types';
import InsurancesCommon from '@/views/model/InsurancesCommon';
import store from '@/store';
import { CALCULATOR_LOADED, INIT_CALCULATOR, RESET_CALCULATORS } from '@/episerver/store/modules/calculatorContext';
import { EnvironmentService } from '@/views/sharedcomponents/lib/services/environmentService';
import TransitionExpand from '../components/TransitionExpand';

@Options({
    name: 'selfserviceBuyInsuranceAccidentComponent',
    props: {
        contentBlocks: Object as PropType< Array<CardBlock>>,
        cardReciept: Object as PropType<CardBlock>,
        settingsBlock: Object as PropType<HtmlBlock>,
        usabillaBlock: Object as PropType<UsabillaInPageBlockComponent>,
        usabillaBlockLeaveIntent: Object as PropType<UsabillaInPageBlockComponent>,
        contentUrl: String, // Used by helper to handle browser history
        headerBadgeTxt: String,
    },
    components: {
        ModalComponentCalculator,
        UsabillaInPageBlockComponent,
        PersonInfoStepComponent,
        ChoosePackageStepComponent,
        OverviewStepComponent,
        AdditionalInfoStepComponent,
        ContactInformationStepComponent,
        PaymentStepComponent,
        RecieptStepComponent,
        CampaignStepComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
})

export default class BuyAccident extends mixins(InsurancesCommon) {
    contentBlocks: Array<CardBlock>;
    cardReciept: CardBlock;
    settingsBlock: HtmlBlock;
    usabillaBlock: UsabillaInPageBlockComponent;
    usabillaBlockLeaveIntent: UsabillaInPageBlockComponent;
    contentUrl: string; // Used by helper to handle browser history
    headerBadgeTxt: string;
    // list and order of steps
    public steps : Array<string> = STEPS

    public model!: any; // model from store
    public cms!: AccidentSettings; // setting from store
    public helper: BuyInsuranceHelper;
    public calculator: AccidentCalculator = null;
    public componentInit = false;
    public checkout = false;
    public transitionExpand = new TransitionExpand();

    // TODO KLK 
    // @Watch('$route', { deep: true }) // used for history back button
    // onUrlChange(params: any) {
    //     this.helper.onUrlChange(params);
    // }

    public async created() {
        this.helper = new BuyInsuranceHelper(this);

        if (!store.getters.getActiveCalculator) { // this is first load - update store
            const cms = new AccidentSettings(this.settingsBlock);
            if(cms.mockData === true) {
                Model.mock();
            }
            await store.dispatch(INIT_CALCULATOR, {cms, model: cloneDeep(Model.model)});
        } else {
            await store.dispatch(CALCULATOR_LOADED);
        }

        if (!await this.helper.initComponent()) {
            return;
        }

        this.calculator = new AccidentCalculator(this);

        this.helper.setFirstCard();

    }

    // called by helper
    public addResetSubscription() {
        const unsubscribe = store.subscribeAction((action, state) => {
            if (action.type === RESET_CALCULATORS) {
                setTimeout(() => {
                    this.checkout = true;
                    store.dispatch(INIT_CALCULATOR, {cms : this.cms, model: cloneDeep(Model.model)});
                     unsubscribe();
                }, 2000);
            }
        });
    }

    public get showValidNowOption() {
        return this.helper.showValidNowOption;
    }

    public async gotoCard(cardName: string): Promise<boolean> {

        if(cardName === STEP.PERSON_INFO || cardName === STEP.PACKAGE) {
            BuyInsuranceHelper.resetSelectedCalculation(this);
            // altid kun en risk (dummy)
            this.model.choosePackage.ownRiskId = this.model.calculation.abCalc.excessIdDefault;
        }

        if(cardName === STEP.PACKAGE) {
            if (!this.checkCustomerAge()) {
                this.gotoCard(STEP.PERSON_INFO);
                    return false;
            }
            this.calculator.setupExcessList();
        }

        if(cardName === STEP.PAYMENT) {
            // force user to choose yearly/monthly payment to ensure progressive steps
            this.model.choosePackage.monthYear = undefined;
        }

        this.model.currentCardName = cardName;

        if(cardName === STEP.ORDER) {
            await this.orderByEmail();
            return false;
        }
        return true;
    }

    private checkCustomerAge(): boolean {
        const otherCustomer = this.model.personInfo.otherCustomerAge !== undefined;
        const customerAge = otherCustomer ? this.model.personInfo.otherCustomerAge : this.model.personInfo.customerAge;

        let ok = false;
        if (!otherCustomer) {
            ok = this.helper.handleValidCustomerAge();
        } else {
            ok = this.handleValidOtherCustomerAge();
        }
        if (!ok) {
            return false;
        }


        if (this.model.personInfo.customerAgeChecked) {
            return true;
        }

        ok = customerAge < this.cms.maxCustomerAge;
        if (!ok) {
            this.model.modal.title = otherCustomer ? this.cms.customerOtherMaxAgePopupTitle : this.cms.customerMaxAgePopupTitle;
            this.model.modal.content = otherCustomer ? this.cms.customerOtherMaxAgePopupContent : this.cms.customerMaxAgePopupContent;
            this.model.modal.redirect = otherCustomer ? this.cms.customerOtherMaxAgePopupRedirect : this.cms.customerMaxAgePopupRedirect;
            this.model.modal.btnSecondLabel = this.model.modal.redirect ? 'Afslut' : 'Ok';
            this.model.modal.track = false;
            this.model.modal.id = 'customerMaxAgePopup';
            this.model.modal.show = true;
            this.toggleModal();
            this.model.personInfo.customerAgeChecked = true;
        }
        // clean customer age input

        this.model.personInfo.customerAge = this.model.personInfo.customerAge.trim().replace(/\D/g,'');
        return ok;
    }

    private handleValidOtherCustomerAge(): boolean {
        const ok = Validator.isValidAge(this.model.personInfo.otherCustomerAge);
        if (!ok) {
            this.model.modal.title = this.cms.customerOtherAgePopupTitle;
            this.model.modal.content = this.cms.customerOtherAgePopupContent;
            this.model.modal.redirect = this.cms.customerOtherAgePopupRedirect;
            this.model.modal.btnSecondLabel = this.model.modal.redirect ? 'Afslut' : 'Ok';
            this.model.modal.track = false;
            this.model.modal.id = 'customerOtherAgePopup';
            this.model.modal.show = true;
            this.toggleModal();
        }
        // clean customer age input
        this.model.personInfo.otherCustomerAge = this.model.personInfo.otherCustomerAge.trim().replace(/\D/g,'');
        return ok;
    }


    /**
     * when nextbtn is clicked - check for valid
     * @param cardName
     */
    public nextStep(cardName: string, addToHistory: boolean = true) {
        this.helper.nextStep(cardName, addToHistory);
    }

    public getSubtitle(cardName: string) {
        return this.helper.getSubtitle(cardName);
    }

    public isActiveCard(cardName: string): boolean {
        return cardName === this.model.currentCardName;
    }

    public getCardTitle(card: CardBlock): string {
        if (card.name === STEP.PACKAGE && this.model.choosePackage.selectedPackage !== undefined) {
            return this.cms.calculatedHeadline;
        }
        if (store.getters.getActiveCalculatorInx > 0) {
            if (card.name === STEP.PERSON_INFO) {
                return 'Om person';
            }
            if (card.name === STEP.PACKAGE) {
                return 'Pris';
            }
        }

        return card.title;
    }

    public isValid(cardName): boolean {
        switch(cardName) {
            case STEP.PERSON_INFO:
                if (store.getters.getActiveCalculatorInx > 0) {
                    if (this.model.personInfo.otherName) {
                        const res = this.model.personInfo.otherName.match(/^[a-zA-Z\æøå\s]+/ig);
                        if (res && res[0]?.length > 1) {
                            this.model.personInfo.otherName = res[0];
                        }
                    }
                    return this.model.personInfo.workId && this.helper.isValidOtherPersonInfo();
                }
                return this.model.personInfo.workId && this.helper.isValidPersonInfo();
            case STEP.PACKAGE:
                return this.helper.isValidPackage();
            case STEP.OVERVIEW:
                return this.helper.isValidPackage();
            case STEP.CONTACT_INFORMATION:
                const ok = this.helper.isValidContactInfo();
                if (ok) {
                    if(this.model.personInfo.otherCustomerAge) {
                        return Validator.isValidCpr(this.model.personInfo.otherCpr);
                    }
                }
                return ok;
            case STEP.ADDITIONAL_INFO:
                return this.helper.isValidAdditionalInfo(false);
            case STEP.PAYMENT:
                return this.helper.isValidPaymentInfo();
            default: return true;
        }
    }

    // called by helper
    public setOverviewData() {
        this.helper.setHighLights();
        if (this.model.personInfo.isMainPerson) {
            this.model.overview.details = [
                'Gælder dig',
                this.model.personInfo.customerAge + ' år',
                this.model.personInfo.zipCode + ', ' + this.model.personInfo.zipName,
                this.model.personInfo.work,
            ];
        } else {
            this.model.overview.details = [
                this.model.personInfo.otherName,
                this.model.personInfo.otherCustomerAge + ' år',
                this.model.personInfo.work];
        }
        this.helper.setDetailsYearlyPrice();
    }

    public async orderByEmail() {
        try {
            this.model.showSpinner = true;
            if (!this.isValid(this.model.currentCardName)) {
                this.model.showSpinner = false;
                return;
            }
            const pack = this.model.choosePackage.selectedPackage;
            let desc = '';

            let tracking = '';

            // if (this.cms.createOnlineQuote) {
            //     await this.calculator.createQuote(pack.name).then( (quote) => {
            //         desc += `<li><h2><i>Tilbud er oprettet i TIA</i></h2></li>`;
            //         tracking += "/quotecreated";

            //     }).catch( (err) => {
            //         desc += `<li><h2><i>Tilbud kunne IKKE oprettes i TIA</i></h2></li>`;
            //         tracking += "/quotenotcreated";
            //         // this.modal.id = 'createQuoteError'
            //         // this.modal.title = this.cms.defaultCalcErrorTitle;
            //         // this.modal.content = this.cms.defaultCalcErrorContent;
            //         // this.modal.btnSecondLabel = 'Ok';
            //         // this.modal.show = true;
            //         // this.toggleModal();
            //         // this.data.showSpinner = false;
            //         // this.gotoCard(STEP.CAR_INFO);
            //         // return;
            //     });
            // }
            if (this.model.contact_information.customerNo) {
                //app/web
                const device = EnvironmentService.isApp() ? '(APP)' : '(WEB)';
                desc += `<li><h2><i>Kunde genkendt ${device}</i></h2></li>`;
                tracking += "/existingCustomer";
            }
            if (this.model.campaign.valid) {
                const id = this.model.campaign.ID ? this.model.campaign.ID.toLocaleUpperCase() : 'Ukendt!';
                desc += `<li><h2><i>Kampagne ${id}</i></h2></li>`;
                tracking += `/campaign/${id}`;
            }

            if(this.model.additionalInfo.rki?.toLowerCase() === 'ja') {
                desc += '<li><h2><i>Kunde er registreret i RKI</i></h2></li>';
                // tracking += "/rki";
            }
            if (this.model.additionalInfo.skader !== '0') {
                desc += `<li><h2><i>Kunde har ${this.model.additionalInfo.skader} skader</i></h2></li>`;
                // tracking += "/damages";
            }

            if(this.model.personInfo.customerAge > this.cms.seniorStep1) {
                desc += `<li><h2><i>Senior kunde (${this.model.personInfo.customerAge} år), tjek dækninger</i></h2></li>`;
                tracking += "/seniorcustomer";
            }

            if( desc !== '') {
                desc = '<ul>' + desc + '</ul>'
            }

            await this.helper.submitOrder(desc, tracking);

            const price: number = this.model.choosePackage.monthYear === 'M' ? pack.totalPrice * 12 : pack.yearlyPriceTotal;
            const product = {
                name: 'UP_UP',
                // sku: 'SKU',
                id: pack.name,
                price: '' + price,
                brand: 'Fakedoor flow',
                // brand: this.data.carInfo.car,
                // brand: 'BRAND',
                variant: undefined,
                category: 'Online Salg',
                quantity: '1'
            }
            this.helper.trackPurchase(product, price);

        } catch(e) {
            this.model.showSpinner = false;
            if (this.isDevelop()) {
                this.model.modal.content = this.model.modal.content + '<br><div style="border: 2px solid red">' + e +'</div> ';
            }
            this.toggleModal({
                    id: 'error',
                    show: true,
                    redirect: undefined,
                    track: true,
                }
            );
            this.helper.sendErrorMailToTeam(e);
        }
    }

    /** Called by helper */
    public buildDescriptionForEmail(desc: string) {
        const addProp: Function = this.helper.addProp;
        const pack = this.model.choosePackage.selectedPackage;
        desc += '<h2>Person</h2>';
        const now: Date = new Date();
        desc += addProp('Email sendt', `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${(now.getMonth()+1)}-${now.getFullYear()}`);
        desc += addProp('Navn', this.model.contact_information.name);
        desc += addProp('Postnr./by', `${this.model.personInfo.zipCode} ${this.model.personInfo.zipName}`);
        desc += addProp('Alder', this.model.personInfo.customerAge);
        if (this.model.contact_information.customerNo) {
            desc += addProp('KundeNr', this.model.contact_information.customerNo);
        } else {
            desc += addProp('Cpr', this.model.contact_information.cpr);
        }

        desc += addProp('Antal skader', this.model.additionalInfo.skader);
        desc += addProp('Kunde har accepteret betingelser', this.model.contact_information.accept ? 'Ja' : 'Nej');
        desc += addProp('Email', this.model.contact_information.email);
        desc += addProp('Telefonnummer', this.model.contact_information.phone);

        desc += this.helper.buildExistingProductsDescription();

        desc += '<h2>Forsikring</h2>';
        desc += addProp('Rki', this.model.additionalInfo.rki);
        desc += addProp('Eksisterende forsikring', this.model.additionalInfo.existInsurance);

        if (this.model.additionalInfo.existInsurance === 'ja') {
            desc += addProp('Nuværende selskab', this.model.additionalInfo.existingInsurance);
        }

        desc += addProp('Gyldig hurtigst mulig', this.model.additionalInfo.validNow);
        if(this.model.additionalInfo.validNow === 'nej') {
            desc += addProp('Gyldig pr.', this.model.additionalInfo.validFromDate);
        }


        desc += '<h2>Ønsket forsikring</h2>';
        desc += addProp('Forsikringstype', pack.name);
        if (!this.model.personInfo.isMainPerson && this.model.personInfo.otherName) {
            desc += addProp('Navn', this.model.personInfo.otherName);
            desc += addProp('Alder', this.model.personInfo.otherCustomerAge);
            desc += addProp('Cpr', this.model.personInfo.otherCpr);
        }

        desc += addProp('Arbejde', `${this.model.personInfo.work}`);
        desc += addProp('Arbejde-id', `${this.model.personInfo.workId}`);

        // desc += addProp('Selvrisiko', this.getExcessAmount());
        desc += addProp('<br>Betaling', this.model.choosePackage.monthYear === 'M' ? 'Månedlig' : 'Årlig');
        desc += addProp('Samlet pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += addProp('Beregningstype', 'Tia beregning');
        desc += addProp('Dækninger', this.helper.getCoverageNames(pack));

        desc += '<h2>Betaling</h2>';
        desc += addProp('Reg. nr.', this.model.payment.regNo);
        desc += addProp('Konto nr.', this.model.payment.kontoNo);

        const params: Map<string, string> = new Map();
        params.set('description', desc);
        return params;
    }

    public toggleModal(data: any = {}) {
        this.helper.toggleModal(data);
    }
    public gotoAddInsurances(step: string) {
        this.helper.gotoAddInsurances(step);
    }

}
