import { TileBlock } from "@/definitions/episerver/content-types";
import { App } from "@/views/sharedcomponents/lib/services/app";
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";

@Options({
  name: "TileApp",
  components: {},
  computed: mapState<any>({
    isEditable: (state) => state.epiContext.isEditable,
    parentModel: (state) => state.epiContent.model,
  }),
  props: {
    model: Object as PropType<TileBlock>,
    trackingUrl: String,
  },
})
export default class TileApp extends Vue {
  model: TileBlock;
  trackingUrl: string;

  isApp: boolean = false;

  // LIFECYCLE
  beforeMount() {
    this.isApp = App.isEnabled();
  }

  // METHODS
  sendMessage() {
    App.sendMessage(JSON.parse(this.model.appMessage));
  }
}
