/**
 *  This class is for adding misc stuff
*/
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import ScrollConfig from '@/definitions/ScrollConfig';

class AbMisc {

    constructor() {}

    public initToolTips(): void {
        const toolTips: Array<HTMLElement> = Array.from(document.querySelectorAll('[data-dfnid]'));
        if (toolTips === null || toolTips === undefined || toolTips.length < 1) return;

        toolTips.forEach((toolTip) => {
            toolTip.addEventListener('keyup', (evt) => {
                evt.preventDefault();
                const _this: HTMLElement = (<HTMLElement>evt.srcElement);
                // "Enter"
                if (evt.keyCode === 13) {
                    const ddfId: string = _this.getAttribute('data-dfnid');
                    const ddf = document.getElementById(ddfId);
                    if (ddf.classList.contains('hide')) {
                        // hint is not shown - then show it with the click
                        _this.click();
                    } else {
                        // hint is shown - toggle hide class
                        ddf.click();
                    }
                }
            });
        });
    }

    /**
     * This is used in search
     * @param name
     */
    public getQueryStringValues(name: string): Array<string> {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "g"),
            results: Array<string> = [];
        let m;
        while ((m = regex.exec(url)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            results.push(decodeURIComponent(m[2].replace(/\+/g, " ")));
        }
        if (results.length === 0) {
            return undefined;
        }
        return results;
    };

    /**
     *  For stuff that brakes in IE. Only use if absolutly necessary
     * */
    public browserIe11OrOlder() {
        let bool = false;
        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
            bool = true;
        }
        return bool;
    }

    /**
     * For checking if user is on a mobile device
     * */
    public isMobileDevice() {
        let bool = false;
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
            bool = true;
        }
        return bool;
    }

    public initWordDefinition(delayMilis) {
        setTimeout(function () {
            (window.document as any)._initWordDefinition.now();
        }, delayMilis);
    }

    public initClickScroll() {
        const scrollTos = document.querySelectorAll("[data-scroll-to], [v-scroll-to]");
        if(scrollTos && scrollTos.length > 0) {
            //const vueScrollTo = VueScrollTo;
            //Vue.use(VueScrollTo);
            scrollTos.forEach((element) => {
                const scrollTo: string = element.getAttribute("data-scroll-to") || element.getAttribute("v-scroll-to");
                if( !scrollTo || scrollTo === '#') {
                    return;
                }
                // remove hashtags needed for old scroll.js
                const scrollId = scrollTo.replace('#','');
                const elementToScrollTo = document.getElementById(scrollId);
                if (elementToScrollTo) {
                    // offset: -90 default from old scroll.js
                    const scrollCfg = Object.assign({}, ScrollConfig, { offset: -90});
                    let offset = parseInt(element.getAttribute("data-offset"));
                    if (!isNaN(offset)) {
                        // old scroll js had positive numbers for data-offset, should have been negative
                        if ( offset > 0 ) {
                            offset *= -1;
                        }
                        scrollCfg.offset = offset;
                    } 
                    scrollCfg.easing = element.getAttribute("data-easing") || ScrollConfig.easing;
                    const duration = element.getAttribute("data-duration") || 500;
                    element.addEventListener('click', () => {
                        //vueScrollTo.scrollTo(elementToScrollTo, duration, scrollCfg);
                    });
                }
            });
        }
    }

    /**
     * 
     * @param theObject
     * @param path
     * @param separator
     *
     *  var snack = {
        id: "0001",
        name: "Cake",
        batters:
                {
                    batter:
                        [
                            { id: "1001", type: "Regular" },
                            { id: "1002", type: "Chocolate" },
                            { id: "1003", type: "Blueberry" },
                        ]
                }
            };

        getNested(snack, 'batters.batter').length;                 // --> 3
        getNested(snack, 'batters.batter.2.id');                   // --> "1003"
        getNested(snack, 'batters.batter[1].id');                  // --> "1002"
        getNested(snack, 'batters.batter[99].id') || 0;            // --> 0
        getNested(snack, 'batters.batter.nutrition') || 'none';    // --> 'none'
        getNested(snack, 'batters/batter/0/id', '/');              // --> "1001"
     */
    getNested(theObject, path, separator) {
        try {
            separator = separator || '.';

            return path.
                replace('[', separator).replace(']', '').
                split(separator).
                reduce(
                    function (obj, property) {
                        return obj[property];
                    }, theObject
                );

        } catch (err) {
            return undefined;
        }
    }

    decodeJwtToken(token: string): string{
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    cleanAttr(attr: Attr): string {
        if (attr !== null && attr.value.replace(/(\r\n\t|\n|\r\t)/gm, "").length > 0) {
            return attr.value.replace(/(\r\n\t|\n|\r\t)/gm, "");
        }

        return "";
    }
    /**
     * 
     * @param obj
     *
     * checks if object is empty
     */
    isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    
}

export default AbMisc;


