import GeneralHighlight from "./GeneralHighlight";
import AgreementLineAdapter from '@/views/model/adapter/AgreementLineAdapter';


export default class HundeHighlight extends GeneralHighlight{

 
    public constructor(productDetails: AgreementLineAdapter, isBusiness: boolean) {
        super(productDetails, isBusiness);
    }


    protected buildHighlights() {
        super.buildHighlights();
    }
}
