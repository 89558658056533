import GeneralHighlight from "./GeneralHighlight";
import AgreementLineAdapter from '@/views/model/adapter/AgreementLineAdapter';

export default class RejseHighlight extends GeneralHighlight{

    public constructor(productDetails: AgreementLineAdapter, isBusiness: boolean) {
        super(productDetails, isBusiness);
    }


    protected buildHighlights() {
        super.buildHighlights();

        // Dækning will be updated from OrderflowTravelPropertyHandler.ts

        this.highlights.splice(1, 0, {
            label: 'Selvrisiko',
            value: 'Ingen'
        });

        this.highlights.push({
            label: 'Dækning',
            value: ''
        });

    }
}
