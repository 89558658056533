import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "accordion" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_productline_feature_component = _resolveComponent("productline-feature-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coverages, (coverage) => {
      return (_openBlock(), _createBlock(_component_productline_feature_component, {
        key: coverage.id,
        coverage: coverage,
        isCovered: true,
        track: _ctx.track,
        trackGtm: _ctx.trackGtm,
        trackGtmLabel: _ctx.trackGtmLabel,
        productTitle: "Dummy"
      }, null, 8, ["coverage", "track", "trackGtm", "trackGtmLabel"]))
    }), 128))
  ]))
}