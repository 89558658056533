import { TeaserCardBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd'

export default class TeaserCardBlockCd extends ContentItemCd {
    public model: TeaserCardBlock
    // public imageUrl: string;
    // public backgroundColor: string;
    // public title: string;
    // public text: string;
    // public isNewWindow: boolean = false;
    // public buttonLink: string;
    // public buttonStyle: string;
    // public buttonText: string;
    public subCardBlocks: Array<any> = [];

    constructor(model: TeaserCardBlock) {
        super();
        this.model = model;
        this.init();

    }

    public init(): void {
        if (this.model.backgroundColor) {
            this.model.backgroundColor = 'background-color:' + this.model.backgroundColor;
        }

        if (this.model.mainArea?.length > 0) {
            this.model.mainArea.forEach(item => {
                const subItem = {
                    "title": (item as any).title,
                    "text": (item as any).text
                }
                this.subCardBlocks.push(subItem);
            })
        }
    }


}
