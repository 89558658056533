import { Vue, Options, mixins } from "vue-class-component";
import InsurancesCommon from '@/views/model/InsurancesCommon';
import InsurancesProductEnum from "@/views/model/InsurancesProductEnum";
import template from './InsuranceListitem.vue';
import { CustomerAgreementSummary } from '@/generated-api/customer-agreements'
import { UrlUtil } from "@/episerver/api/urlUtil";
import AgreementStatus from '@/views/model/AgreementStatus';
import { PropType } from "vue";
import store from "@/store";

@Options({
    name: 'InsuranceListItemComponent',
    mixins: [template],
    props: {
        agreement: Object as PropType<CustomerAgreementSummary>
    }
})
export default class InsuranceListItem extends mixins(InsurancesCommon) {
    agreement!: CustomerAgreementSummary;
    agreementStatus = AgreementStatus;
    basePath = store.getters.getConfigEpi.disableBasePathForLegacyRouting ? "" : UrlUtil.baseUrlPath;

    // COMPUTED
    get productDesc() {
        return this.getBaseInfoDescription(this.agreement.title, this.agreement.agreement_parts, this.agreement.description)
    }

    get productValueDesc() {
        if (this.isOneTimePayment) {
            return `Gyldig til ${new Date(this.agreement.end_date).getFullYear()}`;
        }

        return this.agreement.price.amount_total ? this.utils.formatPrice(this.agreement.price.amount_total) : '';
    }

    get isOneTimePayment() {
        return this.agreement.payment_type === 'one_time_amount';
    }

    get themeBrandSimple() {
        return this.$root['themeBrandSimple'];
    }

    get linkData() {
        if (this.agreement && this.agreement.title.toLowerCase() === InsurancesProductEnum.BYGNINGS_FORSIKRING) {
            // Mellemside hvor man f.eks. kan vælge mellem bygninger på et b1 produkt
            return {
                path: this.basePath + '/forsikring/bygninger',
                query: {
                    id: this.agreement.agreement_line_seq_no
                }
            }
        } else {
            return {
                path: this.basePath + '/forsikring/mineforsikringer/' + this.replaceDanishChars(this.agreement.title.toLowerCase()),
                query:{
                    id: this.agreement.agreement_line_seq_no
                }
            }
        }
    }
}
