import OrderFlowModel from "../OrderFlowModel";
import Utils from "@/views/providers/Utils";

export default class Adapter {
    public id: string;
    public title: string;
    public description: string;

    public image?: string;
    public price?: number;
    public selected?: boolean;
    public included?: boolean;
    public linkText?: string;
    public routePath?: string;
    public inactive?: boolean;
    public height?: string;
    public buttonStyle?: string;
    public noBottomMargin?: boolean;
    public noBorder?: boolean;

    public orderFlowList?: Array<OrderFlowModel> = [];

    private utils = new Utils();

    public constructor(id: string, title: string, description: string, price: number = -1,
        selected: boolean = false, image: string = "", linkText: string = "", routePath: string = "",
        inactive: boolean = false, height: string = "", buttonStyle: string = "", noBottomMargin: boolean = false,
        noBorder: boolean = false) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.price = price;
        this.selected = selected;
        this.image = image;
        this.linkText = linkText;
        this.buttonStyle = buttonStyle;
        this.routePath = routePath;
        this.inactive = inactive;
        this.height = height;
        this.noBottomMargin = noBottomMargin;
        this.noBorder = noBorder;
    }

    get formattedPrice(): string {
        return this.utils.formatPrice(this.price);
    }

    public formatDate(dateStr: string): string {
        return this.utils.formatDate(dateStr);
    }
}
