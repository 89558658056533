import { mixins, Options } from 'vue-class-component';
import VueScrollTo from "vue-scrollto";
import { mapState } from 'vuex';
import InsurancesCommon from '@/views/model/InsurancesCommon';
import { CardBlock, HtmlBlock } from '@/definitions/episerver/content-types';
import UsabillaInPageBlockComponent from '@/views/contentApi/components/UsabillaInPageBlockComponent/UsabillaInPageBlock.vue';
import { BasketSettings } from './BasketSettings';
import { Model, STEPS } from'./Model';
import RecieptStepComponent from '../steps/common/RecieptStepComponent.vue';
import {BuyInsuranceHelper, STEP, ValuePair, monthYearArray, Validator , Formatter} from '../BuyInsuranceHelper';
import { BasketProductsEnum, BASKET_LOADED, DISCOUNT_UPDATE, RESET_CALCULATORS, UPDATE_BASKET } from '@/episerver/store/modules/calculatorContext';
import ModalComponentCalculator from '../components/ModalComponentCalculator.vue';
import CampaignStepComponent from '../steps/common/CampaignStepComponent.vue';
import PaymentBasketStepComponent from '../steps/basket/PaymentBasketStepComponent.vue';
import OverviewBasketStepComponent from '../steps/basket/OverviewBasketStepComponent.vue';
import ContactInformationBasketStepComponent from '../steps/basket/ContactInformationBasketStepComponent.vue';
import { RESET_SELECTABLE} from '@/episerver/store/modules/selectableContext';
import InsurancesProductEnum from '@/views/model/InsurancesProductEnum';
import { cloneDeep } from "lodash";
import { EnvironmentService } from '@/views/sharedcomponents/lib/services/environmentService';
import UrlHandler from '../UrlHandler';
import store from '@/store';
import TransitionExpand from '../components/TransitionExpand';

@Options({
    name: 'selfserviceBasketComponent',
    props: {
        contentBlocks: Array,
        cardReciept: Object,
        settingsBlock: Object,
        usabillaBlock: UsabillaInPageBlockComponent,
        usabillaBlockLeaveIntent: UsabillaInPageBlockComponent,
        contentUrl: String, // Used by helper to handle browser history
        headerBadgeTxt: String,
    },
    components: {
        ModalComponentCalculator,
        UsabillaInPageBlockComponent,
        RecieptStepComponent,
        CampaignStepComponent,
        OverviewBasketStepComponent,
        ContactInformationBasketStepComponent,
        PaymentBasketStepComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext.basket.model,
        cms: state => state.calculatorContext.basket.cms,
    }),
    
})

export default class BasketComponent extends mixins(InsurancesCommon) {
    contentBlocks: Array<CardBlock>;
    cardReciept: CardBlock;
    settingsBlock: HtmlBlock;
    usabillaBlock: UsabillaInPageBlockComponent;
    usabillaBlockLeaveIntent: UsabillaInPageBlockComponent;
    contentUrl: string; // Used by helper to handle browser history
    headerBadgeTxt: string;

    // list and order of steps
    public steps : Array<string> = STEPS

    public model!: any; // model (store)
    public cms!: BasketSettings; // settings (store)
    public helper: BuyInsuranceHelper;
    public monthYearArray: Array<ValuePair> = monthYearArray;
    public componentInit = false;
    public checkout = false;
    public transitionExpand = new TransitionExpand();

    public checkoutProducts: Array<{
        name:string,
        id: string,
        price: string,
        brand: string,
        category: string,
        quantity: number}> = [];

        // TOD= KLK 
    // @Watch('$route', { deep: true }) // used for history back button
    // onUrlChange(params: any) {
    //     if (!this.model.showReciept) {
    //         if (!!params.query.step) {
    //             this.nextStep(params.query.step, false);
    //         }
    //         else {
    //             this.nextStep(this.cms.defaultCard, true);
    //         }
    //     } else {
    //         // pressing basket
    //         this.model.showReciept = false;
    //         this.nextStep(this.cms.defaultCard, true);
    //     }
    // }

    public async created() {
        this.helper = new BuyInsuranceHelper(this);
        UrlHandler.handlePreselectedProducts(this.$route.query);
        if (!store.getters.getBasket) {
            // this is first load - update store
            const cms = new BasketSettings(this.settingsBlock);
            await store.dispatch(UPDATE_BASKET, {cms, model: cloneDeep(Model.model)});
            // check for contact_information
            const basket = store.getters.getSelectableGroup('basket');
            basket.forEach( (product) => {
                const calc = store.getters.getCalculator(product);
                if (calc && calc.model?.contact_information?.customerNo) {
                    this.model.contact_information.customerNo = calc.model.contact_information.customerNo;
                    if(calc.model.contact_information.name) {
                        this.model.contact_information.name = calc.model.contact_information.name;
                    }
                    if (calc.model.contact_information.phone) {
                        this.model.contact_information.phone = calc.model.contact_information.phone;
                    }
                    if (calc.model.contact_information.email) {
                        this.model.contact_information.email = calc.model.contact_information.email;
                    }
                }
            });
            const rebate = this.helper.getDiscount();
            this.model.calculation.discount = rebate.discount;
            this.model.calculation.discountDisplay = rebate.discountDisplay;
        } else {
            await store.dispatch(BASKET_LOADED);
        }
        // check if discount has changed since last view of basket
        await store.dispatch(DISCOUNT_UPDATE);
        // this.handleDiscountChange();
        if (!await this.helper.initComponent()) {
            return;
        }

        setTimeout(() => {
            if (this.model.campaign.valid && this.model.campaign.hasContentPage) {
                this.nextStep(this.model.campaign.ID);
            } else {
                this.nextStep(this.cms.defaultCard);
            }
            this.model.showSpinner = false;
        }, this.cms.initialTimeout);

        this.model.showSpinner = true;
        this.componentInit = true;

    }

    public handlePrice(totalMonthlyPrice: number) {
        const rebate = this.helper.getDiscount();
        this.setSubtitle(rebate, totalMonthlyPrice);
    }

    public async gotoCard(cardName: string): Promise<boolean> {
        if(cardName === STEP.PAYMENT) {
            this.model.choosePackage.monthYear = undefined;
        }

        if(cardName === STEP.ORDER) {
            await this.orderByEmail();
            // currentCard is being set by succes of orderByEmail
            return false;
        }

        this.model.currentCardName = cardName;
        return true;
    }

    /**
     * when nextbtn is clicked - check for valid
     * @param cardName
     */
    public nextStep(cardName: string, addToHistory: boolean = true) {
        this.helper.nextStep(cardName, addToHistory);
    }

    public getSubtitle(cardName: string) {
        return this.helper.getSubtitle(cardName);
    }

    public isActiveCard(cardName: string): boolean {
        return cardName === this.model.currentCardName;
    }

    private setSubtitle(rebate: {discount: number, discountDisplay: string}, totalMonthlyPrice: number): void {
        let subtitle = '';
        if (rebate.discount < 1) {
            subtitle = this.cms.subtitleDiscount + rebate.discountDisplay;
        }
        if (totalMonthlyPrice) {
            if (subtitle !== '') {
                subtitle += ',';
            }
            subtitle += this.cms.subtitlePrice + Formatter.format(totalMonthlyPrice) + ' kr./md';
        }
        this.model.overview.subtitle = subtitle;
    }


    public isValid(cardName): boolean {
        switch(cardName) {
            case STEP.OVERVIEW:
                return store.getters.getSelectableGroup('basket').length > 1;
            case STEP.CONTACT_INFORMATION:
                let ok = this.model.contact_information.rki === 'nej' && this.helper.isValidContactInfo();
                if (!ok) {
                    return false;
                }
                const basket = store.getters.getSelectableGroup('basket');
                basket.forEach(product => {
                    if (ok && product.startsWith(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
                        const model = store.getters.getCalculator(product)?.model;
                        if (model?.readyForBasket && model.personInfo.otherCustomerAge) {
                            ok = Validator.isValidCpr(model.personInfo.otherCpr);
                        }
                    }
                });

                if (!ok) {
                    return false;
                }

                let model;
                if (basket.includes(BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0)) {
                    model = store.getters.getCalculator(BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0)?.model;
                } else if (basket.includes(BasketProductsEnum.BOERNEULYKKES_FORSIKRING0)) {
                    model = store.getters.getCalculator(BasketProductsEnum.BOERNEULYKKES_FORSIKRING0)?.model;
                }
                if(!model?.readyForBasket) {
                    // product not calculated
                   return true;
                }
                if (model?.familyInfo?.personCountId > -1) {
                    for (let i = 0; i <= model.familyInfo.personCountId; i++) {
                        const cpr = 'cpr'+ i;
                        if(model.familyInfo[cpr] === undefined || Validator.getAgeUnderEighteen(model.familyInfo[cpr]) < 0) {
                            ok = false;
                            i = model.familyInfo.personCountId;
                        }
                    }
                }

                return ok;

            case STEP.PAYMENT:
                return this.helper.isValidPaymentInfo();
            default: return true;
        }
    }

    public async orderByEmail() {
        try {
            this.model.showSpinner = true;
            if (!this.isValid(this.model.currentCardName)) {
                this.model.showSpinner = false;
                return;
            }
            // get commonModel for caclulators
            this.model.personInfo = await store.getters.getCalculatorCommonModel.personInfo;

            const productNames = await store.getters.getSelectableGroup('basket');
            let price: number = 0;
            let missingCalculations: boolean = false;
            const monthly = this.model.choosePackage.monthYear === 'M';
            productNames.forEach(productName => {
                const calculator = store.getters.getCalculator(productName);
                if (calculator && calculator.model?.readyForBasket) {
                    if(monthly) {
                        price += calculator.model.choosePackage.selectedPackage.totalPrice;
                    } else {
                        price += calculator.model.choosePackage.selectedPackage.yearlyPriceTotal;
                    }
                } else {
                    missingCalculations = true;
                }
            });

            let desc = '';

            let tracking = '';

            if(this.model.contact_information.customerNo) {
                const device = EnvironmentService.isApp() ? '(APP)' : '(WEB)';
                desc += `<li><h2><i>Kunde genkendt ${device}</i></h2></li>`;
                tracking += "/existingCustomer";
            }

            if(this.model.campaign.valid) {
                const id = this.model.campaign.ID ? this.model.campaign.ID.toLocaleUpperCase() : 'Ukendt!'
                desc += `<li><h2><i>Kampagne ${id}</i></h2></li>`;
                tracking += `/campaign/${id}`;
            }
            if(this.model.contact_information.rki?.toLowerCase() === 'ja') {
                desc += '<li><h2><i>Kunde er registreret i RKI</i></h2></li>';
                // tracking += "/rki";
            }
            if (this.model.contact_information.skader !== '0') {
                desc += `<li><h2><i>Kunde har ${this.model.contact_information.skader} skader</i></h2></li>`;
                // tracking += "/damages";
            }
            if(missingCalculations) {
                desc += `<li><h2><i>Én eller flere beregninger mangler</i></h2></li>`;
                tracking += "/missingCalculations";
            }
            if( desc !== '') {
                desc = '<ul>' + desc + '</ul>'
            }

            await this.submitOrder(desc, tracking, price, missingCalculations);

            const product = {
                name: this.model.productName, // kurv
                // sku: 'SKU',
                id: productNames.toString(),
                price: '' + price,
                brand: 'Fakedoor flow',
                variant: undefined,
                category: 'Online Salg',
                quantity: '1'
            }
            await store.dispatch(RESET_SELECTABLE, 'basket');
            this.helper.trackPurchase(product, price);
            this.model.currentCardName = 'reciept';
        } catch(e) {
            console.error(e);
            
            // card doesn't change
            this.model.showSpinner = false;
            if (this.isDevelop()) {
                this.model.modal.content = this.model.modal.content + '<br><div style="border: 2px solid red">' + e +'</div> ';
            }
            this.toggleModal({
                    id: 'error',
                    show: true,
                    redirect: undefined,
                    track: true,
                }
            );
            this.helper.sendErrorMailToTeam(e);
        }
    }

    public async submitOrder(desc: string, tracking: string, totalPrice: number, missingCalculations: boolean) {

        desc = `<h2>Kunde har bestilt samlet tilbud</h2>${desc}`;

        if(this.model.campaign.valid) {
            const id = this.model.campaign.ID ? this.model.campaign.ID.toLocaleUpperCase() : 'Ukendt!'
            if (this.model.campaign.uniqueKey) {
                desc += this.addProp(`${id} unik ID`, this.model.campaign.uniqueKey);
            }
            if (this.model.campaign.description) {
                desc += this.addProp(`Kampagne beskrivelse`, this.model.campaign.description);
            }
            if (this.model.campaign.groupId > 0) {
                desc += this.addProp(`Kampagne Gruppe kode`, this.model.campaign.groupId);
            }
            if (this.model.campaign.discount > 0) {
                desc += this.addProp(`Kampagne rabat inkluderet i pris`, this.model.campaign.discountDisplay);
            }
            if (this.model.campaign.tiaDiscount > 0) {
                desc += this.addProp(`Kampagne rabat fra TIA inkluderet i pris`, Formatter.format(100 * this.model.campaign.tiaDiscount) + ' %');
            }
            if (this.model.campaign.splash) {
                desc += this.addProp(`Kampagne specifik`, this.model.campaign.splash);
            }
        }

        const params: Map<string, string> = await this.buildDescriptionForEmail(desc, totalPrice);

        params.set('subject', `Kunde samlet tilbud`);
        params.set('name', this.model.contact_information.name);
        params.set('cprNo', this.model.contact_information.cpr);
        params.set('email', this.model.contact_information.email);
        params.set('phoneNo', this.model.contact_information.phone);

        const basket = store.getters.getSelectableGroup('basket').map( name => this.capitalizeFirstLetter(name.replace('0', '')));

        let products: string = basket.join(', ');
        const lastInx = products.lastIndexOf(',');
        products = products.slice(0, lastInx) + ' og' + products.slice(lastInx + 1);
        params.set('products', products);

        const monthly = this.model.choosePackage.monthYear === 'M';
        const monthlyYearlyDisplay = monthly ? ' kr. Månedligt' : ' kr. Årligt';

        const trackOrderProducts = [];
        const productsPrice = store.getters.getSelectableGroup('basket');
        productsPrice.forEach(productName => {
            const calculator = store.getters.getCalculator(productName);

            if (calculator?.model?.readyForBasket) {
                // product calculated
                const selectedPackage = calculator.model.choosePackage.selectedPackage;

                let price = '';
                if (monthly) {
                    price = Formatter.format(selectedPackage.totalPrice) + monthlyYearlyDisplay;
                } else {
                    price = Formatter.format(selectedPackage.yearlyPriceTotal) + monthlyYearlyDisplay;
                }
                params.set(productName.replace('ø', 'oe'), this.capitalizeFirstLetter(productName.replace('0', '')) + ', ' + price);
                trackOrderProducts.push(BuyInsuranceHelper.stripProductIndex(productName));
            } else {
                params.set(productName.replace('ø', 'oe'), this.capitalizeFirstLetter(productName.replace('0', '')));
            }
        });


        if (!missingCalculations) {
            params.set('isOpenMultiple', BuyInsuranceHelper.isOpenHours(this.cms.open) + '');
        }
        params.set('uncalculatedProducts', missingCalculations + '');
        params.set('price', totalPrice + '');
        params.set('monthlyPayment', monthly ? 'true' : 'false');

        if (totalPrice > 1) {
            params.set('priceFormatted', `${Formatter.format(totalPrice)}${monthlyYearlyDisplay}`);
            if (missingCalculations) {
                params.set('uncalculatedProductsWithPrice', 'true');
            }
        } else {
            params.set('priceFormatted', '0');
        }
        params.set('multipleInsurances', 'true');

        this.model.choosePackage.monthYear

        const startTime = new Date().getTime();

        await this.sendMailToOssNoLogin(params);

        if (this.cms.mailConfirmationToTeam) {
            await this.helper.sendMailToTeam();
        }

        const unsubscribe = store.subscribeAction((action, state) => {
            if (action.type === RESET_CALCULATORS) {
                setTimeout(() => {
                    this.checkout = true;
                    const model = cloneDeep(Model.model);
                    store.dispatch(UPDATE_BASKET, { cms: this.cms, model});
                    unsubscribe();
                }, 2500);
            }
        });

        const waitForEmail = this.cms.waitForEmail;
        if (waitForEmail && waitForEmail > 0) {
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve('Done waiting');
                }, this.cms.waitForEmail)
              });
        }

        this.checkoutBasket();
        this.helper.resetCampaignStorage(this.model);

        this.abGtm.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/order${tracking}`
        });

        // track individual products
        trackOrderProducts.forEach(productName => {
            this.abGtm.triggerCustomGtmEvent({
                'event': 'track-vp',
                'virtualPath': `${UrlHandler.getTrackingPreUrl()}${productName}/order`,
            });
        });


        const mailDuration = (new Date().getTime() - startTime);
        const fakeTimeout = this.cms.animationTimeoutBuy - mailDuration;
        setTimeout(() => {
            this.helper.setOpenTxtOnReciept(true, missingCalculations);
            this.model.showReciept = true;
            this.model.showSpinner = false;
            window.scrollTo(0,0);
            this.model.currentCardName = 'reciept';
            setTimeout(() => {
                VueScrollTo.scrollTo(`#reciept`, 300, { easing: 'ease-out', offset: -25 });
            }, this.cms.animationTimeout);
            this.helper.addStepToHistory(true);
        }, (fakeTimeout < 0 ? 100 : fakeTimeout) );

        // if stuff hangs
        setTimeout( () => {
            if(this.model.showSpinner === true) {
                this.model.showSpinner = false;
            }
        }, 10000);
    }


    public async buildDescriptionForEmail(desc: string, totalPrice: number) {

        desc += '<h2>Person</h2>';
        const now: Date = new Date();
        desc += this.addProp('Email sendt', `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${(now.getMonth()+1)}-${now.getFullYear()}`);
        desc += this.addProp('Navn', this.model.contact_information.name);
        if (this.model.personInfo.address) {
            desc += this.addProp('Adresse', this.model.personInfo.address);
        } else if (this.model.personInfo.zipCode) {
            desc += this.addProp('Postnr./by', `${this.model.personInfo.zipCode} ${this.model.personInfo.zipName}`);
        }
        if (this.model.personInfo.customerAge) {
            desc += this.addProp('Alder', this.model.personInfo.customerAge);
        }
        if (this.model.contact_information.customerNo) {
            desc += this.addProp('KundeNr', this.model.contact_information.customerNo);
        } else {
            desc += this.addProp('Cpr', this.model.contact_information.cpr);
        }
        if(this.model.personInfo.familyMemberCount) {
            desc += this.addProp('Antal personer i husstand', this.model.personInfo.familyMemberCount);
        }
        desc += this.addProp('Kunde har accepteret betingelser', this.model.contact_information.accept ? 'Ja' : 'Nej');
        desc += this.addProp('Email', this.model.contact_information.email);
        desc += this.addProp('Telefonnummer', this.model.contact_information.phone);
        desc += this.addProp('Rki', this.model.contact_information.rki);
        desc += this.addProp('Skader', this.model.contact_information.skader);
        desc += this.addProp('Betaling', this.model.choosePackage.monthYear === 'M' ? 'Månedlig' : 'Årlig');
        desc += this.addProp('Samlet pris', Formatter.format(totalPrice) + ' kr');


        let description = this.addProp('Eksisterende kunde', this.model.personInfo.almbrandCustomer);
        if (this.model.personInfo.almbrandCustomer === 'ja') {
            const existingProducts = this.model.personInfo.existingAlmBrandProducts.includes(this.cms.discountProductNone) ?
            [] : this.model.personInfo.existingAlmBrandProducts;

            description += this.addProp('Nuværende forsikringer', existingProducts.length > 0 ? existingProducts.join(', ') : 'Ingen');
        }

        const rebate = this.helper.getDiscount();
        if (rebate.discount < 1) {
            description += this.addProp('<i>Plus</i>Kunde rabat inkluderet i pris', rebate.discountDisplay);
        }

        desc += description;

        desc += '<h2>Betaling</h2>';
        desc += this.addProp('Reg. nr.', this.model.payment.regNo);
        desc += this.addProp('konto nr.', this.model.payment.kontoNo);

        // produkter
        const productNames = await store.getters.getSelectableGroup('basket');
        let productDescription = '';

        productNames.forEach(productName => {
            const calculator = store.getters.getCalculator(productName);
            productDescription += this.productHeader(productName, calculator);
            productDescription += this.productModel(productName, calculator);
        });

        desc += productDescription;

        const params: Map<string, string> = new Map();
        params.set('description', desc);
        return params;
    }

    public getExcessAmount(model: any): string {
        return model.ownRisks.find(x => x.value === model.choosePackage.ownRiskId).displayValue;
    }

    public toggleModal(model: any = {}) {
        this.helper.toggleModal(model);
    }

    private productHeader(productName: string, calculator: any): string {
        let desc = `<hr><h2>${BuyInsuranceHelper.getCalculatorNameUi(productName)}</h2>`;
        if(!calculator || !calculator?.model?.readyForBasket) {
            desc += '<b>Produkt ikke udfyldt - kontakt kunde</b><br>'
            return desc;
        }
        if (productName.includes(InsurancesProductEnum.INDBO_FORSIKRING) ||
                productName.includes(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING) ||
                productName.includes(InsurancesProductEnum.BIL_FORSIKRING) ||
                productName.startsWith(InsurancesProductEnum.HUS_FORSIKRING)) {
            let high: string = '';
            if (calculator.model.personInfo?.thatchedRoof === 'ja') {
                high += '<li><h2><i>Stråtag</i></h2></li>';
            }
            if(calculator.model.calculation?.addressSupplement > 1) {
                high += '<li><h2><i>Adresse tillæg</i></h2></li>';
            }
            if (high !== '') {
                desc += `<ul>${high}</ul>`;
            }
        }

        const model = calculator.model;
        if(model.campaign.valid) {
            const id = model.campaign.ID ? model.campaign.ID.toLocaleUpperCase() : 'Ukendt!'
            if (model.campaign.uniqueKey) {
                desc += this.addProp(`${id} unik ID`, model.campaign.uniqueKey);
            }
            if (model.campaign.description) {
                desc += this.addProp(`Kampagne beskrivelse`, model.campaign.description);
            }
            if (model.campaign.groupId > 0) {
                desc += this.addProp(`Kampagne Gruppe kode`, model.campaign.groupId);
            }

            if (model.campaign.discount > 0) {
                desc += this.addProp(`Kampagne rabat inkluderet i pris`, model.campaign.discountDisplay);
            }
            if (model.campaign.tiaDiscount > 0) {
                desc += this.addProp(`Kampagne rabat fra TIA inkluderet i pris`, Formatter.format(100 * model.campaign.tiaDiscount) + ' %');
            }

            if (model.campaign.splash) {
                desc += this.addProp(`Kampagne specifik`, model.campaign.splash);
            }
        }
        const pack = model.choosePackage?.selectedPackage;
        if (pack?.specialDiscount) {
            const discount = BuyInsuranceHelper.handleSpecialCarDiscounts(calculator);
            if (discount.specialDiscountType === 'vehicleGroup') {
                desc += this.addProp(`Bilgruppe rabat (${calculator.cms.vehicleDiscountGroups.join(', ')}) inkluderet i pris`, Formatter.format(100 * pack.specialDiscount) + ' %');
            } else if (discount.specialDiscountType === 'fuelType') {
                desc += this.addProp(`El/Hybrid rabat (bilgrp. ${calculator.cms.electricFuelTypeVehicleGroups.join(', ')}) inkluderet i pris`, Formatter.format(100 * pack.specialDiscount) + ' %');
            } else {
                desc += this.addProp(`UKENDT rabat inkluderet i pris`, Formatter.format(100 * pack.specialDiscount) + ' %');
            }

        }

        return desc;
    }

    private productModel(productName: string, calculator: any): string {

        const name = productName.toLocaleLowerCase();
        if(!calculator || !calculator?.model?.readyForBasket) {
            this.addProductToCheckout({productName: name , id: name});
            return '';
        }
        const model = calculator.model;

        const n = BuyInsuranceHelper.stripProductIndex(name);
        switch(n) {
            case InsurancesProductEnum.BIL_FORSIKRING : return this.getCar(model, calculator.cms);
            case InsurancesProductEnum.ULYKKES_FORSIKRING : return this.getAccident(model);
            case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING : return this.getAccidentChildren(model);
            case InsurancesProductEnum.INDBO_FORSIKRING : return this.getHome(model);
            case InsurancesProductEnum.HUNDE_FORSIKRING : return this.getDog(model);
            case InsurancesProductEnum.REJSE_FORSIKRING : return this.getTravel(model);
            case InsurancesProductEnum.HUS_FORSIKRING : return this.getHouse(model);
            case InsurancesProductEnum.FRITIDSHUS_FORSIKRING : return this.getHouse(model);
            case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING : return this.getFamily(model);
            default : return '';
        }
    }

    private getAccident(model: any): string {
        const pack = model.choosePackage.selectedPackage;
        let desc = '';
        if (model.personInfo.otherName) {
            desc += this.addProp('Navn', model.personInfo.otherName);
            desc += this.addProp('Alder', model.personInfo.otherCustomerAge);
            desc += this.addProp('Cpr', model.personInfo.otherCpr);
        }

        desc += this.addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

        if (model.additionalInfo.existInsurance === 'ja') {
            desc += this.addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
        }

        desc += this.addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
        if(model.additionalInfo.validNow === 'nej') {
            desc += this.addProp('Gyldig pr.', model.additionalInfo.validFromDate);
        }

        desc += '<br><b>Ønsket forsikring</b><br>';
        desc += this.addProp('Forsikringstype', pack.name);
        desc += this.addProp('Arbejde', `${model.personInfo.work}`);
        desc += this.addProp('Arbejde-id', `${model.personInfo.workId}`);
        desc += this.addProp('Pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += this.addProp('Beregningstype', 'Tia beregning');
        desc += this.addProp('Dækninger', this.helper.getCoverageNames(pack));

        this.addProductToCheckout(model);
        return desc
    }

    private getAccidentChildren(model: any): string {
        const pack = model.choosePackage.selectedPackage;
        let desc = this.addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

        if (model.additionalInfo.existInsurance === 'ja') {
            desc += this.addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
        }

        desc += this.addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
        if(model.additionalInfo.validNow === 'nej') {
            desc += this.addProp('Gyldig pr.', model.additionalInfo.validFromDate);
        }

        desc += '<br><b>Ønsket forsikring</b><br>';
        if (model.familyInfo.personCountId > 0) {
            desc += '<br><b>' + (model.familyInfo.personCountId + 1) + ' Børn</b><br>';
        } else {
            desc += '<br><b>' + (model.familyInfo.personCountId + 1) + ' Barn</b><br>';
        }

        for (let i= 0; i <= model.familyInfo.personCountId; i++) {
            desc += '<br><b>Barn ' + (i+1) + '</b><br>';
            desc += this.addProp('navn ', model.familyInfo['name' + i]);
            desc += this.addProp('alder ', model.familyInfo['age' + i] + ' år');
            desc += this.addProp('cpr ', model.familyInfo['cpr' + i]);
            if(model.familyInfo['workId' + i]) {
                desc += this.addProp('job ', model.familyInfo['work' + i]);
                desc += this.addProp('jobId ', model.familyInfo['workId' + i]);
            }

        }
        desc += "<br>"
        desc += this.addProp('Forsikringstype', pack.name);
        desc += this.addProp('Pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += this.addProp('Beregningstype', 'Tia beregning');
        desc += this.addProp('Dækninger', this.helper.getCoverageNames(pack));

        this.addProductToCheckout(model);
        return desc
    }

    private getHome(model: any): string {
        const pack = model.choosePackage.selectedPackage;
        let desc = '';
        desc += this.addProp('Stråtag', `${model.personInfo.thatchedRoof}`);
        desc += this.addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

        if (model.additionalInfo.existInsurance === 'ja') {
            desc += this.addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
        }

        desc += this.addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
        if(model.additionalInfo.validNow === 'nej') {
            desc += this.addProp('Gyldig pr.', model.additionalInfo.validFromDate);
        }

        desc += '<br><b>Ønsket forsikring</b><br>';
        desc += this.addProp('Forsikringstype', pack.name);
        desc += this.addProp('Stråtag', `${model.personInfo.thatchedRoof}`);
        desc += this.addProp('Selvrisiko', this.getExcessAmount(model));
        desc += this.addProp('Forsikret indbo sum', model.choosePackage.insuredValue);
        desc += this.addProp('Pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += this.addProp('Beregningstype', 'FP_FP beregning');
        desc += this.addProp('Dækninger', this.helper.getCoverageNames(pack));
        this.addProductToCheckout(model);
        return desc
    }
    private getFamily(model: any): string {
        const pack = model.choosePackage.selectedPackage;
        let desc = '';

        desc += this.addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
        if(model.additionalInfo.validNow === 'nej') {
            desc += this.addProp('Gyldig pr.', model.additionalInfo.validFromDate);
        }

        desc += '<br><b>Ønsket forsikring</b><br>';
        desc += this.addProp('Forsikringstype', pack.name);

        desc += '<br><b>Indbo</b><br>';
        desc += this.addProp('Stråtag', `${model.personInfo.thatchedRoof}`);
        desc += this.addProp('Selvrisiko', this.getExcessAmount(model));
        desc += this.addProp('Forsikret indbo sum', model.choosePackage.insuredValue);
        desc += this.addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

        if (model.additionalInfo.existInsurance === 'ja') {
            desc += this.addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
        }

        desc += '<br><b>Børneulykke</b>';
        for (let i= 0; i <= model.familyInfo.personCountId; i++) {
            desc += '<br><b>Barn ' + (i+1) + '</b><br>';
            desc += this.addProp('navn ', model.familyInfo['name' + i]);
            desc += this.addProp('alder ', model.familyInfo['age' + i] + ' år');
            desc += this.addProp('cpr ', model.familyInfo['cpr' + i]);
            if(model.familyInfo['workId' + i]) {
                desc += this.addProp('job ', model.familyInfo['work' + i]);
                desc += this.addProp('jobId ', model.familyInfo['workId' + i]);
            }
            desc += this.addProp('Eksisterende forsikring', model.familyInfo['existInsurance' + i]);
            if (model.familyInfo['existInsurance' + i] === 'ja') {
                desc += this.addProp('Nuværende selskab', model.familyInfo['existingInsurance' + i]);
            }

        }
        desc += "<br>"

        desc += this.addProp('Pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += this.addProp('Beregningstype', 'FP_FP beregning indbo, Tia beregning ulykke');
        desc += this.addProp('Dækninger', this.helper.getCoverageNames(pack));
        this.addProductToCheckout(model);
        return desc
    }
    private getHouse(model: any): string {
        const pack = model.choosePackage.selectedPackage;
        const productCode = model.productName === InsurancesProductEnum.HUS_FORSIKRING ? 'HU_HP' : 'FH_HP';
        let desc = '';

        if( model.productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING) {
            desc += this.addProp('Adresse', model.personInfo.address);
        }

        desc += this.addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

        if (model.additionalInfo.existInsurance === 'ja') {
            desc += this.addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
        }

        desc += this.addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
        if(model.additionalInfo.validNow === 'nej') {
            desc += this.addProp('Gyldig pr.', model.additionalInfo.validFromDate);
        }

        desc += '<br><b>Ønsket forsikring</b><br>';
        desc += this.addProp('Forsikringstype', pack.name);
        desc += this.addProp('Selvrisiko', this.getExcessAmount(model));
        desc += this.addProp('Pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += this.addProp('Beregningstype', productCode + ' beregning');
        desc += this.addProp('Dækninger', this.helper.getCoverageNames(pack));
        this.addProductToCheckout(model);
        return desc;
    }

    private getDog(model: any): string {
        const pack = model.choosePackage.selectedPackage;
        let desc = '';
        desc += this.addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

        if (model.additionalInfo.existInsurance === 'ja') {
            desc += this.addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
        }

        desc += this.addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
        if(model.additionalInfo.validNow === 'nej') {
            desc += this.addProp('Gyldig pr.', model.additionalInfo.validFromDate);
        }

        desc += '<br><b>Ønsket forsikring</b><br>';
        desc += this.addProp('Forsikringstype', pack.name);
        desc += this.addProp('Antal hunde', model.dogInfo.dogCount);

        desc += this.addProp('<br>Navn hund 1', model.dogInfo.dogName1);
        desc += this.addProp('Race', model.dogInfo.dogType1);
        desc += this.addProp('Race ID', model.dogInfo.dogTypeId1);
        desc += this.addProp('Køn', model.dogInfo.gender1 === 'T' ? 'Tæve' : 'Han');
        desc += this.addProp('Født', model.dogInfo.birthdate1);
        if (model.dogInfo.dogCount > 1) {
            desc += this.addProp('<br>Navn hund 2', model.dogInfo.dogName2);
            desc += this.addProp('Race', model.dogInfo.dogType2);
            desc += this.addProp('Race ID', model.dogInfo.dogTypeId2);
            desc += this.addProp('Køn', model.dogInfo.gender2 === 'T' ? 'Tæve' : 'Han');
            desc += this.addProp('Født', model.dogInfo.birthdate2);
        }
        if (model.dogInfo.healthy) {
            desc += this.addProp('<br>Hund(e) sund og rask', model.dogInfo.healthy);
            desc += this.addProp('Hund(e) tidligere været syg', model.dogInfo.beenUnhealthy);
            desc += this.addProp('Hund(e) Været ved dyrlæge p.g.a. sydgom/skade', model.dogInfo.vet);
            desc += this.addProp('Hund(e) vaccineret og over 8 uger', model.dogInfo.vaccine);
        }
        desc += this.addProp('<br>Pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += this.addProp('Beregningstype', 'VP_VP beregning');
        desc += this.addProp('Dækninger', this.helper.getCoverageNames(pack));

        this.addProductToCheckout(model);
        return desc
    }
    private getTravel(model: any): string {
        const pack = model.choosePackage.selectedPackage;
        let desc = '';
        // Kun Mastercard kampagne
        if (model.choosePackage.forfald) {
            desc += this.addProp('Forfald', model.choosePackage.forfald);
        }

        desc += this.addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

        if (model.additionalInfo.existInsurance === 'ja') {
            desc += this.addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
        }

        desc += this.addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
        if(model.additionalInfo.validNow === 'nej') {
            desc += this.addProp('Gyldig pr.', model.additionalInfo.validFromDate);
        }

        desc += '<br><b>Ønsket forsikring</b><br>';
        desc += this.addProp('Forsikringstype', pack.name);
        desc += this.addProp('Destination', model.choosePackage.segmentedProductProperty);
        desc += this.addProp('Pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += this.addProp('Beregningstype', 'RP_RP beregning');
        desc += this.addProp('Dækninger', this.helper.getCoverageNames(pack));
        this.addProductToCheckout(model);
        return desc
    }
    private getCar(model: any, cms: any): string {
        const pack = model.choosePackage.selectedPackage;
        let desc = '';
        if (model.personInfo.carOwnerYears && cms.carOwnerYears.indexOf(model.personInfo.carOwnerYears) > 0) {
            desc += this.addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);
            if (model.additionalInfo.existInsurance === 'nej') {
                desc += this.addProp('Tidligere forsikring', model.additionalInfo.existFormerInsurance);
                if(model.additionalInfo.existFormerInsurance === 'ja') {
                    desc += this.addProp('Tidligere selskab', model.additionalInfo.formerInsurance);
                }
            }
            else {
                desc += this.addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
            }
        } else {
            desc += this.addProp('Eksisterende forsikring', 'Ingen');
            desc += this.addProp('Nuværende selskab', 'Ingen, ny bilist');
        }

        desc += this.addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
        if(model.additionalInfo.validNow === 'nej') {
            desc += this.addProp('Gyldig pr.', model.additionalInfo.validFromDate);
        }

        desc += '<br><b>Ønsket forsikring</b><br>';
        desc += this.addProp('Forsikringstype', pack.name);
        desc += this.addProp('Årlig kørselsbehov', model.carInfo.yearlyMileage);
        desc += this.addProp('Antal år med egen bil', model.personInfo.carOwnerYears);
        desc += this.addProp('Selvrisiko', this.getExcessAmount(model));
        desc += this.addProp('Pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += this.addProp('Beregningstype', model.choosePackage.useTiaOffer ? 'Tia beregning' : 'AU_PB beregning');
        desc += this.addProp('Dækninger', this.helper.getCoverageNames(pack));

        desc += '<h2>Bil oplysninger</h2>';
        desc += this.addProp('Nummerplade', model.carInfo.licenseplate ? model.carInfo.licenseplate.toUpperCase() : 'Ikke oplyst');
        desc += this.addProp('Bil', model.carInfo.car);
        desc += this.addProp('Variant', model.carInfo.variantName);
        desc += this.addProp('Årgang', model.carInfo.year);
        desc += this.addProp('Kid', model.carInfo.kid + '');
        desc += this.addProp('VariantId', model.carInfo.variantId);
        desc += this.addProp('Drivmiddel', model.carInfo.fuelType + '');
        desc += this.addProp('VehicleGroup', model.carInfo.vehicleGroup);
        this.addProductToCheckout(model);
        return desc;
    }

    private addProp(label: string, val: string): string {
        return this.helper.addProp(label, val)
    }

    private addProductToCheckout(model: any) {
        const price = model?.choosePackage?.selectedPackage?.yearlyPriceTotal;
        this.checkoutProducts.push({
            name: model.productName,
            id: model.productName,
            price: price ? price + '' : '',
            brand: 'Fakedoor flow',
            category: 'Online salg',
            quantity: 1,
        });
        this.helper.resetCampaignStorage(model);
    }

    private checkoutBasket() {
        this.abGtm.triggerCustomGtmEvent({ ecommerce: null });  // Clear the previous ecommerce object.
        this.abGtm.triggerCustomGtmEvent({
          event: 'checkout',
          ecommerce: {
            currencyCode: 'DKK',
            checkout: {
              products: this.checkoutProducts
            }
          }
        });

    }
}
