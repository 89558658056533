import { HtmlBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd';

export default class HtmlBlockCd extends ContentItemCd {
    public model: HtmlBlock;

    constructor(model: HtmlBlock) {
        super();
        this.model = model;
    }
}
