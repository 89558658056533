
import ContentItemCd from './ContentItemCd'
import { cloneDeep } from "lodash";
import { OrderflowBlock, OrderflowContainerBlock } from '@/definitions/episerver/content-types';
import OrderflowBlockCd from './OrderflowBlockCd';
import store from '@/store';


export default class OrderflowContainerBlockCd extends ContentItemCd {
    public model: OrderflowContainerBlock;
    public orderflowBlocks: Array<OrderflowBlockCd> = [];
    public orderflowId: string;

    constructor(model: OrderflowContainerBlock) {
        super();
        // console.log('OrderflowContainerBlock model', model);
        // console.log('OrderflowContainerBlock model.mainArea', model.mainArea);
        
        this.model = model;
        this.init();
    }


    public init(): void {
        this.orderflowId = this.model.orderflowId;
        this.model.useGlobalManuelTilFravalg = !!this.model.useGlobalManuelTilFravalg;
        this.model.useGlobalOnlineTilFravalg = !!this.model.useGlobalOnlineTilFravalg;
        //console.log('OrderflowContainerBlockCd', this.orderflowId);
        if (this.model.mainArea) {
            this.model.mainArea.forEach(orderflowBlock => {
                const orderflowBlockCd = new OrderflowBlockCd(orderflowBlock as OrderflowBlock);
                orderflowBlockCd.orderflowId = this.model.orderflowId;
                // console.log('adding step', orderflowBlockCd.orderflowId, orderflowBlockCd.model);
                this.orderflowBlocks.push(orderflowBlockCd);
            });
        }
        // console.log('OrderflowContainerBlockCD after mapping ', this.orderflowBlocks);
    }

    public async getOrderflowBySubType(tilvalg: boolean, onlineFlow: boolean): Promise<OrderflowBlockCd> {

        let orderflowBlock: OrderflowBlockCd;

        // orderflows from content
        orderflowBlock = this.orderflowBlocks.find( (orderflowBlock) => {
            return orderflowBlock.model.tilvalg === tilvalg && orderflowBlock.model.onlineFlow === onlineFlow;
        });

        // fallback til manual
        if (!orderflowBlock && onlineFlow) {
            orderflowBlock = this.orderflowBlocks.find( (orderflowBlock) => {
                return orderflowBlock.model.tilvalg === tilvalg && orderflowBlock.model.onlineFlow === false;
            });
        }

        if (orderflowBlock) {
            //await orderflowBlock.load(this.abAmount);
            return await Promise.resolve(orderflowBlock);
        }

        if (this.model.useGlobalOnlineTilFravalg && onlineFlow) {
            // Use global online flows
            const globalOrderflow = OrderflowContainerBlockCd.getGlobalOrderFlow(tilvalg, onlineFlow, this.model.orderflowId);
            if (globalOrderflow) {
                return Promise.resolve(globalOrderflow);
            }
        }

        if (this.model.useGlobalManuelTilFravalg) {
            // Try manual fallback
            const globalOrderflow = OrderflowContainerBlockCd.getGlobalOrderFlow(tilvalg, false, this.model.orderflowId);
            return Promise.resolve(globalOrderflow);
        }

        return Promise.resolve(undefined);
    }

    public static getGlobalOrderFlow(tilvalg: boolean, onlineFlow: boolean, orderflowId: string) {
        // global flows
        const globalOrderflow = store.getters.getGlobalOrderflow;
        if (!globalOrderflow) {
            return undefined;
        }

        const orderflowBlock = globalOrderflow.orderflowBlocks.find( (orderflowBlock) => {
            // console.log('Global orderflowBlock', orderflowBlock);

                return orderflowBlock.model.tilvalg === tilvalg && orderflowBlock.model.onlineFlow === onlineFlow;
            });

        if (!orderflowBlock) {
            return undefined;
        }

        const orderflowBlocCopy = cloneDeep(orderflowBlock) as OrderflowBlockCd;

        // orderflowBlocCopy.orderflowSteps.map( (step) => {
        //     step.inputFields.map( (field) => {
        //         if( field.model ) {
        //             console.log('not mapping', field.model.fieldName);
                    
        //             // console.log('mapping field.model', field.model);
                    
        //             // field.model.inputId = Math.random();
        //             // field.model.fieldName = '_' + (Math.random());
        //             // console.log('field.model after', field.model);
                    
        //         }
        //     });
        // })
        orderflowBlocCopy.orderflowId = orderflowId;
        return orderflowBlocCopy;
    }
}
