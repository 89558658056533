import FeatureAdapter from '@/views/model/adapter/FeatureAdapter';
import InsurancesProductEnum from '@/views/model/InsurancesProductEnum';
import {STEP, ValuePair} from '../BuyInsuranceHelper';

// list and order of steps
export const STEPS: Array<string> = [STEP.PERSON_INFO, STEP.CHILDREN_INFO, STEP.PACKAGE, STEP.ADDITIONAL_INFO, STEP.OVERVIEW, STEP.CONTACT_INFORMATION, STEP.PAYMENT, STEP.ORDER];

export class Model {
    public static model = {
        campaign: {
            ID: undefined,
            valid: false,
            init: false,
            hasContentPage: false,
            subtitle: undefined,
            uniqueKey: undefined,
            discount: undefined,
            productStepDiscount: undefined,
        },
        personInfo: {
            subtitle: undefined,
            customerAge: undefined,
            address: undefined,
            zipCode: undefined,
            zipName: undefined,
            streetName: undefined,
            houseNr: undefined,
            floorNr: undefined,
            doorNr: undefined,
            kvhxValue: undefined,
            familyMemberCount: undefined,
            familyMemberCountId: 0,
            thatchedRoof: undefined,
            almbrandCustomer: undefined,
            existingAlmBrandProducts: [],
        },

        familyInfo: {}, // filled on init
        choosePackage: {
            subtitle: undefined,
            ownRiskId: undefined,
            insuredValue: undefined,
            insuredValueAmount: undefined,
            selectedPackage: undefined,
            monthYear: undefined,//this.monthYearArray[0].value,
        },
        contact_information: {
            subtitle: undefined,
            name: undefined,
            email: undefined,
            phone: undefined,
            cpr: undefined,
            customerNo: undefined,
            accept: false,
        },
        overview: {
            subtitle: undefined,
            highlights: undefined,
            details: undefined,
        },
        additionalInfo: {
            subtitle: undefined,
            existInsurance: undefined,
            existingInsurance: undefined,
            existingInsuranceId: undefined,
            validNow: undefined,
            validFromDate: undefined,
            skader: undefined,
            rki: undefined,
        },
        payment: {
            subtitle: undefined,
            regNo: undefined,
            kontoNo: undefined,
        },
        pristineStep: new Map([[STEP.PERSON_INFO.valueOf(), true],
            [STEP.CHILDREN_INFO.valueOf(), true],
            [STEP.PACKAGE.valueOf(), true],
            [STEP.ADDITIONAL_INFO.valueOf(), true],
            [STEP.OVERVIEW.valueOf(), true],
            [STEP.CONTACT_INFORMATION.valueOf(), true],
            [STEP.PAYMENT.valueOf(), true],
        ]),
        productName: InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING,
        ownRisks: [] as Array<ValuePair>,
        calculation: {
            isCalculated: false,
            updatePrices: ['pr1', 'pr2','pr3'],
            excessIdDefault: undefined,
            packageIds: undefined,
            packages: undefined,
            abCalc: {
                excesses: [],
                packs: new Map<number, Object>(),
            },
            allCoverages: new Map<number, Map<number, Array<FeatureAdapter>>>(),
            discount: undefined,
            discountDisplay: undefined,
            addressSupplement: 1,
        },
        calculationAccident: {
            // isCalculated: false,
            // updatePrices: ['pr1', 'pr2','pr3'],
            excessIdDefault: undefined,
            packageIds: undefined,
            packages: undefined,
            abCalc: {
                excesses: [],
                packs: new Map<number, Object>(),
            },
            allCoverages: new Map<number, Map<number, Array<FeatureAdapter>>>(),
            discount: undefined,
            discountDisplay: undefined,
        },
        modal: {
            show: false,
            id: 'none',
            track: false,
            trackToken: undefined,
            content: undefined,
            btnStyle: undefined,
            btnLabel: undefined,
            btnAction: undefined,
            btnSecondStyle: undefined,
            btnSecondLabel: undefined,
            title: undefined,
            redirect: undefined,
        },
        modalId: 'none',
        showSpinner: false,
        showReciept: false,
        currentCardName: 'none',
        updateAll: 'updateAll',
        calculating: false,
        readyForBasket: false,
        multipleProducts: false,
        showExistingAlmBrandProducts: true,
    };
    public static setFamilyInforProps() {
        const familyInfo: any = {};
        familyInfo.personCount= undefined;
        familyInfo.personCountId= 0;
        familyInfo.subtitle= undefined;

        for(let i = 0; i <=10; i++) {
            familyInfo['name' + i] = undefined;
            familyInfo['age' + i] = undefined;
            familyInfo['cpr' + i] = undefined;
            familyInfo['hasWork' + i] = undefined;
            familyInfo['work' + i] = undefined;
            familyInfo['workId' + i] = undefined;
            familyInfo['existInsurance' + i] = undefined;
            familyInfo['existingInsurance' + i] = undefined;
            familyInfo['existingInsuranceId' + i] = undefined;
        }
        Model.model.familyInfo = familyInfo;
    }
    public static mock() {
        if (location.href.startsWith('https://localhost') || location.href.startsWith('https://alpha')) {
            Object.assign(Model.model.personInfo, {
            address:"Adolphsvej 19, 2820 Gentofte",
            almbrandCustomer:"nej",
            customerAge:"45",
            existingAlmBrandProducts:[],
            familyMemberCount:"4 personer",
            familyMemberCountId:4,
            houseNr:"19",
            streetName:"Adolphsvej",
            subtitle:"45 år, Adolphsvej 19, 2820 Gentofte",
            zipCode:"2820",
            zipName:"Gentofte",
            thatchedRoof: 'ja'
            });

            Object.assign(Model.model.familyInfo, {
                personCount:"3 børn",
                personCountId:2,
                subtitle:"3 børn",

                // personCount:"2 Børn",
                // personCountId:1,
                // subtitle:"2 børn",

                age0:0,
                // cpr0:"1212201212",
                name0:"Lisa funfact",
                existInsurance0: "nej",

                age1:3,
                // cpr1:"1212171212",
                name1:"Peter gammelar",
                existInsurance1: "nej",

                age2:4,
                // cpr2:"1212161212",
                name2:"Franke Hop",
                existInsurance2: "nej",
            });

            Object.assign(Model.model.additionalInfo, {
                existInsurance: 'nej',
                validNow: 'ja',
                skader: '0',
                rki: 'nej',
                });
        }
    }
}
(function () {
    Model.setFamilyInforProps();
}());
