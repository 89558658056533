<template>
  <div
    role="banner"
    v-if="!isLoggedInAsApp()"
    class="page-header"
    ref="navOverlay"
  >
    <div class="page-header__content">
      <div class="primary-navigation__brand">
        <AbLink
          data-gtm-action="menu click"
          data-gtm-label="Menu logo"
          :data-gtm-url="trackingUrl"
          class="primary-navigation__brand__link"
          to="/forsikring"
          aria-label="Til startside"
        >
          <inline-svg
            class="primary-navigation__brand__icon"
            :src="logoUrl"
            alt=""
          />
        </AbLink>
      </div>
      <nav class="primary-navigation" :aria-label="menuLabel">
        <ul
          class="primary-navigation__list"
          role="menubar"
          :aria-label="menuLabel"
        >
          <li
            role="none"
            v-for="(model, index) in model"
            :key="index"
            class="primary-navigation__item"
            :ref="`sub-menu-${model.title}`"
          >
            <button
              v-if="hasSubmenu(model)"
              class="primary-navigation__link primary-navigation__link--toggle"
              @click="toggleMenu(model.title)"
              :ref="`toggle-${model.title}`"
              aria-haspopup="true"
              aria-expanded="false"
              :aria-controls="`sub-menu-${model.title}`"
            >
              {{ model.title }}
            </button>
            <AbLink
              v-else
              class="primary-navigation__link"
              role="menuitem"
              :to="model?.menuPointLink?.url"
              data-gtm-action="menu click"
              :data-gtm-label="`Menu ${model.title}`"
              :data-gtm-url="trackingUrl"
            >
              <span :class="'primary-navigation__link__icon__' + index" />
              {{ model.title }}
            </AbLink>

            <ul
              class="sub-navigation"
              v-if="hasSubmenu(model)"
              :aria-label="model.title"
              :id="`sub-menu-${model.title}`"
            >
              <li
                class="sub-navigation__item"
                v-for="(subRoute, index) in model.menuPoints"
                role="none"
                :key="index"
              >
                <AbLink
                  class="sub-navigation__link"
                  :to="subRoute.menuPointLink?.url"
                  v-html="subRoute.title"
                  role="menuitem"
                  data-gtm-action="menu click"
                  :data-gtm-label="`Menu ${subRoute.title}`"
                  :data-gtm-url="trackingUrl"
                />
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <a
        data-gtm-action="menu click"
        data-gtm-label="Menu log ud"
        :data-gtm-url="trackingUrl"
        :href="logoutUrl()"
        :class="['primary-navigation__logout', 'btn-outline-secondary', 'mb-0']"
      >
        Log ud
      </a>
    </div>
  </div>
</template>

<style src="./_assets/_menu.scss" lang="scss"></style>
