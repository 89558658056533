import store from "@/store";

export class UrlUtil {
    private static instance = new UrlUtil(window.location);
    private constructor(readonly location: Location) {
        this._baseUrlPath = this.getBasePath();
    }
    
    private _baseUrlPath : string;
    public static get baseUrlPath(): string {
        return this.instance._baseUrlPath;
    }

    public static urlPathIndex(index: number) {
        const url = window.location.pathname;
        const arr = url.split("/");
        return arr[index];
    }
    
    private getRootPath() {
        const indexOfSlash = this.location.pathname.substr(1).indexOf("/");
    
        return location.pathname.substr(0, indexOfSlash + 1);
    }

    private getBasePath() {
        if(this.location.hash?.length > 0) {
            //when there is an # in the url we assume it is running the old paths
            return '';
        }
        return this.getRootPath();
    }


    public static ensureCorrectBasePath(url: string) {
        //check if the url is absolute/external
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url;
        }

        if (url.startsWith(this.baseUrlPath)) {
            return url;
        }

        if(url.startsWith('/')) {
            return this.baseUrlPath + url;
        }

        return this.baseUrlPath + "/" + url;
    }

    public static ensureCorrectLink(url: string) {
        if (this.baseUrlPath === '') {
            if (url.startsWith("/")) {
                return url.replace(this.instance.location.pathname, '');
            }
            
        }
        const correctUrl = this.ensureCorrectBasePath(url);
        return correctUrl;
    }

    public static isDevelop() {
        return (location.href.startsWith('https://localhost') || 
                    location.href.startsWith('https://alpha') || 
                    location.href.startsWith('https://ab-fnol.azurewebsites.net/'));
    }

    public static async getReCaptchaToken() {
        for (let i = 0; i < 3; i++) {
            const token = await this.doGetReCaptchaToken();    
            if (token) {
                return token;
            }
        }
        return undefined;
    }

    private static async doGetReCaptchaToken() {
        try {
            if((window as any).grecaptcha) {
                const token = await (window as any).grecaptcha.execute(store.getters.getRecaptchaSiteKey, { action: 'online_sale' })
                return token;
            } else {
                console.warn('no grecaptcha');
                return undefined;
            }
        } catch(e) {
            console.error(e);
            //too bad - couldn't get token
            return undefined;
        }
    }

}
