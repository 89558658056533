import { Options, Vue } from 'vue-class-component';
import { nextTick, PropType } from 'vue';
import { mapState } from 'vuex';
import InputComponent from '../../components/InputComponent.vue';
import AddressSearchComponent from '../../components/AddressSearchComponent.vue';
import { SelectModalComponent } from '@/views/sharedcomponents/baseComponents/components'; // TODO KLK evt. almbrand shared
import FieldValidator from '../../FieldValidator';
import { TravelSettings } from '../../travel/TravelSettings';
import { Validator } from '../../BuyInsuranceHelper';
import ExistingAbProductsComponent from '../../components/ExistingABProductsComponent.vue'
import store from '@/store';
import { CardBlock } from '@/definitions/episerver/content-types';
import Utils from '@/views/providers/Utils';
import AbDatePickerComponent from '@/views/sharedcomponents/baseComponents/components/abDatePickerComponent/AbDatePickerComponent.vue';

@Options({
    name: 'PersonInfoStepComponent',
    props: {
        card: Object as PropType<CardBlock>,
        isValid: Boolean,
    },
    components: {
        AbDatePickerComponent,
        InputComponent,
        AddressSearchComponent,
        SelectModalComponent,
        ExistingAbProductsComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
})

export default class PersonInfoStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // whether card is complete

    public model!: any; // datastructure for user input (store)
    public cms!: TravelSettings; // settings from CMS (store)

    private utils = new Utils();
    public fieldValidator: FieldValidator = Validator;
    public readonlyZip: string = null;
    public readonlyAge: string = null;
    public readonlyFamilyMemberCount: string = null;

    // TODO KLK remove test
    public testData = {
        person : {
            //birthdate: '11-11-2022',
            birthdate: undefined,
            blabla: 'hello'
        }
    }
    public test(args) {
        console.log('HITTTTTTTTTTTTTTTTTT', args);
    }

    public created () {
        // Todo klk evt flyt til BuyTravleComponent
        // hvis params allerede er sat, skal der ikke brges params fra query
        try {
            let zipParam = this.$route.query.zipCode as string || this.$route.query.zipcode as string;
            if (zipParam) {
                this.model.personInfo.zipCode = zipParam;
            }
            zipParam = this.$route.query.zipName as string || this.$route.query.zipname as string;
            if (zipParam) {
                this.model.personInfo.zipName = zipParam;
            }

            const customerAge = this.$route.query.customerAge as string || this.$route.query.customerage as string;
            if (customerAge) {
                this.model.personInfo.customerAge = customerAge;
            }

            let familyMemberCount = this.$route.query.familyMemberCount as string || this.$route.query.familymembercount as string || this.$route.query.persons as string;
            if (familyMemberCount) {
                this.model.personInfo.familyMemberCountId = parseInt(familyMemberCount);
                this.model.personInfo.familyMemberCount = this.familyMemberList[this.model.personInfo.familyMemberCountId - 1];
            }
            let destination = this.$route.query.destination as string;
            if (destination) {
                destination = this.utils.capitalizeFirstLetter(destination.toLowerCase());
                if(destination === 'Verden' || destination === 'Europa') {
                    this.model.choosePackage.segmentedProductProperty = destination;
                }
            }

            // Kun Mastercard kampagne
            const forfald = this.$route.query.forfald as string;
            if (forfald) {
                this.model.choosePackage.forfald = forfald;
            }
            // TODO klk travel insurance query params
            // country
            // city
        } catch(e) {
            // do nothing, params might be off
        }
    }

    public mounted() {
        if (!this.model.personInfo.zipCode || !this.model.personInfo.zipName || store.getters.getCustomerCalculation.hasCustomerData) {
            // wait for transition to begin showing zipcode field
            setTimeout(() => {
                const input = (document.querySelector('#person_info .autocomplete-input') as HTMLInputElement);
                if (input) {
                    input.focus();
                }
            }, this.cms.animationTimeout);
        }

        if (this.model.multipleProducts) {
            const commonModel = store.getters.getCalculatorCommonModel;
            if (commonModel.personInfo.zipCode && commonModel.personInfo.zipName) {
                this.readonlyZip = this.model.personInfo.zipCode + ' ' + this.model.personInfo.zipName;
            }
            if (this.fieldValidator.isValidAge(commonModel.personInfo.customerAge)) {
                this.readonlyAge = this.model.personInfo.customerAge;
            }
            if (commonModel.personInfo.familyMemberCount) {
                this.readonlyFamilyMemberCount = this.model.personInfo.familyMemberCount;
            }
        }
        window.addEventListener('keyup' , this.moveOn );
    }

    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    public nextStep() {
        this.$emit('nextStep', this.card.routePath);
    }

    public zipSearch(data) {
        if(data.matchText) {
            this.model.personInfo.zipCode = data.zipCode;
            this.model.personInfo.zipName = data.zipName;
            this.model.pristineStep.set(this.card.name, true);
            nextTick( () => {
                // wait for transition to begin showing customerAge field
                setTimeout(() => {
                    const input = document.getElementById('customerAge');
                    if (input) {
                        input.focus();
                    }
                }, 300);

            });
            return;
        }
        this.model.personInfo.zipCode = undefined;
        this.model.personInfo.zipName = undefined;
    }

    public get zipCodeDisplay() {
        if (this.model.personInfo.zipCode && this.model.personInfo.zipName){
            return this.model.personInfo.zipCode + ' ' + this.model.personInfo.zipName
        } else if (this.model.personInfo.zipCode){
            return this.model.personInfo.zipCode;
        } else if (this.model.personInfo.zipName){
            return this.model.personInfo.zipName;
        }
        return undefined;
    }

    public familyMemberSelected(result: any) {
        if (result && result.selectedValue) {
            this.model.personInfo.familyMemberCount = result.selectedValue;
            const inx = this.familyMemberList.indexOf(result.selectedValue);
            this.model.personInfo.familyMemberCountId = this.model.calculation.abCalc.familyMemberCountOptions[inx].value;
            return;
        }

        this.model.personInfo.familyMemberCount = undefined;
        this.model.personInfo.familyMemberCountId = undefined;
    }

    get familyMemberList(): Array<string> {
        const memberListFormatted = [];
            this.model.calculation.abCalc.familyMemberCountOptions.forEach(element => {
                memberListFormatted.push(element.key);
            });
        return memberListFormatted;
    }
}
