export default class TransitionExpand {

  public enter(element) {
        const width = getComputedStyle(element).width;

        /* eslint-disable no-param-reassign */
        element.style.width = width;
        element.style.position = "absolute";
        element.style.visibility = "hidden";
        element.style.height = "auto";
        /* eslint-enable */

        const height = getComputedStyle(element).height;

        /* eslint-disable no-param-reassign */
        element.style.width = null;
        element.style.position = 'inherit';
        element.style.visibility = 'visible';
        element.style.height = 0;
        /* eslint-enable */

        // Force repaint to make sure the
        // animation is triggered correctly.
        // eslint-disable-next-line no-unused-expressions
        getComputedStyle(element).height;

        setTimeout(function() {
            // eslint-disable-next-line no-param-reassign
            element.style.height = height;
        });
  }

  public afterEnter(element) {
  // eslint-disable-next-line no-param-reassign
    element.style.height = "auto";
  }

  public leave(element) {
    const height = getComputedStyle(element).height;

    // eslint-disable-next-line no-param-reassign
    element.style.height = height;

    // Force repaint to make sure the
    // animation is triggered correctly.
    // eslint-disable-next-line no-unused-expressions
    getComputedStyle(element).height;

    setTimeout(function() {
        // eslint-disable-next-line no-param-reassign
        element.style.height = 0;
    });

  }
}