import Axios from 'axios';
import AxiosRetry from 'axios-retry';
import Toggle from './ab_toggle';
import * as localForage from "localforage";
import { isNullOrUndefined } from '@/views/common/util';
import { UrlUtil } from '@/episerver/api/urlUtil';

/**
 * axios wrapper https://github.com/axios/axios
 */
class AbAxios {
    public abAxios = Axios.create();
    public abaxiosRetry = AxiosRetry;
    public abtoggle = new Toggle();
    private csrfToken: string;
    private recaptchaEnabled: boolean = false;    

    constructor(baseUrl: string = window.location.origin, contentType: string = 'application/json; charset=utf-8', csrfToken: string = null) {
        this.abAxios.defaults.baseURL = baseUrl;
        this.abAxios.defaults.headers.common['Content-Type'] = contentType;
        this.csrfToken = csrfToken || this.getCSRFToken();
    }

    public useRecaptcha(useRecaptcha: boolean) {
        this.recaptchaEnabled = useRecaptcha;
    }
    
    /**
     * Encode params from key value object
     * @param paramsObj
     */
    public getCSRFToken() {
        let meta = (window as any).document.querySelector("meta[name='csrfToken']");
        if (meta != undefined) {
            return meta.content as string;
        }
        return null;
    }

    public RegetCSRFToken() {
        //Use when csrfToken has changed due to late authentication. 
        this.csrfToken = this.getCSRFToken();
    }

    public encodeParams(paramsObj: any, isbeginQueryString: boolean): string {
        const queryString = Object.keys(paramsObj).map((key) => {
            return [key, paramsObj[key]].map(encodeURIComponent).join("=");
        }).join("&");

        return isbeginQueryString === true ? "?"+queryString : "&"+queryString;
    }

    public async abPut(urlEndpoint: string, payload: any, contentType: string = "application/json", toggleSpinner: boolean, successCallback: any, errorCallback: any) {
        var self = this;
        toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;

        self.abaxiosRetry(self.abAxios, { retries: 3 });
        await self.abAxios.put(urlEndpoint, payload, {
            headers: {
                'Content-Type': contentType
            }
        })
            .then(function (response) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                successCallback(response);
            })
            .catch(function (error) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                errorCallback(error);
            });
    }

    public async abPutWithToken(urlEndpoint: string, payload: any, toggleSpinner: boolean, successCallback: any, errorCallback: any) {
        var self = this;
        toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;

        let headers = await this.buildSecurityHeader();
        self.abaxiosRetry(self.abAxios, { retries: 3 });
        await self.abAxios.put(urlEndpoint, payload, headers)
            .then(function (response) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                successCallback(response);
            })
            .catch(function (error) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                self.handleApiError(error);
                errorCallback(error);
            });
    }

  /**
   * Get request
   * @param urlEndpoint
   * @param paramsObj
   * @param toggleSpinner
   * @param successCallback
   * @param errorCallback
   */
    public async abGet(urlEndpoint: string, toggleSpinner: boolean, successCallback: any, errorCallback: any, ignoreSSL: boolean = false, accept?: string, contentType?: string) {
        var self = this;
        toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;

        self.abaxiosRetry(self.abAxios, { retries: 3 });

        /* TODO aberec
        if (ignoreSSL) {
            let httpsAgent = new https.Agent({
                rejectUnauthorized: false
            });
            self.abAxios.defaults.httpsAgent = httpsAgent;
        }*/

        if (accept != undefined) {
            self.abAxios.defaults.headers.common['Accept'] = accept;
        }

        if (accept != undefined) {
            self.abAxios.defaults.headers.common['Content-Type'] = contentType;
        }

        await self.abAxios.get(urlEndpoint)
            .then(function (response) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                successCallback(response);
            })
            .catch(function (error) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                errorCallback(error);
            });
    }

     /**
     * GET REST kald der kan styre oauth token i headeren via følgende localforage værdier
     *
     * loginRequired: hvis det er påkrævet at headeren indeholder oauth token (kan sættes i epi-server på selv blokken)
     *
     * token: selve oauth token som api-service har returneret efter login
     *
     * @param urlEndpoint
     * @param toggleSpinner
     * @param successCallback
     * @param errorCallback
     */
    public async abGetWithToken(urlEndpoint: string, toggleSpinner: boolean, successCallback: any, errorCallback: any) {
        var self = this;
        toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;

        let headers = await this.buildSecurityHeader();
        self.abaxiosRetry(self.abAxios, { retries: 3 });
        await self.abAxios.get(urlEndpoint, headers)
            .then(function (response) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                successCallback(response);
            })
            .catch(function (error) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                self.handleApiError(error);
                errorCallback(error);
            });
    }

    public async abPost(urlEndpoint: string, payload: any, contentType: string = "application/json", toggleSpinner: boolean, successCallback: any, errorCallback: any) {
        var self = this;
        toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;

        self.abaxiosRetry(self.abAxios, { retries: 3 });
        await self.abAxios.post(urlEndpoint, payload, {
            headers: {
                'Content-Type': contentType
            }
        })
            .then(function (response) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                successCallback(response);
            })
            .catch(function (error) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                errorCallback(error);
            });
    }


    /**
     * POST REST kald der kan styre oauth token i headeren via følgende localforage værdier
     *
     * loginRequired: hvis det er påkrævet at headeren indeholder oauth token (kan sættes i epi-server på selv blokken)
     *
     * token: selve oauth token som api-service har returneret efter login
     *
     * @param urlEndpoint
     * @param payload
     * @param contentType
     * @param toggleSpinner
     * @param successCallback
     * @param errorCallback
     * @param baseUrl - override default baseUrl
     */
    public async abPostWithToken(urlEndpoint: string, payload: any, contentType: string = "application/json", toggleSpinner: boolean, successCallback: any, errorCallback: any, baseURL: string = undefined) {
        var self = this;
        toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;

        let headers = await this.buildSecurityHeader(contentType);
        if (baseURL || baseURL === '') {
            headers = Object.assign(headers, {baseURL})
        }

        self.abaxiosRetry(self.abAxios, { retries: 3 });
        await self.abAxios.post(urlEndpoint, payload, headers)
            .then(function (response) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                successCallback(response);
            })
            .catch(function (error) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                self.handleApiError(error);
                errorCallback(error);
            });
    }

    public async abPostWithHeaders(urlEndpoint: string, payload: any, headers: any, toggleSpinner: boolean, successCallback: any, errorCallback: any) {

        var self = this;
        toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;

        self.abaxiosRetry(self.abAxios, { retries: 3 });
        await self.abAxios.post(urlEndpoint, payload, {
            headers
        })
            .then(function (response) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                successCallback(response);
            })
            .catch(function (error) {
                toggleSpinner === true ? self.abtoggle.modal({ overlay: true, spinner: true }) : false;
                errorCallback(error);
            });
    }

    private async buildSecurityHeader(contentType: string = "application/json") {
        let headers: any = null;
        //selfcare opsætning
        if (!isNullOrUndefined(this.csrfToken)) {
            headers = {
                "headers": {
                    "x-csrf-token": this.csrfToken,
                    'Content-Type': contentType,
                    //'x-use-test-customer-number': 9608386350
                }
            }
            headers = await this.handleRecaptcha(headers);
            return headers;
        }

        //leasing opsætning
        let loginRequired: boolean = false;
        await localForage.getItem("loginRequired").then((value) => {
            if (value !== null) {
                loginRequired = new Boolean(value).valueOf();
            }
        }).catch((err) => {
            console.error(err);
        });
        await localForage.getItem("token").then((token) => {
            if (token !== null && loginRequired) {
                headers = {
                    "headers": {
                        "Authorization": "Bearer " + token,
                        'Content-Type': contentType
                    }
                }
            } else {
                headers = {
                    "headers": {
                        'Content-Type': contentType
                    }
                }
            }
        }).catch((err) => {
            console.error(err);
        });

        headers = await this.handleRecaptcha(headers);
        return headers;
    }
    private async handleRecaptcha(headers: any): Promise<any> {
        if (this.recaptchaEnabled) {
            const recaptchaToken = await UrlUtil.getReCaptchaToken();
            if(!headers) {
                headers = { headers: {
                    'x-recaptcha-token': recaptchaToken
                }}
            } else {
                headers.headers['x-recaptcha-token'] = recaptchaToken;
            }
        }
        return headers;
    
    }

    private handleApiError(error: any) {
        if (!isNullOrUndefined(error.response) && error.response.status === 401) {
            localForage.removeItem("token").then(function () {
                //window.location.reload(true);
                //router.replace('/login');
            })
            throw new Error('401 Unauthorized');
        }
    }
}

export default AbAxios;
