import { UrlUtil } from "@/episerver/api/urlUtil";
import store from '@/store';
import InsurancesCommon from '@/views/model/InsurancesCommon';
import { nextTick } from 'vue';
import { mixins, Options } from 'vue-class-component';
import UrlHandler from './UrlHandler';
@Options({
    name: 'selfserviceInsurancesLocateBasketProductComponent',
    components: {
    }

})
export default class BasketProductSelectorComponent extends mixins(InsurancesCommon) {
    // KLK hack, sometimes the url isn't resolved correctly and basket/product not loaded
    private delay = 25;
    private hasRedirected = false;

    async mounted() {
        // await new Promise(() => setTimeout(() => {
        //     this.resolveUrl();
        // }, this.delay));
        nextTick( () => this.resolveUrl());
        // klk hack , sometimes page doesn't load!!
        setTimeout(() => {
            if (!this.hasRedirected) {
                console.warn('BasketProductSelectorComponent has not redirected - trying again');
                this.resolveUrl();
            }
            
        }, this.delay * 1000);  // 5 seconds

    }
    private async resolveUrl() {
        // got specific product
        if( this.$route.query.product ) {
            await store.dispatch('setAppReplaceRoute', true);
            this.$router.replace({
                path: this.$route.query.product as string,
                query: this.$route.query
            });
            this.hasRedirected = true;
            return;
        }
        // check if there is any products defined by queryParams, and put them in the basket
        if( this.$route.query.products ) {
            // console.log('this.$route.query.products', this.$route.query.products);

            await UrlHandler.handlePreselectedProducts(this.$route.query);
            // KLK hack delay
            await new Promise((res) => setTimeout(res, this.delay));
        }
        const insurance = await UrlHandler.gotoFirstProductInBasket();
        // console.log('Url resolved to', insurance);

        let url = UrlUtil.ensureCorrectLink(insurance);
        let query = '';
        try {
            const inx = this.$route.fullPath.indexOf('?');
            if (inx > -1) {
                query = this.$route.fullPath.substr(inx, this.$route.fullPath.length);
                url += query;
            }

        } catch (error) {
            console.error(error);
            // too bad
        }
        await store.dispatch('setAppReplaceRoute', true);
        this.hasRedirected = true;
        this.$router.replace(url);
    }
}
