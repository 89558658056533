import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "button-container icon-btn text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_buttonComponent = _resolveComponent("buttonComponent")!
  const _component_modalComponent = _resolveComponent("modalComponent")!

  return (_ctx.model.modalId !== 'none')
    ? (_openBlock(), _createBlock(_component_modalComponent, {
        key: 0,
        padding: 0,
        id: _ctx.model.modalId,
        dismisable: true,
        trackingEnabled: false
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", {
            class: "h2 text-center",
            innerHTML: _ctx.model.modal.title
          }, null, 8, _hoisted_1)
        ]),
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "modal-content",
              innerHTML: _ctx.model.modal.content
            }, null, 8, _hoisted_3),
            (_ctx.model.modal.btnInlineLabel)
              ? (_openBlock(), _createBlock(_component_buttonComponent, {
                  key: 0,
                  btnClass: _ctx.model.modal.btnInlineStyle,
                  label: _ctx.model.modal.btnInlineLabel,
                  onButtonComponent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInlineAction()))
                }, null, 8, ["btnClass", "label"]))
              : _createCommentVNode("", true)
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_buttonComponent, {
              btnClass: _ctx.model.modal.btnSecondStyle,
              btnType: 'button',
              label: _ctx.model.modal.btnSecondLabel,
              onButtonComponent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleModal({show: false, id: _ctx.model.modalId})))
            }, null, 8, ["btnClass", "label"]),
            _createVNode(_component_buttonComponent, {
              "data-mainbtn": "",
              btnClass: _ctx.model.modal.btnStyle,
              btnType: _ctx.model.modal.btnType,
              label: _ctx.getLabel(),
              icon: _ctx.getIcon(),
              target: _ctx.getTarget(),
              href: _ctx.getHrefType() + _ctx.model.modal.btnAction,
              onButtonComponent: _cache[2] || (_cache[2] = ($event: any) => (_ctx.track()))
            }, null, 8, ["btnClass", "btnType", "label", "icon", "target", "href"])
          ])
        ]),
        _: 1
      }, 8, ["id"]))
    : _createCommentVNode("", true)
}