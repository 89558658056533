// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * User Info
 * # Introduction The User Info API allows client applications to fetch information about the user signed in eg. fetch customer no by cprnr  # How do I start? If you're new to the Catalog & Customer API, here's how we recommend you get started + Read this document. + Read generel information about howto register your client application and security model - comming soon here + Read Open API specification via ReDoc endpoint or generate client application code - [\"here\"](https://bluetest-api.almbrand.dk/customer/developer/open-api/v2/api-self-service.yaml?x-api-key=e0122b76-d302-4148-af52-7b3890e54482 ) + Import collection and Evironment information into Postman and Try out API's - [\"here\"](https://documenter.getpostman.com/view/2603739/S11NMGYa )   ## Use Cases + fetch information about the user signed in eg. fetch customer no by cprnr 
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "https://preprod-api.almbrand.dk/user-info".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * Request customer details
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * Unique ID for a danish citizen.
     * @type {string}
     * @memberof InlineObject
     */
    cpr_no?: string;
    /**
     * Unique ID for a danish company.
     * @type {string}
     * @memberof InlineObject
     */
    cvr_no?: string;
    /**
     * Email to the person or company.
     * @type {string}
     * @memberof InlineObject
     */
    email?: string;
    /**
     * Indicates whether broker or not
     * @type {boolean}
     * @memberof InlineObject
     */
    broker?: boolean;
}

/**
 * Request customer details
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * Unique ID for a danish citizen.
     * @type {string}
     * @memberof InlineObject1
     */
    cpr_no?: string;
    /**
     * Unique ID for a danish company.
     * @type {string}
     * @memberof InlineObject1
     */
    cvr_no?: string;
    /**
     * Email to the person or company.
     * @type {string}
     * @memberof InlineObject1
     */
    email?: string;
    /**
     * Indicates whether broker or not
     * @type {boolean}
     * @memberof InlineObject1
     */
    broker?: boolean;
}

/**
 * Customer Detail
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    tags?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    customer_id?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    tia_customer_id?: number;
    /**
     * The customers id in CarCommerce
     * @type {string}
     * @memberof InlineResponse200
     */
    cc_customer_id?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    broker_id?: number;
    /**
     * Indicates that the user is a SuperAdmin, that has the role to see all organisation for the domain (line of business).
     * @type {boolean}
     * @memberof InlineResponse200
     */
    super_admin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    email?: string;
    /**
     * 
     * @type {UserInfoPerson}
     * @memberof InlineResponse200
     */
    person?: UserInfoPerson;
    /**
     * 
     * @type {UserInfoOrganization}
     * @memberof InlineResponse200
     */
    organization?: UserInfoOrganization;
    /**
     * 
     * @type {Array<UserInfoDetailsRelation>}
     * @memberof InlineResponse200
     */
    relations?: Array<UserInfoDetailsRelation>;
}

/**
 * Customer list
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {Array<UserInfoCustomer>}
     * @memberof InlineResponse2001
     */
    customers?: Array<UserInfoCustomer>;
}

/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof InlineResponse400
     */
    type?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof InlineResponse400
     */
    title?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof InlineResponse400
     */
    status?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof InlineResponse400
     */
    detail?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof InlineResponse400
     */
    instance?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    internal_error_code?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    user_id?: string;
}

/**
 * Customer ids
 * @export
 * @interface UserInfoCustomer
 */
export interface UserInfoCustomer {
    /**
     * 
     * @type {number}
     * @memberof UserInfoCustomer
     */
    customer_number?: number;
    /**
     * 
     * @type {string}
     * @memberof UserInfoCustomer
     */
    primary_contact_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoCustomer
     */
    primary_contact_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoCustomer
     */
    customer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoCustomer
     */
    customer_address?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoCustomer
     */
    customer_type?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoCustomer
     */
    customer_vat_number?: string;
}

/**
 * Details about the manager
 * @export
 * @interface UserInfoDetailsRelation
 */
export interface UserInfoDetailsRelation {
    /**
     * Unique ID for the User Relation
     * @type {string}
     * @memberof UserInfoDetailsRelation
     */
    relation_id?: string;
    /**
     * Type of relation
     * @type {string}
     * @memberof UserInfoDetailsRelation
     */
    is?: string;
    /**
     * 
     * @type {any}
     * @memberof UserInfoDetailsRelation
     */
    _for?: any;
}

/**
 * 
 * @export
 * @interface UserInfoDetailsRelationFor
 */
export interface UserInfoDetailsRelationFor {
    /**
     * 
     * @type {UserInfoOrganization}
     * @memberof UserInfoDetailsRelationFor
     */
    organization?: UserInfoOrganization;
}

/**
 * A Person (Legal Entity)
 * @export
 * @interface UserInfoOrganization
 */
export interface UserInfoOrganization {
    /**
     * The organization's legal name
     * @type {string}
     * @memberof UserInfoOrganization
     */
    name?: string;
    /**
     * Unique ID for the organization (Danish Goverment externally)
     * @type {string}
     * @memberof UserInfoOrganization
     */
    cvr_nr?: string;
    /**
     * email address
     * @type {string}
     * @memberof UserInfoOrganization
     */
    email?: string;
}

/**
 * Person
 * @export
 * @interface UserInfoPerson
 */
export interface UserInfoPerson {
    /**
     * 
     * @type {string}
     * @memberof UserInfoPerson
     */
    person_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoPerson
     */
    cpr_nr?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoPerson
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoPerson
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoPerson
     */
    fullname?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfoPerson
     */
    roles?: Array<string>;
}


/**
 * UserInfoApi - axios parameter creator
 * @export
 */
export const UserInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * fetch broker related customer list
         * @summary fetchBrokerCustomerList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBrokerCustomerList(options: any = {}): RequestArgs {
            const localVarPath = `/user-info/customers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Ocp-Apim-Subscription-Key")
					: configuration.apiKey;
                localVarHeaderParameter["Ocp-Apim-Subscription-Key"] = localVarApiKeyValue;
            }

            // authentication apiKeyQuery required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("subscription-key")
					: configuration.apiKey;
                localVarQueryParameter["subscription-key"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fetch user information
         * @summary fetchUserInfo
         * @param {InlineObject} [InlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInfo(InlineObject?: InlineObject, options: any = {}): RequestArgs {
            const localVarPath = `/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Ocp-Apim-Subscription-Key")
					: configuration.apiKey;
                localVarHeaderParameter["Ocp-Apim-Subscription-Key"] = localVarApiKeyValue;
            }

            // authentication apiKeyQuery required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("subscription-key")
					: configuration.apiKey;
                localVarQueryParameter["subscription-key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InlineObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(InlineObject || {}) : (InlineObject || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fetch user customer id by cprnr
         * @summary fetchUserInfoByCpr
         * @param {InlineObject1} [InlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInfoByCpr(InlineObject1?: InlineObject1, options: any = {}): RequestArgs {
            const localVarPath = `/by-cpr`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Ocp-Apim-Subscription-Key")
					: configuration.apiKey;
                localVarHeaderParameter["Ocp-Apim-Subscription-Key"] = localVarApiKeyValue;
            }

            // authentication apiKeyQuery required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("subscription-key")
					: configuration.apiKey;
                localVarQueryParameter["subscription-key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InlineObject1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(InlineObject1 || {}) : (InlineObject1 || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInfoApi - functional programming interface
 * @export
 */
export const UserInfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * fetch broker related customer list
         * @summary fetchBrokerCustomerList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBrokerCustomerList(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001> {
            const localVarAxiosArgs = UserInfoApiAxiosParamCreator(configuration).fetchBrokerCustomerList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * fetch user information
         * @summary fetchUserInfo
         * @param {InlineObject} [InlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInfo(InlineObject?: InlineObject, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
            const localVarAxiosArgs = UserInfoApiAxiosParamCreator(configuration).fetchUserInfo(InlineObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * fetch user customer id by cprnr
         * @summary fetchUserInfoByCpr
         * @param {InlineObject1} [InlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInfoByCpr(InlineObject1?: InlineObject1, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
            const localVarAxiosArgs = UserInfoApiAxiosParamCreator(configuration).fetchUserInfoByCpr(InlineObject1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * UserInfoApi - factory interface
 * @export
 */
export const UserInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * fetch broker related customer list
         * @summary fetchBrokerCustomerList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBrokerCustomerList(options?: any) {
            return UserInfoApiFp(configuration).fetchBrokerCustomerList(options)(axios, basePath);
        },
        /**
         * fetch user information
         * @summary fetchUserInfo
         * @param {InlineObject} [InlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInfo(InlineObject?: InlineObject, options?: any) {
            return UserInfoApiFp(configuration).fetchUserInfo(InlineObject, options)(axios, basePath);
        },
        /**
         * fetch user customer id by cprnr
         * @summary fetchUserInfoByCpr
         * @param {InlineObject1} [InlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInfoByCpr(InlineObject1?: InlineObject1, options?: any) {
            return UserInfoApiFp(configuration).fetchUserInfoByCpr(InlineObject1, options)(axios, basePath);
        },
    };
};

/**
 * UserInfoApi - object-oriented interface
 * @export
 * @class UserInfoApi
 * @extends {BaseAPI}
 */
export class UserInfoApi extends BaseAPI {
    /**
     * fetch broker related customer list
     * @summary fetchBrokerCustomerList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public fetchBrokerCustomerList(options?: any) {
        return UserInfoApiFp(this.configuration).fetchBrokerCustomerList(options)(this.axios, this.basePath);
    }

    /**
     * fetch user information
     * @summary fetchUserInfo
     * @param {InlineObject} [InlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public fetchUserInfo(InlineObject?: InlineObject, options?: any) {
        return UserInfoApiFp(this.configuration).fetchUserInfo(InlineObject, options)(this.axios, this.basePath);
    }

    /**
     * fetch user customer id by cprnr
     * @summary fetchUserInfoByCpr
     * @param {InlineObject1} [InlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public fetchUserInfoByCpr(InlineObject1?: InlineObject1, options?: any) {
        return UserInfoApiFp(this.configuration).fetchUserInfoByCpr(InlineObject1, options)(this.axios, this.basePath);
    }

}

