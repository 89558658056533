import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  class: "card__body",
  key: "cardbody"
}
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "card__text" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["href", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_usabillaInPageBlockComponent = _resolveComponent("usabillaInPageBlockComponent")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "expand",
    onAfterEnter: _ctx.transitionExpand.afterEnter,
    onEnter: _ctx.transitionExpand.enter,
    onLeave: _ctx.transitionExpand.leave
  }, {
    default: _withCtx(() => [
      (_ctx.componentInit)
        ? (_openBlock(), _createElementBlock("div", {
            id: _ctx.card.name,
            class: _normalizeClass([_ctx.card.cardHeight, _ctx.card.inactivate ? 'disabled ' : '', _ctx.card.textCenter ? 'text-center' : '', ' card card--base']),
            key: "card"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h2", {
                class: "mb-4",
                textContent: _toDisplayString(_ctx.card.title)
              }, null, 8, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: "pb-5 text-left",
                  innerHTML: _ctx.card.text
                }, null, 8, _hoisted_5),
                (_ctx.usabillaBlock)
                  ? (_openBlock(), _createBlock(_component_usabillaInPageBlockComponent, {
                      key: 0,
                      model: _ctx.usabillaBlock
                    }, null, 8, ["model"]))
                  : _createCommentVNode("", true),
                _createElementVNode("a", {
                  class: _normalizeClass([_ctx.card.buttonStyle, 'm-0 mt-4']),
                  href: _ctx.link,
                  textContent: _toDisplayString(_ctx.linkTxt)
                }, null, 10, _hoisted_6)
              ])
            ])
          ], 10, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onAfterEnter", "onEnter", "onLeave"]))
}