import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState } from "vuex";
import { SkeletonLoaderComponent, SpinnerComponent } from '@/views/sharedcomponents/baseComponents/components';
import ContentItem from "@/views/contentApi/contentTypes/ContentItemCd";
import InsurancesCommon from "@/views/model/InsurancesCommon";
import { CustomerInvoiceYearSummary } from "@/generated-api/customer-invoices";
import AbLightbox from "@/views/common/ab_lightbox";
import { Tracking } from "@/views/sharedcomponents/lib/services/tracking"
import store from '@/store';

@Options({
    name: 'BillingBlockComponent',
    components: {
        SkeletonLoaderComponent,
        SpinnerComponent
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable,
        parentModel: state => state.epiContent.model
    }),
    props: {
        model: Object as PropType<any>
    },
})

export default class BillingBlockComponent extends mixins(InsurancesCommon) {
    model: any;

    contentBlocks: Array<ContentItem> = [];
    customerInvoiceYearSummaryList: Array<CustomerInvoiceYearSummary> = null;

    vueScrollTo: any;
    abLightbox = new AbLightbox();

    public blockList: Array<ContentItem> = [];

    fullVersion: boolean = true;

    isBusiness: boolean = false;

    trackingUrl: string;

    beforeMount() {
        this.trackingUrl = Tracking.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
    }

    // LIFECYCLE
    async mounted() {
        this.isBusiness = await this.customerIsBusiness();
        this.getInvoicesViaRest();
        this.vueScrollTo = require('vue-scrollto');
    }

    // METHODS
    detailsSelected(invoice: any) {
        invoice.selected = !invoice.selected;
    }

    invoiceSelected(payment: any) {
        (this.$refs.spinnerComponent as any).startSpinner(); 

        if (this.isLoggedInAsApp()) {
            this.callFromNativeScript(
                {
                    "PDF_REST": `/customer/invoices/${payment.invoice_id}/pdf`
                }
            );
        } else {
            this.abAxiosSelfservice.customerInvoicesServiceLayer.fetchCustomerInvoicePdfById(payment.invoice_id,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    },
                    responseType: "blob"
                }
            ).then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                let date = new Date();
                let formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                this.downloadFile(blob, `${payment.payment_date}_regning_${formattedDate}.pdf`);
                (this.$refs.spinnerComponent as any).closeSpinner();
            }).catch(err => {
                (this.$refs.spinnerComponent as any).closeSpinner();
                this.handleApiServiceError(err, "PDF kunne ikke hentes. Noget gik galt.. ");
            });
        }
    }

    getInvoicesViaRest(): void {
        this.getBillingList().then(invoiceYear => {
            this.handleGetInvoices(invoiceYear);
        }).catch(err => {
            this.handleApiServiceError(err, "Regninger kunne ikke hentes. Noget gik galt.. ");
        })
    }

    // Tranformerer data til view
    handleGetInvoices(invoiceYears: Array<any>): void {
        this.customerInvoiceYearSummaryList = [];
        invoiceYears.forEach(invoiceYear => {
            if (invoiceYear.invoices.length > 0) {
                this.customerInvoiceYearSummaryList.push(invoiceYear);
            }
        })
    }
}
