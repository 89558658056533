import { InfoCardBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd';

export default class InfoCardBlockCd extends ContentItemCd {
    public model: InfoCardBlock;
    constructor(model: InfoCardBlock) {
        super();
        this.model = model;
        this.init();
    }

    public init(): void {
        if (this.model.backgroundColor) {
            this.model.backgroundColor = 'background-color:' + this.model.backgroundColor;
        }
    }
}
