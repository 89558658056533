import CustomerPhoneHandler from '@/views/providers/CustomerPhoneHandler';
import { OrderFlowInputBlock } from '@/definitions/episerver/content-types';
import ContentItemCd from './ContentItemCd'

const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

export default class OrderflowInputIntBlockCd extends ContentItemCd {
    public model: OrderFlowInputBlock

    // These will be set after model loaded
    public id: string;
    public canSubmit: boolean = true;
    public value: string;

    constructor(model: OrderFlowInputBlock) {
        super();
        this.model = model;
        this.init();
    }

    public init(): void {
        // console.log('OrderflowInputIntBlockCd', this.model);
        this.model.hidden = !!this.model.hidden;
        this.model.required = !!this.model.required;
        // if (this.model.fieldType) {
        //     this.type = this.model.fieldType;
        // }

        if  (!this.model.fieldName) {
            this.model.fieldName = '_' + Math.random();
        }
        if (this.model.fieldType === 'Tel') {
            this.id = 'frmPhoneNumA';
        } else {
            this.id = Math.random() + '';
        }
        if (this.model.defaultValue) {
            this.value = this.model.defaultValue;
        }
        // console.log('InputIntBlock!!!!', this);
    }

    public handleEvent(evt) {
        if (!this.model.hidden) {
            if(evt.event.type === 'keyup' || evt.event.type === 'blur' || evt.event.type === 'input') {
                this.value = evt.value;
                const valid = this.isValid(evt.value);
                this.canSubmit = this.model.required ? valid : true;
                if (valid && this.model.fieldType === 'Tel') {
                    CustomerPhoneHandler.updateCustomerPhoneNo(evt.value);
                }
            }
        }
    }


    public setupEventHandler() {
        this.canSubmit = (!this.model.required || this.model.hidden);
        if (this.model.fieldType === 'Tel') {
            try {
                const _value = CustomerPhoneHandler.getCustomerPhoneNo();
                if (_value && this.isValid(_value)) {
                    this.model.defaultValue = _value;
                    this.value = _value;
                    this.canSubmit = true;
                    return;
                }
            } catch(err) {
                console.error(err);
            }
        }
    }

    private isValid(value: string): boolean {
        const length = value?.trim()?.replace(/\D/g,'').length;
        if (length < 1) {
            return false;
        }
        if (this.model.fieldType === 'Tel') {
            if (length < 8) {
                // this.errorMsg = 'Telefonnummer er ikke indtastet korrekt';
                return false;
            }
            const regex = new RegExp(phoneRegex, "gi");
            if (!regex.test(value.trim())) {
                // this.errorMsg = 'Telefonnummer er ikke indtastet korrekt';
                return false;
            }
        } 
        return true;
    }

}
