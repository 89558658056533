import SweetAlert2 from "sweetalert2";

/**
 * sweetalert2 wrapper https://github.com/sweetalert2/sweetalert2
 * docs https://sweetalert2.github.io/
 */
class AbLightbox {
    public _abSwal = SweetAlert2;

    constructor() {
    }

    basic(abTitle: string = "", abText: string = "", abHtml: string = "") {
        this._abSwal({
            title: abTitle,
            text: abText,
            html: abHtml,
            customClass: "richtext"
        });
        this.removeX();

    }

    toastMsg(abTitle: string = "", abText: string = "", abHtml: string = "", popupTime: number = 2000) {
        this._abSwal({
            title: abTitle,
            text: abText,
            html: abHtml,
            showConfirmButton: false,
            showCloseButton: true,
            timer: popupTime,
            position: 'center',
            animation: false,
        });
    }

    standard(abTitle: string = "", abHtml: string = "") {
        this._abSwal({
            title: "<h3 class='-lineBelow'>" + abTitle + "</h3>",
            html: abHtml,
            showCloseButton: true,
            confirmButtonText: "Luk",
            confirmButtonClass: "button__cta__outlineInput",
            buttonsStyling: false,
            animation: false,
            // customClass: "abStandard richtext slideUpAni"
            customClass: "abStandard richtext"
        });
        this.removeX();
        // this.slideUp();
    }

    standardLightText(abTitle: string = "", abHtml: string = "") {
        this._abSwal({
            title: "<h3 class='-lineBelow'>" + abTitle + "</h3>",
            html: abHtml,
            showCloseButton: true,
            confirmButtonText: "Luk",
            confirmButtonClass: "button__cta__outlineInput",
            buttonsStyling: false,
            animation: false,
            customClass: "abStandard abLight richtext"
        });
        this.removeX();
    }

    standardLightTextSelfService(abTitle: string, abHtml: string, errorDesc: string) {
        abTitle = abTitle ? abTitle : "Der skete en fejl";
        abHtml = abHtml ? abHtml : `Siden kan desværre ikke hentes i <br>øjeblikket, vi beklager ulejligheden. <br> Prøv igen senere.`;
        if ((location.href.startsWith('https://localhost') || location.href.startsWith('https://alpha') || location.href.startsWith('https://ab-fnol.azurewebsites.net/'))) {
            abHtml += errorDesc ? '<div style="border: 2px solid lightgrey; margin-top: 20px; padding: 10px;"><p style="color: red">' + errorDesc + '</p></div>': '';
        }
        this._abSwal({
            title: abTitle,
            html: abHtml,
            showCloseButton: true,
            focusConfirm: false,
            buttonsStyling: false,
            animation: true,
            customClass: "",
            showCancelButton: false,
        });
    }

    LightTextQueueKmsPrYear(cmsText: object, componentsToMount:  Array<any> = [], callback: any) {
        const defineSteps = () => {
            const ignoreSteps = componentsToMount[0].getIgnoreSteps() as Array<number>;
            ignoreSteps.forEach( (ItemToSkip) => {
                this._abSwal.deleteQueueStep(ItemToSkip);
                totalKmsAccumilated.push(0);
            });
        }
        const abTitle = cmsText['ui.kmberegner.title'];//'Dit km-forbrug';
        const buttonTxt = cmsText['ui.kmberegner.continue_btn'];
        const config = {
            title: abTitle,
            buttonsStyling: false,
            confirmButtonText: buttonTxt,
            // confirmButtonClass: "button__cta__outlineInput",
            confirmButtonClass: "button__lightblue button--dark",
            // progressSteps: ['1', '2', '3', '4', '5'],
            animation: false,
            showCloseButton: true,
            heightAuto: false,
            customClass: 'km-beregner-popup',
        };
        const totalKmsAccumilated: Array<number> = [];

        this._abSwal.queue([
            Object.assign({}, config, {
                animation: false,
                html: '<div id="kmStep00"></div>',
                customClass: 'km-beregner-popup init-alert-animation',
                onBeforeOpen: () => {
                    this.removeX();
                    componentsToMount[0].$props.step = -1;
                    componentsToMount[0].$mount('#kmStep00');
                },
                onOpen: (modal) => {
                    // slide up animation on tablet/phone
                    modal.classList.add('do-alert-animation');
                },
                onClose: () => {

                    defineSteps()
                },
                preConfirm: () => {
                    const ignoreSteps = componentsToMount[0].getIgnoreSteps() as Array<number>;
                    if (ignoreSteps.length === 4) {
                        componentsToMount[0].setSelectError();
                        return false;
                    }
                    return true;
                },
            }),
            Object.assign({}, config, {
                html: '<div id="kmStep01"></div>',
                onBeforeOpen: () => {
                    this.removeX();
                    componentsToMount[1].$props.step = 0;
                    componentsToMount[1].$mount('#kmStep01');
                },

                onClose: () => {
                    totalKmsAccumilated.push(componentsToMount[1].value);
                },
            }),
            Object.assign({}, config, {
                html: '<div id="kmStep02"></div>',
                onBeforeOpen: () => {
                    this.removeX();
                    componentsToMount[2].$props.step=1;
                    componentsToMount[2].$mount('#kmStep02');
                },
                onClose: () => {
                    totalKmsAccumilated.push(componentsToMount[2].value);
                },
            }),
            Object.assign({}, config, {
                html: '<div id="kmStep03"></div>',
                onBeforeOpen: () => {
                    this.removeX();
                    componentsToMount[3].$props.step=2;
                    componentsToMount[3].$mount('#kmStep03');
                },
                onClose: () => {
                    totalKmsAccumilated.push(componentsToMount[3].value);
                },
            }),
            Object.assign({}, config, {
                html: '<div id="kmStep04"></div>',
                onBeforeOpen: () => {
                    this.removeX();
                    componentsToMount[4].$props.step=3;
                    componentsToMount[4].$mount('#kmStep04');
                },
                onClose: () => {
                    totalKmsAccumilated.push(componentsToMount[4].value);
                },
            }),
      ]).then((result) => {
        if(totalKmsAccumilated && totalKmsAccumilated.length === 4) {
            let kmSum = totalKmsAccumilated.reduce((a, b) => (a + b), 0);
            if(kmSum === 0) {
                return;
            }
            kmSum = kmSum - (kmSum % 1000); // round down

            const totalKmsInCar: Array<number> = cmsText['ui.kmberegner.range.totalKms'].split(',').map(function(item) {
                return parseInt(item.trim());
              });
            let inx = -1;
            let closest = 0;
            totalKmsInCar.every( (elem: number) => {
                if (kmSum <= elem) {
                    closest = elem;
                    inx = totalKmsInCar.indexOf(closest);
                    return false;
                }
                return true;
            });
            if(closest === 0) {
                inx = totalKmsInCar.length-1;
            }
            let resultContent = '<div class="km-beregner">' + cmsText['ui.kmberegner.result.text'] + '</div>';
            resultContent = resultContent.replace('${km}', totalKmsInCar[inx].toLocaleString('da-DK'));
            this._abSwal({
                    title: abTitle,
                    animation: false,
                    focusConfirm: false,
                    buttonsStyling: false,
                    customClass: 'km-beregner-popup',
                    html: resultContent,
                    confirmButtonText: cmsText['ui.kmberegner.result.confirm_btn'],
                    // confirmButtonClass: "button__lightblue button--dark button--light",
                    confirmButtonClass: "button__lightblue button--dark",
                    cancelButtonClass: "button__darkblue button--dark",
                    cancelButtonText: cmsText['ui.kmberegner.result.cancel_btn'],
                    showCancelButton: true,
                    reverseButtons: true,
                    onBeforeOpen: () => {
                        this.removeX();
                        const buttons = (<HTMLElement>document.querySelector('.swal2-popup .swal2-actions'));
                        buttons.style.justifyContent = 'space-between';
                        buttons.style.margin = '0';
                    }
                }).then((result) => {
                    if (!result.dismiss) {
                        callback(inx);
                    }
                  });
            }
      });
    }

    switch(type: string, header: string = "", text: string = "") {
        switch (type) {
            case "Accept":
                this.accept(header, text);
                break;
            case "StandardLightText":
                this.standardLightText(header, text);
                break;
            case "StandardLightTextSelfService":
                this.standardLightTextSelfService(header, text, '');
                break;
            case "Info":
                this.info(header, text);
                break;
            case "Basic":
                this.basic(header, text);
                break;
            case "Success":
                this.success(header, text);
                break;
            case "Warning":
                this.warning(header, text);
                break;
            case "Error":
                this.error(header, text);
                break;
            case "Question":
                this.question(header, text);
                break;
            default:
                this.standard(header, text);
                break;
        }
    }

    accept(abTitle: string = "", abHtml: string = "") {
        this._abSwal({
            title: "<h3 class='-lineBelow'>" + abTitle + "</h3>",
            html: abHtml,
            footer:
                "<button id='ab_acceptbtn' type='button' disabled class='button__cta__outlineInput'>Accepter</button><span>Læs venligst alle vilkår før du accepterer</span>",
            showCloseButton: true,
            buttonsStyling: false,
            animation: false,
            customClass: "abAccept richtext",
            showCancelButton: false,
            showConfirmButton: false
        });

        let footerElm = this._abSwal.getFooter(),
            acceptbtn: HTMLElement = footerElm.querySelector("#ab_acceptbtn"),
            _abContent: HTMLElement = this._abSwal.getContent()
                .firstElementChild as HTMLElement,
            _abSwalRef = this._abSwal;

        function scrollBottomReached(e: Event, acceptbtn: HTMLElement) {
            let element = e.target as HTMLElement,
                almostBottom = element.clientHeight + 20;
            if (element.scrollHeight - element.scrollTop <= almostBottom) {
                //Reached bottom
                acceptbtn.removeAttribute("disabled");
            }
        }

        function scrollContext(e: Event) {
            scrollBottomReached(e, acceptbtn);
        }
        scrollBottomReached;

        _abContent.addEventListener("scroll", scrollContext);

        acceptbtn.onclick = (function(_abSwalRef) {
            return function() {
                //close
                _abSwalRef.close();
            };
        })(_abSwalRef);
    }

    makeOrder(abTitle: string, abHtml: string, animation: boolean = false, animationOnlyOut: boolean = false) {
        const self = this;
        this._abSwal({
            title: abTitle !== null ? "<h3 class='-lineBelow'>" + abTitle + "</h3>" : null,
            html: abHtml,
            showCloseButton: true,
            buttonsStyling: false,
            animation: false,
            customClass: "abAcceptOrder richtext slideUpAni" +  (animation ? "" : " slideUpAniNow"),
            showCancelButton: false,
            showConfirmButton: false,
            //heightAuto: false,
            onOpen: (swal) => {
                try {
                    swal.parentElement.scrollTo(0, 0);
                    swal.scrollTo(0, 0);
                }
                catch (err) {
                    console.warn("Could not scroll to element; Browser is outdated")
                }
                // waiting for vue form to mount max 2 seconds
                let threshold = 20;
                let interval = setInterval( () => {
                    threshold--;
                    const formSubmitBtn: HTMLButtonElement = swal.querySelector('form button[id="submitOrderBtn"]');
                    if (threshold < 0 || formSubmitBtn) {
                        clearInterval(interval);
                        if (formSubmitBtn) {
                            self.makeFooterLabelButtonForSubmit (formSubmitBtn, swal);
                        }
                        // move title
                        if (!abTitle) {
                            self.moveTitle(swal);
                        }
                    }
                }, 50);
                if (animation || animationOnlyOut) {
                    self.slideDownAndClose();
                }
            }
        });
        this.fullWidthAndHeight();
        this.removeX();
        animation ? this.slideUp() : null;
    }

    makeOrderWithValidation(abTitle: string, elementId: string, preHtml: string = "", postHtml: string = "", animation: boolean = false, animationOnlyOut: boolean = false) {
        const self = this;
        this._abSwal({
            title: abTitle !== null ? "<h3 class='-lineBelow'>"+ abTitle + "</h3>" : null,
            html: '',
            showCloseButton: true,
            buttonsStyling: false,
            animation: false,
            customClass: "abAcceptOrder richtext slideUpAni" +  (animation ? "" : " slideUpAniNow"),
            showCancelButton: false,
            // confirmButtonClass: "button__cta__outlineInput",
            showConfirmButton: false,
            onOpen: (swal) => {
                try {
                    swal.parentElement.scrollTo(0, 0);
                    swal.scrollTo(0, 0);
                }
                catch (err) {
                    console.warn("Could not scroll to element; Browser is outdated")
                }
                // Get the SWAL2 content div
                const swalContent = (window as any).jQuery("#swal2-content");
                // Insert the pre html
                swalContent.prepend(preHtml);
                // Fetch data from EPiServer form
                const form = (window as any).jQuery("#" + elementId + " form")[0];

                if (form) {
                    // Create a wrapper
                    let wrapper = document.createElement("div");
                    wrapper.className = "form__inputs grid-1-2-c";
                    // Add our form to the wrapper and set display to block
                    (window as any).jQuery(form).appendTo(wrapper);
                    //(window as any).jQuery(form).show();
                    // Add our wrapper to the SWAL2 content div
                    (window as any).jQuery(wrapper).appendTo(swalContent);
                    // Append the post html
                    swalContent.append(postHtml);
                    (window as any).jQuery(swalContent).show();

                    // create label for form submit button and place it in the sweetalert buttonpanel
                    // buttonpanel is hidden if form is submittted succesful (ab_epiform.ts)
                    //const formSubmitBtn: HTMLButtonElement = form.querySelector('[data-f-type="submitbutton"]');
                    //if (formSubmitBtn) {
                    //    self.makeFooterLabelButtonForSubmit (formSubmitBtn, swal);
                    //}
                }
                // move title
                if (!abTitle) {
                    self.moveTitle(swal);
                }

                if (animation || animationOnlyOut) {
                    self.slideDownAndClose();
                }

            },
            onClose: (swal) => {
                let container = (window as any).jQuery("#" + elementId)[0];
                let form = (window as any).jQuery("form", swal)[0];
                (window as any).jQuery(form).appendTo(container);
            }
        });
        this.fullWidthAndHeight();
        this.removeX();
        animation ? this.slideUp() : null;
    }

    private moveTitle(swal: HTMLElement): void {
        const titleElement: HTMLElement = swal.querySelector('#swal2-title');
        if (!titleElement || titleElement.innerHTML !== '') {
            return;
        }
        let formHeader: HTMLElement = swal.querySelector('.abcenterText');
        if (formHeader) {
            formHeader = formHeader.querySelector(':not(p), :not(div), :not(span), :not(img)');
        }
        if(!formHeader) {
            formHeader = swal.querySelector('form .Form__Title');
            if(formHeader) {
                formHeader.classList.add('-lineBelow');
            }
        }

        if (formHeader) {
            formHeader.className += ' ' + titleElement.classList.toString();
            const clonedTitle: any = document.createElement(formHeader.tagName);
            clonedTitle.innerHTML = formHeader.innerHTML;
            clonedTitle.className = ' ' + titleElement.classList.toString() + ' ' + formHeader.classList.toString();
            clonedTitle.classList.remove('hide');
            titleElement.parentNode.insertBefore(clonedTitle, titleElement);
            clonedTitle.parentNode.removeChild(titleElement);
            formHeader.classList.add('hide');
        }
    }

    private makeFooterLabelButtonForSubmit (formSubmitBtn: HTMLButtonElement, swal: HTMLElement): void {

        const episerverForm: HTMLElement = swal.querySelector('.EPiServerForms .Form__MainBody');
        if (episerverForm && episerverForm.style.display === 'none') {
            // form is already submitted. Don't render button
            return;
        }

        // hide the form submit button
        formSubmitBtn.style.display = 'none';

        const swalConfirmBtnLabel: HTMLLabelElement =  document.createElement("label");
        const actionPanel: HTMLElement = swal.querySelector('.swal2-actions');
        // show button panel
        actionPanel.style.display = 'flex';

        // copy classes from submitbtn to labelBtn
        swalConfirmBtnLabel.className = formSubmitBtn.classList.toString();
        // set innerhtml
        swalConfirmBtnLabel.innerHTML = formSubmitBtn.innerHTML;
        // connect label to btn
        swalConfirmBtnLabel.setAttribute('for', formSubmitBtn.getAttribute('id'));
        // check for disabled
        // check for statechange on form
        const swalContent: HTMLElement = swal.querySelector('#swal2-content');
        swalContent.onclick = () => {
            setTimeout( () => {
                if (formSubmitBtn.hasAttribute('disabled')) {
                    swalConfirmBtnLabel.setAttribute('disabled', '')
                } else {
                    swalConfirmBtnLabel.removeAttribute('disabled');
                }
            }, 20);
        }
        // add labelBtn to action panel
        actionPanel.appendChild(swalConfirmBtnLabel);
        // set enabled/disabled for label
        swalContent.click();
    }

    info(abTitle: string = "", abText: string = "") {
        this._abSwal({
            title: abTitle,
            text: abText,
            type: "info",
            animation: false,
            customClass: "richtext"
        });
    }

    success(abTitle: string = "", abText: string = "") {
        this._abSwal({
            title: abTitle,
            text: abText,
            type: "success",
            animation: false,
            customClass: "richtext"
        });
    }

    warning(abTitle: string = "", abText: string = "") {
        this._abSwal({
            title: abTitle,
            text: abText,
            type: "warning",
            animation: false,
            customClass: "richtext"
        });
    }

    error(abTitle: string = "", abText: string = "") {
        this._abSwal({
            title: abTitle,
            text: abText,
            type: "error",
            animation: false,
            customClass: "richtext"
        });
    }

    question(abTitle: string = "", abText: string = "") {
        this._abSwal({
            title: abTitle,
            text: abText,
            type: "question",
            animation: false,
            customClass: "richtext"
        });
    }

    // The library hardcodes an "x" as text in the close button, but we want to use a specific icon instead.
    // So this method removes the "x" manually.
    removeX() {
        let closeButtons = Array.from(
            document.querySelectorAll(".swal2-close")
        );
        closeButtons.forEach(btn => (btn.innerHTML = ""));
    }

    //Make container fullWidth
    fullWidthAndHeight() {
        let swalContainer = document.querySelector(".swal2-container");
        if (window.innerWidth >= 1024) {
            swalContainer.setAttribute("style", "padding: 0 !important; height: 100% !important; overflow:hidden; z-index: 28;");
        } else {
            swalContainer.setAttribute("style", "padding: 0 !important; height: 100% !important; z-index: 28;");
        }
    }

    private slideUp() {
        const swal = document.querySelector(".swal2-popup.swal2-modal.slideUpAni");
        if (swal) {
            swal.classList.add("slideUpAniNow");
            return
        }
    }
    private slideDownAndClose() {
        const self = this;
        const closeIcon: HTMLButtonElement = document.querySelector('button.swal2-close');
        closeIcon.onclick = (evt) => {
            evt.preventDefault();
            const swal: HTMLElement = document.querySelector(".swal2-popup.swal2-modal.slideUpAni");
            if (swal) {
                swal.classList.remove("slideUpAniNow");
            }
            // wait for slide down animation before closing
            setTimeout( () => {
                self._abSwal.clickConfirm();
                // close() doesn't work. it messes up if you want to open the popup again.
                // self._abSwal.close();
            }, 250);
        }
    }
}

export default AbLightbox;
