<template>
    <div>
        <div class="details">
            <img v-if="productImage" class="details__image" :src="productImage.url" alt="" />
            <h2 class="details__headline" v-html="baseInfoTitle"></h2>
            <div class="details__subtitle" v-if="baseInfoSubtitle != ''">{{baseInfoSubtitle}}</div>
        </div>

        <div class="details">
            <div :key="updateHighlights" v-if="highlight !== null">
                <div class="details__item"
                     v-for="(highlight) in highlight.highlights"
                     :key="highlight.label">
                    <p v-html="highlight.label"></p>
                    <p v-html="highlight.value"></p>
                </div>
            </div>

        </div>

        <shared-components v-for="(contentItem, inx) in contentBlocks" :key="'pHigh' + inx" :contentItem="contentItem" />

        <span v-if="showSpinner" class="spinner-full-page"></span>
    </div>
</template>
