import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "col-12 calculator-header-basket-page" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 0,
  class: "tile-wrapper"
}
const _hoisted_5 = { class: "ContentAreaItem col-sm-12 col-lg-12" }
const _hoisted_6 = { class: "info-text" }
const _hoisted_7 = { class: "info-text__content" }
const _hoisted_8 = { class: "calculators badge" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cmsImg = _resolveComponent("cmsImg")!
  const _component_page_title = _resolveComponent("page-title")!
  const _component_BasketWidgetComponent = _resolveComponent("BasketWidgetComponent")!
  const _component_content_area = _resolveComponent("content-area")!
  const _component_MessageComponent = _resolveComponent("MessageComponent")!
  const _component_layout_private = _resolveComponent("layout-private")!
  const _directive_epi_edit = _resolveDirective("epi-edit")!

  return (_openBlock(), _createBlock(_component_layout_private, {
    disableAuthenticationCheck: _ctx.model.disableAuthenticationCheck,
    showTitleInApp: _ctx.model.showTitleInApp,
    hideMenus: _ctx.hideMenus
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: _normalizeClass(['tile-wrapper tile-wrapper-basket-header', {'app' : _ctx.isLoggedInApp}])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.showLogo)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.homeUrl,
                    title: "Til Alm. Brand forside"
                  }, [
                    _createVNode(_component_cmsImg, {
                      name: "icons-svg/system-icons/icon-system-ab-logo.svg",
                      isInlineSvg: true,
                      globalImages: true,
                      alt: "Almbrand",
                      class: "logo__icon"
                    })
                  ], 8, _hoisted_3))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_page_title, {
              class: "page__title--tiles",
              preTitle: _ctx.model.preTitle,
              mainTitle: _ctx.model.mainTitle,
              showTitleInApp: _ctx.model.showTitleInApp
            }, null, 8, ["preTitle", "mainTitle", "showTitleInApp"]),
            (_ctx.cmsInit)
              ? (_openBlock(), _createBlock(_component_BasketWidgetComponent, {
                  key: 0,
                  showBasket: true,
                  forceOpen: true,
                  productName: undefined
                }))
              : _createCommentVNode("", true)
          ])
        ], 2),
        (_ctx.headerBadgeTxt)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", {
                        innerHTML: _ctx.headerBadgeTxt,
                        class: "calculator-badge"
                      }, null, 8, _hoisted_9)
                    ])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_content_area, {
          rootClass: "tile-wrapper",
          model: _ctx.model.mainArea,
          isTiles: true
        }, null, 8, ["model"]), [
          [_directive_epi_edit, 'MainArea']
        ]),
        (_ctx.cmsInit)
          ? (_openBlock(), _createBlock(_component_MessageComponent, {
              key: 1,
              useBasketModel: true,
              class: "page__content calculators vertical-flow"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["disableAuthenticationCheck", "showTitleInApp", "hideMenus"]))
}