import { OrderFlowInputBlock } from '@/definitions/episerver/content-types';
import CustomerEmailHandler from '@/views/providers/CustomerEmailHandler';
import ContentItemCd from './ContentItemCd'

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default class OrderflowInputStringBlockCd extends ContentItemCd {
    public model: OrderFlowInputBlock;
    
    // These will be set after model loaded
    public canSubmit: boolean = true;
    public id: string;
    public value: string;

    constructor(model: OrderFlowInputBlock) {
        super();
        this.model = model;
        this.init();
    }

    public init(): void {
        // console.log('OrderflowInputStringBlockCd', this.model);
        this.model.hidden = !!this.model.hidden;
        
        this.model.required = !!this.model.required;
        this.setValue();
        if (this.model.fieldType === 'Email') {
            this.id = 'frmEmailC';
        } else {
            this.id = Math.random() + '';
        }

        if  (!this.model.fieldName) {
            this.model.fieldName = '_' + Math.random();
        }

    }

    private setValue() {
        if (this.model.defaultValue) {
            this.value = this.model.defaultValue;
        }
        if (this.model.descriptionRichText) {
            this.value = this.model.descriptionRichText;
            this.model.defaultValue = this.model.descriptionRichText;
        }
    }

    public handleEvent(evt) {
        this.canSubmit = !this.model.required || this.model.hidden;

        if (!this.model.hidden) {
            if(evt.event.type === 'keyup' || evt.event.type === 'blur' || evt.event.type === 'input') {
                this.value = evt.value;
                const valid = this.isValid(evt.value);
                this.canSubmit = this.model.required ? valid : true;
            }
        }
    }

    public setupEventHandler() {
        this.canSubmit = !this.model.required || this.model.hidden;
        if (this.model.fieldType === 'Email') {
            try {
                const _value = CustomerEmailHandler.getCustomerEmail();
                if (_value && this.isValid(_value)) {
                    this.model.defaultValue = _value;
                    this.value = _value;
                    this.canSubmit = true;
                    return;
                }
            } catch(err) {
                console.error(err);
            }
        }
    }

    private isValid(value: string): boolean {
        if(value?.trim().length < 1) {
            return false;
        }
        const regex = new RegExp(emailRegex, "gi");
        if (!regex.test(value.trim())) {
            // this.errorMsg = 'Email ikke indtastet korrekt';
            return false;
        }
        return true;
    }

}
