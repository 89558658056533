import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_object_component = _resolveComponent("product-object-component")!

  return (_ctx.agreementObjects !== null && _ctx.agreementObjects.length > 0)
    ? (_openBlock(), _createBlock(_component_product_object_component, {
        key: 0,
        title: _ctx.title,
        totalPrice: _ctx.totalPrice,
        agreemenObjects: _ctx.agreementObjects
      }, null, 8, ["title", "totalPrice", "agreemenObjects"]))
    : _createCommentVNode("", true)
}