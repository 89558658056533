import { Vue, Options, mixins } from "vue-class-component";
import template from "./PageTitle.vue";
import { App } from "@/views/sharedcomponents/lib/services/app";
import AbCommon from '@/views/model/AbCommon';
import { isNullOrUndefined } from "@/views/common/util";

@Options({
    name: "page-title",
    mixins: [template],
    props: {
        preTitle: String,
        mainTitle: String,
        //dontSendMainTitleToApp: Boolean,
        showTitleInApp: Boolean
    }
})
export default class PageTitle extends mixins(AbCommon) {
    preTitle: string;
    mainTitle: string;
    //dontSendMainTitleToApp: boolean;
    showTitleInApp: boolean;
    
    isWeb: boolean;

    public created() {
        /*
        if (!this.dontSendMainTitleToApp) {
            if(!isNullOrUndefined(this.mainTitle)){
                App.sendMessage({ MAIN_TITLE: this.mainTitle });
            }
        }*/

        this.isWeb = !App.isEnabled();
    }

    public get showTitle() {
        return (this.isWeb || this.showTitleInApp) && (this.preTitle || this.mainTitle);
    }
}
