import { mapState } from 'vuex';
import { CarSettings } from '../../car/CarSettings';
import InputComponent from '../../components/InputComponent.vue';
import { PopoverComponent } from '@/views/sharedcomponents/baseComponents/components';
import { RadioComponent } from "@/almbrand/web-shared";
import AbGtm from '@/views/common/ab_gtm';
import { CardBlock } from '@/definitions/episerver/content-types';
import { AccidentSettings } from '../../accident/AccidentSettings';
import { BuyInsuranceHelper, Formatter, monthYearArray, Validator, ValuePair } from '../../BuyInsuranceHelper';
import { HomeSettings } from '../../home/HomeSettings';
import { HouseSettings } from '../../house/HouseSettings';
import { DogSettings } from '../../dog/DogSettings';
import { TravelSettings } from '../../travel/TravelSettings';
import FieldValidator from '../../FieldValidator';
import UrlHandler from '../../UrlHandler';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'PaymentStepComponent',
    components: {
        PopoverComponent,
        InputComponent,
        RadioComponent
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
    props: {
        card: Object, // Card from cms
        isValid: Boolean, // wether card is complete
    }
})

export default class PaymentStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // wether card is complete

    public model!: any; // datastructure for user input (store)

    public cms!: DogSettings | TravelSettings | CarSettings | AccidentSettings | HomeSettings | HouseSettings; // settings from CMS (store)

    private abGtm: AbGtm = new AbGtm();
    public monthYearArray: Array<ValuePair> = monthYearArray;

    public monthlyDisplayPrice: string = null;
    public yearlyDisplayPrice: string = null;
    public fieldValidator: FieldValidator = Validator;
    public oneSubmit = false;

    public created() {
        const pack = this.model.choosePackage.selectedPackage;
        this.monthlyDisplayPrice = pack.totalPriceDisplay + ' pr. måned';
        this.yearlyDisplayPrice = pack.yearlyPriceTotalDisplay + ' årligt';

        window.addEventListener('keyup' , this.moveOn );

    }

    public handleRadioClick(evt) {
        this.model.choosePackage.monthYear = evt.value;
        this.abGtm.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/betaling/${evt.value === 'M' ? 'monthly' : 'yearly'}`
        });
    }

    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    public getYearlySavingsTxt() {
        const amount = (this.model.choosePackage.selectedPackage.totalPrice * 12) - this.model.choosePackage.selectedPackage.yearlyPriceTotal;
        const res = Formatter.format(amount) + ' kr.';
        return this.cms.yearlySavings ? this.cms.yearlySavings.replace('¤yearlySaving', res) : '';
    }

    public nextStep() {
        if (this.isValid && !this.oneSubmit) {
            this.oneSubmit = true;
            this.$emit('nextStep', this.card.routePath);
        }

    }
}
