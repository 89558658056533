import { Vue, Options, mixins } from "vue-class-component";
import template from './CardBlock.vue';
import CardBlock from '../../contentTypes/CardBlockCd';
import SpecialLinkHandler from '@/views/providers/SpecialLinkHandler';
import { App } from "@/views/sharedcomponents/lib/services/app"

@Options({
    name: 'selfservice-card-block',
    mixins: [template],
    props: {
        contentItem: CardBlock
    }
})
export default class CardBlockComponent extends Vue {
    contentItem: CardBlock;

    public hasMobilbankLink: boolean = false;
    public isMobile: boolean = false;
    public hasExternalLink: boolean = false;

    beforeMount() {
        if(this.hasMobilbankLink) {
            this.hasExternalLink = true;
        } else {
            this.hasExternalLink = this.hasMobilbankLink || SpecialLinkHandler.isExternalLink(this.contentItem.model.routePath);
        }

        this.isMobile = App.isEnabled();
        // test
        // this.isMobile = true;
        // console.log('isMobile', this.isMobile, 'hasMobilbankLink', this.hasNetbankLink);
    }

    openApp() {
        //OPEN_APP: MOBILBANK
        App.sendMessage({
            OPEN_APP: 'MOBILBANK'
        })
    }

}
