import { Vue, Options, mixins } from "vue-class-component";
import { PropType } from "vue";
import { mapState } from "vuex";
import PageTitle from "@/views/overview/_partials/pageTitle";
import ContentArea from "@/views/sharedcomponents/epiComponents/infrastructure/contentArea/contentArea";
import Private from "@/views/layouts/private";

@Options({
    name: 'SelfServiceErrorPageComponent',
    components: {
        ContentArea,
        PageTitle,
        Private
    },
    computed: mapState<any>({
        isInEditMode: state => state.epiContext.inEditMode,
    }),
    props: {
        model: Object as PropType<any>
    },
})

export default class SelfServiceErrorPageComponent extends Vue {
    model: any;

    // COMPUTED
    get isTiles() { // hacky shiat for demo purposes
        return this.model.mainArea && this.model.mainArea.some(x => x.contentType === "TileBlock");
    }

    get hideMenus() {
        if (this.model.disableAuthenticationCheck || this.model.hideMenus) {
            return true;
        }

        return false;
    }
}
