enum InsurancesProductCodeEnum {

        ERHVERV = "EF_EF",
        ANSVAR = "AE_AE",
        LANDBRUG = "LL_LL",
        BYGNING1 = "B1_B1",
        ARBEJDSSKADE = "AF_AF",
        ERHVERVSREJSE = "EE_EE",

        BILFORSIKRING = "AU_PB",
        BP_BILFORSIKRING = "BP_BP",
        EB_BILFORSIKRING = "EB_EB",
        TRAKTORFORSIKRING = "TR_TA",
        ARBEJDSMASKINEFORSIKRING = "AM_TA",

        TEKNISKFORSIKRING = "TF_TF",
        INDUSTRI = "IF_IF",
        BYGNING2 =  "B2_B2",

        ULYKKESFORSIKRING = "UP_UP",
        BOERNEULYKKESFORSIKRING = "UP_UPB",
        LYSTFARTOEJSFORSIKRING = "LF_LF",
        INDBOFORSIKRING = "FP_FP",
        HUSFORSIKRING = "HU_HP",
        HUNDEFORSIKRING = "VP_VP",
        HESTEFORSIKRING = "HE_HE",
        REJSEFORSIKRING = "RP_RP",
        REJSEFORSIKRINGUNDERFAMILIEFORSIKRING = "FP_RP",
        CAMPINGVOGNSFORSIKRING = "CV_CP",

        MOTORCYKEL = "MC_MC",
        REGISTRERETKNALLERT = "RK_MC",
        ELEKTRISKKOERETOEJ = "EL_MC",
        UREGISTRERETKNALLERT = "UK_MC",

        ERHVERVSPRODUKTANSVAR = "ER_EA",
        ERHVERVSANSVAR = "EA_EA",
        TRANSPORTREGULERING = "TG_TP",
        TRANSPORTAAR = "TY_TP",
        TRANSPORTKORTTID = "TK_TK",
        TRANSPORT = "ET_ET",
        FLAADEFORSIKRING = "FF_FF",
        VETERANKOERETOEJ = "VK_VE",
        KOLLEKTIVULYKKE = "KU_KU",
        FORHANDLERVAERKSTED = "FV_FV",
        FRAGTFOERERANSVARSFORSIKRING = "FA_FA",

        AUTOULYKKE = "UA_UA",
        PRIVATANSVAR = "PA_PA",
        LANDBO = "LB_LB",

        FRITIDSHUS = "FH_HP",
        FRITIDSHUSKONVERTERET = "FR_PF",
        PAAHAENGSVOGN = "PV_CP",

        BYGGESKADE = "BS_BS",
        SKIFTEPOLICE = "SF_SF",
        KORTTIDSREJSE = "RP_KR",
        GRAVIDITETSFORSIKRING = "TD_TD",
        ANSVARSFORSIKRING_PLUS = "TD_TD",
        KORTTID = "KT_KT",
        EJERSKIFTEFORSIKRING = "ES_ES",
        BYGGEFORSIKRING = "EP_EP",
        SAELGERANSVAR= "SA_SA",
        SAELGERANSVAR15 = "SA_SA15",
        SAELGERANSVAR20 = "SA_SA20",

        BILFORSIKRING_OLD = "PM_PM",
        FRITIDSHUS_OLD = "PH_PF"

}

export default InsurancesProductCodeEnum;
