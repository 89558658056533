import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6dae460"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CmsImg = _resolveComponent("CmsImg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['input-error', { 'input-error--show' : _ctx.showValidationError }])
  }, [
    _createVNode(_component_CmsImg, {
      name: "icons-svg/system-icons/icon-system-cancel-red.svg",
      isInlineSvg: false,
      globalImages: true,
      height: "15",
      width: "15",
      alt: "Rediger",
      class: "input-error__icon"
    }),
    _createElementVNode("span", {
      class: "input-error__message",
      innerHTML: _ctx.text
    }, null, 8, _hoisted_1)
  ], 2))
}