import AbGtm from '@/views/common/ab_gtm';
import AgreementStatus from '@/views/model/AgreementStatus';
import { CustomerAgreement } from '@/generated-api/customer-agreements';
import ProductlineHeader from '@/views/overview/insurances/productline/productlineheader';
import FlowHandler from './FlowHandler';
import CartHandler from './CartHandler';
import { CustomerAgreementSummaryList } from "@/generated-api/customer-agreements";
import { UrlUtil } from "@/episerver/api/urlUtil";
import { App } from "@/views/sharedcomponents/lib/services/app";
import OrderflowBlockCd from '@/views/contentApi/contentTypes/OrderflowBlockCd';
import OrderflowContainerBlockCd from '@/views/contentApi/contentTypes/OrderflowContainerBlockCd';
import UsabillaInPageBlockComponent from '@/views/contentApi/components/UsabillaInPageBlockComponent';
import store from '@/store';
import { ContentBlockType } from '@/views/contentApi/contentTypes/ContentBlockType';
import ContentBlockCreator from '@/views/contentApi/ContentBlockCreator';
export default class OrderflowTravelPropertyHandler {
    private productlineHeader: ProductlineHeader;
    private agreement: CustomerAgreement;
    public canChangeCoverageAreaUi = false;
    private orderflowBlockTheWorld: OrderflowBlockCd = undefined;
    private orderflowContainerBlock: OrderflowContainerBlockCd = undefined;
    private expiresSoon: boolean = false;
    private hasCheckedOut: boolean = false;
    private coverageArea: string;
    private abGtm: AbGtm = new AbGtm();
    public productNameLower: string;

    constructor(productlineHeader: ProductlineHeader) {
        this.productlineHeader = productlineHeader;
        this.agreement = productlineHeader.productDetails.agreement;
        this.coverageArea = this.productlineHeader.getBaseInfoValueByName('destination', this.productlineHeader.agreementPart);
        this.productNameLower = productlineHeader.title.toLocaleLowerCase().trim();
        const endDate = new Date(this.agreement.end_date);
        const now = new Date();
        const dayDiff = this.productlineHeader.getDateDifferenceInDays(now, endDate);

        this.expiresSoon = dayDiff < 35; // 5 uger
        // TODO KLK Test
        if (UrlUtil.isDevelop()) {
            this.expiresSoon = false;
        }
    }

    public handleOrderflow() {
        if (!this.orderflowContainerBlock) {
            const orderflowContainerBlocks = this.productlineHeader.orderflowContainerBlocks;
            if (orderflowContainerBlocks.length > 0) {
                orderflowContainerBlocks.forEach( (orderflowContainerBlock) => {
                    // console.log('orderflowContainerBlock', orderflowContainerBlock.orderflowId);
                    if (orderflowContainerBlock.orderflowId === 'Dækning område') { 
                        // id = 4
                        this.orderflowContainerBlock = orderflowContainerBlock;
                        // this.orderflowContainerBlock.setup(this.productlineHeader.abAmount);
                        const tilvalg: boolean = this.coverageArea === 'Europa';
                        const onlineFlow: boolean = this.isOnlineFlow();
                        this.orderflowContainerBlock.getOrderflowBySubType(tilvalg, onlineFlow).then( (orderflowBlockTheWorld) => {
                            if (orderflowBlockTheWorld) {
                                this.orderflowBlockTheWorld = orderflowBlockTheWorld;
                                this.setCoverageArea();
                                this.updateParentCoverages();
                            } else {
                                this.setCoverageArea();
                            }
                        }).catch( (err) => {
                            console.error('err', err);
                            this.setCoverageArea();
                            // this.updateParentCoverages();
                        });
                    }
                });
            }
            else {
                this.setCoverageArea();
                // this.updateParentCoverages();
            }
            // });
        }
    }

    private isOnlineFlow(): boolean {
        const canChangeOnline = store.getters.getUserData.canChangeOnline;
        // console.log('onlineFlowPossible', canChangeOnline ,this.agreement.changeable ,!this.expiresSoon ,(this.productlineHeader.status === AgreementStatus.IKRAFT));
        return canChangeOnline && this.agreement.changeable && !this.expiresSoon && this.productlineHeader.status === AgreementStatus.IKRAFT;
    }
    private updateParentCoverages() {
        // TODO get 4 descriptions from content
        const europaDescription = `Rejseforsikring Europa gælder følgende steder: Det geografiske Europa (indtil Ural), Færøerne, Grønland, Azorerne, De Kanariske Øer og Madeira. Det geografiske Europa: Albanien, Andorra, Belgien, Bosnien-Hercegovina, Bulgarien, Cypern, Estland, Finland, Frankrig, Gibraltar, Grækenland, Holland/Nederland, Hviderusland/Belarus, Irland, Island, Isle of Man, Italien, Kanaløerne, Kasakhstan, Kosovo, Kroatien, Letland, Liechtenstein, Litauen, Luxembourg, Makedonien, Malta, Moldova, Monaco, Montenegro, Norge, Polen, Portugal, Rumænien, Rusland, San Marino, Schweiz, Serbien, Slovakiet, Slovenien, Spanien, Storbritannien (England, Skotland, Wales), Sverige, Tjekkiet, Tyrkiet, Tyskland, Ukraine, Ungarn, Vatikanstaten og Østrig`
        const worldDescription = `Denne forsikring dækker i hele verden. Du skal derfor ikke tegne en selvstændig rejseforsikring til USA, da forsikringen også dækker rejser dertil.`
        const europaHeader = 'Din rejseforsikring dækker lige nu i europa. Se en liste over alle lande nedenfor.';
        const worldHeader = 'Din rejseforsikring dækker lige nu hele verden. Se en liste over alle lande nedenfor.';
        const changeToArea = this.coverageArea === 'Europa' ? 'Verden' : 'Europa';

        const data = {
            title: 'Dækning ' + this.coverageArea,
            description: this.coverageArea === 'Europa' ? europaHeader : worldHeader,
            id: 'coverage_Eu_world',
            // name: 'Dækning Europa eller verden',
            selected: false,
            feature: {
                selected: false,
                sub_features: [
                    {
                        sub_feature_id: window.btoa('I-RP_RP-12-9999'), // '123'
                        name: 'Hvilke lande er jeg dækket i?',
                        description: this.coverageArea === 'Europa' ? europaDescription : worldDescription,
                    }
                ]
            },
            hasAction: this.canChangeCoverageAreaUi,
            changeFunction: this.canChangeCoverageAreaUi ? this.openOrderFlow() : undefined,
            actionLabel: 'Skift til ' + changeToArea,
        };
        this.productlineHeader.$emit('updateCoverages', {
            includedCoverage: true,
            position: 1,
            data,
            });
    }


    public canChangeCoverageArea(): boolean {
        this.canChangeCoverageAreaUi = ( !store.getters.getUserData.mastercard &&
                                    this.orderflowBlockTheWorld !== undefined);
        return this.canChangeCoverageAreaUi;
    }
    private setCoverageArea() {
        if (this.coverageArea) {
            this.productlineHeader.updateHighlight('Dækning', this.coverageArea);
            this.canChangeCoverageArea();
        }
    }

    public openOrderFlow() {
        return () => {
            if (this.canChangeCoverageArea() && this.isOnlineFlow() && this.orderflowBlockTheWorld.model.onlineFlow) {
                this.productlineHeader.showSpinner = true;
                // hent eller lav cart
                // sæt priser
                // lav orderflow
                let changeId = '4'; // tilvalg verden
                if (!this.orderflowBlockTheWorld.model.tilvalg) {
                    changeId = '1'; // fravalg verden
                }

                const translate = {
                    insuranceName: 'Rejseforsikring',
                    orderflowName: 'dækning Verden',
                    expireDate: this.productlineHeader.formatDate(this.agreement.end_date),

                };
                this.orderflowBlockTheWorld.addPropertyChange(
                    this.productlineHeader.$route.query.id as string,
                    this.orderflowBlockTheWorld.model.tilvalg,
                    this.productlineHeader.productDetails.price,
                    'travel_coverage_area',
                    changeId,
                    translate, this.productlineHeader)
                .then( () => {
                    this.createOnlineOrderflow();
                    this.productlineHeader.showSpinner = false;
                }, (error) => {
                    // manual orderflow
                    const cancel = this.cancelCallBack();
                    cancel();
                    this.createAlternateOrderflow();
                    this.productlineHeader.showSpinner = false;
                });
            } else {
                // manual orderflow
                this.createAlternateOrderflow();
                this.productlineHeader.showSpinner = false;
            }
        }
    }

    private createOnlineOrderflow() {
        const includePrices: boolean = this.orderflowBlockTheWorld.hasPrices();

        let submitCallBack: Function = this.submitWithoutPrices(this.orderflowBlockTheWorld.model.tilvalg);
        if (includePrices) {
            submitCallBack = this.submitWithPrices(this.orderflowBlockTheWorld.orderflowId);
        }
        const tilvalgFravalg: string = this.coverageArea === 'Europa' ? 'tilvalg' : 'fravalg';
        const orderflowSteps = this.orderflowBlockTheWorld.orderflowSteps;
        const flowHandler = FlowHandler.createFlowHandlerContent(this.orderflowBlockTheWorld.orderflowSteps);
        flowHandler.productTitle = this.productlineHeader.title;
        flowHandler.closeCallBack = this.closeCallBackCoverage(true);
        let title = 'skift-til-';
        title += this.coverageArea === 'Europa' ? 'Verden' : 'Europa';
        flowHandler.orderFlowList.map( (step, inx) => {
            // add submitCallBack to submit step
            if (orderflowSteps[inx].model.isSubmit) {
                // step.submitCallBack = submitCallBack;
                orderflowSteps[inx].submitCallBack = submitCallBack;
            }
            // add cancelfunction to all but last step (reciept)
            if (inx < flowHandler.orderFlowList.length - 1) {
                // step.cancelCallBack = this.cancelCallBack();
                orderflowSteps[inx].cancelCallBack = this.cancelCallBack();
            }
            if (orderflowSteps[inx].model.trackCode) {
                step.track = this.track(title, true, orderflowSteps[inx].allTrackCodes, tilvalgFravalg);
            }
        });

        this.productlineHeader.$emit('openOrderflow', {
            flowHandler,
        });
        this.resetProductCart(flowHandler);
    }

    private async createAlternateOrderflow(failFlow: boolean = false) {
        const tilvalg: boolean = this.coverageArea === 'Europa';
        const orderflowBlockTheWorld = await this.orderflowContainerBlock.getOrderflowBySubType(tilvalg, false);
        try {
            if (orderflowBlockTheWorld) {
                this.orderflowBlockTheWorld = orderflowBlockTheWorld;
                let submitCallBack: Function = this.submitWithoutPrices(this.orderflowBlockTheWorld.model.tilvalg);
                const tilvalgFravalg: string = this.coverageArea === 'Europa' ? 'tilvalg' : 'fravalg';

                const orderflowSteps = this.orderflowBlockTheWorld.orderflowSteps;
                const flowHandler = FlowHandler.createFlowHandlerContent(this.orderflowBlockTheWorld.orderflowSteps);
                flowHandler.productTitle = this.productlineHeader.title;
                flowHandler.closeCallBack = this.closeCallBackCoverage(false);
                let title = 'skift-til-';
                title += this.coverageArea === 'Europa' ? 'Verden' : 'Europa';
                flowHandler.orderFlowList.forEach( (step, inx) => {
                    // add submitCallBack to submit step
                    if (orderflowSteps[inx].model.isSubmit) {
                        // step.submitCallBack = submitCallBack;
                        orderflowSteps[inx].submitCallBack = submitCallBack;
                    }
                    // add cancelfunction to all but last step (reciept)
                    if (inx < flowHandler.orderFlowList.length - 1) {
                        // step.cancelCallBack = this.cancelCallBack();
                        orderflowSteps[inx].cancelCallBack = this.cancelCallBack();
                    }
                    // add tracking show if not failflow
                    if (orderflowSteps[inx].model.trackCode && !failFlow) {
                        step.track = this.track(title, false, orderflowSteps[inx].allTrackCodes, tilvalgFravalg);
                    }

                });
                this.productlineHeader.$emit('openOrderflow', {
                    flowHandler,
                });
                this.resetProductCart(flowHandler);
            }
        } catch (err) {
            console.error('err', err);
        }
    }

    private resetProductCart(flowHandler?: FlowHandler) {
        if (flowHandler) {
            flowHandler.reset();
        }
        this.setCoverageArea();
    }

    private track(orderflowId: string, onlineFlow: boolean, action: string, tilvalgFravalg: string): Function {
        return () => {
            this.abGtm.triggerCustomGtmEvent({
                'event': 'track-vp',
                'virtualPath': `/mitalmbrand/forsikring/mineforsikringer/rejseforsikring/${orderflowId}/${tilvalgFravalg}/${onlineFlow ? 'online' : 'manual'}/${action}`
                });
        }
    }

    private closeCallBackCoverage(update: boolean): Function {
        if(!update) {
            return () => {}
        }

        return () => {
            // always remove possible "ignored carts"
            CartHandler.removeCart();

            if (!this.hasCheckedOut) {
                // customer has not accepted buying coverage - we're done
                return;
            }
            this.productlineHeader.showSpinner = true;
            this.hasCheckedOut = false; // reset

            this.productlineHeader.getAgreementList().then(customerAgreementList => {
                console.log('this.productlineHeader.getAgreementList()');
                this.productlineHeader.$emit("closeOrderflow");
                this.productlineHeader.showSpinner = false;
                this.handleRoutingNewPolicy();
            }).catch(err => {
                // clean agreement cache
                // this will force reload of agreementlist and product
                store.dispatch('setAgreementList', undefined);
                store.dispatch('setCustomerAgreement', undefined);
                const url = UrlUtil.ensureCorrectLink(`/forsikring/mineforsikringer`); // fallback
                this.productlineHeader.$router.replace(url);
                this.productlineHeader.showSpinner = false;
            });
        }
    }

    private cancelCallBack(): Function {
        return () => {
            this.resetProductCart();
            UsabillaInPageBlockComponent.reloadUsabillas();
        }
    }

    private submitWithPrices(orderflowId: string): Function {
        console.log('Checkout cart coverage (property)');
        return async () => {
            try {
                const checkOut = await CartHandler.checkoutCart(this.productlineHeader, this.productlineHeader.title, orderflowId);
                this.hasCheckedOut = true;
                store.dispatch('setLastUserUpdatedAgreementId', this.productlineHeader.agreementId);
                this.refreshCacheForProductAndProductList();
                return checkOut;
            } catch(err) {
                console.log(err);
                
                if (UrlUtil.isDevelop()) {
                    console.warn('TODO KLK remove test ');
                    this.hasCheckedOut = true;
                    return true;
                }
                await CartHandler.removeCart();
                this.createErrorFlow();
                return Promise.reject();
            }
        }
    }

    private async createErrorFlow() {
        await this.createAlternateOrderflow(true);
        this.orderflowBlockTheWorld.useErrorTexts(0);

        const translate = {
            insuranceName: 'Rejseforsikring',
            orderflowName: 'dækningsområde',
        };
        this.orderflowBlockTheWorld.translateTemplate(translate);
        const tilvalgFravalg: string = this.coverageArea === 'Europa' ? 'tilvalg' : 'fravalg';
        let title = 'skift-til-';
        title += this.coverageArea === 'Europa' ? 'Verden' : 'Europa';
        const track = this.track(title, true, 'fail', tilvalgFravalg);
        track();

    }

    private submitWithoutPrices(tilvalg: boolean): Function {
        return async (params: Map<string, string>) => {
            const tilvalgFravalg =  tilvalg ? 'tilvalg af verdensdækning' : 'fravalg af verdensdækning';
            params.set('policyLine', '' + this.productlineHeader.productId);
            params.set('policyNo', '' + this.productlineHeader.agreementId);
            params.set('subject', 'Anmodning om ' + tilvalgFravalg + ' på ' + this.productlineHeader.title);
            const customer = await this.productlineHeader.getCustomer();
            FlowHandler.setUserDetailsForMail(params, customer);
            await this.productlineHeader.sendMailToOss(params);
            return Promise.resolve();
        }
    }

    private async refreshCacheForProductAndProductList(): Promise<any> {
        // fecht new agreement(s) in background
        await store.dispatch('setAgreementList', undefined);
        await store.dispatch('setCustomerAgreement', undefined);
        await this.productlineHeader.getAgreementList();
    }

    private handleRoutingNewPolicy() {
        store.dispatch('setAppReplaceRoute', true);
        this.productlineHeader.doRoute(`/forsikring/mineforsikringer`);
    }

}
