
import { mapState } from 'vuex';
import { ButtonComponent, ModalComponent } from '@/views/sharedcomponents/baseComponents/components';
//import { ModalComponent } from "@/almbrand/web-shared";
import AbGtm from '@/views/common/ab_gtm';
import UrlHandler from '../UrlHandler';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'ModalComponentCalculator',
    components: {
        ModalComponent,
        ButtonComponent,
    },
    computed: mapState<any>({
        productModel: state => state.calculatorContext[state.calculatorContext.active].model,
        basketModel: state => state.calculatorContext.basket.model,
    }),
    props: {
        useBasketModel: {type: Boolean, default: false}
    }
})

export default class ModalComponentCalculator extends Vue {
    useBasketModel?: boolean;
    
    public productModel!: any; // datastructure for user input (store)
    public basketModel!: any; // datastructure for basket
    private abGtm: AbGtm = new AbGtm();

    public model: any;
    public created() {
        this.model = this.useBasketModel ? this.basketModel : this.productModel;
    }

    public toggleModal(args) {
        this.$emit('toggleModal', args);
    }

    public track() {
        // default tracking
        let virtualPath = `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/error/customercall`;

        if (this.model.modal.track) {
            const token = this.model.modal.trackToken ? this.model.modal.trackToken : 'error';
            virtualPath = `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/${token}/`;
        }

        this.abGtm.triggerCustomGtmEvent({
            'event': 'track-vp',
            virtualPath
        });

    }

    public getHrefType(): string {
        if (this.model.modal.btnType === 'tel') {
            return 'tel:';
        }
        if (this.model.modal.btnType === 'mailto') {
            return 'mailto:';
        }
        return '';
    }

    public getIcon(): string {
        return this.model.modal.btnType === 'tel' ? this.model.modal.btnIcon: undefined;
    }

    public getLabel(): string {
        if (this.model.modal.btnType === 'tel' || this.model.modal.btnType === 'mailto') {
            return this.model.modal.btnLabel + this.model.modal.btnAction;
        }
        return this.model.modal.btnLabel;
    }

    public getTarget(): string {
        if (this.model.modal.btnType === 'link') {
            return '_blank';
        }
        return undefined;
    }

    public handleInlineAction() {
        if (typeof this.model.modal.btnInlineAction == 'function') {
            this.model.modal.btnInlineAction();

        } else {
            this.$router.push(this.model.modal.btnInlineAction);
        }
    }
}
