<template>
    <div :class="['sub-accordion__item', { 'sub-accordion__item--open' : showSubFeature }]">
        <div class="sub-accordion__header"
             v-on:click="animateSubAccordion(formattedText)"
             v-on:keyup.enter="animateSubAccordion(formattedText)"
             role="button"
             tabindex="0"
             data-gtm-action="Accordian Sub"
             :data-gtm-label="headline"
             :data-gtm-url="trackingUrl">
            <h4 :class="['sub-accordion__headline', isCovered ? 'sub-accordion__headline--track_covered' : 'sub-accordion__headline--track_not_covered']">
                {{headline}}
            </h4>
        </div>
        <transition name="slide">
            <div v-show="showSubFeature" class="sub-accordion__body">
                <div v-html="formattedText" class="mb-3"></div>

                <div v-for="(highlight) in highlights" :key="highlight.label" class="d-flex justify-content-between">
                    <p v-html="highlight.label"></p>
                    <p class="font-family-work-sans-bold" v-html="highlight.value" style="text-align: right"></p>
                </div>
            </div>
        </transition>
    </div>
</template>
