import { mapState } from 'vuex';
import { CardBlock } from '@/definitions/episerver/content-types';
import { PopoverComponent } from '@/views/sharedcomponents/baseComponents/components';
import { AccidentSettings } from '../../accident/AccidentSettings';
import { CarSettings } from '../../car/CarSettings';
import { DogSettings } from '../../dog/DogSettings';
import { FamilySettings } from '../../family/FamilySettings';
import { HomeSettings } from '../../home/HomeSettings';
import { HouseSettings } from '../../house/HouseSettings';
import { TravelSettings } from '../../travel/TravelSettings';
import { BuyInsuranceHelper } from '../../BuyInsuranceHelper';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';

@Options({
    name: 'OverviewStepComponent',
    components: {
        PopoverComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
    props: {
        card: Object, // Card from cms
        isValid: Boolean, // wether card is complete
    }
})

export default class OverviewStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // wether card is complete

    public model!: any; // datastructure for user input (store)
    public cms!: DogSettings | TravelSettings | CarSettings | AccidentSettings | HomeSettings | HouseSettings | FamilySettings; // settings from CMS (store)

    public showOrderDetails = false;
    public coverages: any = null; // coverages on selected package

    public suggest: {suggestedProducts: string, discountDisplay: string} = {
        suggestedProducts: undefined,
        discountDisplay: undefined
    }
    public suggestText: string = null;
    public suggestText1: string = null;

    public created () {
        if (this.model.choosePackage.selectedPackage.expandedCoverages?.length > 0) {
            this.coverages = [];
            this.model.choosePackage.selectedPackage.expandedCoverages.forEach(name => {
                this.coverages.push({name});
            });
        } else {
            this.coverages = this.model.choosePackage.selectedPackage.coverages;
        }
        this.setSuggestText();
        window.addEventListener('keyup' , this.moveOn );
    }

    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    private setSuggestText() {
        this.suggestText = this.getSuggestText();
        this.suggestText1 = this.getSuggestText1();

    }
    private getSuggestText(): string {
        if(!this.cms.suggestProductsText) {
            return undefined;
        }
        this.suggest = BuyInsuranceHelper.getSuggestedProducts(store, this.cms);
        if (!this.suggest) {
            return undefined;
        }
        return this.cms.suggestProductsText.replace('¤discount', this.suggest.discountDisplay).replace('¤products', this.suggest.suggestedProducts);
    }
    private getSuggestText1(): string {
        if (this.suggest && this.cms.suggestProductsText1) {
            return this.cms.suggestProductsText1.replace('¤discount', this.suggest.discountDisplay).replace('¤products', this.suggest.suggestedProducts);
        }
        return undefined;
    }

    public toggleOrderDetails() {
        this.showOrderDetails = !this.showOrderDetails;
    }

    public nextStep() {
        this.$emit('nextStep', this.card.routePath);
    }

    public getMonthlyYearlyPrices(monthly: boolean) {
        const pack = this.model.choosePackage.selectedPackage;
        return monthly ? pack.totalPriceDisplay + ' / pr. md.': pack.yearlyPriceTotalDisplay + ' årligt';
    }

    public gotoAddInsurances() {
        this.$emit('gotoAddInsurances', this.card.name);
    }

}
