import Adapter from "./Adapter";
import FeatureAdapter from "./FeatureAdapter";
import { CustomerAgreementBundle } from "@/generated-api/customer-agreements/api";



export default class BundleAdapter extends Adapter {

    public bundle: CustomerAgreementBundle;
    public _featureList: Array<FeatureAdapter> = [];

    public constructor(bundle: CustomerAgreementBundle) {
        super(bundle.bundle_id + "", bundle.name, "description");

        if (bundle.features) {
            for (let feature of bundle.features) {
                this.featureList.push(new FeatureAdapter(feature));
            }
        }
        this.bundle = bundle;
    }

    get featureList(): Array<FeatureAdapter> {
        return this._featureList;
    }

}
