
import FieldValidator from '../FieldValidator';
import { InputFieldComponent } from '@/views/sharedcomponents/baseComponents/components';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'InputComponent',
    components: {
        InputFieldComponent,
    }, 
    props: {
        id: String,
        name: String,
        placeholder: String,
        label: String,
        labelPopover: String,
        type: String,
        innerData: Object,
        validateOnLoad: Boolean,
        required: Boolean,
        validationClass: {type: String, default: ''},
        readonly: {type: String, default: null},
        fieldValidatorType: {type: String, default: null},
    }
})

export default class InputComponent extends Vue {
    id: string;
    name: string;
    placeholder: string;
    label: string;
    labelPopover?: string;
    type: string;
    innerData: any;
    validateOnLoad: boolean;
    required: boolean;
    validationClass?: string; // validation success css class
    readonly?: string;
    fieldValidatorType?: string;

    private fieldValidator: FieldValidator;
    public validationErrorFormatText: string = '';
    public inputValue: string = '';
    public hasError = false;

    public mounted() {
        this.inputValue = this.innerData[this.name];
        if (this.fieldValidatorType) {
            this.fieldValidator = new FieldValidator(this.fieldValidatorType);
        } else {
            this.fieldValidator = new FieldValidator(this.name);
        }

        if (this.validateOnLoad) {
            this.validateInput("blur", this.inputValue);
        }
    }

    // METHODS
    public handleInput(data) {
        this.inputValue = data.value;
        this.validateInput(data.event.type, data.value);
        this.innerData[this.name] = data.value;
        if (data.event.type === "focus") {
            this.$emit('InputFocus', data.value);
        }
        if (data.event.type === "input") {
            this.$emit('InputChanged', data.value);
        }
        if (data.event.type === "blur") {
            this.$emit("InputFieldComponentBlur", data.value);
        }
    }

    private validateInput(event: string, value: string) {
        if (event === "blur" || (event === "keyup" && this.validationErrorFormatText) || (event === 'input' && value?.length > 1) ) {
            // make sure error doesn't show instantly
            setTimeout(() => {
                this.hasError = !this.fieldValidator.isValid(value);
                this.validationErrorFormatText = !this.hasError ? '' : this.fieldValidator.errorMsg;                    
            }, 100);
        }
    }
}
