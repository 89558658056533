import Axios, { AxiosRequestConfig } from "axios";
import AxiosRetry from 'axios-retry';
import * as AxiosApiCustomerAgreements from "@/generated-api/customer-agreements/index";
import * as AxiosApiCustomerDetails from "@/generated-api/customer-details/index";
import * as AxiosApiCustomerInvoices from "@/generated-api/customer-invoices/index";
import * as AxiosApiCustomerClaims from "@/generated-api/customer-claims/index";
import * as AxiosApiCustomerInbox from "@/generated-api/customer-documents/index";
import * as AxiosApiCart from "@/generated-api/cart/index";
import * as AxiosApiCatalog from "@/generated-api/catalog/index";
import * as AxiosApiQuote from '@/generated-api/car-quote/index';
import * as AxiosApiAccidentQuote from '@/generated-api/accident-quote/index';
import * as AxiosLayerBroker from '@/generated-api/broker/index';
import AbCookieBanner from "./ab_cookiebanner";
import { isNullOrUndefined } from '@/views/common/util';
import { defaultConfig } from '@/episerver/config';
import store from "@/store";
import { UrlUtil } from "@/episerver/api/urlUtil";

type OptionalHeader = {name: string, value: string};
/**
 * axios openAPI Generator https://openapi-generator.tech/docs/generators/typescript-axios
 */

class AbAxiosSelfservice {
    private axios = Axios.create({
        timeout: 15000
    });
    private headers;
    
    private abaxiosRetry = AxiosRetry;
    private retries: number = 0;

    private abCookieHandler = new AbCookieBanner();

    private csrfToken: string;
    private baseUrl: string;
    private recaptchaEnabled: boolean = false;

    public customerAgreementsServiceLayer: AxiosApiCustomerAgreements.CustomerAgreementApi;
    public customerAgreementsDocumentServiceLayer: AxiosApiCustomerAgreements.CustomerAgreementDocumentApi;
    public customerDetailsServiceLayer: AxiosApiCustomerDetails.CustomerDetailsApi;
    public customerInvoicesServiceLayer: AxiosApiCustomerInvoices.CustomerInvoiceApi;
    public customerClaimsServiceLayer: AxiosApiCustomerClaims.CustomerApi;
    public customerInboxServiceLayer: AxiosApiCustomerInbox.SystemApi;
    public customerInboxDocumentServiceLayer: AxiosApiCustomerInbox.CustomerAgreementDocumentApi;
    public catalogServiceLayer: AxiosApiCatalog.CatalogApi;
    public cartServiceLayer: AxiosApiCart.CartApi;
    public quoteServiceLayer: AxiosApiQuote.QuoteApi;
    public offerServiceLayer: AxiosApiQuote.OfferApi;
    public accidentQuoteServiceLayer: AxiosApiAccidentQuote.InsuranceOfferApi;
    public brokerServiceLayer: AxiosLayerBroker.UserInfoApi;

    private retryConfig = { 
        retries: 3,
        retryDelay: (retryCount) => {
        // console.log(`retry attempt: ${retryCount}`);
        return retryCount * 200; // time interval between retries
        },
        retryCondition: (error) => {
        // if retry condition is not specified, by default idempotent requests are retried
        // console.log('retry error', error);
            if (!error?.response?.status) {
                return true;
            }
            return error?.response?.status > 240;
        },
        shouldResetTimeout : true,
    };



    constructor(
        baseUrl: string,
        contentType: string = "application/json",
        csrfToken: string = null,
        optionalHeaders: Array<OptionalHeader> = null,
        retries: number = 0
    ) {
        this.csrfToken = csrfToken;
        this.setupAxios(contentType, optionalHeaders);
        // TODO: Below baseUrl should be alligned to also include the entire URL
        if(defaultConfig.baseUrl) {
            this.baseUrl = defaultConfig.baseUrl + baseUrl;
            this.axios.defaults.withCredentials = true;
        } else {
            this.baseUrl = window.location.origin + baseUrl;
        }
        if (retries) {
            this.retries = retries;
            this.abaxiosRetry(this.axios, this.retryConfig);
        }
        
        this.customerAgreementsServiceLayer = new AxiosApiCustomerAgreements.CustomerAgreementApi({ /*basePath: this.baseUrl*/ }, this.baseUrl, this.axios);
        this.customerDetailsServiceLayer = new AxiosApiCustomerDetails.CustomerDetailsApi({ /*basePath: this.baseUrl*/ }, this.baseUrl, this.axios);
        this.customerInvoicesServiceLayer = new AxiosApiCustomerInvoices.CustomerInvoiceApi({}, this.baseUrl, this.axios);
        this.customerClaimsServiceLayer = new AxiosApiCustomerClaims.CustomerApi({}, this.baseUrl, this.axios);
        this.customerInboxServiceLayer = new AxiosApiCustomerInbox.SystemApi({}, this.baseUrl, this.axios);
        this.customerInboxDocumentServiceLayer = new AxiosApiCustomerInbox.CustomerAgreementDocumentApi({}, this.baseUrl, this.axios);
        this.cartServiceLayer = new AxiosApiCart.CartApi({ /*basePath: this.baseUrl*/ }, this.baseUrl, this.axios);
        this.catalogServiceLayer = new AxiosApiCatalog.CatalogApi({ /*basePath: this.baseUrl*/ }, this.baseUrl, this.axios);
        this.customerAgreementsDocumentServiceLayer = new AxiosApiCustomerAgreements.CustomerAgreementDocumentApi({ /*basePath: this.baseUrl*/ }, this.baseUrl, this.axios);
        this.quoteServiceLayer = new AxiosApiQuote.QuoteApi({}, this.baseUrl, this.axios);
        this.offerServiceLayer = new AxiosApiQuote.OfferApi({}, this.baseUrl, this.axios);
        this.accidentQuoteServiceLayer = new AxiosApiAccidentQuote.InsuranceOfferApi({}, this.baseUrl, this.axios);
        this.brokerServiceLayer = new AxiosLayerBroker.UserInfoApi({}, this.baseUrl, this.axios);
    }

    public useRecaptcha(useRecaptcha: boolean) {
        this.recaptchaEnabled = useRecaptcha;
    }


    private setupAxios(contentType: string = "application/json", optionalHeaders: Array<OptionalHeader> = null) {
        this.headers = {
            "x-csrf-token": this.csrfToken,
            "x-sales-channel": "web",
            // 'Content-Type': contentType,
            // 'Accept': 'application/json',
            post: {
                'Content-Type': contentType,
                'Accept': 'application/json',
            },
            put: {
                'Content-Type': contentType,
                'Accept': 'application/hal+json',
            },
            // delete: {
            //     'Content-Type': contentType,
            //     'Accept': 'application/json',
            // }
        };

        if (optionalHeaders) {
            optionalHeaders.forEach( (optionalHeader) => {
                this.headers[optionalHeader.name] = optionalHeader.value;
            })
            
        }

        this.axios.interceptors.request.use(async config => {
            config.headers = this.headers;
            return await this.updateHeaders(config).then( (config_) => {
                if (
                    config.method === "post" ||
                    config.method === "put" ||
                    config.method === "delete"
                ) {
                    this.abaxiosRetry(this.axios, { retries: this.retries });
                    config.timeout = 30000;
                }
                return config_;
            });
        });
    }

    private async updateHeaders(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        let recaptchaToken = undefined;
        if (this.recaptchaEnabled) {
            recaptchaToken = await UrlUtil.getReCaptchaToken();
        }
        config.headers['x-recaptcha-token'] = recaptchaToken;

        const custNum = this.abCookieHandler.getCookie("custNum");
        if (!isNullOrUndefined(custNum) && custNum.length > 0) {
            config.headers["x-use-test-customer-number"] = this.abCookieHandler.getCookie("custNum");
        } else {
            delete config.headers["x-use-test-customer-number"];
        }
        return config;
    }
}

export default AbAxiosSelfservice;
