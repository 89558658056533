<template>
    <ul :class="['bullet-list']">
        <li :class="['bullet-list__item', `bullet-list__item--${type}`, { 'selectable': selectable }, {'selected input--valid': selected === index}]"
            v-for="(item, index) in list"
            :key="`bullit ${index}`"
            @click="clicked(index)">
            <span :class="[{ 'bullet-list__item--overflow': overflow }]" v-html="item" />
        </li>
    </ul>
</template>
