import { IContent } from "@/definitions/episerver/content";
import ContentComponentSelector from "@/views/sharedcomponents/epiComponents/infrastructure/contentComponentSelector/contentComponentSelector";
import { resolveComponent} from 'vue';

/**
 * Will check the content's content type against the inheritance chain list in
 * components.
 *
 * Used to get the Vue component matching the loaded content's type by
 * `ContentComponentSelector` and `PageComponentSelector`.
 *
 * @param {*} content The content object that has a contentType property, which
 * holds the inheritance chain from the C# models for the content with the last
 * item being the actual implementation.
 * @param {Array} components The list of registered Vue components.
 * @returns The matching content type, or `null`.
 */
export default function getComponentTypeForContent(content: any): string {
    // Here we will try to find a component that matches the content type name.
    
    if (content?.contentType) {
        return content?.contentType;

    }
    console.warn("content was not vaild", content?.contentType, content);
    return null;
}
